import vue from '@/main.js';
import fun from './index';
import teachFun from './teachFun';
class CreateTeach{
  main = async () => {
    // 创建task 拿到taskId
    // 附带价格信息 * 100

    const task_res = await fun.createTask();
    return task_res;
  }
}

const createTeach = new CreateTeach();

export default createTeach.main;
