var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loading flex" }, [
    _c(
      "svg",
      {
        staticClass: "spinner",
        style: {
          width: _vm.size,
          height: _vm.size,
        },
        attrs: {
          width: _vm.size,
          height: _vm.size,
          viewBox: "0 0 66 66",
          xmlns: "https://www.w3.org/2000/svg",
        },
      },
      [
        _c("circle", {
          staticClass: "path",
          attrs: {
            fill: "none",
            "stroke-width": "6",
            "stroke-linecap": "round",
            cx: "33",
            cy: "33",
            r: "30",
          },
        }),
      ]
    ),
    0
      ? _c(
          "svg",
          {
            staticStyle: { "enable-background": "new 0 0 50 50" },
            attrs: {
              version: "1.1",
              id: "loader-1",
              xmlns: "https://www.w3.org/2000/svg",
              "xmlns:xlink": "https://www.w3.org/1999/xlink",
              x: "0px",
              y: "0px",
              width: _vm.size,
              height: _vm.size,
              viewBox: "0 0 50 50",
              "xml:space": "preserve",
            },
          },
          [
            _c(
              "path",
              {
                attrs: {
                  fill: _vm.background,
                  d: "M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z",
                },
              },
              [
                _c("animateTransform", {
                  attrs: {
                    attributeType: "xml",
                    attributeName: "transform",
                    type: "rotate",
                    from: "0 25 25",
                    to: "360 25 25",
                    dur: "0.6s",
                    repeatCount: "indefinite",
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }