var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "information", on: { click: _vm.pageHandler } },
    [
      _c("div", { staticClass: "information_mask" }),
      _c("div", { staticClass: "information-con" }, [
        _vm._m(0),
        _c("div", { staticClass: "gray" }),
        _c("div", { staticClass: "input_wrapper" }, [
          _c("div", { staticClass: "gender line_group" }, [
            _c("p", { staticClass: "key" }, [_vm._v("性别:")]),
            _c("div", { staticClass: "val no_border" }, [
              _c(
                "p",
                {
                  staticClass: "redu cp",
                  on: {
                    click: function ($event) {
                      return _vm.selectSex("女")
                    },
                  },
                },
                [
                  _vm.user.userSex && _vm.user.userSex == "女"
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/icon-dui.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("../../assets/icon-nor.png"),
                          alt: "",
                        },
                      }),
                ]
              ),
              _c("p", { staticClass: "nv" }, [_vm._v("女士")]),
              _c("img", {
                staticClass: "girl",
                attrs: { src: require("../../assets/icon-girl.png"), alt: "" },
              }),
              _c(
                "p",
                {
                  staticClass: "redu1 cp",
                  on: {
                    click: function ($event) {
                      return _vm.selectSex("男")
                    },
                  },
                },
                [
                  _vm.user.userSex && _vm.user.userSex == "男"
                    ? _c("img", {
                        attrs: {
                          src: require("../../assets/icon-dui.png"),
                          alt: "",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: require("../../assets/icon-nor.png"),
                          alt: "",
                        },
                      }),
                ]
              ),
              _c("p", { staticClass: "man" }, [_vm._v("男士")]),
              _c("img", {
                staticClass: "boy",
                attrs: { src: require("../../assets/icon-boy.png"), alt: "" },
              }),
            ]),
          ]),
          _c("div", { staticClass: "region line_group" }, [
            _c("p", { staticClass: "key" }, [_vm._v("地区:")]),
            _c("div", { staticClass: "val select_area" }, [
              _c(
                "div",
                {
                  staticClass: "info cp",
                  class: { un_input: !_vm.area_selected },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.switch_select_area = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.area))]
              ),
              _c("div", {
                staticClass: "select_icon cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.switch_select_area = true
                  },
                },
              }),
              _vm.switch_select_area
                ? _c(
                    "div",
                    { staticClass: "select_area_model" },
                    [
                      _c("select-area", {
                        on: {
                          closeSelectArea: function ($event) {
                            _vm.switch_select_area = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "line_group" }, [
            _c("div", { staticClass: "key" }, [_vm._v("机构:")]),
            _c("div", { staticClass: "val" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.ownedEnterprises,
                    expression: "user.ownedEnterprises",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.user.ownedEnterprises },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "ownedEnterprises", $event.target.value)
                  },
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user.ownedEnterprises,
                    expression: "user.ownedEnterprises",
                  },
                ],
                staticClass: "clear",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.user.ownedEnterprises = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "line_group" }, [
            _c("div", { staticClass: "key" }, [_vm._v("职务:")]),
            _c("div", { staticClass: "val" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.userPosition,
                    expression: "user.userPosition",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.user.userPosition },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "userPosition", $event.target.value)
                  },
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user.userPosition,
                    expression: "user.userPosition",
                  },
                ],
                staticClass: "clear",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.user.userPosition = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "line_group" }, [
            _c("div", { staticClass: "key" }, [_vm._v("身份:")]),
            _c("div", { staticClass: "val select_identity" }, [
              _c(
                "div",
                {
                  staticClass: "info cp",
                  class: { un_input: !_vm.user.identity },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.switch_select_identity = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.identity_text))]
              ),
              _c("div", {
                staticClass: "select_icon cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.switch_select_identity = true
                  },
                },
              }),
              _vm.switch_select_identity
                ? _c("div", { staticClass: "select_identity_group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        class: { un_input: !_vm.user.identity },
                      },
                      [
                        _vm._v(_vm._s(_vm.identity_text) + "\n              "),
                        _c("div", { staticClass: "select_icon" }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.selectIdentity(1)
                          },
                        },
                      },
                      [_vm._v("教师")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.selectIdentity(2)
                          },
                        },
                      },
                      [_vm._v("学生")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.selectIdentity(3)
                          },
                        },
                      },
                      [_vm._v("从业者")]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "line_group" }, [
            _c("div", { staticClass: "key" }, [_vm._v("邮箱:")]),
            _c("div", { staticClass: "val" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.email,
                    expression: "user.email",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.user.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.user, "email", $event.target.value)
                  },
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.user.email,
                    expression: "user.email",
                  },
                ],
                staticClass: "clear",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.user.email = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "line_group" }, [
            _c(
              "div",
              {
                staticClass: "key",
                staticStyle: { "align-self": "flex-start" },
              },
              [_vm._v("个性签名:")]
            ),
            _c(
              "div",
              {
                staticClass: "val no_border",
                staticStyle: { "align-self": "flex-start", height: "auto" },
              },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.personalDescribe,
                      expression: "user.personalDescribe",
                    },
                  ],
                  staticClass: "sign",
                  domProps: { value: _vm.user.personalDescribe },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.user,
                        "personalDescribe",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "vation" }, [
          _c(
            "button",
            { staticClass: "reser cp", on: { click: _vm.saveFile } },
            [_vm._v("保存")]
          ),
          _c("button", { staticClass: "too cp", on: { click: _vm.tiaoguo } }, [
            _vm._v("跳过"),
          ]),
        ]),
      ]),
      _vm.switch_identification_code
        ? _c("identification-code", {
            attrs: { loginUserCode: _vm.loginUserCode, user: _vm.user },
            on: {
              handleClose: function ($event) {
                return _vm.closeIdentificationCode()
              },
              handleSuccess: function ($event) {
                return _vm.identifySuccess()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "intion" }, [
      _c("p", { staticClass: "xxi" }, [_vm._v("完善个人信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }