<template>
  <div class="c_teaching_package_selected_task_list_opertaion_bar bbox d-flex justify-end"
  >
    <div class="btn_group d-flex">
      <div
        class="btn_light btn"
        @click.stop="doClose"
      >
        取消
      </div>
      <div
        class="btn_dark btn"
        @click.stop="doSure"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OperationBar',
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    doClose() {
      this.$closeTeachingPackageSelectedTaskList()
    },
    doSure() {
      const { packageSelectTask, taskList } = this
      const selectedTaskList = taskList.filter((item) => {
        return item.selected
      })
      packageSelectTask.selectedList = selectedTaskList
      this.doClose()
    }
  }

}
</script>

<style scoped lang="scss">
.c_teaching_package_selected_task_list_opertaion_bar{
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1);
  border-radius: 0 0 10px 10px;
  padding: 0 20px;
  .btn_group{
    .btn{
      cursor: pointer;
      font-size: 14px;
      line-height: 40px;
      border-radius: 4px;
      padding: 0 26px;
      margin-left: 12px;
    }
  }
}
</style>