import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Login/Register.vue";
import Sign from "../views/Login/Sign.vue";
import Login from "../views/Login/Login.vue";
import Forget from "../views/Login/Forget.vue";
import Success from "../views/Login/Success.vue";
import About from "../views/Detail/About.vue";
import Information from "../views/Detail/Information.vue";
import Deta from "../views/Detail/Deta.vue";
import Cate from "../views/Municate/Cate.vue";
import contacts from '../views/Contacts/contacts.vue';
import plication from '../views/Plication/plication.vue';
import fun_task from './fun_task';
import fun_contact from './fun_contact';
import fun_template from './fun_template';
import test from './test';
import personal_center from './personal_center.js';
import beforeEach from './beforeEach';
import active_page from './active_page';
import card_page from './modules/card_page'
import funArgumentedReality from './funArgumentedReality'


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {name: 'fun_task_index'} // 重定向
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    children: [
      {
        path: "home",
        redirect: "/home/about"
      },
      {
        path: "/home/about",
        component: About
      },
      {
        path: "/home/information",
        component: Information
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    children: [
      {
        path: "/login",
        redirect: "/login/sign"
      },
      {
        path: "/login/sign",
        name: 'login_sign',
        component: Sign
      },
      {
        path: "/login/register",
        component: Register
      },
      {
        path: "/login/forget",
        component: Forget
      },
      {
        path: "/login/success",
        component: Success
      }
    ]
  },
  {
    path: "/Detail/Deta",
    component: Deta,
  },
  {
    path: "/Municate/Cate",
    component: Cate
  },
  {
    path: "/Contacts/contacts",
    component: contacts
  },
  {
    path: "/Plication/plication",
    component:plication
  },
  {
    path: '/download/index',
    name: 'download-index',
    component: ()=> import('@/views/DownloadApp/index.vue'),
  },
  {
    path: '/system_notice/domain_change',
    name: 'domain_change',
    component: () => import('@/views/SystemNotice/DomainChange.vue'),
  },
  {
    path: "/default/",
    name:'default',
    // redirect:'test-home',
    component: ()=> import('@/views/layout/default.vue'),
  },
  // {
  //   path: "/test/",
  //   name:'test',
  //   // redirect:'test-home',
  //   component: ()=> import('@/views/test.vue'),
  // },
  ...fun_task,
  ...fun_contact,
  ...fun_template,
  ...funArgumentedReality,
  ...personal_center,
  ...test,
  ...active_page,
  ...card_page,
  // 打印
  {
    path: "/PrintPage",
    name:'PrintPage',
    // redirect:'test-home',
    component: ()=> import('@/views/PrintPage/index.vue'),
  },

];

const router = new VueRouter({
  mode: 'history', // 后端没配置的时候，不要使用history模式
  base: process.env.BASE_URL,
  routes
});
router.beforeEach(beforeEach);

export default router;
