<template>
  <div class="c_popup_gift_card_payment_success_index d-flex bbox flex-column align-center">
    <div
      class="close cp"
      @click.stop="handleClose"
    ></div>
    <div class="title_group d-flex align-center justify-center">
      <div class="icon"></div>
      <div class="title">支付成功</div>
    </div>
    <div class="describe_group d-flex align-center">
      <div class="text">
        {{ time }}s后将跳转到填写寄语，或
      </div>
      <div
        class="jump cp"
        @click.stop="openGiftCardGiveAway"
      >
        点击跳转
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    giftCard: {
      type: Object,
      default: undefined
    },
    pcarduser: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      timeInterval: undefined,
      time: 3
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    handleClose() {
      clearInterval(this.timeInterval)
      this.$emit('handleClose')
    },
    init() {
      this.handleInterval()
    },
    handleInterval() {
      this.timeInterval = setInterval(() => {
        this.time--
        console.log(this.time)
        if (this.time === 0) {
          this.openGiftCardGiveAway()
        }
      }, 1000)
    },
    openGiftCardGiveAway(){
      clearInterval(this.timeInterval)
      const { pcarduser, giftCard } = this
      this.$openGiftCardGiveAway({
        pcarduser,
        giftCard
      })
      // 跳转到可赠送
      // if (giftCard) {
      //   giftCard.type = 2
      // }
      this.handleClose()
    }
  }
}
</script>

<style scoped lang="scss">
.c_popup_gift_card_payment_success_index{
  padding: 40px 20px;
  position: relative;
  .close{
    @include background(12px, 12px);
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/common/close.png');
  }
  .title_group{
    margin-bottom: 20px;
    .icon{
      @include background;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/teaching/success.png);
      margin-right: 5px;
    }
  }
  .title{
    font-size: 20px;
    font-weight: bold;
  }
  .describe_group{
    padding: 0 20px;
    .jump{
      color: $main_blue;
    }
  }
}
</style>