import vue from '@/main.js';
import fun from './index';
import teachFun from './teachFun';
class EditTeach{
  main = async () => {
    // vue.$store.commit('set_task_config', Object.assign(
    //   {},
    //   vue.$store.state.task_config,
    //   {edit_teach:true}
    // ))
    // 创建task 拿到taskId
    // 附带价格信息 * 100

    const task_res = await fun.editTask();
    return task_res;
  }
}

const editTeach = new EditTeach();

export default editTeach.main;