/*
 * @Author       : Hugo
 * @Date         : 2020-05-26 10:06:36
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-06-08 16:22:06
 * @Description  : 
 * @FilePath     : \miaohang\src\store\modules\contact.js
 */ 
// 任务
const contact = {
  state: {
    workteam_detail_name:'', //工作组名
  },
  mutations: {
    /**
     * 设置工作组名
     * @param {*} state 
     * @param {*} payload 
     */
    set_workteam_detail_name(state,payload){
      state.workteam_detail_name = payload;
    },
  
  }
};

export default contact;
