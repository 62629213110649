import vue from '@/main.js'
import sendTaskFun from './sendTaskFun'
import { PTask } from '@/types'
import store from '@/store'
class Common {
  /**
   * 更新消息盒子
   * @param {*} params taskLog 
   * @returns 
   */
  pushMessageBox (params) {
    // params => taskLog
    const { state } = vue.$store
    const { message_box_list, task_config } = state;
    const { create, use, call } = task_config;
    const messageContent = (create || use || call ) ? '创建' : '更新';
    if (!message_box_list) {
      return
    }
    const { chatId, type, thumbnail, createTime, userName, taskName } = params
    let data = {
      chatId: chatId,
      chatType: type,
      photo: thumbnail,
      lastSendTime: createTime,
      updateusername: userName,
      messageContent: `${messageContent} ${taskName}`
    }
    // 如果是单聊 则将chat_id设置为对方id
    if (type == 0 ) {
      data.chatId = sendTaskFun.generateChatId();
    }
    const index = message_box_list.findIndex(item=>{
      return item.chatId == data.chatId && item.chatType == type;
    })
    if (index != -1) {
      data = message_box_list[index];
      data.updateusername = userName;
      data.lastSendTime = createTime;
      data.messageContent = `${messageContent} ${taskName}`;
    }
    if (data.istop == 1) {
      // 置顶
      message_box_list.splice(index, 1)
      message_box_list.unshift(data)
    } else {
      // 不是置顶盒子
      message_box_list.splice(index, 1) // 移除
      // 要移动到的目标索引
      let aim_index = message_box_list.findIndex((item) => {
        return item.lastSendTime <= data.lastSendTime && item.istop == 0;
      })
      // if(aim_index != -1){
      message_box_list.splice(aim_index || 0, 0, data)
    }
  }
  /**
   * 追加消息列表
   * @param {*} params taskLog
   */
  pushMessageList(params){
    const {state, commit } = vue.$store;
    let {message_list, task_config} = state;
    const {chat_type} = task_config;
    const chat_id = sendTaskFun.generateChatId();
    if(vue.$route.params && vue.$route.params.id){
      const current_chat_id = vue.$global.decodeBase64(vue.$route.params.id);
      if(!current_chat_id){
        return;
      }
      const current_chat_type = vue.$tools.getChatType();
      if(current_chat_id != chat_id || current_chat_type != chat_type){
        return;
      }
    }
    let data = params;
    data.createTime = Number(data.createTime);
    if (!message_list) {
      message_list = []
    }
    // 更新与我有关
    message_list.push(Object.assign(
      {},
      data,
      {linkme: true})
    );
    commit('set_message_list', message_list);
    vue.$nextTick(function(){
      // // console.log('触发滚动到底部')
      // 触发滚动到顶部
      vue.$store.commit('set_task_trigger_scroll_to_bottom', 1);
    })
  }
  /**
   * 更新消息列表 与我有关
   * @param {*} params taskLog || webIm ext
   */
  updateMessageList(params){
    // TODO 隐私未更新
    let {taskId, taskType, linkme} = params;
    let {message_list} = vue.$store.state;
    if(message_list && message_list.length){
      message_list.forEach( item => {
        if(item.taskId == taskId){
          item.taskType = taskType;
          item.linkme = linkme
        }
      })
      vue.$store.commit('set_message_list', message_list)
    }
    
  }

  updateMyTaskList(res){
    const { task_config, task } = vue.$store.state
    if (res.contents && res.contents.taskLog) {
      const data = {
        ...res.contents.taskLog,
        taskType: task_config.task_type,
        hide: task_config.privacy,
        colour: task_config.color,
      }

      let {
        createusername,
        createuserthumbnail,
        levelStatus,
        processStatus
      } = task.task || {}
      let { taskId, taskName, createTime, taskType, chatId, hide, colour, isremind } = data
      // let route = vue.$route
      let getRouteParamsId =  vue.$api.moduleTask.getRouteParamsId()
      // 不是当前聊天不更新
      if (getRouteParamsId != task_config.chat_id || vue.$tools.getChatType() != task_config.chat_type) {
        return
      }
      let my_task_list = vue.$tools.deepClone(vue.$store.state.my_task_list)
      // // console.log(my_task_list)
      if (my_task_list) {
        let unit = {
          id: taskId,
          name: taskName,
          updateTime: createTime,
          taskType,
          chatId,
          createuserthumbnail: createuserthumbnail || vue.$tools.getCookie('user').thumbnail,
          createusername: createusername || '我',
          hide,
          colour,
          isremind,
          levelStatus,
          processStatus
        }
        unit = new PTask(unit)
        // console.log(unit, 'unit');
        let index = my_task_list.findIndex((item) => {
          return item.id == taskId
        })
        if(index != -1){
          my_task_list.splice(index, 1);
        }
        // unit.dot = true;
        my_task_list.unshift(unit);
        // // console.log(unit);
        vue.$store.commit('set_my_task_list', my_task_list);

      }
    }
  }

  /**
   * 跳转到指定页面
   */
  jumpToAppointPage(){
    const { task_config } = vue.$store.state
    const { select_target, select_target_chat_id,select_target_chat_type, jump, chat_id, chat_type, draft, use } = task_config
    console.log({ select_target, use, select_target_chat_id, select_target_chat_type, jump })
    if ((select_target || use) && select_target_chat_id !== null && select_target_chat_type !== null) {

      let name = 'fun_task_dialog_single';
      if(select_target_chat_type == 1){
        name = 'fun_task_dialog_group';
      }
      vue.$router.push({
        name, 
        params: {
          id: vue.$tools.btoa(select_target_chat_id)
        }
      })
      return;
    }
    if (jump) {
      let name = 'fun_task_dialog_single'
      if (chat_type == 1) {
        name = 'fun_task_dialog_group'
      }
      const pushId = sendTaskFun.generateChatId()
      vue.$router.push({
        name, 
        params: {
          // 单聊跳转到对方id
          id: vue.$tools.btoa(pushId)
        }
      })
    }
  }

  jumpToSellPage(res){
    // console.log(res);
    // 在我销售的页面 更新列表
    const {name, params} = vue.$route;
    if(name === 'fun_template_my'){
      if(params && params.type === 'sell'){
        this.updateTemplateMyList(res);
        return;
      }
    }
    
    // 不在我销售的页面跳转
    vue.$router.push({name: 'fun_template_my', params:{type: 'sell'}});

  }

  /**
   * 更新我的模板列表
   * @param {*} res 
   * @returns 
   */
  updateTemplateMyList(res){
    const { template_my_list } = vue.$store.state.template
    const { message, contents } = res
    const { task } = store.state
    const { levelStatus, processStatus } = task.task
    if (message === 'success' && contents) {
      const { taskLog } = contents
      // 只编辑状态标签，taskLog为空
      if (taskLog) {
        if(template_my_list){
          const aim = template_my_list.find(item => {
            return item.id === taskLog.taskId
          })
          if(aim !== undefined){
            aim.name = taskLog.taskName
            aim.levelStatus = levelStatus
            aim.processStatus = processStatus
            // template_my_list.splice(aim_index, 1) // 删除
          }else{
            vue.$store.commit('set_template_my_list', [
              ...new Set([
                ...[new PTask({
                  id: taskLog.taskId,
                  taskType: taskLog.taskType,
                  updateTime: taskLog.createTime,
                  name: taskLog.taskName,
                  checkstatus: 3,
                  levelStatus,
                  processStatus
                })],
                ...template_my_list,
              ])
            ])
          }
        }
      }
    }
    return
  }

  /**
   * 更新提醒相关
   * @param {*} params 
   */
  updateReminderConcrened(params){
    let {taskId, type} = params || {}
    const {state} = vue.$store;
    const {message_list, my_task_list} = state;
    if(!type){
      type = 'increase';
    }
    if(taskId){
      if(message_list && message_list.length){
        message_list.forEach(item=> {
          if(item.taskId == taskId){
         
            if(type == 'increase'){
              if(item.isremind){
                vue.$set(item, 'isremind', Number(item.isremind)+1);
              }else{
                vue.$set(item, 'isremind', 1);
              }
            }

            if(type == 'decrease'){
              if(item.isremind){
                vue.$set(item, 'isremind', Number(item.isremind)-1);
              }
            }
            
          }
        })
      }

      if(my_task_list && my_task_list.length){
        my_task_list.forEach(item=> {
          if(item.id == taskId){
           
            if(type == 'increase'){
              if(item.isremind){
                vue.$set(item, 'isremind', Number(item.isremind)+1);
              }else{
                vue.$set(item, 'isremind', 1);
              }
            }

            if(type == 'decrease'){
              if(item.isremind){
                vue.$set(item, 'isremind', Number(item.isremind)-1);
              }
            }
          
          }
        })
      }
    }
  }
  
  /**
   * 更新消息列表特殊字段
   */
  updateMessageListSpecifiedFields(params){
    const {taskId, fields} = params;
    let {message_list} = vue.$store.state;
    if(message_list){
      message_list.forEach(item=>{
        if(item.taskId == taskId){
          item = Object.assign({}, item, {fields});
        }
      })
    }
  }


  /**
   * 更新操作区特殊字段
   */
  updateMyTaskListSpecifiedFields(params){
    const {taskId, fields} = params;
    const my_task_list = vue.$store.state.my_task_list;
    if(my_task_list){
      my_task_list.forEach(item=>{
        if(item.id == taskId){
          item = Object.assign({}, item, {fields});
          // console.log(item);
        }
      })
    }
  }
}

const common = new Common();

export default common;