<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-31 01:04:26
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-31 16:11:03
 * @FilePath: /miaohang/src/components/ClassesTask/modules/Plugins/internalContact/ListUnit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="c_classes_task_modules_plugins_internal_contact_list_unit d-flex align-center justify-space-between bbox"
    v-if="show_item"
  >
    <img
      :src="`${$avatar_url}${userinfo.thumbnail}@!small200`"
      alt="" class="avatar flex-shrink-0">
    <div class="name"
      v-html="name"
    ></div>
    <div class="select flex-shrink-0 cp bbox"
      :class="{active: userinfo.selected}"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    userinfo: {
      type: Object,
      default: undefined,
    },
    keyword: {
      type: String,
      default: '',
    }
  },
  computed: {
    name(){
      const {keyword} = this
      if(keyword.trim() !== ''){
        return this.userinfo.search({keyword: keyword.trim()})
      }

      return this.userinfo.priorableName()
    },
    show_item(){
      const {keyword, name, userinfo} = this
      if(!userinfo){
        return false
      }
      if(keyword.trim() !== '' && name === false){
        return false
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.c_classes_task_modules_plugins_internal_contact_list_unit{
  overflow: auto;
  padding: 5px 20px;
  .avatar{
    width: 44px;
    height: 44px;
    border-radius: 4px;
  }
  .name{
    padding-left: 16px;
    width:100%;
    flex:1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:14px;
    
    font-weight:400;
    color:rgba(51,51,51,1);
  }
  .select{
    width: 18px;
    height: 18px;
    border:2px solid rgba(153,153,153,1);
    border-radius:50%;
    &.active{
      border:none;
      background-image: url(~@/assets/images/create_dialog/selected.png);
      background-size: 100%;
    }
  }
}
</style>

