/*
 * @Author       : Hugo
 * @Date         : 2021-06-17 14:13:33
 * @LastEditTime : 2021-06-22 09:56:56
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/draft.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main.js';

class Draft{
  async getDraftList({keyword = '', start = null}){
    let res = await vue.$global.doPost({
      url: '/projectDraft/draftList',
      data: {keyword, start},
      notice: '获取草稿失败'
    })
    return res;
  }
  
}

const draft = new Draft();

export default draft;