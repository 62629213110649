import vue from '@/main'
import oss from '@/api/modules/oss'

class PFile{
  constructor(params){

    const {
      id,
      filename,
      chatId,
      taskId,
      conId,
      userId,
      createTime,
      url,
      isTag,
      filetype,
      filesize,
      width,
      height,
      teach,
      type,
      sort,
      taskNotice,
      taskname,
      username,
      filesort, // 附件排序 
    } = params;

    this.id         = id;          //	String		id
    this.filename   = filename;    //	String		文件名称
    this.chatId     = chatId;      //	String		所属群聊
    this.taskId     = taskId;      //	String		所属任务
    this.conId      = conId;       //	String		控件id
    this.userId     = userId;      //	String		上传用户
    this.createTime = createTime;  //	String		创建时间
    this.url        = url;         //	String		文件地址
    this.isTag      = isTag;       //	String		是否标星1是 0否
    this.filetype   = filetype;    //	String		文件类型
    this.filesize   = filesize;    //	String		文件大小（单位K）
    this.width      = width;       //	String		宽度
    this.height     = height;      //	String		高度
    this.teach      = teach;       //	String		教学附件0否1是
    this.type       = type;        //	String	 0新增  1删除  2修改 注意排序传2，不传0或1   以免文件被删除或复制
    this.sort       = sort;        //	String		控件序号
    this.taskNotice = taskNotice;  //	String		0任务单文件 1公示板文件
    this.taskname   = taskname;
    this.username   = username;
    this.filesort   = filesort;
  }

  getExtName(){
    return vue.$tools.getExtName(this.filename);
  }
  /**
   * 转换文件大小
   * @returns 
   */
  formatFilesize(){
    const {filesize} = this;
    if(filesize){
      if (filesize >= 1024 * 1024) {
        return (filesize / (1024 * 1024.0)).toFixed(2) + "G";
      } else if (filesize < 1024 * 1024 && filesize >= 1024) {
        return (filesize / 1024.0).toFixed(2) + "M";
      } else {
        return filesize + "K";
      }
    }
    return '';
  }

  /**
   * 高亮关键词
   * @param {*} params 
   * @returns 
   */
  highlightKeywords(params){
    const {keyword} = params;
    return vue.$tools.highlightKeywords(this.filename, keyword);
  }

  previewFile(params){
    const {type} = params || {}; // 2:附件 1: 图片
    const domain = type === 2 ? vue.$file_url : vue.$img_url;
    const {url} = this;
    const filetype = this.getExtName();
    if(filetype === 'mp4'){
      this.previewVideo(url);
    }
    if(filetype === 'pic' || filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png'){
      this.previewPicture({domain});
    }
    if(filetype === 'doc' || filetype === 'ppt' || filetype === 'xls' || filetype === 'docx' || filetype === 'pptx' || filetype === 'xlsx'){
      this.previewOffice(url);
    }
    if(filetype === 'pdf'){
      this.previewPdf(url);
    }
  }

  previewVideo(url){
    let protocol = location.protocol;
    let src = vue.$file_url;
    // 根据当前环境切换http/https源
    if(protocol == 'http:'){
      src = vue.$file_url_http;
    }
    src += url;
    vue.$openVideo({src})
  }
  previewPicture(params){
    const {domain} = params;

    vue.$previewPicture({
      url: `${domain}${this.url}`,
      list: [`${domain}${this.url}`]
    })
  }
  previewOffice(url){
    const basic_url = 'https://view.officeapps.live.com/op/view.aspx?src=';
    window.open(`${basic_url}${vue.$file_url}${url}`, '_blank')
  }
  previewPdf(url){
    window.open(`${vue.$file_url}${url}`)
  }

  /**
   * 打开任务
   * @param {Object} config 
   */
  openTask(params){
    const {config} = params || {};
    vue.$api.moduleTask.openTask({taskId: this.taskId, config: Object.assign({}, config)});
  }

  download(params){
    oss.downloadFile({ file: this })
    return
    const {type} = params || {};
    const { id } = this;
    if(id){
      let exportURL = `https://${vue.$domain_name}/project/download?fileid=${id}&type=${type}`;
      // iframe下载
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = exportURL; 
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(()=>{
        iframe.remove();
      }, 5 * 60 * 1000);
    }

  }

  

}


// class File{
//   constructor(params){
//     const {id, name} = params;
//     this.id = id;
//     this.name = name;
//   }
//   doPost = ()=>{
//     return 'doPost';
//   }
// }

export default PFile;