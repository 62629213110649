<template>
  <div
    class="c_pop_up_migrate_task_select_task_thumbnail_task"
  >
    <thumbnail-task
      :p-task="pTask"
      :can-select="true"
      :selected="selected"
      @handleSelected="handleSelected"
    ></thumbnail-task>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/Thumbnail/Task'
export default {
  components: {
    ThumbnailTask
  },
  props: {
    pTask: {
      type: Object,
      default: undefined
    },
    pageData: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    selected () {
      const { pageData, pTask } = this
      if (!pageData || !pTask) {
        return false
      }
      const { selectedList } = pageData
      if (selectedList.length === 0) {
        return false
      }
      const aim = selectedList.find((unit) => {
        return Number(unit.id) === Number(pTask.id)
      })
      if (aim) {
        return true
      }
      return false
    }
  },
  methods: {
    handleSelected () {
      const { pageData, pTask } = this
      if ((pTask ?? '') === '') {
        return
      }
      const { selectedList } = pageData
      const index = selectedList.findIndex((item) => {
        return Number(item.id) === Number(pTask.id)
      })
      if (index === -1) {
        if (pTask.tousers) {
          const tousersArr = pTask.tousers.split(',')
          if (tousersArr && tousersArr.length > 100) {
            this.$notice({
              desc: '请注意：该任务参与人数量大于100！'
            })
          }
        }
        selectedList.push(pTask)
      } else {
        selectedList.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>