import vue from '@/main';

class UpdateUser{
  main =  async (ext)=>{
    // const {} = ext;
    // console.log(ext);
    const {commit } = vue.$store;
    commit('set_user_data_update', false);
    await vue.$api.user.refreshUserData();
    commit('set_user_data_update', true);
  }

}

// async function refreshUserData(){
//   let password = vue.$tools.getCookie('password');
//   let user = vue.$tools.getCookie('user');
//   let username = user.loginName;
//   let data = {
//     username,
//     password,
//     logintype: 4,
//   };
//   let res = await vue.$http.request('/project/loginAuth', data);
//   for(let i in res.contents){
//     // vue.$cookies.set(i,res.contents[i]);
//     vue.$tools.setCookie(i, res.contents[i]);
//   }
// }

const updateUser = new UpdateUser();

export default updateUser.main;