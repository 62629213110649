<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="768px"
      width="834px"
      border-radius  = "10px"
    >
      <div class="c_plugin_recommuication_record d-flex flex-column"
      >

        <!-- <common-title
          class        = "flex-shrink-0 title_common"
          @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
          border-radius  = "10px"
          background_color = "#fff"
        >
          <template slot>
            <div class="title_group bbox d-flex align-center">
              <div class="title">{{title}}</div>
              <img src="~@/assets/images/common/vip.png" alt="" class="vip">
              <div class="close cp"
                @click.stop="handleClose()"
              ></div>
            </div>

          </template>
        </common-title> -->
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <plugin-component
          class="plugin_component"
          :hint="hint"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from '@/components/PopUp/RecordOfCommunication/index.vue'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      title: '',
      hint: '',
      onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      list: undefined,
      work: '',
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(work){
      if(this.input == ''){
        this.$notice({desc: this.hint})
        return
      }
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure(work)
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_recommuication_record{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 10px;
  overflow-y: auto;
  .title_common{
    width: 100%;
    height: 50px;
    background: #fff;
    position: relative;
    
    .title_group{
      position: relative;
      background-color: transparent;
      width: 100%;
      padding: 0 20px;
      .title{
        position: relative;
      }
      
    }
  }
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
