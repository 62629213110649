/*
 * @Author       : Hugo
 * @Date         : 2020-12-17 10:04:18
 * @LastEditTime : 2020-12-17 10:16:02
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/test.js
 * @Description  : 测试用api
 * @^_^          : 
 */
import vue from '@/main.js';

const TEST_USER_ID  = [
  22,
  106,
  2923,
  1389,
  4360,
  4361,
  4362,
  4363,
  4374,
  4370,
  4369,
  94,
  1,
  3,
  4377,
  22,
]

const test = {
  /**
   * 是否是测试用户
   * @return 
   */
  isTestUser(){
    let is_test_user = false;
    let userid = vue.$tools.getUserId();
    userid = parseInt(userid);
    is_test_user = TEST_USER_ID.includes(userid);
    return is_test_user;
  },
}

export default test;