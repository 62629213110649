<template>
    <div class="template">
        <div class="tel" v-if="show">
            <ul>
                <li>
                    <p>密 &nbsp; &nbsp; &nbsp; &nbsp;码:</p>
                    <div class="hint_group">
                        <input
                          :type="input_type"
                          placeholder="请输入密码"
                          v-model="password"
                          name="new_pwd"
                          maxlength="8"
                        />
                        <img
                          src="../../../assets/icon-eye.png"
                          alt=""
                          v-show="(password || repetition) && !show_pwd"
                          @click="show_pwd=true;"
                          class="cp"
                        >
                        <img
                          src="../../../assets/icon-sel.png"
                          alt=""
                          v-show="(password || repetition) && show_pwd"
                          @click="show_pwd=false;"
                          class="cp"
                        >
                        <div class="hint">请输入6-8位的数字或字母</div>
                    </div>
                </li>
                <li>
                    <p>重复密码:</p>
                    <div>
                        <input
                          :type="input_type"
                          placeholder="请重复输入密码"
                          name="repetition"
                          v-model="repetition"
                          maxlength="8"
                        />
                        <!-- <img src="../../../assets/icon-sel.png" alt="" v-show="repetition"> -->
                    </div>
                </li>
                <li class="agreement">
                    <h1 @click="selsctShow"><img src="../../../assets/icon-nor.png" v-show="selsct"></h1>
                    <h1 @click="selsctShow"><img src="../../../assets/icon-dui.png" v-show="!selsct"></h1>
                    <p>我已阅读并同意
                        <a @click.prevent="agreementShow('service_agreement')" href>用户服务协议</a>
                        &nbsp;
                        <a @click.prevent="agreementShow('privacy_policy')" href>用户隐私政策</a>
                    </p>
                </li>
            </ul>
            <span v-html="Warn"></span>
            <div class="btn">
                <button @click="nextstep" class="low">下一步</button>
                <button @click="cancelRegister" class="cancel">取消注册</button>
            </div>
        </div>
        <div class="protocol" v-else>
            <!-- <h2>用户注册及免责协议</h2> -->
            <div class="content">
                <div class="contentArea">
                  <service-agreement
                    v-if="agreement_type == 'service_agreement'"
                  ></service-agreement>
                  <privacy-policy
                    v-if="agreement_type == 'privacy_policy'"
                  ></privacy-policy>
                </div>
            </div>
            <div class="continue">
                <button @click="continueShow">继 续</button>
            </div>
        </div>
    </div>
</template>
<script>
import PrivacyPolicy from '@/components/Clause/PrivacyPolicy.vue';
import ServiceAgreement from '@/components/Clause/ServiceAgreement.vue';

export default {
  components: {
    PrivacyPolicy,
    ServiceAgreement,
  },
  data() {
      return {
          selsct:true,
          show:true,
          password:"",
          repetition:"",
          Warn:"",
          show_pwd: false,
      }
  },
  computed:{
    input_type(){
      let input_type = this.show_pwd ? 'text' : 'password';
      return input_type;
    },
  },
  watch: {
    password: function(val){
      if(val){
        val = val.replace(/[^\w]/g,'');
        this.password = val;
      }
    },
    repetition: function(val){
      if(val){
        val = val.replace(/[^\w]/g,'');
        this.repetition = val;
      }
    },
  },
  methods: {
    cancelRegister(){
        this.$router.back()
    },
    selsctShow(){
        this.selsct = !this.selsct
    },
    nextstep(){
      if(this.password == '' && this.repetition == ''){
        this.Warn = '请输入密码';
        return;
      }
      let {password, repetition} = this;
      if(password.length<6){
        this.Warn = '请输入6-8位的数字或字母';
        return;
      }
        if(this.password === this.repetition && !this.selsct && this.password.length){
            this.Warn = ''
            this.$emit("myevent",2)
            this.$emit("pass",this.password)
        }else if(this.password == this.repetition && this.password.length){
            this.Warn = '请阅读并选择同意协议'
        }else{
            this.Warn = '两次密码输入不一致'
        }
    },
    agreementShow(type){
      this.agreement_type = type;
      let docName = '';
      if(type == 'service_agreement'){
        docName = '用户服务协议';
      }
      if(type == 'privacy_policy'){
        docName = '用户隐私政策';
      }
      this.show = false;
      this.$emit("myevent",false, docName)
    },
    continueShow(){
        this.show = true;
        this.$emit("myevent",true)
    },
  },
}
</script>
<style lang="less" scoped>
.iframe{
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 0;
    // background: rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0);

  }
}
li{
  list-style: none;
}
.hint_group{
  position: relative;
  &:hover .hint{
    opacity: 1;
  }
  .hint{
    position: absolute;
    width: 100%!important;
    left: 0;
    font-size: 12px;
    color: #999;
    bottom: -60px;
    border-bottom: none!important;
    margin:0!important;
    transition: all 0.2s;
    opacity: 0;
  }
}
    .template{
        width:  1200px;
        height: 550px;
        .tel{
            width: 415px;
            height: 500px;
            margin: 0 auto;
            padding-top: 50px;
            ul{
                float: left;
                display: block;
                width: 100%;
                // background: #000;
                flex-wrap: wrap;
                li{
                    width: 100%;
                    height: 97px;
                    p{
                        float: left;
                        height: 97px;
                        font-size: 18px;
                        line-height: 97px;
                        color: #0054a6;
                        margin-right: 31px;
                    }
                    div{
                        float: right;
                        margin: 25px 0;
                        width: 300px;
                        height: 47px;
                        border-bottom: 1px solid #d5d9db;
                        input{
                            float: left;
                            border: 0;
                            outline: none;
                            height: 19px;
                            margin-top: 14px;
                            font-size: 18px;
                            color: #83929c;
                        }
                        img{
                            float: right;
                            width: 18px;
                            height: 12px;
                            margin: 17px 0;
                        }
                    }
                }
                .agreement{
                    width: 100%;
                    height: 97px;
                    img{
                        float: left;
                        display: block;
                        width: 18px;
                        height: 18px;
                        margin: 42px 10px 0 0;
                        color: #000;
                        z-index: 12;
                    }
                    p{
                        float: left;
                        display: block;
                        height: 97px;
                        margin: 0;
                        font-size: 16px;
                        line-height: 97px;
                        color: #333;
                        a{
                            text-decoration: underline;
                            color: #0054a6;
                        }
                    }
                }
            }
            span{
                float: left;
                width: 100%;
                height: 15px;
                margin: 10px 0 43px;
                font-size: 16px;
                line-height: 15px;
                text-align: left;
                color:#d82e0d;
            }
            .btn{
                float: left;
                width: 100%;
                height: 50px;
                button:nth-of-type(1){
                    float: left;
                    width: 200px;
                    height: 50px;
                    background: rgba(0, 84, 166, 1);
                    border: 0;
                    color: #ffffff;
                    font-size: 18px;
                    border-radius: 5px;
                    margin-right: 15px;
                }
                .low:hover{
                    background:#004080;
                }
                button:nth-of-type(2){
                   float: left;
                    width: 200px;
                    height: 50px;
                    background: #e6eef6;
                    font-size: 18px;
                    border: 0;
                    border-radius: 5px;
                    color: rgba(0, 84, 166, 1);
                }
                 .cancel:hover{
                    background: #ccdded;
                }
            }
        }
        .protocol{
            width: 1200px;
            height: 558px;
            .content{
                width: 1160px;
                height: 426px;
                padding: 20px;
                .contentArea{
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    // ul{
                    //     width: 100%;
                    //     h2{
                    //         width: 100%;
                    //         font-size: px;
                    //         line-height: px;
                    //         color: #;
                    //     }
                    //     h3{
                    //         width: 100%;
                    //         font-size: px;
                    //         line-height: px;
                    //         color: #;
                    //         margin-top: px;
                    //     }
                    //     li{
                    //         width: 100%;
                    //         font-size: px;
                    //         line-height: px;
                    //         color: #;
                    //     }
                    // }
                    img{
                        width: 100%;
                    }
                }
                .contentArea::-webkit-scrollbar {
                   display: none; /* Chrome Safari */
                }
            }
            .continue{
                width: 1200px;
                height: 90px;
                border-top: 2px solid #f0f0f0;
                button{
                    width: 200px;
                    height: 50px;
                    margin: 20px 0 0 500px;
                    background: #0054a6;
                    border: 0;
                    border-radius: 5px;
                    font-size: 18px;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                }
            }
        }
    }
</style>
