var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canShow
    ? _c(
        "div",
        {
          staticClass:
            "c_teaching_package_selected_task_list_thumbnail_task ml-5 mr-10 mb-10",
        },
        [
          _c("thumbnail-task", {
            attrs: {
              "p-task": _vm.pTask,
              "can-select": true,
              selected: _vm.pTask.selected,
            },
            on: { handleSelected: _vm.handleSelected },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }