<template>
  <div
    class="hyperlink_sketch_list"
  >
    <div class="list"
     
    >
      <template
        v-for="(item, index) in list"
      >
        <div class="item"
          :key="index"
          v-if="index < 5"
          :class="{ value :item.value}"
          @click="openHyperlink(item)"
        >{{item.name || item.value}}</div>
      </template>
      <div class="more"
        v-if="is_more && !switch_more"
        @click="switch_more=true;"
      >更多</div>
      <template
        v-if="is_more && switch_more"
      >
        <template
          v-for="(item, index) in list"
        >
          <div
            :key="index"
            v-if="index >= 5"
          >
            <div class="item"
              :class="{ value :item.value}"
              :key="index"
              @click="openHyperlink(item)"
            >{{item.name || item.value}}</div>
          </div>
        </template>
      </template>
      
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  data(){
    return {
      list: [],
      switch_more: false,
    }
  },
  computed: {
    is_more () {
      const {list} = this;
      if(list && list.length >5){
        return true;
      }
      return false;
    },
  },
  methods: {
    getList(){
      const {msg} = this;
      const {content} = msg;
      let list = [];
      if(content){
        //新浪,,https://www.sina.com||百度,,https://www.baidu.com
        const item_split = content.split('||');
        if(item_split){
          item_split.forEach(unit=>{
            const obj_split = unit.split(',,');
            list.push({
              name: obj_split[0],
              value: obj_split[1],
            })
          })
        }
        this.$set(this, 'list', list);
      }

    },
    async init(){
      await this.getList();
    },
    openHyperlink(item){
      const {value} = item;
      if(value === ''){
        return;
      }
      let link = '';
      if(value.indexOf('http://') == -1 && value.indexOf('https://') == -1){
        link = `http://${item.value}`;
      }else{
        link = value;
      }
      window.open(link, '_blank');
    }
  },
  mounted() {
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.hyperlink_sketch_list{
  .list{
    font-size: 14px;
    line-height: 20px;
  }
  .item{
    padding-bottom: 5px;
    color: #555;
    &.value{
      text-decoration: underline;
      color: $main_blue;
    }
  }
  
}
</style>