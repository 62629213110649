var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_classes_task_index d-flex flex-column", style: {} },
    [
      _c("fun-bar", {
        staticClass: "fun_bar flex-shrink-0",
        attrs: {
          data: _vm.data,
          backup: _vm.backup,
          large_task: _vm.large_task,
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex main_group align-stretch" },
        [
          _c("main-component", {
            ref: "main",
            staticClass: "main",
            attrs: {
              data: _vm.data,
              backup: _vm.backup,
              large_task: _vm.large_task,
              task: _vm.task,
              taskDetails: _vm.taskDetails,
            },
          }),
          _c("side-bar", {
            staticClass: "flex-shrink-0 side_bar",
            attrs: {
              large_task: _vm.large_task,
              data: _vm.data,
              backup: _vm.backup,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }