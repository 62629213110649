import vue from '@/main.js';
import OSS from 'ali-oss';
import tools from '../tools';
class Oss{
  constructor(){
    this.client = null;
    this.client_list = {};
  }

  /**
   * 获取授权信息
   * @returns
   */
  async getStsInfo(){
    // const sts_info = vue.$tools.getCookie('sts_info');
    const res = await vue.$global.doPost({
      url:'project/fileUploadAccount',
      notice: '获取STS授权失败',
    })
    if(res.contents){
      const { AccessKeyId,AccessKeySecret,SecurityToken,expirationTime } = res.contents;
      vue.$tools.setCookie(
        'sts_info',
        { AccessKeyId,AccessKeySecret,SecurityToken,expirationTime }
      )
      return { AccessKeyId,AccessKeySecret,SecurityToken,expirationTime }
    }

  }


  async setClient(bucket){
    let sts_info = vue.$tools.getCookie('sts_info');
    if(!sts_info){
      sts_info = await this.getStsInfo();
    }else{
      const {expirationTime} = sts_info;
      // // console.log(expirationTime);
      // // console.log(new Date().getTime());
      if(expirationTime <= new Date().getTime()){
        sts_info = await this.getStsInfo();
      }
    }
    const {AccessKeyId,AccessKeySecret,SecurityToken,expirationTime} = sts_info;

    this.client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: 'oss-cn-beijing',
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: AccessKeyId,
      accessKeySecret: AccessKeySecret,
      endpoint: `${location.protocol}//oss-cn-beijing.aliyuncs.com`,
      // 从STS服务获取的安全令牌（SecurityToken）。
      secure: true,
      stsToken: SecurityToken,
      // 填写Bucket名称。
      bucket,
    });
    return this.client;
  }


  getBukcet(type){
    let bucket;
    switch(type){
      case 'avatar':
        bucket = 'utec-img-icon';
        break;
      case 'picture':
        bucket = 'utec-img-content';
        break;
      case 'file':
        bucket = 'utec-mixture-attach';
        break;
      default:
        bucket = 'utec-mixture-attach';
        break;
    }
    return bucket;
  }


  generateName(params){
    const {file} = params;
    const extname = tools.getExtName(file.name);
    const time_stamp = vue.$moment().format('YYYY/MM/DD/x');
    const prefix = process.env.VUE_APP_OSS_PREFIX;
    const randomLetter = tools.generateRandomLetter({type: 'lowercase', num: 6});
    const name = `${prefix}${time_stamp}${randomLetter}.${extname}`;
    // // console.log(name);
    return name;
  }
  /**
   *
   * @param {*} params type: 'avatar / picture / file'
   * @returns
   */
  async upload(params){
    const {file, handleProgress, type} = params;
    let bucket = this.getBukcet(type);
    let client = await this.setClient(bucket);
    this.client = client;
    // let tempCheckpoint;
    const name = params.name || this.generateName({file});

    this.client_list[name] = client;



    // 定义上传方法。
    async function multipartUpload () {
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或目录（例如exampledir/exampleobject.txt）的形式，实现将文件上传到当前Bucket或Bucket中的指定目录。

        const result = await client.multipartUpload(name, file, {
          // progress: function (p, checkpoint) {
          //   // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          //   // console.log(p);
          //   // tempCheckpoint = checkpoint;
          // },
          progress: handleProgress,
          // meta: { year: 2020, people: 'test' },
          mime: file.type,
          // mime: 'text/plain',
        })
        return true;
      } catch(e){
        // console.log(e);
        return false;
      }

    }

    // 开始分片上传。
    const res = await multipartUpload();
    // // console.log(res);
    if(res){
      delete this.client_list[name];
      // // console.log(this.client_list);
      return name;
    }
    // // console.log('res');
    return res;

  }

  /**
   * 暂停上传/取消上传
   */
  cancelUpload(){
    if(this.client){
      this.client.cancel();
    }
    /**
     * TODO 删除上传一半的文件
     */
  }

  cancelAll(){
    const {client_list} = this;
    for(let i in client_list){
      client_list[i].cancel();
    }
    this.client_list = {};
  }

  /**
   * 获取下载url
   * @param {*} params
   */
  async downloadUrl(params){
    const {file, type} = params;
    let bucket = this.getBukcet(type);
    let client = await this.setClient(bucket);

    console.log({bucket, client, file})


    const {filename, url,} = file;
    const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)


    // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
    // const filename = 'examplefile.txt' // 自定义下载后的文件名。
    let content_disposition = '';
    // if(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)){
    //   content_disposition = `attachment; filename*=utf-8''${filename}`
    // }else{
    // content-dispositio%E6%85%A7.pdf
    //   content_disposition = `attachment;filename*=UTF-8''${encodeURIComponent(filename)}`
    content_disposition = `attachment;filename*=UTF-8''${encodeURIComponent(filename)}`
    if (isSafari) {
      content_disposition = `attachment;filename*=UTF-8''${filename}`
    }
    // }
    const response = {
      'content-disposition': content_disposition
      // 'content-disposition': `attachment; filename=${escape(filename)};filename*=utf-8'${escape(filename)}'`

      // response.setHeader("content-disposition","attachment;filename*=UTF-8''" +new String(pf.getFilename().getBytes(), "ISO8859-1"));
    }
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
    const download_url = client.signatureUrl(url, { response });
    return download_url;

  }


}

const oss = new Oss();

export default oss;
