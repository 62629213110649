<template>
  <div
    class="pop_up_dilaog_send_files_index d-flex flex-column"
  >
    <div class="container bbox">
      <files-list
        :dialog="dialog"
      ></files-list>
    </div>
    <progress-component
      v-if="dialog.uploadingFile"
      :dialog="dialog"
    ></progress-component>
    <div
      v-if="!dialog.uploadingFile"
      class="operation_bar d-flex align-center justify-end flex-shrink-0"
    >
      <div
        class="button btn_light"
        @click.stop="$emit('handleClose')"
      >
        取消
      </div>
      <div
        class="button btn_dark"
        @click.stop="doSend"
      >
        发送
      </div>
    </div>
  </div>
</template>

<script>
import FilesList from '@/components/PopUp/Dialog/SendFiles/FilesList'
import ProgressComponent from '@/components/PopUp/Dialog/SendFiles/Progress'
export default {
  name: 'index',
  components: {
    ProgressComponent,
    FilesList
  },
  props: {
    dialog: {
      type: Object,
      default: undefined
    }
  },
  mounted () {
  },
  methods: {
    doSend() {
      this.dialog.autoUpload({
        handleSuccess: () => {
          this.$emit('handleClose')
        },
        handleEmpty: () => {
          this.$emit('handleClose')
          this.$notice({ desc:'请选择图片后再发送' })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pop_up_dilaog_send_files_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  .container{
    @include scrollbar;
    width: 100%;
    flex: 1;
    background-color: #f4f4f4;
    overflow: auto;
    padding: 10px 0;
  }
  .operation_bar{
    width: 100%;
    padding: 10px 0;
    .button{
      font-size: 14px;
      margin-right: 10px;
      line-height: 30px;
      border-radius: 4px;
      padding: 0 20px;
    }
  }
}
</style>