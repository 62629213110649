/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-08 18:13:20
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 18:15:57
 * @FilePath     : /miaohang/src/router/modules/card_page.js
 * @Description  : 
 */
const card_page = [
  {
    path: '/card_page',
    name: 'card_page',
    component: () => import('@/views/ActivePage/index.vue'),
    children: [
      /**接收礼品卡 */
      {
        path: 'invitation',
        name: 'card_page_invitation',
        component: () => import('@/views/CardPage/invitation/index.vue'),
        meta: {allowBack: false},
      }
    ]
  }
]

export default card_page;