/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-17 23:05:02
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 11:57:19
 * @FilePath     : /miaohang/src/types/index.js
 * @Description  : 
 */

import PTask from './modules/PTask/index.js'
import PTaskLog from './modules/PTaskLog/index.js'
import PFile from './modules/PFile/index.js'
import PChatDetail from './modules/PChatDetail/index.js'
import PChat from './modules/PChat/index.js'
import PMessageBox from './modules/PMessageBox/index.js'
import UserInfo from './modules/Userinfo/index.js'
import POrder from './modules/POrder/index.js'
import PRotation from './modules/PRotation/index.js'
import PSelected from './modules/PSelected/index.js'
import PPackage from './modules/PPackage/index.js'
import PUserCode from './modules/PUserCode/index.js'
import PTaskDetail from './modules/PTaskDetail/index.js'
import PScreen from './modules/PScreen/index.js'
import PTaskReadUser from './modules/PTaskReadUser/index.js'
import PTag from './modules/PTag/index.js'
import PScreenTask from './modules/PScreenTask/index.js'
import PFolder from './modules/PFolder/index.js'
import PFolderTask from './modules/PFolderTask/index.js'
import PEnquire from './modules/PEnquire/index.js'
import PGift from './modules/PGift/index.js'
import UserInfoSimplePmcs from './modules/UserInfoSimplePmcs'
import PCard from './modules/PCard'
import PCardUser from './modules/PCardUser/index.js'
import PVipRecord from './modules/PVipRecord/index.js'


function listForClasses(params) {
  const {
    list,
    classes,
    defaultParams // 默认参数
  } = params || {}
  if(!list){
    return []
  }
  if(list.length === 0){
    return []
  }
  if(!classes){
    return list
  }
  const classes_list = []
  list.forEach(item=>{
    const new_classes = new classes(
      Object.assign(
        {},
        item,
        defaultParams
      )
    )
    classes_list.push(new_classes)
  })
  return classes_list
}

const Types = { listForClasses,PTask, PTaskLog, PFile, PChatDetail, PChat, PMessageBox, UserInfo, POrder, PRotation, PSelected, PPackage, PUserCode, PTaskDetail, PScreen, PTaskReadUser, PTag, PScreenTask, PFolder, PFolderTask, PEnquire, PGift, UserInfoSimplePmcs, PCard, PCardUser, PVipRecord }

export { listForClasses,PTask, PTaskLog, PFile, PChatDetail, PChat, PMessageBox, UserInfo, POrder, PRotation, PSelected, PPackage, PUserCode, PTaskDetail, PScreen, PTaskReadUser, PTag, PScreenTask, PFolder, PFolderTask, PEnquire, PGift, UserInfoSimplePmcs, PCard, PCardUser, PVipRecord }

export default Types;
