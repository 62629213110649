/*
 * @Author       : Hugo
 * @Date         : 2022-03-07 10:42:53
 * @LastEditTime : 2022-03-30 12:06:40
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PScreen/index.js
 * @Description  : 
 * @^_^          : 
 */
import {PScreenTask} from '@/types'
import types from '@/utils/types'
class PScreen{
  id         = undefined;
  screenNo   = undefined;
  screenName = undefined;
  createTime = undefined;
  useflag    = undefined;
  screenTask = undefined;
  // 自定义字段
  cf_selected = undefined;

  constructor(params = {}){
    const {
      id,
      screenNo,
      screenName,
      createTime,
      useflag,
      screenTask,
    } = params;

    this.id         = id;
    this.screenNo   = screenNo;
    this.screenName = screenName;
    this.createTime = createTime;
    this.useflag    = useflag;
    this.screenTask = new PScreenTask(screenTask);
  }

}

export default PScreen;