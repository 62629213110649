var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_collect_folder_index bbox" }, [
    _c("div", { staticClass: "title_group flex-shrink-0 bbox" }, [
      _vm._v("分组"),
    ]),
    _c(
      "div",
      {
        ref: "list",
        staticClass:
          "list_group bbox d-flex flex-wrap align-start justify-start",
      },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            _c("folder-unit", {
              key: index,
              staticClass: "mr-10 mb-5",
              attrs: {
                p_folder: item,
                preview: _vm.preview,
                show_menu: _vm.show_menu,
                call: _vm.call,
                current_folder: _vm.current_folder,
                disable_select: _vm.disable_select,
                switch_select: _vm.switch_select,
              },
              on: {
                handleRemove: _vm.handleRemove,
                handleStartSelect: _vm.handleStartSelect,
              },
            }),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }