var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("payment-status", {
            attrs: {
              title: _vm.title,
              close: _vm.close,
              jump: _vm.jump,
              jump_second: _vm.jump_second,
              jump_name: _vm.jump_name,
              jump_router_name: _vm.jump_router_name,
              type: _vm.type,
              status: _vm.status,
            },
            on: { handleClose: _vm.handleClose },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }