var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cate" }, [
    _c("div", { staticClass: "cate-left" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "tupian",
          style: _vm.disabled
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(1)
            },
          },
        },
        [
          _vm.disabled
            ? _c("img", {
                staticClass: "book",
                attrs: {
                  src: require("../../assets/icon-message.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "book",
                attrs: { src: require("../../assets/book.png"), alt: "" },
              }),
        ]
      ),
      _c("div", { staticClass: "bok" }),
      _c(
        "div",
        {
          staticClass: "pple",
          style: _vm.people
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(2)
            },
          },
        },
        [
          _vm.people
            ? _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-sel.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mpa",
          style: _vm.mpashow
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(3)
            },
          },
        },
        [
          _vm.mpashow
            ? _c("img", {
                staticClass: "mplae",
                attrs: { src: require("../../assets/modulex.png"), alt: "" },
              })
            : _c("img", {
                staticClass: "mplae",
                attrs: {
                  src: require("../../assets/icon-Template-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c("img", { staticClass: "phical", attrs: { src: "", alt: "" } }),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("../../assets/logo.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pages-con" }, [
      _c("div", { staticClass: "bar" }, [
        _c("div", { staticClass: "barinput" }, [
          _c("input", {
            staticClass: "hder",
            attrs: { type: "text", placeholder: "搜索" },
          }),
          _c("img", {
            staticClass: "search",
            attrs: { src: require("../../assets/icon-search.png"), alt: "" },
          }),
        ]),
        _c("div", { staticClass: "imgbox" }, [
          _c("img", {
            staticClass: "jia",
            attrs: { src: require("../../assets/icon-add.png"), alt: "" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "contact" }, [
        _c("p", { staticClass: "conta" }, [_vm._v("联系人")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }