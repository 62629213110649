/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 10:21:51
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-24 16:15:55
 * @Description  : 公共方法
 * @FilePath     : /miaohang/src/api/common.js
 */ 
import axios from "axios";
import config from "@/api/config/config";
import VueCookies from 'vue-cookies';
import vue from '@/main.js';

const common = {
  stopPropagation(e){
    e.stopPropagation();
    // console.log('阻止冒泡');
  },
  handleKeydownChinese(e){
    let status = false;
    if(e.keyCode == 229){
      status = true;
    }
    return status;
  },
  
  /**
   * 打开添加联系人弹窗
   */
  common_open_add_friend_box(){
    vue.$store.commit('open_add_friend_box', true);
  },
  /**
   * 关闭添加好友弹窗
   */
  common_close_add_friend_box(){
    vue.$store.commit('close_add_friend_box');
  },
  /**
   * 打开或关闭拉人到群聊
   * @param {Boolean} isopen 开启(默认)/关闭
   */
  common_set_add_contact_to_dialog_box(isopen = true){
    vue.$store.commit('set_add_contact_dialog_box_type', '');
    vue.$store.commit('set_add_contact_to_dialog_box', isopen);
  },
  /**
   * 开启/关闭沟通记录
   * @param {Boolean} open 是否开启
   */
  common_set_communication_record_box(open = true){
    // console.log('common_set_communication_record_box')
    vue.$store.commit('set_communication_record_box', open);
    // vue.$openCommunicationRecord()
  },
  /**
   * 跳转到单聊会话
   * 需要提前查询是否已有会话 没有则创建
   * @param {Object} userdata 用户信息
   */
  common_goToSingleDialog(userdata){
    let chat_id = userdata.userId;
    let message_box_list = vue.$store.state.message_box_list;
    let has = message_box_list.find(item=>{
      return item.chatId == chat_id;
    })
    if(has){
      // 已有会话 直接跳转
      vue.$router.push({name:'fun_task_dialog_single', params: {id: vue.$tools.btoa(chat_id)}});
    }else{
      // 创建新会话 然后跳转
      let message_box = {
        chatId: chat_id,
        chatType: 0,
        lastSendTime: new Date().getTime(),
        masterUserid: vue.$tools.getUserId(),
        messageContent: '',
        messageContentType: null,
        name: userdata.friendRemark || userdata.userName,
        photo: userdata.thumbnail,
        unreadMessageNum: 0,
      }
      vue.$store.commit('set_message_box_unit',message_box );
      let not_top_index = message_box_list.findIndex( item => {
        return !item.istop;
      })
      if(not_top_index != -1){
        message_box_list.splice(not_top_index, 0, message_box);
      }else{
        message_box_list.unshift(message_box);
      }
      // message_box_list.unshift(message_box);
      vue.$store.commit('set_message_box_list', message_box_list);
      vue.$nextTick(function(){
        vue.$router.push({name:'fun_task_dialog_single', params: {id: vue.$tools.btoa(chat_id)}});
      })
    }
  },
  /**退出登录 */
  common_logout(){
    vue.$tools.setCookie('nologin', false);
    vue.$tools.removeCookie('loginUserCode')
    vue.$tools.removeCookie('user');
    vue.$tools.removeCookie('autologin');
    VueCookies.remove('user');
    VueCookies.remove('loginUserCode');
    VueCookies.remove('miaohangUsername');
    // console.log('common_logout 跳转登录')
    vue.$router.push({
      name:
        'login_sign'
    })
    vue.$store.dispatch('imLogout');
  },
  /** ********************* */
  /**登录 */
  /**
   * 
   * @param {String} username 
   * @param {String} password 
   * @param {boolean} autologin 自动登录
   * @param {boolean} init_data 是否初始化数据(重新登录不初始化)
   */
  async doLogin(username, password, autologin, init_data=true){
    username = String(username);
    password = String(password);
    // // console.log(password);
    if(password == 'null'){
      vue.$router.push({name:'login_sign'});
      return;
    }
    let data = {
      username,
      password: password,
      logintype: 4,
    }
    vue.$tools.setCookie('nologin', false);
    let res = await vue.$http.request('/project/loginAuth', data);
    console.log(res)
    if(init_data){
      vue.$store.commit('loginInit');
    }
    if ( res.message === "success") { 
      // 判断是否激活 , 如未激活则将用户信息返回 同时不存储用户信息在cookie
      const { contents } = res
      const { user } = contents
      const { usercode } = user
      if (!usercode) {
        vue.$tools.setCookie('password', data.password) // 存储密码
        vue.$store.commit('set_password', data.password)
        /** 清除cookie  */
        // console.log('清除cookie')
        // vue.$cookies.remove('loginUserCode')
        // vue.$cookies.remove('user')
        vue.$tools.removeCookie('loginUserCode')
        vue.$tools.removeCookie('user')
        vue.$tools.removeCookie('autologin')
        for(let i in res.contents){
          // vue.$cookies.set(i,res.contents[i])
          vue.$tools.setCookie(i, res.contents[i])
        }

        return {
          identification_code: true, // 需要进入识别码界面
          contents,
        }
      }
      if (autologin) {
        for (let i in res.contents) {
          // vue.$cookies.set(i,res.contents[i]);
          vue.$tools.setCookie(i, res.contents[i])
        }
        vue.$tools.setCookie('password', data.password) // 存储密码
        vue.$tools.setCookie('autologin', true) // 自动登录
      }else{
        vue.$tools.setCookie('password', data.password) // 存储密码
        vue.$store.commit('set_password', data.password)
        /** 清除cookie  */
        // console.log('清除cookie')
        // vue.$cookies.remove('loginUserCode')
        // vue.$cookies.remove('user')
        vue.$tools.removeCookie('loginUserCode')
        vue.$tools.removeCookie('user')
        vue.$tools.removeCookie('autologin')
        for (let i in res.contents) {
          // vue.$cookies.set(i,res.contents[i])
          vue.$tools.setCookie(i, res.contents[i])
        }
        /** 存储在sessionStorage中 */
        // if(typeof(Storage)!=="undefined"){
        //     // 是的! 支持 localStorage  sessionStorage 对象!
        //     // 一些代码.....
        //   sessionStorage.setItem('loginUserCode', res.contents.loginUserCode)
        //   sessionStorage.setItem('user', JSON.stringify(res.contents.user))
        // } else {
        //     // 抱歉! 不支持 web 存储。
        // }
      }
      vue.$store.commit('set_login_user_id', res.contents.user.userId)
      vue.$store.dispatch('imLogin')
      return true
    } else {
      // console.log(res.describe)
      return res
    }
  },
  /**
   * 获取用户信息
   * @param {Object}} params 
   */
  async getUserData(params){
    let {userId} = params || {};
    if(userId){
      let res = await vue.$http.request('/project/getUserById', {userId});
      return res;
    }else{
      return ;
    }
  },
  /**
   * 更改用户昵称需要同步调整的数据
   * @param {Object} params {friendRemark: 用户名, userId: 用户id}
   */
  async changeUserFriendRemark(params){
    let {friendRemark, userId} = params || {};
    let {dialog_user_list, message_box_list, message_list, chat_user_list} = vue.$store.state; //{dialog_user_list: 会话联系人列表, message_box_list: 消息盒子, message_list: 消息列表, chat_user_list: 工作组用户列表}
    
    // if(dialog_user_list){
    //   let list = dialog_user_list;
    //   let index = list.findIndex(item=>{
    //     return item.userId == userId;
    //   })
    //   if(index != -1){
    //     list[index].friendRemark = friendRemark;
    //     vue.$store.commit('set_dialog_user_list', list);
    //   }
    // }
    changeDataRemark({list:dialog_user_list, type: 'dialog_user_list'});
    changeDataRemark({list:message_list, type: 'message_list'});
    changeDataRemark({list:message_box_list, type: 'message_box_list'});
    changeDataRemark({list:chat_user_list, type: 'chat_user_list'});

    function changeDataRemark(data){
      let {list, type} = data;
      if(list){
        let index = list.findIndex(item=>{
          if(type == 'message_list'){
            return item.fromUserId == userId;
          }else if(type == 'message_box_list'){
            return item.updateUserId == userId;
          }else{
            return item.userId == userId;
          }
        })
        if(type == 'message_box_list'){
          // 消息盒子单聊 并且 是更改用户
          let single_dialog_index = list.findIndex( item => {
            return item.chatId == userId && item.chatType == 0;
          })
          if(single_dialog_index != -1){
            list[single_dialog_index].name = friendRemark;
          }
        }
        if(index != -1){
          if(type == 'message_list'){
            list[index].userName = friendRemark;
          }else if(type == 'message_box_list'){
            if(list[index].chatType == '1'){
              list[index].updateUserName = friendRemark;
            }
            if(list[index].chatType == '0'){
              list[index].name = friendRemark;
            }
          }else{
            list[index].friendRemark = friendRemark;
          }
          vue.$store.commit(`set_${type}`, list);
        }
      }
    }

  },
  /**
   * 更改群聊名称
   * @param {Object} params {chatName: 名称, chatId: 群聊id}
   */
  async changeGroupName(params){
    // // console.log(params);
    let {chatName, chatId} = params || {};
    let {message_box_list, workteam_list} = vue.$store.state; //{ message_box_list: 消息盒子, , workteam_list: 工作组列表}
    modGroupName({list:workteam_list, type: 'workteam_list'});
    modGroupName({list:message_box_list, type: 'message_box_list'});

    function modGroupName(data){
      // // console.log(data);
      let {list, type} = data;
      if(list){
        let index = list.findIndex(item=>{
          if(type == 'message_box_list'){
            return item.updateUserId == chatId && item.chatType == 1;
          }else{
            return item.chatId == chatId ;
          }
        })
        if(type == 'message_box_list'){
          // 消息盒子单聊 并且 是更改用户
          let message_box_list_index = list.findIndex( item => {
            return item.chatId == chatId && item.chatType == 1;
          })
          if(message_box_list_index != -1){
            list[message_box_list_index].name = chatName;
          }
        }
        if(index != -1){
          if(type == 'message_box_list'){
            list[index].name = chatName;
          }else{
            list[index].chatName = chatName;
          }
          vue.$store.commit(`set_${type}`, list);
        }
      }
    }
  },
  /**判断当前用户是否在任务用户列表中, 或者是新建的任务或taskType == 2 */
  canEditTask(){
    let {task_data, task_preview} = vue.$store.state;
    let {tousers, taskType, id, chatType, signUserId } = task_data || {};
    let tousers_arr = [];
    if(tousers){
      tousers_arr= tousers.split(',');
    }
    let userid = vue.$tools.getUserId();
    // // console.log(taskType)
    // // console.log(tousers, taskType, id, tousers_arr, userid);
    if(task_preview){
      return false;
    }
    if(tousers_arr.indexOf(userid) != -1){
      // 当前用户在群聊中
      return true;
    }else if(taskType == 2 || taskType == 4){
      // 范文 / 实例拍照
      return true;
    }else if(!id){
      // 新建
      return true;
    }else if(chatType == 0){
      // 单聊
      return true;
    }else if(task_data.bindTeach){
      // 绑定了teachId
      return true;
    }else{
      // vue.$toast({icon:'none',title:'错误',desc:'您没有编辑权限',duration:1000});
      return false;
    }
  },
  /** 可编辑权限(直接弹窗)*/
  editTaskAuthorityBox(){
    const {task_see_type} = vue.$store.state;
    // task_see_type == 1 欢迎消息
    if(!this.canEditTask() && task_see_type != 1){
      // vue.$toast({icon:'none',title:'错误',desc:'您没有编辑权限',duration:1000});
      vue.$notice({desc:'您不是该模板参与者，无法进行修改'})
      return false;
    }
    return true;
  },
  /**
   * 刷新当前登录的用户信息
   */
  async refreshLoginUserInfo(){
    
    // console.log('refreshLoginUserInfo')
    let res ;
    try{
      res = await this.getUserData({userId: vue.$tools.getUserId()});
      if(res){
        // console.log(res);
        vue.$tools.removeCookie('user');
        let contents = res.contents;
        let user = contents.user;
        const userinfo = res.contents.user;

        const origin_user = this.$tools.getCookie('user');

        const new_user = Object.assign(
          {},
          origin_user,
          userinfo,
          {
            vipEndDate: origin_user?.vipEndDate || null,
            vipStartDate: origin_user?.vipEndDate || null,
          }
        )
        this.$tools.setCookie('user', new_user);


        // vue.$tools.setCookie('user', new_user);
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return ;
    

  },
  /**
   * 移除对象中某种字段
   * @param {String}} fieldname 需要移除的字段名
   */
  removeAllField(contents, fieldname){
    // // console.log('removeAllField',contents, fieldname)
    if(contents && fieldname != undefined && fieldname != ''){
      contents = clearAllField({contents, fieldname});
    }
    // // console.log('contents', contents)
    return contents;
  },
  /**
   * 是否系统级别用户
   * @param {*} userid 
   */
  isSystemUser(userid){
    let status = false;
    if(userid == 5494){
      status = true;
    }
    return status;
  }
}

/**
 * 移除所有id字段
 * @param {Object}} params {contents:内容对象, fieldname: 字段名}
 */
function clearAllField(params){
  let {contents, fieldname} = params || {};
  let type = getType(contents);
  let obj;
  if(type === 'array'){
    obj = [];
  } else if(type === 'object'){
      obj = {};
  } else {
      //不再具有下一层次
      return contents;
  }
  if(type === 'array'){
    for(var i = 0, len = contents.length; i < len; i++){
      obj.push(clearAllField({contents:contents[i], fieldname}));
    }
  } else if(type === 'object'){
      for(var key in contents){
        if(i != fieldname){
          obj[key] = clearAllField({contents:contents[key], fieldname});
        }
      }
  }
  return obj;
}

/**
 * 判断data类型array/object
 * @param {*} data 
 */
function getType(data){
  let aim = Object.prototype.toString.call(data);
  if(aim === '[object Object]'){
    return 'object';
  }
  if(aim === '[object Array]'){
    return 'array';
  }
  return false;
  // // console.log(Object.prototype.toString.call(a) === '[object Object]');//判断对象 返回true
  // // console.log(Object.prototype.toString.call(b) === '[object Array]');//判断数组 返回t
}


export default common;