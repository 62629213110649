var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "600px", max_height: "460px" } },
    [
      _c(
        "div",
        {
          staticClass:
            "com_shopping_mail_add_to_custom_list_index pb-20 d-flex flex-column",
        },
        [
          _c("common-title", {
            staticClass: "flex-shrink-0",
            attrs: { title: _vm.title, close: "" },
          }),
          _c(
            "div",
            { staticClass: "main bbox d-flex flex-column align-start" },
            [
              _c(
                "div",
                { staticClass: "search_group d-flex px-16 flex-shrink-0" },
                [
                  _c("img", {
                    staticClass: "icon mr-4",
                    attrs: {
                      src: require("@/assets/images/common/search_icon.png"),
                      alt: "",
                    },
                  }),
                  _c("input", {
                    staticClass: "search input",
                    attrs: { type: "text", placeholder: "搜索" },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "create_group d-flex mt-15 px-16 bbox un_sel cp flex-shrink-0",
                },
                [
                  _c("img", {
                    staticClass: "icon mr-4",
                    attrs: {
                      src: require("@/assets/images/shopping_mail/custom_list/create.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v("创建清单")]),
                ]
              ),
              _c(
                "div",
                { staticClass: "list_group mt-10" },
                [
                  _vm._l(20, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "list d-flex justify-space-between bbox px-16 mb-10",
                        },
                        [
                          _c("div", { staticClass: "name_group d-flex" }, [
                            _c("div", { staticClass: "name mr-10" }, [
                              _vm._v("未命名"),
                            ]),
                            _c("img", {
                              staticClass: "edit cp un_sel",
                              attrs: {
                                src: require("@/assets/images/shopping_mail/custom_list/edit.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "operation_group un_sel" }, [
                            _c("div", { staticClass: "btn button disabled" }, [
                              _vm._v("添加"),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }