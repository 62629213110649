<!--
 * @Author       : Hugo
 * @Date         : 2022-03-11 17:00:14
 * @LastEditTime : 2022-04-06 10:41:56
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/ClassesTask/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_classes_task_index d-flex flex-column"
    :style="{
      
    }"
  >
    <fun-bar
      class="fun_bar flex-shrink-0"
      :data="data"
      :backup="backup"
      :large_task="large_task"
    ></fun-bar>
    <div class="d-flex main_group align-stretch">
      <main-component
        class="main"
        :data="data"
        :backup="backup"
        :large_task="large_task"
        :task="task"
        :taskDetails="taskDetails"
        ref="main"
      ></main-component>
      <side-bar
        class="flex-shrink-0 side_bar"
        :large_task="large_task"
        :data="data"
        :backup="backup"
      ></side-bar>
    </div>
  </div>
</template>

<script>

import FunBar from './modules/FunBar/index.vue'
import MainComponent from './modules/Main/index.vue'
import SideBar from './modules/SideBar/index.vue';
export default {
  props: {
    // data: {
    //   type: Object,
    //   default: undefined,
    // },
    backup: {
      type: Object,
      default: undefined,
    },
    large_task: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    FunBar,
    MainComponent,
    SideBar,
  },
  computed: {
    data() {
      const {large_task} = this
      const {data} = large_task
      return data
    },
    readtaskUsers(){
      const {data} = this
      const {readtaskUsers} = data
      return readtaskUsers
    },
    task(){
      const {data} = this
      const {task} = data
      return task
    },
    taskDetails(){
      const {data} = this
      const {taskDetails} = data
      return taskDetails
    },
    taskUsers(){
      const {data} = this
      const {taskUsers} = data
      return taskUsers
    },
  },
  data(){
    return{
    }
  },
  methods: {
    async init(){
    }
  },
  mounted(){
    // this.init();
  }
}
</script>


<style lang="scss" scoped>
.c_classes_task_index{
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color:#fff;
  .main_group{
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: #f8f8f8;
    overflow: hidden;
  }
  .main{
    flex:1;
  }
  .side_bar{
    border-left: 2px solid #f4f4f4;
  }
}
</style>