<template>
  <div class="c_batch_add_contacts_to_tasks_task_list bbox">
    <div class="list_group d-flex flex-wrap bbox">
      <template
        v-for="(item, index) in taskList"
      >
        <thumbnail-task
          :key="index"
          :p-task="item"
          :search="search"
        ></thumbnail-task>
      </template>
    </div>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/PopUp/TeachingPackage/SelectedTaskList/ThumbnailTask'
export default {
  name: 'TaskList',
  components: {
    ThumbnailTask
  },
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    search: {
      type: String,
      default: ''
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  mounted () {
    console.log(this.taskList)
  },
  methods: {
    handleDelete(params) {
      const { pTask } = params
      const { taskList } = this
      const index = taskList.findIndex((unit) => {
        return unit.id === pTask.id
      })
      if (index !== -1) {
        taskList.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c_batch_add_contacts_to_tasks_task_list{
  width: 100%;
  padding: 20px;
  .list_group{
    width: 100%;
  }
}
</style>