import vue from '@/main.js';
import sendTaskFun from './sendTaskFun';

class SendWidget{
  main = async (item, parent)=>{
    // console.log(item, parent);
    if(sendTaskFun.widgetUploaded(item) === false){
      vue.$notice({desc: '有文件正在上传, 请稍候再发布'});
      return false;
    }

    const {task, task_config} = vue.$store.state;
    let data = {
      name: '',
      taskId: task.task.id,
      task_type: task_config.task_type,
      // hide: task_config.privacy ? 1 : 0,
      to_users: sendTaskFun.generateToUsers(),
      controls: sendTaskFun.generateWidget(item, parent),
      chat_type: task_config.chat_type,
      // chat_id: task_config.chat_id,
      chat_id: sendTaskFun.generateChatId(), // 单聊时切换为对方id
      // addtags: task_config.addtags,
      // deletetagIds: task_config.deletetagIds,
      // sortJson: sendTaskFun.generateSortJson(),
      sortJson: '[]',
      locknum: task.task.locknum,
    }
    if(data.controls==='[]'){
      await this.$http.request('/projectTask/loselock', {ids: item.id});
      return false;
    }
    // // console.log(data);
    const res = await vue.$global.doPost({
      url: '/projectTask/editTask',
      data,
      notice: '发布失败',
      preventDuplicateClicks: true,
    })

    return res;

  }
}

const sendWidget = new SendWidget();
export default sendWidget.main;

/**
 * 
feature

name: 
taskId: 6553
task_type: 1
to_users: 4360,4378,4379,4416,22
controls: {"id":51280,"type":1,"remark":"2","content":"33","sort":1,"timetype":null,"unit":null,"islock":1,"userlock":"4360","updatenum":0,"controlLinkJson":null,"fileJson":null,"sortJson":null}
chat_type: 1
chat_id: 132
sortJson: []
locknum: 0

origin

name: 
to_users: 4360,4378,4379
chat_type: 1
chat_id: 132
controls: [{"id":55666,"type":1,"remark":"1","content":"3","unit":null,"islock":1,"userlock":"4360","updatenum":0,"islink":true,"controlLinkJson":[],"fileJson":[],"sortJson":[],"sort":1}]
task_type: 1
sortJson: []
taskId: 7098
locknum: 0
 */