var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        {
          staticClass:
            "components_popup_record_of_communication_task_task_page bbox cp",
          on: {
            click: function ($event) {
              return _vm.item.open({ edit: true })
            },
          },
        },
        [
          _vm.item.colour
            ? _c(
                "div",
                { staticClass: "color_group" },
                [
                  _vm._l(
                    _vm.getColorList(_vm.item.colour),
                    function (item, index) {
                      return [
                        _c("div", {
                          key: index,
                          staticClass: "color",
                          style: { "border-left-color": item },
                        }),
                      ]
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "content_group" }, [
            _c(
              "div",
              {
                staticClass:
                  "title_group d-flex align-center justify-space-between",
              },
              [
                _c("div", {
                  staticClass: "title",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$tools.highlightKeywords(_vm.item.name, _vm.keyword)
                    ),
                  },
                }),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(
                      _vm
                        .$moment(_vm.item.updateTime)
                        .format("YYYY年MM月DD日 HH:mm")
                    )
                  ),
                ]),
              ]
            ),
            _vm.widget
              ? _c("div", { staticClass: "widget_group" }, [
                  _vm.keyword !== ""
                    ? _c(
                        "div",
                        {
                          staticClass: "widget",
                          domProps: {
                            innerHTML: _vm._s(
                              `包含控件: ${_vm.$tools.highlightKeywords(
                                _vm.widget.remark,
                                _vm.keyword
                              )}`
                            ),
                          },
                        },
                        [_vm._v("包含控件: 内容排期及其他事项讨论")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass:
                "info_group d-flex align-center justify-space-between",
            },
            [
              _c(
                "div",
                { staticClass: "user_info" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.item.createusername,
                        placement: "bottom",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${_vm.item.createuserthumbnail}@!small200`,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "tag_info" }, [
                0
                  ? _c("div", { staticClass: "tag finance" }, [
                      _vm._v("\n        财\n      "),
                    ])
                  : _vm._e(),
                _vm.item.draft
                  ? _c("div", { staticClass: "tag draft" }, [_vm._v("草")])
                  : _vm._e(),
                _vm.item.taskType === 2 || _vm.item.taskType === 3
                  ? _c("div", { staticClass: "tag teach" }, [_vm._v("教")])
                  : _vm._e(),
                _vm.item.hide === 1
                  ? _c("div", { staticClass: "tag privacy" }, [_vm._v("私")])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }