var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "400px", max_height: "240px" } },
    [
      _c(
        "div",
        { staticClass: "components_pop_up_identification_code_index" },
        [
          _c("common-title", { attrs: { title: "请输入识别码" } }),
          _c("div", { staticClass: "main" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.identify,
                  expression: "identify",
                },
              ],
              staticClass: "identify",
              attrs: { type: "text", placeholder: "请输入识别码" },
              domProps: { value: _vm.identify },
              on: {
                keyup: function ($event) {
                  _vm.identify_hint = ""
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.identify = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "hint" }, [
              _vm._v(_vm._s(_vm.identify_hint)),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "footer_group d-flex justify-space-between align-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn cp un_sel sure btn_dark",
                    class: { on: _vm.identify != "" },
                    on: { click: _vm.doIdentify },
                  },
                  [_vm._v("确认")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn cp un_sel btn_light",
                    on: {
                      click: function ($event) {
                        return _vm.doClose()
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ]
            ),
            _c("div", { staticClass: "free_group" }, [
              _c(
                "div",
                {
                  staticClass: "free cp",
                  on: {
                    click: function ($event) {
                      return _vm.getFree()
                    },
                  },
                },
                [_vm._v("教师免费获取")]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.switch_identification_code_free
        ? _c("identification-code-free", {
            attrs: { loginUserCode: _vm.loginUserCode, user: _vm.user },
            on: {
              handleClose: function ($event) {
                return _vm.closeFree()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }