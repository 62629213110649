var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "newPass-conr" }, [
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "i-box" }, [
        _c("img", {
          staticClass: "img2",
          attrs: { src: require("../../../assets/icon-password.png"), alt: "" },
        }),
        _vm.sel
          ? _c("div", [
              _vm.types === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.userName)
                        ? _vm._i(_vm.userName, null) > -1
                        : _vm.userName,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.userName,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.userName = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.userName = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.userName = $$c
                        }
                      },
                    },
                  })
                : _vm.types === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.userName, null) },
                    on: {
                      change: function ($event) {
                        _vm.userName = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: _vm.types,
                    },
                    domProps: { value: _vm.userName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.userName = $event.target.value
                      },
                    },
                  }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userName || _vm.password,
                    expression: "(userName || password)",
                  },
                ],
                staticClass: "sel",
                attrs: {
                  src: require("../../../assets/icon-sel.png"),
                  alt: "",
                },
                on: { click: _vm.showps },
              }),
            ])
          : _c("div", [
              _vm.types === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.userName)
                        ? _vm._i(_vm.userName, null) > -1
                        : _vm.userName,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.userName,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.userName = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.userName = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.userName = $$c
                        }
                      },
                    },
                  })
                : _vm.types === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.userName, null) },
                    on: {
                      change: function ($event) {
                        _vm.userName = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userName,
                        expression: "userName",
                      },
                    ],
                    staticClass: "eye",
                    attrs: {
                      placeholder: "请设置新密码",
                      maxlength: "8",
                      type: _vm.types,
                    },
                    domProps: { value: _vm.userName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.userName = $event.target.value
                      },
                    },
                  }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userName || _vm.password,
                    expression: "(userName || password)",
                  },
                ],
                staticClass: "sel",
                attrs: {
                  src: require("../../../assets/icon-eye.png"),
                  alt: "",
                },
                on: { click: _vm.showps },
              }),
            ]),
        _c("div", { staticClass: "hint" }, [_vm._v("请输入6-8位的数字或字母")]),
      ]),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticClass: "i-box", staticStyle: { "padding-top": "56px" } },
        [
          _c("img", {
            staticClass: "img2",
            attrs: {
              src: require("../../../assets/icon-password.png"),
              alt: "",
            },
          }),
          _vm.sel
            ? _c("div", [
                _vm.types === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : _vm.types === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: _vm.types,
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                0
                  ? _c("img", {
                      staticClass: "sel",
                      attrs: {
                        src: require("../../../assets/icon-sel.png"),
                        alt: "",
                      },
                      on: { click: _vm.showps },
                    })
                  : _vm._e(),
              ])
            : _c("div", [
                _vm.types === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : _vm.types === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "password",
                      attrs: {
                        placeholder: "请确认新密码",
                        maxlength: "8",
                        type: _vm.types,
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                0
                  ? _c("img", {
                      staticClass: "sel",
                      attrs: {
                        src: require("../../../assets/icon-eye.png"),
                        alt: "",
                      },
                      on: { click: _vm.showps },
                    })
                  : _vm._e(),
              ]),
        ]
      ),
      _c("div", { staticClass: "line1" }),
      _c("p", {
        staticClass: "cuowu",
        domProps: { innerHTML: _vm._s(_vm.userNameWarn) },
      }),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-button",
            {
              staticClass: "newPass_btn",
              attrs: { type: "primary", round: "", loading: _vm.isBtnLoading },
              nativeOn: {
                click: function ($event) {
                  return _vm.login.apply(null, arguments)
                },
              },
            },
            [_vm._v("保存并登录")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }