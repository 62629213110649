<!--
 * @Author       : Hugo
 * @Date         : 2021-11-15 10:42:16
 * @LastEditTime : 2021-11-23 16:19:52
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/identificationCode/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="400px"
    max_height="240px"
  >
    <div class="components_pop_up_identification_code_index">
      <common-title
        title="请输入识别码"
      ></common-title>
      <div class="main">
         <input
          type="text"
          class="identify"
          v-model="identify"
          placeholder="请输入识别码"
          @keyup="identify_hint=''"
        >
        <div class="hint">{{identify_hint}}</div>
        <div class="footer_group d-flex justify-space-between align-center">
          <div
            class="btn cp un_sel sure btn_dark"
            :class="{on: identify!=''}"
            @click="doIdentify"
          >确认</div>
          <div class="btn cp un_sel btn_light"
            @click="doClose();"
          >取消</div>
        </div>
        <div class="free_group">
          <div class="free cp"
            @click="getFree();"
          >教师免费获取</div>
        </div>
      </div>
    </div>
    <identification-code-free
      v-if="switch_identification_code_free"
      @handleClose="closeFree();"
      :loginUserCode="loginUserCode"
      :user="user"
    ></identification-code-free>
  </common-wrapper>
</template>


<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper.vue';
import CommonTitle from '@/components/PopUp/Common/Title.vue';
import IdentificationCodeFree from '@/components/PopUp/identificationCode/Free.vue';
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    loginUserCode: {
      type: Object,
      default: null,
    }
  },
  components: {
    // Table
    CommonWrapper,
    CommonTitle,
    IdentificationCodeFree,
  },
  data() {
    return {
      identify: '',
      identify_hint: '',
      switch_identification_code_free: false,
    }
  },
  methods: {
    getFree(){
      this.switch_identification_code_free=true;
    },
    closeFree(){
      this.switch_identification_code_free=false;
    },
    async doIdentify(){
      if(String(this.identify).trim() == ''){
        this.identify_hint = '* 请输入识别码';
      }else{
        this.$showLoading({ title: '加载中' })
        let res = await this.$http.basic_request(
          '/project/bindUserCode', 
          {usercode: String(this.identify).trim()},
          {
            headers: {
              loginUserCode: JSON.stringify(this.loginUserCode),
            }
          }
        );
        this.$hideLoading();
        if(res.message != 'success'){
          this.identify_hint = `* ${res.describe}`;
        }else{
          // 返回上一层 重新登录
          this.$emit('handleSuccess');


          // this.identify_hint = '';
          // this.identify_group = false;
          // // cookie 设置为true
          // let user = this.$tools.getCookie('user');
          // user.usercode = true;
          // this.$cookies.set('user', user);
        }
      }
    },
    doClose(){
      this.$emit('handleClose');
    }
  },
  mounted(){
    // console.log(this.user, this.loginUserCode);
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_identification_code_index{
  width: 100%;
  .identify{
    @include bbox;
    padding: 0 20px;
    display: block;
    margin: 30px auto 0;
    width:360px;
    height:36px;
    background:rgba(251,251,251,1);
    border:1px solid rgba(240,240,240,1);
    border-radius:4px;
    outline: none;
  }
  .hint{
    width:360px;
    font-size:14px;
    
    font-weight:400;
    color:rgba(216,46,13,1);
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
  }
  .footer_group{
    @include flexsb;
    margin: 0 auto ;
    width: 360px;
    .btn{
      text-align: center;
      width:170px;
      height:40px;
      border-radius:4px;
      line-height: 40px;
      font-size:14px;
      font-weight:400;
      &.cancel{
        background-color: rgba(0,84,167,0.1);
        color: $main_blue;
      }

    }
  }
  .free_group{
    width: 360px;
    margin: 10px auto 0;
    .free{
      font-size: 14px;
      color: #82929C;
      text-decoration: underline;
    }
  }
}
</style>