<!--
 * @Author       : Hugo
 * @Date         : 2022-04-26 12:02:37
 * @LastEditTime : 2022-04-26 15:22:07
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Invivation/Modules/Poster/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_invivation_modules_poster bbox"
    id="invivation_poster"
    @click.stop="save()"
  >
    <div class="wrapper bbox">
      <div class="user_group d-flex align-center bbox">
        <img src="~@/assets/logo.png" alt="" class="logo">
        <div class="username">用户: 刘松</div>
      </div>
      <img src="~@/assets/images/invivation_poster/line.png" alt="" class="line">
      <img src="~@/assets/images/invivation_poster/welcome.png" alt="" class="welcome">
      <div class="feature_list">
        <template
          v-for="(item, index) in feature_list"
        >
          <div class="feature d-flex align-center"
            :key="index"
          >
            <div class="symbol"></div>
            <div class="text">{{item.text}}</div>
          </div>
        </template>
      </div>
      <div class="qrcode_group d-flex align-stretch justify-space-between">
        <img
          :src="qrcode_url"
          alt=""
          class="qrcode flex-shrink-0"
        >
        <div class="info_group d-flex flex-column justify-space-between align-start">
          <div class="title_group">
            <div class="text">长按或扫描二维码</div>
            <div class="text">注册妙行</div>
          </div>
          <div class="time_group">
            <div class="title">海报有效期至：</div>
            <div class="time">2020-01-29 15:38</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import files from '@/utils/files.js'
import qrcode from 'qrcode'
export default {
  data(){
    return{
      feature_list: [
        {text: '基于沟通和微观任务控制'},
        {text: '能力画像'},
        {text: '产教贯通管教融合'},
        {text: '实践教学体系化内容'},
        {text: '······'},
      ],
      qrcode_url: undefined,
    }
  },
  mounted(){
    qrcode.toDataURL('https://www.baidu.com', {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: 2,
    })
    .then(url => {
      console.log(url)
      this.qrcode_url = url
    })
    .catch(err => {
      console.error(err)
    })

  },
  methods: {
    save(){
      // files.downloadElement('#invivation_poster')
    },
    copy(){
      // files.copyElement('#invivation_poster')
    }
  }
}
</script>

<style lang="scss" scoped>
.c_invivation_modules_poster{
  width: 280px;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  .wrapper{
    width: 100%;
    border: 1px dashed #A9C2D9;
    border-radius: 20px;
    padding: 20px;
  }
  .user_group{
    width: 100%;
    
    .logo{
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .username{
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
  }
  .line{
    width: 100%;
    display: block;
    margin-top: 20px;
  }
  .welcome{
    display: block;
    margin: 20px auto 0;
  }
  .feature_list{
    margin-top: 20px;
    width: 100%;
    .feature{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
      .symbol{
        width: 8px;
        height: 8px;
        background: #0054A7;
        opacity: 0.39;
        border-radius: 50%;
        margin-right: 8px;
      }
      .text{
        font-size: 14px;
        color: #616D74;
      }
    }
  }
  .qrcode_group{
    width: 100%;
    margin-top: 20px;
    .qrcode{
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
    .info_group{
      .title_group{
        font-size: 14px;
        color: #002141;
        line-height: 20px;
      }
      .time_group{
        font-size: 12px;
        color: #82929C;
        line-height: 17px;
      }
    }
  }
}
</style>
