var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_collect_folder_preview_operation_bar bbox d-flex justify-space-between align-center un_sel",
    },
    [
      _c(
        "div",
        { staticClass: "from_group", domProps: { textContent: _vm._s("") } },
        [_vm._v("来源:柳叶")]
      ),
      _c(
        "div",
        { staticClass: "operation_group d-flex align-center" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                trigger: "click",
                "visible-arrow": false,
                "popper-class": "components_task_fun_bar_page_popover",
              },
            },
            [
              _c("div", { staticClass: "list un_sel" }, [
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    on: {
                      click: function ($event) {
                        return _vm.doShare()
                      },
                    },
                  },
                  [_vm._v("分享")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    domProps: {
                      textContent: _vm._s(
                        _vm.p_folder && _vm.p_folder.collectionflag
                          ? "取消收藏"
                          : "收藏"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.touchCollect()
                      },
                    },
                  },
                  [_vm._v("收藏")]
                ),
              ]),
              _c("template", { slot: "reference" }, [
                _c("div", { staticClass: "menu cp" }),
              ]),
            ],
            2
          ),
          _c("div", {
            staticClass: "close cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doClose()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }