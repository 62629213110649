<template>
  <div class="cate">
    <div class="cate-left">
      <h1 class="logo">
        <img src="../../assets/logo.png" alt />
      </h1>
      <div
        class="tupian"
        @click="book(1)"
        :style="disabled?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-message.png" alt class="book" v-if="disabled" />
        <img src="../../assets/book.png" alt class="book" v-else />
      </div>
      <div class="bok"></div>
      <div
        class="pple"
        @click="book(2)"
        :style="people?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-people-sel.png" alt class="people" v-if="people" />
        <img src="../../assets/icon-people-nor.png" alt class="people" v-else />
      </div>
      <div
        class="mpa"
        @click="book(3)"
        :style="mpashow?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/modulex.png" alt class="mplae" v-if="mpashow" />
        <img src="../../assets/icon-Template-nor.png" alt class="mplae" v-else />
      </div>
      <img src alt class="phical" />
    </div>
  <div class="pages-con">
      <div class="bar">
        <div class="barinput">
          <input type="text" placeholder="搜索" class="hder" />
          <img src="../../assets/icon-search.png" alt class="search" />
        </div>
        <div class="imgbox">
          <img src="../../assets/icon-add.png" alt class="jia" />
        </div>
      </div>
      <div class="contact">
        <p class="conta">联系人</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
 data() {
    return {
      searchString:'',
      disabled: false,
      people: false,
      mpashow: false,
      item:'',
    };
  },
   methods: {
    book(index) {
      if (index == 1) {
        this.mpashow = false;
        this.people = false;
        this.disabled = true;
      }
      if (index == 2) {
        this.mpashow = false;
        this.disabled = false;
        this.people = true;
      }
      if (index == 3) {
        this.people = false;
        this.disabled = false;
        this.mpashow = true;
      }
    },
    pple() {
      this.people = !this.people;
    },
  },
}
</script>
<style lang='less' scoped>
.cate{
    width: 100%;
    height: 100%;
    background: pink;
    display: flex;
   .cate-left {
    width: 60px;
    height: 100%;
    background: #0054a6;
    .logo {
      display: block;
      width: 48px;
      height: 48px;
      margin-top: 6px;
      margin-left: 6px;
    }
    .tupian {
      widows: 62px;
      height: 50px;
      margin-top: 30px;
      .book {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 12px;
        z-index: 11;
      }
    }
    .pple {
      width: 60px;
      height: 50px;
      margin-top:10px;
      .people {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
    .mpa {
      width: 60px;
      height: 50px;
      margin-top:10px;
      .mplae {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }

    .phical {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background: orange;
      margin-top: 381px;
      margin-left: 8px;
    }
  }
  .pages-con {
    width: 250px;
    height:100%;
    background: #F5F8FA;
    //    overflow:hidden;
    .bar {
      display: flex;
      width: 250px;
      height: 66px;
      border-bottom: 2px solid #f4f4f4;

      .barinput {
        margin-top: 16px;
        margin-left: 16px;
        .hder {
          width: 138px;
          height: 30px;
          background: rgba(243, 243, 243, 1);
          border-radius: 15px;
          border: none;
          outline: none;
          position: relative;
          padding-left: 40px;
        }
        .search {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 24px;
          left: 86px;
        }
      }
      .imgbox {
        width: 30px;
        height: 30px;
        background: rgba(243, 243, 243, 1);
        border-radius: 50%;
        margin-left: 14px;
        margin-top: 16px;
        .jia {
          display: block;
          width: 10px;
          height: 11px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
    .listbox::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .contact{
         p{
      width:42px;
      height:14px;
      font-size:14px;
      font-weight:400;
      color:rgba(153,153,153,1);
      margin-top: 19px;
      margin-left: 16px;
    }
    }
 
  } 
}
</style>