var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_select_task_filter_bar bbox d-flex" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-start",
            trigger: "manual",
            "visible-arrow": false,
            "popper-class":
              "c_teaching_package_select_task_filter_bar_type_select",
          },
          model: {
            value: _vm.switchOrigin,
            callback: function ($$v) {
              _vm.switchOrigin = $$v
            },
            expression: "switchOrigin",
          },
        },
        [
          _c("div", { staticClass: "origin_select_list_group" }, [
            _c(
              "div",
              {
                staticClass: "item",
                class: { active: _vm.packageSelectTask.origin === "sell" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.changeOrigin("sell")
                  },
                },
              },
              [_vm._v("\n        我销售的\n      ")]
            ),
            _c(
              "div",
              {
                staticClass: "item",
                class: { active: _vm.packageSelectTask.origin === "shopping" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.changeOrigin("shopping")
                  },
                },
              },
              [_vm._v("\n        模板商城\n      ")]
            ),
          ]),
          _c("template", { slot: "reference" }, [
            _c(
              "div",
              {
                staticClass: "origin_button bbox d-flex cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.switchOrigin = true
                  },
                },
              },
              [
                _c("div", { staticClass: "text" }, [
                  _vm._v("\n          " + _vm._s(_vm.origin) + "\n        "),
                ]),
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require("@/assets/images/teaching_package/select_task/type_select_icon.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "search_group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "search bbox",
          attrs: { type: "text", placeholder: "搜索" },
          domProps: { value: _vm.search },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleKeydownEnter($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search !== "",
              expression: "search !== ''",
            },
          ],
          staticClass: "clear cp",
          attrs: {
            src: require("@/assets/images/common/clear_icon.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doClearSearch()
            },
          },
        }),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showType,
              expression: "showType",
            },
          ],
          staticClass: "type_list d-flex bbox",
        },
        [
          _c(
            "div",
            {
              staticClass: "type",
              class: { active: _vm.type === "package" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.changeType("package")
                },
              },
            },
            [_vm._v("\n      课程\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "type",
              class: { active: _vm.type === "task" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.changeType("task")
                },
              },
            },
            [_vm._v("\n      教学模板\n    ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }