/*
 * @Author       : Hugo
 * @Date         : 2021-12-14 14:50:01
 * @LastEditTime : 2022-06-08 00:18:07
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/types/modules/POrder/index.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main'
import global from '@/api/global'

let polling_member_timer= null; // 轮询会员定时器
class POrder{
  constructor(params={}){
    const {
      orderId,
      orderNum,
      userId,
      tradeType,
      caseType,
      caseId,
      caseSubject,
      caseDesc,
      totalFee,
      prepayId,
      codeUrl,
      createTime,
      payType,
      transactionId,
      timeEnd,
      orderStatus,
      noncestr,
      sign,
      timestamp,
      product_code,
      userName,
      userVip,
      sellnum,
      singleFee,
      selforother
    } = params;
    this.orderId       = orderId;        // String		订单id
    this.orderNum      = orderNum;       // String		订单号
    this.userId        = userId;         // String		用户id
    this.tradeType     = tradeType;      // 交易类型: JSAPI--公众号支付、NATIVE--原生扫码支付、APP--app支付
    this.caseType      = caseType;       // 商品类型(1.会员充值)(2.模板购买)(3.定制购买)（4.礼品卡购买)
    this.caseId        = caseId;         // 商品id(依赖商品类型 1. 对应的exam_signup中的id)
    this.caseSubject   = caseSubject;    // 商品名称
    this.caseDesc      = caseDesc;       // 商品描述
    this.totalFee      = totalFee;       // 总金额(单位/分)
    this.prepayId      = prepayId;       // 微信预支付交易会话标识,用于后续接口调用中使用，该值有效期为2小时
    this.codeUrl       = codeUrl;        // trade_type为NATIVE是有返回，可将该参数值生成二维码展示出来进行扫码支付
    this.createTime    = createTime;     // 订单创建时间
    this.payType       = payType;        // 支付类型(1微信支付,2支付宝支付,3苹果支付)
    this.transactionId = transactionId;  // 微信支付订单号
    this.timeEnd       = timeEnd;        // 支付完成时间
    this.orderStatus   = orderStatus;    // 订单状态 1未支付 2 支付成功 3 支付失败 4 取消订单 5取消订单申请中 6订单关闭
    this.noncestr      = noncestr;       //
    this.sign          = sign;           //
    this.timestamp     = timestamp;      //
    this.product_code  = product_code;   //
    this.userName      = userName;       //
    this.userVip       = userVip;        //
    this.sellnum       = sellnum;        //
    this.singleFee     = singleFee;      //
    this.selforother   = selforother;    // 0自己购买 1代买
  }

  convertToYuan(){
    const {totalFee} = this;
    let price = totalFee ?? undefined;
    if(price !== undefined){
      //TODO 用parseFloat优化
      const is_integer = Number.isInteger(Number((Number(price)/100).toFixed(2)));
      if(is_integer){
        // 整数
        price = (Number(price)/100);
      }else{
        // 非整数
        price = (Number(price)/100).toFixed(2);
      }
    }
    return price;
  }

  pollingMemberStatus(){
    clearInterval(polling_member_timer);
    let polling_num = 0;
    // const that = this;
    // // console.log(that);
    const interval_time = 5000;
    polling_member_timer = setInterval(async ()=>{

      polling_num++;
      // 10分钟超时
      if(polling_num > 1000*60*10/interval_time){
        clearInterval(polling_member_timer);
      }
      const res = await this.getOrderStatus();
      const {message, contents} = res;
      if(message === 'success'){
        // console.log(contents);
        const {orderQueryResponse} = contents;
        const {orderNum, orderStatus,payType} = orderQueryResponse;
        if(orderNum === this.orderNum && payType === this.payType){
          // 订单状态 1未支付 2 支付成功 3 支付失败 4 取消订单 5取消订单申请中 6订单关闭
          if(orderStatus === 1){
            // clearInterval(time_interval);
            // vue.$close_become_member();
            // vue.$payment_status({
            //   title: ''
            // })
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '支付失败'
            // })

          }
          if(orderStatus === 2){
            clearInterval(polling_member_timer);
            // vue.$api.user.refreshUserData(); // 刷新用户信息
            vue.$api.user.updateUserData(); // 刷新用户信息
            vue.$api.payment.becomeMemberStatus({
              title: '恭喜您成为我们的VIP用户'
            })
          }
          if(orderStatus === 3){
            clearInterval(polling_member_timer);
            vue.$api.payment.becomeMemberStatus({
              status: 'failed',
              title: '支付失败'
            })
          }
          if(orderStatus === 4){
            clearInterval(polling_member_timer);
            vue.$api.payment.becomeMemberStatus({
              status: 'failed',
              title: '取消订单'
            })
          }
          if(orderStatus === 5){
            clearInterval(polling_member_timer);
            vue.$api.payment.becomeMemberStatus({
              status: 'failed',
              title: '取消订单申请中'
            })
          }
          if(orderStatus === 6){
            clearInterval(polling_member_timer);
            vue.$api.payment.becomeMemberStatus({
              status: 'failed',
              title: '订单关闭'
            })
          }

        }
      }
    },interval_time)

  }
  
  getOrderStatus = async ()=>{
    const {orderNum, payType} = this;
    const res = await vue.$global.doPost({
      url: '/order/orderQuery',
      data: {
        orderNum,
        payType
      },
      openNotice: false,
    });
    return res;
  }

  pollingStatus(params={}){
    const {
      success_cb,
      fail_cb,
      cancel_cb,
      cancel_apply_cb,
      close_cb,
    } = params;
    clearInterval(polling_member_timer);
    let polling_num = 0;
    // const that = this;
    // // console.log(that);
    const interval_time = 5000;
    polling_member_timer = setInterval(async ()=>{

      polling_num++;
      // 10分钟超时
      if(polling_num > 1000*60*10/interval_time){
        clearInterval(polling_member_timer);
      }
      const res = await this.getOrderStatus();
      const {message, contents} = res;
      if(message === 'success'){
        // console.log(contents);
        const {orderQueryResponse} = contents;
        const {orderNum, orderStatus,payType} = orderQueryResponse;
        if(orderNum === this.orderNum && payType === this.payType){
          // 订单状态 1未支付 2 支付成功 3 支付失败 4 取消订单 5取消订单申请中 6订单关闭
          if(orderStatus === 1){
            // clearInterval(time_interval);
            // vue.$close_become_member();
            // vue.$payment_status({
            //   title: ''
            // })
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '支付失败'
            // })

          }
          if(orderStatus === 2){
            clearInterval(polling_member_timer);
            // vue.$api.user.refreshUserData(); // 刷新用户信息
            // vue.$api.user.updateUserData(); // 刷新用户信息
            // vue.$api.payment.becomeMemberStatus({
            //   title: '恭喜您成为我们的VIP用户'
            // })
            if(success_cb){
              success_cb();
            }
          }
          if(orderStatus === 3){
            clearInterval(polling_member_timer);
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '支付失败'
            // })
            if(fail_cb){
              fail_cb();
            }
          }
          if(orderStatus === 4){
            clearInterval(polling_member_timer);
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '取消订单'
            // })
            if(cancel_cb){
              cancel_cb();
            }
          }
          if(orderStatus === 5){
            clearInterval(polling_member_timer);
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '取消订单申请中'
            // })
            if(cancel_apply_cb){
              cancel_apply_cb();
            }
          }
          if(orderStatus === 6){
            clearInterval(polling_member_timer);
            // vue.$api.payment.becomeMemberStatus({
            //   status: 'failed',
            //   title: '订单关闭'
            // })
            if(close_cb){
              close_cb();
            }
          }

        }
      }
    },interval_time)

  }

  getGiftCard = async () => {
    const { orderNum } = this
    return await global.doPost({
      url: '/card/queryPCardUserByOrderNum',
      data: {
        orderNum
        // orderNum: 16596227606083821
        // orderNum: 16596226277887353
      }
    })
  }
}

export default POrder;