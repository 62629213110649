<template>
  <div
    class="components_pop_up_record_of_communication_hint_page bbox d-flex align-center justify-center"
  >
    <div class="default"
      v-if="type === 'default'"
    >输入关键词进行搜索</div>
    <div class="none_group d-flex flex-column align-center"
      v-if="type === 'none'"
    >
      <img 
        src="~@/assets/images/common/empty.png" alt="" class="none"
      >
      <div class="text pt-20">暂无搜索结果</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type:{ 
      type: String,
      default: 'default',
    },
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_record_of_communication_hint_page{
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #333;
  
}
</style>
