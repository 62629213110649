var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return 1
    ? _c(
        "div",
        {
          staticClass:
            "components_task_main_widget_container_page bbox d-flex flex-column bbox",
        },
        [
          !_vm.list || (_vm.list && _vm.list.length == 0)
            ? [_vm._t("default")]
            : _vm._e(),
          _vm.list
            ? _c(
                "draggable",
                {
                  staticClass: "draggable_wrapper",
                  class: { position: 1 },
                  style: { minHeight: `${_vm.minHeight}px` },
                  attrs: {
                    list: _vm.list,
                    group: { name: `widget`, pull: false },
                    handle: ".components_task_main_widget_title_page .icon",
                    animation: "300",
                  },
                  on: { change: _vm.changeLog },
                },
                [
                  _vm._l(_vm.list, function (item) {
                    return [
                      _c("widget-wrapper", {
                        key: item.id
                          ? `id_${item.id}`
                          : `create_num_${item.create_num}`,
                        ref: "widget",
                        refInFor: true,
                        staticClass: "widget_wrapper",
                        attrs: {
                          large_task: _vm.large_task,
                          data: _vm.data,
                          backup: _vm.backup,
                          p_task_detail: item,
                          parent: _vm.parent,
                        },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }