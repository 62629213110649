var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_batch_add_contacts_to_tasks_selected_task_list_index bbox d-flex flex-column",
    },
    [
      _c("div", { staticClass: "main_group bbox" }, [
        _c("div", { staticClass: "sticky_group bbox" }, [
          _c("div", { staticClass: "filter_group bbox" }, [
            _c("div", { staticClass: "search_group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pageData.search,
                    expression: "pageData.search",
                  },
                ],
                staticClass: "search",
                attrs: { type: "text", placeholder: "搜索" },
                domProps: { value: _vm.pageData.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.pageData, "search", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageData.search !== "",
                    expression: "pageData.search !== ''",
                  },
                ],
                staticClass: "clear cp",
                attrs: {
                  src: require("@/assets/images/common/clear_icon.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.pageData.search = ""
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("task-list", {
              attrs: { "task-list": _vm.taskList, search: _vm.pageData.search },
            }),
          ],
          1
        ),
      ]),
      _c("operation-bar", {
        staticClass: "flex-shrink-0",
        attrs: {
          "task-list": _vm.taskList,
          "user-info-simple-pmcs": _vm.userInfoSimplePmcs,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }