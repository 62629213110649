<template>
  <div class="about">
    <div class="about-left">
      <h1 class="logo">
        <img src="../../assets/logo.png" alt class="logop" />
      </h1>

      <div
        class="tupian"
        @click="book(1)"
        :style="disabled?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-message.png" alt class="book" v-if="disabled" />
        <img src="../../assets/book.png" alt class="book" v-else />
      </div>

      <div
        class="pple"
        @click="book(2)"
        :style="people?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-people-sel.png" alt class="people" v-if="people" />
        <img src="../../assets/icon-people-nor.png" alt class="people" v-else />
      </div>

      <div
        class="mpa"
        @click="book(3)"
        :style="mpashow?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/modulex.png" alt class="mplae" v-if="mpashow" />
        <img src="../../assets/icon-Template-nor.png" alt class="mplae" v-else />
      </div>
    </div>
    <div class="about-con">
      <div class="ger" @click="gerenziliao">
        <img src="../../assets/icon-personal.png" alt class="ren" />
        <a class="personal">个人资料</a>
      </div>
      <div class="line"></div>
      <div class="gdeta" @click="xiugaimima">
        <img src="../../assets/icon-passwordx.png" alt class="datap" />
        <a class="detam">修改密码</a>
      </div>
      <div class="line"></div>
      <div class="set">
        <img src="../../assets/icon-Setup.png" alt class="setp" />
        <a class="setz">设置</a>
      </div>
      <div class="line"></div>
      <div class="equity">
        <img src="../../assets/icon-vipx.png" alt class="equp" />
        <a class="equityy">用户权益</a>
      </div>
      <div class="line"></div>
      <div class="out" @click="tuichudenglu">
        <img src="../../assets/icon-drop out.png" alt class="outp" />
        <a class="outl">退出登录</a>
      </div>
      <div class="line"></div>
    </div>
    <div class="xian"></div>

    <div class="about-right">
      <div class="aboutc">
        <!-- <div class="boutc"> -->
        <div class="plate">
          <h2 class="platec">模板</h2>
          <div class="with1"></div>
          <p class="p1">联系对话</p>
          <div class="withx"></div>
          <p class="p2">模板</p>
          <div class="withxm"></div>
          <p class="p3">签约讲师</p>
          <div class="withq"></div>
          <p class="p4">学习</p>
          <div class="withi"></div>
        </div>
        <div class="tmp">
          <h2 class="tmpl">功能</h2>
          <div class="with2"></div>
          <ul>
            <li class="lan1">新建对话</li>
            <li class="lan">接受对话</li>
            <li class="lan2">增加成员（转为工作组）</li>
          </ul>
          <div class="withx1"></div>
          <ul>
            <li class="xj">新建模板</li>
            <li class="xj1">收藏模板</li>
            <li class="xj2">接收/编辑模板</li>
          </ul>
          <div class="withxm1"></div>
          <ul>
            <li class="qyue">签约并上传教学内容</li>
            <li class="qyue1">共享教学收益</li>
          </ul>
          <div class="withq1"></div>
          <ul>
            <li class="study">学习教学内容</li>
          </ul>
          <div class="withi1"></div>
        </div>
        <div class="nary">
          <h2 class="nar">普通用户</h2>
          <div class="with3"></div>
          <p class="xn"></p>
          <img src="../../assets/icon-duix.png" alt class="duix" />
          <p class="xn2"></p>
          <div class="withx2"></div>
          <p class="xn3"></p>
          <p class="xn4"></p>
          <img src="../../assets/icon-duix.png" alt class="duix1" />
          <div class="withm2"></div>
          <p class="xn5"></p>
          <p class="xn6"></p>
          <div class="withq2"></div>
          <h3 class="price">享受普通用户价格</h3>
          <div class="withi2"></div>
          <h2 class="mf">免费</h2>
        </div>
        <div class="vyh">
          <h4 class="vpyh">VIP用户</h4>
          <div class="with4"></div>
          <img src="../../assets/icon-duix.png" alt class="imgdui" />
          <img src="../../assets/icon-duix.png" alt class="imgdui2" />
          <img src="../../assets/icon-duix.png" alt class="imgdui3" />
          <div class="withx3"></div>
          <img src="../../assets/icon-duix.png" alt class="imgdui4" />
          <img src="../../assets/icon-duix.png" alt class="imgdui5" />
          <img src="../../assets/icon-duix.png" alt class="imgdui6" />
          <div class="withm3"></div>
          <img src="../../assets/icon-duix.png" alt class="imgdui7" />
          <img src="../../assets/icon-duix.png" alt class="imgdui8" />
          <div class="withq3"></div>
          <h5 class="jiage">享受VIP用户价格</h5>
          <div class="withi3"></div>
          <p class="old">388元/年</p>
          <!-- <div class="moy"></div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  data() {
    return {
      searchString: "",
      disabled: false,
      people: false,
      mpashow: false
    };
  },
  methods: {
    book(index) {
      if (index == 1) {
        this.mpashow = false;
        this.people = false;
        this.disabled = true;
      }
      if (index == 2) {
        this.mpashow = false;
        this.disabled = false;
        this.people = true;
      }
      if (index == 3) {
        this.people = false;
        this.disabled = false;
        this.mpashow = true;
      }
    },
    pple() {
      this.people = !this.people;
    },
    mpaclick() {
      this.mpashow = !this.mpashow;
    },
    lbo(item, index) {
      this.lbo = item;
    },
    gerenziliao(){
      this.$router.push('/home/information')
    },
    xiugaimima(){
      this.$router.push('/login/forget')
    },
    tuichudenglu(){
      this.$router.push('/login/sign')
    }

    // data() {
    //   return {
    //     tabIndex:1
    //   }
    // },
    // methods: {
    //   jump(){
    //     this.$router.push({path:'/data'})
    //   },
    //   changeIndex(index){
    //     this.tabIndex=index;
    //   }
    // },
  }
};
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  height: 100%;
  display: flex;

  .about-left {
    width: 60px;
    height: 100%;
    background: #0054a6;

    .logop {
      display: block;
      width: 58px;
      height: 58px;
    }
    .bookp {
      display: block;
      width: 24px;
      height: 24px;
      margin-left: 15px;
      margin-top: 50px;
    }
    .people {
      display: block;
      width: 24px;
      height: 24px;
      background: #ffffff;
      margin-top: 50px;
      margin-left: 15px;
    }
    .modnor {
      width: 24px;
      height: 24px;
      margin-top: 50px;
      margin-left: 15px;
    }
  }
  .about-con {
    width: 250px;
    height: 100%;
    .ger {
      width: 250px;
      height: 66px;
      display: flex;
      .ren {
        width: 24px;
        height: 24px;
        display: block;
        margin-top: 22px;
        margin-left: 29px;
      }
      .personal {
        width: 72px;
        height: 17px;
        font-size: 16px;
        color: #333333;
        margin-top: 24px;
        margin-left: 15px;
      }
    }
    .line {
      width: 174px;
      height: 1px;
      background: #f4f4f4;
      margin-left: 76px;
    }
    .gdeta {
      width: 250px;
      height: 66px;
      display: flex;

      .datap {
        display: block;
        width: 24px;
        height: 24px;
        margin-top: 22px;
        margin-left: 29px;
      }

      .detam {
        display: block;
        width: 72px;
        height: 17px;
        font-size: 16px;
        color: #333333;
        margin-top: 24px;
        margin-left: 15px;
      }
    }
    .set {
      width: 250px;
      height: 66px;
      display: flex;

      .setp {
        display: block;
        width: 24px;
        height: 22px;
        margin-top: 22px;
        margin-left: 29px;
      }
      .setz {
        display: block;
        width: 72px;
        height: 17px;
        font-size: 16px;
        color: #333333;
        margin-top: 24px;
        margin-left: 15px;
      }
    }

    .equity {
      width: 250px;
      height: 66px;
      display: flex;

      .equp {
        width: 24px;
        height: 22px;
        display: block;
        margin-top: 22px;
        margin-left: 29px;
      }

      .equityy {
        display: block;
        width: 72px;
        height: 17px;
        font-size: 16px;
        color: #333333;
        margin-top: 24px;
        margin-left: 15px;
      }
    }
    .out {
      width: 250px;
      height: 66px;
      display: flex;

      .outp {
        display: block;
        width: 24px;
        height: 22px;
        margin-top: 22px;
        margin-left: 29px;
      }

      .outl {
        display: block;
        width: 72px;
        height: 17px;
        font-size: 16px;
        color: #333333;
        margin-top: 24px;
        margin-left: 15px;
      }
    }
  }
  .xian {
    width: 2px;
    height: 100%;
    background: #f4f4f4;
  }
  .about-right {
    .aboutc {
      width: 1200px;
      height: 100%;
      display: flex;
      margin: 10px auto;

      .plate {
        width: 300px;
        height: 100%;
        background: #f4f4f4;
        // text-align: center;

        .platec {
          width: 40px;
          height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          margin-top: 22px;
          margin-left: 130px;
          display: flex;
        }
        .with1 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 34px;
        }

        .p1 {
          width: 90px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 54px;
          margin-left: 99px;
          align-items: center;
        }
        .withx {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 77px;
        }
        .p2 {
          width: 36px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-left: 132px;
          margin-top: 46px;
          align-items: center;
        }
        .withxm {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 77px;
        }
        .p3 {
          width: 72px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-left: 114px;
          margin-top: 43px;
          align-items: center;
        }
        .withq {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 33px;
        }
        .p4 {
          width: 36px;
          height: 16px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 25px;
          margin-left: 133px;
        }
      }
      .withi {
        width: 300px;
        height: 2px;
        background: #ffffff;
        margin-top: 27px;
      }
      .tmp {
        width: 300px;
        height: 100%;
        background: #f9f9f9;

        .tmpl {
          width: 40px;
          height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          margin-top: 22px;
          margin-left: 130px;
        }
        .with2 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 34px;
        }
        .lan1 {
          width: 72px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 14px;
          margin-left: 114px;
        }
        .lan {
          width: 72px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 20px;
          margin-left: 114px;
        }
        .lan2 {
          width: 198px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 20px;
          margin-left: 56px;
        }
        .withx1 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 43px;
        }
        .xj {
          width: 72px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 27px;
          margin-left: 117px;
        }
        .xj1 {
          width: 72px;
          height: 19px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 20px;
          margin-left: 115px;
        }
        .xj2 {
          width: 116px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 20px;
          margin-left: 91px;
        }
        .withxm1 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 19px;
        }
        .qyue {
          width: 162px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-left: 69px;
          margin-top: 15px;
        }
        .qyue1 {
          width: 108px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 20px;
          margin-left: 96px;
        }
        .withq1 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 23px;
        }
        .study {
          width: 108px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 21px;
          margin-left: 96px;
        }
        .withi1 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 29px;
        }
      }
      .nary {
        width: 300px;
        height: 100%;
        background: #edf1f5;

        .nar {
          width: 80px;
          height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          margin-top: 22px;
          margin-left: 110px;
        }
        .with3 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 34px;
        }
        .xn {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 26px;
          margin-left: 140px;
        }

        .duix {
          display: block;
          width: 27px;
          height: 27px;
          margin-top: 27px;
          margin-left: 137px;
        }
        .xn2 {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 20px;
          margin-left: 140px;
        }
        .withx2 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 47px;
        }
        .xn3 {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 37px;
          margin-left: 140px;
        }
        .xn4 {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 37px;
          margin-left: 140px;
        }
        .duix1 {
          display: block;
          width: 20px;
          height: 20px;
          margin-top: 27px;
          margin-left: 140px;
        }
        .withm2 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 17px;
        }
        .xn5 {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 31px;
          margin-left: 140px;
        }
        .xn6 {
          display: block;
          width: 21px;
          height: 1px;
          background: #333333;
          margin-top: 37px;
          margin-left: 140px;
        }
        .withq2 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 23px;
        }
        .price {
          width: 144px;
          height: 18px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 24px;
          margin-left: 78px;
        }
        .withi2 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 26px;
        }
        .mf {
          width: 40px;
          height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          margin-top: 84px;
          margin-left: 130px;
        }
      }
      .vyh {
        width: 300px;
        height: 100%;
        background: #d6e3f1;

        .vpyh {
          width: 75px;
          height: 20px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          margin-top: 22px;
          margin-left: 117px;
        }
        .with4 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 34px;
        }

        .imgdui,
        .imgdui2,
        .imgdui3,
        .imgdui4,
        .imgdui5,
        .imgdui6,
        .imgdui7,
        .imgdui8,
        .imgdui9 {
          display: block;
          width: 20px;
          height: 20px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
          margin-left: 140px;
        }
        .imgdui {
          margin-top: 18px;
        }
        .imgdui2,
        .imgdui3 {
          margin-top: 17px;
        }
        .withx3 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 37px;
        }
        .imgdui4 {
          margin-top: 23px;
        }
        .imgdui5,
        .imgdui6 {
          margin-top: 17px;
        }
        .withm3 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 23px;
        }
        .imgdui7 {
          margin-top: 14px;
        }
        .imgdui8 {
          margin-top: 17px;
        }
        .withq3 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 23px;
        }
        .jiage {
          width: 137px;
          height: 17px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 27px;
          margin-left: 84px;
        }
        .withi3 {
          width: 300px;
          height: 2px;
          background: #ffffff;
          margin-top: 23px;
        }
        .old {
          width: 220px;
          height: 66px;
          background: rgba(243, 88, 12, 1);
          border-radius: 10px;
          text-align: center;
          color: #ffffff;
          font-size: 20px;
          font-weight: bold;
          line-height: 66px;
          margin-top: 28px;
          margin-left: 45px;
        }
        .moy {
          width: 220px;
          height: 256px;
          background: pink;
          margin-top: 15px;
          margin-left: 45px;
        }
      }
    }
  }
}
</style>