import PluginComponent from '@/components/Plugin/Modules/MigrateTask/SelectTargetGroup'

const PLUGIN_NAME = 'MigrateTaskSelectTargetGroup'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    /**
     * 【确认迁移任务工作组】 $openMigrateTaskSelectTargetGroup
     * @param {Object} params
     * @param {String} params.title - 标题
     * @param {Array} params.taskList - 已选择任务
     */
    Vue.prototype[`$open${PLUGIN_NAME}`] = (params={}) => {
      // // console.log(obj)
      // const { title } = params
      // instance.title = title ?? '任务迁移'
      const {
        title,
        taskList,
        cancelCallback // 取消时的回调
      } = params ?? {}
      instance.params = {
        title,
        taskList,
        cancelCallback
      }
      // instance.pcard = pcard
      // instance.gift_card = gift_card
      instance.visible = true
    }
    Vue.prototype[`$close${PLUGIN_NAME}`] = () => {
      instance.visible = false
    }
  }
}

export default PluginMember