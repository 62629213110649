/*
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-12 14:34:34
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-25 11:19:38
 * @FilePath: /miaohang/src/api/modules/task_fun/createReminder.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import vue from '@/main';
class CreateReminder{
  main = async (data)=> {
    // console.log(data);
    const res = await vue.$global.doPost({
      url: '/remind/remindCreate',
      // data: {
      //   taskId: 6679,
      //   conId: 52709,
      //   pType: 1,
      //   remindTime: '2021-09-30 16:39',
      //   toUsers: '4360',
      //   content: '测试0',

      // }
      data,
      loading: true,
      loadingText: '提交中',
    })
    return res;
  }
}

const createReminder = new CreateReminder();

export default createReminder.main;