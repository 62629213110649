<!--
 * @Author       : Hugo
 * @Date         : 2022-03-11 16:55:15
 * @LastEditTime : 2022-04-13 14:13:46
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/Task/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <plugin-component
      :data="data"
      :large_task="large_task"
      :backup="backup"
    ></plugin-component>
  </div>
</template>

<script>
import PluginComponent from '@/components/PopUp/Task/index.vue';
export default {
  components: {
    PluginComponent,
  },
  data(){
    return{
      visible: false,
      data: undefined,
      backup: undefined,
      large_task: undefined,
    }
  },
  mounted(){

  },
  methods: {
    doClose(){
      this.visible = false;
    }
  }
}
</script>