var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "success-con" },
    [
      _c("div", { staticClass: "success-icon" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("../../assets/icon-success.png"), alt: "" },
        }),
        _c("h2", { staticClass: "fon" }, [
          _vm._v("注册成功，恭喜您成为我们的普通注册用户！"),
        ]),
        _c("div", { staticClass: "data" }, [
          _c(
            "button",
            { staticClass: "personal cp", on: { click: _vm.information } },
            [_vm._v("继续完善个人信息")]
          ),
          _c(
            "button",
            { staticClass: "major cp", on: { click: _vm.kaishigoutong } },
            [_vm._v("开始专业沟通")]
          ),
        ]),
        0 ? _c("p", { staticClass: "xian" }) : _vm._e(),
      ]),
      0
        ? _c("div", { staticClass: "vip" }, [
            _c("div", { staticClass: "vip-right" }, [
              _vm._m(0),
              _c("p", { staticClass: "ftion" }, [
                _vm._v("成为VIP用户后，您将享受以下功能："),
              ]),
              _vm._m(1),
              _c("a", { on: { click: _vm.equities } }, [
                _vm._v("更多权益...."),
              ]),
            ]),
            _c("div", { staticClass: "line" }),
            _vm._m(2),
          ])
        : _vm._e(),
      _vm.switch_identification_code
        ? _c("identification-code", {
            attrs: { loginUserCode: _vm.loginUserCode, user: _vm.user },
            on: {
              handleClose: function ($event) {
                return _vm.closeIdentificationCode()
              },
              handleSuccess: function ($event) {
                return _vm.identifySuccess()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "iconvip" }, [
      _c("h3", { staticClass: "yh" }, [_vm._v("或者 您还可以选择成为VIP用户")]),
      _c("img", {
        staticClass: "iconv",
        attrs: { src: require("../../assets/icon-vip.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "gn" }, [
      _c("ul", [
        _c("li", [_vm._v("主动添加联系人功能")]),
        _c("li", [_vm._v("新建一对一和工作组对话窗口功能")]),
        _c("li", [_vm._v("新建模板功能")]),
        _c("li", [_vm._v("收藏及管理模块功能")]),
        _c("li", [_vm._v("申请签约讲师,共享教学收益(暂未上线,敬请期待....)")]),
        _c("li", [_vm._v("享受教学内容VIP用户价")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vip-left" }, [
      _c("img", {
        staticClass: "money",
        attrs: { src: require("../../assets/388.png"), alt: "" },
      }),
      _c("div", { staticClass: "code" }, [
        _c("div", { staticClass: "coder" }, [
          _c("button", { staticClass: "er" }),
          _c("div", { staticClass: "weixin" }, [
            _c("img", {
              staticClass: "wx",
              attrs: { src: require("../../assets/weixin.png"), alt: "" },
            }),
            _c("p", { staticClass: "wzf" }, [_vm._v("微信支付")]),
          ]),
        ]),
        _c("div", { staticClass: "linex" }),
        _c("div", { staticClass: "codel" }, [
          _c("button", { staticClass: "zf" }),
          _c("div", { staticClass: "zfb" }, [
            _c("img", {
              staticClass: "bao",
              attrs: { src: require("../../assets/zhifubao.png"), alt: "" },
            }),
            _c("p", { staticClass: "zfub" }, [_vm._v("支付宝扫码支付")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }