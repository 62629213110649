/*
 * @Author       : Hugo
 * @Date         : 2022-03-04 14:52:10
 * @LastEditTime : 2022-03-04 14:54:42
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/store/stateModule/wisdom.js
 * @Description  : 
 * @^_^          : 
 */
const wisdom = {
  wisdom_user_screen: undefined, // 用户设备
}

export default wisdom;