var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "unit_page",
      on: {
        click: function ($event) {
          return _vm.$api.stopPropagation($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "list flex",
          style: { "max-height": `${_vm.max_height}px` },
        },
        [
          _vm.createable
            ? _c("div", { staticClass: "group new" }, [
                _c(
                  "div",
                  {
                    staticClass: "item un_sel cp",
                    on: {
                      click: function ($event) {
                        return _vm.createNewFolder()
                      },
                    },
                  },
                  [_c("div", { staticClass: "text" }, [_vm._v("新建分组")])]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              ref: "list",
              staticClass: "group folder",
              style: { "max-height": `${_vm.max_height - 51}px` },
            },
            [
              _vm._l(_vm.origin, function (item, index) {
                return [
                  _vm.canShowFolder(item)
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "item un_sel cp",
                          on: {
                            click: function ($event) {
                              return _vm.doSelect(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          item.sonfolderList && item.sonfolderList.length
                            ? _c("img", {
                                staticClass: "arrow",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/arrow.png"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading && !_vm.no_more,
                      expression: "loading && !no_more",
                    },
                  ],
                  staticClass: "loading",
                },
                [_c("loading-icon", { attrs: { size: "12px" } })],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _vm.next_data && _vm.next_data.length
        ? [
            _c("unit", {
              attrs: {
                data: _vm.next_data,
                multiple: _vm.multiple,
                type: _vm.type,
                id: _vm.id,
                max_height: _vm.max_height,
                filter_list: _vm.filter_list,
              },
              on: {
                handleRemove: _vm.handleRemove,
                handleSure: _vm.handleSure,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }