/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-12 14:34:34
 * @LastEditors  : hugo
 * @LastEditTime : 2023-11-07 10:43:16
 * @FilePath     : /pmcs_web/src/api/modules/oss.js
 * @Description  :
 */
import vue from '@/main';
class Oss{
  uploadAvatar = async (params) => {
    const {file, handleProgress} = params;
    const res = await this.upload({file, handleProgress, type: 'avatar'});
    return res;
  }
  uploadPicture = async (params) => {
    const {file, handleProgress} = params;
    const res = await this.upload({file, handleProgress, type: 'picture'});
    return res;
  }
  uploadFile = async (params) => {
    const {file, handleProgress, name} = params;
    const res = await this.upload({file, handleProgress, type: 'file', name});
    return res;
  }

  upload = async (params) => {
    const {file, handleProgress, type, name} = params;
    const res = await vue.$oss.upload({file, handleProgress, type, name});
    return res;
  }

  downloadAvatar = async (params) => {
    const {file} = params;
    await this.download({file, type: 'avatar'});
  }
  downloadPicture = async (params) => {
    const {file} = params;
    await this.download({file, type: 'picture'});
  }
  downloadFile = async (params) => {
    const {file} = params;
    await this.download({file, type: 'file'});
  }
  download = async (params)=>{
    const {file, type} = params;
    const res = await vue.$oss.downloadUrl({file, type});
    // window.open(res, "_blank"); // 下载打开新窗口
    const iframe = document.createElement("iframe");
    iframe.style.display = "none"; // 防止影响页面
    iframe.style.height = 0; // 防止影响页面
    iframe.src = res;
    document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
    // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
    setTimeout(()=>{
      iframe.remove();
    }, 5 * 60 * 1000);
  }
}

const oss = new Oss();

export default oss;
