var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_common_title d-flex align-center justify-center",
    },
    [
      _vm.$slots.default
        ? [
            _c(
              "div",
              {
                staticClass: "slot_basic",
                style: {
                  "border-radius": `${_vm.border_radius} ${_vm.border_radius} 0 0`,
                  "background-color": _vm.background_color,
                },
              },
              [_vm._t("default")],
              2
            ),
          ]
        : _c(
            "div",
            {
              staticClass: "wrapper_group d-flex align-center justify-center",
              style: {
                "border-radius": `${_vm.border_radius} ${_vm.border_radius} 0 0`,
                "background-color": _vm.background_color,
              },
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _vm.close
                ? _c("div", {
                    staticClass: "close cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("handleClose")
                      },
                    },
                  })
                : _vm._e(),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }