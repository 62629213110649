<!--
 * @Author       : Hugo
 * @Date         : 2021-05-07 01:02:19
 * @LastEditTime : 2022-04-15 15:13:07
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : /miaohang/src/components/Common/MovingPath/Container.vue
 * ^_^
-->
<template>
  <div
    class="moving_path_page"
  >
    <div class="mask"
      v-if="mask"
      @click="doClose()"
    ></div>
    <overlay-scrollbars
      class="overlay_container"
      ref="overlay"
    >
      <div class="container flex"
        @click="doClose()"
      > 
        <unit
          :data="top_list"
          :filter_list="filter_list"
          :type="type"
          :id="id"
          :max_height="max_height"
          :createable="false"
          :current_folder="current_folder"
          @handleClose="handleClose"
          @handleRemove="handleRemove"
          @handleSure="handleSure"
        ></unit>
        <unit
          :data="unit_data"
          :multiple="multiple"
          :filter_list="filter_list"
          :type="type"
          :id="id"
          :max_height="max_height"
          :current_folder="current_folder"
          @handleClose="handleClose"
          @handleRemove="handleRemove"
          @handleSure="handleSure"
        ></unit>
      </div>
    </overlay-scrollbars>
  </div>
</template>
<script>
import Unit from '@/components/Common/MovingPath/Unit.vue';
import {PFolder} from '@/types'
export default {
  components: {
    Unit,
  },
  props: {
    mask: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'default'
    },
    id: {
      type: String,
      default: '0',
    },
    max_height: {
      type: String,
      default: '451',
    },
    filter_list: { //不显示需要排除的文件夹 
      type: Array,
      default: undefined,
    },
    current_folder: { // 当前文件夹
      type: Object,
      default: undefined,
    },
  },
  data(){
    return {
      unit_data: [],
      top_list: [
        new PFolder({id:0, name:'我收藏的', cf: {selected: true}})
      ],
    }
  },
  computed: {
    moving_path_scroll_to_right(){
      return this.$store.state.moving_path_scroll_to_right
    },
    switch_moving_path(){
      return this.$store.state.switch_moving_path;
    },
  },
  watch: {
    moving_path_scroll_to_right: function(val){
      if(val){
        this.scrollToRight();
      }
    },
    // switch_moving_path: function(val){
    //   if(val){
    //     this.init()
    //   }
    // }
  },
  methods: {
    scrollToRight(){
      this.$refs.overlay.osInstance().scroll({x: '100%'});
      setTimeout(()=>{
        this.$store.commit('set_moving_path_scroll_to_right', false);
      }, 50)
    },
    doClose(){
      this.$store.commit('set_switch_moving_path', false);
    },
    async init(){
      this.getFolders();
    },
    async getFolders(){
      let data = {
        sort: 1,
        fId: 0,
      }
      let res = await this.$api.custom.getFolders(data);
      if(res.contents && res.contents.pFolderList){
        this.$set(this, 'unit_data', res.contents.pFolderList)
      }
    },
    handleClose(){
      this.$emit('handleClose')
    },
    handleRemove(){
      console.log('handleRemove')
      this.$emit('handleRemove')
    },
    handleSure(aim){
      console.log(aim)
      this.$emit('handleSure', aim)
    }
  },
  async mounted(){
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.moving_path_page{
  width: 100%;
  // background-color: red;
  // max-height: 12*40 + 20px;
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 2;
  }
  .overlay_container{
    height: 100%;
    width:100%;
    z-index: 4;
    // background-color: rgba(255,255,255, 0.3);
  }
  .container{
    @include scrollbar;
    height: 100%;
    align-items: flex-start;
    
    // overflow-y: auto;
    // padding-bottom: 11px;
  }
}
</style>