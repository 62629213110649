var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status_title
    ? _c(
        "div",
        {
          staticClass: "change_title",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.openAppoint()
            },
          },
        },
        [
          _vm._v(
            _vm._s(_vm.is_task_title_change ? "" : "标题 ") +
              _vm._s(_vm.msg.title)
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }