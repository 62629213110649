var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            {
              attrs: {
                height: "auto",
                max_width: "800px",
                max_height: "800px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "components_plugin_modules_buy_now_index pb-20 d-flex flex-column",
                },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0",
                    attrs: { title: _vm.title, close: true },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _c("buy-now-page", {
                    staticClass: "main bbox",
                    attrs: { task: _vm.task },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }