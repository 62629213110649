/*
 * @Author       : Hugo
 * @Date         : 2022-01-10 15:20:47
 * @LastEditTime : 2022-01-10 15:44:20
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PUserCode/index.js
 * @Description  : 
 * @^_^          : 
 */
class PUserCode{
  batchId      = undefined;
  code         = undefined;
  createTime   = undefined;
  deleteflag   = undefined;
  id           = undefined;
  name         = undefined;
  packageNames = undefined;
  teacheNames  = undefined;
  thumbnail    = undefined;
  type         = undefined;
  updateTime   = undefined;
  userId       = undefined;
  userName     = undefined;
  vipdays      = undefined;
  constructor(params={}){
    const {
      batchId,
      code,
      createTime,
      deleteflag,
      id,
      name,
      packageNames,
      teacheNames,
      thumbnail,
      type,
      updateTime,
      userId,
      userName,
      vipdays,
    } = params;
    
    this.batchId      = batchId;
    this.code         = code;
    this.createTime   = createTime;
    this.deleteflag   = deleteflag;
    this.id           = id;
    this.name         = name;
    this.packageNames = packageNames;
    this.teacheNames  = teacheNames;
    this.thumbnail    = thumbnail;
    this.type         = type;
    this.updateTime   = updateTime;
    this.userId       = userId;
    this.userName     = userName;
    this.vipdays      = vipdays;
  }
}

export default PUserCode;