var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sketch_content_wrapper" },
    [
      _vm.status_relevance
        ? [
            _c("sketch-title", {
              attrs: { msg: _vm.msg, relevance: true },
              on: { handleAppoint: _vm.openAppointTask },
            }),
            _c("sketch-title-change", {
              attrs: { msg: _vm.msg, relevance: true },
              on: { handleAppoint: _vm.openAppointTask },
            }),
            _c("sketch-content", {
              attrs: { msg: _vm.msg, relevance: true },
              on: { handleAppoint: _vm.openAppointTask },
            }),
          ]
        : _vm._e(),
      _vm.status_special
        ? [
            _vm.msg.controlType === 6
              ? _c("file-sketch", { attrs: { msg: _vm.msg } })
              : _vm._e(),
            _vm.msg.controlType === 7
              ? _c("hyperlink-sketch", {
                  attrs: { msg: _vm.msg },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$api.stopPropagation($event)
                    },
                  },
                })
              : _vm._e(),
            _vm.msg.controlType === 9
              ? _c("picture-sketch", { attrs: { msg: _vm.msg } })
              : _vm._e(),
            _vm.msg.controlType === 10
              ? _c("contact-sketch", { attrs: { msg: _vm.msg } })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.status_common
        ? _c("common-sketch", {
            attrs: { msg: _vm.msg },
            on: { handleAppoint: _vm.openAppointTask },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }