var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.titleStatusList && _vm.titleStatusList.length
    ? _c(
        "span",
        { staticClass: "tag_list" },
        [
          _vm._l(_vm.titleStatusList, function (item, index) {
            return [
              _c("span", {
                key: index,
                staticClass: "tag",
                class: { [item.type]: true },
                style: { width: _vm.size, height: _vm.size },
              }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }