<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="80%"
      width="600px"
      max_width="60%"
    >
      <div
        class="c_plugin_migrate_task_select_target_group d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose ="handleClose"
          :title="title"
          :close="true"
          background_color="#fff"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          :params="params"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonTitle from '@/components/PopUp/Common/Title'
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/PopUp/MigrateTask/SelectTargetGroup/index'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent
  },
  data(){
    return {
      visible: false,
      // title: '',
      // onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      params: {
        taskList: undefined,
        title: '选择迁移群聊'
      }
    }
  },
  computed: {
    title () {
      const { params } = this
      if ((params ?? '') === '') {
        return '选择迁移群聊'
      }
      const { title } = params
      return title ?? '选择迁移群聊'
    }
  },
  watch: {
    visible: function (val) {
      // if (val) {
      //   const taskList = this.generateTaskList()
      //   this.$set(this, 'taskList', taskList)
      // }
    }
  },
  methods: {
    handleClose() {
      this.visible = false
      const { params } = this
      const { cancelCallback } = params
      console.log(cancelCallback)
      if (cancelCallback) {
        cancelCallback()
      }
    },
    handleReturn(params) {
      const { work } = params
      const { onReturn, list } = this
      if (onReturn) {
        console.log('onReturn')
        onReturn({ list, work })
      }
      this.handleClose()
    },
    async handleSure() {
      if (this.onSure) {
        if (this.loading) {
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_migrate_task_select_target_group {
  background-color: #fff;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  overflow-y: auto;
  .main{
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
