var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moving_path_page" },
    [
      _vm.mask
        ? _c("div", {
            staticClass: "mask",
            on: {
              click: function ($event) {
                return _vm.doClose()
              },
            },
          })
        : _vm._e(),
      _c(
        "overlay-scrollbars",
        { ref: "overlay", staticClass: "overlay_container" },
        [
          _c(
            "div",
            {
              staticClass: "container flex",
              on: {
                click: function ($event) {
                  return _vm.doClose()
                },
              },
            },
            [
              _c("unit", {
                attrs: {
                  data: _vm.top_list,
                  filter_list: _vm.filter_list,
                  type: _vm.type,
                  id: _vm.id,
                  max_height: _vm.max_height,
                  createable: false,
                  current_folder: _vm.current_folder,
                },
                on: {
                  handleClose: _vm.handleClose,
                  handleRemove: _vm.handleRemove,
                  handleSure: _vm.handleSure,
                },
              }),
              _c("unit", {
                attrs: {
                  data: _vm.unit_data,
                  multiple: _vm.multiple,
                  filter_list: _vm.filter_list,
                  type: _vm.type,
                  id: _vm.id,
                  max_height: _vm.max_height,
                  current_folder: _vm.current_folder,
                },
                on: {
                  handleClose: _vm.handleClose,
                  handleRemove: _vm.handleRemove,
                  handleSure: _vm.handleSure,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }