<!--
 * @Author       : Hugo
 * @Date         : 2022-01-07 12:13:54
 * @LastEditTime : 2022-06-08 10:05:27
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/ScanCode/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      max_width="400px"
      max_height="800px"
    >
      <div class="components_plugin_modules_scan_code_index pb-20 d-flex flex-column"
      >
        <common-title
          class        = "flex-shrink-0"
          @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
        >
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <scan-code-page
          class="main bbox"
          :order="order"
          :onSuccess="onSuccess"
          :onFail="onFail"
          :onCancel="onCancel"
          :onCancelApply="onCancelApply"
          :onClose="onClose"
        ></scan-code-page>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import ScanCodePage from '@/components/Payment/ScanCode/index.vue';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    ScanCodePage,
  },
  data(){
    return {
      visible: false,
      order: undefined,
      title: '购买支付',
      onSuccess: undefined,
      onFail: undefined,
      onCancel: undefined,
      onCancelApply: undefined,
      onClose: undefined,
    }
  },
  mounted(){
  },
  methods: {
    handleClose(){
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.components_plugin_modules_scan_code_index{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    padding: 40px;
    overflow-y: auto;
  }
}
</style>
