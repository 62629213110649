/*
 * @Author       : Hugo
 * @Date         : 2020-10-28 14:18:01
 * @LastEditTime : 2021-08-16 12:00:57
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/task.js
 * @Description  : 任务api
 * @^_^          : 
 */
import vue from '@/main.js';

let common_reserved_fileds = [
  'type',
  'sort',
  'id',
  'taskId',
  'taskDetailNext',
  'islock',
  'websort',
  'remark',
  'content',
  'unit',
  'controlLinkJson',
  'controlLinkList',
  'updatenum',
  'userlock',
  'deep_level',
  'parent_websort',
  'islink',
  'teachId',
];

let type_reserved_fileds = {
  1: [],
  2: [],
  3: [],
  4: ['timetype'],
  5: [],
  6: ['filelist','delete_list', 'fileList'],
  7: ['hyperlink_list',],
  8: [],
  9: ['imglist', 'delete_list', 'fileList'],
  10: ['userList'],
}

// let {commit, state, dispatch} = vue.$store;
const task = {
  /**
   * 获取任务数据
   * @param {Object} params {taskId: 任务id}
   */
  async getTaskDetails(params){
    let {task_edit_status, task_need_relevancy, task_see_type} = vue.$store.state;
    let {taskId} = params || {};
    if(taskId){
      // 有数据
      let res = await vue.$http.request('/projectTask/taskDetail', {taskId, linkflag: task_need_relevancy ? 1 : 0, see_type: task_see_type ? task_see_type : '',});
      if(res.message != 'success'){
        vue.$notice({desc: res.describe});
        return false;
      }
      let contents = res.contents;
      // task_see_type == 1 欢迎消息[抹除关联id]
      if(contents && (task_need_relevancy || task_see_type == 1)){
        // // console.log('清空关联数据');
        // 抹除所有id [图片和附件的id不删除, 发布的时候再清空一次taskDtails的id]
        contents = clearAllIdField(contents);
        // 处理关联附件/图片数据
        contents.taskDeatails.forEach( item => {
          item.controlLinkList = taskDeatailsReservedFields(item.controlLinkList);
        })
      }
      
      contents.taskDeatails = generateWebsort(contents.taskDeatails); // 生成web排序 websort 
      contents.taskDeatails = taskDeatailsReservedFields(contents.taskDeatails); //处理保留字段 
      contents.taskDeatails = generateParentWebsort(contents.taskDeatails); // 处理关联字段
      // 清空相关数据 教学不清除控件id
      // TODO 关联未处理清空
      if(task_edit_status == 'clear' || task_edit_status== 'clearuser'){
        contents.taskUsers = await generateTaskUsers();
        contents.task = clearTaskData(contents.task);
      }
      // task_see_type == 1 欢迎消息[抹除控件id]
      if(task_edit_status== 'clear' ){ // 调用创建时不删除id
        contents.taskDeatails = clearTaskDetailsId(contents.taskDeatails);
      }
      return contents;
    }else{
      // 新建
      let route = vue.$route;
      let contents = {
        task: {},
        taskDeatails: [],
      }
      let taskUsers = await generateTaskUsers(); // 单聊时直接生成默认用户(默认选择自己)
      contents.taskUsers = taskUsers;
      return contents;
    }
  },
  /**
   * 转换消息类型
   * @param {*} type 
   */
  transformDetailsType(type){
    if(type == 1){
      return '创建';
    }else if(type == 2){
      return '更新';
    }else if(type == 3){
      return '删除';
    }
  },
  /**
   * 转换控件类型
   * @param {*} type 转换消息类型
   * @param {*} controlType 
   */
  transformDetailsControlType(type,controlType){
    if(controlType == 101){
      if(type == 2){
        return '任务标题';
      }
    }
    let json = {
      101: '任务',
      102: '参与人',
      103: '控件顺序',
      104: '关联',
      1: '文本',
      2: '数字',
      3: '金额',
      4: '时间',
      5: '公式',
      6: '附件',
      7: '链接',
      8: '位置',
      9: '图片',
      10: '名片',
    }
    return json[controlType];
  },
  /**
   * 转换消息简述标题
   * @param {*} type 
   * @param {*} controlType 
   * @param {*} title 
   */
  transformDetailsTitle(type, controlType, title){
    if(controlType == 102 || controlType == 103){
      return '';
    }else{
      return ` [${title}]`
    }
  },
  /**
   * 设置消息置顶[如果是当前的聊天 则滚动到顶部]
   * @param {Object} params {}
   * @return {Boolean} t=> 触发滚动到顶部
   */
  async setMessageTop(params){
    let {message_box_list, chat_detail} = vue.$store.state;
    let {chatType,chatId,istop} = params || {};
    if(chat_detail){
      if(chatType == chat_detail.chatType && chatId == chat_detail.chatId && istop != chat_detail.istop){
        chat_detail.istop = istop;
      }
    }
    
    let res, status;
    try{
      res = await vue.$http.request('/projectTask/top', {chatType, chatId, istop});
    }catch(err){
      status = false;
    }
    // 需要移动的index
    let index =  message_box_list.findIndex(item=>{
      return item.chatType == chatType && item.chatId == chatId;
    })
    let unit = message_box_list[index];
    if(index != -1){
      unit.istop = istop;
      if(istop == 1){
        // 置顶
        message_box_list.splice(index, 1);
        message_box_list.unshift(unit);
        status = true;
      }else{
        // 取消置顶
        message_box_list.splice(index, 1); // 移除
        // 要移动到的目标索引
        let aim_index = message_box_list.findIndex((item, index) => {
          return item.lastSendTime <= unit.lastSendTime && item.istop == 0;
        })
        if(aim_index != -1){
          message_box_list.splice(aim_index, 0, unit);
          status = true;
        }else{
          status = false;
        }
      }
    }else{
      status = false;
    }
    return status;
    
  },
  /**
   * 删除工作组
   * @param {Object} params {chatId} 
   */
  async deleteChat(params){
    let {id} = params || {};
    if(id){
      try{
        let res = await vue.$http.request('/projectTask/deleteChat', {id});
        if(res.message == 'success'){
          // 提示成功
          vue.$notice({desc: '解散成功', type:'success', duration: '1000', mask: false});
          // 删除消息盒子
          deleteMessageBoxUnit({chatId: id});
          // 删除工作组
          deleteWorkteamUnit({chatId: id});
          // 跳转到index页
          vue.$router.push({name: 'fun_task_index'});
        }else{
          vue.$notice({desc: res.describe, duration: '1000', mask: false});
        }
        
      }catch(err){
        // console.log(err);
      }
    }
  },
  /**
   * 删除模板
   * @param Object params {taskId,chatId, chatType}
   */
  async deleteTask(params){
    let status = true;
    let {taskId,chatId, chatType, taskType} = params || {};
    let url = '/projectTask/deleteTask'
    let data = {id: taskId, chatId, chatType}
    if(taskType === 2){
      url = '/projectTeach/deleteTeachTask'
      data = {
        taskId
      }
    }
    try{
      // let res = await vue.$http.request('/projectTask/deleteTask', {id: taskId, chatId, chatType});
      let res = await vue.$http.request(
        url,
        data,
      )
      if(res.message == 'success'){
        // 提示成功
        vue.$notice({desc: '删除成功', type:'success', duration: '1000', mask: false});
        
        // 更新消息列表
        updateMessageListAfterDeleteTask(params);
        // 更新相关模板
        deleteMyTaskListAfterDeleteTask(params);
        // 更新我的模板页相关
        deleteTemplateMyListAfterDeleteTask(params);
        if(taskType==2){
          return true
        }
      }else{
        vue.$notice({desc: res.describe, duration: '1000', mask: false});
        status = false;
      }
    }catch(err){
      // console.log(err);
      status = false;
    }
    return status;
  },
  /**
   * 任务是否已删除
   * @param {*} item
   * @return {Boolean} t已被删除 / f未删除
   */
  isDeleteTask(item){
    let {id, deleteFlag} = item || {};
    let status = false;
    // if(id && deleteFlag == 1){
    if(deleteFlag == 1){
      vue.$notice({desc: '该模板已被删除', duration: '1000', mask: false});
      status = true;
    }
    return status;
  },
  /**
   * 获取控件关联数据
   * @param {Object} params {id: 控件id}
   * @return {Array} 关联数据
   */
  async getLinks(params){
    let controlLinkList = [];
    let {id} = params || {};
    let res = await vue.$http.request('/projectTask/getLinks', {id});
    if(res.message == 'success'){
      controlLinkList = res.contents.controlLinkList;
    }
    return controlLinkList;
  },
  /**
   * 获取公示板详情
   * @param {*} params 
   */
  async getAnncDetails(params){
    let {chatId} = params || {};
    let chatType = vue.$tools.getChatType();
    
    if(chatId && chatType == 1){
      // vue.$showLoading({title: '加载中'});
      vue.$showLoading({title:'加载中'});
      try{
        let res = await vue.$http.request('/projectChat/taskNoticeDetail', {chatId});
        if(res.message == 'success' && res.contents.tasknoticeDeatails){
          let contents = res.contents;
          contents.tasknoticeDeatails = generateWebsort(contents.tasknoticeDeatails); // 生成web排序 websort 
          contents.tasknoticeDeatails = taskDeatailsReservedFields(contents.tasknoticeDeatails); //处理保留字段
          vue.$store.commit('set_annc_details', res.contents.tasknoticeDeatails);
        }else{
          vue.$store.commit('set_annc_details', []);
        }
      }catch(err){
        // console.log(err);
      }
      vue.$hideLoading();
      
    }

  },
}

/** [新建时]生成任务用户 默认选择自己*/
async function generateTaskUsers(){
  let {user_data, user} = vue;
  const {task_reelect_dialog} = vue.$store.state;
  let route = vue.$route;
  let task_user = [];
  if(!user){
    user = vue.$tools.getCookie('user');
  }
  // 默认选择自己
  task_user.push(user);
  // 单聊 && 不需要重新选择对象
  if(route.name == 'fun_task_dialog_single' && !task_reelect_dialog){
    // let chat_id = route.params.id;
    let chat_id = vue.$api.moduleTask.getRouteParamsId();
    if(user_data && user_data.userId == chat_id){
      task_user.push(user_data);
    }else{
      let res = await vue.$http.request('/project/getUserById', {userId: chat_id});
      vue.$store.commit('set_user_data', res.contents.user);
      task_user.push(res.contents.user);
    }
  }
  return task_user;
}
/** [获取控件详情时] 生成前端用排序 */
function generateWebsort(val){
  if(val && val.length){
    val.forEach( (item, index) => {
      item.websort = index+1;
    })
  }
  return val;
}
/** 清空任务id等信息 */
function clearTaskData(val){
  if(val){
    if(val.taskType != 2){
      delete val.id;
    }
    delete val.userId; //清除调用时的群主id
    // delete val.chatType;
    delete val.chatId;
    delete val.checkstatus;
    // delete val.;
  }
  return val;
}
/**
 * 清除控件id和文件id, 控件锁
 */
function clearTaskDetailsId(val){
  if(val && val.length){
    val.forEach(item => {
      delete item.id;
      if(item.fileList){
        item.fileList.forEach( unit => {
          delete unit.id;
          delete unit.chatId;
          delete unit.islock;
          delete unit.userlock;
        })
      }
    })
  }
  return val
}
/** 处理保留字段 其余删除 */
// 保存一份在origin_data中
function taskDeatailsReservedFields(data){
  if(data && data.length){
    data = vue.$tools.deepClone(data);
    
    data.forEach(item => {
      let fileds = [...common_reserved_fileds, ...type_reserved_fileds[item.type]];
      for(let i in item){
        if(!fileds.includes(i)){
          delete item[i]
        }
        item.controlLinkList = item.controlLinkList || [];
        if(item.fileList && item.fileList.length){
          item.fileList.forEach( unit => {
            unit.progress = 100;
            unit.selected = false;
          })
        }
        item.origin_data = vue.$tools.deepClone(item);
      }

    })
  }
  return data;
}
/**
 * 处理关联相关的数据 deep_level, parent_websort[相关的字段需要保留] (在websort生成后处理), switch_relevancy[关联开关]
 * @param {Array} list task_details
 */
function generateParentWebsort(list){
  
  if(list && list.length){
    list.forEach( item => {

      item.deep_level = 0;
      item.parent_websort = 0;
      item.switch_relevancy = false;
      let controlLinkJson = item.controlLinkJson;
      if(controlLinkJson && controlLinkJson.length){
        controlLinkJson = generateWebsort(controlLinkJson);
        controlLinkJson.forEach( subitem => {
          // 目前层级只考虑到1层
          subitem.deep_level = item.deep_level + 1;
          subitem.parent_websort = item.websort;
        })
      }
      
    })
    
  }
  return list;
}
/**
 * (解散工作组后)根据[群聊]id删除消息盒子
 * @param {Object} params {chatId}
 */
function deleteMessageBoxUnit(params){
  let {chatId} = params || {};
  let {message_box_list} = vue.$store.state;
  if(message_box_list){
    let index = message_box_list.findIndex( item => {
      return item.chatId == chatId && item.chatType == 1;
    })
    if( index != -1){
      message_box_list.splice( index, 1);
    }
  }
}
/**
 * (解散工作组后)根据[群聊]id删除工作组
 * @param {Object} params {chatId}
 */
function deleteWorkteamUnit(params){
  let {chatId} = params || {};
  let {workteam_list} = vue.$store.state;
  if(workteam_list){
    let index = workteam_list.findIndex( item => {
      return item.chatId == chatId && item.chatType == 1;
    })
    if( index != -1){
      workteam_list.splice( index, 1);
    }
  }
}
/**
 * (删除模板后)更新消息列表
 * @param {Object} params {taskId,chatId, chatType}
 */
function updateMessageListAfterDeleteTask(params){
  let {taskId,chatId, chatType} = params || {};
  let {message_list} = vue.$store.state;
  if(message_list){
    message_list.forEach(item => {
      if(item.taskId == taskId && item.chatId == chatId){
        item.deleteFlag = 1;
      }
    })
  }
}
/**
 * (删除模板后)删除相关/引用模板列表相应的模板
 * @param {Object} params {taskId,chatId, chatType}
 */
function deleteMyTaskListAfterDeleteTask(params){
  let {taskId,chatId, chatType} = params || {};
  let {my_task_list} = vue.$store.state;
  if(my_task_list){
    let new_my_task_list = my_task_list.filter(item => {
      return !(item.id == taskId && item.chatId == chatId);
    })
    if(new_my_task_list){
      vue.$store.commit('set_my_task_list', new_my_task_list);
    }
  }
}
/**
 * (删除模板后)删除我的模板频道相应的模板
 * @param {Object} params {taskId,chatId, chatType}
 */
function deleteTemplateMyListAfterDeleteTask(params){
  let {taskId,chatId, chatType} = params || {};
  let {template_my_list} = vue.$store.state.template;
  if(template_my_list){
    let new_template_my_list = template_my_list.filter(item => {
      return !(item.id == taskId && item.chatId == chatId);
    })
    // console.log(new_template_my_list, params)
    if(new_template_my_list){
      vue.$store.commit('set_template_my_list', new_template_my_list);
    }
  }
}
/**
 * 移除所有id字段
 * @param {Object}} contents 
 */
function clearAllIdField(contents){
  contents = vue.$tools.deepClone(contents);
  let type = getType(contents);
  let obj;
  if(type === 'array'){
    obj = [];
  } else if(type === 'object'){
      obj = {};
  } else {
      //不再具有下一层次
      return contents;
  }
  if(type === 'array'){
    for(var i = 0, len = contents.length; i < len; i++){
      obj.push(clearAllIdField(contents[i]));
    }
  } else if(type === 'object'){
      for(var key in contents){
        // 判断文件才有的字段
        const is_file = Boolean(contents.filename && contents.filetype && contents.filesize && contents.url);
        // 不删除文件的id
        if(i != 'id' || is_file){
          obj[key] = clearAllIdField(contents[key]);
        }
      }
  }
  return obj;
}

/**
 * 判断data类型array/object
 * @param {*} data 
 */
function getType(data){
  let aim = Object.prototype.toString.call(data);
  if(aim === '[object Object]'){
    return 'object';
  }
  if(aim === '[object Array]'){
    return 'array';
  }
  return false;
  // // console.log(Object.prototype.toString.call(a) === '[object Object]');//判断对象 返回true
  // // console.log(Object.prototype.toString.call(b) === '[object Array]');//判断数组 返回t
}
export default task;