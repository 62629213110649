var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_pop_up_migrate_task_select_task_thumbnail_task" },
    [
      _c("thumbnail-task", {
        attrs: {
          "p-task": _vm.pTask,
          "can-select": true,
          selected: _vm.selected,
        },
        on: { handleSelected: _vm.handleSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }