/*
 * @Author       : Hugo
 * @Date         : 2020-11-12 15:25:47
 * @LastEditTime : 2021-07-23 14:18:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/directives.js
 * @Description  : 全局自定义指令
 * @^_^          : 
 */


let Directives ={
  // 除某元素外点击 触发事件
  clickoutside: { // 初始化指令
    bind(el, binding, vnode) {
      function documentHandler(e) {
        // 这里判断点击的元素是否是本身，是本身，则返回
        if (el.contains(e.target)) {
          return false;
        }
        // 判断指令中是否绑定了函数
        if (binding.expression) {
          // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
          binding.value(e);
        }
      }
      // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
      el.__vueClickOutside__ = documentHandler;
      document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) { // 解除事件监听
      document.removeEventListener('click', el.__vueClickOutside__);
      delete el.__vueClickOutside__;
    }
  },
  // 防止重复点击
  preventReClick: {
    inserted: function (el, binding) {
      el.addEventListener('click', () => {
          if (!el.disabled) {
              // el.setAttribute('disabled',true)
              el.disabled = true;
              setTimeout(() => {
                  el.disabled = false;
                  // el.setAttribute('disabled', false);
              }, binding.value || 3000)
          }
      })
  }
  },
  /**
   * 元素resize
   * binding 需要有参{width,height}
   */
  resize: { // 指令的名称
    bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
      // // console.log(el,"绑定",binding);
      let width = '', height = '';
      function isReize() {
        const style = document.defaultView.getComputedStyle(el);
        if (width !== style.width || height !== style.height) {
          binding.value({width:style.width,height:style.height});  // 关键(这传入的是函数,所以执行此函数)
        }
        width = style.width;
        height = style.height;
      }
      el.__vueSetInterval__ = setInterval(isReize, 300);
    },
    unbind(el) {
      // // console.log(el,"解绑");
      clearInterval(el.__vueSetInterval__);
    }
  }
}

export default Directives;
