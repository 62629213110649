var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.action
    ? _c(
        "div",
        {
          staticClass: "weaken_common_page d-flex justify-center align-center",
        },
        [
          _vm.action.userName
            ? _c("div", { staticClass: "username" }, [
                _vm._v('"' + _vm._s(_vm.action.userName) + '"'),
              ])
            : _vm._e(),
          _vm.action.task_action
            ? _c("div", [_vm._v(" " + _vm._s(_vm.action.task_action))])
            : _vm._e(),
          _c("div", { staticClass: "action" }, [
            _vm._v(' "'),
            _c(
              "span",
              {
                staticClass: "name",
                class: { cp: _vm.action.type !== "delete_task" },
                on: {
                  click: function ($event) {
                    return _vm.handlerClick(_vm.action)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.action.taskName))]
            ),
            _vm._v('"\n  '),
          ]),
          _vm.action.task_hint
            ? _c("div", [_vm._v(" " + _vm._s(_vm.action.task_hint))])
            : _vm._e(),
          _vm.action.control_hint
            ? _c("div", [_vm._v(" " + _vm._s(_vm.action.control_hint))])
            : _vm._e(),
          _vm.action.user_action
            ? _c("div", [_vm._v(" " + _vm._s(_vm.action.user_action))])
            : _vm._e(),
          _vm.action.conTitle
            ? _c("div", [
                _vm._v(' "'),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.action.conTitle)),
                ]),
                _vm._v('"'),
              ])
            : _vm._e(),
          _vm.action.control_action
            ? _c("div", [_vm._v(" " + _vm._s(_vm.action.control_action))])
            : _vm._e(),
          _vm.action.relevanceTitle
            ? _c("div", [
                _vm._v(' "'),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.action.relevanceTitle)),
                ]),
                _vm._v('"'),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }