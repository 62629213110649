<!--
 * @Author       : Hugo
 * @Date         : 2022-01-10 10:09:58
 * @LastEditTime : 2022-01-10 16:53:06
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/ViewKeys/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      max_width="1000px"
      max_height="800px"
    >
      <div class="components_plugin_modules_view_keys_index pb-20 d-flex flex-column"
      >
        <common-title
            class        = "flex-shrink-0"
            @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
        >
          <slot>
            <div class="custom_title_group d-flex align-center justify-center bbox">
              <div class="title_group d-flex align-center">
                <div class="title">{{title}}</div>
              </div>
              <div class="operation_group d-flex align-center">
                <el-popover
                  placement="bottom-start"
                  width="120"
                  trigger="click"
                  popper-class="components_plugin_modules_view_keys_index_popover"
                  :visible-arrow="false"
                  v-model="switch_filter"
                > 
                  <div class="filter_list">
                    <div class="item"
                      :class="{active: filter_type === ''}"
                      @click.stop="changeFilter('');"
                    >全部</div>
                    <div class="item"
                      :class="{active: filter_type === 1}"
                      @click.stop="changeFilter(1);"
                    >已激活</div>
                    <div class="item"
                      :class="{active: filter_type === 0}"
                      @click.stop="changeFilter(0);"
                    >未激活</div>
                  </div>
                  <template
                    slot="reference"
                  >
                    <div class="filter_group d-flex align-center button mr-10">
                      <div class="text mr-6">{{filter_text}}</div>
                      <img src="~@/assets/images/payment/view_keys/filter_down.png" alt="" class="ic">
                    </div>
                  </template>
                </el-popover>
                <div class="import_group d-flex align-center button"
                  @click.stop="download();"
                >
                  <div class="text mr-6">导出</div>
                  <img src="~@/assets/images/payment/view_keys/export.png" alt="" class="icon">
                </div>
              </div>
              <div class="close cp" 
                @click.stop="handleClose();"
              ></div>
            </div>
          </slot>
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <view-keys-page
          class="main bbox"
          :order="order"
          :key_type="String(filter_type)"
        ></view-keys-page>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import ViewKeysPage from '@/components/Payment/ViewKeys/index.vue';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    ViewKeysPage,
  },
  data(){
    return {
      visible: false,
      title: '查看激活码',
      order: undefined,
      switch_filter: false,
      filter_type: '',
    }
  },
  computed: {
    filter_text(){
      const {filter_type} = this;
      switch(filter_type){
        case(''):
          return '全部';
        case(1):
          return '已激活';
        case(0):
          return '未激活';
        default:
          return '全部';
      }
    }
  },
  mounted(){
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    closeFilter(){
      this.switch_filter = false;
    },
    changeFilter(value){
      this.closeFilter();
      this.filter_type = value;
    },
    download(){
      const {order, filter_type:useflag} = this;
      const {orderId} = order;
      const url = `https://${this.$domain_name}/order/getBatchCodeExcel?orderId=${orderId}&useflag=${useflag}`;
      // console.log(url);
      // this.$api.download.byUrl({
      //   url:`https://${this.$domain_name}/order/getBatchCodeExcel?orderId=${orderId}&useflag=${useflag}`
      // });

      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url; 

      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(()=>{
        iframe.remove();
      }, 5 * 60 * 1000);
    }

  }
}
</script>

<style lang="scss" scoped>
.components_plugin_modules_view_keys_index{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .custom_title_group{
    padding         : 0 40px;
    min-height      : 50px;
    background-color: #f8f8f8;
    .close{
      right: 20px;
    }
    .operation_group{
      font-size: 14px;
      color: #666666;
      position: absolute;
      top: 50%;
      right: 70px;
      transform: translateY(-50%);
      font-weight: normal;
      .button{
        background-color: #fff;
        border: 1px solid #E5E7EA;
        border-radius: 4px;
        height: 30px;
        padding: 0 16px;
      }
    }
  }
  .main{
    flex   : 1;
    width  : 100%;
    // padding: 40px;
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.components_plugin_modules_view_keys_index_popover{
  min-width: 120px;
  padding: 0;
  margin-top: 4px!important;
  .filter_list{
    .item{
      @include transition;
      box-sizing: border-box;
      padding: 0 10px;
      line-height: 34px;
      cursor: pointer;
      &:hover, &.active{
        font-weight: bold;
        background-color: #ddd;
      }

    }
  }
}
</style>
