<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="60%"
      width="70%"
      max_width="400px"
      max_height="408px"
    >
      <div class="c_plugin_share_route d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose="handleClose"
          :title="title"
          :close="true"
          background_color="#fff"
        >
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <plugin-component
          v-if="visible"
          :dialog="dialog"
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from '@/components/PopUp/Dialog/SendFiles/index.vue'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      // title: '',
      onSure: undefined,
      onCancel: undefined,
      loading: false,
      work: '',
      params: undefined
    }
  },
  computed: {
    title() {
      const { params } = this
      const { title } = params
      return title ?? '发送图片'
    },
    dialog() {
      const { params } = this
      const { dialog } = params
      return dialog
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {
    handleClose(){
      const { dialog } = this.params
      const { uploadingFile } = dialog
      if (uploadingFile) {
        this.$notice({ desc:'正在上传，请不要关闭' })
        return
      }
      this.visible = false
    },
    async handleSure(selected_list){
      console.log(selected_list)
      if (this.input === '') {
        this.$notice({desc: this.hint})
        return
      }
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure(selected_list)
        this.loading = false
      }
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_share_route{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
