/*
 * @Author       : Hugo
 * @Date         : 2021-04-26 16:36:53
 * @LastEditTime : 2021-05-10 13:38:08
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/expand.js
 * @Description  : vue拓展功能
 * @^_^          : 
 */
import vue from '@/main.js';
const expand = {
  /**
   * 
   * @param {Array} params [{name: 'store字段名', modules: '模块字段名'}]
   * @returns 
   */
  generateComputed(params){
    let computed = {};
    if(params && params.length){
      params.forEach(item => {
        if(item.name){
          computed[item.name] = function(){
            if(item.modules){
              return this.$store.state[item.modules][item.name];
            }
            return this.$store.state[item.name];
          }
        }
        
      })
    }
    // // console.log(computed);
    return computed;
  },
  generateZIndexWatch(params, fun){
    let watch = {};
    if(params && params.length && fun){
      params.forEach(item => {
        watch[item] = function(){
          fun(item);
        }
      })
    }

    return watch;
  },
}

export default expand;