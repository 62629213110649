var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_pc_gift_card_plugins_save_poster_main bbox" },
    [
      _c(
        "div",
        {
          staticClass: "poster_group bbox d-flex flex-column align-center",
          attrs: { id: "poster" },
        },
        [
          _c(
            "div",
            { staticClass: "card_group bbox" },
            [
              _c(
                "div",
                { staticClass: "text_group" },
                [
                  _vm._l(_vm.sendword, function (item, index) {
                    return [
                      _c("div", { key: index, staticClass: "text" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
              _vm.pcarduser
                ? _c("card-component", {
                    staticClass: "mt-10",
                    attrs: {
                      width: "420px",
                      height: "244px",
                      pcard: _vm.pcarduser.card,
                      pcarduser: _vm.pcarduser,
                      show_indate: false,
                      "term-of-validity": String(_vm.pcarduser.getTime),
                      title_size: "28px",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "qrcode_group d-flex align-stretch bbox mt-20 align-self-start",
                },
                [
                  _c("img", {
                    staticClass: "qrcode mr-16",
                    attrs: { src: _vm.img_url, alt: "" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "info_group d-flex flex-column justify-space-between align-start",
                    },
                    [
                      _vm._m(0),
                      _c("div", { staticClass: "sub" }, [
                        _vm._v(
                          "\n            请在 " +
                            _vm._s(
                              _vm
                                .$moment(_vm.pcarduser.getTime)
                                .format("YYYY-MM-DD")
                            ) +
                            " 之前领取\n          "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._m(1),
        ]
      ),
      _c("div", { staticClass: "button_group bbox" }, [
        _c(
          "div",
          {
            staticClass: "button btn_dark",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doSave()
              },
            },
          },
          [_vm._v("保存海报")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("长按或扫描二维码")]),
      _c("div", { staticClass: "title" }, [_vm._v("领取权益")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hint_group bbox" }, [
      _c("div", { staticClass: "hint" }, [_vm._v("温馨提示：")]),
      _c("div", { staticClass: "hint" }, [
        _vm._v("1、每张礼品卡仅限一人领取使用。"),
      ]),
      _c("div", { staticClass: "hint" }, [
        _vm._v(
          "2、礼品卡是妙行推出的具有不同权益的虚拟卡，包含的权益以卡面信息为准。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }