/*
 * @Author       : Hugo
 * @Date         : 2021-11-11 16:32:58
 * @LastEditTime : 2022-06-10 00:49:19
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/api/modules/app/index.js
 * @Description  : 
 * @^_^          : 
 */
// import vue from '@/main';
import global from '@/api/global'
import vue from '../../../main';
// // console.log(vue);
class App{
  /**
   * 标签页激活监听
   */
  labelPageActivationEventListener(){
    const {commit, state} = vue.$store;
    // 标签页被激活 处理兼容
    var bowhidden="hidden" in document?"hidden": "webkithidden" in document?"webkithidden": "mozhidden" in document ?"mozhidden": null;
    var vibchage="visibilitychange" || "webkitvisibilitychange" || "mozvisibilitychange";
    document.addEventListener(vibchage, ()=>{
      /*ie10+  moz  webkit  默认*/
      if(!document[bowhidden]) /*false*/
      { 
        // // console.log("激活");
        const now = Date.now();
        const {label_page_hide_time_stamp} = state;
        // // console.log(label_page_hide_time_stamp, now)
        if(label_page_hide_time_stamp){

          const diff_hours = vue.$moment(now).diff(
            vue.$moment(state.label_page_hide_time_stamp),
            'hours'
          );
          // // console.log(diff_hours);
          // 超过三小时重新加载
          if(diff_hours > 3){
            location.reload();
          }
        }
        // this.$api.version.check();
      }
      else{
        /*true*/
        // // console.log("隐藏");
        // const hide_time = vue
        commit('set_label_page_hide_time_stamp', Date.now());

      }
    });
  }
  /**
   * 检测域名变化
   */
  domainChange(){
    const location = window.location;
    const {hostname, pathname} = location;
    const filter_arr = [
      '/download/index',
      '/system_notice/domain_change'
    ]
    if(hostname === 'pmcs.ourutec.com'){
      const is_filter = filter_arr.find(item=>{
        return pathname === item;
      })
      if(!is_filter){
        vue.$router.push({name: 'domain_change'});
        return true;
      }
    }
    return false;
  }
  is_ourutec(){
    const location = window.location;
    const {hostname} = location;
    if(hostname === 'pmcs.ourutec.com'){
      return true;
    }
    return false;
  }

  isWechat = ()=>{
    let is_wechat = false;
    let wx = navigator.userAgent.toLowerCase()
    if (wx.match(/MicroMessenger/i) == 'micromessenger') {
      is_wechat = true
    } else {
      is_wechat = false
    }
    this.is_wechat = is_wechat;
    return is_wechat;
  }

  isIos = ()=>{
    var u = navigator.userAgent,
    isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if(isiOS){
      this.is_ios = true;
      return true;
    }
    return false;
  }

  getAppUrl = async ()=> {
    const res = await global.doPost({
      url: 'projectCommon/download'
    })
    if(res.message == 'success'){
      const {downloadAppAndroid,downloadAppIOS} = res.contents;
      return {downloadAppAndroid,downloadAppIOS}
    }
    return false
  }


  downloadApp = (params={}) => {
    const {url, platform} = params ?? {}
    if(!url){
      return 
    }
    if(!platform){
      return 
    }
    if(platform === 'android'){

      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url; 
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(()=>{
        iframe.remove();
      }, 5 * 60 * 1000);
    }

    if(platform === 'ios'){
      window.location.href=url
    }


  }
  
  downloadAndroid = (params) => {
    const {url} = params
    this.downloadApp({url, platform: 'android'})
  }
  
  downloadIos = (params) => {
    const {url} = params
    this.downloadApp({url, platform: 'ios'})
  }



} 

const app = new App();

export default app;