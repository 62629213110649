<template>
  <div class="newPass-conr">
    <!-- 用户信息 -->
    <div class="right">
      <!-- <p class="text">请设置新密码</p> -->
      <div class="i-box">
        <img src="../../../assets/icon-password.png" class="img2" alt />
        <div v-if="sel">
          <input
            v-model="userName"
            class="eye"
            :type="types"
            placeholder="请设置新密码"
            maxlength="8"
          />
          <img
            src="../../../assets/icon-sel.png"
            alt
            @click="showps"
            class="sel"
            v-show="(userName || password)"
          />
        </div>
        <div v-else>
          <input
            v-model="userName"
            class="eye"
            :type="types"
            placeholder="请设置新密码"
            maxlength="8"
          />
          <img
            src="../../../assets/icon-eye.png"
            alt
            @click="showps"
            class="sel"
            v-show="(userName || password)"
          />
        </div>
        <!-- 输入密码 -->
        <div class="hint">请输入6-8位的数字或字母</div>
      </div>
      <div class="line"></div>
      <!-- <p class="text">请确认新密码</p> -->
      <div class="i-box" style="padding-top: 56px;">
        <img src="../../../assets/icon-password.png" class="img2" alt />

        <div v-if="sel">
          <input
            class="password"
            :type="types"
            v-model="password"
            placeholder="请确认新密码"
            maxlength="8"
          />

          <img src="../../../assets/icon-sel.png" alt @click="showps" class="sel" v-if="0"/>
        </div>
        <div v-else>
          <input 
            class="password"
            :type="types"
            v-model="password"
            placeholder="请确认新密码"
            maxlength="8"
          />
          <img src="../../../assets/icon-eye.png" alt @click="showps" class="sel" v-if="0" />
        </div>
      </div>
      <div class="line1"></div>
      <p v-html="userNameWarn" class="cuowu"></p>
      <div class="box">
        <el-button
          class="newPass_btn"
          @click.native="login"
          type="primary"
          round
          :loading="isBtnLoading"
        >保存并登录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
      types: "password",
      check: false,
      nor: true,
      isBtnLoading: false,
      sel: true,
      userNameWarn: ""
    };
  },
  computed: {
    btnText() {
      if (this.isBtnLoading) return "登录中...";
      return "登录";
    }
  },
  watch:{
    userName: function(val){
      if(val){
        val = val.replace(/[^\w]/g,'');
        this.userName = val;
      }
    },
    password: function(val){
      if(val){
        val = val.replace(/[^\w]/g,'');
        this.password = val;
      }
    },
  },
  methods: {
    login() {
      if(!this.userName || !this.password){
        this.userNameWarn = '请设置新密码';
        return;
      }
      if(this.userName.length<6){
        this.userNameWarn = '请输入6-8位的数字或字母';
        return;
      }
      if (this.userName === this.password) {
        this.userNameWarn = "";
        this.$emit("logIn",this.password)
      } else {
        this.userNameWarn = "两次输入密码不一致";
      }
    },

    showps() {
      this.$data.types = this.$data.types === "password" ? "text" : "password";
      this.$data.sel = !this.$data.sel;
    }
  }
};
</script>
<style lang="less" scoped>
.newPass-conr {
  width: 724px;
  height: 650px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;

  .right {
    position: relative;

    .text {
      margin-top: 50px;
      width: 126px;
      height: 17px;
      font-size: 18px;
      
      font-weight: 400;
      color: rgba(130, 146, 156, 1);
      margin-bottom: 10px;
    }

    .i-box {
      display: flex;
      position: relative;
      align-items: center;
      padding-bottom: 17px;
      input {
        outline: none;
        display: block;
        width: 330px;
        height: 30px;
        font-size: 18px;
        
        font-weight: 400;
        border: none;
        color: rgba(130, 146, 156, 1);
        margin-left: 10px;
      }
      .hint{
        transition: all 0.2s;
        font-size: 12px;
        line-height: 20px;
        color: #999;
        position: absolute;
        width: 100%;
        bottom: -30px;
        left: 0;
        opacity: 0;
      }
      &:hover{
        .hint{
          opacity: 1;
        }
      }

      .img2 {
        display: flex;
        width: 18px;
        height: 22px;
        // margin-top: 10px;
        // margin-bottom: 5px;
      }

      .sel {
        width: 18px;
        height: 12px;
        position: absolute;
        left: 308px;
        top: 10px;
      }

      .password {
        // margin-top: 5px;
      }
    }

    .line {
      width: 330px;
      height: 1px;
      background: rgba(213, 217, 219, 1);
    }

    .line1 {
      width: 330px;
      height: 1px;
      background: rgba(213, 217, 219, 1);
    }

    .cuowu {
      display: block;
      width: 220px;
      height: 15px;
      font-size: 14px;
      color: #d82e0d;
      margin-top: 15px;
    }

    .box {
      margin-top: 50px;

      .newPass_btn {
        width: 330px;
        height: 50px;
        background: rgba(0, 84, 166, 1);
        border-radius: 4px;
        border: none;
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
        margin-right: 10px;
      }

      .newPass_btn:hover {
        background: rgba(0, 64, 128, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
      }
    }
  }
}
</style>