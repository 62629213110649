var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "plugin_confirm",
            style: { "z-index": _vm.zindex },
            on: { click: _vm.handleMask },
          },
          [
            _c(
              "div",
              {
                staticClass: "confirm_group",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.stopPropagation()
                  },
                },
              },
              [
                _vm.cancelable
                  ? _c("div", {
                      staticClass: "close cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.doClose.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.desc,
                        expression: "desc",
                      },
                    ],
                    staticClass: "desc",
                  },
                  [_vm._v(_vm._s(_vm.desc))]
                ),
                _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn sure",
                      class: {
                        btn_dark: _vm.sureType === "",
                        btn_warn: _vm.sureType === "warn",
                        btn_light: _vm.sureType === "light",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.doSure.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.sure))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn cancel",
                      class: {
                        btn_light: !_vm.cancelType,
                        btn_warn: _vm.cancelType === "warn",
                        btn_dark: _vm.cancelType === "dark",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.doCancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.cancel))]
                  ),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }