/*
 * @Author       : Hugo
 * @Date         : 2021-11-25 14:55:59
 * @LastEditTime : 2022-02-24 10:02:14
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/store/stateModule/task.js
 * @Description  : 
 * @^_^          : 
 */
const task = {
  // task_log: null,
  // FEATURE 任务数据
  task: {},
  // FEATURE 任务原始数据
  task_original: null,
  // FEATURE 任务配置
  task_config: {
    system: false, // 系统消息
    view: false, // 查看
    create: false, // 新建
    edit: false, // 编辑
    call: false, // 调用
    draft: false, // 草稿
    teach: false, // 有教学内容
    recycle: false, // 回收
    public_notice: false, // 公示板
    create_teach: false, // 创建教学
    eidt_teach: false, // 编辑教学
    create_instance: false, // 创建实例
    edit_instance: false,  // 编辑实例
    create_draft: false, // 创建草稿 // 配合 create_teach
    edit_draft: false, // 编辑草稿 
    call_custom: false, // 调用自定义分类
    moved: false, // 排序
    modified: false,
    editable: true,
    users: [],
    switch_sidebar: true,
    widget_sort: 0, // 控件数量
    task_name: '',
    color: '', // 颜色
    privacy: false, // 隐私
    select_target: false, // 重新选择对象
    select_target_chat_id: null, // 重新选择对象id
    select_target_chat_type: null, // 重新选择对象type
    chat_id: null, 
    chat_type: null,
    tags: null,
    addtags: '',
    deletetagIds: '',
    price: '',
    from: null, // 来源: 
    jump: false, // 发送后跳转
    call_teach: false, // 调用教学
    creater_id: undefined, //创建者id
    appoint_widget: null, // 打开跳转到指定控件 {parent_id,widget_id}
    uploading_teach_fileList: false,
    uploading_teach_videoList: false,
    chat: false, // 是否从聊天开始
    share: false, // 分享
    use:  false,
    task_log: null, // 打开时触发的log
    introduction: undefined , //简介
    open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
    // open_with_call_page: false, // 打开时打开调用页面, 配合 open_by_call
    reEditTeach: false, // 重新编辑教学
  },
  task_config_basic: {
    system: false, // 系统消息
    view: false, // 查看
    create: false, // 新建
    edit: false, // 编辑
    call: false, // 调用
    draft: false, // 草稿
    teach: false, // 有教学内容
    recycle: false, // 回收
    public_notice: false, // 公示板
    create_teach: false, // 创建教学
    eidt_teach: false, // 编辑教学
    create_instance: false, // 创建实例
    edit_instance: false,  // 编辑实例
    create_draft: false, // 创建草稿 // 配合 create_teach
    edit_draft: false, // 编辑草稿 
    call_custom: false, // 调用自定义分类
    moved: false, // 排序
    modified: false,
    editable: true,
    users: [],
    switch_sidebar: true,
    widget_sort: 0, // 控件数量
    task_name: '',
    color: '', // 颜色
    privacy: false, // 隐私
    select_target: false, // 重新选择对象
    select_target_chat_id: null, // 重新选择对象id
    select_target_chat_type: null, // 重新选择对象type
    chat_id: null, 
    chat_type: null,
    tags: null,
    addtags: '',
    deletetagIds: '',
    price: '',
    from: null, // 来源: 
    jump: false, // 发送后跳转
    call_teach: false, // 调用教学
    creater_id: undefined, //创建者id
    appoint_widget: null, // 打开跳转到指定控件 {parent_id,widget_id}
    uploading_teach_fileList: false,
    uploading_teach_videoList: false,
    chat: false, // 是否从聊天开始
    share: false, // 分享
    relevancy: false, // 加载关联数据
    use: false, // 我要使用
    chat_message_id: null, // 指定聊天id
    task_log: null, // 打开时触发的log
    introduction: undefined, // 简介
    open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
    open_with_call_page: false, // 打开时打开调用页面, 配合 open_by_call
    reEditTeach: false, // 重新编辑教学
  },
  teach_files: {
    fileList: [],
    linkList: [],
    videoList: [],
  },
  teach_files_basic: {
    fileList: [],
    linkList: [],
    videoList: [],
  },
  teach_config: {
    price: '',
  },
  teach_config_basic: {
    price: '',
  },
  // 任务参与人
  task_users_list: {
    task_id: null,
    list: [],
  },
  // 是否有未发送消息 [打开任务时重置为 false]
  task_unsent_message: false,

  task_chat_at_data: null,

  task_chat_at_callback: null,

  /**任务调用参数 关闭任务 及点击新建时重置 */
  task_call_config: {
    search_type: 'default',
    search_keyword: '',
    switch_custom: false,
    tag_list: [],
    appoint: null,
    search_tags: '',
    package: false, // 搜索任务/教学包
    package_id: undefined, // 教学包id
  },
}

export default task;