/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-30 12:03:47
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 18:12:58
 * @FilePath     : /miaohang/src/router/active_page.js
 * @Description  : 
 */
const active_page = [
  {
    path: '/active_page',
    name: 'active_page',
    component: () => import('@/views/ActivePage/index.vue'),
    children: [
      // 妙行链动
      {
        path: 'plan',
        name: 'active_page_plan',
        component: () => import('@/views/ActivePage/Plan/Plan.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'plan_success',
        name: 'active_page_plan_success',
        component: () => import('@/views/ActivePage/Plan/Success.vue'),
        meta: {allowBack: false},
        props: true,
      },

      // 活动实验室扫码
      {
        path: 'laboratory',
        name: 'active_page_laboratory',
        component: () => import('@/views/ActivePage/Laboratory/index.vue'),
        meta: {allowBack: false},
      },
      /**腾讯会议 */
      {
        path: 'tencent_meeting_v1',
        name: 'tencent_meeting_v1',
        component: () => import('@/views/ActivePage/TencentMeeting/v1.vue'),
        meta: {allowBack: false},
      },
      /**接收礼品卡 */
      {
        path: 'receiving_gift_card',
        name: 'receiving_gift_card',
        component: () => import('@/views/ActivePage/ReceivingGiftCard/index.vue'),
        meta: {allowBack: false},
      },
      /**查看任务 */
      {
        path: 'check_task',
        name: 'check_task',
        component: () => import('@/views/ActivePage/CheckTask/index.vue'),
        meta: {allowBack: false},
      },
      /** 智慧桌技术支持 */
      {
        path: 'technology',
        name: 'ap_technology',
        component: () => import('@/views/ActivePage/Technology/index.vue'),
        meta: {allowBack: false},
      },
      /** CJ Active */
      {
        path: 'cjactive',
        name: 'ap_cjactive',
        component: () => import('@/views/ActivePage/CJActive/index.vue'),
        meta: {allowBack: false},
      }
    ]
  }
]

export default active_page;