<template>
  <div
    class="components_popup_record_of_communication_text_index_page bbox"
  >
    <template
      v-for="(item, index) in list"
    >
      <unit
        class="mb-20"
        :key="index"
        :item="PTaskLog(item)"
        :keyword="keyword"
        :chat_type="Number(chat_type)"
        :chat_id="Number(chat_id)"
      ></unit>
    </template>
    <div class="nomore py-10"
      v-if="nomore"
    >没有更多了</div>
    <div class="loading d-flex justify-center"
      v-if="loading"
    >
      <loading
        size="20px"
      ></loading>
    </div>
    
    
  </div>
</template>

<script>
import Unit from './Text.vue';
import Loading from '@/components/basic/loadingIcon.vue';
import {PTaskLog} from '@/types';
export default {
  components: {
    Unit,
    Loading,
  },
  data() {
    return {
      list: [],
      loading: false,
      nomore: false,
    }
  },
  props:{
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  watch:{
    keyword: function(val){
      if(val){
        this.clear();
        this.init();
      }
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    clear(){
      this.loading = false;
      this.nomore = false;
      this.$set(this, 'list', []);
    },
    async init(){
      await this.getList();
    },
    async getList(){
      this.loading = true;
      const {chat_id, chat_type, keyword, list} = this;
      if(isNaN(chat_id)){
        return;
      }
      if(isNaN(chat_type)){
        return;
      }
      if(chat_type == -1){
        return;
      }
      const res = await this.$global.doPost({
        url: '/projectTaskLogSearch/searchTaskLogText',
        data: {
          keyword,
          chatType: chat_type,
          chatId: chat_id,
          start: list.length,
        }
      });
      this.loading = false;
      const {contents, message} = res;
      if(message === 'success'){
        const {tasklogList} = contents;
        if(tasklogList){
          this.$set(this, 'list', [...new Set([...list, ...tasklogList])]);
          if(tasklogList.length != 50){
            this.nomore = true;
          }
          return tasklogList;
        }

      }
      return false;
    },
    async handleLoadMore(){
      const {nomore, loading} = this;
      // console.log({nomore, loading})
      if(nomore || loading){
        return;
      }
      // console.log('getList')
      await this.getList();
    },
    PTaskLog(item){
      return new PTaskLog(item);
    },
    
  }
}
</script>

<style lang="scss" scoped>
.components_popup_record_of_communication_text_index_page{
  width: 100%;
  height: 100%;
  .loading{
    height: 40px;
  }
  .nomore{
    font-size: 14px;
    text-align: center;
    color: #999;
  }
}
</style>