<template>
  <div
    v-if="canShow"
    class="c_teaching_package_selected_task_list_thumbnail_task ml-5 mr-10 mb-10"
  >
    <thumbnail-task
      :p-task="pTask"
      :can-select="true"
      :selected="pTask.selected"
      @handleSelected="handleSelected"
    ></thumbnail-task>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/Thumbnail/Task'
import tools from '@/api/tools'
export default {
  components: {
    ThumbnailTask
  },
  props: {
    pTask: {
      type: Object,
      default: undefined
    },
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    search: {
      type: String,
      default: ''
    }
  },
  computed: {
    canShow() {
      const { pTask, search } = this
      const { name } = pTask
      if (search === '') {
        return true
      }
      const filterSearch = this.doSearch(name)
      if (filterSearch) {
        return true
      }
      return false
    }
  },
  methods: {
    handleSelected(params) {
      this.pTask.selected = !this.pTask.selected
      // const { pTask } = params
      // pTask.selected = !pTask.selected
      console.log(this.pTask, this.pTask.selected)
    },
    doSearch(str) {
      const { search } = this
      if (((str ?? '') !== '') && str.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        return tools.highlightKeywords(str, search)
      }
      return undefined
    }
  },
}
</script>

<style scoped lang="scss">
.c_teaching_package_selected_task_list_thumbnail_task{
}
</style>