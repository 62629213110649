var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about" }, [
    _c("div", { staticClass: "about-left" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "tupian",
          style: _vm.disabled
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(1)
            },
          },
        },
        [
          _vm.disabled
            ? _c("img", {
                staticClass: "book",
                attrs: {
                  src: require("../../assets/icon-message.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "book",
                attrs: { src: require("../../assets/book.png"), alt: "" },
              }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "pple",
          style: _vm.people
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(2)
            },
          },
        },
        [
          _vm.people
            ? _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-sel.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mpa",
          style: _vm.mpashow
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(3)
            },
          },
        },
        [
          _vm.mpashow
            ? _c("img", {
                staticClass: "mplae",
                attrs: { src: require("../../assets/modulex.png"), alt: "" },
              })
            : _c("img", {
                staticClass: "mplae",
                attrs: {
                  src: require("../../assets/icon-Template-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
    ]),
    _c("div", { staticClass: "about-con" }, [
      _c("div", { staticClass: "ger", on: { click: _vm.gerenziliao } }, [
        _c("img", {
          staticClass: "ren",
          attrs: { src: require("../../assets/icon-personal.png"), alt: "" },
        }),
        _c("a", { staticClass: "personal" }, [_vm._v("个人资料")]),
      ]),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "gdeta", on: { click: _vm.xiugaimima } }, [
        _c("img", {
          staticClass: "datap",
          attrs: { src: require("../../assets/icon-passwordx.png"), alt: "" },
        }),
        _c("a", { staticClass: "detam" }, [_vm._v("修改密码")]),
      ]),
      _c("div", { staticClass: "line" }),
      _vm._m(1),
      _c("div", { staticClass: "line" }),
      _vm._m(2),
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "out", on: { click: _vm.tuichudenglu } }, [
        _c("img", {
          staticClass: "outp",
          attrs: { src: require("../../assets/icon-drop out.png"), alt: "" },
        }),
        _c("a", { staticClass: "outl" }, [_vm._v("退出登录")]),
      ]),
      _c("div", { staticClass: "line" }),
    ]),
    _c("div", { staticClass: "xian" }),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "logop",
        attrs: { src: require("../../assets/logo.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "set" }, [
      _c("img", {
        staticClass: "setp",
        attrs: { src: require("../../assets/icon-Setup.png"), alt: "" },
      }),
      _c("a", { staticClass: "setz" }, [_vm._v("设置")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "equity" }, [
      _c("img", {
        staticClass: "equp",
        attrs: { src: require("../../assets/icon-vipx.png"), alt: "" },
      }),
      _c("a", { staticClass: "equityy" }, [_vm._v("用户权益")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "about-right" }, [
      _c("div", { staticClass: "aboutc" }, [
        _c("div", { staticClass: "plate" }, [
          _c("h2", { staticClass: "platec" }, [_vm._v("模板")]),
          _c("div", { staticClass: "with1" }),
          _c("p", { staticClass: "p1" }, [_vm._v("联系对话")]),
          _c("div", { staticClass: "withx" }),
          _c("p", { staticClass: "p2" }, [_vm._v("模板")]),
          _c("div", { staticClass: "withxm" }),
          _c("p", { staticClass: "p3" }, [_vm._v("签约讲师")]),
          _c("div", { staticClass: "withq" }),
          _c("p", { staticClass: "p4" }, [_vm._v("学习")]),
          _c("div", { staticClass: "withi" }),
        ]),
        _c("div", { staticClass: "tmp" }, [
          _c("h2", { staticClass: "tmpl" }, [_vm._v("功能")]),
          _c("div", { staticClass: "with2" }),
          _c("ul", [
            _c("li", { staticClass: "lan1" }, [_vm._v("新建对话")]),
            _c("li", { staticClass: "lan" }, [_vm._v("接受对话")]),
            _c("li", { staticClass: "lan2" }, [
              _vm._v("增加成员（转为工作组）"),
            ]),
          ]),
          _c("div", { staticClass: "withx1" }),
          _c("ul", [
            _c("li", { staticClass: "xj" }, [_vm._v("新建模板")]),
            _c("li", { staticClass: "xj1" }, [_vm._v("收藏模板")]),
            _c("li", { staticClass: "xj2" }, [_vm._v("接收/编辑模板")]),
          ]),
          _c("div", { staticClass: "withxm1" }),
          _c("ul", [
            _c("li", { staticClass: "qyue" }, [_vm._v("签约并上传教学内容")]),
            _c("li", { staticClass: "qyue1" }, [_vm._v("共享教学收益")]),
          ]),
          _c("div", { staticClass: "withq1" }),
          _c("ul", [
            _c("li", { staticClass: "study" }, [_vm._v("学习教学内容")]),
          ]),
          _c("div", { staticClass: "withi1" }),
        ]),
        _c("div", { staticClass: "nary" }, [
          _c("h2", { staticClass: "nar" }, [_vm._v("普通用户")]),
          _c("div", { staticClass: "with3" }),
          _c("p", { staticClass: "xn" }),
          _c("img", {
            staticClass: "duix",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("p", { staticClass: "xn2" }),
          _c("div", { staticClass: "withx2" }),
          _c("p", { staticClass: "xn3" }),
          _c("p", { staticClass: "xn4" }),
          _c("img", {
            staticClass: "duix1",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("div", { staticClass: "withm2" }),
          _c("p", { staticClass: "xn5" }),
          _c("p", { staticClass: "xn6" }),
          _c("div", { staticClass: "withq2" }),
          _c("h3", { staticClass: "price" }, [_vm._v("享受普通用户价格")]),
          _c("div", { staticClass: "withi2" }),
          _c("h2", { staticClass: "mf" }, [_vm._v("免费")]),
        ]),
        _c("div", { staticClass: "vyh" }, [
          _c("h4", { staticClass: "vpyh" }, [_vm._v("VIP用户")]),
          _c("div", { staticClass: "with4" }),
          _c("img", {
            staticClass: "imgdui",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "imgdui2",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "imgdui3",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("div", { staticClass: "withx3" }),
          _c("img", {
            staticClass: "imgdui4",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "imgdui5",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "imgdui6",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("div", { staticClass: "withm3" }),
          _c("img", {
            staticClass: "imgdui7",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "imgdui8",
            attrs: { src: require("../../assets/icon-duix.png"), alt: "" },
          }),
          _c("div", { staticClass: "withq3" }),
          _c("h5", { staticClass: "jiage" }, [_vm._v("享受VIP用户价格")]),
          _c("div", { staticClass: "withi3" }),
          _c("p", { staticClass: "old" }, [_vm._v("388元/年")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }