var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_batch_add_contacts_to_tasks_task_list bbox" },
    [
      _c(
        "div",
        { staticClass: "list_group d-flex flex-wrap bbox" },
        [
          _vm._l(_vm.taskList, function (item, index) {
            return [
              _c("thumbnail-task", {
                key: index,
                attrs: { "p-task": item, search: _vm.search },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }