import vue from '@/main.js'
import fun from './index'
import teachFun from './teachFun'
class CreateDraft{
  main = async () => {
    const { task_config } = vue.$store.state
    const { create_teach } = task_config
    const task_res = await fun.createTask({
      create_draft: true
    })
    if (!task_res || !task_res.contents) {
      return false
    }
    const { task } = task_res.contents
    const { id } = task
    if (create_teach) {
      await teachFun.uploadTeachFile({
        taskId: id
      })
    }
    return task_res
  }
}

const createDraft = new CreateDraft()

export default createDraft.main