var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pages" }, [
    _c("div", { staticClass: "pages-left" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "tupian",
          style: _vm.disabled
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(1)
            },
          },
        },
        [
          _vm.disabled
            ? _c("img", {
                staticClass: "book",
                attrs: {
                  src: require("../../assets/icon-message.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "book",
                attrs: { src: require("../../assets/book.png"), alt: "" },
              }),
        ]
      ),
      _c("div", { staticClass: "bok" }),
      _c(
        "div",
        {
          staticClass: "pple",
          style: _vm.people
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(2)
            },
          },
        },
        [
          _vm.people
            ? _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-sel.png"),
                  alt: "",
                },
              })
            : _c("img", {
                staticClass: "people",
                attrs: {
                  src: require("../../assets/icon-people-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mpa",
          style: _vm.mpashow
            ? "backgroundColor:#FFFFFF;"
            : "backgroundColor:#0054A6;",
          on: {
            click: function ($event) {
              return _vm.book(3)
            },
          },
        },
        [
          _vm.mpashow
            ? _c("img", {
                staticClass: "mplae",
                attrs: { src: require("../../assets/modulex.png"), alt: "" },
              })
            : _c("img", {
                staticClass: "mplae",
                attrs: {
                  src: require("../../assets/icon-Template-nor.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _c("img", { staticClass: "phical", attrs: { src: "", alt: "" } }),
    ]),
    _c("div", { staticClass: "pages-con" }, [
      _c("div", { staticClass: "bar" }, [
        _c("div", { staticClass: "barinput" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchString,
                expression: "searchString",
              },
            ],
            staticClass: "hder",
            attrs: { type: "text", placeholder: "搜索" },
            domProps: { value: _vm.searchString },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchString = $event.target.value
              },
            },
          }),
          _c("img", {
            staticClass: "search",
            attrs: { src: require("../../assets/icon-search.png"), alt: "" },
          }),
        ]),
        _vm._m(1),
      ]),
      _c("div", { staticClass: "listbox" }, [
        _c(
          "ul",
          { staticClass: "list" },
          _vm._l(_vm.zhidingArr, function (item, index) {
            return _c("li", { key: index, staticClass: "lbo" }, [
              _c("div", { staticClass: "lbo1" }, [
                _c("img", { staticClass: "img1", attrs: { src: "", alt: "" } }),
                _c("div", { staticClass: "lboleft" }, [
                  _c("div", { staticClass: "zhao" }, [
                    _c("p", { staticClass: "ptop" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("p", { staticClass: "tim" }, [_vm._v("11:45")]),
                    _c("img", {
                      staticClass: "sticy",
                      attrs: {
                        src: require("../../assets/label-Sticky.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "pbottom" }, [
                    _c("p", { staticClass: "vloe" }, [
                      _vm._v(_vm._s(item.explain)),
                    ]),
                    _c("img", {
                      staticClass: "more",
                      attrs: {
                        src: require("../../assets/icon-More.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.diandian(item)
                        },
                      },
                    }),
                  ]),
                  item.flag
                    ? _c("p", { staticClass: "qu" }, [_vm._v("取消置顶")])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "linex" }),
            ])
          }),
          0
        ),
        _c(
          "ul",
          { staticClass: "list" },
          _vm._l(50, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "lbo",
                on: {
                  click: function ($event) {
                    return _vm.lbo(index)
                  },
                },
              },
              [_vm._m(2, true), _c("div", { staticClass: "linex" })]
            )
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "dtleft" },
      [_c("DetailInfo", { attrs: { info: _vm.detailsInfo } })],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "logo" }, [
      _c("img", { attrs: { src: require("../../assets/logo.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "imgbox" }, [
      _c("img", {
        staticClass: "jia",
        attrs: { src: require("../../assets/icon-add.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lbo1" }, [
      _c("img", { staticClass: "img1", attrs: { src: "", alt: "" } }),
      _c("div", { staticClass: "lboleft" }, [
        _c("div", { staticClass: "zhao" }, [
          _c("p", { staticClass: "ptop" }, [_vm._v("赵丽颖")]),
          _c("p", { staticClass: "tim" }, [_vm._v("11:45")]),
          _c("img", {
            staticClass: "sticy",
            attrs: { src: require("../../assets/label-Sticky.png"), alt: "" },
          }),
        ]),
        _c("div", { staticClass: "pbottom" }, [
          _c("p", { staticClass: "vloe" }, [_vm._v("按照VIVO的KV，开始...")]),
          _c("img", {
            staticClass: "more",
            attrs: { src: require("../../assets/icon-More.png"), alt: "" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }