var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_popup_gift_card_payment_success_index d-flex bbox flex-column align-center",
    },
    [
      _c("div", {
        staticClass: "close cp",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleClose.apply(null, arguments)
          },
        },
      }),
      _vm._m(0),
      _c("div", { staticClass: "describe_group d-flex align-center" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.time) + "s后将跳转到填写寄语，或\n    "
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "jump cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.openGiftCardGiveAway.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      点击跳转\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "title_group d-flex align-center justify-center" },
      [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "title" }, [_vm._v("支付成功")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }