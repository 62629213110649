<template>
  <div class="c_teaching_package_select_task_thumbnail_package">
    <div class="title">
      {{ pPackage.title }}
    </div>
    <img :src="`${$img_url}${pPackage.webpic}`" alt="" class="picture">
  </div>
</template>

<script>
export default {
  name: 'ThumbnailPackage',
  props: {
    pPackage: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_thumbnail_package{
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  color: #333;
  padding: 20px;
  width: 280px;
  height: 210px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border: 1px solid #E8EEFF;
  .title{
    padding-bottom: 16px;
  }
  .picture{
    width: 100%;
  }
}
</style>