/*
 * @Author       : Hugo
 * @Date         : 2020-07-01 16:13:19
 * @LastEditTime : 2020-07-01 16:42:18
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : \miaohang\src\store\modules\im_chat.js
 * @^_^
 */ 
const im_chat = {
  actions: {
    /**
     * 发送文本消息
     * @param {*} context 
     * @param {*} payload 
     */
    imChatSendText(context, payload){
      let {rootState, commit} = context;
      // console.log(rootState.contact_data);
      // console.log(payload);
      let {messageContent} = payload;

      const id = WebIM.conn.getUniqueId();
      const msgObj = new WebIM.message("txt", id);
      msgObj.set({
          msg: messageContent,                  // 消息内容
          to: `userid_${payload.chatId}`,                          // 接收消息对象（用户id）
          roomType: false,
          ext: payload,                                  //扩展消息
          success: function (id, serverMsgId) {
              // console.log('send private text Success');  
          },                                       // 对成功的相关定义，sdk会将消息id登记到日志进行备份处理
          fail: function(e){
              // console.log("Send private text error");  
          }                                        // 对失败的相关定义，sdk会将消息id登记到日志进行备份处理
      });
      WebIM.conn.send(msgObj.body);
    }
  }
}

export default im_chat;