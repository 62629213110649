/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-12 14:34:34
 * @LastEditors  : hugo
 * @LastEditTime : 2024-03-21 13:42:23
 * @FilePath     : /pmcs_web/src/api/modules/consulting.js
 * @Description  : 
 */
import vue from '@/main';
class Consulting{
  create = async (params)=>{
    const {name, phone, identities, school,content, paramId, enquireType} = params || {};
    const res = await vue.$global.doPost({
      url: '/projectTeach/addEnquire',
      data: {
        name,
        phone,
        identities,
        school,
        content,
        paramId,
        enquireType,
      }
    })
    return res;
  }

  edit = async (params)=>{
    const {name, phone, identities, school,content, id} = params || {};
    const res = await vue.$global.doPost({
      url: '/projectTeach/editEnquire',
      data: {
        name,
        phone,
        identities,
        school,
        content,
        id,
      }
    })
    return res;
  }

  query = async (params) => {
    const {paramId, enquireType} = params || {};
    const res = await vue.$global.doPost({
      url: '/projectTeach/queryEnquire',
      data: {paramId, enquireType},
      loading: true,
      openNotice: false,
    })
    return res;

  }
}


const consulting = new Consulting();

export default consulting;