import axios from 'axios'
import requestConfig from './requestConfig'
import VueCookies from 'vue-cookies'
import router from '@/router'
import vue from '@/main'
class Post {
  /**
   * 基础post请求
   * @param {Object} params
   * @param {String} params.url - 请求地址
   * @param {Object} params.data = 请求数据
   * @param {Object} params.config = 请求参数
   * @returns {Promise<any>}
   */
  basic = async (params) => {
    const { url, data, config } = params ?? {}
    // console.log(params)
    const assignConfig = Object.assign(
      {},
      requestConfig,
      config
    )
    const res = await axios.post(url, data, assignConfig)
    return res
  }

  /**
   * 普通post请求 【headers带userCode】
   * @param {Object} params
   * @param {Object} params
   * @param {String} params.url - 请求地址
   * @param {Object} params.data = 请求数据
   * @param {Object} params.config = 请求参数
   * @returns {Promise<*>}
   */
  request = async (params) => {

    let loginUserCode = vue.$tools.getCookie('loginUserCode') || ''
    const { url, data, config } = params ?? {}
    const assignHeaders = Object.assign(
      {},
      requestConfig.headers,
      {
        loginUserCode: JSON.stringify(loginUserCode ?? '')
      },
      config ? config.headers : {}
    )

    if ((url ?? '') === '') {
      return
    }
    let res = await this.basic({
      url,
      data,
      config: Object.assign(
        {},
        config,
        {
          headers: assignHeaders
        },
      )
    })
    console.log(res)
    if (res.message === 'nologin') {
      // 跳转到登陆页
      // console.log('request.js 跳转登录')
    } else if(res.message === 'updating'){
      // 维护中
    } else if (res.message ==='success') {
      return res
    }
    return res
  }

  /**
   *
   * @param params
   * @returns {Promise<*>}
   */
  requestJson = async (params) => {
    let loginUserCode = vue.$tools.getCookie('loginUserCode') || ''
    const { url, data, config } = params ?? {}
    const assignHeaders = Object.assign(
      {},
      requestConfig.headers,
      {
        loginUserCode: JSON.stringify(loginUserCode),
        'Content-Type': 'application/json'
      },
      config ? config.headers : {}
    )
    if ((url ?? '') === '') {
      return
    }
    let res = await this.basic({
      url,
      data,
      config: Object.assign(
        {},
        config,
        {
          headers: assignHeaders
        },
      )
    })
    if (res.message === 'nologin') {
      // 跳转到登陆页
      // console.log('request.js 跳转登录')
    } else if(res.message === 'updating'){
      // 维护中
    } else if (res.message ==='success') {
      return res
    }
    return res
  }
}

const post = new Post()

export default post

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // console.log('axios.interceptors.response', response)
  if (!response.data) {
    return response
  }
  const { data } = response
  const { message } = data
  if (message === 'nologin') {
    // 跳转到登录页
    // console.log(router)
    router.push({ name: 'LoginEnter' })
  }
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

