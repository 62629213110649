<!--
 * @Author       : Hugo
 * @Date         : 2021-11-29 10:49:57
 * @LastEditTime : 2021-12-30 16:36:54
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/At/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="400px"
    max_height="600px"
  >
    <div class="components_pop_up_at_index pb-20 d-flex flex-column">
      
      <common-title
        :title="title"
        class="flex-shrink-0"
        close
        @handleClose="handleClose"
      ></common-title>
      <div class="main">
        <div class="sticky_group">
          <search
            class="common_border pt-20"
            @handleSearch="handleSearch"
          ></search>
          <all
            class="common_border pt-20 pb-10"
            :task_id="task_id"
            @handleAll="handleAll"
          ></all>
        </div>
        <div class="list_group">
          <template
            v-for="(item, index) in list"
          >
              
            <unit
              v-if="self_id != item.userId"
              :key="index"
              class="unit common_border bbox py-10"
              :search="search"
              :item="UserInfo(item)"
              @handleSure="doSure"
            ></unit>
          </template>
        </div>
      </div>
      
    </div>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import Search from './Search.vue';
import All from './All.vue';
import Unit from './Unit.vue';
import {UserInfo} from '@/types';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    Search,
    All,
    Unit,
  },
  props: {
    title: {
      type: String,
      default: '选择@对象'
    },
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    task_id: {
      type: Number,
      default: null,
    },
  },
  data(){
    return {
      list: [],
      search: '',
      self_id: this.$tools.getUserId(),
    }
  },
  methods: {
    handleClose(){
      this.$emit('handleClose');
    },
    async init(){
      const res = await this.getList();
      if(res){
        this.$set(this, 'list', res);
      }

    },
    async getList(){
      const { chat_id, chat_type, task_id} = this;
      if(chat_id === null || chat_type === null){
        return false;
      }
      if(task_id === null){
        // const {dialog_users} = this.$store.state;
        // if(dialog_users){
        //   const {chat_id: id, chat_type: type} = dialog_users;
        //   // vuex储存的用户信息是否为当前用户信息
        //   if(id === chat_id && type === chat_type && dialog_users.list){
        //     return dialog_users.list;
        //   }
        // }
        const res = await this.getDialogUser({chat_id, chat_type})
        if(res && res.list){
          return res.list;
        }
      }
      if(task_id){
        // const {task_users_list} = this.$store.state;
        // if(task_users_list){
        //   const {task_id:id, list} = task_users_list;
        //   if(id === task_id && list) {
        //     return list;
        //   }

        // }
        const res = await this.getTaskUser({task_id});
        if(res && res.list){
          return res.list;
        }
      }
      return false;
    },
    async getDialogUser(params){
      const {chat_id, chat_type} = params;
      const res = await this.$api.dialog.getDialogUsers({chat_id, chat_type});
      return res;
    },
    async getTaskUser(params){
      const {task_id} = params;
      const res = await this.$api.moduleTask.getTaskUsers({task_id});
      return res;
    },
    UserInfo(params){
      return new UserInfo(params);
    },
    handleSearch(search){
      this.search = search;
    },
    doSure(item){
      this.$emit('handleSure', item);
    },
    handleAll(){
      this.$emit('handleAll');
    },
  },
  mounted(){
    // // console.log(this.$store.state.dialog_users);
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_at_index{
  width: 100%;
  height: 100%;
  .common_border{
    padding: {
      left: 30px;
      right: 30px;
    }
  }
  .main{
    @include scrollbar;
    width:100%;
    overflow: auto;
    flex: 1;
  }
  .sticky_group{
    position: relative;
    width: 100%;
    top:0;
    left: 0;
    position: sticky;
    background-color: #fff;
  }
  .list_group{
    
  }
}
</style>