/*
 * @Author       : Hugo
 * @Date         : 2020-09-10 14:44:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-13 11:00:11
 * @Description  : 
 * @FilePath     : /miaohang/src/utils/prompt.js
 */
import PromptComponent from '@/components/Plugin/Prompt.vue'

const Prompt = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PromptConstructor = Vue.extend(PromptComponent);

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PromptConstructor();

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$openPrompt = (obj) => {
      if(obj){
        let {title, placeholder,hint, sure, cancel, onSure, onCancel, input} = obj;
        instance.title = title;
        instance.placeholder = placeholder;
        instance.hint = hint;
        instance.input = input ?? ''
        instance.sure = sure || '确定';
        instance.cancel = cancel || '取消';
        instance.onSure = onSure;
        instance.onCancel = onCancel;
      }else{
        instance.title = '提示';
        instance.placeholder = 'placeholder';
        instance.hint = 'hint';
        instance.input = ''
        instance.sure = '确定';
        instance.cancel = '取消';
      }
      instance.visible = true
     
    }
  }
}

export default Prompt;