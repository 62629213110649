var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiple_contacts_page un_sel" }, [
    _c("div", { staticClass: "search_group" }, [_c("search-box", {})], 1),
    _c("div", { staticClass: "main_group" }, [
      _c("div", { staticClass: "select_group" }, [
        _c(
          "div",
          { staticClass: "main_box" },
          [
            _vm.if_lately
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title_bar un_sel cp",
                      class: { on: _vm.switch_lately },
                      on: {
                        click: function ($event) {
                          _vm.switch_lately = !_vm.switch_lately
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "title" }, [
                        _vm._v("最近联系人"),
                      ]),
                    ]
                  ),
                  _vm.switch_lately
                    ? [
                        _vm._l(_vm.lately_list, function (item, index) {
                          return [
                            item.chatId != _vm.$cm_id
                              ? _c(
                                  "div",
                                  {
                                    key: `${index}lately`,
                                    staticClass: "unit",
                                    class: { on: item.selected },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doSelect({
                                          id: item.chatId,
                                          type: item.chatType,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                    _c("div", { staticClass: "select" }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.if_contacts
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title_bar un_sel cp",
                      class: { on: _vm.switch_contacts },
                      on: {
                        click: function ($event) {
                          _vm.switch_contacts = !_vm.switch_contacts
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "title" }, [_vm._v("联系人")]),
                    ]
                  ),
                  _vm.switch_contacts && !_vm.switch_search
                    ? _vm._l(_vm.contacts_list, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: `${index}contacts`,
                            staticClass: "unit",
                            class: { on: item.selected },
                            on: {
                              click: function ($event) {
                                return _vm.doSelect({
                                  id: item.friendUserId,
                                  type: 0,
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(
                                _vm._s(item.friendRemark || item.userName)
                              ),
                            ]),
                            _c("div", { staticClass: "select" }),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.switch_contacts && _vm.switch_search
                    ? _vm._l(_vm.search_contacts, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: `${index}search_contacts`,
                            staticClass: "unit",
                            class: { on: item.selected },
                            attrs: { "data-b": item.friendUserId },
                            on: {
                              click: function ($event) {
                                return _vm.doSelect({
                                  id: item.friendUserId,
                                  type: 0,
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                alt: "",
                              },
                            }),
                            _c("div", {
                              staticClass: "name",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$tools.highlightKeywords(
                                    item.friendRemark || item.userName,
                                    _vm.keywords
                                  )
                                ),
                              },
                            }),
                            _c("div", { staticClass: "select" }),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.no_contacts_data_was_searched
                    ? _c("div", { staticClass: "no_data" }, [
                        _vm._v("没有数据"),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.if_teams
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "title_bar un_sel cp",
                      class: { on: _vm.switch_teams },
                      on: {
                        click: function ($event) {
                          _vm.switch_teams = !_vm.switch_teams
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "title" }, [_vm._v("工作组")]),
                    ]
                  ),
                  _vm.switch_teams && !_vm.switch_search
                    ? _vm._l(_vm.teams_list, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: `${index}teams`,
                            staticClass: "unit",
                            class: { on: item.selected },
                            on: {
                              click: function ($event) {
                                return _vm.doSelect({
                                  id: item.chatId,
                                  type: 1,
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.chatName)),
                            ]),
                            _c("div", { staticClass: "select" }),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.switch_teams && _vm.switch_search
                    ? _vm._l(_vm.search_teams, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: `${index}search_teams`,
                            staticClass: "unit",
                            class: { on: item.selected },
                            on: {
                              click: function ($event) {
                                return _vm.doSelect({
                                  id: item.chatId,
                                  type: 1,
                                })
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: `${_vm.$avatar_url}${item.photo}@!small200`,
                                alt: "",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$tools.highlightKeywords(
                                      item.chatName,
                                      _vm.keywords
                                    )
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(item.chatName))]
                            ),
                            _c("div", { staticClass: "select" }),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.no_teams_data_was_searched
                    ? _c("div", { staticClass: "no_data" }, [
                        _vm._v("没有数据"),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "selected_group" }, [
        _c(
          "div",
          { staticClass: "main_box", attrs: { id: "selected_box" } },
          _vm._l(_vm.selected_list, function (item, index) {
            return _c(
              "div",
              {
                key: `${index}selected`,
                ref: "selected_unit",
                refInFor: true,
                staticClass: "unit",
              },
              [
                _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("div", {
                  staticClass: "select del",
                  on: {
                    click: function ($event) {
                      return _vm.deleteSelected({
                        id: item.id,
                        type: item.type,
                      })
                    },
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "btn_group" }, [
      _c(
        "button",
        {
          staticClass: "button cp btn_dark",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSure()
            },
          },
        },
        [_vm._v("确定")]
      ),
      _c(
        "button",
        {
          staticClass: "button cp btn_light",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doClear()
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }