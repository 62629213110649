import { listConvertToModel } from '@/models'
import CourseSection from '@/models/cource_package/course_section'
import Userinfo from '@/types/modules/Userinfo'
import request from '@/api/request'
import post from '@/utils/axios/post'
class File {
  chatId
  conId
  createTime
  filename
  filesize
  filesort
  filetype
  height
  id
  isTag
  taskId
  taskNotice
  taskname
  teach
  type
  url
  userId
  username
  width
  // iconType
  // textType
  formatSize


  constructor (params) {
    this.updateData(params)
  }


  updateData (params) {
    const {
      chatId,
      conId,
      createTime,
      filename,
      filesize,
      filesort,
      filetype,
      height,
      id,
      isTag,
      taskId,
      taskNotice,
      taskname,
      teach,
      type,
      url,
      userId,
      username,
      width,
    } = params ?? {}

    this.chatId = chatId
    this.conId = conId
    this.createTime = createTime
    this.filename = filename
    this.filesize = filesize
    this.filesort = filesort
    this.filetype = filetype
    this.height = height
    this.id = id
    this.isTag = isTag
    this.taskId = taskId
    this.taskNotice = taskNotice
    this.taskname = taskname
    this.teach = teach
    this.type = type
    this.url = url
    this.userId = userId
    this.username = username
    this.width = width
    // this.getIconType()
    if (filesize) {
      this.formatSize = this.getFormatSize(filesize)
    }


  }

  getIconType () {
    const { filename }  = this
    if (!filename) {
      return ''
    }
    // 设置图表类型
    const fileName = filename;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    let fileType;
    let textType
    switch (fileExtension) {
      case 'zip':
      case 'rar':
        fileType = 'zip';
        textType = '压缩文件';
        break;
      case 'xls':
      case 'xlsx':
        fileType = 'excel';
        textType = 'Excel文件';
        break;
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'wmv':
      case 'flv':
      case 'mkv':
        fileType = 'video'; // 这里只是列举了常见视频后缀，可按需扩充更多
        textType = '视频';
        break;
      case 'pdf':
        fileType = 'pdf';
        textType = 'PDF文件';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        fileType = 'pic'; // 同样列举常见图片后缀，可完善
        textType = '图片';
        break;
      case 'ppt':
      case 'pptx':
        fileType = 'ppt';
        textType = 'PPT文件';
        break;
      case 'txt':
        fileType = 'txt';
        textType = '文本';
        break;
      case 'doc':
      case 'docx':
        fileType = 'word';
        textType = 'WORD文件';
        break;
      case 'glb':
        fileType = 'vr';
        textType = 'VR文件';
        break;
      case'mp3':
      case 'wav':
      case 'aac':
      case 'flac':
        fileType = 'audio'; // 常见音频后缀示例，可补充更多
        textType = '音频文件';
        break;
      case 'task':
        fileType = 'task';
        textType = '任务';
        break;
      case 'link':
        fileType = 'link';
        textType = '网页链接';
        break;
      default:
        fileType = 'unknown';
        textType = '未知类型';
    }
    console.log(textType)
    this.iconType = fileType
    this.textType = textType
  }

  getFormatSize (sizeInBytes) {
    if (sizeInBytes < 1024) {
      return sizeInBytes + " KB";
    } else if (sizeInBytes < 1024 * 1024) {
      let sizeInKB = sizeInBytes / 1024;
      return sizeInKB.toFixed(2) + " MB";
    }
    // else if (sizeInBytes < 1024 * 1024 * 1024) {
    else {

      let sizeInMB = sizeInBytes / (1024 * 1024);
      return sizeInMB.toFixed(2) + " GB";
    }
    // else {
    //   let sizeInGB = sizeInBytes / (1024 * 1024 * 1024);
    //   return sizeInGB.toFixed(2) + " GB";
    // }
  }

}

export default File
