/*
 * @Author       : Hugo
 * @Date         : 2021-04-26 13:44:54
 * @LastEditTime : 2021-04-26 14:10:16
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/utils/prompt.js
 * @Description  : 
 * @^_^          : 
 */

import vue from '@/main.js';

const PROMPT = {
  /**
   * 对话框关闭时 清除的数据
   */
  doClear(){
    // console.log('clear')
    vue.$store.commit('set_utils_prompt', '');
  },
  /**
   * 对话框内容修改
   */
  changeInput(input){
    vue.$store.commit('set_utils_prompt', input);
  },

}
export default PROMPT;
