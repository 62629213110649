<template>
  <div 
    class="change_title"
    v-if="status_title"
    @click.stop="openAppoint();"
  >{{is_task_title_change?'':'标题 '}}{{msg.title}}</div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    },
    relevance: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    status_title(){
      const {msg, relevance} = this;
      const {conIdP, title ,conTitle} = msg;
      if(conIdP && !relevance){
        return false;
      }

      return title !== conTitle;
    },
    is_task_title_change(){
      const {controlType, type} = this.msg;
      if(controlType === 101){
        if(type == 2){
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    openAppoint(){
      const {msg, relevance} = this;
      const {conId, conIdP} = msg;
      if(!relevance && conIdP){
        this.$emit('handleAppoint', {parent_id: null, widget_id: conIdP});
        return;
      }
      this.$emit('handleAppoint', {parent_id: conIdP, widget_id: conId});

    }
  }
}
</script>

<style lang="scss" scoped>
.change_title{
  max-width: 650px;
  padding: {
    left: 21px;
    // top: 5px;
    // bottom: 5px;
  }
  font-size: 14px;
  color: #555;
  line-height: 20px;
}
</style>