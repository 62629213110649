var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("consulting-form-page", {
            attrs: {
              title: _vm.title,
              loginUserCode: _vm.loginUserCode,
              user: _vm.user,
            },
            on: {
              handleClose: function ($event) {
                return _vm.handleClose()
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }