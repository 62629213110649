/*
 * @Author      : Hugo
 * @Date        : 2022-04-07 14: 13: 54
 * @LastEditTime : 2022-04-14 14:35:17
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PFolder/index.js
 * @Description : 
 * @^_^         : 
 */
import vue from '@/main'
import store from '@/store'
import global from '@/api/global'
class PFolder{
  createTime     = undefined
  createuserid   = undefined
  fid            = undefined
  id             = undefined
  name           = undefined
  ptype          = undefined
  shareid        = undefined
  sonfolderList  = undefined
  upFolder       = undefined
  updateTime     = undefined
  userId         = undefined
  collectionflag = undefined
  cf             = undefined

  constructor(params = {}){
    if(params){
      const {
        createTime,
        createuserid,
        fid,
        id,
        name,
        ptype,
        shareid,
        sonfolderList,
        upFolder,
        updateTime,
        userId,
        collectionflag,
        cf,
      } = params

      this.createTime     = createTime
      this.createuserid   = createuserid
      this.fid            = fid
      this.id             = id
      this.name           = name
      this.ptype          = ptype
      this.shareid        = shareid
      this.sonfolderList  = sonfolderList
      this.upFolder       = upFolder
      this.updateTime     = updateTime
      this.userId         = userId
      this.collectionflag = collectionflag
      this.cf             = cf
    }
  }
  /**
   * 重命名文件夹
   */
  rename = async ()=> {
    const {name, id:folderId} = this
    store.commit('set_utils_prompt', name)
    vue.$openPrompt({
      title:'重命名',
      placeholder: '',
      hint: '请输入名字',
      sure: '',
      input: name,
      cancel: '',
      onSure: async ()=>{
        const {state} = store
        const {utils_prompt} = state
        const res = await global.doPost({
          url: '/projectCustom/renameFolder',
          data: {
            folderId,
            name: utils_prompt.trim()
          }
        })
        if(res.message === 'success'){
          vue.$notice({desc:"重命名成功", type: 'success'})
          this.name = utils_prompt.trim()
          // if(res.contents && res.contents.PFolder){
          // }
        }
        return

      },
      onCancel: ()=>{},
    })
  }
  /**
   * 分享
   */
  share = (params)=>{
    vue.$shareRoute({
      list: params?.list,
      work: params?.work,
      onSure: async (list)=> {
        console.log(list)
        vue.$closeShareRoute()
        // 确认分享
        this.shareConfirm({list, work: params?.work})
      }
    })
  }

  shareConfirm = (params) => {
    const {list, work} = params
    vue.$shareConfirm({
      list,
      work,
      onSure: async (data)=>{
        await this.postShare({list, work:data})
      },
      onReturn: async (data)=>{
        this.share({
          list: data.list,
          work: data.work,
        })
      }
    })
  }

  /**
   * 分享请求
   * @param {*} params 
   */
  postShare = async (params) => {
    const {id, name} = this
    const {list, work} = params
    const {userIds, chatIds} = getShareIds({list});
    const data = {
      id,
      shareType:4,
      userIds,
      chatIds,
      work
    }
    try{
      const res = await shareTask(data);
      if(res){
        let messageContent = `分享 ${name}`;
        updateMessageBoxAfterShare({messageContent, list});
        // 判断是否追加消息列表
        this.addToMessageListAfterShare({shareType: 4, taskId: id, work});
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return false;
  }

  /**
   * 分享后追加消息列表
   * @param {*} params 
   * @returns 
   */
  addToMessageListAfterShare = (params)=>{
    const {name} = this
    const taskName = name;
    const get_id = vue.$api.moduleTask.getRouteParamsId();
    const get_type = vue.$tools.getChatType();
    const {shareType, taskId, work, } = params || {};
    const user = vue.$tools.getCookie('user');
    if(chatInMultipleContactsList()){
      vue.$api.task.addToMessageList({
        chat_id: get_id,
        type: get_type,
        createTime: new Date().getTime(),
        details: [],
        fromUserId: vue.$tools.getUserId(),
        logType: 1,
        shareType,
        taskId,
        taskName,
        thumbnail: user.thumbnail,
        userName: user.userName,
        work,
      });
    }

    return;
  }

  select = () => {
    const {cf} = this
    this.cf = Object.assign(
      cf??{},
      {
        selected: !(cf?.selected ?? false)
      }
    )
  }

  cancelSelect = () => {
    const {cf} = this
    this.cf = Object.assign(
      cf??{},
      {
        selected: false
      }
    )
  }

  removeCollect = async ()=>{
    const {id} = this
    const res = await global.doPost({
      url: '/projectCustom/deleteFolder',
      data: {
        folderIds: id,
      }
    })
    if(res.message === 'success'){
      this.collectionflag = 0
      return res
    }
    return false
  }

  collect = async ()=>{
    const {id} = this
    const res = await global.doPost({
      url: '/projectCustom/collectionFolder',
      data: {
        id: id,
      }
    })
    if(res.message === 'success'){
      this.collectionflag = 0
      return res
    }
    return false
  }



}

export default PFolder

/**
 * 获取分享的id列表
 * @returns {userIds, chatIds}
 */
function getShareIds(params){
  const {list} = params
  let chatIds = ''
  let userIds = ''
  list.forEach(item=>{
    if(item.type == 0){
      userIds += userIds ? `,${item.id}` : item.id
    }
    if(item.type == 1){
      chatIds += chatIds ? `,${item.id}` : item.id
    }
  })
  return {userIds, chatIds}
}


/**
 * 分享教学包/任务
 * @param {*} params 
 */
 async function shareTask(params){
  // const {id, shareType, userIds, chatIds, work} = params || {};
  let res;
  try{
    res = await vue.$http.request('/projectTask/shareTask', params);
    if(res.message == 'success'){
      vue.$notice({desc: '分享成功', type:'success'});
      return true;
    }else{
      vue.$notice({desc: res.describe});
    }
  }catch(err){
    // console.log(err);
  }
  return false;
}

/**
 * 当前聊天在分享多选列表里面
 * @param {*} params 
 * @returns 
 */
function chatInMultipleContactsList(params){
  const {list} = params
  const {task} = vue.$api
  const get_id = task.getRouteParamsId()
  const get_type = vue.$tools.getChatType()
  if(list && list.length){
    const find = list.find(item => {
      return item.type == get_type && item.id == get_id
    })
    return find
  }
  return false
}
/**
 * 分享后更新消息盒子
 * @returns 
 */
function updateMessageBoxAfterShare(params){
  const {messageContent, list} = params
  // console.log(multiple_contacts_list);
  list.forEach((item)=>{
    vue.$api.task.messageBoxUp({
      messageContent,
      updateusername: vue.$tools.getCookie('user').userName,
      lastSendTime: new Date().getTime(),
      photo: item.thumbnail,
      chatId: item.id,
      chatType: item.type,
    })
  })
  return ;
}



