class PVipRecord {
  createTime = undefined
  days = undefined
  endTime = undefined
  id = undefined
  type = undefined
  userId = undefined
  constructor (params = {}) {
    const {
      createTime,
      days,
      endTime,
      id,
      type,
      userId,
    } = params ?? {}

    this.createTime = createTime
    this.days = days
    this.endTime = endTime
    this.id = id
    this.type = type // 1 购买 2 激活码 3 礼品卡 4 后台开通
    this.userId = userId
  }
}

export default PVipRecord