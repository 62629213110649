/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 14:25:01
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-08-17 10:52:59
 * @Description  : 
 * @FilePath     : /miaohang/src/store/modules/task_module.js
 */ 
// 任务
const task = {
  state: {
    /**
     * 添加好友
     */
    add_friend_box: false, //盒子
    search_friend: false, //搜索好友框
    add_friend: false, //添加好友框
    add_friend_data: null, //被添加好友信息
    /**
     * 置顶
     */
    contact_stick_fun_id: '', // 联系人置顶功能id
    workteam_stick_fun_id: '', // 工作组置顶功能id
    /**
     * 会话顶部标题
     */
    group_dialog_title_data: null, //工作组会话窗口标题
    /**
     * 单人会话信息
     */
    single_dialog_data: null, 
    /**
     * 消息列表
     */
    message_list: null,
    /** 触发消息滚动到底部 */
    task_trigger_scroll_to_bottom: false,
  },
  mutations: {
    /**
     * 添加好友弹窗状态(打开搜索)
     */
    open_add_friend_box(state, payload){
      // console.log(111)
      state.add_friend_box = true;
      state.search_friend = true;
      state.add_friend = false;
    },
    // 添加好友框
    to_add_friend(state, payload){
      state.add_friend_box = true;
      state.search_friend = false;
      state.add_friend = true;
      state.add_friend_data = payload;
    },
    /**
     * 关闭添加好友弹窗
     */
    close_add_friend_box(state, payload){
      state.add_friend_box = false;
      state.search_friend = false;
      state.add_friend = false;
      state.add_friend_data = null;
    },
    /**
     * 置顶联系人功能
     */
    set_contact_stick_fun_id(state, payload){
      state.contact_stick_fun_id = payload;
      state.workteam_stick_fun_id = '';
    },

    /**
     * 置顶工作组功能
     */
    set_workteam_stick_fun_id(state, payload){
      state.workteam_stick_fun_id = payload;
      state.contact_stick_fun_id = '';
    },
    /**
     * 设置工作组会话顶部标题
     */
    set_task_group_dialog_title_data(state,payload){
      state.group_dialog_title_data = payload;
    },
    /**
     * 设置单人会话数据
     */
    set_single_dialog_data(state,payload){
      state.single_dialog_data = payload;
    },
    /**
     * 设置消息列表
     */
    set_message_list(state, payload){
      state.message_list = payload;
    },
    /** 触发滚动到底部 */
    set_task_trigger_scroll_to_bottom(state, payload){
      state.task_trigger_scroll_to_bottom = payload;
    },
  }
};

export default task;
