<!--
 * @Author       : Hugo
 * @Date         : 2022-04-11 11:16:11
 * @LastEditTime: 2022-05-20 00:16:47
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Collect/Select/Route/Wrapper.vue
 * @Description  : 选择收藏路径
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_seletct_route bbox d-flex flex-column"
  > 
    <overlay-scrollbars
      class="overlay bbox"
      ref="overlay"
    > 
      <div
        class="main_group d-flex align-center flex-shrink-0"
      > 
        <template
          v-for="(item, index) in list"
        >
          <list-component
            :key="index"
            :list="item"
            class="list_group flex-shrink-0"
            @handleNext="handleNext"
            :ref="`list${index}`"
          ></list-component>
        </template>
      </div>
    </overlay-scrollbars>
    <div class="operation_group d-flex justify-space-between flex-shrink-0 bbox align-center">
      <div class="create_group">
        <div class="create un_sel d-flex align-center cp"
          @click.stop="touchCreate()"
        >
          <img src="~@/assets/images/custom/create.png" alt="" class="img mr-8">
          <div class="text">创建分组</div>
        </div>
      </div>
      <div class="btn_group d-flex">
        <div class="button btn_light"
          @click.stop="handleClose()"
        >取消</div>
        <div class="button btn_dark"
          @click.stop="doSure()"
        >确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import ListComponent from './List.vue'
import {listForClasses, PFolder} from '@/types'
import store from '@/store'
import global from '@/api/global.js'
export default {
  components: {
    ListComponent,
  },
  props: {
    onSure: {
      type: Function,
      default: undefined
    }
  },
  data(){
    return{
      list: [
        [
          new PFolder({id:0, name:'我收藏的', cf: {selected: true}})
        ],
      ],
      loading: false,
    }
  },
  methods: {
    async getList(params){
      const {id} = params
      const res = await this.$global.doPost({
        url: '/projectCustom/queryFolder',
        data: {
          sort: 0,
          fId: id,
        },
      })
      if(res.message === 'success'){
        if(res.contents && res.contents.pFolderList){
          let folder_list =  [
            ...new Set([
              ...listForClasses({
                list:res.contents.pFolderList,
                classes: PFolder,
              })
            ])
          ]
          return folder_list
        }
      }
      return false
    },
    init(){
      this.next({id: 0})
    },
    async next(params){
      if(this.loading){
        return false
      }
      this.loading = true
      const res = await this.getList(params)
      this.loading = false
      if(res !== false){
        this.list.push(res)
        this.toRight()
      }
    },
    toRight(){
      this.$nextTick(function(){
        this.$refs.overlay.osInstance().scroll({x: '100%'}) // 滚动到最右边
      })
    },
    handleNext(p_folder){
      const {list} = this  
      // 点击选择的后面都删除
      const index = list.findIndex(item=>{
        const has = item.find(unit=>{
          return Number(unit.id) === Number(p_folder.id)
        })
        return Boolean(has)
      })
      if(index !== -1){
        this.$set(this, 'list', this.list.slice(0,index+1))
      }
      // 然后加载新的 先判断是否有下一级
      if((!p_folder.sonfolderList || !p_folder.sonfolderList.length) &&p_folder.id !== 0){
        return
      }
      this.next(p_folder)
    },
    touchCreate(){
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }
      const p_folder = this.getSelectedFolder()
      if(p_folder === undefined){
        return
      }
      this.$openPrompt({
        title:'新建分组',
        placeholder: '',
        hint: '未输入内容时的提示内容',
        sure: '',
        cancel: '',
        onSure: async ()=>{
          const {state} = store
          const {utils_prompt} = state
          const res = await global.doPost({
            url: '/projectCustom/createFolder',
            data: {
              fId: p_folder.id,
              name: utils_prompt.trim()
            }
          })
          if(res.message === 'success'){
            this.$notice({desc:"创建成功", type: 'success'})
            if(res.contents && res.contents.PFolder){
              this.handleCreateFolder(new PFolder(res.contents.PFolder))
            }
            // this.handleCreateFolder()
          }
          return

        },
        onCancel: ()=>{},
      })
    },
    getSelectedFolder(){
      const {list} = this
      let folder = list[list.length - 1].find(item=>{
        return item.cf && item.cf.selected
      })
      if(folder === undefined){
        folder = list[list.length - 2].find(item=>{
          return item.cf && item.cf.selected
        })
      }
      return folder
    },
    /**
     * 创建文件夹
     */
    handleCreateFolder(new_folder){
      const {list} = this
      let is_last = list[list.length - 1].find(item=>{
        return item.cf && item.cf.selected
      })
      if(is_last === undefined){
       
        list[list.length-1].unshift(new_folder)
        const ref_name = `list${list.length-1}`
        if( this.$refs[ref_name] &&  this.$refs[ref_name][0]){
          this.$refs[ref_name][0].goToTop() // 滚动到顶部
        }
       
        
      }else{
        is_last.sonfolderList = [
          ...new Set([
            ...[new_folder]
          ])
        ]
        list.push([new_folder])
        this.toRight()
      }
      // 自动选择
      this.$set(new_folder, 'cf', Object.assign(
        new_folder.cf??{},
        {selected: true}
      ))
    },
    async doSure(){

      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        const p_folder = this.getSelectedFolder()
        this.onSure({p_folder})
        this.loading = false
        this.handleClose()
      }
      
      return

      if(this.loading){
        return
      }
      this.loading = true
      const {state} = store
      const {task} = state
      const p_folder = this.getSelectedFolder()
      const res = await global.doPost({
        url: '/projectCustom/addFolderTask',
        data: {
          folderId: p_folder.id,
          addIds: task.task.id,
        }
      })
      this.loading = false
      if(res.message === 'success'){
        task.task.collectionflag = 1
        this.$notice({desc:"收藏成功", type: 'success'})
        this.handleClose()
      }
      

    },
    handleClose(){
      this.$emit('handleClose')
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.c_collect_seletct_route{
  width: 100%;
  height: 100%;
  .operation_group{
    width: 100%;
    height: 60px;
    padding: 0 20px;
    .create_group{
      font-size: 14px;
      color: #666;
      .create{
        border: 1px solid #ddd;
        background-color: #f8f8f8;
        line-height: 38px;
        border-radius: 4px;
        padding: 0 15px;
      }
    }
    .btn_group{
      .button{
        line-height: 40px;
        padding:0 25px;
        border-radius: 4px;
        margin-left: 10px;
      }
    }
  }
  .overlay{
    width: 100%;
    flex: 1;
    background-color: #f8f8f8;
    padding-bottom: 10px;
  }
  .main_group{
    height: 100%;
  }
  .list_group{
    height: 100%;
    width: 240px;
    margin-left: 14px;
    background-color: #fff;
    &:first-child{
      margin-left: 0;
    }
  }
}
</style>
