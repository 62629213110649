<template>
  <div
    class="components_task_main_linebar_task_tag_page bbox d-flex align-center"
  >
  
    <div class="add_group cp flex-shrink-0 d-flex align-center"
    >
      <div class="icon"></div>
      <div class="text"
      >添加自定义标签</div>
    </div>
    <div class="input_group flex-shrink-0"
    >
      <input type="text" class="input bbox"
        ref="input"
      >
      <div class="placeholder bbox">input_tag</div>
    </div>
    <overlay-scrollbars
      class="overlay_container bbox d-flex align-center"
      ref="overlay"
    >
      <div class="list d-flex align-center">
        <template
          v-for="(item, index) in list"
        >
          <div class="tag flex-shrink-0"
            :key="index"
          >
            <div class="text">{{item.tagName}}</div>
            <div class="close cp"
            ></div>
          </div>
        </template>
      
      </div>
    </overlay-scrollbars>
    
    <div class="tag_list_group bbox"
      :class="{active: 1}"
      v-if="0"
    >
      <div class="tag_list bbox"
      >
        <div class="title">选择已有标签</div>
        <div class="tag_group bbox d-flex align-center flex-wrap">
          <!-- <template
            v-for="(val,index) in tag_list"
          > -->
            <div
              class="tag cp"
              :class="{on: 1}"
            >val.tagName</div>
          <!-- </template> -->
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import store from '@/store'
export default {
  props: {
    task: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    list(){
      const {state} = store
      const {ctask} = state
      const {task} = ctask
      const {tags} = task
      // console.log(tags)
      return tags
    }
  },
  mounted(){
    console.log(this.task)
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_linebar_task_tag_page{
  position: relative;
  background-color: #fff;
  width: 100%;
  .overlay_container{
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  .tag_list_group{
    @include transition;
    transition-delay: 0.5s;
    transform: scaleY(0);
    transform-origin: top center;
    padding: 0 20px;
    width: 100%;
    position: absolute;
    top: 60px;
    left:0;
    z-index: 4;
    &.active{
      transform: scaleY(1);
    }
    .title{
      font-size: 12px;
      color: #333333;
      line-height: 16px;
      padding-bottom: 12px;
    }
    .tag_group{
      @include scrollbar;
      overflow-y: auto;
      max-height: 38*3px;
      .tag{
        @include transition;
        padding: 0 12px;
        line-height: 28px;
        background: #E9E9E9;
        border-radius: 16px;
        font-size: 12px;
        color: #555555;
        margin: {
          right: 10px;
          bottom: 10px;
        }
        &.on{
          color: $main_blue;
          background-color: #E6EEF7;
        }
        &:hover{
          color: $main_blue;
          background-color: #B2CBE4;
        }
      }
    }
  }
  .tag_list{
    padding: 12px;
    width: 100%;
    // height: 118px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
   
  }
  .add_group{
    padding-left: 2px;
    padding-right: 10px;
    .icon{
      @include background(16px, 16px);
      width: 16px;
      height: 20px;
      background-image: url(~@/assets/images/task/add_tag.png);
    }
    .text{
      padding-left: 10px;
      font-size: 16px;
      color: #CCCCCC;
    }
  }
  .input_group{
    position: relative;
    height: 28px;
    margin-right: 10px;
    // margin-left: 10px;
    .input{
      width: 100%;
      position: absolute;
      top:0;
      left: 0;
      background-color: #E6EEF7;
      color: $main_blue;
      font-size: 12px;
      color: #0054A7;
      padding:  0 12px;
      line-height: 28px;
      border-radius: 14px;
      border:none;
      outline: none;
      z-index: 3;
    }
    .placeholder{
      position: relative;
      z-index: 2;
      opacity: 0;
      color: $main_blue;
      font-size: 12px;
      padding:  0 12px;
      line-height: 28px;
      // border-radius: 14px;
      width: 100%;
      min-width: 50px;
    }
  }
  .list{
    height: 48px;
  }
  .tag{
    position: relative;
    padding-right: 10px;
    &:last-child{
      padding-right: 0;
    }
    &:hover{
      .close{
        opacity: 1;
      }
    }
    .text{
      background-color: #E6EEF7;
      color: $main_blue;
      font-size: 12px;
      color: #0054A7;
      padding:  0 12px;
      line-height: 28px;
      border-radius: 14px;
    }
    .close{
      @include transition;
      opacity: 0;
      transition-delay: 0.3s;
      width: 16px;
      height: 16px;
      position: absolute;
      background-image: url(~@/assets/images/task/close_tag.png);
      z-index: 3;
      right: 3px;
      top: -4px;
    }
  }
}
</style>