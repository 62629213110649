<template>
  <div class="c_teaching_package_selected_task_list_index bbox d-flex flex-column">
    <div class="main_group bbox">
      <div class="sticky_group bbox">
        <filter-bar
          class="mb-10"
          :package-select-task="packageSelectTask"
          @handleSearch="handleSearch"
        ></filter-bar>
      </div>
      <div class="container">
        <task-list
          :package-select-task="packageSelectTask"
          :task-list="taskList"
          :search="pageData.search"
        ></task-list>
      </div>
    </div>
    <operation-bar
      class="flex-shrink-0"
      :task-list="taskList"
      :package-select-task="packageSelectTask"
    ></operation-bar>
  </div>
</template>

<script>
import tools from '@/api/tools'
import TaskList from '@/components/PopUp/TeachingPackage/SelectedTaskList/TaskList'
import FilterBar from '@/components/PopUp/TeachingPackage/SelectedTaskList/FilterBar'
import OperationBar from '@/components/PopUp/TeachingPackage/SelectedTaskList/OperationBar'
import { listForClasses, PTask } from '@/types'
export default {
  name: 'index',
  components: {
    FilterBar,
    OperationBar,
    TaskList
  },
  props: {
    pPackage: {
      type: Object,
      default: undefined
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      // taskList: [],
      pageData: {
        search: ''
      }
    }
  },
  computed: {
    packageSelectTask() {
      const { pPackage } = this
      const { packageSelectTask } = pPackage
      return packageSelectTask
    }
    // taskList() {
    //   const { packageSelectTask } = this
    //   const { selectedList } = packageSelectTask
    //   return tools.deepClone(selectedList)
    // }
  },
  mounted () {
    // console.log(this.taskList)
    // this.init()
  },
  methods: {
    handleSearch(params) {
      const { search } = params
      this.pageData.search = search
      console.log(this.pageData)
    },
    init() {
      this.generateTaskList()
    },
    generateTaskList() {
      const { packageSelectTask } = this
      const { selectedList } = packageSelectTask
      // return tools.deepClone(selectedList)
      const taskList = listForClasses({
        list: tools.deepClone(selectedList),
        classes: PTask,
        defaultParams: { selected: true } // 默认选中
      })
      this.$set(this, 'taskList', taskList)
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_selected_task_list_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  .main_group{
    @include scrollbar;
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .sticky_group{
      position: sticky;
      width: 100%;
      background-color: #f8f8f8;
      top: 0;
      left: 0;
      z-index: 3;
      padding: 20px 20px 0;
    }
  }
}
</style>