import vue from "@/main";
class Search{

  chatContext = async (params) => {
    const {chat_id: chatId, chat_type: chatType, start_id: startId, task_id: taskId, type} = params;
    let data = {
      chatType,
      chatId,
      startId,
      type,
    }
    if(taskId){
      data.taskId = taskId;
    }
    const res = await vue.$global.doPost({
      url: '/projectTaskLogSearch/searchTaskLogTextPage',
      data,
      openNotice: false,
    })
    if(res.message === 'success'){
      return res;
    }
    return false;
  }
  /**
   * 工作组下一页
   * @param {*} params 
   * @returns 
   */
  workgroupNextContext = async (params) => {
    const data = Object.assign(
      {},
      params,
      {type:2}
    )
    const res = await this.chatContext(data);
    return res;
  }
  /**
   * 工作组上一页
   * @param {*} params 
   * @returns 
   */
  workgroupPrevContext = async (params) => {
    const data = Object.assign(
      {},
      params,
      {type:1}
    )
    const res = await this.chatContext(data);
    return res;
  }
  /**
   * 聊天下一页
   * @param {*} params 
   * @returns 
   */
  taskNextContext = async (params) => {
    const data = Object.assign(
      {},
      params,
      {type:2}
    )
    const res = await this.chatContext(data);
    return res;
  }
  /**
   * 聊天上一页
   * @param {*} params 
   * @returns 
   */
  taskPrevContext = async (params) => {
    const data = Object.assign(
      {},
      params,
      {type:1}
    )
    const res = await this.chatContext(data);
    return res;
  }
}

const search = new Search();

export default search;