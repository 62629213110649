var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "list_group d-flex" }, [
    _c("div", {
      staticClass: "icon flex-shrink-0 pic",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.doPreview.apply(null, arguments)
        },
      },
    }),
    _c(
      "div",
      {
        staticClass:
          "info_group d-flex flex-column justify-space-between align-start",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doPreview.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.fileData.file.name) + "\n    "),
        ]),
        _c("div", { staticClass: "size" }, [
          _vm._v("\n      " + _vm._s(_vm.fileSize) + "\n    "),
        ]),
      ]
    ),
    _c("div", {
      staticClass: "delete cp",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.$emit("handleDelete")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }