import vue from '@/main';
class RecycleBin{
  /**
   * 恢复任务
   * @param {*} item
   */
  recover(item, type){
    const {id, from, draft} = item;
    let desc = '是否确认恢复?';

    if (type === 'course_package') {
      vue.$openConfirm({
        title: '提示',
        desc,
        sure:'是',
        cancel:'否',
        onSure: async ()=>{

          const res = await vue.$global.doPost({
            url: '/projectTask/backDeletePCource',
            data: {
              courceId: item.id,
            },
          })
          if(res.message === 'success'){
            vue.$notice({
              desc: '恢复成功',
              type: 'success'
            })
            this.deleteFromList(item);
            // 关闭任务
            // vue.$api.moduleTask.closeTask();

            // vue.$openConfirm({
            //   title: '提示',
            //   desc: '任务已被恢复，是否前往查看？?',
            //   sure:'是',
            //   cancel:'否',
            //   onSure: async ()=>{
            //     vue.$store.commit('set_task_config', Object.assign(
            //       {},
            //       vue.$store.state.task_config,
            //       {jump: true},
            //     ))
            //     if(draft !==1){
            //       vue.$api.moduleTask.common.jumpToAppointPage();
            //     }else{
            //       vue.$router.push({name: 'fun_template_my', params: {type: 'draft'}});
            //     }
            //     vue.$api.moduleTask.openTask({taskId: id});
            //   },
            //   onCancel:()=>{},
            // })


          }

        },
        onCancel:()=>{},
      })
    } else {
      if(from == null && draft !==1){
        desc = '您已退出工作组，此任务将恢复到草稿箱';
      }
      vue.$openConfirm({
        title: '提示',
        desc,
        sure:'是',
        cancel:'否',
        onSure: async ()=>{

          const res = await vue.$global.doPost({
            url: '/projectTask/backDeleteTask',
            data: {
              taskId: id,
            },
          })
          if(res.message === 'success'){
            this.deleteFromList(item);
            // 关闭任务
            vue.$api.moduleTask.closeTask();

            vue.$openConfirm({
              title: '提示',
              desc: '任务已被恢复，是否前往查看？?',
              sure:'是',
              cancel:'否',
              onSure: async ()=>{
                vue.$store.commit('set_task_config', Object.assign(
                  {},
                  vue.$store.state.task_config,
                  {jump: true},
                ))
                if(draft !==1){
                  vue.$api.moduleTask.common.jumpToAppointPage();
                }else{
                  vue.$router.push({name: 'fun_template_my', params: {type: 'draft'}});
                }
                vue.$api.moduleTask.openTask({taskId: id});
              },
              onCancel:()=>{},
            })


          }

        },
        onCancel:()=>{},
      })
    }

  }
  /**
   * 从列表中删除指定项
   * @param {*} item
   */
  deleteFromList(item){
    vue.$store.commit('set_recycle_delete_id', item.id);
  }
  completeDelete(item){
    vue.$openConfirm({
      title: '提示',
      desc: '是否确认彻底删除，此操作不可撤销?',
      sure:'彻底删除',
      sureType: 'warn',
      cancel:'取消',
      onSure: async ()=>{
        const {id} = item;
        const res = await vue.$global.doPost({
          url: '/projectTask/clearDeleteTask',
          data: {
            taskIds: id,
          },
        })
        if(res.message === 'success'){
          this.deleteFromList(item);
          // 关闭任务
          vue.$api.moduleTask.closeTask();

        }

      },
      onCancel:()=>{},
    })
  }
  clearAll(){
    vue.$openConfirm({
      title: '提示',
      desc: '是否全部清空，此操作不可撤销?',
      sure:'全部清空',
      sureType: 'warn',
      cancel:'取消',
      onSure: async ()=>{
        const res = await vue.$global.doPost({
          url: '/projectTask/clearDeleteTask',
          data: {
            taskIds: '',
          },
        })
        if(res.message === 'success'){
          vue.$store.commit('set_recycle_clear_all', true);
        }

      },
      onCancel:()=>{},
    })
  }
}

const recycle_bin = new RecycleBin();

export default recycle_bin;
