<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="90%"
      width="80%"
    >
      <div
        class="c_plugin_batch_add_contacts_to_tasks_selected_task_list d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose ="handleClose"
          :title="`查看已选（${number}）`"
          :close="true"
          background_color="#fff"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          :user-info-simple-pmcs="userInfoSimplePmcs"
          :task-list="taskList"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonTitle from '@/components/PopUp/Common/Title'
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/PopUp/BatchAddContactsToTasks/SelectedTaskList/index'
import { listForClasses, PTask } from '@/types'
import tools from '@/api/tools'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent
  },
  data(){
    return {
      visible: false,
      // title: '',
      // onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      params: undefined,
      taskList: []
    }
  },
  computed: {
    title() {
      const { params } = this
      const { title } = params
      return title ?? '查看已选'
    },
    number() {
      const { taskList } = this
      if (!taskList || taskList.length === 0) {
        return 0
      }
      const filterList = taskList.filter((item) => {
        return item.selected
      })
      return filterList.length
    },
    userInfoSimplePmcs () {
      const { params } = this
      const { userInfoSimplePmcs } = params
      return userInfoSimplePmcs
    },
    onSure() {
      const { params } = this
      const { onSure } = params
      return onSure
    }
  },
  watch: {
    visible: function(val) {
      if (val) {
        const taskList = this.generateTaskList()
        this.$set(this, 'taskList', taskList)
      }
    }
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    },
    generateTaskList() {
      const { userInfoSimplePmcs } = this
      const { selectedList } = userInfoSimplePmcs
      // const { selectedList } = packageSelectTask
      const taskList = listForClasses({
        list: tools.deepClone(selectedList),
        classes: PTask,
        defaultParams: { selected: true } // 默认选中
      })
      // this.$set(this, 'taskList', taskList)
      console.log(taskList)
      return taskList
    }
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_batch_add_contacts_to_tasks_selected_task_list{
  background-color: #fff;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  overflow-y: auto;
  .main{
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
