var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            {
              attrs: {
                height: "auto",
                max_width: "400px",
                max_height: "800px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "components_plugin_modules_scan_code_index pb-20 d-flex flex-column",
                },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0",
                    attrs: { title: _vm.title, close: true },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _c("scan-code-page", {
                    staticClass: "main bbox",
                    attrs: {
                      order: _vm.order,
                      onSuccess: _vm.onSuccess,
                      onFail: _vm.onFail,
                      onCancel: _vm.onCancel,
                      onCancelApply: _vm.onCancelApply,
                      onClose: _vm.onClose,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }