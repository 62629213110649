<!--
 * @Author       : Hugo
 * @Date         : 2022-04-13 12:07:01
 * @LastEditTime : 2022-04-13 14:28:22
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/ShareConfirm/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      width="auto"
    >
      <div class="c_plugin_share_confirm d-flex flex-column"
      >
        <common-title
          class        = "flex-shrink-0"
          @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
          background_color = "#fff"
        >
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          :list="list"
          :props_work="work"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from '@/components/PopUp/ShareConfirm/index.vue'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      title: '',
      onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      list: undefined,
      work: '',
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(work){
      if(this.input == ''){
        this.$notice({desc: this.hint})
        return
      }
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure(work)
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_share_confirm{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
