/*
 * @Author       : Hugo
 * @Date         : 2021-04-26 13:42:13
 * @LastEditTime : 2021-08-05 10:37:39
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/utils/index.js
 * @Description  : 
 * @^_^          : 
 */
import common from '@/api/utils/common';
import prompt from '@/api/utils/prompt';
import users from './users';
import push from './push';
import debounce from './debounce';
const index = {
  ...common,
  prompt,
  users,
  push,
  debounce,
}
export default index;