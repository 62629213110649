var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_consulting_modules_common bbox" }, [
    _c("div", { staticClass: "hint" }, [
      _vm._v("您好，请确认并填写以下信息，方便工作人员与您及时联系。"),
    ]),
    _c(
      "div",
      {
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [_vm._v("真实姓名")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name,
              expression: "name",
            },
          ],
          staticClass: "input",
          attrs: {
            type: "text",
            placeholder: "请输入真实姓名",
            disabled: _vm.readonly,
          },
          domProps: { value: _vm.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.name = $event.target.value
            },
          },
        }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [_vm._v("联系方式")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone",
            },
          ],
          staticClass: "input",
          attrs: {
            type: "text",
            maxlength: "11",
            placeholder: "请输入联系方式",
            disabled: _vm.readonly,
          },
          domProps: { value: _vm.phone },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.phone = $event.target.value
            },
          },
        }),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.need_code,
            expression: "need_code",
          },
        ],
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [_vm._v("短信验证码")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.code,
              expression: "code",
            },
          ],
          staticClass: "input",
          attrs: { type: "text", placeholder: "请输入短信验证码" },
          domProps: { value: _vm.code },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.code = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "button verify flex-shrink-0 un_sel",
            class: { active: _vm.countdown !== 0 },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.getCode()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.countdown !== 0 ? `已发送 ${_vm.countdown}s` : "发送验证码"
              )
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [_vm._v("您的职位")]),
        _c(
          "el-select",
          {
            staticClass: "select_group",
            attrs: {
              clearable: "",
              disabled: _vm.readonly,
              placeholder: "请选择您的职位",
            },
            model: {
              value: _vm.identities,
              callback: function ($$v) {
                _vm.identities = $$v
              },
              expression: "identities",
            },
          },
          _vm._l(_vm.identities_list, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [_vm._v("机构名称")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.school,
              expression: "school",
            },
          ],
          staticClass: "input",
          attrs: {
            type: "text",
            disabled: _vm.readonly,
            placeholder: "请输入机构名称",
          },
          domProps: { value: _vm.school },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.school = $event.target.value
            },
          },
        }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "line_group bbox d-flex align-start",
        class: { readonly: _vm.readonly },
      },
      [
        _c("div", { staticClass: "key flex-shrink-0" }, [
          _vm._v(_vm._s(_vm.content_key)),
        ]),
        _c("el-input", {
          staticClass: "textarea",
          attrs: {
            type: "textarea",
            disabled: _vm.readonly,
            placeholder: "请输入内容",
            maxlength: "80",
            "show-word-limit": "",
            resize: "none",
          },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "opertaion_group" }, [
      _c(
        "div",
        {
          staticClass: "button btn_dark",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchSubmit()
            },
          },
        },
        [_vm._v(_vm._s(_vm.submit_again ? "再次提交" : _vm.submit_text))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }