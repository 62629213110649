var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_payment_buy_now_index bbox d-flex flex-column justify-space-between",
    },
    [
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "task_name_group d-flex align-center" }, [
          _c("div", { staticClass: "name mr-20" }, [_vm._v("任务名称")]),
          _c("div", { staticClass: "task_name" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _c(
          "div",
          { staticClass: "unit_price_group d-flex align-center mt-20" },
          [
            _c("div", { staticClass: "name mr-20" }, [_vm._v("任务单价")]),
            _c("div", { staticClass: "price" }, [
              _vm._v("¥ " + _vm._s(_vm.unit_price)),
            ]),
          ]
        ),
        _c("div", { staticClass: "line mt-20" }),
        _c(
          "div",
          {
            staticClass: "d-flex align-center mt-20",
            class: { gray: _vm.binding === undefined },
          },
          [
            _c("div", { staticClass: "name mr-6" }, [
              _vm._v("是否绑定在此账号？"),
            ]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  placement: "right",
                  "popper-class": "",
                },
              },
              [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _vm._v("如绑定在此账号，购买后即可自动绑定在该账户"),
                  _c("br"),
                  _vm._v(
                    "如绑定在其他账号，购买后需在我的订单中查看激活码手动激活。"
                  ),
                ]),
                _c("img", {
                  staticClass: "question_mark",
                  attrs: {
                    src: require("@/assets/images/common/question_mark.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "binding_group d-flex align-center mt-20",
            class: { gray: _vm.binding === undefined },
          },
          [
            _c(
              "div",
              {
                staticClass: "button_group mr-10",
                class: {
                  active: _vm.binding === 0,
                  disabled: _vm.task.buy,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleBinding(0)
                      },
                    },
                  },
                  [_vm._v("\n          是\n        ")]
                ),
                _c("div", { staticClass: "placeholder mb-5" }, [_vm._v(" ")]),
                _c("div", { staticClass: "hint" }, [
                  _vm._v("*购买后将自动绑定至此账户中"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "button_group",
                class: { active: _vm.binding === 1 },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.binding = 1
                      },
                    },
                  },
                  [_vm._v("否")]
                ),
                _c("div", { staticClass: "placeholder mb-5" }, [_vm._v(" ")]),
                _c("div", { staticClass: "hint" }, [
                  _vm._v("*购买后需在我的订单中查看激活码手动激活"),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.binding === 1,
                expression: "binding === 1",
              },
            ],
            staticClass: "binding_number_group d-flex align-center mt-20",
          },
          [
            _c("div", { staticClass: "name mr-20" }, [
              _vm._v("选择绑定账户数"),
            ]),
            _c("number-group", {
              attrs: { number: _vm.binding_number },
              on: {
                handleChange: _vm.bindingNumberChange,
                handleMinus: _vm.bindingNumberMinus,
                handlePlus: _vm.bindingNumberPlus,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "line my-10" }),
        _c(
          "div",
          {
            staticClass: "d-flex align-center mt-10",
            class: { gray: _vm.binding === undefined },
          },
          [
            _c("div", { staticClass: "name mr-6" }, [
              _vm._v("是否需要同时购买VIP"),
            ]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.vip_hint,
                  placement: "right",
                  "popper-class": "",
                },
              },
              [
                _c("img", {
                  staticClass: "question_mark",
                  attrs: {
                    src: require("@/assets/images/common/question_mark.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "binding_group d-flex align-center mt-20",
            class: { gray: _vm.binding === undefined },
          },
          [
            _c(
              "div",
              {
                staticClass: "button_group mr-10",
                class: { active: _vm.buy_vip === true },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.choiceBuyVip(true)
                      },
                    },
                  },
                  [_vm._v("是")]
                ),
                _c("div", { staticClass: "placeholder mb-5" }, [_vm._v(" ")]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "button_group",
                class: { active: _vm.buy_vip === false },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.choiceBuyVip(false)
                      },
                    },
                  },
                  [_vm._v("否")]
                ),
                _c("div", { staticClass: "placeholder mb-5" }, [_vm._v(" ")]),
                _vm.vip_hint_status
                  ? _c("div", { staticClass: "hint" }, [
                      _vm._v("*非VIP账户使用时部分功能可能会受限"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.buy_vip === true,
                expression: "buy_vip === true",
              },
            ],
            staticClass: "binding_number_group d-flex align-center mt-10",
          },
          [
            _c("div", { staticClass: "name mr-20" }, [_vm._v("选择VIP年限")]),
            _c("number-group", {
              attrs: { number: _vm.vip_number },
              on: {
                handleChange: _vm.vipNumberChange,
                handleMinus: _vm.vipNumberMinus,
                handlePlus: _vm.vipNumberPlus,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "submit_group un_sel mt-20 flex-shrink-0",
          class: { gray: _vm.buy_vip === undefined },
        },
        [
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "total_price_group d-flex align-center mt-20" },
            [
              _c("div", { staticClass: "name" }, [_vm._v("总计：")]),
              _c("div", { staticClass: "price mx-10" }, [
                _vm._v("¥ " + _vm._s(_vm.total_price)),
              ]),
              _c(
                "el-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show_total_hint,
                      expression: "show_total_hint",
                    },
                  ],
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    placement: "right",
                    "popper-class": "",
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("计算过程："),
                    _c("br"),
                    _vm._v(_vm._s(_vm.total_price_hint)),
                  ]),
                  _c("img", {
                    staticClass: "question_mark",
                    attrs: {
                      src: require("@/assets/images/common/question_mark.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button submit btn_dark mt-20",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doSubmit()
                },
              },
            },
            [_vm._v("确认")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }