var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_common_modules_thumb_task" }, [
    _c("div", { staticClass: "container flex" }, [
      _vm.switch_select
        ? _c("div", { staticClass: "select_group" }, [
            _c("div", {
              staticClass: "icon cp",
              class: {
                on: _vm.selected,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doSelect()
                },
              },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "task_group flexsb cp",
          on: {
            click: function ($event) {
              return _vm.handleClick()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "name_group flexsb" },
            [
              _c(
                "div",
                { staticClass: "name" },
                [
                  _c("title-status-tag", {
                    staticClass: "status_tag_group",
                    attrs: {
                      "level-status": String(_vm.p_task.levelStatus),
                      "process-status": String(_vm.p_task.processStatus),
                      size: "14px",
                    },
                  }),
                  _c("span", { staticClass: "task_name" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.p_task.name) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right-start",
                    width: "400",
                    trigger: "click",
                    "visible-arrow": false,
                    "popper-class": "c_collect_folder_unit_popover",
                  },
                  on: {
                    hide: function ($event) {
                      return _vm.hidePopoverHandler()
                    },
                    show: function ($event) {
                      return _vm.popoverShowHandler()
                    },
                  },
                  model: {
                    value: _vm.switch_popover,
                    callback: function ($$v) {
                      _vm.switch_popover = $$v
                    },
                    expression: "switch_popover",
                  },
                },
                [
                  !_vm.start_moving
                    ? _c(
                        "div",
                        { ref: "menu_group", staticClass: "menu_group" },
                        [
                          _c("div", { staticClass: "menu_list_group" }, [
                            _vm.canSetStatus
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        _vm.switch_more_btn = false
                                        _vm.touchSetStatusTag()
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n                  设置状态\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.canSetGeneralTag
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.stouchSetGeneralTag()
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n                  设置标签\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.moveable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startMove()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/images/custom/menu/move.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v("移动至"),
                                    ]),
                                    _c("img", {
                                      staticClass: "arrow",
                                      attrs: {
                                        src: require("@/assets/images/custom/menu/arrow.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.collectable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.touchCollect()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/images/custom/menu/move_out.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.p_task.collectionflag
                                              ? "取消收藏"
                                              : "收藏"
                                          ),
                                        },
                                      },
                                      [_vm._v("取消收藏")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.disable_select && _vm.multipleable
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "item un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startSelect()
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/images/custom/menu/multiple.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v("多选任务"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.callTask()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/call.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("调用任务"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "item un_sel cp",
                                on: {
                                  click: function ($event) {
                                    return _vm.shareTask()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/custom/menu/share.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("分享任务"),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.switch_popover && _vm.start_moving
                    ? _c(
                        "div",
                        {
                          staticClass: "moving_path_wrapper",
                          style: {
                            width: `${_vm.moving_path_width}px`,
                            "z-index": _vm.moving_path_z_index,
                          },
                        },
                        [
                          _vm.switch_popover && _vm.start_moving
                            ? _c("moving-path", {
                                attrs: {
                                  mask: false,
                                  multiple: false,
                                  current_folder: _vm.current_folder,
                                  type: "file",
                                  id: String(_vm.p_task.id),
                                  max_height: String(_vm.moving_max_height),
                                },
                                on: {
                                  handleRemove: _vm.handleRemove,
                                  handleSure: _vm.handleSure,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.menu,
                        expression: "menu",
                      },
                    ],
                    staticClass: "more_btn button un_sel",
                    attrs: { slot: "reference" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$api.stopPropagation($event)
                      },
                    },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.show_time
            ? _c("div", { staticClass: "time_group" }, [
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(_vm._f("universalDate")(_vm.p_task.updateTime))
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.is_shopping_mail && _vm.is_teach
            ? _c("img", {
                staticClass: "shopping_mail_teaching",
                attrs: {
                  src: require("@/assets/images/shopping_mail/teaching_task.png"),
                  alt: "",
                },
              })
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "info_group flexsb" }, [
        _vm.p_task.createuserthumbnail
          ? _c(
              "div",
              { staticClass: "avatar_group" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.p_task.createusername,
                      placement: "top-start",
                      "open-delay": 500,
                    },
                  },
                  [
                    _vm.show_user
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${_vm.p_task.createuserthumbnail}@!small200`,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          : _c("div"),
        _c(
          "div",
          { staticClass: "tag_group flex" },
          [
            0
              ? _c("div", { staticClass: "tag finance" }, [
                  _vm._v("\n          财\n        "),
                ])
              : _vm._e(),
            _vm.show_mini_tag
              ? [
                  _vm.is_teach
                    ? _c("div", { staticClass: "tag teach" }, [_vm._v("教")])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm.show_from
      ? _c(
          "div",
          { staticClass: "from_group flex" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("来源:")]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.p_task.from,
                  placement: "bottom-start",
                  "open-delay": 500,
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.p_task.from)),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }