var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showUnit
    ? _c(
        "div",
        {
          staticClass:
            "c_pop_up_migrate_task_select_task_group_group_unit bbox d-flex justify-center cp",
          class: {
            active: _vm.active,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSelect.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "chat_group d-flex align-center justify-start" },
            [
              _c("img", {
                staticClass: "avatar",
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.chatData.photo}`,
                  alt: "",
                },
              }),
              _c("div", { staticClass: "group_name" }, [
                _vm._v("\n      " + _vm._s(_vm.chatData.chatName) + "\n    "),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }