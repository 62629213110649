<template>
  <div
    class="sketch_common_page"
    @click.stop="openAppoint();"
  >{{msg.content}}</div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  methods:{
    openAppoint(){
      const {msg} = this;
      const {conId, conIdP} = msg;
      this.$emit('handleAppoint', {parent_id: conIdP, widget_id: conId});

    }
  }
}
</script>

<style lang="scss" scoped>
.sketch_common_page{
  @include multi_line_intercept(3);
  max-width: 650px;
  padding: {
    // top: 6px;
  }
  color: #555;
  font-size: 14px;
  word-break: break-all;
}
</style>