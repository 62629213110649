/*
 * @Author       : Hugo
 * @Date         : 2022-01-11 10:24:07
 * @LastEditTime : 2022-01-11 10:26:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/clipboard/index.js
 * @Description  : 剪切板
 * @^_^          : 
 */
import vue from '@/main';
class Clipboard{
  copyText(params = {}){
    const {text, notice} = params;
    vue.$copyText(text).then( (e)=>{
      // alert('Copied')
      vue.$notice({desc: notice || '已复制', type: 'success'});
      // // console.log(e)
    }, (e) => {
      // alert('Can not copy')
      // this.$notice({desc: '激活码已复制', type: 'success'});
      // console.log(e)
    })
  }
}

const clipboard = new Clipboard();

export default clipboard;