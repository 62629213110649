<template>
  <div class="c_pop_up_batch_add_contacts_to_tasks_select_task_operation_bar d-flex justify-space-between bbox">
    <div
      class="select_group d-flex"
      @click.stop="checkList"
    >
      <div class="text">
        已选：
      </div>
      <div class="number">
        {{ number }} 个
      </div>
      <img
        src="~@/assets/images/teaching_package/select_task/select_number.png"
        alt=""
        class="icon ml-10">
    </div>
    <div class="btn_group d-flex">
      <div
        class="btn_light btn"
        @click.stop="doClose"
      >
        取消
      </div>
      <div
        class="btn_dark btn"
        @click.stop="doSure"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
import tools from '@/api/tools'
import router from '@/router'
import store from '@/store'
import { PTaskLog } from '@/types'
import vue from '@/main'

export default {
  name: 'OperationBar',
  props: {
    userInfoSimplePmcs: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    number() {
      const { userInfoSimplePmcs } = this
      const { selectedList } = userInfoSimplePmcs
      return selectedList.length
    }
  },
  methods: {
    async doSure() {
      if (this.loading) {
        return
      }
      console.log(this.userInfoSimplePmcs.selectedList)
      const { userInfoSimplePmcs } = this
      const { selectedList } = userInfoSimplePmcs
      const { currentRoute } = router
      console.log(currentRoute)
      const chatId = tools.atob(currentRoute.params.id)
      if (!chatId) {
        return
      }
      this.loading = true
      // const res = await userInfoSimplePmcs.batchContentsToTasks({ chatId })
      const res = await userInfoSimplePmcs.batchContentsToTasks({
        chatId
      })
      this.loading = false
      const { message, contents } = res
      if (message === 'success') {
        const { taskLog } = contents
        const { my_task_list, message_list } = store.state
        if (my_task_list) {
          // 移除选择的项目
          selectedList.forEach((item) => {
            const index = my_task_list.find((unit) => {
              return Number(unit.id) === Number(item.id)
            })
            if (index !== -1) {
              my_task_list.splice(index, 1)
            }
          })
          // 将选择的项目加到最前面
          store.state.my_task_list = selectedList.concat(my_task_list)
        }
        // 追加消息列表
        // const { name:currentRouteName } = currentRoute
        // 在列表更新之前获取聊天是否在底部 如果不在底部；则unreadNumber+1,并且不触发滚动到底部
        const isBottom = store.state.dialogData.isBottom
        const pTaskLog = new PTaskLog(taskLog)
        message_list.push(pTaskLog)
        if (isBottom) {
          // 滚动到底部
          store.commit('set_task_trigger_scroll_to_bottom', true)
        } else {
          store.state.dialogData.unreadNumber += 1
        }
        this.$notice({
          desc: '添加成功',
          type: 'success'
        })
        this.doClose()
        userInfoSimplePmcs.clearBatchData()
      }
    },
    doClose() {
      this.$closeBatchAddContactsToTasksSelectTask()
    },
    checkList() {
      const { userInfoSimplePmcs, number } = this
      if (number === 0) {
        return
      }
      this.$openBatchAddContactsToTasksSelectedTaskList({
        userInfoSimplePmcs
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_batch_add_contacts_to_tasks_select_task_operation_bar{
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1);
  border-radius: 0 0 10px 10px;
  padding: 0 20px;
  .select_group{
    font-size: 14px;
    color: #333;
  }
  .btn_group{
    .btn{
      cursor: pointer;
      font-size: 14px;
      line-height: 40px;
      border-radius: 4px;
      padding: 0 26px;
      margin-left: 12px;
    }
  }
}
</style>