var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_popup_record_of_communication_index_page bbox" },
    [
      _c("div", { staticClass: "container bbox d-flex flex-column" }, [
        _c("div", { staticClass: "header_group flex-shrink-0" }, [
          _c(
            "div",
            {
              staticClass:
                "title_group d-flex align-center un_sel justify-center",
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("搜索沟通记录")]),
              _c("div", {
                staticClass: "close cp",
                on: {
                  click: function ($event) {
                    return _vm.doClose()
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "search_group bbox" }, [
            _c("div", {
              staticClass: "icon",
              on: {
                click: function ($event) {
                  return _vm.$refs.search.focus()
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              ref: "search",
              staticClass: "search bbox",
              class: { disabled: _vm.tab_type === "picture" },
              attrs: { type: "text", placeholder: "搜索..." },
              domProps: { value: _vm.search },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleSearchKeydown($event)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.search = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "tab_group bbox" }, [
            _c("div", { staticClass: "tab_bar d-flex align-center" }, [
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab_type === "task" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("task")
                    },
                  },
                },
                [_vm._v("任务模板")]
              ),
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab_type === "text" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("text")
                    },
                  },
                },
                [_vm._v("文本消息")]
              ),
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab_type === "file" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("file")
                    },
                  },
                },
                [_vm._v("附件")]
              ),
              _c(
                "div",
                {
                  staticClass: "tab",
                  class: { active: _vm.tab_type === "picture" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("picture")
                    },
                  },
                },
                [_vm._v("图片")]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "main d-flex flex-column" }, [
          _c(
            "div",
            { ref: "wrapper", staticClass: "wrapper" },
            [
              !_vm.do_search
                ? _c("hint")
                : [
                    _vm.tab_type === "task"
                      ? _c("task", {
                          ref: "task",
                          staticClass: "bbox section",
                          attrs: {
                            keyword: _vm.keyword,
                            chat_type: Number(_vm.chat_type),
                            chat_id: Number(_vm.chat_id),
                          },
                        })
                      : _vm._e(),
                    _vm.tab_type === "text"
                      ? _c("text-group", {
                          ref: "text",
                          staticClass: "bbox section",
                          attrs: {
                            keyword: _vm.keyword,
                            chat_type: Number(_vm.chat_type),
                            chat_id: Number(_vm.chat_id),
                          },
                        })
                      : _vm._e(),
                    _vm.tab_type === "file"
                      ? _c("file", {
                          ref: "file",
                          staticClass: "bbox section",
                          attrs: {
                            keyword: _vm.keyword,
                            chat_type: Number(_vm.chat_type),
                            chat_id: Number(_vm.chat_id),
                          },
                        })
                      : _vm._e(),
                    _vm.tab_type === "picture"
                      ? _c("picture-group", {
                          ref: "picture",
                          staticClass: "bbox section",
                          attrs: {
                            keyword: _vm.keyword,
                            chat_type: Number(_vm.chat_type),
                            chat_id: Number(_vm.chat_id),
                          },
                        })
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }