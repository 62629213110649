<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 16:47:16
 * @LastEditTime : 2022-04-25 15:35:10
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Folder/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_folder_index bbox"
  >
    <div class="title_group flex-shrink-0 bbox">分组</div> 
    <div class="list_group bbox d-flex flex-wrap align-start justify-start"
      ref="list"
    >
      <template
        v-for="(item,index) in list"
      >
        <folder-unit
          :key="index"
          class="mr-10 mb-5"
          :p_folder="item"
          :preview="preview"
          :show_menu="show_menu"
          :call="call"
          :current_folder="current_folder"
          :disable_select="disable_select"
          :switch_select="switch_select"
          @handleRemove="handleRemove"
          @handleStartSelect="handleStartSelect"
        ></folder-unit>
      </template>
    </div>
  </div>
</template>

<script>
import FolderUnit from './FolderUnit.vue'
export default {
  components: {
    FolderUnit,
  },
  props: {
    list: {
      type: Array,
      default: undefined,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    switch_select: {
      type: Boolean,
      default: false,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
    call: { // 是否调用
      type: Boolean,
      default: false,
    },
    show_menu: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goTop(){
      this.$refs.list.scrollTop = 0
    },
    handleRemove(p_folder){
      console.log(p_folder)
      const {list} = this
      const index = list.findIndex(item=>{
        return item.id === p_folder.id
      })
      if(index !== -1){
        list.splice(index, 1)
      }
    },
    handleStartSelect(){
      this.$emit('handleStartSelect')
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_folder_index{
  width: 100%;
  .title_group{
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 24px;
    border-left: 4px solid $main_blue;
    padding: {
      left: 20px;
    }
    margin: 10px 0;
    &.call_custom{
      margin-left: 20px;
    }
  }
  .list_group{
    @include scrollbar;
    width: 100%;
    padding: 0 20px;
    max-height: 240px;
    overflow: auto;
  }
}
</style>
