<template>
  <div
    class="pop_up_dialog_send_files_progress d-flex flex-column align-center bbox justify-center"
  >
    <div class="progress_group">
      <div
        class="progress"
        :style="{width: `${progress}%`}"
      ></div>
    </div>
    <div
      class="text"
    >
      正在上传 {{ progress }}%
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    dialog: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    totalMax() {
      const { dialog } = this
      if (dialog === undefined) {
        return 0
      }
      const { files } = dialog
      if ( files && files.length ) {
        return files.length * 100
      }
      return 0
    },
    currentProgress() {
      const { dialog } = this
      if (dialog === undefined) {
        return 0
      }
      let currentProgress = 0
      const { files } = dialog
      if (files && files.length) {
        for (let i in files) {
          currentProgress += files[i].progress
        }
      }
      return currentProgress
    },
    progress() {
      const { totalMax, currentProgress } = this
      if (totalMax) {
        return Math.ceil((currentProgress / totalMax) * 100)
      }
      return 0
    }
  }
}
</script>

<style scoped lang="scss">
.pop_up_dialog_send_files_progress{
  width: 100%;
  height: 50px;
  padding: 0 20px;
  .progress_group{
    width: 100%;
    position: relative;
    height: 8px;
    border-radius: 4px;
    background-color: #ccc;
    .progress{
      position: absolute;
      height: 8px;
      border-radius: 4px;
      top: 0;
      left: 0;
      background-color: $main_blue;
      width: 50%;
    }
  }
  .text{
    font-size: 14px;
    color: #666;
    padding-top: 10px;
  }
}
</style>