<!--
 * @Author       : Hugo
 * @Date         : 2022-01-06 14:54:18
 * @LastEditTime : 2022-02-23 10:10:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/BuyNow/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->

<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      max_width="800px"
      max_height="800px"
    >
      <div class="components_plugin_modules_buy_now_index pb-20 d-flex flex-column"
      >
        <common-title
              class        = "flex-shrink-0"
              @handleClose = "handleClose"
            :title         = "title"
            :close         = "true"
        >
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <buy-now-page
          class="main bbox"
          :task="task"
        ></buy-now-page>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import BuyNowPage from '@/components/Payment/BuyNow/index.vue';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    BuyNowPage,
  },
  data(){
    return {
      visible: false,
      task: undefined,
      title: '',
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {
    handleClose(){
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.components_plugin_modules_buy_now_index{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    padding: 40px;
    overflow-y: auto;
  }
}
</style>
