<!--
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-08 20:40:50
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 20:52:51
 * @FilePath     : /miaohang/src/components/Aggrement/Plugins/Service/Main.vue
 * @Description  : 
-->
<template>
  <service-agreement></service-agreement>
</template>


<script>
import ServiceAgreement from '@/components/Clause/ServiceAgreement.vue'
export default {
  components: {
    ServiceAgreement,
  }
}
</script>
