<template>
  <div class="contacts">
    <div class="contacts-left">
      <h1 class="logo">
        <img src="../../assets/logo.png" alt />
      </h1>
      <div
        class="tupian"
        @click="book(1)"
        :style="disabled?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-message.png" alt class="book" v-if="disabled" />
        <img src="../../assets/book.png" alt class="book" v-else />
      </div>
      <div class="bok"></div>
      <div
        class="pple"
        @click="book(2)"
        :style="people?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-people-sel.png" alt class="people" v-if="people" />
        <img src="../../assets/icon-people-nor.png" alt class="people" v-else />
      </div>
      <div
        class="mpa"
        @click="book(3)"
        :style="mpashow?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/modulex.png" alt class="mplae" v-if="mpashow" />
        <img src="../../assets/icon-Template-nor.png" alt class="mplae" v-else />
      </div>
      <img src alt class="phical" />
    </div>
    <div class="contacts-con">
      <div class="bar">
        <div class="barinput">
          <input type="text" v-model="searchString" placeholder="搜索" class="hder" />
          <img src="../../assets/icon-search.png" alt class="search" />
        </div>
        <div class="imgbox">
          <img src="../../assets/icon-add.png" alt class="jia" />
        </div>
      </div>
      <div class="applyc">
        <div class="apply">
          <img src="../../assets/icon-apply.png" alt class="imgrec" />
          <p class="record">申请记录</p>
        </div>
        <div class="xian1"></div>
        <div class="work">
          <div class="w" @click="gongzuozuClick">
            <img v-show="!gongzuozu" src="../../assets/icon-shouqi-nor.png" alt class="triangle" />
            <img v-show="gongzuozu" src="../../assets/icon-zhankaix.png" alt class="triangle" />
            <p class="zu">工作组</p>
          </div>
          <div class="wor" v-show="gongzuozu">
            <div class="img">
              <div class="img1">
                <div class="wrp">
                  <div class="imgx"></div>
                  <div class="imgl"></div>
                </div>
                <div class="xia">
                  <div class="imgs"></div>
                  <div class="imgr"></div>
                </div>
              </div>
              <p class="pro">1391项目组</p>
            </div>
            <div class="img">
              <div class="img1">
                <div class="wrp">
                  <div class="imgx"></div>
                  <div class="imgl"></div>
                </div>
                <div class="xia">
                  <div class="imgs"></div>
                  <div class="imgr"></div>
                </div>
              </div>
              <p class="prol">杨勇、瓜瓜、王明、李...</p>
            </div>
            <div class="img">
              <div class="img1">
                <div class="wrp">
                  <div class="imgx"></div>
                  <div class="imgl"></div>
                </div>
                <div class="xia">
                  <div class="imgs"></div>
                  <div class="imgr"></div>
                </div>
              </div>
              <p class="pror">中国进口商品展览会</p>
            </div>
          </div>
        </div>
        <div class="work1"></div>
        <div class="lian">
          <div class="liana" @click="lianaClick">
            <img v-show="liana" src="../../assets/icon-zhankaix.png" alt class="triangle" />
            <img v-show="!liana" src="../../assets/icon-shouqi-nor.png" alt class="triangle" />
            <p class="ted">联系人</p>
          </div>
          <div class="lianxipeple" v-show="liana">
            <div class="c">
              <p class="b">A</p>
            </div>
            <div class="xi">
              <div class="pe">
                <img src alt class="tp" />
                <p class="am">AM</p>
              </div>
              <div class="pe">
                <img src alt class="tp" />
                <p class="ak">奥卡</p>
              </div>
              <div class="pe">
                <img src alt class="tp" />
                <p class="aks">艾科斯</p>
              </div>
            </div>
            <div class="b">
              <div class="bc">
                <p class="bi">B</p>
              </div>
              <div class="bxi">
                <img src alt class="tp" />
                <p class="am">办文博</p>
              </div>
              <div class="pe">
                <img src alt class="tp" />
                <p class="ak">本开</p>
              </div>
              <div class="fbs">
                <img src alt class="tp" />
                <p class="fps">博平生</p>
              </div>
            </div>
          </div>
        </div>
        <div class="line2"></div>
        <div class="bably">
          <div class="prob" @click="probClick">
            <img v-show="prob" src="../../assets/icon-zhankaix.png" alt class="inve" />
            <img v-show="!prob" src="../../assets/icon-shouqi-nor.png" alt class="inve" />
            <p class="proba">可能认识的人</p>
          </div>
          <div class="keneng" v-show="prob">
            <div class="ak">
              <img src alt class="aks" />
              <p class="ams">AM</p>
            </div>
            <div class="ask">
              <img src alt class="asks" />
              <p class="aks">奥卡</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line1"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchString: "",
      disabled: false,
      people: false,
      mpashow: false,
      item: "",
      gongzuozu: false,
      liana: false,
      prob: false
    };
  },
  created() {
    let object = JSON.parse(localStorage.getItem("object"));
    let loginUserCode = object.loginUserCode;
    // // console.log(loginUserCode);
    this.$http.loginUserCode(loginUserCode).then(res => {
      this.router.push("/");
    });
  },
  methods: {
    book(index) {
      if (index == 1) {
        this.mpashow = false;
        this.people = false;
        this.disabled = true;
      }
      if (index == 2) {
        this.mpashow = false;
        this.disabled = false;
        this.people = true;
      }
      if (index == 3) {
        this.people = false;
        this.disabled = false;
        this.mpashow = true;
      }
    },
    pple() {
      this.people = !this.people;
    },
    mpaclick() {
      this.mpashow = !this.mpashow;
    },
    gongzuozuClick() {
      this.gongzuozu = !this.gongzuozu;
    },
    lianaClick() {
      this.liana = !this.liana;
    },
    probClick() {
      this.prob = !this.prob;
    }
  }
};
</script>

<style  lang='less' scoped>
.contacts {
  width: 100%;
  height: 100%;
  display: flex;

  .contacts-left {
    width: 60px;
    height: 100%;
    background: #0054a6;
    .logo {
      display: block;
      width: 48px;
      height: 48px;
      margin-top: 6px;
      margin-left: 6px;
    }
    .tupian {
      widows: 62px;
      height: 50px;
      margin-top: 30px;
      .book {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 12px;
        z-index: 11;
      }
    }
    .pple {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .people {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
    .mpa {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .mplae {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
    .phical {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background: orange;
      margin-top: 381px;
      margin-left: 8px;
    }
  }
  .contacts-con {
    width: 250px;
    // height: 100%;
    overflow: hidden;
    .bar {
      display: flex;
      width: 250px;
      height: 66px;
      border-bottom: 2px solid #f4f4f4;
      overflow: hidden;
      .barinput {
        margin-top: 16px;
        margin-left: 16px;
        .hder {
          width: 138px;
          height: 30px;
          background: rgba(243, 243, 243, 1);
          border-radius: 15px;
          border: none;
          outline: none;
          position: relative;
          padding-left: 40px;
        }
        .search {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 24px;
          left: 86px;
        }
      }
      .imgbox {
        width: 30px;
        height: 30px;
        background: rgba(243, 243, 243, 1);
        border-radius: 50%;
        margin-left: 14px;
        margin-top: 16px;
        .jia {
          display: block;
          width: 10px;
          height: 11px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
    .applyc {
      width: 250px;
      height: 100%;
      overflow-x: scroll;

      .apply {
        width: 250px;
        height: 84px;
        display: flex;
        .imgrec {
          display: block;
          width: 44px;
          height: 44px;
          margin-top: 20px;
          margin-left: 18px;
        }
        .record {
          width: 56px;
          height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 35px;
          margin-left: 16px;
        }
      }

      .xian1 {
        width: 232px;
        height: 2px;
        background: rgba(240, 240, 240, 1);
        margin-left: 19px;
      }
      .work {
        width: 250px;
        .w {
          width: 250px;
          height: 52px;
          display: flex;
          .triangle {
            display: block;
            width: 10px;
            height: 8px;
            margin-left: 16px;
            margin-top: 22px;
          }
          .zu {
            width: 42px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            margin-top: 15px;
            margin-left: 6px;
          }
        }
        .wor {
          .img {
            width: 250px;
            height: 66px;
            display: flex;
            .img1 {
              width: 44px;
              height: 44px;
              background: pink;
              //  margin-top:22px;
              //  margin-left: 17px;
              margin: 11px 0 0 17px;
              .wrp {
                display: flex;
                .imgx {
                  width: 22px;
                  height: 22px;
                  background: orange;
                }
                .imgl {
                  width: 22px;
                  height: 22px;
                  background: blue;
                }
              }
              .xia {
                display: flex;
                .imgs {
                  width: 22px;
                  height: 22px;
                  background: purple;
                }
                .imgr {
                  width: 22px;
                  height: 22px;
                  background: red;
                }
              }
            }
            .pro {
              width: 75px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 25px;
              margin-left: 7px;
            }
            .prol {
              width: 151px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 25px;
              margin-left: 7px;
            }
            .pror {
              width: 126px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 25px;
              margin-left: 7px;
            }
          }
        }
      }
      .work1 {
        width: 232px;
        height: 2px;
        background: rgba(240, 240, 240, 1);
        margin-left: 17px;
      }
      .lian {
        .liana {
          display: flex;
          .triangle {
            display: block;
            width: 10px;
            height: 8px;
            margin-left: 16px;
            margin-top: 22px;
          }
          .ted {
            width: 42px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            margin-top: 15px;
            margin-left: 6px;
          }
        }
        .lianxipeple {
          .c {
            .b {
              width: 10px;
              height: 11px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(170, 170, 170, 1);
              margin-left: 16px;
              margin-top: 24px;
            }
          }
          .xi {
            .pe {
              display: flex;
              .tp {
                display: block;
                width: 44px;
                height: 44px;
                background: red;
                margin-top: 20px;
                margin-left: 16px;
              }
              .am {
                width: 23px;
                height: 11px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-left: 15px;
                margin-top: 37px;
              }
            }
            .ak {
              width: 28px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 32px;
              margin-left: 15px;
            }
            .aks {
              width: 42px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 31px;
              margin-left: 16px;
            }
          }
          .b {
            .bc {
              .bi {
                width: 10px;
                height: 11px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(170, 170, 170, 1);
                margin-left: 16px;
                margin-top: 24px;
              }
            }
            .bxi {
              display: flex;
              .tp {
                width: 44px;
                height: 44px;
                background: red;
                display: block;
                margin-top: 20px;
                margin-left: 16px;
              }
              .am {
                width: 42px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-top: 36px;
                margin-left: 15px;
              }
            }
            .pe {
              display: flex;
              .tp {
                width: 44px;
                height: 44px;
                background: red;
                display: block;
                margin-top: 20px;
                margin-left: 16px;
              }
              .ak {
                width: 28px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-top: 36px;
                margin-left: 15px;
              }
            }
            .fbs {
              display: flex;
              .tp {
                width: 44px;
                height: 44px;
                background: red;
                display: block;
                margin-top: 20px;
                margin-left: 16px;
              }
              .fps {
                width: 42px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-top: 36px;
                margin-left: 15px;
              }
            }
          }
        }
      }
      .line2 {
        width: 232px;
        height: 2px;
        background: rgba(240, 240, 240, 1);
        margin-top: 20px;
        margin-left: 19px;
      }
      .bably {
        .prob {
          display: flex;
          .inve {
            display: block;
            width: 10px;
            height: 8px;
            margin-left: 16px;
            margin-top: 22px;
          }
          .proba {
            width: 84px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            margin-left: 6px;
            margin-top: 15px;
          }
        }
        .keneng {
          .ak {
            display: flex;
            .aks {
              display: block;
              width: 44px;
              height: 44px;
              border-radius: 4px;
              background: purple;
              margin-left: 16px;
              margin-top: 20px;
            }
            .ams {
              width: 23px;
              height: 11px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-left: 15px;
              margin-top: 30px;
            }
          }
          .ask {
            display: flex;
            .asks {
              width: 44px;
              height: 44px;
              background: plum;
              margin-top: 20px;
              margin-left: 16px;
            }
            .aks {
              width: 28px;
              height: 14px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              margin-top: 36px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  .line1 {
    width: 2px;
    height: 100%;
    background: rgba(244, 244, 244, 1);
  }
}
.applyc::-webkit-scrollbar {
  width: 10px;
}
.applyc::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #ddd;
}
</style>