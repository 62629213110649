/*
 * @Author       : Hugo
 * @Date         : 2020-07-13 14:23:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-07-24 17:02:02
 * @Description  : 
 * @FilePath     : \miaohang\src\router\fun_contact.js
 */ 
const fun_contact = [
  {
    path: "/fun_contact",
    name: "fun_contact",
    component: ()=> import('@/views/layout/default.vue'),
    // redirect: 'fun_contact_index',
    children: [
      {
        path: "index",
        name: 'fun_contact_index',
        components: {
          default: () => import('@/views/fun_contact/index.vue'),
          sidebar: () => import('@/components/Group/Aside/Contact.vue'),
        },
      },
      {
        // 申请记录
        path: "apply",
        name: "fun_contact_apply",
        components: {
          default: () => import('@/views/fun_contact/apply.vue'),
          sidebar: () => import('@/components/Group/Aside/Contact.vue'),
        },
      },
      {
        // 联系人详情
        path: "detail/:id",
        name: "fun_contact_detail",
        components: {
          default: () => import('@/views/fun_contact/detail.vue'),
          sidebar: () => import('@/components/Group/Aside/Contact.vue'),
        },
      },
      {
        // 工作组详情
        path: "workteam_detail/:id",
        name: "fun_contact_workteam_detail",
        components: {
          default: () => import('@/views/fun_contact/workTeamDetail.vue'),
          sidebar: () => import('@/components/Group/Aside/Contact.vue'),
        },
      },
    ]
  }
]

export default fun_contact;