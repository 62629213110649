<!--
 * @Author       : Hugo
 * @Date         : 2021-04-12 11:53:02
 * @LastEditTime : 2022-04-13 11:36:29
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/basic/SearchBox.vue
 * @Description  : 搜索
 * @^_^          : 
-->
<template>
  <div
    class="search_box"
  >
    <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
    <el-input v-model="input" placeholder="搜索" class="search"
      @keydown.enter.native="doSearch($event)"
    ></el-input>
    <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp" @click="doClear();">
  </div>
</template>
<script>
export default {
  data(){
    return{
      input: '',
    }
  },
  watch:{
    input: function(val){
      if(val == ''){
        this.doClearSearch();
      }
    }
  },
  methods: {
    chineseHandler(e){
      let status = false;
      if(e.keyCode == 229){
        status = true;
      }
      return status;
    },
    /**
     * @description: 搜索[父组件需要写]
     * @param {*}
     * @return {*}
     */
    doSearch(e){
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      if(this.$parent.doSearch){
        this.$parent.doSearch(this.input);
      }
    },
    doClear(){
      this.input = '';
    },
    /**
     * @description: 清除搜索
     * @param {*}
     * @return {*}
     */
    doClearSearch(){
      // this.input = '';
      if(this.$parent.doClearSearch){
        this.$parent.doClearSearch();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search_box{
  @include flexsb;
  @include bbox;
  padding: {
    left: 30px;
    right: 20px;
  }
  margin:0 auto;
  width:100%;
  height:40px;
  background:rgba(243,243,243,1);
  // border-radius:20px;
  border-radius: 4px;
  .search_icon{}
  .search{
    border:none;
    background-color: transparent;
    .el-input__inner{
      border:none;
      background-color: transparent;
    }
  }
  .clear_icon{}
}
</style>