var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "750px", height: "auto", border_radius: "10px" } },
    [
      _vm.pOrder
        ? _c(
            "div",
            {
              staticClass:
                "components_pop_up_become_member_index pb-20 d-flex flex-column",
            },
            [
              _c(
                "common-title",
                {
                  staticClass: "flex-shrink-0",
                  attrs: { border_radius: "10px" },
                },
                [
                  _vm._t("default", function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom_title_group d-flex align-center justify-space-between bbox",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "title_group d-flex align-center" },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.title
                                      ? _vm.title
                                      : `亲爱的${_vm.user.userName}，您正在${
                                          _vm.renew ? "续费" : "申请成为"
                                        }VIP用户`
                                  )
                                ),
                              ]),
                              _c("img", {
                                staticClass: "vip",
                                attrs: {
                                  src: require("@/assets/images/common/vip.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("div", {
                            staticClass: "close cp",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.$emit("handleClose")
                              },
                            },
                          }),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "main bbox" }, [
                _c("div", { staticClass: "list_group bbox" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("成为VIP用户享受以下功能："),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "entitlemen_table d-flex align-center flex-wrap un_sel",
                    },
                    [
                      _vm._l(_vm.entitlemen_table, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item d-flex align-center",
                            },
                            [
                              _c("img", {
                                staticClass: "img mr-10",
                                attrs: {
                                  src: require(`@/assets/images/personal_center/vip/vip${item.pic}.png`),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "pay_group bbox d-flex mt-10" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "img_wrapper d-flex align-center justify-center",
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: `${_vm.domain}/code/twoCode?width=120&text=${_vm.pOrder.codeUrl}`,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "info_group" }, [
                    _c("div", { staticClass: "price_group" }, [
                      _c("span", { staticClass: "text" }, [_vm._v("价格：")]),
                      _c("span", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.pOrder.convertToYuan())),
                      ]),
                      _c("span", { staticClass: "symbol" }, [_vm._v("元/年")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "hint_group d-flex align-center" },
                      [
                        _c("div", { staticClass: "icon wechat mr-10" }),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "扫码支付" +
                              _vm._s(_vm.pOrder.convertToYuan()) +
                              "元"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
              0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "main d-flex align-start justify-space-between bbox",
                    },
                    [
                      _c("div", { staticClass: "hint_group" }, [
                        _c("div", { staticClass: "title pb-20" }, [
                          _vm._v("成为VIP用户后，您将享受以下功能："),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("主动创建工作组"),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("邀请新成员加入工作组"),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("查看工作组中非联系人成员的个人资料"),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("任务单的颜色标记功能"),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("隐私模式发送任务单"),
                        ]),
                        _c("div", { staticClass: "item" }, [
                          _vm._v("自定义分类管理任务单"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "more",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.switch_member_rights = true
                              },
                            },
                          },
                          [_vm._v("更多权益…")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "payment_group d-flex flex-column align-center bbox",
                        },
                        [
                          _c("div", { staticClass: "number_group" }, [
                            _c("div", { staticClass: "box" }, [
                              _c("span", { staticClass: "symbol" }, [
                                _vm._v("¥"),
                              ]),
                              _c("span", { staticClass: "price" }, [
                                _vm._v(_vm._s(_vm.pOrder.convertToYuan())),
                              ]),
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("/年"),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "img_group d-flex flex-column align-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "img_wrapper d-flex align-center justify-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "img",
                                    attrs: {
                                      src: `${_vm.domain}/code/twoCode?width=150&text=${_vm.pOrder.codeUrl}`,
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "info_group pt-10 d-flex align-center",
                                },
                                [
                                  _c("div", {
                                    staticClass: "icon wechat mr-10",
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(
                                      "扫码支付" +
                                        _vm._s(_vm.pOrder.convertToYuan()) +
                                        "元"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.switch_member_rights
        ? _c("member-rights", {
            on: {
              handleClose: function ($event) {
                return _vm.closeMemberRights()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }