import vue from '@/main.js';
class TeachFun{
  async uploadTeachFile(params){
    const {all, taskId} = params;
    const {teach_files, task_config} = vue.$store.state;
    const {create_draft, call_teach} = task_config;
    const {fileList,linkList,videoList} = teach_files;
    // console.log({fileList,linkList,videoList});
    let item = fileList.find(unit=>{
      if(all || create_draft || call_teach){
        // 创建草稿 全部上传 调用教学[草稿创建]
        return !unit.done && !unit.delete;
      }else{
        return !unit.id && !unit.done && !unit.delete;
      }
    })
    if (item == undefined) {
      item = videoList.find(unit=>{
        if(all || create_draft || call_teach){
          // 创建草稿 全部上传 调用教学[草稿创建]
          return !unit.done && !unit.delete;
        }else{
          return !unit.id && !unit.done && !unit.delete;
        }
      })
    }
    if(item == undefined){
      item = linkList.find(unit=>{
        if(all || create_draft || call_teach){
          // 创建草稿 全部上传 调用教学[草稿创建]
          return !unit.done && !unit.delete;
        }else{
          // (没有id || 修改 )&& 未完成
          return (!unit.id || unit.modified) && !unit.done && !unit.delete;
        }
      })
    }

    if(item !== undefined ){
      let fileId;
      if(item.type == 1 || item.type == 2){
        fileId = await this.addFile(item, taskId);
      }
      let addTeachFile = await this.addTeachFile(item,taskId, fileId);
      if(addTeachFile){
        vue.$set(item, 'done', true);
      }
      return this.uploadTeachFile(params);
    }else{
      return true;
    }

  }

  addFile = async (item, taskId)=>{
    const file = item.file;
    let addFileData = {
      id: 0, // 控件id
      filename: file.filename,
      chatId: file.chatId,
      taskId:  taskId  ,
      filetype: file.filetype,
      filesize: file.filesize,
      width: '',
      height: '',
      teach: 1, // 教学附件
      url: file.url,
    }
    let addFileRes = await vue.$http.request('/projectTask/addFile', addFileData);
    return addFileRes.contents.fileId;
  }

  addTeachFile = async (item, taskId, fileId) => {
    const {type, modified, content} = item;

    let data = {
      taskId,
      type,
      content: fileId || content,
    }
    if(type == 3 && modified){
      await vue.$http.request('/projectTeach/deleteTeachFile', {ids: item.id})
    }
    await vue.$http.request('/projectTeach/addTeachFile', data);
    return true;
  }

  deleteTeachFile = async (params) => {
    const {taskId} = params || {};
    const {teach_files, task_config} = vue.$store.state;
    const {create_draft, call_teach} = task_config;
    const {fileList,linkList,videoList} = teach_files;
    let ids = '';
    videoList.forEach(unit=>{
      if(unit.delete){
        if(!( create_draft || call_teach)){
          if (unit.id) {
            ids += ids ? `,${unit.id}` : unit.id
          }
        }
      }
    })
    fileList.forEach(unit=>{
      if(unit.delete){
        if(!( create_draft || call_teach)){
          if (unit.id) {
            ids += ids ? `,${unit.id}` : unit.id
          }
        }
      }
    })
    linkList.forEach(unit=>{
      if(unit.delete){
        if(!( create_draft || call_teach)){
          if (unit.id) {
            ids += ids ? `,${unit.id}` : unit.id
          }
        }
      }
    })
    // console.log(ids);
    if(ids && ids !== 'undefined' && ids !== 'null' && ids != ''){
      await vue.$global.doPost({
        url:'/projectTeach/deleteTeachFile',
        data: {ids}
      });
    }



  }
}

const teachFun = new TeachFun();

export default teachFun;
