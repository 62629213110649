/*
 * @Author      : Hugo
 * @Date        : 2022-03-18 15: 31: 11
 * @LastEditTime : 2022-03-18 15:33:50
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PTag/index.js
 * @Description : 
 * @^_^         : 
 */
class PTag{
  id         = undefined
  userId     = undefined
  tagName    = undefined
  createTime = undefined
  updateTime = undefined
  constructor(params={}){
    const {
      id,
      userId,
      tagName,
      createTime,
      updateTime,
    } = params;
    this.id         = id;
    this.userId     = userId;
    this.tagName    = tagName;
    this.createTime = createTime;
    this.updateTime = updateTime;
  }
}

export default PTag