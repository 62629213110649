import { listConvertToModel } from '@/models'
import CourseSection from '@/models/cource_package/course_section'
import Userinfo from '@/types/modules/Userinfo'
import request from '@/api/request'
import post from '@/utils/axios/post'
import File from '@/models/resource/file'
class ResourceFile {
  browseCount
  collectCount
  content
  createTime
  file
  id
  likeCount
  ownedEnterprises
  taskId
  type
  userName

  fileType
  typeText
  fileName
  linkUrl


  constructor (params) {
    this.updateData(params)
  }

  generateFileType () {
    const {
      type,
      file,
    } = this

    let fileType;
    let textType
    if (type === 3) {
      fileType = 'link'
      textType = '网页链接'
    } else {
      if (file) {
        const { filename } = file

        // 设置图表类型
        const fileName = filename;
        const fileExtension = fileName.split('.').pop().toLowerCase();

        switch (fileExtension) {
          case 'zip':
          case 'rar':
            fileType = 'zip';
            textType = '压缩文件';
            break;
          case 'xls':
          case 'xlsx':
            fileType = 'excel';
            textType = 'Excel文件';
            break;
          case 'mp4':
          case 'avi':
          case 'mov':
          case 'wmv':
          case 'flv':
          case 'mkv':
            fileType = 'video'; // 这里只是列举了常见视频后缀，可按需扩充更多
            textType = '视频';
            break;
          case 'pdf':
            fileType = 'pdf';
            textType = 'PDF文件';
            break;
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
          case 'bmp':
            fileType = 'pic'; // 同样列举常见图片后缀，可完善
            textType = '图片';
            break;
          case 'ppt':
          case 'pptx':
            fileType = 'ppt';
            textType = 'PPT文件';
            break;
          case 'txt':
            fileType = 'txt';
            textType = '文本';
            break;
          case 'doc':
          case 'docx':
            fileType = 'word';
            textType = 'WORD文件';
            break;
          case 'glb':
            fileType = 'vr';
            textType = 'VR文件';
            break;
          case'mp3':
          case 'wav':
          case 'aac':
          case 'flac':
            fileType = 'audio'; // 常见音频后缀示例，可补充更多
            textType = '音频文件';
            break;
          case 'task':
            fileType = 'task';
            textType = '任务';
            break;
          case 'link':
            fileType = 'link';
            textType = '网页链接';
            break;
          default:
            fileType = 'unknown';
            textType = '未知类型';
        }
      }

    }


    console.log(textType)
    this.fileType = fileType
    this.typeText = textType
  }

  generateFileName () {
    const {
      file,
      content,
      type
    } = this
    if (file) {
      this.fileName = file.filename
    }
    if (type === 3 && content) {
      const arr = content.split('|')
      this.fileName =  arr[0] || arr[1]
      this.linkUrl = arr[1]
    }
  }

  async getData () {
    const res = await request.request(
      '/resource/queryCource',
      {
        courceId: this.id
      }
    )
    if (res.message === 'success') {
      this.updateData(res.contents.PCource)
      return true
    }
    return false
  }

  updateData (params) {
    const {
      browseCount,
      collectCount,
      content,
      createTime,
      file,
      id,
      likeCount,
      ownedEnterprises,
      taskId,
      type,
      userName,
    } = params ?? {}

    this.browseCount = browseCount
    this.collectCount = collectCount
    this.content = content
    this.createTime = createTime
    // this.file = file
    this.id = id
    this.likeCount = likeCount
    this.ownedEnterprises = ownedEnterprises
    this.taskId = taskId
    this.type = type
    this.userName = userName
    if (file) {
      this.file = new File(file)
    }
    this.generateFileType()
    this.generateFileName()
  }


  async doLike () {
    const res = await request.request(
      '/resource/like',
      {
        id: this.id,
        type: 2,
      }
    )
    if (res.message === 'success') {
      this.likeCount = this.likeCount ? this.likeCount + 1 : 1
    }
    return res
  }

}

export default ResourceFile
