var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog_file_message_picture_message bbox" },
    [
      _c(
        "el-image",
        {
          staticClass: "picture_message",
          style: {
            width: `${_vm.clacSize.width}px`,
            height: `${_vm.clacSize.height}px`,
          },
          attrs: {
            src: `${_vm.$file_url}${_vm.msgData.work}`,
            lazy: true,
            "preview-src-list": [`${_vm.$file_url}${_vm.msgData.work}`],
            fit: "cover",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "img_placeholder d-flex justify-center align-center",
              attrs: { slot: "placeholder" },
              slot: "placeholder",
            },
            [
              _vm._v("\n      加载中"),
              _c("span", { staticClass: "dot" }, [_vm._v("...")]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }