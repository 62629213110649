/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-01 15:55:53
 * @LastEditors  : hugo
 * @LastEditTime : 2022-08-30 11:50:44
 * @FilePath     : /miaohang/src/utils/tools.js
 * @Description  :
 */
class Tools{
  /**
   * 获取拓展名
   * @param {*} name
   * @returns
   */
  getExtName(name){
    console.log(name)
    let extReg = /.+[\/\.]/;
    let extname = name.replace(extReg, '');
    extname = extname.toLowerCase();
    return extname;
  }
  /**
   * 生成随机字母
   * @param {Object} params {type: 'lowercase/uppercase', num,}
   */
  generateRandomLetter(params){
    let {type, num} = params || {};
    if(!type){
      type = 'lowercase';
    }
    if(!num){
      num = 4;
    }
    var result = [];
    const ASCII_CODE = type === 'lowercase' ? 97 : 65;
    // 97 小写字母: 65 大写字母
    for(var i=0;i<num;i++){
      //生成一个0到25的数字
      var ranNum = Math.ceil(Math.random() * 25);
      //大写字母'A'的ASCII是65,A~Z的ASCII码就是65 + 0~25;
      //然后调用String.fromCharCode()传入ASCII值返回相应的字符并push进数组里
      result.push(String.fromCharCode(ASCII_CODE+ranNum));
    }
    return result.join('');
  }
}
const tools = new Tools();
export default tools;
