import vue from '@/main.js';
class Users {
  /**
   * 获取优先展示用户名
   * @param {userId, name} params:{userId: number, name: 群用户名 | 用户名} 
   * @returns string
   */
  getPriorityName = ({userId, name}) => {
    const {friends_list} = vue.$store.state;
    if(!friends_list || friends_list.length === 0){
      return name;
    }
    const aim = friends_list.find( item => {
      return item.friendUserId === Number(userId);
    })
    if(aim !== undefined){
      const {updateremark, friendRemark, userName} = aim;
      if(updateremark === 1){
        return friendRemark || name || userName;
      }
      return name || userName;
    }
    return name;
  }
}

const users = new Users();
export default users;