var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_popup_record_of_communication_file_file_page bbox d-flex align-stretch",
    },
    [
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "file_group p-10 d-flex align-start" }, [
          _c("div", {
            staticClass: "icon mr-10",
            class: [_vm.item.getExtName()],
          }),
          _c("div", { staticClass: "info" }, [
            _c(
              "div",
              {
                staticClass: "name pb-4 cp",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.item.highlightKeywords({ keyword: _vm.keyword })
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.item.previewFile({ type: 2 })
                  },
                },
              },
              [
                _vm._v(
                  "Apple公司年会要求-三亚_活动报批文件.xlsx,Apple公司年会要求-三亚_活动报批文件.xlsx,Apple公司年会要求-三亚_活动报批文件.xlsx"
                ),
              ]
            ),
            _c("div", { staticClass: "detail_group d-flex align-center" }, [
              _c(
                "div",
                {
                  staticClass: "size pr-10",
                  domProps: { textContent: _vm._s(_vm.item.formatFilesize()) },
                },
                [_vm._v("200K")]
              ),
              _c(
                "div",
                {
                  staticClass: "time",
                  domProps: {
                    textContent: _vm._s(
                      _vm
                        .$moment(_vm.item.createTime)
                        .format("YYYY/MM/DD HH:mm")
                    ),
                  },
                },
                [_vm._v("2021/06/30 16:57")]
              ),
            ]),
          ]),
          _c("div", {
            staticClass: "download flex-shrink-0 ml-10 mt-2 cp",
            on: {
              click: function ($event) {
                return _vm.item.download({ type: 2 })
              },
            },
          }),
        ]),
        _c("div", { staticClass: "task_group mt-10 cp" }, [
          _c(
            "div",
            {
              staticClass: "task_name",
              domProps: { textContent: _vm._s(`任务: ${_vm.item.taskname}`) },
              on: {
                click: function ($event) {
                  return _vm.item.openTask({ config: { edit: true } })
                },
              },
            },
            [_vm._v("任务：Apple公司年会 / 控件：参考文件")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "fun_group ml-10 flex-shrink-0 d-flex flex-column justify-space-between align-start bbox",
        },
        [
          _c("div", { staticClass: "upload_user" }),
          _c(
            "div",
            {
              staticClass: "check cp",
              on: {
                click: function ($event) {
                  return _vm.item.openTask({ config: { edit: true } })
                },
              },
            },
            [_vm._v("查看任务")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }