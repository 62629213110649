var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            {
              attrs: {
                height: "auto",
                width: "auto",
                max_width: "90%",
                max_height: "90%",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "v_ap_gift_receiving_card_plugins_receiving_index d-flex flex-column",
                },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0 title_common",
                    attrs: {
                      title: _vm.title,
                      close: true,
                      "border-radius": "10px",
                      background_color: "#f8f8f8",
                    },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _c("plugin-component", {
                    staticClass: "plugin_component bbox",
                    attrs: {
                      data: _vm.data,
                      backup: _vm.backup,
                      mobile: _vm.mobile,
                    },
                    on: {
                      handleClose: _vm.handleClose,
                      handleSure: _vm.handleSure,
                      handleReturn: _vm.handleReturn,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }