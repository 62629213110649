var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "service_agreement_page" }, [
      _c("div", [_vm._v("尊敬的用户：")]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "为了让您更好地体验和使用「妙行」系统（以下简称「本系统」）提供的功能和服务，请您务必审慎阅读、充分理解「妙行用户服务协议」（以下简称「本协议」）各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。您的注册、登录、使用等行为将被认为您已阅读并承诺同意遵守本协议所有条款内容的约束，且不应以未阅读本协议的内容或者未获得妙行对用户问询的解答等理由，主张本协议无效，或要求撤销本协议。"
        ),
      ]),
      _c("h4", [_vm._v("一、协议定义")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "「妙行」，全称为「妙行项目管理沟通协作系统」（英文全称：Project Management Communication System，英文缩写：PMCS），属于多平台的在线即时通讯类型的项目管理沟通协作系统，由北京优联信驰信息科技有限公司（以下简称「优联科技」或「UTEC」）研发和运营，优联科技对本系统具有完整的所有权和解释权。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "本协议是用户(包括个人、企业及其他组织)与优联科技之间的法律契约。用户访问、浏览和注册本系统即代表接受本合约条款的约束，用户对本系统的访问和使用应以接受并遵守本协议所载明的条款和条件为前提。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("二、知识产权申明")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "本系统（包括但不限于形态、应用、程序、源代码、设计、界面、商标、标示图案、LOGO、应用程序编程接口(API)、文字、图片、音频、视频、图表等）关联的所有知识产权均属优联科技所有，均受中华人民共和国法律法规和相应的国际条约保护，用户不得复制、修改、传播或在非本网站所属的服务器上做镜像或者以其它方式进行非法使用。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "用户在本网站平台所创建的独创性数据归属该用户所有，该用户有权对前述数据进行任何形式的处置，包括从平台中复制，导出和删除。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "用户应当确保其公开或存储（方式包括但不限于：上传、保存、公布、发布等）于本网站及相关服务器的内容不存在任何侵犯其他第三方知识产权的情形。若存在本条所述情形，优联科技有权根据具体情节针对上述内容，采取包括但不限于限制、屏蔽、删除、修改等手段，由此给用户造成的损失由用户自行承担；若优联科技因用户存在本条所述行为而向其他第三方承担法律责任的，优联科技有权向用户追偿全部损失。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("三、系统使用规则")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [_vm._v("用户在使用本系统服务过程中，必须遵循以下规则：")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t遵守中国所有相关的法律法规；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t不得对本系统服务进行任何形式的对其他第三方的再授权使用、销售或转让；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t不得抄袭模仿本系统的设计；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t不得滥用本系统的通信功能发送垃圾邮件、短信或一切违反国家法律法规的内容信息等；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t不得做损害本系统服务和商誉行为，包括对系统服务器的攻击、发表不当言论等；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t遵守本协议中的全部约定。")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "如用户在使用本系统服务时违反任何上述规定，优联科技有权要求用户改正或直接采取一切必要的措施（包括但不限于暂停或终止用户使用本系统服务的权利并追讨因此带来的损失）以减轻用户不当行为造成的影响。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "用户必须对在本系统注册的信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；否则优联科技有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("4.")]),
        _c("div", [
          _vm._v(
            "用户直接或间接使用本系统服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请立即停止使用本系统所提供的全部服务。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("5.")]),
        _c("div", [
          _vm._v(
            "用户承诺不得以任何方式利用本系统直接或间接从事违反中国法律、以及社会公德的行为，优联科技有权对违反上述承诺的内容予以删除。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("6.")]),
        _c("div", [
          _vm._v(
            "用户不得利用本系统服务制作、上载、复制、发布、传播或者转载如下内容："
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t有损国家声誉和利益，危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的； "
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t煽动民族仇恨、破坏民族团结、挑动种族歧视的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t违反中国宗教政策，宣扬邪教和封建迷信内容的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t散布谣言，扰乱社会秩序，破坏社会稳定的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t散布色情、淫秽、暴力、赌博、凶杀、恐怖内容或者教唆犯罪的；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t侮辱或诽谤他人，侵害他人合法权益的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t所有中国法律、行政法规禁止的其他内容的信息。")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("7.")]),
        _c("div", [
          _vm._v(
            "优联科技有权对用户使用本系统的情况进行审查和监督，如用户在使用本系统时违反任何上述规定，优联科技或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容、暂停或终止用户使用本系统的权利）以减轻用户不当行为造成的影响。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("8.")]),
        _c("div", [
          _vm._v(
            "优联科技有权但无义务对用户在本系统发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》及相关法律法规等对侵权信息进行处理。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("9.")]),
        _c("div", [
          _vm._v(
            "本系统与会E人平台（优联科技旗下产品）共享用户数据，用户在本系统注册成功时，即同时成为会E人平台注册用户，用户在使用会E人平台提供的服务时，可随时查看会E人平台的相关服务协议，如果用户继续使用，则默认用户同意遵守会E人平台相关的服务协议等规则。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("四、用户信息保护")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "优联科技将尽可能采取一切措施保护用户使用本系统时的数据及个人信息资料安全，只根据用户在系统上的行为指示来分发用户的信息，不会向任何第三方公开或共享用户的具体数据，除非有下列情况："
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t有关法律、法规规定；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t在紧急情况下，为维护用户及公众的权益；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t为维护本系统的商标权、专利权及其他任何合法权益；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t其他依据中国法律需要公开、编辑或透露个人信息的情况。"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "本系统保留使用汇总统计性信息的权利，在不透露单个用户隐私资料的前提下，本系统有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v("更多关于用户信息保护的内容，请参见《妙行用户隐私协议》。"),
        ]),
      ]),
      _c("h4", [_vm._v("五、免责声明")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "优联科技将尽最大努力保障本系统的连续可靠运行，但鉴于网络服务的特殊性，本系统对可能发生的网络服务的中断或终止所可能导致的一切损失，不承担任何法律责任及经济赔偿。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "本系统将尽最大努力保障用户数据的安全备份，但无法承诺100%的数据恢复，对因数据丢失带来的任何直接或间接损失不承担任何责任。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "在发生需要从备份文件中恢复数据的情形时，本系统通常需要24小时（法定工作时间），最长72小时完成（法定工作时间），在此时间范围内的数据恢复视作服务是连续的。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("4.")]),
        _c("div", [
          _vm._v(
            "对于因不可抗力或本系统不能控制的原因造成的网络服务中断和长时间终止，本系统不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("5.")]),
        _c("div", [
          _vm._v(
            "本系统将尽可能采取一切措施保护用户数据及个人信息资料安全。但用户通过使用或安装第三方应用打开本系统中的文件，该文件会在第三方应用中打开，由于第三方应用而造成的损失，本系统不承担任何法律责任及经济赔偿。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("6.")]),
        _c("div", [
          _vm._v(
            "鉴于互联网体制及环境的特殊性，用户在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途，本系统对此不承担任何责任。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("7.")]),
        _c("div", [
          _vm._v(
            "用户在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险本系统对此不承担任何责任。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("8.")]),
        _c("div", [
          _vm._v(
            "与特定网络安全事故相关免责事由。例如，在法律允许的范围内，本系统对于因受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏而导致的服务中断及信息泄露不承担责任。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("9.")]),
        _c("div", [
          _vm._v(
            "本系统并非为某些特定目的而设计，包括但不限于核设施、军事用途、 医疗设施、交通通讯等重要领域。 如果因为本系统或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，我们不承担法律责任"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("六、协议变更")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "优联科技有权随时对本协议的条款进行修订，一旦本协议的内容发生变更，本系统将会在修订生效日前一个工作日更新，更新条款将即时生效。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "用户可以随时在本系统相关页面查阅最新版本的协议条款，当协议条款被更新后，如果用户继续使用本系统提供的服务，即视为用户已接受修改后的协议条款。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("七、违约责任")]),
      _c("div", [
        _c("div", [
          _vm._v(
            "由于用户通过本系统服务上载、传送或分享之信息、使用本系统服务其他功能、违反本合约、或侵害他人任何权利因而衍生或导致任何第三人向本网站及其关联公司提出任何索赔或请求，或本网站及其关联公司因此而发生任何损失，用户同意将足额进行赔偿（包括但不限于合理的律师费）。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("八、法律适用")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            " 本协议的订立、执行和解释以及争议的解决均应适用中华人民共和国法律并受中国法院管辖。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "协议双方就本合约内容或其执行发生任何争议，应首先力争友好协商解决；协商不成时，任何一方均可向优联科技注册地所在的人民法院提起诉讼。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("九、联系方式")]),
      _c("div", [
        _c("div", [
          _vm._v(
            "任何涉及与本系统服务、协议等相关的问题，可通过邮件与我们联系：Contact@ourutec.com"
          ),
        ]),
      ]),
      _c("div", { staticClass: "comname" }, [
        _vm._v("北京优联信驰信息科技有限公司"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }