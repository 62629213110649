import store from '@/store'
import router from '@/router'
import { PTaskLog, PMessageBox } from '@/types'
import vue from '@/main'
import global from '@/api/global'
import tools from '@/api/tools'
import utilUsers from '@/api/utils/users'
import im from '@/utils/WebIM/im'

class PushTask {
  receive = (params) => {
    console.log(params)
    if (!params) {
      return
    }
    const { ext } = params ?? {}
    if (!ext) {
      return
    }
    if (!ext.pTaskLog) {
      return
    }
    console.log(ext.pTaskLog,(JSON.parse(ext.pTaskLog)))
    const pTaskLog = new PTaskLog(JSON.parse(ext.pTaskLog))
    // const { pTaskLog } = ext ?? {}
    // console.log(JSON.parse(pTaskLog))
    console.log({ pTaskLog })
    this.pushTaskLog({ ext, pTaskLog })
    this.pushMessageBoxList({ ext, pTaskLog })
    this.updateMyTaskList({ ext, pTaskLog })
    // this.updateMessageListStatus({ ext, pTaskLog })
  }

  pushTaskLog = (params = {}) => {
    const { pTaskLog, ext } = params ?? {}
    const { currentRoute } = router
    const { name:currentRouteName } = currentRoute
    // 在列表更新之前获取聊天是否在底部 如果不在底部；则unreadNumber+1,并且不触发滚动到底部
    const isBottom = store.state.dialogData.isBottom
    this.pushPageMessageList( { ext, pTaskLog })
    if (isBottom) {
      // 滚动到底部
      vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
    } else {
      store.state.dialogData.unreadNumber += 1
    }
    // 增加最左侧红点
    if (currentRouteName.indexOf('fun_task') === -1) {
      // 任务类提示
      store.commit(
        'set_new_task',
        true
      )
    }
  }

  /**
   * 推送到页面消息列表
   * @param params
   */
  pushPageMessageList = (params) => {
    const { pTaskLog, ext } = params
    const { currentRoute } = router
    const { name:currentRouteName } = currentRoute
    if (currentRouteName.indexOf('fun_task_dialog') === -1) {
      return
    }
    const { chatId, chatType } = ext
    const isSingle = currentRouteName === 'fun_task_dialog_single' && Number(chatType) === 0
    const isGroup = currentRouteName === 'fun_task_dialog_group' && Number(chatType) === 1
    const { id } = currentRoute.params
    const currentId = global.decodeBase64(id)
    const isCurrentChat = currentId && (Number(currentId) === Number(chatId))
    // console.log({ currentRouteName, chatType, chatId, currentId })
    // console.log({isSingle, isGroup, isCurrentChat})

    // 当前聊天页面
    if ((isSingle || isGroup) && isCurrentChat) {
      // 追加
      const message_list = store.state.message_list
      message_list.push(pTaskLog)
    }
  }

  pushMessageBoxList = async (params = {}) => {
    const { ext, pTaskLog } = params ?? {}
    if (!ext) { return }
    if (!pTaskLog) { return }
    const { chatId, chatType } = ext
    const {
      // tousers,
      chatName,
      lastSendTime,
      messageContent,
      updateUserId,
      updateusername,
      updateuserthumbnail,
      photo,
      userName
    } = ext
    let message_box_list = store.state.message_box_list
    const workteam_list = store.state.workteam_list
    if (!message_box_list || !message_box_list.length) {
      const res = await global.doPost({
        url: '/projectTask/messageBoxList',
        openNotice: false
      })
      if (res.message === 'success') {
        if(res.contents && res.contents.messageBox){
          message_box_list = res.contents.messageBox || []
        }else{
          message_box_list = []
        }
      }else{
        message_box_list = []
      }
    }
    const index = message_box_list.findIndex((item) => {
      return (Number(item.chatId) === Number(chatId)) && (Number(item.chatType) === Number(chatType))
    })
    // const loginUserId = tools.getUserId()
    // 有人@我
    // const isLinkMe = Boolean(tousers && `,${tousers},`.indexOf(`,${loginUserId},`) !== -1)
    if (index !== -1) {
      message_box_list[index].name = chatName ?? message_box_list[index].name
      message_box_list[index].lastSendTime = Number(lastSendTime)
      message_box_list[index].messageContent = messageContent
      message_box_list[index].updateusername = utilUsers.getPriorityName({
        userId: updateUserId,
        name: updateusername
      })
      message_box_list[index].photo = Number(chatType) === 1 ? photo : updateuserthumbnail
      const currentChatType = tools.getChatType()
      const { currentRoute } = router
      const { id } = currentRoute.params
      const currentChatId = global.decodeBase64(id)
      if (!( (Number(currentChatType) === Number(chatType)) && currentChatId && (Number(currentChatId) === Number(chatId))) ) {
        // 如果不在当前窗口 添加红点提示
        message_box_list[index].unreadMessageNum = message_box_list[index].unreadMessageNum ? message_box_list[index].unreadMessageNum + 1 : 1
      } else {
        // 在当前窗口 发送已读透传
        im.sendMySelf()
      }
      // 移到顶部
      const pMessageBox = message_box_list[index]
      message_box_list.splice(index, 1)
      // 置顶消息处理
      if (pMessageBox.istop) {
        message_box_list.unshift(pMessageBox)
      } else {
        // 非置顶消息
        const notTopIndex = message_box_list.findIndex((item) => {
          return !item.istop
        })
        if(notTopIndex !== -1){
          message_box_list.splice(
            notTopIndex,
            0,
            pMessageBox
          )
        }else{
          message_box_list.unshift(pMessageBox)
        }
      }
    } else {
      const pMessageBox = new PMessageBox({
        chatId,
        chatType,
        createTime: Number(lastSendTime),
        messageContent,
        photo: Number(chatType) === 1 ? photo : updateuserthumbnail,
        unreadMessageNum: 1,
        updateusername: updateusername || userName,
        name: chatName,
        // islinkme: isLinkMe
      })
      // 非置顶消息
      let notTopIndex = message_box_list.findIndex(item => {
        return !item.istop;
      })
      if(notTopIndex !== -1){
        message_box_list.splice(
          notTopIndex,
          0,
          pMessageBox
        )
      }else{
        message_box_list.unshift(pMessageBox)
      }
    }
    // 如果不再workteam_list中 则重新加载 workteam_list
    const workTeamListIndex = workteam_list.findIndex((item) => {
      return (Number(item.chatId) === Number(chatId)) && (Number(item.chatType) === Number(chatType))
    })
    if (workTeamListIndex === -1) {
      store.dispatch('get_workteam_list') // 刷新工作组列表
    }
    store.commit(
      'set_message_box_list',
      message_box_list
    )
  }

  updateMyTaskList = (params) => {
    const { ext, pTaskLog } = params
    const { currentRoute } = router
    const { name:currentRouteName } = currentRoute
    if (currentRouteName.indexOf('fun_task_dialog') === -1) {
      return
    }
    const { chatId, chatType } = ext
    const { id } = currentRoute.params
    const currentId = global.decodeBase64(id)
    const isCurrentChat = currentId && (Number(currentId) === Number(chatId))

    /**
     * START 不是当前窗口不追加
     */
    if (!isCurrentChat) {
      return
    }
    if (currentRouteName === 'fun_task_dialog_single' && Number(chatType) === 1) {
      return
    }
    if (currentRouteName === 'fun_task_dialog_group' && Number(chatType) === 0) {
      return
    }
    /**
     * END 不是当前窗口不追加
     */

    const {
      taskId,
      taskName,
      createTime,
      taskType,
      hide,
      colour,
      isremind,
      levelStatus,
      processStatus
    } = pTaskLog
    const {
      createuserthumbnail,
      createusername,
    } = JSON.parse(ext.pTaskLog)
    const { my_task_list } = store.state
    const index = my_task_list.findIndex((item) => {
      return item.id === Number(taskId)
    })
    if (index !== -1) {
      my_task_list.splice(index, 1)
    }
    const task = {
      id: taskId,
      name: taskName,
      createTime,
      taskType,
      chatId,
      createuserthumbnail,
      createusername,
      hide,
      colour,
      isremind,
      levelStatus,
      processStatus
    }
    console.log(task)
    my_task_list.unshift(task)
    // const {} = ext
    // const  unit = {
    //   unit = {
    //     id: taskId,
    //     name: taskName,
    //     updateTime: createTime,
    //     taskType,
    //     chatId,
    //     createuserthumbnail: createuserthumbnail || vue.$tools.getCookie('user').thumbnail,
    //     createusername: createusername || '我',
    //     hide,
    //     colour,
    //     isremind,
    //     levelStatus,
    //     processStatus
    //   }
  }

  /**
   * 更新消息列表的状态信息
   * @param params
   */
  updateMessageListStatus = (params) => {
    const { pTaskLog } = params
    const {
      levelStatus,
      processStatus,
      taskId
    } = pTaskLog
    const { message_list } = store.state
    message_list.forEach((unit) => {

      if (unit.taskId === taskId) {
        unit.levelStatus = levelStatus
        unit.processStatus = processStatus
      }
    })
  }
}

const pushTask = new PushTask()

export default pushTask
