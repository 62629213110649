import vue from '@/main.js';

class MessageList {
  scrollToLatest = () => {
    setTimeout(()=>{
      vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
    },200)
  }
}

const messageList = new MessageList();

export default messageList;