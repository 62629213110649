var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_pop_up_tag_set_general_tag_index" }, [
    _c("div", { staticClass: "content_group" }, [
      _c("div", { staticClass: "fixed_display" }, [
        _c("div", { staticClass: "search_group" }, [
          _c(
            "div",
            {
              staticClass: "search_box",
              class: { on: _vm.input_on || _vm.input_list.length != 0 },
            },
            [
              _vm._l(_vm.input_list, function (item, index) {
                return _c(
                  "div",
                  { key: `input_list${index}`, staticClass: "search_tag" },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.tagName) + "\n          "
                    ),
                  ]
                )
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                ref: "input",
                staticClass: "search",
                attrs: {
                  type: "text",
                  placeholder: "请输入标签，Enter确认, 多个标签用逗号分隔...",
                },
                domProps: { value: _vm.input },
                on: {
                  keyup: _vm.inputKeyup,
                  focus: function ($event) {
                    _vm.input_on = true
                  },
                  blur: function ($event) {
                    _vm.input_on = false
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.input = $event.target.value
                  },
                },
              }),
            ],
            2
          ),
        ]),
        (_vm.template_addtag_unit_data &&
          _vm.template_addtag_unit_data.tags &&
          _vm.template_addtag_unit_data.tags.length) ||
        _vm.append_tag_list.length
          ? _c("div", { staticClass: "selected_group tag_group" }, [
              _c("div", { staticClass: "tag_title" }, [
                _vm._v("\n          当前模板标签\n        "),
              ]),
              _c(
                "div",
                { staticClass: "tag_box" },
                [
                  _vm.tag_list && _vm.tag_list.length
                    ? _vm._l(_vm.tag_list, function (item, index) {
                        return _c(
                          "div",
                          { key: `tag_list${index}`, staticClass: "tag" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.tagName) +
                                "\n              "
                            ),
                            _c("div", {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTagHandler(item)
                                },
                              },
                            }),
                          ]
                        )
                      })
                    : _vm._e(),
                  _vm.append_tag_list && _vm.append_tag_list.length
                    ? _vm._l(_vm.append_tag_list, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: `append_tag_list${index}`,
                            staticClass: "tag",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.tagName) +
                                "\n              "
                            ),
                            _c("div", {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTagHandler(item)
                                },
                              },
                            }),
                          ]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "all_group tag_group" }, [
        _c("div", { staticClass: "tag_title" }, [
          _vm._v("\n        全部标签...\n      "),
        ]),
        _c(
          "div",
          { staticClass: "tag_box" },
          _vm._l(_vm.all_tag_list, function (item, index) {
            return _c(
              "div",
              {
                key: `all_tag_list${index}`,
                staticClass: "tag cp",
                class: { on: item.select },
                on: {
                  click: function ($event) {
                    return _vm.linkageClickHandler(item, index)
                  },
                },
              },
              [_vm._v("\n          " + _vm._s(item.tagName) + "\n        ")]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "fun cp sure", on: { click: _vm.addTags } }, [
        _vm._v("\n      确定\n    "),
      ]),
      _c(
        "div",
        {
          staticClass: "fun cp cancel",
          on: {
            click: function ($event) {
              return _vm.$emit("handleClose")
            },
          },
        },
        [_vm._v("\n      取消\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }