var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("plugin-component", {
            attrs: {
              title: _vm.title,
              chat_type: _vm.chat_type,
              chat_id: _vm.chat_id,
              task_id: _vm.task_id,
            },
            on: {
              handleClose: _vm.doClose,
              handleSure: _vm.handleSure,
              handleAll: _vm.handleAll,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }