var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            { attrs: { height: "auto", width: "90%", max_width: "1200px" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "c_plugin_address_book_prompt d-flex flex-column",
                },
                [
                  _c("plugin-component", {
                    staticClass: "plugin_component",
                    on: {
                      handleClose: _vm.handleClose,
                      handleSure: _vm.handleSure,
                      handleReturn: _vm.handleReturn,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }