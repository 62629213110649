var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_tag_status_tag_index d-flex flex-column align-stretch",
    },
    [
      _c(
        "div",
        { staticClass: "tag_list" },
        [
          _vm._l(_vm.levelList, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "tag d-flex align-center",
                  class: {
                    [item.type]: Boolean(item.type),
                    active: item.active,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectLevel(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "select_icon" }),
                  item.type
                    ? _c("div", {
                        staticClass: "type_icon",
                        class: {
                          [item.type]: Boolean(item.type),
                          active: item.active,
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "name" }, [
                    _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "tag_list" },
        [
          _vm._l(_vm.statusList, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "tag d-flex align-center",
                  class: {
                    [item.type]: Boolean(item.type),
                    active: item.active,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectStatus(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "select_icon" }),
                  item.type
                    ? _c("div", {
                        staticClass: "type_icon",
                        class: { [item.type]: Boolean(item.type) },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "name" }, [
                    _vm._v("\n          " + _vm._s(item.name) + "\n        "),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "fun_group d-flex align-center justify-end" }, [
        _c(
          "div",
          {
            staticClass: "btn_dark btn cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doSure()
              },
            },
          },
          [_vm._v("\n      确定\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "btn_light btn cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doClose()
              },
            },
          },
          [_vm._v("\n      取消\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }