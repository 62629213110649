var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c_invivation_modules_poster bbox",
      attrs: { id: "invivation_poster" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.save()
        },
      },
    },
    [
      _c("div", { staticClass: "wrapper bbox" }, [
        _vm._m(0),
        _c("img", {
          staticClass: "line",
          attrs: {
            src: require("@/assets/images/invivation_poster/line.png"),
            alt: "",
          },
        }),
        _c("img", {
          staticClass: "welcome",
          attrs: {
            src: require("@/assets/images/invivation_poster/welcome.png"),
            alt: "",
          },
        }),
        _c(
          "div",
          { staticClass: "feature_list" },
          [
            _vm._l(_vm.feature_list, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "feature d-flex align-center" },
                  [
                    _c("div", { staticClass: "symbol" }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.text)),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass:
              "qrcode_group d-flex align-stretch justify-space-between",
          },
          [
            _c("img", {
              staticClass: "qrcode flex-shrink-0",
              attrs: { src: _vm.qrcode_url, alt: "" },
            }),
            _vm._m(1),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "user_group d-flex align-center bbox" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/logo.png"), alt: "" },
      }),
      _c("div", { staticClass: "username" }, [_vm._v("用户: 刘松")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "info_group d-flex flex-column justify-space-between align-start",
      },
      [
        _c("div", { staticClass: "title_group" }, [
          _c("div", { staticClass: "text" }, [_vm._v("长按或扫描二维码")]),
          _c("div", { staticClass: "text" }, [_vm._v("注册妙行")]),
        ]),
        _c("div", { staticClass: "time_group" }, [
          _c("div", { staticClass: "title" }, [_vm._v("海报有效期至：")]),
          _c("div", { staticClass: "time" }, [_vm._v("2020-01-29 15:38")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }