/*
 * @Author       : Hugo
 * @Date         : 2022-04-11 11:22:57
 * @LastEditTime: 2022-05-30 23:54:19
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/utils/plugin/index.js
 * @Description  : 
 * @^_^          : 
 */
import Vue from 'vue'

import CollectSelectRoute from './modules/collect_select_route'
import ShareRoute from './modules/share_route'
import ShareConfirm from './modules/share_confirm'
import FolderPreview from './modules/folder_preview'
import Consulting from './modules/consulting'
import TaskGiftBag from './modules/task_gift_bag'
import AddressBookPrompt from './modules/address_book_prompt'
import InvivationPoster from './modules/invivation_poster'
import NotMember from './modules/not_member'
import CommunicationRecord from './modules/communication_record'

// CTask
import AllTaskUsers from './modules/ctask/all_task_users'
import SelectInternalContact from './modules/ctask/select_internal_contact'

// PersonalCenter

import GiftCardConfirmPurchase from './modules/personal_center/gift_card_confirm_purchase'
import GiftCardGiveAway from './modules/personal_center/gift_card_give_away'
import GiftCardSavePoster from './modules/personal_center/gift_card_save_poster'
import GiftCardReceiving from './modules/personal_center/gift_card_receiving'
import GiftCardSendOutDetails from './modules/personal_center/gift_card_send_out_details'
import GiftCardPaymentSuccess from '@/utils/plugin/modules/personal_center/giftCardPaymentSuccess'

// Aggrement 协议

import AggrementService from './modules/aggrement/service'
import AggrementPrivacy from './modules/aggrement/privacy'

// Tag 标签
import StatusTag from './modules/tag/statusTag'
import GeneralTag from './modules/tag/generalTag'

// At @
import At from './modules/at'

// TeachingPackage
import TeachingPackageEdit from './modules/teaching_package/edit'
import TeachingPackageSelectTask from './modules/teaching_package/selectTask'
import TeachingPackageSelectedTaskList from './modules/teaching_package/selectedTaskList'

// Member
import MemberOpenRecord from './modules/member/openRecord'

// BatchAddContactsToTasks 批量添加联系人
import BatchAddContactsToTasksSelectTask from './modules/batchAddContactsToTasks/selectTask'
import BatchAddContactsToTasksSelectedTaskList from './modules/batchAddContactsToTasks/selectedTaskList'

// Dialog
import DialogSendFiles from './modules/dialog/sendFiles'

// MigrateTask 任务迁移
import MigrateTaskSelectTask from './modules/migrateTask/selectTask'
import MigrateTaskSelectTargetGroup from './modules/migrateTask/selectTargetGroup'

Vue
  .use(CollectSelectRoute)
  .use(ShareRoute)
  .use(ShareConfirm)
  .use(FolderPreview)
  .use(Consulting)
  .use(TaskGiftBag)
  .use(AddressBookPrompt)
  .use(InvivationPoster)
  .use(NotMember)
  .use(CommunicationRecord)
  // CTask
  .use(AllTaskUsers)
  .use(SelectInternalContact)
  // PersonalCenter
  .use(GiftCardConfirmPurchase)
  .use(GiftCardGiveAway)
  .use(GiftCardSavePoster)
  .use(GiftCardReceiving)
  .use(GiftCardSendOutDetails)
  .use(GiftCardPaymentSuccess)
  // Aggrement
  .use(AggrementService)
  .use(AggrementPrivacy)
  // Tag
  .use(StatusTag)
  .use(GeneralTag)
  // At
  .use(At)
  // TeachingPackage
  .use(TeachingPackageEdit)
  .use(TeachingPackageSelectTask)
  .use(TeachingPackageSelectedTaskList)
  // Member
  .use(MemberOpenRecord)
  // BatchAddContactsToTasks 批量添加联系人
  .use(BatchAddContactsToTasksSelectTask)
  .use(BatchAddContactsToTasksSelectedTaskList)
  // Dialog
  .use(DialogSendFiles)
  // MigrateTask 任务迁移
  .use(MigrateTaskSelectTask)
  .use(MigrateTaskSelectTargetGroup)