var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_migrate_task_select_task_group_index bbox d-flex algin-center flex-column",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "search_wrapper d-flex justify-center flex-shrink-0 bbox",
        },
        [
          _c(
            "div",
            {
              staticClass: "search_group d-flex align-center bbox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "icon mr-4" }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                ref: "search",
                staticClass: "search bbox",
                attrs: { type: "text", placeholder: "搜索..." },
                domProps: { value: _vm.search },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.search.trim() !== "",
                    expression: "search.trim() !== ''",
                  },
                ],
                staticClass: "clear",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearSearch.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "group_wrapper" }, [
        _c(
          "div",
          { staticClass: "group_list" },
          [
            _vm._l(_vm.groupList, function (item, index) {
              return [
                Number(item.chatId) !== Number(_vm.currentChatId)
                  ? _c("group-unit", {
                      key: index,
                      attrs: {
                        "chat-data": item,
                        "page-data": _vm.pageData,
                        search: _vm.search,
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass:
            "operation_wrapper d-flex align-center justify-center bbox flex-shrink-0",
        },
        [
          _c(
            "div",
            { staticClass: "btn_group d-flex align-center justify-center" },
            [
              _c(
                "div",
                {
                  staticClass: "btn btn_light",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doClose.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        取消\n      ")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn btn_dark",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doSure.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }