import { render, staticRenderFns } from "./ButtonGroup.vue?vue&type=template&id=e34fc124&scoped=true"
import script from "./ButtonGroup.vue?vue&type=script&lang=js"
export * from "./ButtonGroup.vue?vue&type=script&lang=js"
import style0 from "./ButtonGroup.vue?vue&type=style&index=0&id=e34fc124&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e34fc124",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e34fc124')) {
      api.createRecord('e34fc124', component.options)
    } else {
      api.reload('e34fc124', component.options)
    }
    module.hot.accept("./ButtonGroup.vue?vue&type=template&id=e34fc124&scoped=true", function () {
      api.rerender('e34fc124', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ClassesTask/modules/FunBar/ButtonGroup.vue"
export default component.exports