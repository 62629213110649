var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            { attrs: { height: "60%", width: "60%", max_width: "840px" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "c_classes_task_modules_plugins_all_task_users_index d-flex flex-column",
                },
                [
                  _c(
                    "common-title",
                    {
                      staticClass: "flex-shrink-0 title_common",
                      attrs: {
                        title: _vm.title,
                        close: true,
                        "border-radius": "10px",
                        background_color: "#fff",
                      },
                      on: { handleClose: _vm.handleClose },
                    },
                    [
                      _c("template", { slot: "default" }, [
                        _c(
                          "div",
                          {
                            staticClass: "title_group bbox d-flex align-center",
                          },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("查看参与人"),
                            ]),
                            _c("div", {
                              staticClass: "close cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClose()
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("plugin-component", {
                    staticClass: "plugin_component",
                    attrs: { data: _vm.data, backup: _vm.backup },
                    on: {
                      handleClose: _vm.handleClose,
                      handleSure: _vm.handleSure,
                      handleReturn: _vm.handleReturn,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }