var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            ref: "video_wrapper",
            staticClass: "plugin_video_page",
            style: { "z-index": _vm.zindex },
          },
          [
            _c("div", {
              staticClass: "close cp",
              on: { click: _vm.closeVideo },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ready,
                    expression: "ready",
                  },
                ],
                staticClass: "video_group",
              },
              [
                _c("Xgplayer", {
                  attrs: { config: _vm.config },
                  on: {
                    player: function ($event) {
                      _vm.Player = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }