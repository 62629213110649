<template>
  <div
    class="pop_up_dialog_send_fiels_files_list bbox"
  >
    <!--预览图片 -->
    <el-image
      ref="image"
      v-show="0"
      :src="previewImgSrc"
      :preview-src-list="imgList"
      @click.native="setImgZindex();$api.stopPropagation($event)"
    ></el-image>
    <template
      v-for="(item, index) in fileList"
    >
      <file-component
        :key="index"
        :file-data="item"
        @handleDelete="handleDlete(index)"
        @handlePreview="handlePreview(item)"
      ></file-component>

    </template>
  </div>
</template>

<script>
import FileComponent from './File'
export default {
  name: 'FilesList',
  props: {
    dialog: {
      type: Object,
      default: undefined
    }
  },
  components: {
    FileComponent,
  },
  data() {
    return {
      previewImgSrc: ''
    }
  },
  computed: {
    fileList() {
      const { dialog } = this
      if (dialog) {
        return dialog.files
      }
      return undefined
    },
    imgList() {
      const { fileList } = this
      if (fileList && fileList.length) {
        const imgList = []
        for (let i in fileList) {
          imgList.push(fileList[i].previewUrl)
        }
        return imgList
      }
      return []
    }
  },
  mounted () {
    console.log(this.dialog)
    this.init()
  },
  methods: {
    handlePreview(fileData) {
      console.log(fileData)
      this.previewImgSrc = fileData.previewUrl
      this.$nextTick(function() {
        this.$refs.image.clickHandler()
        this.setImgZindex()
      })
    },
    setImgZindex(){
      const zIndex = this.$utils.getZIndex()
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex
      })
    },
    handleDlete(index) {
      console.log(index)
      this.dialog.files.splice(index, 1)
    },
    async getPreviewUrl() {
      const { fileList } = this
      if (fileList && fileList.length) {
        for (let i = 0; i < fileList.length; i++) {
          const previewData = await this.previewPicture(fileList[i].file)
          fileList[i].previewUrl = previewData.url
          fileList[i].width = previewData.width
          fileList[i].height = previewData.height
        }
      }
      console.log(this.fileList)
    },
    previewPicture(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          /** 地址 */
          let url = event.target.result
          var image = new Image()
          image.src = url;
          image.onload = () => {
            let { width, height } = image;
            // // console.log(image, image.width, image.height);
            resolve({ url, width, height });
          };
        };
      });
    },
    init() {
      this.getPreviewUrl()
    }
  }
}
</script>


<style scoped lang="scss">
.pop_up_dialog_send_fiels_files_list{
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0 10px;

}
</style>