<!--
 * @Author       : Hugo
 * @Date         : 2021-10-27 11:33:03
 * @LastEditTime : 2021-10-27 14:59:41
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/PreviewPicture.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_plugin_preview_picture_page"
  >
    <!--预览图片 -->
    <el-image
      ref="image"
      v-show="0"
      :src="url"
      :preview-src-list="list"
      @click.native="setImgZindex();$api.stopPropagation($event)"
    ></el-image>
  </div>
</template>

<script>
import { Image } from 'element-ui';
export default {
  components: {
    ElImage: Image,
  },
  data() {
    return {
      url: '',
      list: [],
    }
  },
  methods: {
    setImgZindex(){
      const zIndex = this.$utils.getZIndex();
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex;
      })
    },
    preview(params){
      const {url, list} = params;
      this.url = url;
      this.$set(this, 'list', list);
      this.$nextTick(function(){
        this.$refs.image.clickHandler();
      })
    }
  }
}
</script>
