var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            { attrs: { height: "90%", max_width: "80%", max_height: "90%" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "c_collect_plugin_select_route d-flex flex-column",
                },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0",
                    attrs: {
                      title: _vm.title,
                      close: true,
                      background_color: "#fff",
                    },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _c("plugin-component", {
                    staticClass: "plugin_component",
                    attrs: { onSure: _vm.onSure },
                    on: { handleClose: _vm.handleClose },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }