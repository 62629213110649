var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_classes_task_modules_plugins_all_task_users_main bbox d-flex align-center flex-wrap",
    },
    [
      _vm.taskUsers
        ? [
            _vm._l(_vm.taskUsers, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "user d-flex align-center flex-column flex-shrink-0",
                    on: {
                      click: function ($event) {
                        return _vm.openUserDetail(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "avatar_group" }, [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                          alt: "",
                        },
                      }),
                      item.isread
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "readed_group d-flex align-center justify-center",
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("已读"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.priorityName(item))),
                    ]),
                  ]
                ),
              ]
            }),
            _vm.data.task.editable
              ? _c("div", { staticClass: "add_group cp un_sel" }, [
                  _c("div", { staticClass: "text bbox" }, [_vm._v("添加")]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }