/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 10:06:13
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-01 20:41:21
 * @Description  : 
 * @FilePath     : /miaohang/src/router/test.js
 */ 
const test = [
  {
    path: '/test_fu_meta_editor_sdk',
    name: 'TestFuMeatEditorSdk',
    component: () => import('@/views/test/FuMeta/EditorSdk.vue'),
    meta: {allowBack: false},
  },
  {
    path: '/download_app',
    name: 'download_app',
    component: () => import('@/views/App/Download.vue'),
  },
  {
    path: "/test_tz",
    name: "test_tz",
    component: ()=> import('@/views/test/tz.vue'),
  },
  {
    path: "/test_two_list",
    name: "test_two_list",
    component: ()=> import('@/views/test/two_list.vue'),
  },
  {
    path: "/test_zindex",
    name: "test_zindex",
    component: ()=> import('@/views/test/zindex.vue'),
  },
  {
    path: "/test_draggable",
    name: "test_draggable",
    component: ()=> import('@/views/test/draggable.vue'),
  },
  {
    path: '/test_default',
    name: 'test_default',
    component: () => import('@/views/layout/default.vue'),
    children:[
      {
        path:'zindex',
        components:{
          default: ()=> import('@/views/fun_task/index.vue'),
          sidebar: () => import('@/views/test/zindex_routerview.vue'),
        }
      }
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue'),
  },
  // 删除联系人
  {
    path: '/test/delcontact',
    name: 'test_delcontact',
    component: ()=> import('@/views/test/DelContact.vue'),
  },
  /**关联拖拽 */
  {
    path: '/test/draggable_next',
    name: 'test_draggable_next',
    component: ()=> import('@/views/test/DraggableNest.vue'),
  },
  /**大文件上传 */
  {
    path: '/test/simple_upload',
    name: 'test_simple_upload',
    component: ()=> import('@/views/test/SimpleUpload.vue'),
  },
  /**滚动条插件测试 */
  {
    path: '/test/overlayscrollbars',
    name: 'test_overlayscrollbars',
    component: ()=> import('@/views/test/overlayscrollbars.vue'),
    meta: {allowBack: false},
  },
  /**popperjs调试 */
  {
    path: '/test/popperjs',
    name: 'test_popperjs',
    component: ()=> import('@/views/test/popperjs.vue'),
    meta: {allowBack: false},
  },
  /** OSS 测试 */
  {
    path: '/test/oss/upload',
    name: 'test_oss_upload',
    component: ()=> import('@/views/test/Oss/Upload.vue'),
    meta: {allowBack: false},
  },
  {
    path: '/test/html2canvas',
    name: 'test_html2canvas',
    component: ()=> import('@/views/test/html2canvas.vue'),
    meta: {allowBack: false},
  },
  {
    path: '/test/flex',
    name: 'test_flex',
    component: ()=> import('@/views/test/Modules/flex.vue'),
    meta: {allowBack: false},
  },
]

export default test;