/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-08 20:39:37
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 20:44:57
 * @FilePath     : /miaohang/src/utils/plugin/modules/agreement/privacy.js
 * @Description  : 
 */
import PluginComponent from '@/components/Aggrement/Plugins/Privacy/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openAggrementPrivacy = (params={}) => {
      // // console.log(obj)
      const { pcard, mobile, title} = params ?? {}
      instance.title = title ?? '用户服务协议'
      instance.visible = true
    }
    Vue.prototype.$closeAggrementPrivacy = () => {
      instance.visible = false
    }
  }
}

export default PluginMember