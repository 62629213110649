/*
 * @Author       : Hugo
 * @Date         : 2021-12-27 12:17:17
 * @LastEditTime : 2022-02-22 14:41:57
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/router/modules/shopping_mail.js
 * @Description  : 
 * @^_^          : 
 */
const shopping_mail = [
  {
    path: '',
    name: 'shopping_mail',
    component: ()=> import('@/views/ShoppingMail/Pages/Home.vue'),
    redirect: {name: 'shopping_mail_featured'},
    meta: {allowBack: false},
    children: [
      {
        path: 'featured',
        name: 'shopping_mail_featured',
        component: () => import('@/views/ShoppingMail/Pages/Featured/index.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'task',
        name: 'shopping_mail_task',
        component: () => import('@/views/ShoppingMail/Pages/Task/index.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'teaching_package',
        name: 'shopping_mail_teaching_package',
        component: () => import('@/views/ShoppingMail/Pages/TeachingPackage/index.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'task_detail/:task_id/:payment',
        name: 'shopping_mail_task_detail',
        component: () => import('@/views/ShoppingMail/Pages/TaskDetail.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'teaching_package_detail/:package_id',
        name: 'shopping_mail_teaching_package_detail',
        component: () => import('@/views/ShoppingMail/Pages/TeachingPackageDetail.vue'),
        meta: {allowBack: false},
      },
      {
        path: 'search',
        name: 'shopping_mail_search',
        props: true,
        component: () => import('@/views/ShoppingMail/Pages/Search.vue'),
        meta: {allowBack: false},
      },
    
    
    ]
  },
  {
    path: 'order_list',
    name: 'shopping_mail_order_list',
    props: true,
    component: () => import('@/views/ShoppingMail/Pages/Order/List.vue'),
    meta: {allowBack: false},
  },
  {
    path: 'custom_list',
    name: 'shopping_mail_custom_list',
    props: true,
    component: () => import('@/views/ShoppingMail/Pages/CustomList/index.vue'),
    meta: {allowBack: false},
  },
  
];

export default shopping_mail;