/*
 * @Author       : Hugo
 * @Date         : 2022-04-20 10:49:22
 * @LastEditTime: 2022-05-30 21:45:50
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/utils/plugin/modules/task_gift_bag.js
 * @Description  : 
 * @^_^          : 
 */

import PluginComponent from '@/components/ClassesTask/modules/Plugins/AllTaskUsers/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openAllTaskUsers = (params={}) => {
      // // console.log(obj)
      const {data, backup} = params
      instance.data = data
      instance.backup = backup
      instance.visible = true
    }
    Vue.prototype.$closeAllTaskUsers = () => {
      instance.visible = false
    }
  }
}

export default PluginMember