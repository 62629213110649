var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_thumbnail_task ml-5 mr-10 mb-10" }, [
    _c(
      "div",
      {
        staticClass: "new_content_group",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.openTask.apply(null, arguments)
          },
        },
      },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canDelete,
              expression: "canDelete",
            },
          ],
          staticClass: "delete cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleDelete.apply(null, arguments)
            },
          },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canSelect,
              expression: "canSelect",
            },
          ],
          staticClass: "select_group cp",
          class: { active: _vm.selected },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSelected.apply(null, arguments)
            },
          },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nonselectable,
              expression: "nonselectable",
            },
          ],
          staticClass: "nonselectable",
        }),
        _vm.pTask.colour
          ? _c(
              "div",
              { staticClass: "color_group" },
              [
                _vm._l(_vm.getColorList(_vm.pTask), function (item, index) {
                  return [
                    _c("div", {
                      key: index,
                      staticClass: "color",
                      style: { "border-left-color": item },
                    }),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pTask.dot,
              expression: "pTask.dot",
            },
          ],
          staticClass: "dot",
        }),
        _c("div", { staticClass: "info_group" }, [
          _c(
            "div",
            { staticClass: "info_box d-flex justify-space-between" },
            [
              [
                _c(
                  "div",
                  { staticClass: "info cp un_sel" },
                  [
                    _c("title-status-tag", {
                      staticClass: "status_tag_group",
                      attrs: {
                        "level-status": String(_vm.pTask.levelStatus),
                        "process-status": String(_vm.pTask.processStatus),
                        size: "14px",
                      },
                    }),
                    _c("span", { staticClass: "task_name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.pTask.taskDetail ||
                              _vm.pTask.name ||
                              _vm.pTask.taskName
                          ) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right-start",
                    width: "400",
                    trigger: "click",
                    "visible-arrow": false,
                    "popper-class": "c_collect_folder_unit_popover",
                  },
                  on: {
                    hide: function ($event) {
                      return _vm.hidePopoverHandler()
                    },
                    show: function ($event) {
                      return _vm.popoverShowHandler()
                    },
                  },
                  model: {
                    value: _vm.switchMenu,
                    callback: function ($$v) {
                      _vm.switchMenu = $$v
                    },
                    expression: "switchMenu",
                  },
                },
                [
                  _c("div", { ref: "menu_group", staticClass: "menu_group" }, [
                    _c("div", { staticClass: "menu_list_group" }, [
                      _vm.canSetStatus
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.touchSetStatusTag()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  设置状态\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canSetGeneralTag
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.stouchSetGeneralTag()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  设置标签\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canCall
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.callTask()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  调用任务\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canShare
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.shareTask()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  分享任务\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canCollect
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.touchCollect()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      `${
                                        _vm.pTask.collectionflag ? "取消" : ""
                                      }收藏`
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canDeleteTask
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteTask()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  "\n                  删除任务\n                "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMenu,
                        expression: "showMenu",
                      },
                    ],
                    staticClass: "more_btn button un_sel",
                    attrs: { slot: "reference" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$api.stopPropagation($event)
                      },
                    },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            2
          ),
          _c("div", { staticClass: "time_box" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("universalDate")(_vm.pTask.updateTime)) +
                "\n      "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "template_info_group", class: { separate: 1 } },
          [
            _vm.pTask.createuserthumbnail
              ? _c(
                  "div",
                  { staticClass: "avatar_group" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.pTask.createusername,
                          placement: "bottom",
                          "open-delay": 500,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${_vm.pTask.createuserthumbnail}@!small200`,
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div"),
            _c("div", { staticClass: "tag_box" }, [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pTask.isremind,
                    expression: "pTask.isremind",
                  },
                ],
                staticClass: "tag reminder",
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        Number(_vm.pTask.taskType) === 2 ||
                        Number(_vm.pTask.taskType) === 3,
                      expression:
                        "Number(pTask.taskType) === 2 || Number(pTask.taskType) === 3",
                    },
                  ],
                  staticClass: "tag teach",
                },
                [_vm._v("教")]
              ),
              _vm.pTask.hide
                ? _c("div", { staticClass: "tag privacy" }, [_vm._v("私")])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
    _vm.showFrom
      ? _c(
          "div",
          { staticClass: "from_group d-flex bbox" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("\n      来源:\n    ")]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.pTask.from,
                  placement: "bottom-start",
                  "open-delay": 500,
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v("\n        " + _vm._s(_vm.pTask.from) + "\n      "),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }