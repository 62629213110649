import { render, staticRenderFns } from "./DetailInfo.vue?vue&type=template&id=83cbd962"
import script from "./DetailInfo.vue?vue&type=script&lang=js"
export * from "./DetailInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83cbd962')) {
      api.createRecord('83cbd962', component.options)
    } else {
      api.reload('83cbd962', component.options)
    }
    module.hot.accept("./DetailInfo.vue?vue&type=template&id=83cbd962", function () {
      api.rerender('83cbd962', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DetailInfo.vue"
export default component.exports