<template>
  <span
    v-if="titleStatusList && titleStatusList.length"
    class="tag_list"
  >
    <template
      v-for="(item, index) in titleStatusList"
    >
      <span
        :key="index"
        class="tag"
        :style="{ width: size, height: size }"
        :class="{ [item.type]: true }"
      ></span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'TitleStatusTag',
  props: {
    levelStatus: {
      type: String,
      default: ''
    },
    processStatus: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '24px'
    }
  },
  computed: {
    titleStatusList() {
      const { levelStatus, processStatus } = this
      let statusArr = []
      if ((levelStatus ?? '') !== '') {
        const levelArr = String(levelStatus).split(',')
        if (levelArr && levelArr.length) {
          levelArr.forEach((item) => {
            if (Number(item) === 1) {
              statusArr.push({ name: '紧急', value: 1, type: 'urgency' })
            }
            if (Number(item) === 2) {
              statusArr.push({ name: '重要', value: 2, type: 'importance' })
            }
          })
        }
      }
      if ((processStatus ?? '') !== '') {
        if (Number(processStatus) === 1) {
          statusArr.push({name: '未完成', value: 1, type: 'unfinished' })
        }
        if (Number(processStatus) === 2) {
          statusArr.push({name: '暂停', value: 2, type: 'pause' })
        }
        if (Number(processStatus) === 3) {
          statusArr.push({name: '完成', value: 3, type: 'finish' })
        }
      }
      return statusArr
    }
  },
}
</script>

<style scoped lang="scss">
@mixin tag($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/status_tag/#{$name}.png');
  }
}
.tag_list{
  .tag{
    @include background;
    @include tag(urgency);
    @include tag(importance);
    @include tag(finish);
    @include tag(unfinished);
    @include tag(pause);
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-left: 3px;
    margin-bottom: 3px;
  }
}
</style>