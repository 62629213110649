var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    {
      staticClass: "justify-center",
      attrs: { max_width: "600px", transparent: true },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "components_pop_up_identification_code_free_wrapper d-flex align-center justify-center",
        },
        [
          _c(
            "div",
            { staticClass: "components_pop_up_identification_code_free" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/logo.png"), alt: "" },
              }),
              _c(
                "div",
                { staticClass: "wrapper" },
                [
                  _c("div", {
                    staticClass: "close cp",
                    on: {
                      click: function ($event) {
                        return _vm.doClose()
                      },
                    },
                  }),
                  _vm.page_process === "init"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "title_group d-flex align-center justify-center",
                          },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.title)),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "main d-flex flex-column align-start bbox",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "line_group bbox d-flex align-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "key flex-shrink-0" },
                                  [
                                    _vm._v(
                                      "\n                真实姓名\n              "
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.name,
                                      expression: "name",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入真实姓名",
                                  },
                                  domProps: { value: _vm.name },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.name = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "line_group bbox d-flex align-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "key flex-shrink-0" },
                                  [
                                    _vm._v(
                                      "\n                联系方式\n              "
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.phone,
                                      expression: "phone",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    maxlength: "11",
                                    placeholder: "请输入联系方式",
                                  },
                                  domProps: { value: _vm.phone },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.phone = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "line_group bbox d-flex align-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "key flex-shrink-0" },
                                  [
                                    _vm._v(
                                      "\n                您的职位\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "select_group",
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择您的职位",
                                      value: "identities",
                                    },
                                    model: {
                                      value: _vm.identities,
                                      callback: function ($$v) {
                                        _vm.identities = $$v
                                      },
                                      expression: "identities",
                                    },
                                  },
                                  _vm._l(_vm.identities_list, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "line_group bbox d-flex align-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "key flex-shrink-0" },
                                  [
                                    _vm._v(
                                      "\n                机构名称\n              "
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.school,
                                      expression: "school",
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入机构名称",
                                  },
                                  domProps: { value: _vm.school },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.school = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "line_group bbox d-flex align-start",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "key flex-shrink-0" },
                                  [
                                    _vm._v(
                                      "\n                简短描述\n              "
                                    ),
                                  ]
                                ),
                                _c("el-input", {
                                  staticClass: "textarea",
                                  attrs: {
                                    type: "textarea",
                                    placeholder: "请输入内容",
                                    maxlength: "80",
                                    "show-word-limit": "",
                                    resize: "none",
                                  },
                                  model: {
                                    value: _vm.content,
                                    callback: function ($$v) {
                                      _vm.content = $$v
                                    },
                                    expression: "content",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "hint bbox" }, [
                              _vm._v(_vm._s(_vm.hint)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "button_group d-flex justify-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn btn_dark",
                                    on: {
                                      click: function ($event) {
                                        return _vm.touchSubmit()
                                      },
                                    },
                                  },
                                  [_vm._v("点击咨询")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm.page_process === "success"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "success_group d-flex flex-column justify-center align-center",
                          },
                          [
                            _c("img", {
                              staticClass: "success_icon",
                              attrs: {
                                src: require("@/assets/images/identification_code/success.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("您已成功提交，请等待反馈"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn_dark cp bbox",
                                on: {
                                  click: function ($event) {
                                    return _vm.applyMore()
                                  },
                                },
                              },
                              [_vm._v("继续添加多个申请")]
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }