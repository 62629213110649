<template>
  <div class="c_teaching_package_selected_task_list_filter_bar">
    <div class="search_group">
      <input
        type="text"
        class="search bbox"
        placeholder="搜索"
        v-model="search"
        @keydown.enter="doSearch($event)"
      >
      <img
        v-show="search !== ''"
        src="~@/assets/images/common/clear_icon.png"
        alt=""
        class="clear cp"
        @click.stop="doClearSearch()"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterBar',
  data() {
    return {
      search: ''
    }
  },
  methods: {
    doSearch(e) {
      const { keyCode } = e
      if (keyCode === 229) {
        return
      }
      this.$emit('handleSearch', { search: this.search.trim() })
    },
    doClearSearch() {
      this.search = ''
      this.$emit('handleSearch', { search: this.search.trim() })
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_selected_task_list_filter_bar{
  .search_group{
    width: 200px;
    position: relative;
    background: #E9E9E9;
    border-radius: 0 4px 4px 0;
    .search{
      @include placeholder(#999);
      position: relative;
      z-index: 2;
      width: 100%;
      line-height: 30px;
      height: 30px;
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 14px;
      color: #333;
      padding: {
        left: 10px;
        right: 30px;
      }
    }
    .clear{
      position: absolute;
      z-index: 3;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
}
</style>