<template>
  <div
    class="create_user_wrapper d-flex align-center cp"
  >
    <template
      v-for="(item, index) in list"
    >
      <div
        :key="index"
        class="flex-shrink-0 avatar_wrapper"
        v-if="index < 7"
      >
        <!-- <el-tooltip
          class="item"
          effect="dark"
          :content="item.friendRemark || item.chatUserName || item.userName"
          placement="bottom"
        > -->
          <el-image
            v-if="item.thumbnail"
            class="avatar"
            :src="`${$avatar_url}${item.thumbnail}@!small200`"
            fit="cover"
          ></el-image>
          <el-image
            v-else
            class="avatar"
            :src="require(`@/assets/${$default_avatar}`)"
            fit="cover"
          ></el-image>
        <!-- </el-tooltip> -->
      </div>
      
    </template>
    <div class="more cp"
      v-if="list.length > 7"
    >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    createtousers: {
      type: String,
      default: '',
    }
  },
  data(){
    return {
      list: [],
      // task_users: [],
    }
  },
  computed:{
    // dialog_user_list(){
    //   return this.$store.state.dialog_user_list;
    // }
  },
  watch:{
    // taskusers: function(){
    //   this.generateTaskUsers();
    // },
    // dialog_user_list: function(){
    //   this.generateTaskUsers();
    // },
  },
  mounted() {
    // this.generateTaskUsers();
    this.init();
  },
  methods: {
    generateCreateUsers(){
      const {createtousers} = this;
      let list = [];
      if(createtousers){
        const arr_split = createtousers.split('|');
        if(arr_split && arr_split.length){
          arr_split.forEach(item=> {
            if(item){
              const obj_split = item.split(',');
              list.push({
                id: obj_split[0],
                thumbnail: obj_split[1], 
              })
            }
          })
          this.$set(this,'list', list);
        }
      }
    },
    init(){
      this.generateCreateUsers();
    }
  },
}
</script>

<style lang="scss" scoped>
.create_user_wrapper{
  .avatar{
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  .avatar_wrapper{
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .more{
    @include background(20px, 4px);
    background: {
      image: url(~@/assets/images/common/more.png);
    }
    filter: opacity(50%);
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
}
</style>