var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "400px", max_height: "600px" } },
    [
      _c(
        "div",
        { staticClass: "components_pop_up_at_index pb-20 d-flex flex-column" },
        [
          _c("common-title", {
            staticClass: "flex-shrink-0",
            attrs: { title: _vm.title, close: "" },
            on: { handleClose: _vm.handleClose },
          }),
          _c("div", { staticClass: "main" }, [
            _c(
              "div",
              { staticClass: "sticky_group" },
              [
                _c("search", {
                  staticClass: "common_border pt-20",
                  on: { handleSearch: _vm.handleSearch },
                }),
                _c("all", {
                  staticClass: "common_border pt-20 pb-10",
                  attrs: { task_id: _vm.task_id },
                  on: { handleAll: _vm.handleAll },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "list_group" },
              [
                _vm._l(_vm.list, function (item, index) {
                  return [
                    _vm.self_id != item.userId
                      ? _c("unit", {
                          key: index,
                          staticClass: "unit common_border bbox py-10",
                          attrs: {
                            search: _vm.search,
                            item: _vm.UserInfo(item),
                          },
                          on: { handleSure: _vm.doSure },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }