var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_payment_view_keys_index" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "list_group" },
      [
        _vm._l(_vm.key_list, function (item, index) {
          return [
            _c("div", { key: index, staticClass: "list d-flex align-center" }, [
              _c(
                "div",
                {
                  staticClass: "unit bbox",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doCopy(item.code)
                    },
                  },
                },
                [_vm._v(_vm._s(item.code))]
              ),
              _c("div", { staticClass: "unit bbox cp" }, [
                _vm._v(_vm._s(item.userId ? "已" : "未") + "激活"),
              ]),
              _c("div", { staticClass: "unit bbox" }, [
                _vm._v(_vm._s(item.userName || "—")),
              ]),
              _c("div", { staticClass: "unit bbox" }, [
                _vm._v(
                  _vm._s(
                    item.updateTime
                      ? _vm
                          .$moment(item.updateTime)
                          .format("YYYY-MM-DD hh:mm:ss")
                      : "—"
                  )
                ),
              ]),
            ]),
          ]
        }),
        !_vm.nomore_key
          ? _c(
              "div",
              {
                staticClass:
                  "loadmore_group d-flex align-center justify-center",
              },
              [
                !_vm.loading_key
                  ? _c(
                      "div",
                      {
                        staticClass: "text un_sel cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.loadmore()
                          },
                        },
                      },
                      [_vm._v("加载更多")]
                    )
                  : _c("loading-icon", { attrs: { size: "20px" } }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sticky_group" }, [
      _c("div", { staticClass: "header_group d-flex align-center un_sel" }, [
        _c("div", { staticClass: "unit bbox" }, [_vm._v("激活码")]),
        _c("div", { staticClass: "unit bbox" }, [_vm._v("激活状态")]),
        _c("div", { staticClass: "unit bbox" }, [_vm._v("激活账户")]),
        _c("div", { staticClass: "unit bbox" }, [_vm._v("激活时间")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }