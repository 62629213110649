<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 15:58:44
 * @LastEditTime : 2022-04-25 16:01:12
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Navigation/Wrapper.vue
 * @Description  :
 * @^_^          :
-->
<template>
  <div
    class="c_collect_navigation_index bbox d-flex flex-wrap align-center"
  >

    <div class="all unit cp"
      @click.stop="goToTop()"
      v-if="collect_nav && collect_nav.length && !preview "
    >全部分组</div>
    <template
      v-if="collect_nav && collect_nav.length"
    >
      <template
        v-for="(item,index) in collect_nav"
      >
        <template
          v-if="item && item.name"
        >
          <div class="symbol"
            v-if="(preview && index !== 0) || !preview"
            :key="`${index}_symbol`"
          >&nbsp;&gt;&nbsp;</div>
          <unit-component
            :key="index"
            :p_folder="item"
            class="unit cp"
            :class="{active: index === collect_nav.length -1}"
            @click.native.stop="goToFolder(item, index)"
          ></unit-component>
        </template>

      </template>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import UnitComponent from './Unit.vue'
export default {
  components: {
    UnitComponent,
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    top_jump: { // 顶级是否跳转
      type: Boolean,
      default: false,
    }
  },
  computed: {
    collect_nav(){
      return store.state.collect_nav
    }
  },
  data(){
    return {
    }
  },
  methods: {
    goToTop(){
      const {top_jump} = this
      if(top_jump){
        const {name} = this.$route
        // if(name === 'fun_template_my_custom' || name === 'fun_template_my') {
        if(name === 'fun_template_my_custom') {
          this.$router.push({
            name: "fun_template_my",
            params: {
              type: 'custom',
            }
          })
        }
      }
      this.$nextTick(function(){

        store.commit('set_collect_nav', undefined)
        this.$tools.removeCookie('collect_nav')
      })

    },
    goToFolder(p_folder, index){
      const {collect_nav} = this
      if(index === collect_nav.length - 1){
        return
      }
      store.commit(
        'set_collect_nav',
        collect_nav.slice(0,index+1)
      )
    }

  }
}
</script>

<style lang="scss" scoped>
.c_collect_navigation_index{
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  .unit{
    @include transition;
    &.active,&:hover{
      color: $main_blue;
    }
  }
}
</style>
