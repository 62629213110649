<template>
  <div
    class="components_popup_record_of_communication_text_text_page bbox d-flex align-start"
  >
    <div class="user_info flex-shrink-0 d-flex flex-column align-center mr-15">
      <img 
        :src="`${$avatar_url}${item.thumbnail}@!small200`"
        alt=""
        class="avatar"
      >
      <div class="name pt-5">{{item.userName}}</div>
    </div>
    <div class="main_group">
      <div class="line_group mb-8 d-flex align-stretch">
        <div class="chat_content content bbox cp"
          v-html="$tools.highlightKeywords(item.work, keyword)"
        >在之前已完成疫苗接种统计工作的基础上，对相关单位张贴:疫苗接种情况告知书。根据您之前上报的接种人数比例，勾选对应安全等级（如下图）。如无异议，物业将在今天17点前，前往贵司张贴告知书并拍照，同时根据要求需提供公司营业执照照片。</div>
        <div class="fun_group bbox d-flex flex-column align-start justify-space-between ">
          <div class="time">{{getTime()}}</div>
          <div class="check cp"
            @click="openContext()"
          >查看上下文</div>
        </div>
      </div>
      <div class="line_group d-flex align-start"
        v-if="item.logType===3"
      >
        <div class="task_name content bbox cp"
          @click="openContext()"
        >{{item.taskName}}</div>
        <div class="fun_group"></div>
      </div>
    </div>
    <task-chat-context
      v-if="switch_task_chat_context"
      :item="item"
      :chat_type="Number(chat_type)"
      :chat_id="Number(chat_id)"
      :task_id="Number(item.taskId)"
      @handleClose="closeContext"
    ></task-chat-context>

    <workgroup-context
      v-if="switch_chat_context"
      @handleClose="switch_chat_context=false;"
      :chat_id="item.chatId"
      :chat_type="item.type"
      :task_id="item.taskId"
      :item="item"
    ></workgroup-context>
  </div>
</template>

<script>
import store from '@/store'
import TaskChatContext from '@/components/PopUp/TaskChatContext/index.vue';
import WorkgroupContext from '@/components/PopUp/WorkgroupContext/index.vue';
export default {
  components: {
    TaskChatContext,
    WorkgroupContext,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    keyword: {
      type: String,
      default: '',
    },
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
  },
  data(){
    return {
      widget: null,
      switch_task_chat_context: false,
      switch_chat_context: false,
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    /**
     * @description: 获取颜色列表
     * @param {*} colour
     * @return {*}
     */
    getColorList(colour){
      if(!colour){
        return '';
      }
      const color_list = colour.split(',');
      return color_list;

    },
    init(){
      // this.getTime();
    },
    getTime(){
      const {item} = this;
      const {createTime} = item;
      const now = this.$moment(Date.now());
      const aim = this.$moment(createTime);
      const diff_days = now.diff(aim, 'days');
      if(diff_days>0){
        return aim.format('YYYY/MM/DD')
      }
      if(diff_days == 0){
        return aim.format('HH:mm')
      }
      return false;
    },
    openContext(){
      const {item} = this;
      const {logType} = item;
      if(logType === 3) {

        // this.switch_task_chat_context = true;
        store.commit('set_chat_context_data', this.item);
        this.item.checkTaskMessageContext();
      }
      if(logType === 4){
        this.switch_chat_context = true;
      }
    },
    closeContext(){
      this.switch_task_chat_context = false;
    }
  }
}
</script>


<style lang="scss" scoped>
.components_popup_record_of_communication_text_text_page{
  .user_info{
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 4px;
      display: block;
    }
    .name{
      width: 40px;
      color: #aaa;
      font-size: 12px;
    }
  }
  .main_group{
    flex: 1;
    .line_group{
      width: 100%;
      .content{
        flex: 1;
        padding: 12px;
        font-size: 14px;
        color: #333;
        line-height: 20px;
        border-radius: 4px;
        &.chat_content{
          background-color: #fff;
          min-height: 64px;
          word-break: break-all;
          white-space: pre-wrap;
          word-wrap: break-word;
        }
        &.task_name{
          @include transition;
          padding: 7px 12px;
          background-color: #eee;
          font-size: 12px;
          color: #666;
          &:hover{
            background-color: #dfdfdf;
          }
        }
      }
      .fun_group{
        padding: 4px 0 6px;
        width: 60px;
        margin-left: 10px;
        font-size: 12px;
        color: #999;
        .check{
          @include transition;
          &:hover{
            color: $main_blue;
          }
        }
      }
    }
  }
}
</style>