var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pop_up_dialog_send_fiels_files_list bbox" },
    [
      _c("el-image", {
        directives: [
          { name: "show", rawName: "v-show", value: 0, expression: "0" },
        ],
        ref: "image",
        attrs: { src: _vm.previewImgSrc, "preview-src-list": _vm.imgList },
        nativeOn: {
          click: function ($event) {
            _vm.setImgZindex()
            _vm.$api.stopPropagation($event)
          },
        },
      }),
      _vm._l(_vm.fileList, function (item, index) {
        return [
          _c("file-component", {
            key: index,
            attrs: { "file-data": item },
            on: {
              handleDelete: function ($event) {
                return _vm.handleDlete(index)
              },
              handlePreview: function ($event) {
                return _vm.handlePreview(item)
              },
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }