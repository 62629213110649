/*
 * @Author       : Hugo
 * @Date         : 2022-03-04 14:50:18
 * @LastEditTime : 2022-03-11 14:34:39
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/wisdom/index.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main';
// import store from '@/store';
import {PScreen} from '@/types';
class Wisdom{
  getUserScreen = async () => {
    // const {state, commit} = store;
    // const {wisdom_user_screen} = state;
    let user_screen;
    let res;

    // if(wisdom_user_screen && wisdom_user_screen.length){
    //   user_screen = vue.$types.transformObjectForList({
    //     list: wisdom_user_screen,
    //     obj: PScreen
    //   })
    // }else{
      res = await vue.$global.doPost({
        url: '/screen/getUserScreen',
      })
      if(res.message === 'success'){
        const {userScreens} = res.contents;
        // commit('set_wisdom_user_screen', vue.$types.transformObjectForList({
        //   list: userScreens,
        //   obj: PScreen,
        // }))
        user_screen = vue.$types.transformObjectForList({
          list: userScreens,
          obj: PScreen
        })
      }
    // }
    
    return user_screen;
  }
}

const wisdom = new Wisdom();

export default wisdom;