/*
 * @Author       : Hugo
 * @Date         : 2022-03-14 11:03:11
 * @LastEditTime : 2022-03-14 11:33:55
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PTaskReadUser/index.js
 * @Description  : 
 * @^_^          : 
 */
class PTaskReadUser{
  colour      = undefined;
  createTime  = undefined;
  isdo        = undefined;
  isimportant = undefined;
  isread      = undefined;
  taskId      = undefined;
  taskchatnum = undefined;
  thumbnail   = undefined;
  updateTime  = undefined;
  userId      = undefined;
  userName    = undefined;

  constructor(params = {}){
    const {
      colour,
      createTime,
      isdo,
      isimportant,
      isread,
      taskId,
      taskchatnum,
      thumbnail,
      updateTime,
      userId,
      userName,
    } = params;
    this.colour      = colour;
    this.createTime  = createTime;
    this.isdo        = isdo;
    this.isimportant = isimportant;
    this.isread      = isread;
    this.taskId      = taskId;
    this.taskchatnum = taskchatnum;
    this.thumbnail   = thumbnail;
    this.updateTime  = updateTime;
    this.userId      = userId;
    this.userName    = userName;
  }
}
export default PTaskReadUser;