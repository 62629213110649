<!--
 * @Author       : Hugo
 * @Date         : 2022-01-10 10:11:41
 * @LastEditTime : 2022-01-11 09:33:39
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Payment/ViewKeys/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_payment_view_keys_index"
  >
    <div class="sticky_group">
      <div class="header_group d-flex align-center un_sel">
        <div class="unit bbox">激活码</div>
        <div class="unit bbox">激活状态</div>
        <div class="unit bbox">激活账户</div>
        <div class="unit bbox">激活时间</div>
      </div>
    </div>
    <div class="list_group">
      <template
        v-for="(item, index) in key_list"
      >
        <div
          :key="index"
          class="list d-flex align-center"
        >
          <div class="unit bbox"
            @click.stop="doCopy(item.code);"
          >{{item.code}}</div>
          <div class="unit bbox cp">{{item.userId ? '已' : '未'}}激活</div>
          <div class="unit bbox">{{item.userName || '—'}}</div>
          <div class="unit bbox">{{item.updateTime ? $moment(item.updateTime).format('YYYY-MM-DD hh:mm:ss'): '—'}}</div>
        </div>
      </template>
      <div class="loadmore_group d-flex align-center justify-center"
        v-if="!nomore_key"
      >
        <div class="text un_sel cp"
          v-if="!loading_key"
          @click.stop="loadmore();"
        >加载更多</div>
        <loading-icon
          v-else
          size="20px"
        ></loading-icon>
      </div>
    </div>
  </div>
</template>

<script>
import {PUserCode} from '@/types';
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  components: {
    LoadingIcon,
  },
  props: {
    order: {
      type: Object,
      default: undefined,
    },
    key_type: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      nomore_key : false,
      loading_key: false,
      key_list   : [],
    }
  },
  watch: {
    key_type: function(){
      this.init();
    },
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      this.clearList();
      this.getList();
    },
    clearList(){
      this.$set(this, 'key_list', []);
      this.nomore_key  = false;
      this.loading_key = false;
    },
    async getList(){
      const {order, nomore_key, loading_key} = this;
      if(nomore_key || loading_key){
        return false;
      }
      this.loading_key = true;
      if(order === undefined){
        return;
      }
      const { key_type:useflag, key_list } = this;
      const {orderId}                   = order;
      const data                        = {
        orderId,
        useflag,
        start: key_list.length,
      }
      
      const res = await this.$global.doPost({
        url: '/order/myOrderUserCode',
        data,
      })
      const {message, contents} = res;
      if(message==='success'){
        const {usercodelist} = contents;
        if(!usercodelist || usercodelist.length < 20){
          this.nomore_key = true;
        }
        const list = this.$types.transformObjectForList({
          list: usercodelist,
          obj: PUserCode,
        })
        this.$set(this, 'key_list', [
          ...new Set([
            ...this.key_list,
            ...list,
          ])
        ])
      }
      this.loading_key = false;
    },
    async loadmore(){
      await this.getList();
    },
    doCopy(text){
      this.$copyText(text).then( (e)=>{
        // alert('Copied')
        this.$notice({desc: '激活码已复制', type: 'success'});
        // // console.log(e)
      }, (e) => {
        // alert('Can not copy')
        // this.$notice({desc: '激活码已复制', type: 'success'});
        // console.log(e)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.components_payment_view_keys_index{
  position: relative;
  z-index: 2;
  width: 100%;
  .sticky_group{
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
  }
  .header_group{
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    color: #333333;
    background-color: #fff;
    border-bottom: 1px solid #E5EEF6;
    .unit{
      line-height: 50px;
    }
  }
  .unit{
    border-right: 1px solid #E7EAED;
    text-align: center;
    flex: 1;
    &:last-child{
      border-right: none;
    }
  }
  .list_group{
    width: 100%;
    font-size: 14px;
    color: #333;
    .list{
      background-color: #fff;
      &:nth-child(odd){
        background-color: #E5EEF6;
      }
      .unit{
        line-height: 40px;
      }
      &:last-child{
        border-bottom: 1px solid #E5EEF6;
      }
      &:nth-last-child(2){
        border-bottom: 1px solid #E5EEF6;
      }
      &:nth-last-of-type(2){
        border-bottom: 1px soldi #E5EEF6;
      }
    }
  }
  .loadmore_group{
    padding: 10px 0;
    .text{
      line-height: 20px;
      font-size: 14px;
      color: $main_blue;
    }
  }
}
</style>
