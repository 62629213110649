<!--
 * @Author       : Hugo
 * @Date         : 2021-12-15 11:39:37
 * @LastEditTime : 2021-12-15 14:50:36
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/PaymentStatus/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="400px"
    max_height="180px"
  >
    <div class="components_pop_up_payment_status_index d-flex align-center justify-center">
      <div class="status_icon"
        :class="{failed: status==='failed'}"
      ></div>
      <div class="close cp"
        v-if="close" 
        @click.stop="doClose();"
      ></div>
      <div>
        <div class="title">{{title}}</div>
        <div class="jump_group pt-20"
          v-if="jump"
        >
          <span class="seconds blue">{{count_down}}</span>
          <span class="hint"> 秒后将自动跳转至{{jump_name}} ｜ </span>
          <span class="immediately_jump blue cp"
            @click.stop="immediatelyJump();"
          >立即跳转</span>
        </div>
      </div>
    </div>
  </common-wrapper>
</template>


<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import router from "@/router";
export default {
  components: {
    CommonWrapper,
  },
  props: {
    title: {
      type   : String,
      default: null,
    },
    close: {
      type   : Boolean,
      default: false,
    },
    jump: {
      type   : Boolean,
      default: false,
    },
    jump_second: {
      type   : Number,
      default: 5,
    },
    jump_name: {
      type   : String,
      default: undefined,
    },
    jump_router_name: {
      type   : String,
      default: undefined,
    },
    type: {
      type   : String,
      default: 'member',   // member: 会员
    },
    status: {
      type   : String,
      default: 'success',
    },
  },
  data(){
    return{ 
      count_down: 5,
      count_down_timer: undefined,
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      const {jump_second} = this;
      this.count_down = jump_second;
      this.countDown();
    },
    countDown(){
      const {jump, jump_name, jump_router_name} = this;
      if(jump && jump_name !== undefined && jump_router_name !== undefined){
        this.count_down_timer = setInterval(()=>{
          this.count_down = this.count_down-1;
          if(this.count_down === 0){
            clearInterval(this.count_down_timer);
            this.immediatelyJump();
          }
        },1000)
      }
    },
    doClose(){
      clearInterval(this.count_down_timer);
      this.$emit('handleClose');
    },
    immediatelyJump(){
      const {jump_router_name} = this;
      this.doClose();
      router.push({name: jump_router_name });
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_payment_status_index{
  position: relative;
  width: 100%;
  height: 100%;
  .close{
    @include background(12px, 12px);
    width: 20px;
    height: 20px;
    position: absolute;
    background-image: url(~@/assets/images/pop_up/close.png);
    top: 10px;
    right: 10px;
  }
  .status_icon{
    @include background;
    position: absolute;
    left: 50%;
    top: -37px;
    transform: translateX(-50%);
    width: 74px;
    height: 74px;
    border-radius: 50%;
    background-image: url(~@/assets/images/pop_up/payment_status/success.png);
    &.failed{
      background-image: url(~@/assets/images/pop_up/payment_status/failed.png);
    }
  }
  .title{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  .jump_group{
    font-size: 14px;
    .blue{
      color: $main_blue;
    }
  }
}
</style>