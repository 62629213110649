var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_select_task_package_list bbox" },
    [
      _c(
        "div",
        { staticClass: "list_group d-flex flex-wrap bbox" },
        [
          _vm._l(_vm.packageList, function (item, index) {
            return [
              _vm.pPackage.id !== item.id
                ? _c("thumbnail-package", {
                    key: index,
                    staticClass: "package_group cp",
                    attrs: { "p-package": item },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.checkPackage(item)
                      },
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "more_group d-flex align-center justify-center" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMore && !_vm.showLoading,
                  expression: "showMore && !showLoading",
                },
              ],
              staticClass: "more cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.loadmore()
                },
              },
            },
            [_vm._v("\n      加载更多\n    ")]
          ),
          _c("loading-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLoading,
                expression: "showLoading",
              },
            ],
            attrs: { size: "26" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }