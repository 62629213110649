var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sketch_chat_content_page d-flex align-stretch flex-column",
      class: [_vm.type],
    },
    [
      _c("chat-common", {
        staticClass: "cp",
        attrs: { msg: _vm.msg, type: _vm.type },
        nativeOn: {
          click: function ($event) {
            return _vm.openTask.apply(null, arguments)
          },
        },
      }),
      _vm.is_task
        ? _c("chat-task-title", {
            staticClass: "cp",
            attrs: { msg: _vm.msg },
            nativeOn: {
              click: function ($event) {
                return _vm.openTask.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }