import UserDetailComponent from '@/components/Plugin/UserDetail.vue'

const UserDetail = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const UserDetailConstructor = Vue.extend(UserDetailComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new UserDetailConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$user_detail = (obj) => {
      // // console.log(obj)
      let {userId, data} = obj;
      instance.data = data;
      instance.userId = userId;
      instance.visible = true;
      // duration = duration || 1500; // 默认1500关闭
      // setTimeout(()=>{
      //   instance.visible = false;
      // },duration)
    }
  }
}

export default UserDetail