var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog_msg_receive_unit" },
    [
      _vm.show_time
        ? _c("div", { staticClass: "time_group" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("universalDate")(_vm.msg_data.createTime)) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm.isFileMessage
        ? _c("div", { staticClass: "wrapper_group", class: [_vm.type] }, [
            _vm.type === "send"
              ? _c("div", { staticClass: "placeholder" })
              : _vm._e(),
            _c("div", { staticClass: "container_group" }, [
              _vm.type === "receive"
                ? _c(
                    "div",
                    { staticClass: "avatar_group" },
                    [
                      _c("AvatarDetail", {
                        attrs: { type: _vm.type, msg_data: _vm.msg_data },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content_group", class: [_vm.type] },
                [
                  _vm.type === "receive"
                    ? _c(
                        "div",
                        {
                          staticClass: "username bbox cp",
                          class: { open_at: _vm.chat_type === 1 },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "at",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.atUser()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            点此@" +
                                  _vm._s(_vm.userName) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.userName) +
                                "\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("picture-message", {
                    attrs: { "msg-data": _vm.msg_data },
                  }),
                ],
                1
              ),
              _vm.type === "send"
                ? _c(
                    "div",
                    { staticClass: "avatar_group" },
                    [
                      _c("AvatarDetail", {
                        attrs: {
                          type: _vm.type,
                          msg_data: _vm.$tools.getCookie("user"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.type == "receive"
              ? _c("div", { staticClass: "placeholder" })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.is_chat ||
      (_vm.sketch_data.details && _vm.sketch_data.details.length) ||
      _vm.is_normal ||
      _vm.showAuditCoursePackage ||
      _vm.showAuditTask
        ? _c("div", { staticClass: "wrapper_group", class: [_vm.type] }, [
            _vm.type == "send"
              ? _c("div", { staticClass: "placeholder" })
              : _vm._e(),
            _c("div", { staticClass: "container_group" }, [
              _vm.type == "receive"
                ? _c(
                    "div",
                    { staticClass: "avatar_group" },
                    [
                      _c("AvatarDetail", {
                        attrs: { type: _vm.type, msg_data: _vm.msg_data },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content_group", class: [_vm.type] },
                [
                  _vm.type == "receive"
                    ? _c(
                        "div",
                        {
                          staticClass: "username bbox cp",
                          class: { open_at: _vm.chat_type === 1 },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "at",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.atUser()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            点此@" +
                                  _vm._s(_vm.userName) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              "\n             " +
                                _vm._s(_vm.userName) +
                                "\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.is_chat
                    ? [
                        _c("sketch-chat", {
                          attrs: { msg: _vm.msg_data, type: _vm.type },
                        }),
                        _c("div", { staticClass: "tag_group" }, [
                          _c("div", { staticClass: "tag_box" }, [
                            _vm.msg_data.hide
                              ? _c("div", { staticClass: "tag privacy" }, [
                                  _vm._v("私"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.is_normal
                    ? [
                        _vm.msg_data.taskName
                          ? _c(
                              "div",
                              {
                                staticClass: "desc_group cp d-flex",
                                class: { [_vm.type]: true },
                                on: { click: _vm.openTaskBox },
                              },
                              [
                                _vm._m(0),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "desc_title_group d-flex align-center",
                                  },
                                  [
                                    _c("title-status-tag", {
                                      staticClass: "mr-5",
                                      attrs: {
                                        size: "18px",
                                        "process-status": String(
                                          _vm.msg_data.processStatus
                                        ),
                                        "level-status":
                                          _vm.msg_data.levelStatus,
                                      },
                                    }),
                                    _c("div", { staticClass: "desc_title" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.msg_data.taskName) +
                                          "\n              "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.isLinkMe || _vm.type === "send"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "reply cp un_sel",
                                        class: [_vm.type],
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.replyChat()
                                          },
                                        },
                                      },
                                      [_vm._v("回复")]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm.is_create && 0
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "msg_main task",
                                  class: {
                                    delete: _vm.msg_data.deleteFlag,
                                    create: _vm.is_create,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openTaskBox({
                                        go_to_default: true,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c("create-user", {
                                    attrs: {
                                      createtousers:
                                        _vm.msg_data.details[0].createtousers,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "tag_group" }, [
                                _c("div", { staticClass: "tag_box" }, [
                                  _vm.msg_data.taskType == 3
                                    ? _c(
                                        "div",
                                        { staticClass: "tag teaching" },
                                        [_vm._v("教")]
                                      )
                                    : _vm._e(),
                                  _vm.msg_data.hide
                                    ? _c(
                                        "div",
                                        { staticClass: "tag privacy" },
                                        [_vm._v("私")]
                                      )
                                    : _vm._e(),
                                  _vm.msg_data.isremind
                                    ? _c("div", { staticClass: "tag reminder" })
                                    : _vm._e(),
                                ]),
                                _vm.isLinkMe && _vm.type == "receive"
                                  ? _c(
                                      "div",
                                      { staticClass: "attended_group" },
                                      [
                                        _c("div", { staticClass: "icon" }),
                                        _c("div", { staticClass: "text" }, [
                                          _vm._v("与我有关"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          : _vm._e(),
                        [
                          _c(
                            "div",
                            {
                              staticClass: "msg_main task",
                              class: { delete: _vm.sketch_data.deleteFlag },
                              on: {
                                click: function ($event) {
                                  return _vm.openTaskBox({
                                    go_to_default: true,
                                  })
                                },
                              },
                            },
                            [
                              _vm.is_create
                                ? _c("create-user", {
                                    attrs: {
                                      createtousers:
                                        _vm.msg_data.details[0].createtousers,
                                    },
                                  })
                                : _vm._e(),
                              _vm._l(
                                _vm.sketch_data.details,
                                function (item, index) {
                                  return [
                                    index < _vm.max_limit
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "text_line cp un_sel",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.setDesignationControlId(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            index === 0 ||
                                            !(
                                              _vm.sketch_data.details[index - 1]
                                                .conIdP === item.conIdP &&
                                              item.conIdP
                                            )
                                              ? _c("sketch-title", {
                                                  attrs: {
                                                    log: _vm.sketch_data,
                                                    msg: item,
                                                  },
                                                  on: {
                                                    handleAppoint:
                                                      _vm.openAppointTask,
                                                  },
                                                })
                                              : _vm._e(),
                                            _c("sketch-title-change", {
                                              attrs: { msg: item },
                                              on: {
                                                handleAppoint:
                                                  _vm.openAppointTask,
                                              },
                                            }),
                                            _c("sketch-content", {
                                              attrs: { msg: item },
                                              on: {
                                                handleAppoint:
                                                  _vm.openAppointTask,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                              _vm.can_switch_brief
                                ? [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.switch_brief,
                                            expression: "!switch_brief",
                                          },
                                        ],
                                        staticClass: "more_brief",
                                      },
                                      [_vm._v("...")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "switch_brief cp",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.switch_brief ? "收起" : "展开"
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.switch_brief = !_vm.switch_brief
                                          },
                                        },
                                      },
                                      [_vm._v("收起")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "tag_group" }, [
                            _c("div", { staticClass: "tag_box" }, [
                              _vm.msg_data.taskType === 3
                                ? _c("div", { staticClass: "tag teaching" }, [
                                    _vm._v("教"),
                                  ])
                                : _vm._e(),
                              _vm.msg_data.hide
                                ? _c("div", { staticClass: "tag privacy" }, [
                                    _vm._v("私"),
                                  ])
                                : _vm._e(),
                              _vm.msg_data.isremind
                                ? _c("div", { staticClass: "tag reminder" })
                                : _vm._e(),
                            ]),
                            _vm.isLinkMe && _vm.type === "receive"
                              ? _c("div", { staticClass: "attended_group" }, [
                                  _c("div", { staticClass: "icon" }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("与我有关"),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                      ]
                    : _vm._e(),
                  _vm.showAuditCoursePackage
                    ? [
                        _c("AuditCoursePackage", {
                          attrs: { "msg-data": _vm.msg_data },
                        }),
                      ]
                    : _vm._e(),
                  _vm.showAuditTask
                    ? [_c("AuditTask", { attrs: { "msg-data": _vm.msg_data } })]
                    : _vm._e(),
                ],
                2
              ),
              _vm.type == "send"
                ? _c(
                    "div",
                    { staticClass: "avatar_group" },
                    [
                      _c("AvatarDetail", {
                        attrs: {
                          type: _vm.type,
                          msg_data: _vm.$tools.getCookie("user"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.type == "receive"
              ? _c("div", { staticClass: "placeholder" })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.weaken_data
        ? [_c("weaken-content", { attrs: { msg: _vm.weaken_data } })]
        : _vm._e(),
      _vm.is_work
        ? [_c("weaken-content", { attrs: { type: "work", msg: _vm.msg_data } })]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "color_group" }, [
      _c("div", { staticClass: "color" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }