/*
 * @Author       : Hugo
 * @Date         : 2021-03-31 11:59:07
 * @LastEditTime: 2022-05-19 20:01:45
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/api/radar.js
 * @Description  : 雷达图api
 * @^_^          : 
 */


const radar = {
  /**
   * 获取雷达图维度(多个变量)
   * @param {*} data 
   * @returns {Array} indicator
   */
  getRadarIndcator:(data)=>{
    const {humanrelations,humanrelationsmax,knowledge,knowledgemax,taskactive,taskactivemax,teamnum,teamnummax,workefficiency,workefficiencymax,workingpressure,workingpressuremax} = data || {};
    let indicator = [
      {name: '团队参与活跃指数', max: Number(teamnummax)},
      {name: '知识学习量指数', max: Number(knowledgemax)},
      {name: '任务完成效率指数', max: Number(workefficiencymax)},
      {name: '工作压力指数', max: Number(workingpressuremax)},
      {name: '任务处理活跃指数', max: Number(taskactivemax)},
      {name: '人际关系指数', max: Number(humanrelationsmax)},
    ]
    return indicator;
  },
  /**
   * 获取雷达维度数据
   * @param {*} data 
   * @returns 
   */
  getSeriesData: (data)=>{
    const {humanrelations,humanrelationsmax,knowledge,knowledgemax,taskactive,taskactivemax,teamnum,teamnummax,workefficiency,workefficiencymax,workingpressure,workingpressuremax} = data || {};
    let seriesData = [
      {
        value: [Number(teamnum), Number(knowledge), Number(workefficiency), Number(workingpressure), Number(taskactive), Number(humanrelations)],
        name: '我的能力成长数据',
      }
    ]
    return seriesData;
  }
}

export default radar;