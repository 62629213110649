var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_popup_task_chat_context_index_page bbox" },
    [
      _c(
        "div",
        {
          staticClass: "mask d-flex align-center justify-center",
          style: { zIndex: _vm.index },
        },
        [
          _c("div", { staticClass: "container bbox d-flex flex-column" }, [
            _c("div", { staticClass: "header_group flex-shrink-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "title_group d-flex align-center un_sel justify-center",
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("查看上下文")]),
                  _c("div", {
                    staticClass: "close cp",
                    on: {
                      click: function ($event) {
                        return _vm.doClose()
                      },
                    },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "main d-flex flex-column" }, [
              _c(
                "div",
                { ref: "wrapper", staticClass: "wrapper bbox px-10" },
                [
                  _c("main-section", {
                    attrs: {
                      chat_id: _vm.chat_id,
                      chat_type: _vm.chat_type,
                      start_data: _vm.item,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }