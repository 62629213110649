import userDetail from './user_detail';
class Plugin{
  constructor(){
    this.userDetail = userDetail;
  }
  userDetail = this.userDetail;
}

const plugin = new Plugin();

export default plugin;