class TaskInfo {
  buy
  cancelflag
  chatId
  chatName
  chatType
  checkstatus
  collectionflag
  colour
  controls
  createTime
  createusername
  createuserthumbnail
  deleteDays
  deleteFlag
  draft
  endTime
  from
  fromchat
  hide
  id
  inFlag
  infolderflag
  introduction
  isremind
  level
  levelStatus
  locknum
  name
  packageId
  packageTitle
  preTaskId
  preuserid
  preusername
  price
  processStatus
  readNum
  readamount
  reason
  sale
  showTime
  showflag
  signUserId
  startTime
  tags
  taskDetail
  taskType
  taskchatnum
  tasks
  teachId
  teachType
  thumbnail
  tousers
  updateContent
  updateTime
  updateUserId
  updateusername
  updateuserthumbnail
  userId
  userName

  constructor (params) {
    const {
      buy,
      cancelflag,
      chatId,
      chatName,
      chatType,
      checkstatus,
      collectionflag,
      colour,
      controls,
      createTime,
      createusername,
      createuserthumbnail,
      deleteDays,
      deleteFlag,
      draft,
      endTime,
      from,
      fromchat,
      hide,
      id,
      inFlag,
      infolderflag,
      introduction,
      isremind,
      level,
      levelStatus,
      locknum,
      name,
      packageId,
      packageTitle,
      preTaskId,
      preuserid,
      preusername,
      price,
      processStatus,
      readNum,
      readamount,
      reason,
      sale,
      showTime,
      showflag,
      signUserId,
      startTime,
      tags,
      taskDetail,
      taskType,
      taskchatnum,
      tasks,
      teachId,
      teachType,
      thumbnail,
      tousers,
      updateContent,
      updateTime,
      updateUserId,
      updateusername,
      updateuserthumbnail,
      userId,
      userName,
    } = params ?? {}

    this.buy = buy
    this.cancelflag = cancelflag
    this.chatId = chatId
    this.chatName = chatName
    this.chatType = chatType
    this.checkstatus = checkstatus
    this.collectionflag = collectionflag
    this.colour = colour
    this.controls = controls
    this.createTime = createTime
    this.createusername = createusername
    this.createuserthumbnail = createuserthumbnail
    this.deleteDays = deleteDays
    this.deleteFlag = deleteFlag
    this.draft = draft
    this.endTime = endTime
    this.from = from
    this.fromchat = fromchat
    this.hide = hide
    this.id = id
    this.inFlag = inFlag
    this.infolderflag = infolderflag
    this.introduction = introduction
    this.isremind = isremind
    this.level = level
    this.levelStatus = levelStatus
    this.locknum = locknum
    this.name = name
    this.packageId = packageId
    this.packageTitle = packageTitle
    this.preTaskId = preTaskId
    this.preuserid = preuserid
    this.preusername = preusername
    this.price = price
    this.processStatus = processStatus
    this.readNum = readNum
    this.readamount = readamount
    this.reason = reason
    this.sale = sale
    this.showTime = showTime
    this.showflag = showflag
    this.signUserId = signUserId
    this.startTime = startTime
    this.tags = tags
    this.taskDetail = taskDetail
    this.taskType = taskType
    this.taskchatnum = taskchatnum
    this.tasks = tasks
    this.teachId = teachId
    this.teachType = teachType
    this.thumbnail = thumbnail
    this.tousers = tousers
    this.updateContent = updateContent
    this.updateTime = updateTime
    this.updateUserId = updateUserId
    this.updateusername = updateusername
    this.updateuserthumbnail = updateuserthumbnail
    this.userId = userId
    this.userName = userName
  }
}

export default TaskInfo
