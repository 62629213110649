<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      width="60%"
      max_width="500px"
    >
      <div class="v_pc_gift_card_plugins_confirm_purchase d-flex flex-column"
      >
        <common-title
          class        = "flex-shrink-0 title_common"
          @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
          border-radius  = "10px"
          background_color = "#f8f8f8"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          :data="data"
          :backup="backup"
          :pcard="pcard"
          :gift_card="gift_card"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from './Main.vue'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      title: '',
      onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      data: undefined,
      backup: undefined,
      pcard: undefined,
      gift_card: undefined,
    }
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_plugins_confirm_purchase{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .title_common{
    width: 100%;
    height: 50px;
    background: #fff;
    position: relative;
    .title_group{
      position: relative;
      background-color: transparent;
      width: 100%;
      padding: 0 20px;
      text-align: center;
      .title{
        width: 100%;
        position: relative;
      }

    }
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
