/*
 * @Author       : Hugo
 * @Date         : 2021-11-26 12:13:15
 * @LastEditTime : 2021-12-01 16:47:05
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/WebIM/module/common.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main';
class Common{
  /**
   * 将tousers转换成linkme
   */
  tousersConvertLinkme(params={}){
    const {tousers} = params;
    if(!tousers){
      return false;
    }
    const user_id = vue.$tools.getUserId();
    const is_in = `,${tousers},`.indexOf(`,${user_id},`);
    if(is_in !== -1){
      return true
    }
    return false;

  }
}

const common = new Common();

export default common;