<!--
 * @Author       : Hugo
 * @Date         : 2020-09-21 11:13:23
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-10-10 11:42:58
 * @Description  : 选择地区
 * @FilePath     : \miaohang\src\components\Module\SelectArea.vue
-->

<template>
  <div class="select_area_page" @click="pageHandler">
    <div class="title_group">
      <div
        class="select province on cp"
        @click.stop="step = 1; $store.commit('set_city', null)"
      >{{province ? (province.province ? province.province : '省/直辖市') :'省/直辖市'}}</div>
      <div
        class="select city cp"
        :class="{on: city!=null && city.city!=null, selected: city!=null&& city.city!=null}"
        @click.stop="step = 2"
      >{{city ? (city.city ? city.city : '请选择市') : '请选择市'}}</div>
      <div class="select county cp">{{county}}</div>
      <div class="close cp" @click.stop="$emit('closeSelectArea', true)"></div>
    </div>
    <div class="content_group">
      <template v-if="step == 1 && province_list">
        <div
          class="item cp"
          :class="{select: item.select}"
          v-for="(item,index) in province_list"
          :key="index"
          @click.stop="selectProvince(item)"
        >{{item.province}}</div>
      </template>
      <template v-if="step == 2 && city_list">
        <div
          class="item cp"
          :class="{select: item.select}"
          v-for="(item,index) in city_list"
          :key="index"
          @click.stop="selectCity(item)"
        >{{item.city}}</div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      // province: null,
      // city: null,
      // county: '',
      // province_list: [],
      // city_list: [],
      step: '1',
    }
  },
  computed:{
    province(){
      return this.$store.state.province;
    },
    city(){
      return this.$store.state.city;
    },
    province_list(){
      return this.$store.state.province_list;
    },
    city_list(){
      return this.$store.state.city_list;
    },
    county(){
      return this.$store.state.county;
    },
  },
  watch:{
    province: {
      handler: async function(item, old){
        if(item){
          // 不存在 || 新 != 旧
          if(!old || item.provinceCoder != old.provinceCoder){
            this.$store.commit('set_city', null);
            this.$store.commit('set_city_list', []);
            // this.$set(this, 'city_list', null);
            await this.getCity(item.provinceCoder);
            // let {province_list} = this;
            let province_list = this.$tools.deepClone(this.province_list);
            if(province_list){
              let index = province_list.findIndex( unit => {
                return unit.provinceCoder == item.provinceCoder;
              })
              province_list[index].select = true;
              // this.$set(this.province_list[index], 'select', true);
              if(old){
                let oldIndex = province_list.findIndex( oldunit => {
                  return oldunit.provinceCoder == old.provinceCoder;
                })
                if(province_list[oldIndex]) {
                  province_list[oldIndex].select = false;
                }
                // this.$set(this.province_list[oldIndex], 'select', false);
              }
              this.$store.commit('set_province_list', province_list);
            }
          }
        }
      },
      deep: true,
    },
    city: {
      handler: async function(newval, oldval){
        if(newval != null){
          // let {city_list} = this;
          let city_list = this.$tools.deepClone(this.city_list);
          if(city_list){

            let index = city_list.findIndex( unit => {
              return unit.coder == newval.coder;
            })
            city_list[index].select = true;
            // this.$set(this.city_list[index], 'select', true);
            if(oldval){
              let oldIndex = city_list.findIndex( unit => {
                return unit.coder == oldval.coder;
              })
              if(city_list[oldIndex]) {
                city_list[oldIndex].select = false;
              }
              // this.$set(this.city_list[oldIndex], 'select', false);
            }
            this.$store.commit('set_city_list', city_list);
          }
        }else{
          let city_list = this.$tools.deepClone(this.city_list);
          if(city_list){

            city_list.forEach(item=>{
              if(item.select){
                item.select = false;
              }
            })
            // this.$set(this.city_list[index], 'select', true);
            if(oldval){
              let oldIndex = city_list.findIndex( unit => {
                return unit.coder == oldval.coder;
              })
              if(city_list[oldIndex]) {
                city_list[oldIndex].select = false;
              }
              // this.$set(this.city_list[oldIndex], 'select', false);
            }
            this.$store.commit('set_city_list', city_list);
          }
        }
      },
      deep: true,
    },
    province_list: {
      handler: function(){},
      deep: true,
    },
    city_list: {
      handler: function(){},
      deep: true,
    },
  },
  methods:{
    async getProvince(){
      let res = await this.$http.request('/project/getProvinceList')
      // this.$set(this, 'province_list', res.contents.provincelist);
      this.$store.commit('set_province_list', res.contents.provincelist);
    },
    async getCity(id){
      let res = await this.$http.request('/project/getCitysByProvince',{provinceCoder: id});
      // this.$set(this, 'city_list', res.contents.citylist);
      this.$store.commit('set_city_list', res.contents.citylist);
    },
    selectProvince(item){
      this.step = 2;
      item = this.$tools.deepClone(item);
      this.$store.commit('set_province', item);
    },
    selectCity(item){
      item = this.$tools.deepClone(item);
      this.$store.commit('set_city', item);
      //  关闭
      this.$emit('closeSelectArea', true);
    },
    pageHandler(e){
      e.stopPropagation();
      return;
    },
    // async getCounty(id){
    //   let res = await this.$http.request('/project/getCitysByProvince',{provinceCoder: id});
    //   this.list = res.citylist;
    // }
    async init(){
      let {province, city} = this;
      if(!this.province_list.length){
        await this.getProvince();
        this.$nextTick(function(){
          let province_list = this.$tools.deepClone(this.province_list);
          if(province){
            let index = province_list.findIndex( unit => {
              return unit.provinceCoder == province.provinceCoder;
            })
            if(province_list[index]){
              province_list[index].select = true;
            }
            this.$store.commit('set_province_list', province_list);
            // this.$set(this.province_list[index], 'select', true);
          }
        })
      }
     
        
      if(province && city && province.province){
        this.step = 2;
        await this.getCity(province.provinceCoder);
        this.$nextTick(function(){
          if(city){
            let city_list = this.$tools.deepClone(this.city_list);
            let index = city_list.findIndex( unit => {
              return unit.coder == city.coder;
            })
            if(city_list[index]){
              city_list[index].select = true;
            }
            this.$store.commit('set_city_list', city_list);
            // this.$set(this.city_list[index], 'select', true);
          }
        })
      }
     
       
      
    }
  },
  async mounted(){
    // this.getProvince();
    // this.getCity(12)
    // if(!this.province){
    //   this.getProvince();
    // }
    this.init();
  }
}
</script>
<style lang="scss" scoped>
.select_area_page{
  @include bbox;
  width: 630px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .title_group{
    @include flex;
    @include bbox;
    padding: 0 60px;
    width:100%;
    position: relative;
    &::after{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      height: 2px;
      background-color: #f4f4f4;
      z-index: 2;
    }
    .select{
      @include bbox;
      @include transition;
      position: relative;
      z-index: 3;
      height: 60px;
      line-height: 58px;
      border-bottom: 2px solid transparent;
      padding: 0 30px;
      color:$main_blue;
      margin-right: 10px;
      &:first-child{
        color: #111;
      }
      &.on{
        border-bottom-color: $main_blue;
      }
      &.selected{
        color: #111;
      }
    }
    .close{
      @include background(12px, 12px);
      position: absolute;
      top: 50%;
      right: 20px;
      background-image: url(~@/assets/images/common/close.png);
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }
  }
  .content_group{
    @include flex;
    @include bbox;
    align-items: flex-start;
    width: 100%;
    padding: 15px 58px;
    flex-wrap: wrap;
    .item{
      @include bbox;
      @include transition;
      width:25%;
      text-align: center;
      text-align-last: center;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 5px;
      color:#555;
      &:hover{
        background: #F8F8F8;
        border-radius: 4px;
      }
      &.select{
        background-color: $main_blue;
        color:#fff;
        border-radius: 4px;
      }
    }
  }

}
</style>