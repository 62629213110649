var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_pop_up_at_search_index bbox" }, [
    _c("div", { staticClass: "search_group bbox d-flex align-center px-10" }, [
      _c("div", { staticClass: "icon" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search",
          },
        ],
        staticClass: "search bbox px-10",
        attrs: { type: "text", placeholder: "搜索" },
        domProps: { value: _vm.search },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.doSearch()
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.search = $event.target.value
          },
        },
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.search.trim() !== "",
            expression: "search.trim() !== ''",
          },
        ],
        staticClass: "clear cp",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doClear()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }