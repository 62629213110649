<!--
 * @Author       : Hugo
 * @Date         : 2021-11-29 11:14:57
 * @LastEditTime : 2021-11-29 15:38:54
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/At/Search.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_at_search_index bbox"
  > 
    <div class="search_group bbox d-flex align-center px-10">
      <div class="icon"></div>
      <input
        type="text"
        class="search bbox px-10"
        placeholder="搜索"
        v-model="search"
        @keydown.enter="doSearch()"
      >
      <div class="clear cp"
        v-show="search.trim() !== ''"
        @click.stop="doClear();"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      search: '',
    }
  },
  watch: {
    search: function(val){
      if(val === ''){
        this.$emit('handleSearch', '');
      }
    }
  },
  methods: {
    doSearch(){
      const {search} = this;
      this.$emit('handleSearch', search.trim())
    },
    doClear(){
      this.search = '';
      this.$emit('handleSearch', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_at_search_index{
  width: 100%;
  .search_group{
    height          : 30px;
    background-color: #F0F0F0;
    border-radius   : 4px;
    position: relative;
    z-index: 2;
  }
  .icon{
    width           : 13px;
    height          : 13px;
    background-image: url(~@/assets/images/pop_up/search.png);
  }
  .search{
    @include placeholder(#82929C);
    flex            : 1;
    font-size       : 13px;
    line-height     : 30px;
    border          : none;
    outline         : none;
    background-color: transparent;
    padding-right   : 30px;
  }
  .clear{
    @include background(15px, 15px);
    width     : 20px;
    height    : 20px;
    background: {
      image: url(~@/assets/images/pop_up/clear.png);
    }
    position : absolute;
    right    : 10px;
    top      : 50%;
    transform: translateY(-50%);
    z-index  : 3;

  }

}
</style>