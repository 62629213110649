<!--
 * @Author       : Hugo
 * @Date         : 2022-04-25 14:26:59
 * @LastEditTime : 2022-04-26 14:29:28
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Invivation/Plugins/Poster/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_invivation_plugins_poster bbox" 
  >
    <div class="rebuild_group">
      <div class="button d-flex align-center rebuild justify-center">
        <img
          src="~@/assets/images/invivation_poster/send_email.png"
          alt=""
          class="send_email mr-6"
        >
        <div class="text">生成新链接/海报</div>
      </div>
    </div>
    <div class="type_group d-flex align-center">
      <img src="~@/assets/images/invivation_poster/key.png" alt="" class="icon">
      <div class="type_title web">邀请朋友通过网页注册</div>
    </div>
    <div class="web_copy_group bbox d-flex justify-space-between align-center">
      <div class="text">点击【复制内容】，将内容发送给好友</div>
      <div class="button">复制内容</div>
    </div>
    <div class="hyperlink bbox">
      妙行用户 XXX 邀请您注册妙行，请访问 http://www.yourutec.com/xxxxxsdfsfsdfxxx 进入妙行进行注册（有效期至：2022-09-29 14:34），链接72小时内有效，请及时注册。
    </div>
    <div class="hyperlink_hint bbox">*请注意，此访问链接72小时内有效，72小时后点击邀请按钮会重新生成</div>
    <div class="type_group d-flex align-center">
      <img src="~@/assets/images/invivation_poster/scan.png" alt="" class="icon">
      <div class="type_title img">邀请朋友通过手机扫码注册</div>
    </div>
    <div class="poster_group d-flex align-stretch un_sel">
      <poster-component
        class="poster"
      ></poster-component>
      <div class="operation_group d-flex flex-column justify-end align-start bbox">
        <div class="button btn_dark d-flex align-center justify-center">
          <img src="~@/assets/images/invivation_poster/save.png" alt="" class="icon mr-6">
          <div class="text">保存海报</div>
        </div>
        <div class="button btn_light d-flex align-center justify-center">
          <img src="~@/assets/images/invivation_poster/copy.png" alt="" class="icon mr-6">
          <div class="text">复制海报</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PosterComponent from '@/components/Invivation/Modules/Poster/index.vue'
export default {
  components: {
    PosterComponent,
  }
}
</script>

<style lang="scss" scoped>
.c_invivation_plugins_poster{
  width: 100%;
  padding: 0 40px 30px;
  .rebuild_group{
    padding-top: 20px;
    .rebuild{
      width: 230px;
      height: 50px;
      background: #F7FCFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 84, 167, 0.16);
      border-radius: 10px;
      border: 1px solid #D6E0ED;
      font-size: 16px;
      color: $main_blue;
    }
  }
  .type_group{
    padding-top: 30px;
    .icon{
      margin-right: 8px;
    }
    .type_title{
      font-size: 18px;
      font-weight: bold;
      &.web{
        color: #0054A7;
      }
      &.img{
        color: #0D6E8F;
      }
    }
  }
  .web_copy_group{
    padding-top: 20px;
    .text{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .button{
      line-height: 26px;
      background-color: #E5EDF6;
      border-radius: 4px;
      font-size: 12px;
      color: $main_blue;
      padding: 0 12px;
    }
  }
  .hyperlink{
    padding: 10px 16px;
    margin-top: 10px;
    word-break: break-all;
    font-size: 14px;
    color: #555555;
    line-height: 20px;
    background: #FBFBFB;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
  }
  .hyperlink_hint{
    padding-top: 10px;
    font-size: 14px;
    color: #666666;
  }
  .poster_group{
    margin-top: 20px;
    .operation_group{
      padding-bottom: 20px;
      padding-left: 30px;
      .button{
        margin-top: 10px;
        width: 126px;
        line-height: 40px;
        border-radius: 4px;
        font-size: 14px;
      }
    }
    .poster{
      box-shadow: 0px 0px 10px 0px rgba(13, 110, 143, 0.16);
    }
  }
}
</style>
