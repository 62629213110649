var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "audit_task bbox_wrapper cp un_sel",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.touchTask.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "info_group d-felx align-start justify-space-between" },
        [
          _c("div", { staticClass: "name_group" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("\n        " + _vm._s(_vm.msgData.taskName) + "\n      "),
            ]),
            _c("div", {
              staticClass: "subtitle",
              domProps: { innerHTML: _vm._s(_vm.msgData.work) },
            }),
          ]),
          _c("div", { staticClass: "icon flex-shrink-0" }),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "status_wrapper d-flex align-center justify-space-between",
        },
        [
          _c(
            "div",
            {
              staticClass: "status_group d-flex align-center",
              class: [_vm.checkClassName],
            },
            [
              _vm.showCheckStatusIcon
                ? _c("div", { staticClass: "icon" })
                : _vm._e(),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n        " + _vm._s(_vm.checkText) + "\n      "),
              ]),
            ]
          ),
          _c("div", { staticClass: "tag_group" }, [
            _vm._v("\n      任务\n    "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }