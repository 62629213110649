var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_payment_buy_now_number bbox d-flex align-center",
    },
    [
      _c("div", {
        staticClass: "button minus",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doMinus()
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input_number,
            expression: "input_number",
          },
        ],
        staticClass: "number mx-6",
        attrs: { type: "text" },
        domProps: { value: _vm.input_number },
        on: {
          change: function ($event) {
            return _vm.doChange()
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.input_number = $event.target.value
          },
        },
      }),
      _c("div", {
        staticClass: "button plus",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.doPlus()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }