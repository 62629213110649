var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create_user_wrapper d-flex align-center cp" },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          index < 7
            ? _c(
                "div",
                { key: index, staticClass: "flex-shrink-0 avatar_wrapper" },
                [
                  item.thumbnail
                    ? _c("el-image", {
                        staticClass: "avatar",
                        attrs: {
                          src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                          fit: "cover",
                        },
                      })
                    : _c("el-image", {
                        staticClass: "avatar",
                        attrs: {
                          src: require(`@/assets/${_vm.$default_avatar}`),
                          fit: "cover",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _vm.list.length > 7 ? _c("div", { staticClass: "more cp" }) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }