var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_payment_scan_code_index d-flex flex-column align-center",
    },
    [
      _c(
        "div",
        { staticClass: "img_wrapper d-flex align-center justify-center" },
        [
          _c("img", {
            staticClass: "img",
            attrs: {
              src: `${_vm.domain}/code/twoCode?width=150&text=${_vm.codeUrl}`,
              alt: "",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "info_group pt-10 d-flex align-center" }, [
        _c("div", { staticClass: "icon wechat mr-10" }),
        _c("div", { staticClass: "text" }, [
          _vm._v("扫码支付" + _vm._s(_vm.price) + "元"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }