/*
 * @Author       : Hugo
 * @Date         : 2022-04-22 10:11:01
 * @LastEditTime : 2022-04-22 10:14:33
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PGift/index.js
 * @Description  : 
 * @^_^          : 
 */
import {PTask} from '@/types'

class PGift{
  createTime = undefined //: 1649990886000
  gifttype = undefined //: 1
  id = undefined //: 1
  name = undefined //: "个人测试模板23去"
  paramId = undefined //:,
  task = undefined //:

  constructor(params = {}){
    const {
      createTime,
      gifttype,
      id,
      name,
      paramId,
      task,
    } = params||{}
    this.createTime = createTime
    this.gifttype = gifttype
    this.id = id
    this.name = name
    this.paramId = paramId
    if((task??'')!==''){
      this.task = new PTask(task)
    }else{
      this.task = task
    }
  }
  
}

export default PGift