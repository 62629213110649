<!--
 * @Author       : Hugo
 * @Date         : 2021-10-25 15:57:50
 * @LastEditTime : 2021-10-28 15:07:23
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/WorkgroupContext/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
   <div
    class="components_popup_task_chat_context_index_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <div class="header_group  flex-shrink-0">
          <div class="title_group d-flex align-center un_sel justify-center">
            <div class="title">查看上下文</div>
            <div class="close cp"
              @click="doClose();"
            ></div>
          </div>

        </div>
        <div class="main d-flex flex-column">
          <div class="wrapper bbox px-10"
            ref="wrapper"
          >
            <main-section
              :chat_id="chat_id"
              :chat_type="chat_type"
              :start_data="item"
            ></main-section>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import MainSection from './Main.vue';
export default {
  components: {
    MainSection,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    task_id: {
      type: Number,
      default: null,
    },
  },
  data(){
    return {
      index: 1,
      list: [],

    }
  }, 
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    // const chat_type = this.$tools.getChatType();
    // const chat_id = this.$api.moduleTask.getRouteParamsId();
    // this.chat_type = chat_type;
    // this.chat_id = chat_id;
    // this.$refs.wrapper.addEventListener(
    //   'scroll',
    //   this.handleScroll,
    //   false,
    // )
    // this.getList();
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    handleSearchKeydown(e){
      const is_chinese = this.$api.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }
      this.doSearch();
    },
    doSearch(){
      const {search} = this;
      if(search !== ''){
        this.keyword = search;
      }
      this.do_search = true;
    },
    handleScroll(){
      const {scrollTop, clientHeight, scrollHeight} = this.$refs.wrapper;
      let trigger_loadmore = false;
      if((scrollHeight - scrollTop -clientHeight) < 100){
        trigger_loadmore = true;
      }
      // // console.log(scrollHeight - scrollTop - clientHeight);
      // this.wrapper_top = this.$refs.wrapper.scrollTop;
      this.trigger_loadmore = trigger_loadmore;
    },
    async getList(){
      this.loading = true;
      const {chat_id, chat_type, task_id, list, item} = this;
      if(isNaN(chat_id)){
        return;
      }
      if(isNaN(chat_type)){
        return;
      }
      if(chat_type == -1){
        return;
      }
      const res = await this.$global.doPost({
        url: '/projectTaskLogSearch/searchTaskLogTextPage',
        data: {
          chatType: chat_type,
          chatId: chat_id,
          startId: item.id,
          taskId: task_id,
        }
      });
    }
    
  }
}
</script>

<style lang="scss" scoped>
.components_popup_task_chat_context_index_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    width: 100%;
    height: 100%;
    max-width: 1400px;
    max-height: 900px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
    overflow: hidden;
  }
  .header_group{
    width: 100%;
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    .title{
      padding-left: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    background-color: #f8f8f8;
    width: 100%;
    // height: 100%;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    padding: 40px 0;
    flex: 1;
    .wrapper{
      // @include scrollbar;
      width: 100%;
      height: 100%;
      // overflow: auto;
    }
    .section{
      padding: 0 40px;
    }
  }
  .search_group{
    position: relative;
    width: 100%;
    padding: 0 40px;
    .search{
      @include transition;
      width: 100%;
      outline: none;
      border: 1px solid #909090;
      border-radius: 4px;
      line-height: 30px;
      padding: 0 36px;
      font-size: 14px;
      color: #333;
      @include placeholder(#aaa);
      &:focus{
        border: 1px solid $main_blue;
      }
    }
    .icon{
      @include background;
      position: absolute;
      top: 50%;
      left: 56px;
      width: 12px;
      height: 12px;
      background-image: url(~@/assets/images/common/search_icon.png);
      transform: translateY(-50%);
    }
  }
  .tab_group{
    padding: 0 40px;
  }
  .tab_bar{
    .tab{
      @include transition;
      cursor: pointer;
      line-height: 50px;
      border-bottom: 3px solid transparent;
      font-size: 14px;
      color: #333;
      margin-right: 80px;
      &:last-child{
        margin-right: 0;
      }
      &.active{
        color: $main_blue;
        border-bottom-color: $main_blue;
      }
    }
  }
  
}
</style>