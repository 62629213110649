import PluginComponent from '@/components/Plugin/Modules/MigrateTask/SelectTask.vue'

const PLUGIN_NAME = 'MigrateTaskSelectTask'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype[`$open${PLUGIN_NAME}`] = (params={}) => {
      // // console.log(obj)
      // const { title } = params
      // instance.title = title ?? '任务迁移'
      params.title = '任务迁移'
      params.checkSelected = false
      instance.params = params
      // instance.pcard = pcard
      // instance.gift_card = gift_card
      instance.visible = true
    }
    Vue.prototype[`$close${PLUGIN_NAME}`] = () => {
      instance.visible = false
    }
  }
}

export default PluginMember