<template>
  <div
    class="chat_task_title d-flex align-center"
  >
    <title-status-tag
      class="mr-5"
      size="16px"
      :process-status="String(msg.processStatus)"
      :level-status="msg.levelStatus"
    ></title-status-tag>
    <div class="title">{{msg.taskName}}</div>
  </div>
</template>
<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
export default {
  components: {
    TitleStatusTag
  },
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
}
</script>
<style lang="scss" scoped>
.chat_task_title{
  @include bbox;
  margin-top: 10px;
  width: 100%;
  padding: 7px 10px;
  background: #E7E7E7;
  border-radius: 4px 4px 10px 10px;
  font-size: 12px;
  color: #666;
  line-height: 16px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  // &::before{
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   z-index: 3;
  //   width: 5px;
  //   height: 100%;
  //   background-color: #4C8CEF;
  //   top:0;
  //   left: 0;
  // }
  .title{

  }
}
</style>