var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c_classes_task_modules_main_index bbox d-flex flex-column",
    },
    [
      _c("task-user", {
        staticClass: "flex-shrink-0 task_user_component px-20 border-bottom",
        attrs: {
          large_task: _vm.large_task,
          data: _vm.data,
          backup: _vm.backup,
        },
      }),
      _c("task-title", {
        staticClass: "flex-shrink-0 task_title_component px-20 border-bottom",
        attrs: {
          large_task: _vm.large_task,
          data: _vm.data,
          backup: _vm.backup,
        },
      }),
      0
        ? _c("task-tag", {
            staticClass: "flex-shrink-0 task_tag_component px-20 border-bottom",
            attrs: { large_task: _vm.large_task, task: _vm.task },
          })
        : _vm._e(),
      _c("widget-container", {
        ref: "widget_container",
        staticClass: "widge_container_component",
        attrs: {
          large_task: _vm.large_task,
          data: _vm.data,
          backup: _vm.backup,
          list: _vm.taskDetails,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "call_task_entrance d-flex flex-column align-center justify-center cp bbox",
                  },
                  [
                    _c("img", {
                      staticClass: "un_sel",
                      attrs: {
                        src: require("@/assets/images/task/call_task_entrance.png"),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "text un_sel" }, [
                      _vm._v("调用已有模板 创建全新任务"),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }