/*
 * @Author       : Hugo
 * @Date         : 2021-05-28 14:41:38
 * @LastEditTime : 2021-06-24 12:28:06
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/editTask.js
 * @Description  : 编辑任务
 * @^_^          : 
 */
import vue from '@/main.js';
const  // 需要删除的字段 controls
del_fields= ['imglist','fileList','filelist','delete_list','sort','taskId', 'parent_websort','websort', 'deep_level', 'list', 'modified', 'move', 'origin_data', 'controlLinkList', 'deep_level', 'userList', 'input_link', 'input_desc', 'open_relevancy', 'switch_relevancy'];

const editTask = {
  // 打开任务之前应该将数据处理完毕
  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  async getTaskData({chatId, chatType,}){
    vue.$showLoading({title: '加载中'});
    let route = vue.$route;
    /**
     * @description: 生成初始化chatid
     * @param {*}
     * @return {*}
     */
    let insideGenerateChatId = () => {
      let chat_id = -1;
      let route = vue.$route;
      if(route.name.indexOf('fun_task_dialog') != -1){
        if(route.params.id){
          // chat_id = route.params.id;
          chat_id = vue.$api.moduleTask.getRouteParamsId();
        }
      }
      return chat_id;
    }
    /**
     * @description: 生成初始化chatType
     * @param {*}
     * @return {*}
     */
    let insideGenerateChatType = () => {
      let chat_type = -1;
      let route = vue.$route;
      if(route.name.indexOf('fun_task_dialog') != -1){
        chat_type = vue.$tools.getChatType();
      }
      return chat_type;
    }
    const {task_id, my_task_list, task_call} = vue.$store.state;
    let res = await vue.$api.getTaskDetails({taskId: task_id});
    if(res == false){
      vue.$hideLoading();
      return false;
    }
    let {task, taskDeatails, taskUsers, readtaskUsers} = res;
    // 
    if(chatId == undefined){
      if(insideGenerateChatId() != -1){
        task.chatId = insideGenerateChatId();
      }
    }else{
      task.chatId = chatId;
    }
    if(chatType == undefined){
      if(insideGenerateChatType() != -1){
        task.chatType = insideGenerateChatType();
      }
    }else{
      task.chatType = chatType;
    }
    // 如果是单聊 则将chat_id设置为对方id
    if(chatType == 0){
      let tousers = task.tousers;
      const arr = tousers.split(',');
      arr.forEach(item=>{
        if(item != vue.$tools.getUserId()){
          task.chatId = item;
        }
      })
    }
    // TODO 新增初始化聊天相关
    vue.$store.commit('set_task_pannel', 'control');
    vue.$store.commit('set_task_chat_message_list', []);
    // 初始化价格
    vue.$store.commit('set_teaching_price', null);
    // END 新增初始化聊天相关
    vue.$store.commit('set_switch_teach', false); // 初始化查看实例
    vue.$store.commit('set_hint_teaching', false); // 初始化制作教学状态
    if(task_call){
      // 如果是调用任务, 清除id
      delete task.id;
    }
    vue.$store.commit('set_task_data', task);
    
    vue.$store.commit('set_task_details', taskDeatails);
    vue.$store.commit('set_task_users', taskUsers);
    vue.$store.commit('set_task_read_users', readtaskUsers);
    // 设置群主id
    let task_lord_id = task.userId || vue.$tools.getUserId();
    vue.$store.commit('set_task_lord_id', task_lord_id);
    // 我销售的 直接打开教学面板
    if(route.name == 'fun_template_my' && route.params.type == 'sell'){
      // vue.pannel = 'teaching';
      vue.$store.commit('set_task_pannel', 'teaching');
    }

    /**取消红点 */
    if(my_task_list&&my_task_list.length){
      let index = my_task_list.findIndex( item => {
        return item.id == task_id;
      })
      if(index != -1){
        my_task_list[index].dot = false;
      }
    }
    /**是否有未读消息 */
    if(task.taskchatnum){
      vue.$store.commit('set_task_unreader_chat', true);
      // 打开沟通面板 [打开后置空未读消息]
      vue.$store.commit('set_task_pannel','chat');
    }
    /**分享 && 教学模板 */
    if(vue.is_share && (task.taskType == 2 || task.taskType == 3)){
      vue.$store.commit('set_task_pannel','teaching');
    }
    
    vue.$hideLoading();
    vue.$store.commit('set_switch_task_box', true); //打开
    return {task, taskDeatails, taskUsers, readtaskUsers};
  },
  /**
   * 清理用户选择数据
   */
  clearTaskSelectUsersData(){
    let task_select_users_data = {
      chatId: '',
      chatType: '',
      taskId: '',
      taskType: '',
      // taskUsers: [],
    }
    vue.$store.commit('set_task_select_users_data', task_select_users_data);
  },
  /**
   * 关闭编辑[清除锁] 
   */
  async closeTaskBox(){
    let ids = '';
    const {task_details} = vue.$store.state;
    const userid = vue.$tools.getUserId();
    task_details.forEach( item => {
      if(item.islock && item.userlock == userid){
        ids += ids == '' ? item.id : `,${item.id}`
      }
    })
    // 清除锁
    if(ids != ''){
      await vue.$http.request('/projectTask/loselock', {ids});
    }
    vue.$store.commit('set_switch_task_box', false); // 关闭
    vue.$store.commit('set_task_id', ''); // 清空id
    // vue.clearTaskSelectUsersData(); //清空用户选择数据
  },
  /**
   * 关闭编辑前判断是否有编辑
   * @returns {Boolean} 是否可以关闭
   */
  beforeCloseTaskBox(){
    let status = true;
    const {task_details} = vue.$store.state;
    if(task_details && task_details.length){
      task_details.forEach(item => {
        if(!item.id){
          status = false;
        }
        if(item.modified){
          status = false;
        }
      })
    }
    return status;
  },
  /**
   * @description: 生成任务名
   */
  generateName(){
    let {task_data} = vue.$store.state;
    let {modify_name, name, taskType, bindTeach, id} = task_data;
    let generate_name = '';
    if(!id){
      generate_name = name;
    }
    if(modify_name){
      generate_name = name;
    }
    if(taskType == 2){
      generate_name = name;
    }
    if(bindTeach){
      generate_name = name;
    }
    return generate_name;
  },
  /**
   * @description: 生成taskType
   * 编辑教学不考虑在内
   */
  genereateTaskType(){
    const {task_data} = vue.$store.state;
    const {id, taskType} = task_data;
    // // console.log(taskType, id)
    if(!id){
      if(!taskType){ //新建
        return 1;
      }else if(taskType != 1){ //被清空 并且不是1
        return 3
      }
    }else{
      if(taskType == 2 || taskType == 4){
        return 3;
      }else{
        return taskType
      }
    }
    
    return 1; //默认
  },
  /** 
   * 生成to_users 
   * */
  generateToUsers(){
    let {task_users} = vue.$store.state;
    let to_users = '';
    if(task_users && task_users.length){
      task_users.forEach( item => {
        to_users += to_users == '' ? item.userId : `,${item.userId}`;
      })
    }
    return to_users;
  },
  /**
   * @description: 生成FileJson 以单个控件为单位
   * @params {Object} item 控件数据
   * @params {Object} params {clear: 清除id和fileJson里面的id[用于新建]}
   */
  generateFileJson(item, params){
    let fileJson = [];
    let {clear} = params || {};
    /** 新增 */
    // 附件 item.filelist
    /** 图片附件统一为fileList */
    if(item.fileList){
      item.fileList.forEach( subitem => {
        delete subitem.chatId;
        delete subitem.preview;
        delete subitem.progress;
        delete subitem.selected;
        delete subitem.taskId;
        delete subitem.cancelSource;
        delete subitem.createTime;
        delete subitem.file;
        delete subitem.teach;
        if(clear){
          delete subitem.id;
        }
        subitem.type = 0; // 新增
        let obj = Object.assign(subitem);
        if(!subitem.id){
          fileJson.push(obj);
        }
      })
    }
    // 复制不考虑删除
    if(!clear){
      /** 删除 */
      if(item.delete_list){
        item.delete_list.forEach( subitem => {
          
          if(subitem.id){
            // type = 1 删除
            let obj = subitem;
            obj.type = 1;
            fileJson.push(obj)
          }
        })
      }
    }
    // // console.log(fileJson)
    return fileJson;
  },
  /** 
   * @description: 生成controls
   * @params {Object} params {clear: 清除id和fileJson里面的id[用于新建]}
   */
  generateControls(params){
    let controls = [];
    let {clear} = params || {};
    let {task_details} = vue.$store.state;
    task_details = vue.$tools.deepClone(task_details);
    if(task_details && task_details.length){
      task_details.forEach( (item, index) => {
        item.fileJson = this.generateFileJson(item, {clear});
        item.controlLinkJson = this.generateControlLinkJson(item, {clear}, del_fields);
        let can_push = !item.id || (item.modified && item.islock) || clear; // 新建 || (被编辑 && 有锁)
        // 判断关联里面是否有修改
        if(item.controlLinkList && item.controlLinkList.length){
          // console.log(item.controlLinkList,item.controlLinkList.length)
          let child_index = item.controlLinkList.findIndex(child_item => {
            return !child_item.id || child_item.modified || child_item.moved;
          })
          // console.log(child_index)
          if(child_index != -1){
            item.sortJson = this.generateControlSortJson(item.controlLinkList);
            can_push = true;
          }
        } 
        // 处理未确认的超链接
        if(item.type==7){
          if(item.input_link||item.input_desc){
            let content = '';
            content += item.input_desc || '';
            content += ',,';
            content += item.input_link || '';
            if(item.content){
              content += '||';
              content += item.content;
            }
            item.content = content;
            // console.log(content)
          }

        }
        for(let i in item){
          
          if(del_fields.includes(i)){
            delete item[i]
          }
          if(clear){
            delete item.id;
            delete item.updatenum;
          }
          item.sort = index+1;
        }
        // 添加控件是否被编辑的判断 如果未编辑 不带改数据
        if(can_push){
          controls.push(item);
        }

      })
    }
    // // console.log(controls)
    controls = JSON.stringify(controls);
    return controls;
  },
  /**
   * @description: 生成controlLinkJson
   * @params {Object} item 控件数据
   * @params {Object} params {clear: 清除id和fileJson里面的id[用于新建]}
   */
  generateControlLinkJson(item,params){
    let {clear} = params || {};
    let controlLinkJson = [];
    let sort = 1;
    item= vue.$tools.deepClone(item);
    if(item.controlLinkList && item.controlLinkList.length){
      item.controlLinkList.forEach( unit => {
        unit.fileJson = this.generateFileJson(unit, {clear});
        let can_push = !unit.id || unit.modified  || clear; // 新建 || (被编辑 && 有锁)
        // if((!unit.id || unit.modified)){
        //   controlLinkJson.push(unit);
        // }
        if(unit.type==7){
          if(unit.input_link||unit.input_desc){
            let content = '';
            content += unit.input_desc || '';
            content += ',,';
            content += unit.input_link || '';
            if(unit.content){
              content += '||';
              content += unit.content;
            }
            unit.content = content;
            // console.log(content)
          }
        }
        for(let i in unit){
          if(del_fields.includes(i)){
            delete unit[i]
          }
          if(clear){
            delete unit.id;
            delete unit.updatenum;
          }
        }
        // if(unit.sortJson == null){
        //   unit.sortJson = [];
        // }
        delete unit.sortJson;
        if(unit.controlLinkJson == null){
          unit.controlLinkJson = [];
        }
        unit.sort = sort++;
        // delete unit.sortJson;
        if(can_push){
          controlLinkJson.push(unit);
        }
      })
    }
    return controlLinkJson;
  },
  /**
   * @description: 生成有关联控件的sortJson 
   * @param {*} list
   * @return {*}
   */
  generateControlSortJson(list){
    let sortJson = [];
    // 是否有新增
    let has_new = list.findIndex(item=>{
      return !item.id;
    })
    // 有改变位置的
    let has_moved = list.findIndex(item=>{
      return item.moved;
    })
    // 没有新增或者没有位置改变 返回'[]'
    if(has_new == -1 && has_moved == -1){
      return sortJson;
    }
    list.forEach( (item, index) => {
      if(item.id){
        sortJson.push({sort:0, id:item.id});
      }else{
        sortJson.push({sort: index+1, id: 0});
      }
    })
    return sortJson;
  },
  /**生成 sortJson
   * 格式[{sort:0, id: 0},...] sort/id有一个有值另外一个为0
   */
  generateSortJson(){
    let sortJson = [];
    let {task_details} = vue.$store.state;
    // 是否有新增
    let has_new = task_details.findIndex(item=>{
      return !item.id;
    })
    // 有改变位置的
    let has_moved = task_details.findIndex(item=>{
      return item.moved;
    })
    // 没有新增或者没有位置改变 返回'[]'
    if(has_new == -1 && has_moved == -1){
      return JSON.stringify(sortJson);
    }
    task_details.forEach( (item, index) => {
      if(item.id){
        sortJson.push({sort:0, id:item.id});
      }else{
        sortJson.push({sort: index+1, id: 0});
      }
    })
    return JSON.stringify(sortJson);
  },
  /**
   * @description: 附件是否全部上传完毕?
   */
  isAllUpload(){
    let task_details = vue.$tools.deepClone(vue.$store.state.task_details);
    let isAllUpload = true;
    // console.log(task_details);
    if(task_details){
      task_details.forEach( item => {
        /** 新增 */
        // 图片 item.imglist
        // if(item.imglist){
        //   item.imglist.forEach( subitem => {
        //     if(subitem.progress != 100){
        //       isAllUpload = false;
        //     }
        //   })
        // }
        // 附件 item.filelist
        if(item.fileList){
          item.fileList.forEach( subitem => {
            if(subitem.progress != 100){
              isAllUpload = false;
            }
          })
        }
      })
      // console.log(isAllUpload)
      return isAllUpload;
    }
    return true;
  },
  beforeSaveDraft(){
    let {task_data} = vue.$store.state;
    /** 有附件未上传完 */
    if(!this.isAllUpload()){
      // this.$toast({icon:'none',title:'提示',desc:'请等待附件上传完再试',duration:1000})
      vue.$notice({desc:'请等待附件上传完再试'})
      return false;
    }
    if(!task_data.name){
      // console.log('请输入标题')
      // this.$toast({icon:'none',title:'提示',desc:'请输入模板标题',duration:1000})
      vue.$notice({desc:'请输入模板标题'})
      return false;
    }
    return true;
   
  },
  async saveDraftColor(color){
    const {task_data} = vue.$store.state;
    const {id} = task_data;
    let res;
    let data = {
      taskId: id,
    };
    
    data.colour = color;
    if(!id){
      return;
    }
    try{
      res = await vue.$http.request('/projectTask/setcolour', data);
      if(res.message == 'success'){
        // this.changeMyTaskList({taskId: id, fieldName:'colour', fieldValue: color});
        // this.changeTemplateMyList({taskId: id, fieldName:'colour', fieldValue: color});
      }else{
        // this.$notice({desc: '颜色标记设置失败'});
      }
    }catch(err){
      // console.log(err);
    }
  },
  /**
   * 
   * @param {color: String} {color: 颜色值, edit_status:编辑状态, draft_edit_status: 草稿编辑状态} 
   */
  async doSaveDraft({color, edit_status, draft_edit_status}){
    // console.log({color, edit_status})
    // 判断是否已经是草稿
    const {task_data, teaching_price} = vue.$store.state;
    const userid = vue.$tools.getUserId();
    // console.log(task_data);
    const {draft, name, teachId, signUserId, buy, id} = task_data;
    // 已经是草稿
    if(draft === 1){
      // TODO 设置颜色
      this.saveDraftColor(color);
    }
    vue.$showLoading({text: '保存中'});
    let data = {
      name: this.generateName(),
      // to_users: this.generateToUsers(),
      // chat_type: this.generateChatType(),
      // chat_id: this.generateChatId(),
      controls: this.generateControls(),
      task_type: this.genereateTaskType(),
      // hide: task_data.hide,
    }
    
    let res;
    let url = '';
    if(draft === 1){
      // 编辑草稿
      url='/projectDraft/draftEdit';
      data.taskId = id;
      if(teaching_price){
        data.price =  Number(teaching_price) * 100;
      }
      data.sortJson = this.generateSortJson();
    }else{
      // 创建草稿
      url='/projectDraft/draftCreate';
      data.name = name;
      if(color){
        data.color = color;
      }
      // 教学判断
      // // console.log(teachId ,buy,signUserId,userid , draft_edit_status);
      // return;
      if(( (teachId && buy)  || (signUserId == userid) ) || draft_edit_status){
        data.task_type = 2;
        data.price =  Number(teaching_price) * 100;
      }else{
        data.task_type = 1;
      }
      data.controls = this.generateControls({clear: true}); // 清除id
    }

    res = await vue.$global.doPost({
      url,
      data,
      notice: '保存失败',
    })

    if(res.message === 'success'){
      const {contents} = res;
      const {task} = contents;
      if(task && task.id){
        vue.$store.commit('set_draft_id', task.id);
      }
      // vue.$notice({desc: '保存成功', type: 'success' })
    }
    // vue.$hideLoading();
    return res;
  },
  /**
   * 获取颜色值
   * @param {*} color_list 
   * @returns 
   */
  getColor(color_list){
    let color = '';
    if(color_list && color_list.length){
      color_list.forEach(item=>{
        if(item.type){
          color += color ? ',':'';
          color += item.color;
        }
      })
    }
    return color;
  },
  /**
   * 更新草稿列表
   * @param {*} param0 
   */
  updateDraftList({taskId, updateTime, name,createusername,createuserthumbnail, colour, taskType}){
    // console.log({taskId, updateTime, name,createusername,createuserthumbnail, colour})
    let {draft_list} = vue.$store.state;
    // console.log(draft_list);
    if(draft_list && draft_list.length){
      const index = draft_list.findIndex(item=> {
        return item.id == taskId;
      })
      // console.log(index);
      if(index != -1){
        draft_list.splice(index, 1);
      }
      draft_list.unshift({id:taskId, updateTime, name,createusername,createuserthumbnail, colour, taskType, draft: 1});
      // console.log(draft_list)
    }else{
      vue.$store.commit('set_draft_list', [{taskId, updateTime, name, createusername,createuserthumbnail, colour}]);
    }

  },
  deleteDraftList({taskId}){
    let {draft_list} = vue.$store.state;
    if(draft_list && draft_list.length){
      const index = draft_list.findIndex(item=> {
        return item.id == taskId;
      })
      // // console.log(index);
      if(index != -1){
        draft_list.splice(index, 1);
      }
      // draft_list.unshift({id:taskId, updateTime, name,createusername,createuserthumbnail, colour});
      // // console.log(draft_list)
    }
  },
  changeListFields(params){
    const {listName, taskId, fieldName, fieldValue} = params || {};
    // let {template_my_list} = this.$store.state.template;
    let list = vue.$store.state[listName];
    if(!list || list.length == 0){
      return;
    }
    let aim = list.find((item)=>{
      return item.id == taskId;
    } )
    if(aim != undefined){
      aim[fieldName] = fieldValue;
      vue.$set(aim, fieldName, fieldValue);
    }
  },
  /**
   * 删除草稿
   * @param {*} param0 
   * @returns 
   */
  async deleteDraft({id}){
    let status = true;
    try{
      let res = await vue.$http.request('/projectDraft/deleteDraft', {id});
      if(res.message == 'success'){
        // 提示成功
        vue.$notice({desc: '删除成功', type:'success', duration: '1000', mask: false});
      }else{
        vue.$notice({desc: res.describe, duration: '1000', mask: false});
        status = false;
      }
    }catch(err){
      // console.log(err);
      status = false;
    }
    return status;
  }
}

export default editTask;