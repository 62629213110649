import { PPackage } from '@/types'
class TeachingPackage {
  constructor (params = {}) {
    const {
      pPackage
    } = params
    this.pPackage = pPackage ?? new PPackage()
  }
}

export default TeachingPackage