var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components_pop_up_common_wrapper" }, [
    _c(
      "div",
      {
        staticClass: "mask d-flex align-center justify-center",
        style: { zIndex: _vm.index },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.handleClickWrapper.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "container bbox d-flex flex-column",
            style: {
              height: _vm.height,
              width: _vm.width,
              "max-height": _vm.max_height,
              "max-width": _vm.max_width,
              "background-color": _vm.transparent ? "transparent" : "#fff",
              "box-shadow": _vm.transparent ? "none" : _vm.box_shadow,
              "border-radius": `${_vm.border_radius}`,
              overflow: _vm.overflow,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.stopPropagation($event)
              },
            },
          },
          [_vm._t("default")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }