/*
 * @Author       : Hugo
 * @Date         : 2020-08-19 10:00:28
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-08-18 11:09:48
 * @Description  : 
 * @FilePath     : /miaohang/src/store/modules/control.js
 */



// 任务
const control = {
  state: {
    /**
     * 联系人控件 选择联系人开关
     */
    // todo FUTURE更新后删除
    contact_selectbox_switch: false,
    switch_contact_selectbox: false,
    /**
     * 联系人控件 选择列表
     */
    contact_selectbox_list: null,
    /**
     * 联系人控件 索引排序 sort
     */
    contact_selectbox_sort: 0,
    
  },
  mutations: {
    /**
     * 联系人控件 选择联系人开关
     */
    // todo FUTURE更新后删除 
    control_set_contact_selectbox_switch(state, payload){
      state.contact_selectbox_switch = payload;
    },
    control_set_switch_contact_selectbox(state, payload){
      state.switch_contact_selectbox = payload;
    },
    control_set_contact_selectbox_list(state, payload){
      state.contact_selectbox_list = payload;
    },
    control_set_contact_selectbox_sort(state, payload){
      state.contact_selectbox_sort = payload;
    },
    
  }
};

export default control;
