<!--
 * @Author       : Hugo
 * @Date         : 2021-11-29 14:17:30
 * @LastEditTime : 2021-12-03 12:22:39
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/At/Unit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_at_unit_index bbox d-flex align-center cp"
    v-if="search === '' || search && item.search({keyword: search})"
    @click.stop="doSure(item)"
  >
    <img
      :src="`${$avatar_url}${item.thumbnail}@!small200`"
      alt=""
      class="avatar flex-shrink-0"
    >
    <div class="name bbox pl-16"
      v-html="search === '' ? item.priorableName() : item.search({keyword: search})"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    search: {
      type: String,
      default: '',
    },
  },
  methods: {
    doSure(item){
      this.$emit('handleSure', item);
    }
  }

}
</script>

<style lang="scss" scoped>
.components_pop_up_at_unit_index{
  @include transition;
  width: 100%;
  &:hover{
    background-color: #eee;
  }
  .avatar{
    width        : 41px;
    height       : 41px;
    display      : block;
    border-radius: 4px;
  }
  .name{
    @include single_line_intercept;
    color    : #333;
    font-size: 14px;
  }

}
</style>