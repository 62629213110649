/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 14:21:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-13 15:25:19
 * @Description  : 
 * @FilePath     : /miaohang/src/store/action.js
 */ 
// 异步操作中需要用到 increment 方法，所以需要导入 types.js 文件
// import types from './types'
import { PChat, listForClasses, PTaskLog, UserInfoSimplePmcs } from '@/types'
import vue from '@/main.js';
import store from '@/store'
const actions= {
    /**
     * 关闭所有弹出层 100ms后重置状态
     * @param {*} param0 
     * @param {*} payload 
     */
    close_all_popover({commit}){
      // state.close_all_popover = payload;
      commit('close_all_popover', true);
      setTimeout(()=>{
        commit('close_all_popover', false);
      },100)
    },
    /**
     * 获取消息盒子列表
     * @param {*} param0 
     */
    async get_message_box_list ({commit, state}) {
      const { friends_list } = state
      let res = await vue.$http.request('/projectTask/messageBoxList',{})
      // 显示好友备注名
      if (friends_list && friends_list.length) {
        if (res && res.contents && res.contents.messageBox && res.contents.messageBox.length) {
          res.contents.messageBox.forEach( (item) => {
            if (item.chatType === 0) {
              const aim = friends_list.find( (unit) => {
                return unit.friendUserId === item.chatId
              })
              if (aim) {
                item.name = aim.updateremark ? aim.friendRemark : aim.userName
              }
            }
          })
        }
      }
      // if(res.contents.messageBox.length != 0){
      commit('set_message_box_list', res.contents.messageBox)
      // }
    },
    /**
     * 获取好友列表
     * @param {*} param0 
     */
    async get_friends_list({commit}){
      let res = await vue.$http.request("/projectFriends/friendsList", {});
      if (res.contents.list.length != 0) {
        commit('set_friends_list', res.contents.list);
        // this.$set(this, "friends_list", res.contents.list);
        let friends_list_group = packetFriendsList(res.contents.list);
        commit('set_friends_list_group', friends_list_group);
      }
    },
    async get_workteam_list ({commit}) {
      let res = await vue.$http.request("/projectFriends/chatGoupList", {})
      if (res.contents.list.length !== 0) {
        commit('set_workteam_list',res.contents.list)
      }
    },
    /**
     * 打开任务盒子
     * @param {*} params { taskId, status: => task_edit_status, seeType}
     * seeType
     * '1' 小妙推送通知
     * =====
     * status参数
     * '' => 默认
     * clear => 抹除当前数据(id, 文件id, 相关用户) 教学信息保留  触发情景:[全部模板,]
     * 我购买的不清除id
     * preview 是否预览
     * is_new_task 是否新模板
     */
    openTaskBox({commit},payload){
      let {taskId, status, seeType, reelect, preview, is_new_task, chatId, chatType} = payload || {};
      // 清空数据
      commit('set_task_data', {});
      commit('set_task_details', []);
      commit('set_task_users', []);
      if(seeType != undefined){
        commit('set_task_see_type', seeType);
      }else{
        commit('set_task_see_type', '');
      }
      commit('set_is_new_task', is_new_task);
      commit('set_task_preview', preview); // 是否预览
      commit('set_task_reelect_dialog', reelect); // 是否重新选择发送对象
      // END 清空数据
      // // console.log(taskId);
      commit('set_task_id', taskId || '');
      commit('set_task_edit_status', status || '');
      commit('set_task_call', status == 'clear' ? true : false);
      // if(feature){
        vue.$api.editTask.getTaskData({chatId, chatType});
        return;
      // }
      commit('set_switch_task_box', true); //打开
    },
    /**
     * 通过任务id打开 编辑任务 
     * @param {*} param0 
     * @param {Object} payload {
     *    taskId: 任务id,
     *    copy: 复制(清除taskId和用户列表),
     * }
     */
    async get_edit_task_box({commit, state, dispatch},payload){
      let {edit_task_data_list, edit_task_id, my_task_list} = state;
      let {taskId, copy} = payload || {};

      dispatch('openTaskBox', payload);
      return;
    },
    // /**
    //  * 获取任务数据
    //  * @param {*} param0 
    //  * @param {*} taskId 
    //  */
    // async get_edit_task_data({commit}, taskId){
    //   if(taskId){
    //     /**获取任务内容 */
    //     commit('set_edit_task_id', taskId);
    //     let data = {taskId};
    //     let res = await vue.$http.request('/projectTask/taskDetail', data);
    //     // 任务数据
    //     if(res.contents.task){
    //       commit('set_edit_task_data', res.contents.task);
    //     }
       
    //   }
    // },
    /**
     * 获取消息列表
     * @param {*} param0 
     * @param {*} payload 
     */
    async get_message_list({commit, state}, payload){
      // let chatId = vue.$route.params.id;
      let chatId = vue.$api.moduleTask.getRouteParamsId()
      commit('set_chat_id', chatId)
      let chatType = null
      if (vue.$route.name.indexOf('single') != -1) {
        chatType = 0
      }
      if (vue.$route.name.indexOf('group') != -1) {
        chatType = 1
      }
      if (chatType != null) {
        try {
          let res = await vue.$http.request('/projectTask/messageList', {chatId, chatType}, true)
          if (res.message === 'success') {
            commit('set_message_list_data', res.contents)
            let message_list = [];
            if (res.contents && res.contents.chat) {
              // // console.log(res.contents.chat)
              commit('set_dialog_data', new PChat(res.contents.chat));
            }
            if (res.contents && res.contents.tasklogList) {
              /**反转之后赋值 */
              message_list = res.contents.tasklogList.reverse()
              // 将图片【logType === 6】的按照id来排序
              let picType = {index: undefined, pic: undefined}
              for (let i in message_list) {
                if (message_list[i].logType === 6) {
                  if (picType.pic === undefined) {
                    picType = {index: i, pic: message_list[i]}
                  } else {
                    // 将id大的往后移
                    if (picType.pic.id > message_list[i].id) {
                      message_list[picType.index] = message_list[i]
                      message_list[i] = picType.pic
                    } else {
                      picType = {index: i, pic: message_list[i]}
                    }
                  }
                }
              }
            }
            commit('set_message_list', listForClasses({
              list:message_list,
              classes: PTaskLog
            }))
          } else {
            vue.$hideLoading();
            vue.$notice({desc: res.describe})
          }
        }catch(err){
          // console.log(err);
        }
      }
      // 设置群成员
      if (chatType == 1) {
        commit('set_dialog_user_list', [])
        getUserList()
      }
    },
    /**
     * 控件记录
     * @param {*} param0 
     * @param {Number} querytype 查询类型 1时间(default) 2成员 3控件
     */
    async get_task_log({commit, state}, querytype=1){
      let taskId = state.task_id;
      if(taskId){
        let res = await vue.$http.request('/projectTask/getTaskLog',{taskId, querytype});
        commit('set_task_log', res.contents.logList);
      }
    },
    /**
     * 最近控件列表
     * @param {*} param0 
     * @param {*} isAll 是否全部0否 1是
     * @param {*} keyword 搜索关键字
     */
    async get_my_task_list({ commit, state }, params) {
      let keyword = ''
      let isAll = 0
      if(params){
        keyword = params.keyword || ''
        isAll = params.isAll || 0
      }
      if(!isAll){
        isAll = 0
      }
      let route = vue.$route
      let chatType = 0
      // // console.log(route)
      // let chatId = route.params.id
      let chatId = vue.$api.moduleTask.getRouteParamsId()

      if(route.name == 'fun_task_dialog_group') {
        chatType = 1
      }
      let data = {
        chatId,
        chatType,
        isAll,
        keyword: keyword || '',
        start: 0,
      }
      console.log(data)
      let res = await vue.$http.request('/projectTask/mytaskList', data)
      commit('set_my_task_list', res.contents.taskList)
      return res
    },
    /**
     * 获取目标用户信息
     * @param {*} param0 
     * @param {Number} params 用户id
     */
    async get_user_data({commit, state}, params){
      if(params){
        let res = await vue.$http.request('/project/getUserById', {userId: params});
        commit('set_user_data', res.contents.user);
      }
    }
}
/**
 * 将好友列表进行分组 按首字符
 * @param {Object} friends_list 好友列表
 * @return: 分组后的数据
 */
function packetFriendsList(friends_list) {
  // // console.log(friends_list)
  let group_obj = {};
  for (let i in friends_list) {
    let firstword = friends_list[i].firstword.toLowerCase();
    if (!group_obj[firstword]) {
      group_obj[firstword] = [friends_list[i]];
    } else {
      group_obj[firstword].push(friends_list[i]);
    }
  }
  // 对象键名排序
  var newData = {};
  Object.keys(group_obj)
    .sort()
    .map(key => {
      newData[key] = group_obj[key];
    });
  return newData;
}

async function getUserList(){
  const { commit } = store
  const res = await vue.$http.request(
    '/projectFriends/chatUserList',
    {
      chatId:vue.$api.moduleTask.getRouteParamsId()
    })
  // const dialog_user_list = res.contents.list
  const dialog_user_list = listForClasses({
    list: res.contents.list,
    classes: UserInfoSimplePmcs
  })
  // console.log(dialog_user_list)
  commit('set_dialog_user_list', dialog_user_list)
}

// 最后导出
export default actions;