var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.search === "" ||
    (_vm.search && _vm.item.search({ keyword: _vm.search }))
    ? _c(
        "div",
        {
          staticClass:
            "components_pop_up_at_unit_index bbox d-flex align-center cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSure(_vm.item)
            },
          },
        },
        [
          _c("img", {
            staticClass: "avatar flex-shrink-0",
            attrs: {
              src: `${_vm.$avatar_url}${_vm.item.thumbnail}@!small200`,
              alt: "",
            },
          }),
          _c("div", {
            staticClass: "name bbox pl-16",
            domProps: {
              innerHTML: _vm._s(
                _vm.search === ""
                  ? _vm.item.priorableName()
                  : _vm.item.search({ keyword: _vm.search })
              ),
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }