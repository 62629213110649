/*
 * @Author       : Hugo
 * @Date         : 2021-12-15 10:10:39
 * @LastEditTime : 2022-01-10 12:05:36
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/payment/index.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main';
class Payment{
  paymentStatus(params={}){
    vue.$payment_status(params);
  }
  /**
   * 支付会员状态
   */
  becomeMemberStatus(params={}){
    const {status, title} = params;
    const data = {
      status,
      title,
      jump: true,
      jump_name: '会员与消费',
      jump_router_name: 'personal_center_vip_and_consumption',
      type: 'member',
    }
    vue.$close_become_member();
    this.paymentStatus(data);
  }

  scanCodeStatus(params={}){
    const {status, title, jump, jump_name, jump_router_name, type} = params;
    const data = {
      status, title, jump, jump_name, jump_router_name, type
    };
    this.paymentStatus(data);
  }

}

const payment = new Payment();

export default payment;