var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_task_sidebar_widget_container_page bbox" },
    [
      _c(
        "draggable",
        {
          staticClass: "control_draggable d-flex flex-wrap align-center",
          attrs: {
            list: _vm.list,
            group: {
              name: "widget",
              pull: _vm.editable ? "clone" : false,
              put: false,
            },
            clone: _vm.cloneWidget,
            sort: false,
          },
        },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              _c("widget-button", {
                key: index,
                staticClass: "cp",
                attrs: {
                  name: item.name,
                  title: item.title,
                  type: item.type,
                  editable: _vm.editable,
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clickNewWidget(item)
                  },
                },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }