/*
 * @Author       : Hugo
 * @Date         : 2022-04-27 16:41:15
 * @LastEditTime : 2022-04-27 16:47:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/classes/modules/Navigation/index.js
 * @Description  : 导航
 * @^_^          : 
 */
class Navigation{
  name = undefined // 名称
  data = undefined // 数据取决于具体对象

  constructor(params={}){
    const {
      name,
      data,
    } = params ?? {}

    this.name = name
    this.data = data
  }
}

export default Navigation