<template>
  <div class="c_teaching_package_select_task_all_group bbox d-flex">
    <div
      class="all_group d-flex"
      @click.stop="doAll"
    >
      <div
        class="icon cp"
        :class="{ active: all }"
      ></div>
      <div class="text cp">
        全选
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AllGroup',
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    all() {
      const { packageSelectTask, taskList } = this
      const { selectedList } = packageSelectTask
      // console.log(packageSelectTask, taskList)
      if (!taskList || taskList.length === 0) {
        return false
      }
      let all = true
      // 如果taskList 有一个不在 selectedList中， 为false
      taskList.forEach((item) => {
        const inSelected = selectedList.find((unit) => {
          return unit.id === item.id
        })
        if (!inSelected) {
          all = false
        }
      })
      // console.log(all)
      return all
    }
  },
  methods: {
    doAll() {
      const { packageSelectTask, taskList, all } = this
      const { selectedList } = packageSelectTask
      // console.log({ packageSelectTask, taskList, all, selectedList } )
      if (all) {
        // 取消全选
        // 将 taskList中的全部冲selectList中删除
        taskList.forEach((item) => {
          const index = selectedList.findIndex((unit) => {
            return unit.id === item.id
          })
          if (index !== -1) {
            selectedList.splice(index, 1)
          }
        })
      } else {
        // 将taskList中未选中的添加到selectedList中
        taskList.forEach((item) => {
          const inSelected = selectedList.find((unit) => {
            return unit.id === item.id
          })
          if (!inSelected) {
            selectedList.unshift(item)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_all_group{
  font-size: 14px;
  color: #666;
  .text{
    padding-left: 8px;
  }
  .icon{
    width: 20px;
    height: 20px;
    background: {
      image: url(~@/assets/images/teaching_package/select_task/unselected.png);
    }
    &.active{
      background: {
        image: url(~@/assets/images/teaching_package/select_task/selected.png);
      }
    }
  }
}
</style>