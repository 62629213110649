import vue from '@/main.js';
class Push{
  messageList = ({chatId,chatType, data}) => {
    const route = vue.$route;
    const {id} = route.params || {};
    // 判断是否是当前聊天
    const current_chat_type = vue.$tools.getChatType();
    const current_chat_id =  vue.$global.decodeBase64(id);
    if(chatId == current_chat_id && current_chat_type == chatType){
      // 追加message_list;
      const {message_list} = vue.$store.state;
      message_list.push(data);
      // 滚动到底部
      vue.$nextTick(function(){
        vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
      })
    }
  }
}

const push = new Push();

export default push;