var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_modules_card bbox d-flex flex-column align-start",
      class: { mobile: _vm.mobile },
      style: {
        "background-color":
          _vm.pcard && _vm.pcard.cardpic ? "transparent" : "#000",
        height: _vm.height,
      },
    },
    [
      _vm.pcard && _vm.pcard.cardpic
        ? _c("img", {
            ref: "cardpic",
            staticClass: "cardpic",
            attrs: { src: `${_vm.$img_url}${_vm.pcard.cardpic}`, alt: "" },
          })
        : _vm._e(),
      _vm.pcard && _vm.pcard.cardpic && _vm.cardpic_base64
        ? _c("img", {
            staticClass: "cardpic_base64",
            attrs: { src: _vm.cardpic_base64, alt: "" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "card_group bbox d-flex flex-column justify-space-between",
          style: {
            width: _vm.width,
            height: _vm.height,
          },
        },
        [
          _c("div", { staticClass: "descript_group" }, [
            _c(
              "div",
              {
                staticClass:
                  "type_group d-flex align-center justify-space-between",
              },
              [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("@/assets/images/personal_center/gift_card/logo.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "type" }, [
                  _vm._v(_vm._s(_vm.usetype)),
                ]),
              ]
            ),
          ]),
          _vm.pcard && _vm.pcard.cardname
            ? _c(
                "div",
                {
                  staticClass: "title",
                  style: { "font-size": _vm.title_size },
                },
                [_vm._v(_vm._s(_vm.pcard.cardname))]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "info_group d-flex justify-space-between align-center",
            },
            [
              _c("div", { staticClass: "userful_life" }, [
                _vm._v(_vm._s(_vm.indate)),
              ]),
              _vm.show_cardno && _vm.cardno
                ? _c("div", { staticClass: "card_number" }, [
                    _vm._v(_vm._s(_vm.cardno)),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm.show_price
        ? _c("div", { staticClass: "price_group bbox" }, [
            _vm._v("价格：" + _vm._s(_vm.price) + "元"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }