var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_select_task_all_group bbox d-flex" },
    [
      _c(
        "div",
        {
          staticClass: "all_group d-flex",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doAll.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "icon cp", class: { active: _vm.all } }),
          _c("div", { staticClass: "text cp" }, [_vm._v("\n      全选\n    ")]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }