<template>
  <div class="forget-con">
    <div class="forget-conl"></div>
    <div class="forget-conll">
      <div class="forget-contop">
        <img style="top:1px" src="../../assets/logo.png" alt class="logo" />
        <h2>妙行 | 让沟通更专业</h2>
      </div>
      <h3>WELCOME</h3>
      <p class="wb">Project Management Communication System</p>
    </div>

    <div class="forget-conr">
      <div v-if="show">
        <Forget @findPassword="findPassword" />
      </div>
      <div v-if="!show">
        <New @logIn="logIn" />
      </div>
    </div>
  </div>
</template>
<script>
  import Forget from "./forget/Forget";
  import New from "./forget/New";
  export default {
    data() {
      return {
        show: true,
        userName: "",
        password: "",
        nor: true,
        sendcode: "发送验证码",
        notecode: '', //短信验证码
      };
    },
    components: {
      Forget,
      New
    },
    methods: {

      findPassword(price, user, nor, notecode) {
        this.show = price;
        this.userName = user;
        this.nor = nor;
        this.notecode = notecode;
      },
      async logIn(pass) {
        this.password = pass;
        let data = {
          loginName : this.userName,
          password: pass,
          authCode: this.notecode,
        }
        let res = await this.$http.request('/project/resetPassword',data);
        if(res.message == 'success'){
          this.changePassword();
        }


        // let res = await this.$http.updatePassword({
        //   "loginName": this.userName,
        //   "password": pass
        // })
        // if(res.message == 'success'){
        //   this.changePassword();
        // }else{
        //   this.$message(res.describe)
        // }
        // switch (res.status) {
        //   case "error":
        //     this.$message('修改失败');
        //     break;
        //   case "success":
        //     this.changePassword()
        //     break;
        //   default:
        //     break;
        // }
      },
      /** 调登录接口 */
      async changePassword() {
          let data = {
            username: this.userName,
            password: this.$md5(this.password),
            logintype: 4,
          }
          let res = await this.$http.request('/project/loginAuth', data);
          this.$store.commit('loginInit');
          if ( res.message === "success") { 
            /**存储cookie*/
            for(let i in res.contents){
              this.$cookies.set(i,res.contents[i]);
            }
            /**end  存储cookie*/
            document.cookie = `miaohangUsername=${this.userName};`;
            window.localStorage.setItem('object',JSON.stringify(res));

            this.$store.dispatch('imLogin');
            // this.$router.push("/detail/deta");
            this.$router.push({name:'fun_task_index'});
          } else{
            // console.log(res.describe);
            // this.userNameWarn = res.describe;
            // this.$toast({icon:'none',title:'登陆失败',desc:res.describe,duration:1000});
          }



        // if (!this.nor) {
        //   var time = new Date();
        //   time.setTime(
        //     time.getTime() - 1000 * 60 * 60 * 8 + 1000 * 60 * 60 * 24 * 30
        //   );
        //   document.cookie = `miaohangUsername=${this.userName};expires=${time}`;
        //   this.$router.push("/");
        //   this.$router.push("/home");
        // } else {
        //   document.cookie = `miaohangUsername=${this.userName}`;
        //   this.$router.push("/home");
        // }
      }
    }
  };
</script>
<style lang='less' scoped>
  .forget-con {
    width: 1200px;
    height: 650px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    position: relative;

    .forget-conl {
      width: 476px;
      height: 650px;
      background: rgba(0, 0, 0, 1);
      opacity: 0.3;
    }

    .forget-conll {
      position: absolute;
      top: 1px;

      .forget-contop {
        display: flex;

        .logo {
          display: block;
          width: 50px;
          height: 50px;
          margin-top: 60px;
          margin-left: 60px;
        }

        h2 {
          width: 220px;
          height: 26px;
          color: #ffffff;
          margin-left: 16px;
          margin-top: 72px;
        }
      }

      h3 {
        width: 168px;
        height: 23px;
        font-size: 32px;
        font-family: Arial;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 38px;
        opacity: 0.8;
        margin-top: 400px;
        margin-left: 258px;
      }

      .wb {
        width: 274px;
        height: 11px;
        font-size: 12px;
        font-family: Arial;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 27px;
        opacity: 0.6;
        margin-left: 166px;
        margin-top: 21px;
      }
    }

    .forget-conr {
      width: 724px;
      height: 650px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>