var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_popup_record_of_communication_picture_index_page bbox",
    },
    [
      _vm._l(_vm.picture_grouping, function (item, index) {
        return [
          _c("div", { key: index }, [
            _c("div", { staticClass: "type_title mb-15" }, [
              _vm._v(
                _vm._s(
                  item.type === "week"
                    ? "本周"
                    : `${item.year}年${item.month}月`
                )
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "picture_group d-flex align-center flex-wrap mb-10",
              },
              [
                _vm._l(item.list, function (child, child_index) {
                  return [
                    _c("unit", {
                      key: child_index,
                      staticClass: "mb-5 mr-5 flex-shrink-0",
                      attrs: { item: child },
                    }),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      }),
      _vm.nomore
        ? _c("div", { staticClass: "nomore py-10" }, [_vm._v("没有更多了")])
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading d-flex justify-center" },
            [_c("loading", { attrs: { size: "20px" } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }