<!--
 * @Author       : Hugo
 * @Date         : 2022-04-25 14:26:00
 * @LastEditTime : 2022-04-26 11:24:15
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/InvivationPoster/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->

<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      width="600px"
    >
      <div class="c_plugin_invivation_poster d-flex flex-column"
      >
        <common-title
          class        = "flex-shrink-0 title_common"
          @handleClose = "handleClose"
          :title         = "title"
          :close         = "true"
          background_color = "transparent"
        >
          <template
            slot
          >
            <div
              class="title_group bbox d-flex justify-center"
            >
              <div class="title">
                {{title}}
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="bottom"
                >
                  <div slot="content"
                    class="c_plugin_invivation_poster_tooltip_content"
                  >
                    1、每个邀请链接/海报72小时内有效；<br/>
                    2、此页面展示最近一次生成且未失效的链接/海报，更多海报请在“邀请记录”中查看；<br/>
                    3、若想生成新时效的链接/海报，请点击按钮“生成新链接/海报”
                  </div>
                  <div class="question"></div>
                </el-tooltip>
              </div>

              <div class="close cp"
                @click.stop="handleClose()"
              ></div>
            </div>
          </template>
        </common-title>
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from '@/components/Invivation/Plugins/Poster/index.vue'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      title: '',
      onSure: undefined,
      onCancel: undefined,
      loading: false,
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    async handleSure(work){
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure(work)
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_invivation_poster{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .title_common{
    width: 100%;
    height: 50px;
    background: #F8F8F8;
    position: relative;
    .title_group{
      position: relative;
      background-color: transparent;
      .title{
        position: relative;
        .question{
          @include background;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background-image: url(~@/assets/images/invivation_poster/question.png);
          right: -20px;
        }
      }
      
    }
  }
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
<style lang="scss">
.c_plugin_invivation_poster_tooltip_content{
  width: 354px;
  height: 64px;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #FFFFFF;
  line-height: 16px;
}
</style>
