var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "notice_plugin",
            class: { mobile: _vm.mobile },
            style: { "z-index": _vm.zindex },
          },
          [
            _vm.mask ? _c("div", { staticClass: "mask" }) : _vm._e(),
            _c("div", { staticClass: "notice_group" }, [
              !_vm.hide_icon
                ? _c("div", {
                    staticClass: "icon",
                    class: { success: _vm.type == "success" },
                  })
                : _vm._e(),
              _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.desc))]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }