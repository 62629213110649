/*
 * @Author      : hugo 1634765756@qq.com
 * @Date        : 2022-05-16 15: 53             : 43
 * @LastEditors : hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-16 15: 56             : 09
 * @FilePath    : /miaohang/src/types/modules/UserInfoSimplePmcs/index.js
 * @Description : 这是默认设置,        请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https: //github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import global from '@/api/global'
import { listForClasses, PTask } from '@/types'
class UserInfoSimplePmcs{
  userId = undefined  //	String		用户id
  userName = undefined  //	String		用户昵称
  thumbnail = undefined  //	String		用户头像缩略图
  phone = undefined  //	String		手机号
  friendRemark = undefined  // 联系人备注
  chatUserName = undefined  //	String		群聊备注
  updateremark = undefined  //			是否修改过备注0否 1是
  isfriend = undefined  //	Boolean 是否好友 false否 true是
  isread = undefined  //	String		0未读  1已读


  constructor(params={}){
    const {
      userId,
      userName,
      thumbnail,
      phone,
      friendRemark,
      chatUserName,
      updateremark,
      isfriend,
      isread,
      // 批量添加至任务
      selectList,
      moreSelectList,
      keyword,
      searchSelectList,
      moreSearchSelectList,
      selectedList,
    } = params ?? {}

    this.userId = userId
    this.userName = userName
    this.thumbnail = thumbnail
    this.phone = phone
    this.friendRemark = friendRemark
    this.chatUserName = chatUserName
    this.updateremark = updateremark
    this.isfriend = isfriend
    this.isread = isread
    this.selectList = selectList ?? []
    this.moreSelectList = moreSelectList ??false
    this.keyword = keyword ?? ''
    this.searchSelectList = searchSelectList ?? []
    this.moreSearchSelectList = moreSearchSelectList ?? false
    this.selectedList = selectedList ?? []
  }

  /**
   * 清空添加数据
   */
  clearBatchData = () => {
    this.selectList = []
    this.moreSelectList = false
    this.keyword = ''
    this.searchSelectList = []
    this.moreSearchSelectList = false
    this.selectedList = []
  }

  /**
   * 将联系人批量添加到任务中
   * @param {Object} params
   * @param {Number} params.chatId - 群聊id
   * @returns {Promise<*>}
   */
  batchContentsToTasks = async (params = {}) => {
    const { chatId } = params ?? {}
    if (!chatId) {
      return
    }
    const { userId, selectedList } = this
    if (!selectedList || selectedList.length === 0) {
      return
    }
    let taskIds = ''
    selectedList.forEach((item) => {
      taskIds += `${taskIds === '' ? '' : ','}${item.id}`
    })
    const data = {
      chatId,
      userId,
      taskIds
    }
    const res = await global.doPost({
      url: '/projectTask/batchToTask',
      data
    })
    return res
  }

  doSearch = async (params) => {
    const { chatId, chatType } = params
    const { keyword, selectList } = this
    if (keyword.trim() !== '') {
      this.searchSelectList = []
      this.moreSearchSelectList = false
      const res = await this.getChatTaskList({ chatId, chatType })
      return res
    } else {
      if (!selectList || selectList.length === 0) {
        const res = await this.getChatTaskList({ chatId, chatType })
        return res
      }
      return selectList
    }
  }

  getChatTaskList = async (params) => {
    const { chatId, chatType } = params
    const { keyword } = this
    const listFieldName = this.getListFieldName()
    const moreFieldName = this.getMoreFieldName()
    console.log({ chatId, chatType })
    const res = await global.doPost({
      url: '/projectTask/mytaskList',
      data: {
        chatType,
        chatId,
        isAll: 0,
        keyword: keyword.trim(),
        start: this[listFieldName].length
      }
    })
    const { message, contents } = res
    if (message === 'success') {
      const { taskList } = contents
      if (taskList && taskList.length) {
        this[listFieldName] = this[listFieldName].concat(
          listForClasses({
            list: taskList,
            classes: PTask
          })
        )
        console.log(this[listFieldName])
      }
      if (taskList && taskList.length === 50) {
        this[moreFieldName] = true
      } else {
        this[moreFieldName] = false
      }
    }
    return res
  }

  getListFieldName = () => {
    const { keyword } = this
    if (keyword.trim() !== '') {
      return 'searchSelectList'
    }
    return 'selectList'
  }

  getMoreFieldName = () => {
    const { keyword } = this
    if (keyword.trim() !== '') {
      return 'oreSearchSelectList'
    }
    return 'moreSelectList'
  }

}

export default UserInfoSimplePmcs