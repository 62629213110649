import config from "./WebIMConfig"
import websdk from "easemob-websdk"
import vue from '@/main'
import common from './module/common'
import pushTask from '@/utils/WebIM/module/pushTask'
import share from '@/utils/WebIM/module/share'
import chat from '@/utils/WebIM/module/chat'
// import emedia from "easemob-emedia"
// import webrtc from "easemob-webrtc"
import { Message } from "element-ui"
import updateUser from './module/updateUser'
import wisdom from './module/wisdom'
import widget from './module/widget'
import store from '@/store'
import publicNotice from '@/utils/WebIM/module/publicNotice'
// import im from '@/utils/WebIM/im'
import appData from '@/utils/appData'
import messageBox from '@/utils/WebIM/module/messageBox'

// console.log(appData)

// setTimeout(() => {
//   console.log('测试透传消息')
//   im.sendMySelf()
// }, 10000)

function ack(message) {
	var bodyId = message.id; // 需要发送已读回执的消息id
	var msg = new WebIM.message("read", WebIM.conn.getUniqueId());
	msg.set({
		id: bodyId,
		to: message.from
	});
	WebIM.conn.send(msg.body);
}

/**
 * 推送消息时更新消息列表
 * @params {Object} msg 推送来的消息
 */
  async function updateMessageListOnPush(ext){
    let {taskId, taskType, linkme, shareType, logType, tousers} = ext;
    if(logType == 1){
      return false;
    }
    if(linkme === undefined){
      linkme = common.tousersConvertLinkme({tousers});
    }
    let {message_list} = vue.$store.state;
    if(message_list && message_list.length){
      message_list.forEach( (item, index) => {
        if(item.taskId == taskId){
          item.taskType = taskType;
          item.linkme = linkme
          item.tousers = tousers
        }
      })
      vue.$store.commit('set_message_list', message_list)
    }
    
    // 未读消息置空
    // unreadToZero(ext); 
  }
/**
 * 推送消息时置空
 * @param {*} ext 
 */
  async function unreadToZero(ext){
    let {chatId, chatType} = ext || {};
    try{
      vue.$http.request('/projectTask/unreadTozero', {chat_id: chatId, chat_type: chatType});
    }catch(err){
      // console.log(err);
    }
  }
/**
 * 追加消息列表
 * @param {Object} ext 推送拓展字段 
 */
  function pushMessageList(ext){
    let {chatType,taskId, taskName, photo, work, name, lastSendTime, unreadMessageNum, messageContent, userName, thumbnail, taskType, details, linkme, updateusername, updateuserthumbnail, createusername, createuserthumbnail, from, updateUserId, shareType, logType, hide, tousers, pTaskLog } = ext || {};
    let new_msg = {};
    // let message_list = vue.$tools.deepClone(vue.$store.state.message_list);
    let message_list      = vue.$store.state.message_list;
    new_msg.taskId     = taskId;
    new_msg.taskName   = taskName;
    new_msg.thumbnail  = updateuserthumbnail;
    new_msg.work       = work;
    new_msg.shareType  = shareType;
    new_msg.logType    = logType;
    new_msg.hide       = Number(hide);
    new_msg.userName   = vue.$utils.users.getPriorityName({userId:updateUserId, name: updateusername});
    new_msg.createTime = Number(lastSendTime);
    new_msg.linkme     = linkme !== undefined ? linkme : common.tousersConvertLinkme({tousers});
    new_msg.tousers = tousers
    new_msg.taskType   = taskType;
    new_msg.userId     = updateUserId;
    new_msg.fromUserId = updateUserId;
    if(details){
      new_msg.details = JSON.parse(details);
    }
    if (pTaskLog) {
      new_msg = JSON.parse(pTaskLog)
    }
    message_list.push(new_msg);
    console.log(new_msg);
    // console.log(message_list);
  }
/**
 * 更新消息盒子列表
 */
  async function updateMessageBoxList(ext){
    // console.log(ext);
    let {chatType, chatName ,taskId, taskName, photo, work, name, lastSendTime, unreadMessageNum, messageContent, userName, thumbnail, taskType, details, linkme, chatId, updateusername, updateuserthumbnail, createusername, createuserthumbnail, from, updateUserId, linkusers} = ext || {};
    let route = vue.$route;
    /**追加红点 */
    let message_box_list = vue.$store.state.message_box_list;
    if(!message_box_list || !message_box_list.length){
      const res = await this.$doPost({
        url:'/projectTask/messageBoxList',
        openNotice: false,
      })
      if(res.message === 'success'){
        if(res.contents && res.contents.messageBox){
          message_box_list = res.contents.messageBox || [];
        }else{
          message_box_list = [];
        }
      }else{

        message_box_list = [];
      }
    }
    let index = message_box_list.findIndex( item => {
      return (item.chatId == chatId) && ( item.chatType == chatType);
    })
    
    // 判断是否@我
    const userid = vue.$tools.getUserId();
    let is_at_me = false;
    if(linkusers && `,${linkusers},`.indexOf(`,${userid},`) !== -1){
      is_at_me = true;
    }
    
    // 消息盒子列表
    if(index != -1){
      message_box_list[index].islinkme           =  message_box_list[index].islinkme ? 1 : (is_at_me ? 1 : 0);
      message_box_list[index].name               = chatName || message_box_list[index].name ;
      message_box_list[index].lastSendTime       = Number(lastSendTime);
      message_box_list[index].messageContent     = messageContent;
      message_box_list[index].updateusername     = vue.$utils.users.getPriorityName({userId:updateUserId, name: updateusername});
      message_box_list[index].photo              = chatType === "1" ? photo : updateuserthumbnail;
      // message_box_list[index].name = chatName;
      // 当前聊天窗口不更新盒子
      // if(route.params.id != chatId){
      if(vue.$api.moduleTask.getRouteParamsId() != chatId){
        message_box_list[index].unreadMessageNum = message_box_list[index].unreadMessageNum?message_box_list[index].unreadMessageNum+1 : 1;
      }
      // 移到顶部
      let unit = message_box_list[index];
      message_box_list.splice(index, 1);
      console.log(unit)
      if(unit.istop){
        // 置顶消息处理
        message_box_list.unshift(unit);
      }else{
        // 非置顶消息
        let not_top_index = message_box_list.findIndex(item => {
          return !item.istop;
        })
        if(not_top_index != -1){
          message_box_list.splice(not_top_index, 0 ,unit);
        }else{
          message_box_list.unshift(unit);
        }
      }
    }else{
      //新建消息盒子
      let message_box_unit = {
        chatId,
        chatType        : chatType,
        createTime      : Number(lastSendTime),
        messageContent  : messageContent,
        photo           : chatType === "1" ? photo  : updateuserthumbnail,
        unreadMessageNum: 1,
        updateusername  : updateusername || userName,
        name            : chatName,
        islinkme        : is_at_me ? 1              : 0,
      }
      // 非置顶消息
      let not_top_index = message_box_list.findIndex(item => {
        return !item.istop;
      })
      if(not_top_index != -1){
        message_box_list.splice(not_top_index, 0 ,message_box_unit);
      }else{
        message_box_list.unshift(message_box_unit);
      }
    //  message_box_list.unshift(message_box_unit);
    }
    vue.$store.commit('set_message_box_list', message_box_list);
  }
/**
 * 更新工作组名称
 * @param {Object} ext  
 */
  function updateGroupChatName(ext){
    let {chatId, chatType, chatName, name} = ext;
    // 更新盒子列表
    updateMessageBoxList(ext);
    // 更新工作组
    // 更新聊天框
    let {dialog_data} = vue.$store.state;
    // console.log(dialog_data);
    if(dialog_data&& dialog_data.chatId == chatId && vue.$route.name == 'fun_task_dialog_group'){
      // 聊天信息 && 当前聊天id == 推送id && 当前路由名 == '群聊'
      let new_dialog_data = {
        chatId      : chatId,
        chatName    : chatName,
        createTime  : Number(ext.lastSendTime),
        createUserId: dialog_data.createUserId,
        jurisdiction: dialog_data.jurisdiction,
        photo       : dialog_data.photo,
        users       : dialog_data.users,
      }
      vue.$store.commit('set_dialog_data', new_dialog_data);
    }
  }
/**
 * 删除工作组
 * @param {Object} ext 
 */
  function deleteChatGroup(ext){
    let { message_box_list, workteam_list } = vue.$store.state

    let { chatId,chatType } = ext

    let route_type = vue.$tools.getChatType()

    // let route_id = vue.$route.params.id

    let route_id = vue.$api.moduleTask.getRouteParamsId()

    if(route_type == chatType && route_id == chatId){
      vue.$router.push({name: 'fun_task_index'})

    }
    if(vue.$route.name == 'fun_contact_workteam_detail' && route_id == chatId){
      vue.$router.push({name: 'fun_contact_index'})

    }
    // 删除消息盒子
    if( message_box_list&& message_box_list.length){
      let index = message_box_list.findIndex( item => {
        return item.chatId == chatId && item.chatType == chatType

      })
      if(index != -1){
        message_box_list.splice(index, 1)

      }
    } 
    // 删除工作组列表
    if( workteam_list && workteam_list.length){
      let index = workteam_list.findIndex( item => {
        return item.chatId == chatId && item.chatType == chatType;
      })
      if(index != -1){
        workteam_list.splice(index, 1);
      }
    } 
  }
/**
 * 更新公示板按钮
 * @param {Object} ext 
 */
  function updateNoticeBtn(ext){
    let {chatType, chatId} = ext || {};
    // let route_id = vue.$route.params.id;
    let route_id = vue.$api.moduleTask.getRouteParamsId();
    let route_type = vue.$tools.getChatType();
    if(chatType == route_type && chatId == route_id){
      let message_list_data = vue.$store.state.massage_list_data;
      if(!message_list_data){
        message_list_data = {};
      }
      if(message_list_data.chat){
        message_list_data.chat.readflag = 1;
      }else{
        message_list_data.chat = {readflag:1};
      }
      
      vue.$store.commit('set_message_list_data', message_list_data);
    }
  }
/**
 * 更新侧边栏数量
 * @param {*} ext 
 */
  function updateSidebarNumber(ext){
    let {chatType, chatId} = ext || {};
    // let route_id = vue.$route.params.id;
    let route_id = vue.$api.moduleTask.getRouteParamsId();
    let route_type = vue.$tools.getChatType();
    let {chat_detail} = vue.$store.state;
    if(chatType == route_type && chatId == route_id){
      
      if(chat_detail.chat && chat_detail.chat.sidebarflag){
        chat_detail.chat.sidebarflag += 1;
      }else{
        chat_detail.chat.sidebarflag = 1;
      }
      
      vue.$store.commit('set_chat_detail', chat_detail);
      
    }
  }
/**
 * 接收消息钩子
 * @param {*} message 
 */
  async function onReceiveMessageHanlder(message){
    // Message({
    //   type: "success",
    //   message: message.data || "您收到了一条消息",
    // });
    const { time } = message
    if (Number(appData.time) > Number(time)) {
      console.log('过期消息')
      return
    }
    let ext = message.ext
    let {
      pushType,
      chatId,
      chatType,
      linkme,
      tousers
    } = ext
    let route = vue.$route
    let routename = route.name
    if (linkme === undefined) {
      linkme = common.tousersConvertLinkme({tousers})
    }
    /**
     * 任务更新推送
     * 
     */
    if (pushType === '16') {
      pushTask.receive(message)
      return

      // // 如果是智慧屏控件 开始或结束投屏 单独更新
      // widget.changeData(ext)
      // // 在列表更新之前获取聊天是否在底部 如果不在底部；则unreadNumber+1,并且不触发滚动到底部
      // const isBottom = store.state.dialogData.isBottom
      // // 在聊天页面中
      // if(routename.indexOf('fun_task_dialog') != -1){
      //   // (单聊 || 群聊) && chatId相等
      //   if( (routename == 'fun_task_dialog_single' && chatType == 0 || routename == 'fun_task_dialog_group' && chatType == 1) && (chatId == vue.$api.moduleTask.getRouteParamsId()) ){
      //     unreadToZero(ext);
      //     /** 当前追加新数据 */
      //     pushMessageList(ext);
      //     /** 更新相关模板[与我相关才添加] */
      //     if(linkme){
      //       // 不是分享
      //       if(logType != 1){
      //         let my_task_list = vue.$tools.deepClone(vue.$store.state.my_task_list);
      //         if(my_task_list){
      //           let my_task_list_unit = {
      //             id                 : taskId,
      //             name               : taskName,
      //             updateTime         : Number(lastSendTime),
      //             taskType           : taskType || 1,          //TODO 环信推送需要增加taskType,
      //             createuserthumbnail: createuserthumbnail,
      //             createusername     : createusername,
      //           }
      //           if (pTaskLog) {
      //             my_task_list_unit.levelStatus = JSON.parse(pTaskLog).levelStatus
      //             my_task_list_unit.processStatus = JSON.parse(pTaskLog).processStatus
      //           }
      //           let index = my_task_list.findIndex(item=> {
      //             return item.id == taskId
      //           })
      //           if (index != -1) {
      //             my_task_list.splice(index, 1);
      //           }
      //           my_task_list.unshift(my_task_list_unit);
      //           vue.$store.commit('set_my_task_list', my_task_list);
      //         }
      //       }
      //     }
      //   }
      //   updateMessageListOnPush(ext);
      // }
      // if (isBottom) {
      //   // 滚动到底部
      //   vue.$nextTick(function(){
      //     vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
      //   })
      // } else {
      //   store.state.dialogData.unreadNumber += 1
      // }
      // // 增加最左侧红点
      // if(routename.indexOf('fun_task') == -1){
      //   vue.$store.commit('set_new_task', true); // 任务类提示
      // }
      // /** 追加红点 */
      // // 消息盒子列表
      // updateMessageBoxList(ext);
    } else if (pushType == 11) {
      vue.$store.commit('set_new_contact', true) // 联系人类提示
      vue.$store.commit('set_new_apply', true) // 新申请提示
    } else if (pushType == 12) {
      vue.$store.commit('set_new_contact', true) // 联系人类提示
      vue.$store.commit('set_new_apply', true) // 新申请提示
      // 刷新好友列表
      vue.$store.dispatch('get_friends_list')
    } else if(pushType == 17) {
      // 拉人到群聊
      if(routename.indexOf('fun_task_dialog') != -1){
        // (单聊 || 群聊) && chatId相等
        if( (routename == 'fun_task_dialog_single' && chatType == 0 || routename == 'fun_task_dialog_group' && chatType == 1) && (chatId == vue.$api.moduleTask.getRouteParamsId()) ){
          /** 当前追加新数据 */
          // pushMessageList(ext);
          // 滚动到底部
          vue.$nextTick(function(){
            vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
          })
        }
      }
      // 增加最左侧红点
      if(routename.indexOf('fun_task') == -1){
        vue.$store.commit('set_new_task', true); // 任务类提示
      }
      /**追加红点 更新消息盒子列表 */
      // updateMessageBoxList(ext);
      pushTask.pushMessageBoxList({
        ext,
        pTaskLog: JSON.parse(ext.pTaskLog)
      })
      /**更新侧边栏数量 */
      chat.updateSidebarNumber({ ext })
      // updateSidebarNumber(ext);
    } else if (pushType == 18) {
      // 拉人到群聊 更新群聊名称
      chat.updateGroupChatName({ ext })
      // updateGroupChatName(ext);
      /**更新侧边栏数量 */
      chat.updateSidebarNumber({ ext })
      // updateSidebarNumber(ext);
      vue.$utils.push.messageList({
        chatId,
        chatType,
        data: JSON.parse(ext.pTaskLog)
        // data: {
        //   chatId,
        //   chatType,
        //   details: [],
        //   logType: 2,
        //   userName: vue.$utils.users.getPriorityName({userId: ext.updateUserId, name: ext.updateusername}),
        //   createTime: Number(ext.lastSendTime),
        //   work: ext.work,
        // }
      })
    }else if(pushType == 19){
      deleteChatGroup(ext);
    }else if(pushType == 20){
      // 公示板推送
      /**追加红点 更新消息盒子列表 */
      pushTask.pushMessageBoxList({
        ext,
        pTaskLog: JSON.parse(ext.pTaskLog)
      })
      // updateMessageBoxList(ext);
      if( (routename == 'fun_task_dialog_single' && chatType == 0 || routename == 'fun_task_dialog_group' && chatType == 1) && (chatId == vue.$api.moduleTask.getRouteParamsId()) ){
        /** 当前追加新数据 */
        // updatePublicNotice(ext);
        publicNotice.updatePublicNotice({ ext })
        vue.$nextTick(function(){
          vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
        })
      }
      // 更新公示板按钮
      // updateNoticeBtn(ext);
      publicNotice.updateNoticeBtn({ ext })
    }else if(pushType === '21'){
      chat.receive(message)
      return
      // 模板讨论区消息推送
      // updateMessageBoxList(ext);
      updateMessageBoxListOfChat(ext);
    }else if(pushType == 22){
      share.receive(message)
      return
      // (单聊 || 群聊) && chatId相等
      // if( (routename == 'fun_task_dialog_single' && chatType == 0 || routename == 'fun_task_dialog_group' && chatType == 1) && (chatId == route.params.id) ){
      if( (routename == 'fun_task_dialog_single' && chatType == 0 || routename == 'fun_task_dialog_group' && chatType == 1) && (chatId == vue.$api.moduleTask.getRouteParamsId()) ){
        /** 当前追加新数据 */
        pushMessageList(ext);
      }

      // 滚动到底部
      vue.$nextTick(function(){
        vue.$store.commit('set_task_trigger_scroll_to_bottom', true);
      })
      // 增加最左侧红点
      if (routename.indexOf('fun_task') == -1) {
        vue.$store.commit('set_new_task', true); // 任务类提示
      }
      /**追加红点 */
      // 消息盒子列表
      updateMessageBoxList(ext);
    }else if(pushType == 23){
      /**
       * ext       
message: "您收到了一条妙行消息，请前往查看"
pushType: "23"
remind: "{"chatType":1,"conId":52830,"conName":"5","content":"锁定控件粉红色款到发货看金华市科技大富豪开始的发货的水电费水电费水电费水电费舒服舒服舒服水电费水电费 水电费水电费对方水电费阿道夫啊发达 阿斯顿发达啊阿闻风丧胆","createTime":1630640441000,"deleteflag":1,"id":74,"ispush":0,"pType":1,"rangee":"","remindTime":1630640460000,"taskId":6721,"taskName":"sssd","toUsers":"4360","userId":4360,"userName":"Touber","userPhoto":"2020/11/10/1604977511102349951.jpg"}",
       */
      receiveReminderNotice(ext);

    }else if(pushType == 24){
      updateUser(ext);
    }else if(pushType == 25){
      wisdom.endScreen(ext);
    } else if (Number(pushType) === 1) {
      messageBox.clearUnreader( { ext })
    }
  }
/**
 * [其他设备登录] 重新登录
 */
  async function reloadByLoginToOtherDevices(){
    const user = vue.$tools.getCookie('user');
    const autologin = vue.$tools.getCookie('autologin');
    let password = vue.$tools.getCookie('password');
    const username = user.loginName;
    vue.$tools.setCookie('nologin' , true);
    if(!password){
      password = vue.$store.state.password;
    }
    // if(password){
    vue.$openConfirm({
      title:'您的账号已在其他地方登录',
      // desc:'',
      sure:'确定',
      cancel:'重新登录',
      cancelable: false,
      onSure:()=>{
        vue.$router.push({ path: "/login" });
      },
      onCancel:async ()=>{
        vue.$tools.setCookie('nologin' , false);
        let res ;
        try{
          res = await vue.$api.doLogin(username, password, autologin, false);
          if(res == true){
            // vue.$router.push({name:'fun_task_index'});
          }else{
            vue.$notice({desc: res.describe})
          }
        }catch(err){
          // console.log(err);
        }
        
      },
    })
    // }
    
  }

/**
 * 重置环信密码[之后重新获取用户信息]
 */
  async function resetHXpassword(){
    let res;
    let userId = vue.$tools.getUserId();
    try{
      res = await vue.$http.request('/project/resetHXpassword', {userId});
      if(res.message == 'success'){
        // im_login.actions.imLogin();
        // vue.$store.dispatch('imLogin'); // 重新登录
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return false;
  }
/**
 * 任务聊天 [更新操作区]
 * @param {Object} ext 
 */
  function updateMyTaskListOfChat(ext){
    const {chatId, chatType, details, masterUserid, taskId, pTaskLog } = ext || {};
    const chat_type = vue.$tools.getChatType();
    // const chat_id = vue.$route.params.id;
    const chat_id = vue.$api.moduleTask.getRouteParamsId();
    const {task_data, my_task_list, switch_task_box, task_pannel, task_config} = vue.$store.state;
    const {task_id} = task_config;
    // 当前通知的聊天窗口是否打开 => taskId相等&&存在 id相等&&存在 && 聊天类型相等&&存在 && 任务页面打开
    const is_open_chat = Boolean(((task_id) && taskId == task_id  )&&(chatId == chat_id && chat_id != undefined) && (chatType == chat_type && chat_type != undefined) && switch_task_box);
    if(!is_open_chat){
      if(my_task_list && my_task_list.length){
        const aim = my_task_list.find((item)=> {
          return item.id == taskId;
        })
        if(aim){
          if(aim.taskchatnum){
            aim.taskchatnum += 1;
          }else{
            aim.taskchatnum = 1;
          }
        }
      }
    }else{
      // 如果不在聊天界面 添加未读红点
      if(task_pannel != 'chat'){
        vue.$store.commit('set_task_unreader_chat', true);
      }
      pushChatList(ext);
    }

  }
/**
 * @description: 任务聊天 [追加聊天内容]
 * @param {*}
 * @return {*}
 */
  function pushChatList(ext){
    const {task_config} = vue.$store.state;
    const {chat_message_id} = task_config;
    // console.log('任务聊天 [追加聊天内容]');
    const {chatId, chatType, details, masterUserid, taskId} = ext || {};
    const parse_details = JSON.parse(details);
    // const {content, createTime, id, taskId, userId, username, userthumbnail} = details;
    if(chat_message_id){
      vue.$store.commit('set_task_config', Object.assign(
        {},
        task_config,
        {chat_message_id: null},
      ))
      vue.$api.chat.getTaskChatList({taskId})
    }else{

      let {task_chat_message_list} = vue.$store.state;
      if(!task_chat_message_list || task_chat_message_list.length == 0){
        task_chat_message_list = [parse_details];
      }else{
        task_chat_message_list.push(parse_details);
      }
      vue.$nextTick(function(){
        // console.log('滚动到底部');
        // 滚动到底部
        setTimeout(()=>{
          vue.$store.commit('set_task_chat_trigger_scroll_to_bottom', true);
        },200)
      })
    }
  }
/**
 * @description: 任务聊天 [更新我的模板数据]
 * @param {*}
 * @return {*}
 */
/** this.$store.state.template.template_my_list; */
  function updateTemplateteMyList(){

  }
/**
 * 任务聊天 [更新消息盒子] 
 * @param {*} ext 
 */

  function updateMessageBoxListOfChat(ext){
    const {chatId, chatType, details, masterUserid, taskId, messageContent, linkusers, updateuserthumbnail, pTaskLog } = ext || {};
    const {message_box_list, task_config} = vue.$store.state
    // const isBottom = store.state.dialogData.isBottom
    const {chat_message_id} = task_config;
    const chat_type = vue.$tools.getChatType();
    // const chat_id = vue.$route.params.id;
    const chat_id = vue.$api.moduleTask.getRouteParamsId();

    // 更新操作区
    updateMyTaskListOfChat(ext);

    const detail = JSON.parse(details);
    const {thumbnail,work, userName, fromUserId, createTime,taskName,logType, hide } = detail;
    console.log(detail)
    const priorityName = vue.$utils.users.getPriorityName({userId: fromUserId, name: userName});
    // console.log(chat_message_id);
    // 是否是当前聊天
    const isCurrent = Boolean((chatId == chat_id && chat_id) && (chatType == chat_type));
    if(isCurrent){
      unreadToZero(ext);
      if (pTaskLog) {
        vue.$api.chat.pushChatAtMessageList(JSON.parse(pTaskLog))
      } else {
        vue.$api.chat.pushChatAtMessageList({
          chatId,
          type:chatType,
          thumbnail: thumbnail ?? updateuserthumbnail,
          work,
          userName: priorityName,
          fromUserId,
          createTime,
          hide: Number(hide),
          taskId,
          taskName,
          details: [],
          logType
        })
      }
      // if (isBottom) {
      //   vue.$nextTick(function () {
      //     setTimeout(() => {
      //       // console.log('vue.$api.messageList.scrollToLatest()')
      //       vue.$api.messageList.scrollToLatest()
      //
      //     }, 200)
      //   })
      // } else {
      //   store.state.dialogData.unreadNumber += 1
      // }
    }

    if(message_box_list && message_box_list.length){
      let unit = message_box_list.find((item)=>{
        return item.chatId == chatId && item.chatType == chatType;
      })
      // 判断是否@我
      const userid = vue.$tools.getUserId();
      let is_at_me = false;
      if(linkusers && `,${linkusers},`.indexOf(`,${userid},`) !== -1){
        is_at_me = true;
      }
      if(unit){
        if(!isCurrent){
          unit.unreadMessageNum = unit.unreadMessageNum ? unit.unreadMessageNum + 1 : 1;
          unit.islinkme       = unit.islinkme ? 1 : (is_at_me?1:0);
        }
        unit.lastSendTime   = createTime;
        unit.messageContent = messageContent;
        unit.updateusername = priorityName;
        const index = message_box_list.findIndex(item=>{
          return item.chatId == unit.chatId && item.chatType == unit.chatType;
        })
        if(unit.istop == 1){
          message_box_list.splice(index, 1);
          message_box_list.unshift(unit);
        }else{
          // 不是置顶盒子
          message_box_list.splice(index, 1); // 移除
          // 要移动到的目标索引
          let aim_index = message_box_list.findIndex((item) => {
            return item.lastSendTime <= unit.lastSendTime && item.istop == 0;
          })
          // if(aim_index != -1){
          message_box_list.splice(aim_index || 0, 0, unit);
        }
      }else{
        // 重新获取message_box_list;
        vue.$store.dispatch('get_message_box_list');
      }
    }else{
      vue.$store.dispatch('get_message_box_list');
    }
    



  }

  function updatePublicNotice(ext){
    const { chatId,chatName,chatType,lastSendTime,message,messageContent,photo,pushType,unreadMessageNum,updateUserId,updateusername,updateuserthumbnail,work} = ext;
    const data = {
      chatId,
      createTime: Number(lastSendTime),
      deleteFlag: null,
      details   : [],
      fromUserId: updateUserId,
      hide      : null,
      isremind  : null,
      linkme    : false,
      logType   : 2,
      shareType : null,
      shareUrl  : null,
      taskId    : null,
      taskName  : null,
      taskType  : null,
      taskusers : null,
      thumbnail : updateuserthumbnail,
      toUserId  : null,
      type      : chatType,
      userName  : updateusername,
      work,
    }
    let message_list = vue.$store.state.message_list;
    message_list.push(data);
  }


/**
 * 接收提醒通知
 */
  function receiveReminderNotice(ext){
    const {remind} = ext;
    // console.log(ext);
    const obj = JSON.parse(remind);
    // console.log(obj)
    vue.$reminder_notice({data:obj});
  }
  // receiveReminderNotice({
  //   remind: `"chatType":1,"conId":52830,"conName":"5","content":"锁定控件粉红色款到发货看金华市科技大富豪开始的发货的水电费水电费水电费水电费舒服舒服舒服水电费水电费 水电费水电费对方水电费阿道夫啊发达 阿斯顿发达啊阿闻风丧胆","createTime":1630640441000,"deleteflag":1,"id":74,"ispush":0,"pType":1,"rangee":"","remindTime":1630640460000,"taskId":6721,"taskName":"sssd","toUsers":"4360","userId":4360,"userName":"Touber","userPhoto":"2020/11/10/1604977511102349951.jpg"`
  // });

// 初始化IM SDK


const listen = {
  onOpened: function (message) { // 连接成功回调
    // console.log('连接成功');
		// 登录或注册成功后 跳转到好友页面
		// const username = vue.$store.state.login.username || localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo")).userId;
		// const path = location.pathname.indexOf("login") !== -1 || location.pathname.indexOf("register") !== -1 ? "/contact" : location.pathname;
		// const redirectUrl = `${path}?username=${username}`;
		// vue.$router.push({ path: redirectUrl });
	},
	onClosed: function (message) {
    // console.log(message);
    // console.log(WebIM.conn.token);
		// vue.$router.push({ path: "/login" });
	}, // 连接关闭回调
	onTextMessage: function (message) {
    console.log('收到文本消息',message)
    onReceiveMessageHanlder(message);
    // console.log(JSON.parse(message.ext.details))
		// const { from, to, type } = message;
		// const chatId = type !== "chat" ? to : from;
		// const typeMap = {
		// 	chat: "contact",
		// 	groupchat: "group",
		// 	chatroom: "chatroom"
		// };
		// vue.$store.commit("updateMsgList", {
		// 	chatType: typeMap[message.type],
		// 	chatId: chatId,
		// 	msg: message.data,
		// 	bySelf: false,
		// 	from: message.from,
		// 	mid: message.id
		// });
		// type === 'chat' && ack(message);
		// if (WebIM && WebIM.call && message && message.ext && message.ext.msg_extension) {
		// 	var msgExtension = message.ext.msg_extension && JSON.parse(message.ext.msg_extension);
		// 	var options = {
		// 		confrId: message.ext.conferenceId,
		// 		password: message.ext.password || "",
		// 		gid: msgExtension.group_id,
		// 		inviter: msgExtension.inviter
		// 	};
		// 	WebIM.call.listener.onInvite(message.from, options);
		// }
  }, // 收到文本消息
  onCmdMessage: function (message) {
    console.log('收到命令消息',message)
    onReceiveMessageHanlder(message);
  }, // 收到命令消息
	onEmojiMessage: function (message) {
		// console.log("onEmojiMessage", message);
		// const { type } = message;
		// type === 'chat' && ack(message);
	}, // 收到表情消息
	onPictureMessage: function (message) {
    // console.log("onPictureMessage", message);
		// const { from, to, type } = message;
		// const chatId = type !== "chat" ? to : from;
		// const typeMap = {
		// 	chat: "contact",
		// 	groupchat: "group",
		// 	chatroom: "chatroom"
		// };
		// vue.$store.commit("updateMsgList", {
		// 	chatType: typeMap[message.type],
		// 	chatId: chatId,
		// 	msg: message.url,
		// 	bySelf: false,
		// 	type: "img",
		// 	from: message.from
		// });
		// type === 'chat' && ack(message);
	}, // 收到图片消息
	// onCmdMessage: function (message) {
	// 	// console.log("onCmdMessage", message);
	// }, // 收到命令消息
	onAudioMessage: function (message) {
    // console.log("onAudioMessage", message);
		// const typeMap = {
		// 	chat: "contact",
		// 	groupchat: "group",
		// 	chatroom: "chatroom"
		// };
		// const chatId = message.type !== "chat" ? message.to : message.from;
		// let options = {
		// 	url: message.url,
		// 	headers: { Accept: "audio/mp3" },
		// 	onFileDownloadComplete: function (response) {
		// 		let objectUrl = WebIM.utils.parseDownloadResponse.call(WebIM.conn, response);
		// 		vue.$store.commit("updateMsgList", {
		// 			chatType: typeMap[message.type],
		// 			chatId: chatId,
		// 			msg: objectUrl,
		// 			bySelf: false,
		// 			type: "audio",
		// 			from: message.from
		// 		});
		// 	},
		// 	onFileDownloadError: function () {
		// 		// console.log("音频下载失败");
		// 	}
		// };
		// WebIM.utils.download.call(WebIM.conn, options);
		// message.type === 'chat' && 	ack(message);
	}, // 收到音频消息
	onLocationMessage: function (message) {
		// console.log("onLocationMessage", message);
		// message.type === 'chat' && 	ack(message);
	}, // 收到位置消息
	onFileMessage: function (message) {
    // console.log("onFileMessage", message);
		// const { from, to, type } = message;
		// const chatId = type !== "chat" ? to : from;
		// const typeMap = {
		// 	chat: "contact",
		// 	groupchat: "group",
		// 	chatroom: "chatroom"
		// };
		// vue.$store.commit("updateMsgList", {
		// 	chatType: typeMap[message.type],
		// 	chatId: chatId,
		// 	msg: message.url,
		// 	bySelf: false,
		// 	type: "file",
		// 	filename: message.filename,
		// 	file_length: message.file_length,
		// 	from: message.from
		// });
		// type === 'chat' && 	ack(message);
	}, // 收到文件消息
	onVideoMessage: function (message) {
		// console.log("onVideoMessage", message);
		// const { from, to, type } = message;
		// const chatId = type !== "chat" ? to : from;
		// const typeMap = {
		// 	chat: "contact",
		// 	groupchat: "group",
		// 	chatroom: "chatroom"
		// };
		// let options = {
		// 	url: message.url,
		// 	headers: {
		// 		Accept: "audio/mp4"
		// 	},
		// 	onFileDownloadComplete: function (response) {
		// 		let objectURL = WebIM.utils.parseDownloadResponse.call(WebIM.conn, response);
		// 		vue.$store.commit("updateMsgList", {
		// 			chatType: typeMap[message.type],
		// 			chatId: chatId,
		// 			msg: objectURL,
		// 			bySelf: false,
		// 			type: "video",
		// 			filename: message.filename,
		// 			file_length: message.file_length,
		// 			from: message.from
		// 		});
		// 	},
		// 	onFileDownloadError: function () {
		// 		// console.log("视频下载失败");
		// 	}
		// };
		// WebIM.utils.download.call(WebIM.conn, options);
		// type === 'chat' && 	ack(message);
	}, // 收到视频消息
	onPresence: function (message) {
		// console.log("onPresence", message);
		// let select_id = vue.$store.state.group.groupInfo.gid; // 群组相关操作，更新数据时需要
		// switch (message.type) {
		// 	case "subscribe":
		// 		let options = {
		// 			isShow: true,
		// 			...message
		// 		};
		// 		vue.$store.commit("changeFriendRequestState", options);
		// 		break;
		// 	case "subscribed":
		// 		vue.$store.dispatch("onGetContactUserList");
		// 		Message({
		// 			type: "success",
		// 			message: message.from + " " + "已订阅"
		// 		});
		// 		break;
		// 	case "unsubscribed":
		// 		vue.$store.dispatch("onGetContactUserList");
		// 		if ("code" in message) {
		// 			alert(message.from + " " + "请求被拒绝");
		// 		}
		// 		else {
		// 			Message({
		// 				type: "success",
		// 				message: message.from + " " + "已退订"
		// 			});
		// 		}
		// 		break;

		// 	case "direct_joined": // 被拉进群--不需要同意
		// 		vue.$store.dispatch("onGetGroupUserList")
		// 		Message({
		// 			type: "success",
		// 			message: `${message.from}邀请您加入群：${message.gid}`
		// 		})
		// 		break;
		// 	case "invite": //收到邀请进群的通知
		// 		let groupInviteOptions = {
		// 			isShow: true,
		// 			...message
		// 		};
		// 		vue.$store.commit("updateGroupInviteNotifications", groupInviteOptions);
		// 		break;
		// 	case "joinGroupNotifications": // 收到申请进群的通知
		// 		let groupOptions = {
		// 			isShow: true,
		// 			...message
		// 		};
		// 		vue.$store.commit("updateGroupNotifications", groupOptions);
		// 		break;
		// 	case "memberJoinPublicGroupSuccess": // 成员加入聊天室成功回调
		// 		vue.$store.dispatch("onGetGroupinfo", { select_id });
		// 		Message({
		// 			type: "success",
		// 			message: `${message.from}已加入群组`
		// 		})
		// 		break;
		// 	case "joinPublicGroupSuccess":  //申请加入群组成功回调
		// 		vue.$store.dispatch("onGetGroupUserList");
		// 		break;
		// 	case "deleteGroupChat": // 解散群组
		// 		vue.$store.dispatch("onGetGroupUserList")
		// 		Message({
		// 			type: "error",
		// 			message: `${message.from}将群：${message.gid} 已解散`
		// 		})
		// 		break
		// 	case "removedFromGroup": //移除
		// 		vue.$store.dispatch("onGetGroupUserList")
		// 		Message({
		// 			type: "success",
		// 			message: "已被" + message.from + "移除群：" + message.gid
		// 		})
		// 		break;
		// 	case "leaveGroup":
		// 		vue.$store.dispatch("onGetGroupinfo", { select_id });
		// 	default:
		// 		break;
		// }
	}, // 处理“广播”或“发布-订阅”消息，如联系人订阅请求、处理群组、聊天室被踢解散等消息
	onRoster: function (message) {
		// console.log("onRoster", message);
	}, // 处理好友申请
	onInviteMessage: function (message) {
		// console.log("onInviteMessage", message);
	}, // 处理群组邀请
	onOnline: function () {
		// console.log("onOnline 网络已连接");
    // console.log(WebIM.conn.token);
    // vue.$store.dispatch('imLogout', true); 
    const nologin = vue.$tools.getCookie('nologin');
    // console.log('-------')
    // console.log(nologin);
    if(nologin){
      vue.$closeConfirm();
      vue.$router.push({ path: "/login" });
      return;
    }
   
    
    setTimeout(()=>{
      // console.log('+++')
      // console.log("start relogin")
      vue.$store.dispatch('reLogin'); 
      // vue.$store.dispatch('imLogin', true); 
    },600)
	}, // 本机网络连接成功
	onOffline: function () {
		// console.log("onOffline 网络已断开");
    // console.log(WebIM.conn.token);
    vue.$store.dispatch('imLogout'); 
	}, // 本机网络掉线
	onError: async function (message) {
    console.log(message);
    // console.log(WebIM.conn.token);
    if (message.type == 0) {
      // console.log('请输入环信账号密码')
    } else if (message.type == 28) {
      // console.log("未登陆环信")
    } else if (message.type == 206){
      // console.log('账号在其他设备登录');
      // 快捷登录[重新登录]
      // vue.$router.push({ path: "/login" });
      await reloadByLoginToOtherDevices();
      return;
    // }else if (message.type == 3 || message.type == 16){
    //   // 重新登录
    //   vue.$store.dispatch('reLogin'); 
    } else if (message.type === 217) {
      await reloadByLoginToOtherDevices();
      return;
    } else  {
      // console.log('onError', message)
      //websocket has been disconnected
      if(message.data){
        if(message.data.data){
          if (JSON.parse(message.data.data).error_description == "user not found") {
            Message.error("环信用户名不存在！")
            vue.$store.dispatch('imRegist');
          } else if (JSON.parse(message.data.data).error_description == "invalid password") {
            // 环信密码错误
            // console.log('环信密码无效！')
            /**重置密码 */
            let reset_res = await resetHXpassword();
            if(reset_res){
              // 刷新用户信息
              await vue.$api.refreshLoginUserInfo(); 
              // 环信登录
              vue.$store.dispatch('imLogin', true); 
            }
            

          } else if (JSON.parse(message.data.data).error_description == "user not activated") {
            Message.error("环信用户已被封禁！")
          } else if (message.type == "504") {
            Message("环信消息撤回失败");
          }
        }
       
      }
    } 
		// 报错返回到登录页面
		// vue.$router.push({ path: '/login' });
	}, // 失败回调
	onRecallMessage: message => {
		// console.log("撤回消息", message);
		// message.status = "recall";
		// message.msg = "对方撤回了一条消息";
		// vue.$store.commit("updateMessageStatus", message);
	},
	onBlacklistUpdate: function (list) { // 黑名单变动
		// 查询黑名单，将好友拉黑，将好友从黑名单移除都会回调这个函数，list则是黑名单现有的所有好友信息
		// 更新好友黑名单
		// console.log("黑名单变动", list);
		// let blackList = list;
		// vue.$store.commit("updateBlackList", blackList);
	},
	onReceivedMessage: function (message) {
		// console.log("onReceivedMessage", message);
		// vue.$store.commit("updateMessageMid", message);
		// message.status = "sent";
		// vue.$store.commit("updateMessageStatus", message);
	}, // 收到消息送达服务器回执

	onDeliveredMessage: function (message) {
		// console.log("onDeliveredMessage", message);
		// vue.$store.commit('updateMessageStatus', message)
	}, // 收到消息送达客户端回执

	onReadMessage: function (message) {
		// console.log("onReadMessage", message);
		// message.status = "read";
		// vue.$store.commit("updateMessageStatus", message);
	}, // 收到消息已读回执

	onCreateGroup: function (message) {
		// console.log("onCreateGroup", message);
		// vue.$store.dispatch("onGetGroupUserList");
	}, // 创建群组成功回执（需调用createGroupNew）
	onMutedMessage: function (message) {
		// console.log("onMutedMessage", message);
	} // 如果用户在A群组被禁言，在A群发消息会走这个回调并且消息不会传递给群其它成员
};


var WebIM = {};
WebIM = window.WebIM = websdk;
WebIM.config = config;
WebIM.conn = new WebIM.connection({
	appKey: WebIM.config.appkey,
	isHttpDNS: WebIM.config.isHttpDNS,
	isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
	https: WebIM.config.https,
	url: WebIM.config.xmppURL,
	apiUrl: WebIM.config.apiURL,
	isAutoLogin: true,
	heartBeatWait: WebIM.config.heartBeatWait,
	autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
	autoReconnectInterval: WebIM.config.autoReconnectInterval,
	isStropheLog: WebIM.config.isStropheLog,
	delivery: WebIM.config.delivery
});
if (!WebIM.conn.apiUrl) {
	WebIM.conn.apiUrl = WebIM.config.apiURL;
}

WebIM.generateConn = () => {
  WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.xmppURL,
    apiUrl: WebIM.config.apiURL,
    isAutoLogin: true,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    isStropheLog: WebIM.config.isStropheLog,
    delivery: WebIM.config.delivery
  });
  if (!WebIM.conn.apiUrl) {
    WebIM.conn.apiUrl = WebIM.config.apiURL;
  }
  WebIM.conn.listen(listen);
}

// 注册监听回调
WebIM.conn.listen(listen);
  


// WebIM.WebRTC = webrtc;
// WebIM.EMedia = emedia;
export default WebIM;
