var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_ap_gift_receiving_card_plugins_receiving_main bbox",
      class: { mobile: _vm.mobile },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "input_group bbox d-flex align-center justify-space-between",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userinfo.phone,
                expression: "userinfo.phone",
              },
            ],
            staticClass: "input bbox",
            attrs: {
              type: "text",
              placeholder: "请输入手机号码",
              maxlength: "11",
            },
            domProps: { value: _vm.userinfo.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.userinfo, "phone", $event.target.value)
              },
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: String(_vm.userinfo.phone).trim() !== "",
                expression: "String(userinfo.phone).trim()!== ''",
              },
            ],
            staticClass: "clear cp",
            attrs: {
              src: require("@/assets/images/personal_center/gift_card/receiving/clear.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.userinfo.phone = ""
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "input_group bbox d-flex align-center justify-space-between",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userinfo.phonecode,
                expression: "userinfo.phonecode",
              },
            ],
            staticClass: "input bbox",
            attrs: {
              type: "text",
              placeholder: "请输入短信验证码",
              maxlength: "6",
            },
            domProps: { value: _vm.userinfo.phonecode },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.userinfo, "phonecode", $event.target.value)
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "get_verification_code cp",
              class: { active: _vm.userinfo.countdown === 0 },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.getVerify()
                },
              },
            },
            [_vm._v(_vm._s(_vm.verify_text))]
          ),
        ]
      ),
      _vm.userinfo.newuser
        ? _c(
            "div",
            {
              staticClass:
                "input_group bbox d-flex align-center justify-space-between",
            },
            [
              _vm.password_type === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userinfo.userPassword,
                        expression: "userinfo.userPassword",
                      },
                    ],
                    staticClass: "input bbox",
                    attrs: {
                      placeholder: "请输入密码",
                      maxlength: "",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.userinfo.userPassword)
                        ? _vm._i(_vm.userinfo.userPassword, null) > -1
                        : _vm.userinfo.userPassword,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.userinfo.userPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.userinfo,
                                "userPassword",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.userinfo,
                                "userPassword",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.userinfo, "userPassword", $$c)
                        }
                      },
                    },
                  })
                : _vm.password_type === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userinfo.userPassword,
                        expression: "userinfo.userPassword",
                      },
                    ],
                    staticClass: "input bbox",
                    attrs: {
                      placeholder: "请输入密码",
                      maxlength: "",
                      type: "radio",
                    },
                    domProps: {
                      checked: _vm._q(_vm.userinfo.userPassword, null),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.userinfo, "userPassword", null)
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userinfo.userPassword,
                        expression: "userinfo.userPassword",
                      },
                    ],
                    staticClass: "input bbox",
                    attrs: {
                      placeholder: "请输入密码",
                      maxlength: "",
                      type: _vm.password_type,
                    },
                    domProps: { value: _vm.userinfo.userPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.userinfo,
                          "userPassword",
                          $event.target.value
                        )
                      },
                    },
                  }),
              _c("div", {
                staticClass: "see cp",
                class: { hide: _vm.show_password === false },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.show_password = !_vm.show_password
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.userinfo.newuser
        ? _c(
            "div",
            {
              staticClass:
                "input_group bbox d-flex align-center justify-space-between",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userinfo.userName,
                    expression: "userinfo.userName",
                  },
                ],
                staticClass: "input bbox",
                attrs: { type: "text", placeholder: "请输入妙行昵称" },
                domProps: { value: _vm.userinfo.userName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.userinfo, "userName", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userinfo.userName.trim() !== "",
                    expression: "userinfo.userName.trim() !== ''",
                  },
                ],
                staticClass: "clear cp",
                attrs: {
                  src: require("@/assets/images/personal_center/gift_card/receiving/clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.userinfo.userName = ""
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.userinfo.newuser
        ? _c(
            "div",
            { staticClass: "read_group d-flex aling-center flex-wrap mt-20" },
            [
              _c("div", {
                staticClass: "select cp",
                class: { active: _vm.userinfo.selecte_aggrement },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.userinfo.selecte_aggrement =
                      !_vm.userinfo.selecte_aggrement
                  },
                },
              }),
              _c("div", { staticClass: "text" }, [_vm._v("已阅读并同意")]),
              _c(
                "div",
                {
                  staticClass: "check",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkService()
                    },
                  },
                },
                [_vm._v("《服务协议》")]
              ),
              _c(
                "div",
                {
                  staticClass: "check",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.checkPrivacy()
                    },
                  },
                },
                [_vm._v("《隐私政策》")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "receive btn_dark mt-20 cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doReceive()
            },
          },
        },
        [_vm._v(_vm._s(_vm.userinfo.newuser ? "注册" : "领取"))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }