var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show_item
    ? _c(
        "div",
        {
          staticClass:
            "c_classes_task_modules_plugins_internal_contact_list_unit d-flex align-center justify-space-between bbox",
        },
        [
          _c("img", {
            staticClass: "avatar flex-shrink-0",
            attrs: {
              src: `${_vm.$avatar_url}${_vm.userinfo.thumbnail}@!small200`,
              alt: "",
            },
          }),
          _c("div", {
            staticClass: "name",
            domProps: { innerHTML: _vm._s(_vm.name) },
          }),
          _c("div", {
            staticClass: "select flex-shrink-0 cp bbox",
            class: { active: _vm.userinfo.selected },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }