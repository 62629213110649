var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_recommend_modules_address_book_prompt bbox un_sel" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("通过手机通讯录添加更多妙行联系人"),
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(
          "通过妙行APP，您可以查看在您手机通讯录中已经注册妙行的联系人，并向他们申请好友请求。（此功能需要通过妙行获取通讯录权限）"
        ),
      ]),
      _c(
        "div",
        { staticClass: "progress_group d-flex align-stretch" },
        [
          _vm._l(_vm.progress_list, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "progress d-flex flex-column align-center flex-shrink-0 bbox",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "num d-flex justify-center align-center flex-shrink-0",
                    },
                    [_vm._v(_vm._s(item.progress))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "content_group d-flex flex-column align-center bbox",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "desc flex-shrink-0 d-flex align-center",
                        },
                        [_vm._v(_vm._s(item.descript))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "img_group d-flex align-center justify-center",
                        },
                        [
                          _c("img", {
                            staticClass: "img",
                            attrs: {
                              src: require(`@/assets/images/recommend/address_book_prompt/progress${item.progress}.png`),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass:
            "operation_group bbox d-flex justify-space-between align-center",
        },
        [
          _c(
            "div",
            {
              staticClass: "nomore_prompt d-flex align-center cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.selected = !_vm.selected
                },
              },
            },
            [
              _c("div", {
                staticClass: "select_box",
                class: { active: _vm.selected },
              }),
              _c("div", { staticClass: "text" }, [_vm._v("不再提醒")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "button btn_light",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doClose.apply(null, arguments)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }