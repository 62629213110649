<template>
  <div
    class="components_popup_record_of_communication_file_file_page bbox d-flex align-stretch"
  >
    <div class="info_group">
      <div class="file_group p-10 d-flex align-start" >
        <div class="icon mr-10"
          :class="[item.getExtName()]"
        ></div>
        <div class="info">
          <div class="name pb-4 cp"
            v-html="item.highlightKeywords({keyword})"
            @click="item.previewFile({type: 2})"
          >Apple公司年会要求-三亚_活动报批文件.xlsx,Apple公司年会要求-三亚_活动报批文件.xlsx,Apple公司年会要求-三亚_活动报批文件.xlsx</div>
          <div class="detail_group d-flex align-center">
            <div class="size pr-10"
              v-text="item.formatFilesize()"
            >200K</div>
            <div class="time"
              v-text="$moment(item.createTime).format('YYYY/MM/DD HH:mm')"
            >2021/06/30 16:57</div>
          </div>
        </div>
        <div class="download flex-shrink-0 ml-10 mt-2 cp"
          @click="item.download({type: 2});"
        ></div>
      </div>
      <div class="task_group mt-10 cp">
        <div class="task_name"
          v-text="`任务: ${item.taskname}`"
          @click="item.openTask({config: {edit: true}});"
        >任务：Apple公司年会 / 控件：参考文件</div>
      </div>

    </div>
    <div class="fun_group ml-10 flex-shrink-0 d-flex flex-column justify-space-between align-start bbox">
      <div class="upload_user">
        <!-- 上传:水电费空间的还是分开 -->
      </div>
      <div class="check cp"
        @click="item.openTask({config: {edit: true}});"
      >查看任务</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    keyword: {
      type: String,
      default: '',
    },
  }
}
</script>

<style lang="scss" scoped>
@mixin file_icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
.components_popup_record_of_communication_file_file_page{
  width: 100%;
  .info_group{
    flex: 1;
    .file_group{
      background-color: #fff;
      border-radius: 4px;
      .icon{
        background-image: url(~@/assets/images/task/unknown.png);
        width: 32px;
        height: 32px;
        margin-right: 10px;
        @include file_icon(doc);
        @include file_icon(xls);
        @include file_icon(ppt);
        @include file_icon(mp4);
        @include file_icon(pic);
        @include file_icon(zip);
        @include file_icon(pdf);
        display: block;
      }
      .info{
        flex: 1;
        .name{
          font-size: 14px;
          color: #333;
          line-height: 20px;
        }
        .detail_group{
          font-size: 12px;
          color: #9f9f9f;
        }
      }
    }
    .download{
      width: 16px;
      height: 16px;
      background-image: url(~@/assets/images/task/widget_download_button.png);
    }
  } 
  .task_group{
    @include transition;
    padding: 7px 12px;
    background-color: #eee;
    font-size: 12px;
    color: #666;
    border-radius: 4px;
    &:hover{
      background-color: #dfdfdf;
    }
  }
  .fun_group{
    font-size: 12px;
    color: #999;
    padding: 4px 0 6px;
    width: 80px;
    overflow: hidden;
    .upload_user{
      width: 100%;
      @include single_line_intercept;
    }
    .check{
      @include transition;
      &:hover{
        color: $main_blue;
      }
    }
  }
}
</style>