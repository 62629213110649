<template>
  <div
    v-if="selectList"
    class="c_pop_up_batch_add_contacts_to_tasks_select_task_task_list bbox"
  >
    <div class="list_group d-flex flex-wrap bbox">
      <template
        v-for="(item, index) in selectList"
      >
        <thumbnail-task
          :key="index"
          :p-task="item"
          :user-info-simple-pmcs="userInfoSimplePmcs"
        ></thumbnail-task>
      </template>
    </div>
    <div class="more_group d-flex align-center justify-center">
      <div
        v-show="showMore && !showLoading"
        class="more cp"
        @click.stop="loadmore()"
      >
        加载更多
      </div>
      <loading-icon
        v-show="showLoading"
        size="26"
      ></loading-icon>
    </div>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/PopUp/BatchAddContactsToTasks/SelectTask/ThumbnailTask'
import LoadingIcon from '@/components/basic/loadingIcon'
export default {
  name: 'TaskList',
  components: {
    LoadingIcon,
    ThumbnailTask
  },
  props: {
    userInfoSimplePmcs: {
      type: Object,
      default: undefined
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showMore() {
      const {
        userInfoSimplePmcs
      } = this
      if (!userInfoSimplePmcs) {
        return false
      }
      return userInfoSimplePmcs[userInfoSimplePmcs.getMoreFieldName()]
    },
    selectList () {
      const {
        userInfoSimplePmcs,
        listFieldName
      } = this
      return userInfoSimplePmcs[listFieldName]
      // const { selectList } = userInfoSimplePmcs
      // return selectList
    },
    listFieldName () {
      const { userInfoSimplePmcs } = this
      if (userInfoSimplePmcs) {
        return userInfoSimplePmcs.getListFieldName()
      }
      return 'searchList'
    }
  },
  methods: {
    loadmore() {
      this.$emit('handleLoadmore')
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_batch_add_contacts_to_tasks_select_task_task_list{
  width: 100%;
  padding: 20px;
  .list_group{
    width: 100%;
  }
  .more_group{
    width: 100%;
    height: 40px;
    .more{
      font-size: 14px;
      color: $main_blue;
    }
  }
}
</style>
