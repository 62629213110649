var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "pop_up_dialog_send_files_progress d-flex flex-column align-center bbox justify-center",
    },
    [
      _c("div", { staticClass: "progress_group" }, [
        _c("div", {
          staticClass: "progress",
          style: { width: `${_vm.progress}%` },
        }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n    正在上传 " + _vm._s(_vm.progress) + "%\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }