/*
 * @Author       : Hugo
 * @Date         : 2022-04-01 10:14:33
 * @LastEditTime : 2022-04-01 14:29:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/WebIM/module/widget.js
 * @Description  : 
 * @^_^          : 
 */
import store from '@/store'
import {PScreen} from '@/types'
import types from '@/utils/types'

class Widget{
  changeData = async (ext)=>{
    let {
      details,
      taskId,
      updateuserthumbnail:thumbnail,
      updateusername: userName,
      updateUserId: userId,
    } = ext
    const {state} = store
    const {task} = state
    if(Number(task?.task?.id) !== Number(taskId)){
      return
    }
    details = JSON.parse(details)
    if(details && details.length){
      details.forEach(detail => {
        if(Number(detail.controlType) === 11){
          this.updateWisdom({detail, thumbnail, userName, userId})
        }
      });
    }
  }
  /**
   * 更新智慧屏数据(正在打开的任务 更新智慧屏是否投屏及相关样式)
   */
  updateWisdom = async ({detail, thumbnail, userName, userId})=>{
    const {state} = store
    const {task} = state
    const {taskDeatails:list} = task
    const {conId,screenList, type} = detail
    let screenstatus = 0
    if(Number(type) === 4){
      screenstatus = 1
    }
    if(Number(type) === 5 || Number(type) === 6){
      screenstatus = 0
    }
    if(list && list.length){
      list.find(item=>{
        if(Number(item.id) === Number(conId)){
          item.screenuserid        = userId
          item.screenstatus = screenstatus
          item.screenusername      = userName
          item.screenuserthumbnail = thumbnail
          item.screenList = types.transformObjectForList({
            list: screenList,
            obj : PScreen
          })
          // return true
        }
        const {controlLinkList} = item
        if(controlLinkList && controlLinkList.length){
          controlLinkList.find(unit=>{
            if(Number(unit.id) === Number(conId)){
              unit.screenuserid        = userId
              unit.screenstatus = screenstatus
              unit.screenusername      = userName
              unit.screenuserthumbnail = thumbnail
              unit.screenList = types.transformObjectForList({
                list: screenList,
                obj : PScreen
              })
            }
          })
        
        }
      })
    
    
      
    }


  }

}

const widget = new Widget()

export default widget