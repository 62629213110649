var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "basic_avatar_detail" }, [
    _c(
      "div",
      {
        staticClass: "content cp",
        style: { width: _vm.width },
        attrs: { slot: "reference" },
        on: { click: _vm.getUserInfo },
        slot: "reference",
      },
      [
        _c("img", {
          staticClass: "avatar",
          style: { width: _vm.width, height: _vm.height },
          attrs: {
            src: `${_vm.$avatar_url}${_vm.msg_data.thumbnail}@!small200`,
            alt: "",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }