/*
 * @Author       : Hugo
 * @Date         : 2021-12-23 15:50:15
 * @LastEditTime : 2022-04-26 16:01:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PSelected/index.js
 * @Description  : 
 * @^_^          : 
 */
import PPackage from '@/types/modules/PPackage'
import PTask from '@/types/modules/PTask'
class PSelected{
  content    = undefined
  createTime = undefined
  id         = undefined
  pic        = undefined
  ppackage   = undefined
  sort       = undefined
  task       = undefined
  title      = undefined
  type       = undefined
  userInfo   = undefined
  constructor(props={}){
    const {
      content,
      createTime,
      id,
      pic,
      ppackage,
      sort,
      task,
      title,
      type,
      userInfo,
    } = props

    this.content    = content
    this.createTime = createTime
    this.id         = id
    this.pic        = pic
    this.sort       = sort
    this.title      = title
    this.type       = type
    this.userInfo   = userInfo

    if(ppackage){
      this.ppackage = new PPackage(ppackage)
    }else{
      this.ppackage = ppackage
    }
    if(task){
      this.task = new PTask(task)
    }else{
      this.task = task
    }
  }

  getFree = async () => {
    const {ppackage, task} = this
    if(ppackage){
      return await ppackage.getFree()
    }
    if(task){
      return await task.getFree()
    }
  }
  
}

export default PSelected
