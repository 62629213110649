import search from './stateModule/search';
import app from './stateModule/app';
import task from './stateModule/task';
import user from './stateModule/user';
import dialog from './stateModule/dialog';
import shopping_mail from './stateModule/shopping_mail';
import wisdom from './stateModule/wisdom';
import ctask from './stateModule/ctask';
import collect from './stateModule/collect'
const basic= {
  /** *************** 全局重要 ************* */
  login_user_id: '',
  /** *************** END 全局重要 ************* */
  /**
   * 添加联系人拉人到群聊
   */
  add_contact_to_dialog_box: false,
  /**
   * 选择联系人类型
   * create创建对话 /
   */
  add_contact_dialog_box_type: '',
  /**
   * 沟通记录盒子
   */
  communication_record_box: false,
  /**
   * 联系人信息(包括工作组)
   */
  contact_data: null,
  /**
   * 工作组信息
   */
  workteam_data: null,
  /**
   * 关闭所有弹出框
   */
  close_all_popover: false,

  /************************ START 公共方法数据 ***********************/
  prevent_duplicate_clicks: false,
  // 公共弹层
  switch_popup_common: false,
  // 公共弹层类型
  popup_common_type: '',
  /************************ END 公共方法数据 ***********************/
  /************************ START 公共数据 ***********************/
  search_task: null,
  search_keyword: '',
  dialog_fun_status: 'template', // 聊天功能状态 'template /text '
  dialog_fun_chat_data: null, // 会话聊天数据 [是否回复工作组消息]
  /************************ END 公共数据 ***********************/
  /************************ START 公共数据 ***********************/
  // 是否允许返回
  allow_back: true,
  // 消息盒子
  message_box_list: null,
  // 消息盒子 (单项)
  message_box_unit: null,
  // 好友列表
  friends_list: null,
  // 好友列表(按首字符分组)
  friends_list_group: null,
  // 工作组列表
  workteam_list: null,
  // 新建/编辑任务模板(打开关闭盒子)
  edit_task_box: false,
  // 任务id
  edit_task_id: null,
  // 任务数据列表(全部)
  edit_task_data: null,
  // 任务数据列表(全部)
  edit_task_data_list: [],
  // 任务相关users
  edit_task_users: null,
  // 任务记录
  task_log: null,
  /**
   * 审核教学模板状态
   * start | 开始
   *
   *
   */
  aduit_teaching: {
    status:'start',
  },
  /**教学提示 */
  hint_teaching: false,
  /**
   * 教学价格
   */
  teaching_price: 0,
  //  最近模板列表 / 全部模板列表
  my_task_list: null,
  /**
   * 消息列表
   * 包含dialog_user_list和message_list
   */
  message_list_data: null,

  // 聊天用户列表
  dialog_user_list: null,
  // 消息列表
  message_list: null,



  // 当前会话id
  chat_id: null,

  // 默认布局弹层zindex
  default_floor_z_index: 1000,

  /************************ END 公共数据 ***********************/



  /************************ 消息提示 ***********************/
  // 新的申请
  new_apply: false,
  // 任务类
  new_task: false,
  // 联系人
  new_contact: false,
  // 模板
  new_template: false,

  /************************ END 消息提示 ***********************/

  /************************ 省市县 ***********************/
  province: null,
  city: null,
  county: null,
  province_list: [],
  city_list: [],
  county_list: null,

  /************************ END 省市县 ***********************/

  /************************ 用户信息 ***********************/
  // 单人
  user_data: null,
  // 列表
  user_list: null,
  // 当前用户
  user: null,
  // 工作组用户列表
  chat_user_list: null, //TODO  待删除(先判断是否无引用)

  // 替代 chat_user_list
  dialog_users: {
    chat_id: null,
    chat_type: null,
    list: null,
  },
  /************************ END 用户信息 ***********************/

  /************************ 聊天相关 ***********************/
  // 聊天基本信息
  dialog_data: null,
  /**会话详情 */
  chat_detail: null,
  /************************ END 聊天相关 ***********************/

  /************************ 任务相关 ***********************/



  // 是否新模板
  is_new_task: false,
  //任务是否被编辑过
  moderated_task: false,
  // 任务盒子开关
  switch_task_box: null,
  // 任务id
  task_id: '',
  // 任务数据
  task_data: {},
  // 任务详情
  task_details: [],
  // 切换范本和实例
  switch_teach: false, //范本false/实例true
  // 教学任务数据
  task_teach_data: {},
  // 教学任务详情
  task_teach_details: [],
  // 任务相关用户
  task_users: [],
  // 任务发布者id(群主)
  task_lord_id: '',
  // 任务编辑状态
  /**
   * '' => 默认
   * clear => 抹除当前数据(id, 文件id, 相关用户) 教学信息保留  触发情景:[全部模板,]
   * clearuser => 仅抹除用户 用于taskType = 2编辑时
   */
  task_edit_status: '',
  /**
   * 任务选择联系人数据[我的模板频道]
   * {
   *
   * }
   */
  task_select_users_data: {
    chatId: '',
    chatType: '',
    taskId: '',
    taskType: '',
    taskUsers: [],
  },
  // 已读
  task_read_users: [],
  // [快读定位] 最新任务单id
  task_latest_control_id: null,
  // 指定任务单id
  task_designation_control_id: null,
  // 任务需要带上关联数据[每次打开任务时赋值, 获取到数据时对数据进行处理(清除所有id)]
  task_need_relevancy: false,
  // 任务面板
  /**
   * control 控件
   * record 记录
   * teaching 教学
   * chat 沟通
   * control_group 控件组
   */
  task_pannel: 'control',
  // 任务是否有未读沟通消息
  task_unreader_chat: false,
  // 任务查看类型 1==> 小妙推送
  task_see_type: '',
  // 是否是调用模板
  task_call: false,
  // 重选发送的聊天
  task_reelect_dialog: false,
  // 预览任务
  task_preview: false,
  /************************ END 任务相关 ***********************/

  /************************ 任务沟通 ***********************/
  // 消息列表
  task_chat_message_list: [],
  // 触发滚动到底部
  task_chat_trigger_scroll_to_bottom: false,
  // 定时器
  task_chat_heartbeat_interval: null,
  // 心跳是否开启
  task_chat_heartbeat_status: false,
  /************************ END 任务沟通 ***********************/

  /************************ 公示板相关 ***********************/
  // 公示板开关
  annc_switch: false,
  // 公示板数据
  annc_data: {},
  // 公示板详情列表
  annc_details: [],
  // 公示板是否被编辑过
  annc_modified: false,
  // 选择联系人
  switch_annc_selectbox: false,
  /************************ END 公示板相关 ***********************/

  /************************ 侧边栏相关 ***********************/
  // 开关
  switch_sidebar: false,
  // 列表
  sidebar_list: [],

  /************************ END 侧边栏相关 ***********************/

  /************************ 激活码相关 ***********************/
  switch_acti_confirm: false, // 开关
  acti_code: '', // 激活码
  acti_confirm_data: {}, // 激活码确认数据[弹窗]
  acti_status: '', // 激活状态[done]时重新加载激活列表
  /************************ END 激活码相关 ***********************/

  /************************ 分享相关 ***********************/
  // [开关]多选联系人
  switch_multiple_contacts: false,
  // [开关]分享确认框
  switch_share_confirm: false,
  // 下一级开关store.state的名称, 如果存在 则在关闭switch_multiple_contacts时打开下一级的弹窗
  next_switch_multiple_contacts: '',
  // 被分享的任务/教学包数据
  share_data: null,
  // 分享描述
  share_work: '',
  // 多选数据
  multiple_contacts_list: null,
  // 确定回调
  multiple_contacts_callback: null,
  /************************ END 分享相关 ***********************/
  /************************ 教学包[分享打开] ***********************/
  // [开关]教学包
  switch_teaching_package: false,
  // [id]教学包
  teaching_package_id: '',


  /************************ END 教学包 ***********************/

  /************************ 创建/编辑教学包 ***********************/
  // 教学包盒子开关
  switch_package_box: false,
  // 教学包信息
  package_data: null,
  // 教学包列表
  package_list: null,
  // 搜索教学包列表
  package_search_list: null,
  // 教学包模板列表
  package_task_list: null,
  // 教学包模板选择列表
  package_task_select_list: null,
  // 教学包是否被编辑
  package_modified: false,

  /************************ END 创建/编辑教学包 ***********************/
  /************************ 自定义文件夹 ***********************/
  // 文件夹数据
  custom_folders: [],
  // 文件数据[任务]
  custom_files: [],
  // 选择任务
  switch_select_tasks: false,
  // 待增加任务
  custom_to_add_tasks: [],
  // 待删除的任务
  custom_to_delete_tasks: [],
  // 多选文件夹
  switch_custom_multiple_folder: false,
  // 多选文件
  switch_custom_multiple_file: false,
  // 重新加载文件夹数据 [开关]
  switch_reload_custom_folders: false,
  // 重新加载文件数据 [开关]
  switch_reload_custom_files: false,

  // 移动后关闭任务菜单
  close_task_menu_of_moving: false,


  /************************ END 自定义文件夹 ***********************/
  /************************ UTILS ***********************/
  // 确认对话框输入的内容
  utils_prompt:'',
  /************************ END UTILS ***********************/
  /************************ Popup 弹出框 ***********************/

  /************************ END Popup 弹出框 ***********************/
  /************************ 筛选文件夹 ***********************/
  // 筛选列表的起始id [为点击元素的父id]
  filter_folder_origin_folderid: 0,
  // 筛选已选列表点击元素的id
  filter_folder_selected_breakpoint_id: -1,
  filter_folder_select_origin_list: null,
  // 筛选列表跳转到最右
  filter_folder_scroll_to_right: false,
  // 筛选已选择列表跳转到最右
  filter_selected_scroll_to_right: false,
  // 筛选 已选择断点 (子元素在顶层)
  filter_folder_selected_breakpoint: null,
  // 筛选列表记录 (父元素在顶层)
  filter_folder_select_list_record: null,

  switch_filter_folder_select: false,

  // 移动路径 跳转到最右
  moving_path_scroll_to_right: false,
  // 移动路径 [开关]
  switch_moving_path: false,
  /************************ END 筛选文件夹 ***********************/
  /************************ 草稿箱 ***********************/
  // 草稿id
  draft_id: null,
  // 创建草稿教学
  create_draft_teaching: false,
  // 编辑草稿教学
  edit_draft_teaching: false,
  // 草稿列表
  draft_list: [],


  /************************ END 草稿箱 ***********************/
  /************************ 提醒 ***********************/
  // 提醒列表
  reminder_list: null,
  /************************ END 提醒 ***********************/
  /************************ System 系统 ***********************/
  system_update_notify: false, // 系统更新提示
  /************************ END System 系统 ***********************/
  /************************ 回收站 ***********************/
  recycle_delete_id: '', // 从列表中删除项id
  recycle_clear_all: false, // 全部清空

  /************************ END 回收站 ***********************/



}


const state = Object.assign(
  {},
  basic,
  search,
  app,
  task,
  user,
  dialog,
  shopping_mail,
  wisdom,
  ctask,
  collect,
)



export default state;
