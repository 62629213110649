var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_migrate_task_select_task_task_list d-flex align-center flex-wrap",
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c("thumbnail-task", {
            key: index,
            attrs: { "p-task": item, "page-data": _vm.pageData },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }