/*
 * @Author       : Hugo
 * @Date         : 2022-03-11 17:06:04
 * @LastEditTime : 2022-03-14 11:52:25
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/ctask/index.js
 * @Description  : 
 * @^_^          : 
 */
import {open} from './modules/index'
import vue from '@/main'
import { LargeTask } from '@/classes'
class CTask{
  open = async (params={}) => {
    const { id } = params
    if (id) {
      const res = await open.loadTask({ id })
      if(res){
        const { data, backup } = res
        // console.log({res,data, backup})
        vue.$task({
          large_task: new LargeTask({ data,backup })
        })
      }
      return
    }
    // 新建
    open.createNewTask()
  }
}

const ctask = new CTask()

export default ctask