// import TaskInfo from '@/models/task/task_info'
import { PTask } from '@/types/index.js'
class FolderTask {
  cource
  createTime
  folderId
  id
  task
  taskId
  type
  userId

  constructor (params) {
    const {
      cource,
      createTime,
      folderId,
      id,
      task,
      taskId,
      type,
      userId,
    } = params ?? {}
    this.cource = cource
    this.createTime = createTime
    this.folderId = folderId
    this.id = id
    // this.task = task
    this.taskId = taskId
    this.type = type
    this.userId = userId
    if (task) {
      this.task = new PTask(task)
    }
  }
}

export default FolderTask
