<template>
  <tr>
    <td>
      {{ type }}
    </td>
    <td>
      {{ pVipRecord.days }}
    </td>
    <td>
      {{ createTime }}
    </td>
    <td>
      {{ endTime }}
    </td>
  </tr>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Line',
  props: {
    pVipRecord: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    type() {
      const { pVipRecord } = this
      if (!pVipRecord) {
        return ''
      }
      const { type } = pVipRecord
      switch (type) {
        case 1:
          return '购买'
        case 2:
          return '激活码'
        case 3:
          return '礼品卡'
        case 4:
          return '后台开通'
        default:
          return ''
      }
    },
    createTime() {
      const { pVipRecord } = this
      if (!pVipRecord) {
        return ''
      }
      const { createTime } = pVipRecord
      return moment(Number(createTime)).format('yyyy-MM-DD HH:mm')
    },
    endTime() {
      const { pVipRecord } = this
      if (!pVipRecord) {
        return ''
      }
      const { endTime } = pVipRecord
      return moment(Number(endTime)).format('yyyy-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped lang="scss">
td{
  padding: 0;
  text-align: center;
  color: #333;
  line-height: 40px;
  border-right: 1px solid #E7EAED;
  border-bottom: 1px solid #E7EAED;
  &:last-child{
    border-right: none;
  }
}
</style>