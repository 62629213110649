import vue from '@/main';
class Reminder{
  showTime(item){
    const {ptype, remindTime, startTime, endTime, rangee} = item;
    if(ptype == 1){
      return vue.$moment(new Date(Number(remindTime))).format('YYYY-MM-DD HH:mm');
    }
    let str = '';
    if(startTime && endTime){
      str += '周期内';
    }
    if(ptype == 2){
      str += '每天 ';
      str += vue.$moment(new Date(Number(remindTime))).format('HH:mm');
    }
    if(ptype == 3 || ptype == 4){
      if(ptype == 3){
        str += '每周';
      }
      if(ptype == 4){
        str += '每月';
      }
      str += `${rangee} `;
      str += vue.$moment(new Date(Number(remindTime))).format('HH:mm');
    }
    // console.log(str);
    return str;
  }

  getUserList(item){
    const {toUsers} = item;
    const arr = toUsers.split(',');
    const {task_config} = vue.$store.state;
    const {users} = task_config;
    // // console.log(users);
    let list = [];
    users.forEach(unit=>{
      const is_in = arr.find(cell=>{
        return cell == unit.userId;
      })
      if(is_in !== undefined){
        list.push(unit);
      }
    })
    // // console.log(list);
    return list;
  }

  async getReminderList(){
    const res = await vue.$global.doPost({
      url: '/remind/remindList',
      openNotice: false,
    })
    if(res.message === 'success'){
      const {contents} = res;
      const {remindlist} = contents;
      vue.$store.commit('set_reminder_list', remindlist);
      remindlist.forEach((item, index)=>{
        // if(index<3){
          vue.$reminder_notice({data: item, index});
        // }
      })
    }
  }

  // 消息已读
  async readed(remind){
    const data = {
      chat_id: remind.chatId,
      chat_type: remind.chatType,
      // reminduserId: remind.id,
      remindId: remind.id,
    }
    const res = await vue.$global.doPost({
      url: '/remind/doRemind',
      data,
      openNotice: false,
    })

    return res;
  }
  // 是否已经打开任务
  isOpenTask(){
    const {switch_task_box} = vue.$store.state;
    return switch_task_box;
  }


}

const reminder = new Reminder();

export default reminder;