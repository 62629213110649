/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-02 00:14:19
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 11:11:32
 * @FilePath     : /miaohang/src/utils/plugin/modules/personal_center/gift_card_confirm_purchase.js
 * @Description  : 
 */


import PluginComponent from '@/views/PersonalCenter/GiftCard/Plugins/ConfirmPurchase/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openGiftCardConfirmPurchase = (params={}) => {
      // // console.log(obj)
      const {title, pcard, gift_card} = params
      instance.title = title ?? '礼品卡购买'
      instance.pcard = pcard
      instance.gift_card = gift_card
      instance.visible = true
    }
    Vue.prototype.$closeGiftCardConfirmPurchase = () => {
      instance.visible = false
    }
  }
}

export default PluginMember