var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-con" },
    [
      _c("div", { staticClass: "sign-conl" }),
      _vm._m(0),
      _c("div", { staticClass: "sign-conr" }, [
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "i-box" }, [
            _c("img", {
              staticClass: "img2",
              attrs: { src: require("../../assets/icon-mobile.png"), alt: "" },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userName,
                  expression: "userName",
                },
              ],
              staticClass: "phone",
              attrs: {
                type: "text",
                placeholder: "请输入手机号码",
                maxlength: "11",
              },
              domProps: { value: _vm.userName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.userName = $event.target.value
                },
              },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.userName,
                  expression: "userName",
                },
              ],
              staticClass: "shut cp",
              attrs: { src: require("../../assets/icon-shut.png"), alt: "" },
              on: { click: _vm.shut },
            }),
          ]),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "i-box", staticStyle: { "margin-top": "56px" } },
            [
              _c("img", {
                staticClass: "img2",
                attrs: {
                  src: require("../../assets/icon-password.png"),
                  alt: "",
                },
              }),
              _vm.sel
                ? _c("div", [
                    _vm.types === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: {
                            placeholder: "请输入密码",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.password)
                              ? _vm._i(_vm.password, null) > -1
                              : _vm.password,
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                            change: function ($event) {
                              var $$a = _vm.password,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.password = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.password = $$c
                              }
                            },
                          },
                        })
                      : _vm.types === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: { placeholder: "请输入密码", type: "radio" },
                          domProps: { checked: _vm._q(_vm.password, null) },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                            change: function ($event) {
                              _vm.password = null
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: { placeholder: "请输入密码", type: _vm.types },
                          domProps: { value: _vm.password },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.login.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "sel cp",
                      attrs: {
                        src: require("../../assets/icon-sel.png"),
                        alt: "",
                      },
                      on: { click: _vm.showps },
                    }),
                  ])
                : _c("div", [
                    _vm.types === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.password)
                              ? _vm._i(_vm.password, null) > -1
                              : _vm.password,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.password,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.password = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.password = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.password = $$c
                              }
                            },
                          },
                        })
                      : _vm.types === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm._q(_vm.password, null) },
                          on: {
                            change: function ($event) {
                              _vm.password = null
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "password",
                          attrs: { type: _vm.types },
                          domProps: { value: _vm.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "sel cp",
                      attrs: {
                        src: require("../../assets/icon-eye.png"),
                        alt: "",
                      },
                      on: { click: _vm.showps },
                    }),
                  ]),
            ]
          ),
          _c("div", { staticClass: "line1" }),
          _c("p", {
            staticClass: "cuowu",
            domProps: { innerHTML: _vm._s(_vm.userNameWarn) },
          }),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "login_btn",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.isBtnLoading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.login.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("登录")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "forgetps_btn",
                  attrs: {
                    type: "primary",
                    round: "",
                    loading: _vm.isBtnLoading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.forgetPassword.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("忘记密码")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "rudeu" }, [
            _c("p", { staticClass: "automatic" }, [_vm._v("30天内自动登录")]),
            _c("p", { staticClass: "letgo" }, [
              _c("a", { staticClass: "cp", on: { click: _vm.zhuce } }, [
                _vm._v("立即注册"),
              ]),
            ]),
            _vm.nor
              ? _c("img", {
                  staticClass: "nor",
                  attrs: { src: require("../../assets/icon-nor.png"), alt: "" },
                  on: { click: _vm.norc },
                })
              : _c("img", {
                  staticClass: "nor",
                  staticStyle: { left: "-311px" },
                  attrs: { src: require("../../assets/icon-dui.png"), alt: "" },
                  on: { click: _vm.norc },
                }),
          ]),
        ]),
      ]),
      _vm.switch_identification_code
        ? _c("identification-code", {
            attrs: {
              loginUserCode: JSON.parse(_vm.loginUserCode),
              user: _vm.user,
            },
            on: {
              handleClose: function ($event) {
                return _vm.closeIdentificationCode()
              },
              handleSuccess: function ($event) {
                return _vm.identifySuccess()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign-conc" }, [
      _c("div", { staticClass: "sign-contop" }, [
        _c("img", {
          staticClass: "logo",
          staticStyle: { top: "1px" },
          attrs: { src: require("../../assets/logo.png"), alt: "" },
        }),
        _c("h2", [_vm._v("妙行 | 让沟通更专业")]),
      ]),
      _c("h3", [_vm._v("WELCOME")]),
      _c("p", { staticClass: "wb" }, [
        _vm._v("Project Management Communication System"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }