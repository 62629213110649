var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_plugin_preview_picture_page" },
    [
      _c("el-image", {
        directives: [
          { name: "show", rawName: "v-show", value: 0, expression: "0" },
        ],
        ref: "image",
        attrs: { src: _vm.url, "preview-src-list": _vm.list },
        nativeOn: {
          click: function ($event) {
            _vm.setImgZindex()
            _vm.$api.stopPropagation($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }