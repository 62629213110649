var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat_common_page" }, [
    _c("div", { staticClass: "content", class: [_vm.type] }, [
      _vm._v(_vm._s(_vm.msg.work)),
    ]),
    _vm.msg.logType !== 4
      ? _c(
          "div",
          {
            staticClass: "reply cp un_sel",
            class: [_vm.type],
            on: {
              click: function ($event) {
                _vm.$api.stopPropagation($event)
                _vm.replyTaskChat()
              },
            },
          },
          [_vm._v("回复")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }