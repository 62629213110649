var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_teaching_package_selected_task_list_opertaion_bar bbox d-flex justify-end",
    },
    [
      _c("div", { staticClass: "btn_group d-flex" }, [
        _c(
          "div",
          {
            staticClass: "btn_light btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doClose.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      取消\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "btn_dark btn",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doSure.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n      确定\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }