/*
 * @Author: Hugo
 * @Date: 2020-06-16 10:44:30
 * @LastEditTime : 2020-10-30 15:38:50
 * @LastEditors  : Hugo
 * @Description: 控件处理
 * @FilePath     : /miaohang/src/api/control.js
 * @^_^
 */ 
import vue from '@/main.js';
let control = {
  /**
   * 更改任务单项控件数据
   * @param {Arror} originArr | 原数组
   * @param {Number} cid | 更改控件cid
   * @param {Object} controlData | 要更改的控件数据
   */
  control_set_task_control_data(originArr, cid, controlData){
    let index = originArr.findIndex( item => item.cid == cid);
    originArr[index] = controlData;
    // vue.$store.commit('set_edit_task_data_list',originArr);
    vue.$store.commit('set_task_details',originArr);
  },
  /**
   * 保留字段
   * @param {Object} controlData | 控件数据
   * @param {Arrar} reservedFields | 需要保留的字段
   */
  control_reserved_fields(controlData, reservedFields){
    for(let i in controlData){
      // 不包含 删除 为空删除
      if(!reservedFields.includes(i) || !controlData[i]){
        delete controlData[i];
      }
    }
    return controlData;
  },
};

export default control;