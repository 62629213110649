<template>
  <div
    class="c_teaching_package_select_task_operation_bar d-flex justify-space-between bbox"
  >
    <div
      class="select_group d-flex"
      :class="{ cp: number }"
      @click.stop="checkSelected"
    >
      <div class="text">
        已选：
      </div>
      <div class="number">
        {{ number }}个
      </div>
      <img
        src="~@/assets/images/teaching_package/select_task/select_number.png"
        alt=""
        class="icon ml-10">
    </div>
    <div class="btn_group d-flex">
      <div
        class="btn_light btn"
        @click.stop="doClose"
      >
        取消
      </div>
      <div
        class="btn_dark btn"
        @click.stop="doSure"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OperationBar',
  props: {
    pPackage: {
      type: Object,
      default: undefined
    },
    packageSelectTask: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    number() {
      const { packageSelectTask } = this
      const { selectedList } = packageSelectTask
      return selectedList.length
    }
  },
  methods: {
    checkSelected() {
      const { number, pPackage } = this
      if (number !== 0) {
        this.$openTeachingPackageSelectedTaskList({
          pPackage
        })
      }
    },
    doClose() {
      this.$closeTeachingPackageSelectTask()
    },
    doSure() {
      const { packageSelectTask } = this
      const { selectedList } = packageSelectTask
      packageSelectTask.taskList = selectedList
      console.log(packageSelectTask)
      this.doClose()
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_operation_bar{
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.1);
  border-radius: 0 0 10px 10px;
  padding: 0 20px;
  .select_group{
    font-size: 14px;
    color: #333;
  }
  .btn_group{
    .btn{
      cursor: pointer;
      font-size: 14px;
      line-height: 40px;
      border-radius: 4px;
      padding: 0 26px;
      margin-left: 12px;
    }
  }
}
</style>