var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible && 0
    ? _c(
        "div",
        [
          _c("plugin-component", {
            attrs: { title: _vm.title },
            on: { handleClose: _vm.doClose },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }