<template>
  <div
    class="components_task_fun_bar_button_group_page d-flex align-center"
  >
    <div class="button draft"
      @click="seveDraft"
    >保存草稿</div>
    <div class="button btn_dark send"
      @click="sendTask"
    >发布任务</div>
    <div class="button btn_dark send"
      @click="doUse"
    >我要使用</div>
    
  </div>
</template>
<script>
export default {
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    system: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    recycle: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    draft_status(){
      const {recycle} = this;
      const {task_config} = this.$store.state;
      const {create, draft, call, task_type} = task_config;
      if(recycle){
        return false;
      }
      if(create || draft || call){
        if(task_type == 2 && !draft){
          return false;
        }
        return true;
      }
      return false;
    },
    send_status(){
      const {view, editable} = this;

      if(view){
        return false;
      }
      return editable;
    },
    use_status(){
      const {view, system} = this;
      if(system){
        return false;
      }
      if(view){
        return true;
      }
      return false;
    }

  },
  methods: {
    async sendTask(){
      this.$emit('handleSend');
      return;
    },
    seveDraft(){
      const {task_config, task} = this.$store.state;
      const {draft} = task_config;
      if(draft && task.task.id){ // 草稿 && 任务id存在
        this.$api.moduleTask.editDraft();
        return ;
      }
      this.$api.moduleTask.createDraft();
    },
    doUse(){
      const {task_config} = this.$store.state;
      const {task_id} = task_config;
      this.$emit('handleUse', task_id);
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_fun_bar_button_group_page{
  padding: 0 5px;
  .button{
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 10px;
    margin: 0 5px;
    &.draft{
      background-color: #399786;
    }
  }
}
</style>