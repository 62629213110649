<template>
  <div
    class="components_personal_center_vip_and_consumption_vip_page bbox d-flex flex-column align-start"
  >
    <div class="table_group">
      <table class="table">
        <thead>
          <tr
            class="line"
          >
            <th scope="col">模块</th>
            <th scope="col">功能</th>
            <th scope="col">普通用户</th>
            <th scope="col">VIP用户(付费)</th>
            <th scope="col">讲师</th>
          </tr>
        </thead>
        <tbody>

          <!-- 登录注册 -->
          <template>
            <tr>
              <th scope="row"
                rowspan="4"
              >登录注册</th>
              <td>注册新用户</td>
              <td>邀请制</td>
              <td>邀请制</td>
              <td>邀请制</td>
            </tr>
            <tr>
              <td>编辑个人资料</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>修改密码</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>产品激活</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 搜索 -->
          <template>
            <tr
              class="line"
            >
              <th scope="row"
              >搜索</th>
              <td>搜索</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 联系人 -->
          <template>
            <tr>
              <th scope="row"
                rowspan="4"
              >联系人</th>
              <td>添加联系人</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>接收联系人申请</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>备注联系人名称</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>查看申请记录</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 个人沟通窗口 -->
          <template>
              
        
            <tr>
              <th scope="row"
                rowspan="5"
              >个人沟通窗口</th>
              <td>主动创建个人沟通窗口</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>被动接受个人沟通窗口</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>拉入联系人升级为工作组</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>置顶窗口</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>从沟通列表中删除</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 工作组沟通窗口 -->
          <template>
            <tr>
              <th scope="row"
                rowspan="9"
              >工作组沟通窗口</th>
              <td>创建工作组</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>被动加入工作组</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>邀请新成员</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>设置组内昵称</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>置顶窗口</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>查看公示板</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>修改工作组名称</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>从沟通列表中删除</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>查看非联系人成员个人资料</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 文字交流 -->
          <template>
            <tr
              class="line"
            >
              <th scope="row"
              >文字交流</th>
              <td>文字交流</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 个人或工作组内任务单 -->
          <template>
            <tr>
              <th scope="row"
                rowspan="11"
              >个人或工作组内任务单</th>
              <td>创建、删除任务单</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>调用创建任务单</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>添加、删除控件</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>任务单中上传图片和附件</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>查看、接收、编辑任务单</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>分享任务单</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>查看任务单记录</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>任务单内交流讨论</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>颜色标记任务单</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>控件关联</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>隐私模式</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 教学 -->
          <template>
            <tr>
              <th scope="row"
              rowspan="3"
              >教学</th>
              <td>制作教学模板</td>
              <td><div class="none"></div></td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>销售教学模板</td>
              <td><div class="none"></div></td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>购买教学模板</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 模板管理 -->
          <template>
            <tr>
              <th scope="row"
              rowspan="8"
              >模板管理</th>
              <td>我创建的</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>我接收的</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>我购买的</td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>我销售的</td>
              <td><div class="none"></div></td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>创建新自定义分类</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>删除已有的自定义分类</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr>
              <td>在已有的自定义分类中加入任务单</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
            <tr
              class="line"
            >
              <td>在已有的自定义分类中删除任务单</td>
              <td><div class="none"></div></td>
              <td><div class="own"></div></td>
              <td><div class="own"></div></td>
            </tr>
          </template>
          <!-- 操作区 -->
          <template
            v-if="0"
          >
            <tr
              class="operation"
            >
              <td></td>
              <td></td>
              <td>免费</td>
              <td>
                <div class="button btn_dark">成为vip用户（388元/年）</div>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
   
    <div class="expire_group flex-shrink-0 justify-end align-center d-flex bbox"
      v-if="expire_time && expire_group"
    >
      <div class="expire">{{expire_time}}到期</div>
      <div class="renew un_sel cp"
        @click.stop="renew();"
      >续费</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    expire_group: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {
      vipEndDate: null,
    }
  },
  computed: {
    expire_time(){
      const {vipEndDate} = this;
      if(vipEndDate??'' !== ''){
        return this.$moment(Number(vipEndDate)).format('YYYY-MM-DD')
      }
      return false;
    },
    user_data_update(){
      return this.$store.state.user_data_update;
    },
  },
  watch: {
    user_data_update: function(val){
      if(val){
        const user = this.$tools.getCookie('user');
        const vipEndDate = user?.vipEndDate || null;
        if(vipEndDate){
          // console.log({user, vipEndDate});
          this.vipEndDate = vipEndDate;
        }
      }
    }
  },
  mounted(){
    const user = this.$tools.getCookie('user');
    const vipEndDate = user?.vipEndDate || null;
    if(vipEndDate){
      // console.log({user, vipEndDate});
      this.vipEndDate = vipEndDate;
    }
  
  },
  methods: {
    renew(){
      this.$become_member({
        renew: true,
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.components_personal_center_vip_and_consumption_vip_page{

  width:100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  .table_group{
    @include scrollbar;
    flex: 1;
    overflow: auto;
    width: 100%;
  }
  .table{
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid #E7EAED;
    
    tr{
      &.line{
        border-bottom: 1px solid #E7EAED;
      }
    }
    tr:first-child{
      th{
        line-height: 50px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    td, th{
      text-align: center;
      line-height: 38px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      box-sizing: border-box;
      &:nth-last-child(1){
        background-color: rgba(252, 195, 107, 0.13)
      }
      &:nth-last-child(2){
        background-color: rgba(178, 192, 204, 0.2);
      }
      &:nth-last-child(3){
        background-color: rgba(210, 210, 210, 0.2);
      }
      &:nth-last-child(4){
        background-color: rgba(232, 232, 232, 0.2);
      }
    }
    td{
      &:nth-last-child(4){
        padding: 0 20px;
        text-align: left;
      }
    }
    .own{
      width: 16px;
      height: 16px;
      background-image: url(~@/assets/images/personal_center/vip/own.png);
      margin: 0 auto;
    }
    .none{
      width: 16px;
      height: 2px;
      background-color: #6B6C6C;
      margin: 0 auto;
    }
    .operation{
      td{
        height: 78px;
        line-height: 78px;
      }
      .button{
        display: inline-block;
        padding: 0 20px;
        line-height: 40px;
        border-radius: 4px;
        text-transform: uppercase;
      }
    }
  }
  .expire_group{
    font-size: 14px;
    color: #333333;
    line-height: 4px;
    padding-right: 100px;
    width: 100%;
    height: 40px;
    padding-right: 100px;
    .renew{
      color: $main_blue;
      padding-left: 10px;
    }
  }
}
</style>