<template>
  <div
    v-if="showUnit"
    class="c_pop_up_migrate_task_select_task_group_group_unit bbox d-flex justify-center cp"
    :class="{
      active
    }"
    @click.stop="doSelect"
  >
    <div
      class="chat_group d-flex align-center justify-start"
    >
      <img
        :src="`${$avatar_url}${chatData.photo}`"
        alt=""
        class="avatar"
      >
      <div
        class="group_name"
      >
        {{ chatData.chatName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      default: ''
    },
    chatData: {
      type: Object,
      default: undefined
    },
    pageData: {
      type: Object,
      default: undefined
    },
  },
  computed: {
    active() {
      try {
        const { pageData, chatData } = this
        const { selectedGroup } = pageData
        return selectedGroup.chatId === chatData.chatId
      } catch (e) {
        return false
      }
    },
    showUnit() {
      try {
        const { search, chatData } = this
        const keyword = search.trim()
        if (keyword === '') {
          return true
        }
        const { chatName } = chatData
        const lowercaseChatName = chatName.toLowerCase()
        const lowercaseKeyword = keyword.toLowerCase()
        // if (lowercaseChatName.includes(lowercaseKeyword))
        return lowercaseChatName.includes(lowercaseKeyword)
        // return true
      } catch (e) {
        return true
      }
    }
  },
  methods: {
    doSelect () {
      this.$set(this.pageData, 'selectedGroup', this.chatData)
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_migrate_task_select_task_group_group_unit {
  @include transition;
  padding: 10px 20px;
  &:hover {
    background-color: #f4f4f4;
  }
  &.active {
    background-color: #d9d9d9;
  }
  .chat_group {
    width: 400px;
    .avatar {
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: block;
      margin-right: 10px;
    }
    .group_name {
      font-size: 14px;
      line-height: 20px;
      color: #333;
    }
  }
}
</style>