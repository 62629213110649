<!--
 * @Author       : Hugo
 * @Date         : 2020-09-17 14:11:55
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-07 10:16:12
 * @Description  : 
 * @FilePath     : /miaohang/src/components/Plugin/Video.vue
-->
<template>
  <transition name="fade">
    <div class="plugin_video_page" v-if="visible" ref="video_wrapper"
      :style="{'z-index': zindex}"
    >
      <div class="close cp" @click="closeVideo"></div>
      <div class="video_group" v-show="ready">
        <Xgplayer :config="config" @player="Player = $event" />
      </div>
    </div>
  </transition>
 
</template>
<script>
import Xgplayer from 'xgplayer-vue';
export default {
  components:{
    Xgplayer,
  },
  data(){
    return {
      visible:false,
      src: '',
      config: {
        id: 'vs',
        url: '/demo.mp4',
        // fitVideoSize: 'fixWidth',
        fitVideoSize: 'auto',
        // width: 1000,
        // height: 337.5
      },
      Player: null,
      ready: false,
      zindex: 2000,
    }
  },
  watch:{
    src: function(val){
      if(val){
        this.$nextTick(function(){
          this.Player.start(val);
          setTimeout(()=>{
            this.Player.play();
          },200)
        })
      }
    },
    visible: function(val){
      if(val){
        // const {src} = this;
        // if(this.Player && src){
        //   this.Player.start(src);
        //   setTimeout(()=>{
        //     this.Player.play();
        //   },200)
        // }
        this.zindex = this.$utils.getZIndex();
      }
    },
    Player: function(val){
      // console.log(val);
      if(val){
        val.once('ready', ()=>{
          this.ready = true;
         
          setTimeout(()=>{
            
            this.Player.src = this.src;
            this.Player.reload();
            // this.Player.start();
            this.Player.start(this.src);
            // console.log('play1')
            this.Player.play();
          },200)
        })
      }
    },
  },
  methods: {
    closeVideo(){
      this.src = '';
      this.$closeVideo();
    }
  },
  mounted() {
    // this.Player.once('ready', ()=>{
    //   this.ready = true;
    //   this.Player.reload();
    //   this.Player.start(this.src);
    //   setTimeout(()=>{
    //     // console.log('play')
    //     this.Player.play();
    //   },200)
    // })
    if(this.visible){
      let player = new Xgplayer({/*配置*/});
      // console.log(player);
      player.once('ready',()=>{
        // console.log('ready')
      })

    }
  },
  computed: {
  },
}
</script>
<style lang="scss" scoped>

.plugin_video_page{
  @include flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position:  fixed;
  top:0;
  left:0;
  z-index: 99999999;
  background-color: rgba(0,0,0,0.7);
  .close{
    @include background;
    background-image: url(~@/assets/images/plugin/close_video.png);
    position: absolute;
    top: 40px;
    right: 40px;
    width: 24px;
    height:24px;
  }
  .play_box{
    max-width: 80%;
    
  }
}
</style>
<style>
  .video-js .vjs-big-play-button{
    position: absolute;
    top: 50%!important;
    left: 50%!important;
    margin-top: -0.75em;
    margin-left: -1.5em;
  }
</style>