import vue from '@/main.js'
import store from '@/store'
class OpenPublicNotice{
  clearPublicNotice(){
    const {state, commit } = vue.$store;
    commit('set_task', {});
    commit('set_task_config', Object.assign(
      {},
      state.task_config_basic,
      {}
    ));
  }
  async getChatDetail (chat_id) {
    const { state, commit } = vue.$store
    const { chat_detail } = state
    const chat_type = vue.$tools.getChatType()

    if (!chat_detail || (chat_detail && (chat_id != chat_detail.chatId || chat_type != chat_detail.chatType)) ) {
      const res = await vue.$global.doPost({
        url: '/projectTask/chatDetail',
        data: {
          chatType: chat_type,
          chatId: chat_id
        },
        openNotice: false
      })
      if (res.contents.PChatDetail) {
        commit('set_chat_detail', res.contents.PChatDetail)
      }

      return res.contents.PChatDetail
    }
  }
  /**
   * 获取公告数据
   * @param {*} chat_id 
   * @returns 
   */
  async getTaskNoticeDetail (chat_id) {
    const { state } = store
    const { task_config } = state
    let chat_type = vue.$tools.getChatType()
    if(Number(chat_type) === 0) {
      return false
    }
    const res = await vue.$global.doPost({
      url: '/projectChat/taskNoticeDetail', 
      data: {chatId: chat_id}
    })

    if (res.message === 'success' && res.contents.tasknoticeDeatails) {
      const contents = res.contents
      let deep_contents = vue.$tools.deepClone(contents)
      deep_contents = Object.assign(
        {},
        {
          taskDeatails: deep_contents.tasknoticeDeatails,
          task: {}
        }
      )
      vue.$store.commit('set_task', deep_contents)
      vue.$store.commit('set_task_original', deep_contents)
      console.log(deep_contents)
      store.commit('set_annc_data', {
        task: deep_contents,
        task_original: deep_contents,
        task_config
      })
      return deep_contents
    }
    return false
  }
}

const openPublicNotice = new OpenPublicNotice();

export default openPublicNotice;
