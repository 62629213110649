import request from '@/api/request'
import ResourceFile from '@/models/resource/resource_file'
import { listConvertToModel } from '@/models'
import CourseSection from '@/models/cource_package/course_section'
const resource = {
  state: {
    enterpriseList: undefined,
    fileList: undefined,
    shoppingPackageList: undefined,
    shoppingTaskList: undefined,
    fileData: undefined,
  },
  mutations: {
    setResourceEnterpriseList (state, data) {
      state.enterpriseList = data
    },
    setResourceFileList (state, data) {
      state.fileList = data
    },
    setResourceShoppingPackageList (state, data) {
      state.shoppingPackageList = data
    },
    setResourceShoppingTaskList (state, data) {
      state.shoppingTaskList = data
    },
    setResourceFileData (state, data) {
      state.fileData = data
    },
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
    async getResourceEnterpriseList (context, params) {
      const data = Object.assign(
        {},
        params
      )
      const res = await request.request(
        '/resource/ownedEnterprisesList',
        data,
      )
      if (res.message === 'success') {
        context.commit('setResourceEnterpriseList', res.contents.page.result)
      }
    },
    async getResourceFileList (context, params) {
      const data = Object.assign(
        {},
        params
      )
      const res = await request.request(
        'resource/teachFileList',
        data,
      )
      if (res.message === 'success') {
        console.log(res)
        context.commit(
          'setResourceFileList',
          listConvertToModel({
            list: res.contents.page.result,
            model: ResourceFile
          })

        )
      }
      return res
    },
    async getResourceShoppingPackageList (context, params) {
      const data = Object.assign(
        {},
        params
      )
      const res = await request.request(
        'projectShop/shopPackages',
        data,
      )
      if (res.message === 'success') {
        if (data.start) {
          context.commit(
            'setResourceShoppingPackageList',
            [
              ...context.state.shoppingPackageList,
              ...res.contents.shoppackages
            ]
          )
        } else {
          context.commit(
            'setResourceShoppingPackageList',
            res.contents.shoppackages
          )
        }

      } else {
        context.commit(
          'setResourceShoppingPackageList',
          undefined
        )
      }
      return res
    },
    async getResourceShoppingTaskList (context, params) {
      const data = Object.assign(
        {},
        params
      )
      const res = await request.request(
        'projectShop/shopTasks',
        data,
      )
      if (res.message === 'success') {
        console.log(data, res)
        if (data.start) {
          context.commit(
            'setResourceShoppingTaskList',
            [
              ...context.state.shoppingTaskList,
              ...res.contents.shoptasks
            ]
          )
        } else {
          context.commit(
            'setResourceShoppingTaskList',
            res.contents.shoptasks
          )
        }

      } else {
        context.commit(
          'setResourceShoppingTaskList',
          undefined
        )
      }
      return res
    }
  }
}

export default resource
