var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userData
    ? _c("div", { staticClass: "contact_detail" }, [
        _c(
          "div",
          { staticClass: "name_group" },
          [
            _c("el-image", {
              staticClass: "avatar",
              staticStyle: { width: "90px", height: "90px" },
              attrs: {
                src: _vm.userData.thumbnail
                  ? `${_vm.$avatar_url}${_vm.userData.thumbnail}@!small200`
                  : `${_vm.$avatar_url}${_vm.userData.updateuserthumbnail}@!small200`,
                "preview-src-list": [
                  _vm.userData.thumbnail
                    ? `${_vm.$avatar_url}${_vm.userData.thumbnail}`
                    : `${_vm.$avatar_url}${_vm.userData.updateuserthumbnail}`,
                ],
                "z-index": Number(_vm.z_index),
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setImageZIndex()
                },
              },
            }),
            0
              ? _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: _vm.userData.thumbnail
                      ? `${_vm.$avatar_url}${_vm.userData.thumbnail}`
                      : `${_vm.$avatar_url}${_vm.userData.updateuserthumbnail}`,
                    alt: "",
                  },
                })
              : _vm._e(),
            _c("div", { staticClass: "name_box" }, [
              _c("div", { staticClass: "name_wrapper" }, [
                _c(
                  "div",
                  { staticClass: "input_name_group" },
                  [
                    !_vm.userData.isfriend || _vm.is_self
                      ? [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.userData.userName)),
                          ]),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.edit_name &&
                                  _vm.userData.friendRemark != "" &&
                                  _vm.userData.friendRemark != null,
                                expression:
                                  "!edit_name &&(userData.friendRemark !=''&&userData.friendRemark != null)",
                              },
                            ],
                            staticClass: "sex",
                            class: {
                              male: _vm.userData.userSex == "男",
                              female: _vm.userData.userSex == "女",
                            },
                          }),
                        ]
                      : _vm._e(),
                    !_vm.edit_name && _vm.userData.isfriend && !_vm.is_self
                      ? [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.edit_name &&
                                    !_vm.userData.updateremark,
                                  expression:
                                    "!edit_name && !userData.updateremark",
                                },
                              ],
                              staticClass: "name cp empty",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.triggerInputNameFocus.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("点击输入备注名")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.edit_name && _vm.userData.updateremark,
                                  expression:
                                    "!edit_name && userData.updateremark",
                                },
                              ],
                              staticClass: "name cp",
                              class: {
                                empty:
                                  (_vm.userData.friendRemark == "" ||
                                    _vm.userData.friendRemark == null) &&
                                  !_vm.userData.updateremark,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.triggerInputNameFocus.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.userData.friendRemark ||
                                    _vm.userData.userName
                                )
                              ),
                            ]
                          ),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  (!_vm.edit_name &&
                                    _vm.userData.friendRemark != "" &&
                                    _vm.userData.friendRemark != null) ||
                                  _vm.userData.updateremark,
                                expression:
                                  "!edit_name &&(userData.friendRemark !=''&&userData.friendRemark != null) || userData.updateremark",
                              },
                            ],
                            staticClass: "sex cp",
                            class: {
                              male: _vm.userData.userSex == "男",
                              female: _vm.userData.userSex == "女",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.changeUserFriendRemark.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.edit_name,
                          expression: "edit_name",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputname,
                          expression: "inputname",
                        },
                      ],
                      ref: "inputname",
                      staticClass: "input_name",
                      class: { not_friend: !_vm.userData.isfriend },
                      attrs: {
                        type: "text",
                        disabled:
                          _vm.userData.userId == _vm.$tools.getUserId() ||
                          !_vm.userData.isfriend,
                        maxlength: "16",
                      },
                      domProps: { value: _vm.inputname },
                      on: {
                        blur: _vm.changeUserFriendRemark,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.$refs.inputname.blur()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.inputname = $event.target.value
                        },
                      },
                    }),
                  ],
                  2
                ),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: 0,
                      expression: "0",
                    },
                  ],
                  staticClass: "gender",
                }),
              ]),
              _vm.userData.chatUserName
                ? _c("div", { staticClass: "chat_username" }, [
                    _vm._v("工作组昵称:" + _vm._s(_vm.userData.chatUserName)),
                  ])
                : _vm._e(),
              !_vm.is_self
                ? _c("div", { staticClass: "nickname" }, [
                    _vm._v("昵称: " + _vm._s(_vm.userData.userName)),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "sign_group" }, [
          _c("div", { staticClass: "title" }, [_vm._v("个性签名:")]),
          _vm.userData.personalDescribe
            ? _c("div", { staticClass: "sign" }, [
                _vm._v(_vm._s(_vm.userData.personalDescribe)),
              ])
            : _vm._e(),
        ]),
        _vm.userData.isfriend || _vm.is_self
          ? _c("div", { staticClass: "info_group" }, [
              _c("div", { staticClass: "key" }, [_vm._v("机 构:")]),
              _vm.userData.ownedEnterprises
                ? _c("div", { staticClass: "val" }, [
                    _vm._v(_vm._s(_vm.userData.ownedEnterprises)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.userData.isfriend || _vm.is_self
          ? _c("div", { staticClass: "info_group" }, [
              _c("div", { staticClass: "key" }, [_vm._v("职 务:")]),
              _vm.userData.userPosition
                ? _c("div", { staticClass: "val" }, [
                    _vm._v(_vm._s(_vm.userData.userPosition)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.userData.personalDescribe,
              expression: "userData.personalDescribe",
            },
          ],
          staticClass: "line",
        }),
        _vm.userData.isfriend || _vm.is_self
          ? _c("div", { staticClass: "info_group" }, [
              _c("div", { staticClass: "key" }, [_vm._v("妙行号:")]),
              _c("div", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.userData.phone)),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userData.isfriend || _vm.is_self,
                expression: "(userData.isfriend || is_self)",
              },
            ],
            staticClass: "info_group",
          },
          [
            _c("div", { staticClass: "key" }, [_vm._v("姓  名:")]),
            _c("div", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.userData.userRealName)),
            ]),
          ]
        ),
        _c("div", { staticClass: "info_group" }, [
          _c("div", { staticClass: "key" }, [_vm._v("地 区:")]),
          _c("div", { staticClass: "val" }, [
            _vm._v(
              _vm._s(
                `${_vm.userData.province ? _vm.userData.province : ""} ${
                  _vm.userData.city ? _vm.userData.city : ""
                }`
              )
            ),
          ]),
        ]),
        _vm.userData.isfriend || _vm.is_self
          ? _c("div", { staticClass: "info_group" }, [
              _c("div", { staticClass: "key" }, [_vm._v("邮 箱:")]),
              _c("div", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.userData.email)),
              ]),
            ])
          : _vm._e(),
        _c("div", {
          directives: [
            { name: "show", rawName: "v-show", value: 0, expression: "0" },
          ],
          staticClass: "line",
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }