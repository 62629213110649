<template>
  <div
    class="contact_sketch_page"
  >
    <div
      class="list d-flex align-center flex-wrap"
      v-if="list && list.length"
    >
      <template
        v-for="(item, index) in list"
      >
        <div
          :key="index"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name"
            placement="bottom"
          >
            <el-image
              class="avatar"
              :src="`${$avatar_url}${item.thumbnail}@!small200`"
              fit="cover"
            ></el-image>
          </el-tooltip>
        </div>
      </template>
    </div>
    <div
      v-if="(!list || !list.length) && msg.content"
      class="empry"
      v-text="msg.content"
    >
      {{ msg.content }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  data(){
    return {
      list: [],
    }
  },
  methods: {
    generateList(){
      const { msg } = this
      const { content } = msg
      let list = []
      if ((content ?? '') !== '') {
        if (content.indexOf('|') !== -1) {
          const arr_split = content.split('|')
          if(arr_split && arr_split.length){
            arr_split.forEach(item=> {
              if(item){
                const obj = item.split(',,')
                list.push({
                  id: obj[0],
                  name: obj[1],
                  thumbnail: obj[2],
                })
              }
            })
            this.$set(this, 'list', list)
          }
        } else {
          const obj = content.split(',,')
          list.push({
            id: obj[0],
            name: obj[1],
            thumbnail: obj[2],
          })
          this.$set(this, 'list', list)
        }
      }
    },
    init(){
      this.generateList();
    }
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="scss" scoped>
.contact_sketch_page {
  .list {
    max-width: 650px;
  }
  .item {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: {
      right: 10px;
      top: 10px;
    }
  }
  .empry {
    color: #333;
  }
}
</style>