<!--
 * @Author       : Hugo
 * @Date         : 2022-04-11 13:43:16
 * @LastEditTime : 2022-04-12 15:17:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Select/Route/List.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_select_route_list bbox"
  >
    <div class="list_group"
      ref="list"
    >
      <template
        v-for="(item, index) in list"
      >
        <div
          :key="index"
          class="item d-flex align-start bbox cp"
          :class="{active: item.cf && item.cf.selected}"
          @click.stop="touchFolder(item)"
        >
          <img
            :src="require(`@/assets/images/custom/folder.png`)"
            alt=""
            class="img flex-shrink-0"
          >
          <div class="text"
            v-text="item.name"
          ></div>
          <div
            class="next flex-shrink-0"
            :class="{has_child: item.sonfolderList && item.sonfolderList.length}"
          ></div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
export default {
  props:{
    list: {
      type: Array,
      default: undefined,
    }
  },
  methods: {
    touchFolder(p_folder){
  
      this.list.forEach(item=>{
        item.cf = Object.assign(
          item.cf??{},
          {selected: false}
        )
      })
      p_folder.cf.selected = true
      this.$emit('handleNext', p_folder)
    },
    goToTop(){
      console.log('goToTop')
      // this.$refs.list
      this.$refs.list.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_select_route_list{
  padding: 10px 0;
  overflow: auto;
  .list_group{
    @include scrollbar;
    position: relative;
    overflow: auto;
    height: 100%;
  }
  .item{
    @include transition;
    overflow: auto;
    padding: {
      left: 10px;
      right: 10px;
    }
    border-left: 6px solid transparent;
    min-height: 34px;
    &:hover, &.active{
      background-color: #E5EEF6;
      .text{
        color: $main_blue;
      }
    }
    &.active{
      border-left-color: $main_blue;
      position: sticky;
      top:0;
      left: 0;
      bottom: 0;
    }
    .img{
      width: 34px;
      height: 30px;
      margin-top: 2px;
    }
    .text{
      flex: 1;
      line-height: 16px;
      font-size: 12px;
      color: #333;
      word-break: break-all;
      padding: {
        left  : 5px;
        right : 10px;
        top   : 9px;
        bottom: 9px;
      }
    }
    .next{
      @include background;
      width: 8px;
      height: 12px;
      background: {
        image: none;
      }
      &.has_child{
        background: {
          image: url(~@/assets/images/custom/menu/arrow.png);
        }
      }
      margin-top: 11px;
    }
    
  }
}
</style>
