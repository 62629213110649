var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pPackage && _vm.packageSelectTask
    ? _c(
        "div",
        {
          staticClass:
            "c_teaching_package_select_task_index bbox d-flex flex-column",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchWrapper.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "mainGroup",
              staticClass: "main_group bbox",
              attrs: { id: "c_teaching_package_select_task_index_main_group" },
            },
            [
              _c(
                "div",
                { staticClass: "sticky_group bbox" },
                [
                  _c("filter-bar", {
                    ref: "filterBar",
                    attrs: { "package-select-task": _vm.packageSelectTask },
                    on: { handleDoSearch: _vm.handleDoSearch },
                  }),
                  _c("router-bar", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showRouterBar,
                        expression: "showRouterBar",
                      },
                    ],
                    staticClass: "pt-10",
                    attrs: { "package-select-task": _vm.packageSelectTask },
                  }),
                  _c("all-group", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAll,
                        expression: "showAll",
                      },
                    ],
                    staticClass: "py-10",
                    attrs: {
                      "task-list": _vm.taskList,
                      "package-select-task": _vm.packageSelectTask,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c("task-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTask,
                        expression: "showTask",
                      },
                    ],
                    attrs: {
                      "package-select-task": _vm.packageSelectTask,
                      "task-list": _vm.taskList,
                    },
                    on: { handleLoadmore: _vm.loadmore },
                  }),
                  _c("package-list", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPackage,
                        expression: "showPackage",
                      },
                    ],
                    attrs: {
                      "package-select-task": _vm.packageSelectTask,
                      "package-list": _vm.packageList,
                      "p-package": _vm.pPackage,
                    },
                    on: {
                      handleLoadmore: _vm.loadmore,
                      handleGetPackageTaskList: _vm.getPackageTaskList,
                      handleClearFilterBarSearch: _vm.clearFilterBarSearch,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("operation-bar", {
            staticClass: "flex-shrink-0",
            attrs: {
              "p-package": _vm.pPackage,
              "package-select-task": _vm.packageSelectTask,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }