var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("common-wrapper", { attrs: { height: "auto", width: "600px" } }, [
            _c(
              "div",
              {
                staticClass:
                  "v_pc_gift_card_plugins_give_away d-flex flex-column",
              },
              [
                _c("common-title", {
                  staticClass: "flex-shrink-0 title_common",
                  attrs: {
                    title: _vm.title,
                    close: true,
                    "border-radius": "10px",
                    background_color: "#f8f8f8",
                  },
                  on: { handleClose: _vm.handleClose },
                }),
                _c("plugin-component", {
                  staticClass: "plugin_component",
                  attrs: {
                    data: _vm.data,
                    pcarduser: _vm.pcarduser,
                    "gift-card": _vm.giftCard,
                  },
                  on: {
                    handleClose: _vm.handleClose,
                    handleSure: _vm.handleSure,
                    handleReturn: _vm.handleReturn,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }