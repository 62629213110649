<template>
  <div
    class="c_pop_up_migrate_task_select_task_group_index bbox d-flex algin-center flex-column"
  >
    <div
      class="search_wrapper d-flex justify-center flex-shrink-0 bbox"
    >
      <div
        class="search_group d-flex align-center bbox"
        @click.stop="touchSearch"
      >
        <div
          class="icon mr-4"
        ></div>
        <input
          v-model="search"
          type="text"
          class="search bbox"
          ref="search"
          placeholder="搜索..."
        >
        <div
          v-show="search.trim() !== ''"
          class="clear"
          @click.stop="clearSearch"
        ></div>
      </div>
    </div>
    <div
      class="group_wrapper"
    >
      <div
        class="group_list"
      >
        <template
          v-for="(item, index) in groupList"
        >
          <group-unit
            v-if="Number(item.chatId) !== Number(currentChatId)"
            :key="index"
            :chat-data="item"
            :page-data="pageData"
            :search="search"
          ></group-unit>
        </template>
      </div>
    </div>
    <div
      class="operation_wrapper d-flex align-center justify-center bbox flex-shrink-0"
    >
      <div
        class="btn_group d-flex align-center justify-center"
      >
        <div
          class="btn btn_light"
          @click.stop="doClose"
        >
          取消
        </div>
        <div
          class="btn btn_dark"
          @click.stop="doSure"
        >
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global'
import tools from '@/api/tools'
import GroupUnit from './GroupUnit'
import router from '@/router'
import store from '@/store'
export default {
  components: {
    GroupUnit
  },
  props: {
    params: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      search: '',
      groupList: [],
      // currentChatId: tools.atob(router.currentRoute.params.id),
      pageData: {
        selectedGroup: undefined
      }
    }
  },
  computed: {
    currentChatId () {
      try {
        const { params } = this
        const { taskList } = params
        if (router.currentRoute.params.id) {
          return tools.atob(router.currentRoute.params.id)
        }
        if (taskList && taskList.length) {
          return taskList[0].chatId
        }
        return ''
      } catch (e) {
        return ''
      }
    }
  },
  methods: {
    doClose () {
      this.$emit('handleClose')
    },
    doSure () {
      console.log(router)
      const { params, pageData } = this
      const { taskList } = params
      const { selectedGroup } = pageData
      console.log(params, pageData)
      if ((selectedGroup ?? '') === '') {
        this.$notice({
          desc: '请选择一个工作组'
        })
        return
      }
      const data = {
        chatId: selectedGroup.chatId,
        taskIds: ''
      }
      for (let i of taskList) {
        data.taskIds += data.taskIds === '' ? i.id : `,${i.id}`
      }
      this.$openConfirm({
        title: '确定要迁移任务到该工作组？',
        onSure: async () => {
          console.log(data)
          const res = await global.doPost({
            url: '/projectTask/moveTask',
            data,
            loading: true,
            loadingText: '迁移中'
          })
          const { message } = res
          if (message === 'success') {
            this.$notice({
              desc: '迁移成功'
            })
            this.$closeMigrateTaskSelectTargetGroup()
            this.$closeMigrateTaskSelectTask()
            // 跳转到目标工作组
            router.push({
              name: 'fun_task_dialog_group',
              params: {
                id: tools.btoa(data.chatId)
              }
            })
            const { message_box_list } = store.state
            const aim_message_box = message_box_list.find((unit) => {
              return Number(unit.chatId) === Number(data.chatId) && Number(unit.chatType) === 1
            })
            if (aim_message_box) {
              store.commit('set_message_box_unit', aim_message_box)
            }
            // 更新消息盒子
            store.dispatch('get_message_box_list')
          }
        }
      })
    },
    touchSearch () {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    clearSearch () {
      this.search = ''
    },
    init () {
      this.getGroupList()
    },
    async getGroupList () {
      const res = await global.doPost({
        url: 'projectFriends/chatGoupList'
      })
      const { message, contents } = res
      if (message === 'success') {
        const { list } = contents
        this.$set(this, 'groupList', list)
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_migrate_task_select_task_group_index {
  width: 100%;
  height: 100%;
  overflow: auto;
  .search_wrapper {
    width: 100%;
    position: sticky;
    z-index: 3;
    background-color: #fff;
    padding: 10px 20px;
    .search_group {
      padding: 0 10px;
      border-radius: 4px;
      background-color: #f3f3f3;
      position: relative;
      .icon {
        @include background;
        background-image: url(~@/assets/images/common/search_icon.png);
        width: 16px;
        height: 16px;
        cursor: text;
      }
      .search {
        @include placeholder(#ccc);
        background-color: transparent;
        font-size: 14px;
        color: #333;
        line-height: 28px;
        height: 28px;
        outline: none;
        border: none;
        width: 260px;
        padding-right: 20px;
      }
      .clear {
        @include background(14px, 14px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/images/common/clear_icon.png);
      }
    }
  }
  .group_wrapper {
    @include scrollbar;
    width: 100%;
    flex: 1;
    overflow: auto;
  }
  .operation_wrapper {
    width: 100%;
    padding: 10px 20px;
    .btn_group {
      .btn {
        font-size: 14px;
        line-height: 40px;
        border-radius: 4px;
        padding: 0 26px;
        margin: 0 10px;
      }
    }
  }
}
</style>
