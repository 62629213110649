// // console.log(process.env);
import vue from '../../../main';

class Version{
  /**
   * 检测版本号
   */
  async check(){
    const {VUE_APP_Version} = process.env;
    const res = await vue.$global.doPost({
      url: '/project/start',
      data: {
        type: 4,
        version: VUE_APP_Version
      }
    })
    if(res.message === 'success'){
      const {appdownDto} = res.contents;
      const {version} = appdownDto;
      if(version != VUE_APP_Version){
        console.log('jihuo')
        vue.$utils_notify.systemUpdate();
      }
      vue.$store.commit('set_start', appdownDto);
    }
  
  }
}

const version = new Version();

export default version;