/*
 * @Author      : Hugo
 * @Date        : 2022-03-30 12: 02: 20
 * @LastEditTime : 2022-03-30 14:08:57
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PScreenTask/index.js
 * @Description : 
 * @^_^         : 
 */
class PScreenTask{
  content      = undefined
  countdown    = undefined
  createTime   = undefined
  endFlag      = undefined
  endTime      = undefined
  endType      = undefined
  id           = undefined
  screenId     = undefined
  startTime    = undefined
  taskDetailId = undefined
  thumbnail    = undefined
  userId       = undefined
  userName     = undefined
  
  constructor(params = {}){
    const {
      content,
      countdown,
      createTime,
      endFlag,
      endTime,
      endType,
      id,
      screenId,
      startTime,
      taskDetailId,
      thumbnail,
      userId,
      userName,
    } = params || {}

    this.content      = content;
    this.countdown    = countdown;
    this.createTime   = createTime;
    this.endFlag      = endFlag;
    this.endTime      = endTime;
    this.endType      = endType;
    this.id           = id;
    this.screenId     = screenId;
    this.startTime    = startTime;
    this.taskDetailId = taskDetailId;
    this.thumbnail    = thumbnail;
    this.userId       = userId;
    this.userName     = userName;
  }
}

export default PScreenTask