var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sketch_file_page" },
    [
      _vm.file_list &&
      _vm.file_list.length &&
      _vm.msg.files != "" &&
      _vm.msg.filesNum != 0
        ? [
            _c("el-image", {
              directives: [
                { name: "show", rawName: "v-show", value: 0, expression: "0" },
              ],
              ref: "image",
              attrs: {
                src: _vm.preview_img_src,
                "preview-src-list": _vm.img_list,
              },
              nativeOn: {
                click: function ($event) {
                  _vm.setImgZindex()
                  _vm.$api.stopPropagation($event)
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "list",
                on: {
                  click: function ($event) {
                    return _vm.$api.stopPropagation($event)
                  },
                },
              },
              [
                _vm._l(_vm.file_list, function (item, index) {
                  return [
                    index < 5
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "file d-flex align-start",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "info_group d-flex align-start",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerFilename(item)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "icon flex-shrink-0",
                                  class: item.class_name,
                                }),
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.filename)),
                                ]),
                              ]
                            ),
                            _c("div", {
                              staticClass: "download_icon flex-shrink-0",
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(item)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _vm.is_more && !_vm.switch_more
                  ? _c(
                      "div",
                      {
                        staticClass: "more cp un_sel",
                        on: {
                          click: function ($event) {
                            return _vm.getMore()
                          },
                        },
                      },
                      [_vm._v("更多...")]
                    )
                  : _vm._e(),
                _vm.switch_more
                  ? [
                      _vm._l(_vm.file_list, function (item, index) {
                        return [
                          index >= 5
                            ? _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "file d-flex align-start",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "info_group d-flex align-start",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlerFilename(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "icon flex-shrink-0",
                                        class: item.class_name,
                                      }),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.filename)),
                                      ]),
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "download_icon flex-shrink-0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadFile(item)
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm.is_more && _vm.switch_more
              ? _c(
                  "div",
                  {
                    staticClass: "pack_up",
                    on: {
                      click: function ($event) {
                        _vm.switch_more = false
                        _vm.$api.stopPropagation($event)
                      },
                    },
                  },
                  [_vm._v("收起")]
                )
              : _vm._e(),
          ]
        : _c("common-sketch", { attrs: { msg: _vm.msg } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }