import vue from '@/main.js';
import store from '@/store';
import common from './common';
import global from '@/api/global'
import tools from '@/api/tools'
function deleteUnlessFields (item) {
  item.widget_sort = undefined
  delete item.widget_sort
  item.fileList = undefined
  delete item.fileList
  item.userList = undefined
  delete item.userList
  item.modified = undefined
  delete item.modified
  item.taskId = undefined
  delete item.taskId
  item.provinceCoder = undefined
  delete item.provinceCoder
  item.coder = undefined
  delete item.coder
  item.address = undefined
  delete item.address
  item.detailsLevel = undefined
  delete item.detailsLevel
  item.linkId = undefined
  delete item.linkId
  item.islink = undefined
  delete item.islink
  item.switch_relevancy = undefined
  delete item.switch_relevancy
  item.cf_edit_data = undefined
  delete item.cf_edit_data
  item.screenList = undefined
  delete item.screenList
}
/**
 * 生成关联后删除其余无用字段
 * @param {*} item
 */
function deleteUnlessAfterFileJson (item) {
  // console.log('deleteUnlessAfterFileJson', item)
  item.controlLinkList = undefined
  delete item.controlLinkList
}
class SendTaskFun {
  widgetUploaded (widget) {
    let uploaded = true
    if (widget.uploading) {
      uploaded = false
    }
    if (widget.controlLinkList && widget.controlLinkList.length) {
      widget.controlLinkList.forEach((unit) => {
        if (unit.uploading === true) {
          uploaded = false
        }
      })
    }
    return uploaded
  }

  uploaded () {
    const { task, task_config } = vue.$store.state
    const { taskDeatails } = task
    const { uploading_teach_fileList, uploading_teach_videoList } = task_config
    let uploaded = true
    taskDeatails.forEach((item) => {
      if (item.uploading === true) {
        uploaded = false
      }
      if (item.controlLinkList && item.controlLinkList.length) {
        item.controlLinkList.forEach((unit) => {
          if (unit.uploading === true) {
            uploaded = false
          }
        })
      }
    })
    if (uploading_teach_fileList) {
      uploaded = false
    }
    if (uploading_teach_videoList) {
      uploaded = false
    }
    return uploaded
  }

  generateToUsers () {
    const { task_config } = vue.$store.state
    const { users } = task_config
    let to_users = ''
    users.forEach((item) => {
      to_users += to_users ? `,${item.userId}` : item.userId
    })
    return to_users
  }

  generateControls () {
    const { task, task_config } = vue.$store.state
    const { call, create_draft, create_teach } = task_config
    const { taskDeatails } = task
    let task_details = vue.$tools.deepClone(taskDeatails)
    console.log(task_details)
    let widgets = []
    task_details.forEach((unit,index) => {
      // PTaskDetail 专有
      if (unit.generateRequestData) {
        unit = unit.generateRequestData()
      }
      unit.sort = index + 1
      // 调用 || 创建草稿 => 清除id
      if (call || create_draft || create_teach) {
        delete unit.id
      }
      const {id, modified} = unit
      if (!id || modified) {
        widgets.push(unit)
      }
    })
    widgets = widgets.map((item) => {
      // sort
      // item.sort = index + 1;
      if (this.generateFileJson(item)) {
        item.fileJson = this.generateFileJson(item)
      }
      item.sortJson = this.generateWidgetSortJson(item)
      // 删除无用字段
      deleteUnlessFields(item)
      if (item.controlLinkList && item.controlLinkList.length) {
        const controlLinkJson = []
        item.controlLinkList = item.controlLinkList.map(( unit, i ) => {
          // PTaskDetail 专有
          if (unit.generateRequestData) {
            unit = unit.generateRequestData()
          }
          //关联排序
          unit.sort = i + 1
          if (this.generateFileJson(unit)) {
            unit.fileJson = this.generateFileJson(unit)
          }

          // 调用 || 创建草稿 => 清除id
          if (call || create_draft || create_teach) {
            delete unit.id
          }
          // // console.log(unit)
          if (!unit.id || unit.modified) {
             // 删除无用字段
            deleteUnlessFields(unit)
            controlLinkJson.push(unit)
          }
          if (unit.controlLinkJson === null) {
            unit.controlLinkJson = []
          }
          return unit
        })
        item.controlLinkJson = controlLinkJson
      }
      if (item.controlLinkJson === null) {
        item.controlLinkJson = []
      }
      deleteUnlessAfterFileJson(item)
      return item
    })
    return JSON.stringify(widgets)
  }

  generateChatId() {
    const { task_config, task } = vue.$store.state
    const userid = vue.$tools.getUserId()
    const { users, chat_type, chat_id, use, select_target_chat_id, select_target_chat_type } = task_config
    // console.log({task_config, task})
    const { tousers } = task.task
    if (use && select_target_chat_id !== null) {
      return select_target_chat_id
    }
    if (chat_type == 0) {
      // const
      const aim = users.find((item) => {
        return item.userId != userid
      })
      console.log({ aim })
      if (aim !== undefined) {
        console.log(aim.userId)
        return aim.userId
      }
    }
    return chat_id
  }

  generateFileJson (widget) {
    const { task_config } = vue.$store.state
    const { call, create_draft, create_teach } = task_config
    const { fileList } = widget
    let fileJson = []
    if (fileList&&fileList.length) {
      fileList.forEach((item) => {
        const { filename, filetype, filesize, width, height, url, id, type, filesort } = item
        if (call || create_draft || create_teach) {
          delete item.id
        }
        // 没有 id || type 被赋值
        if(!id || type !== null){
          let data = {
            filename,
            filetype,
            filesize,
            width,
            height,
            url,
            filesort: filesort || 0,
            type: type || 0 // 1 删除 0 上传 2 排序
          }
          if (id && (type !== 0)) {
            data.id = id
          }
          fileJson.push(data)
        }
      })
    }
    if (fileJson.length) {
      return fileJson
    }
    return fileJson
  }

  generateWidget (widget, parent) {
    const { task_original } = vue.$store.state
    let parent_obj = null
    if (parent) {
      parent_obj = task_original.taskDeatails.find((unit)=>{
        return unit.id == parent.id
      })
    }

    let item = vue.$tools.deepClone(widget);
    // PTaskDetail 专有
    if(item.generateRequestData){
      item = item.generateRequestData();
    }

    if(this.generateFileJson(item)){
      item.fileJson = this.generateFileJson(item);
    }
    item.sortJson = this.generateWidgetSortJson(item);
    deleteUnlessFields(item);
    if(item.controlLinkList && item.controlLinkList.length){
      const controlLinkJson = [];
      item.controlLinkList = item.controlLinkList.map(( unit, i )=>{
        // PTaskDetail 专有
        if(unit.generateRequestData){
          unit = unit.generateRequestData();
        }
        //关联排序
        unit.sort = i + 1;
        if(this.generateFileJson(unit)){
          unit.fileJson = this.generateFileJson(unit);
        }
        if(!unit.id || unit.modified){
          controlLinkJson.push(unit);
        }
        if(unit.controlLinkJson === null){
          unit.controlLinkJson = [];
        }
        // 删除无用字段
        deleteUnlessFields(unit);
        // delete unit.widget_sort;
        // delete unit.fileList;
        // delete item.userList;
        // delete item.modified;
        return unit
      })
      item.controlLinkJson = controlLinkJson;
    }
    if(item.controlLinkJson === null){
      item.controlLinkJson = [];
    }
    deleteUnlessAfterFileJson(item);
    if(parent){
      const parent_widget = Object.assign(
        {},
        parent_obj,
        {
          controlLinkJson: [item]
        }
      )
      deleteUnlessFields(parent_widget);
      return JSON.stringify([parent_widget]);
    }
    return JSON.stringify([item]);
    // return item;
  }

  generateSortJson(){
    const {task, task_config} = vue.$store.state;
    const {taskDeatails} = task;
    let sortJson = [];
     // 有改变位置的
    const {moved} = task_config;
    // 是否有新增
    let has_new = taskDeatails.findIndex(item=>{
      return !item.id;
    })
    // 没有新增或者没有位置改变 返回'[]'
    if(has_new == -1 && !moved){
      return JSON.stringify(sortJson);
    }
    taskDeatails.forEach( (item, index) => {
      if(item.id){
        sortJson.push({sort:0, id:item.id});
      }else{
        sortJson.push({sort: index+1, id: 0});
      }
    })
    return JSON.stringify(sortJson);

  }

  generateWidgetSortJson(widget){
    const widget_copy = vue.$tools.deepClone(widget);
    const {task_config} = vue.$store.state;
    const {call} = task_config;
    const {controlLinkList, moved} = widget_copy;
    let sortJson = [];
    if(controlLinkList == null || controlLinkList.length == 0){
      return sortJson;
    }
    if(call){
      controlLinkList.forEach(unit=>{
        delete unit.id;
      })
    }
    const has_new = controlLinkList.findIndex( item=> {
      return !item.id;
    })
    // 没有新增或者没有位置改变 返回'[]'
    if(has_new == -1 && !moved){
      return sortJson;
    }
    controlLinkList.forEach( (item, index) => {
      if(item.id){
        sortJson.push({sort:0, id:item.id});
      }else{
        sortJson.push({sort: index+1, id: 0});
      }
    })
    return sortJson;
  }

  afterSendBasic = (res, closeTask=true) => {
    const { commit,state } = vue.$store
    const { task_config, task } = state
    const { chat_id,chat_type,select_target,use, select_target_chat_id, select_target_chat_type } = task_config
    const { taskDeatails } = task
    if (res.message === 'success') {
      if (closeTask) {
        // 发布成功后 清除返回调用的功能
        vue.$store.commit('set_task_config',
          Object.assign(
            {},
            task_config,
            {
              open_with_call_page: false,
              open_by_call: false
            }
          )
        )
        /**
         * 发布后关闭任务前将所有modified 改为false
         * */
        taskDeatails.forEach((item) => {
          item.modified = false
        })
        vue.$api.moduleTask.closeTask()
        vue.$store.commit(
          'set_switch_teaching_package',
          false
        )
        /**
         * 如果公示板没有关闭 将公示板数据恢复
         */
        const { state } = store
        const { annc_switch, annc_data } = state
        try {
          // console.log({
          //   annc_switch,
          //   annc_data
          // })
          if (annc_switch) {
            store.commit('set_task', annc_data.task)
            store.commit('set_task_original', annc_data.task_original)
            store.commit('set_task_config', annc_data.task_config)
          }
        } catch (e) {
          console.log(e)
        }
      }

      common.pushMessageBox(Object.assign(
        {},
        res.contents.taskLog,
        {
          chatId: (select_target || use) ? (select_target_chat_id !== null ? select_target_chat_id : chat_id)  : chat_id,
          chatType: (select_target || use) ?  (select_target_chat_type !== null ? select_target_chat_id : chat_type) :chat_type,
          // messageContent: (this.task_id ? '更新 ' : '创建 ') + res.contents.taskLog.taskName,
          // updateUserName: this.$tools.getCookie('user').userName,
        }
      ))
      if (res.contents.taskLog) {
        common.pushMessageList(res.contents.taskLog)
        common.updateMessageList(res.contents.taskLog)
      }
      common.updateMyTaskList(res)
      vue.$nextTick(function(){
        // 触发滚动到底部
        commit('set_task_trigger_scroll_to_bottom', 1)
      })
      common.jumpToAppointPage()
    }
    if (res.message === 'NOmodify') {
      if (closeTask) {
        vue.$api.moduleTask.closeTask()
      }
    }
    // console.log(vue.$route)
    // 判断是否是当前群聊 如果不是 则跳转到目标群聊
    const { $router } = vue
    // const { name, params } = $route
    if (chat_id) {
      let routeName = 'fun_task_dialog_single'
      if (chat_type) {
        routeName = 'fun_task_dialog_group'
      }
      const pushId = sendTaskFun.generateChatId()
      console.log({ chat_id, chat_type })
      $router.push({
        name: routeName,
        params: {
          id: tools.btoa(pushId)
        }
      })
    }
  }
  afterSendTask = (res) => {
    this.afterSendBasic(res)
    this.afterSendTaskAndProjectionScreen(res)
  }

  /**
   * 发送后投屏
   * @param {}} res
   */
  async afterSendTaskAndProjectionScreen (res) {
    const { state } = vue.$store
    const { task } = state
    let { wisdom_list } = checkWisdom(task.taskDeatails)
    // this.afterSendBasic(res);
    let arr = []
    let send_arr = []
    let conIds = ''
    const { contents } = res
    const { taskLog } = contents
    const { details } = taskLog
    if (details && details.length) {
      details.forEach((item) => {
        if (item.controlType == 11 && item.type == 1) {
          arr = [
            ...new Set([
              ...arr,
              item.conId
            ])
          ]
        }
      })
      if (arr) {
        if (arr && arr.length && wisdom_list && wisdom_list.length && arr.length == wisdom_list.length) {
          arr.forEach((item, index) => {
            if (wisdom_list[index].canProjectionScreen()) {
              send_arr.push(item)
            }
          })
        }
        if (send_arr && send_arr.length) {
          conIds = send_arr.join(',')
          vue.$openConfirm({
            title:'提示',
            desc:'是否同时开始投屏?',
            sure:'是',
            sureType: '', // 确定按钮样式 '','warn'
            cancel:'否',
            onSure: async ()=>{
              // this.afterSendTaskAndProjectionScreen(res);
              const project_res = await vue.$global.doPost({
                url: '/screen/screenShow',
                data: {conIds},
              })
              if(project_res?.contents?.taskLogs && project_res?.contents?.taskLogs?.length ){
                for(let i in project_res.contents.taskLogs){
                   // 消息列表追加
                  common.pushMessageList(project_res.contents.taskLogs[i])
                  // 消息盒子追加
                  common.pushMessageBox(Object.assign(
                    {},
                    project_res.contents.taskLogs[i],
                  ))
                }

              }
            },
            onCancel:() => {
            }
          })
        }
      }
    }
  }

  afterSendWidget = async (res, item, parent) => {
    this.signWidgetId({res, item, parent})
    // // console.log(item)
    this.afterSendBasic(res, false)
    // 编辑锁
    vue.$set(item, 'islock', 0)
    vue.$set(item, 'userlock', null)
    // 控件修改标记
    vue.$set(item, 'modified', false)
    if(res.message === 'success'){
      if(res.contents.taskLog){
        vue.$set(item, 'updatenum', item.updatenum+1)
      }
      // 附件和图片如果有无id的文件 则加载数据进行替换
      console.log(item)
      if (Number(item.type) === 9 || Number(item.type) === 6) {
        const has_no_id_file = item.fileList.find(unit=>{
          return !unit.id && (!unit.type || unit.type == 0)
        })
        console.log(has_no_id_file)
        // 获取任务数据
        const { state } = store
        const { task } = state
        if (task && task.task && (task.task.id??'' !== '')) {
          const res = await global.doPost({
            url: '/projectTask/taskDetail',
            data: {
              taskId: task.task.id,
              linkflag: 1,
              seeType: 0
            }
          })
          if (res.message === 'success') {
            const {contents} = res
            if(!contents){
              return
            }
            const { taskDeatails } = contents
            if (!taskDeatails || Number(taskDeatails.length) !== 0) {
              let aim = undefined
              taskDeatails.find(unit=>{
                if(unit.id === item.id){
                  aim = unit
                }else{
                  if(unit.controlLinkList && unit.controlLinkList.length){
                    unit.controlLinkList.find(child=>{
                      if(child.id === item.id){
                        aim = child
                      }
                    })
                  }
                }
              })
              if (aim) {
                vue.$set(item, 'fileList', aim.fileList)
              }
            }
          }
        }
      }

      if (item.modify) {
        item.modify()
      }
      // 更新原始数据
      let { task_original } = vue.$store.state
      let { taskDeatails } = task_original
      if (parent) {
        // 更新的关联
        const parent_obj = taskDeatails.find((unit) => {
          return unit.id == parent.id
        })
        if (parent_obj !== undefined) {
          const index = parent_obj.controlLinkList.findIndex((unit) => {
            return unit.id == item.id
          })
          if (index !== -1) {
            parent_obj.controlLinkList[index] = vue.$tools.deepClone(item)
          }
        }
      } else {
        // 更新顶级控件
        const index = taskDeatails.findIndex((unit) => {
          return unit.id === item.id
        })
        if (index != -1) {
          taskDeatails[index] = (vue.$tools.deepClone(item))
        }
      }

    }
  }

  afterSendTeach = (params) => {
    const {
      res,
      closeTask
    } = params
    const { state } = store
    const { task } = state
    const { taskDeatails } = task
    /**
     * 发布后关闭任务前将所有modified 改为false
     * */
    taskDeatails.forEach((item) => {
      item.modified = false
    })
    store.state.task_config.open_by_call = false // 将调用状态关闭
    if (closeTask) {
      vue.$api.moduleTask.closeTask()
    }
    // common.jumpToSellPage(res)
  }

  afterEditPublicNotice = (params={}) => {
    // // console.log(params)
    const {contents} = params;
    const {work} = contents;
    const {state, commit} = vue.$store;
    const {chat_detail, task_config} = state;
    const {chatUserName} = chat_detail;
    const {chat_id} = task_config;
    const userid = vue.$tools.getUserId();
    const user = vue.$tools.getCookie('user');
    const {thumbnail, userName} = user;

    let data = {
      chatId: chat_id,
      createTime: Number(Date.now()),
      deleteFlag: null,
      details: [],
      fromUserId: userid,
      hide: null,
      isremind: null,
      linkme: false,
      logType: 2,
      shareType: null,
      shareUrl: null,
      taskId: null,
      taskName: null,
      taskType: null,
      taskusers: null,
      thumbnail,
      toUserId: null,
      type: 1,
      userName: chatUserName === '' ? userName : chatUserName,
      work: work || '更新了公示板'
    }
    let message_list = vue.$store.state.message_list
    message_list.push(data)
    vue.$nextTick( function() {
      // 触发滚动到底部
      commit('set_task_trigger_scroll_to_bottom', 1)
    })
  }


  afterSaveDraft = (res) => {
    const { state } = store
    const { task, task_config } = state
    const { taskDeatails } = task
    /**
     * 发布后关闭任务前将所有modified 改为false
     * */
    taskDeatails.forEach((item) => {
      item.modified = false
    })
    vue.$api.editTask.updateDraftList({
      taskId:res.contents.task ? res.contents.task.id : task_config.task_id,
      name: task_config.task_name,
      updateTime: res.contents.task ? res.contents.task.updateTime : new Date().getTime(),
      createusername:task.task.createusername || vue.$tools.getCookie('user').userName,
      createuserthumbnail: task.task.createuserthumbnail || vue.$tools.getCookie('user').thumbnail,
      taskType: (task_config.price !== null && task_config.price !== '') ? 2 : task_config.task_type,
      colour: task_config.color,
    })
  }
  /**
   * 判断是否需要上传提醒
   */
  async checkReminder(params){
    const {res} = params || {};
    if(res.contents && res.contents.taskLog){
      const taskLog = res.contents.taskLog;

      const {task} = vue.$store.state;
      const {taskDeatails} = task;
      let needed = Boolean(!taskDeatails.every(item=> {
          const {remindList, id} = item;
          return id || (!id && (!remindList || remindList.length == 0));
        })
      )
      if(needed){
        const res = await this.autoPostReminder({taskLog});

        return res;
      }
    }
  }


  // 自动上传提醒
  async autoPostReminder(params){
    const {taskLog} = params || {};
    const {details, taskId} = taskLog;
    const {task} = vue.$store.state;
    const {taskDeatails} = task;
    let aim ;
    // 没有id的控件列表
    let no_id_details = [];
    taskDeatails.forEach(item=> {
      if(!item.id){
        no_id_details.push(item);
      }
    })
    let log_create_list = [];
    details.forEach(item=>{
      const {type, conIdP, conId} = item;
      if(type == 1 && (conId && !conIdP)){
        log_create_list.push(item);
      }
    })

    const index = no_id_details.findIndex(item=>{
      if(!item.id && item.remindList && item.remindList.length){
        const reminder = item.remindList.find(unit=>{
          return !unit.complete;
        })
        aim =  reminder;
      }
      return aim;
    })
    if(index != -1 && aim){
      const res = await vue.$api.moduleTask.createReminder(Object.assign(
        {},
        aim.upload_data,
        {
          taskId,
          conId: log_create_list[index].conId,
        }
      ))
      if(res.message === 'success'){
        taskLog.isremind = taskLog.isremind ? Number.isremind + 1 : 1;
        common.updateReminderConcrened({taskId, type: 'decrease'})
      }
      vue.$set(aim, 'complete', true );
      return this.autoPostReminder({taskLog});

    }else{
      // end
      // console.log('完成');
      return true;
    }
  }

  /**
   * 标记控件id (控件发送关联后进行标记,防止下次继续发送该关联控件)
   */
  signWidgetId(params={}){
    const {res, item, parent} = params;
    const {contents} = res;
    const {taskLog} = contents;
    const {controlLinkList} = item;
    let has_link = false;
    // 判断是否有关联控件被上传
    if(parent === null){

      if(controlLinkList){
        const aim = controlLinkList.find(unit=>{
          return !unit.id;
        })
        if(aim){
          has_link = true;
        }

      }
    }
    if(has_link){
      if(taskLog){
        const {details} = taskLog;
        const {id:pId} = item;
        if(details){
          details.forEach(unit=> {
            const {conIdP, conId, controlType} = unit;
            const aim = controlLinkList.find(link_unit=>{
              return !link_unit.id
            })
            if(aim.type === controlType && pId === conIdP){
              // aim.id = conId;
              vue.$set(aim, 'id', conId)
            }
          })
        }
      }
    }
  }
}

const sendTaskFun = new SendTaskFun()

export default sendTaskFun


/**
 * 检查任务是否有可以投屏的控件
 * @param {*} list
 * @returns
 */
function checkWisdom (list) {
  const { state } = store
  const { task_config } = state
  const { call } = task_config
  let can_projection = false
  let wisdom_list = [] // 所有没有id的智慧桌控件
  if (list && list.length) {
    list.forEach((item) => {
      // TODO (!unit.id || call) 或 (!item.id || call) 增加了调用时的逻辑判断 ctask时需要优化
      if (item.type == 11 && (!item.id || call)) {
        wisdom_list.push(item)
        if (item.canProjectionScreen() && (!item.id || call)) {
          can_projection = true
        }
        const {controlLinkList} = item
        if (controlLinkList && controlLinkList.length) {
          for (const unit of controlLinkList) {
            if (unit.type == 11 && (!unit.id || call)) {
              wisdom_list.push(unit);
              if ((!unit.id || call) && unit.canProjectionScreen && unit.canProjectionScreen()) {
                can_projection = true
              }
            }
          }
        }
      } else {
        const { controlLinkList } = item
        if (controlLinkList && controlLinkList.length) {
          for (const unit of controlLinkList) {
            if (unit.type == 11 && (!unit.id || call)) {
              wisdom_list.push(unit)
              if ((!unit.id || call) && unit.canProjectionScreen && unit.canProjectionScreen()) {
                can_projection = true
              }
            }
          }
        }
      }
    })
  }
  return { can_projection,wisdom_list }
}
