/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-04 00:32:12
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-09 18:29:38
 * @FilePath     : /miaohang/src/utils/plugin/modules/personal_center/gift_card_receiving.js
 * @Description  : 
 */
import PluginComponent from '@/views/CardPage/invitation/Plugins/Receiving/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openGiftCardReceiving = (params={}) => {
      // // console.log(obj)
      const { pcard, mobile, title, userinfo, pcarduser, page, routeGetTime } = params ?? {}
      instance.title = title ?? '权益领取'
      instance.userinfo = userinfo
      instance.pcarduser = pcarduser
      instance.page = page
      instance.mobile = mobile
      instance.routeGetTime = routeGetTime
      instance.visible = true
    }
    Vue.prototype.$closeGiftCardReceiving = () => {
      instance.visible = false
    }
  }
}

export default PluginMember