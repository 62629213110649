<!--
 * @Author       : Hugo
 * @Date         : 2022-02-22 09:52:46
 * @LastEditTime : 2022-02-22 09:54:22
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/AddToCustomList/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible && 0"
  >
    <plugin-component
      :title="title"
      @handleClose="doClose"
    ></plugin-component>
  </div>
</template>

<script>
import PluginComponent from '@/components/ShoppingMail/AddToCustomList/index.vue';
export default {
  components: {
    PluginComponent,
  },
  data(){
    return{
      visible: false,
      title  : undefined,
    }
  },
  mounted(){

  },
  methods: {
    doClose(){
      this.visible = false;
    }
  }
}
</script>
