<!--
 * @Author       : Hugo
 * @Date         : 2022-04-13 12:06:16
 * @LastEditTime : 2022-04-13 14:27:48
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/ShareConfirm/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="share_confirm_page"
  >
    <div class="list_group">
      <div class="unit cp"
        v-for="(item,index) in list"
        :key="index"
        @click="onReturn();"
      >
        <el-tooltip
          effect="dark"
          :content="item.name"
          placement="bottom"
          :open-delay="500"
        >
          <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
        </el-tooltip>
        
      </div>
    </div>
    <input type="text" class="input"
      placeholder="简短描述"
      v-model="work"
    >
    <div class="btn_group">
      <button class="button btn_dark"
        :disabled="disabled_share"
        @click.stop="doSure();"
      >确定 ({{list.length}})</button>
      <button class="button btn_light"
        @click="doClear();"
      >取消</button>
    </div>

  </div>
</template>
<script>
import store from '@/store'
export default {
  props: {
    list: {
      type: Array,
      default: undefined,
    },
    props_work: {
      type: String,
      default: '',
    }
  },
  data(){
    return{
      work: '',
      disabled_share: false,
      loading: false,
    }
  },
  computed: {
  },
  methods: {
    doClose(){
      this.$emit('handleClose')
      // this.$store.commit('set_switch_share_confirm', false);
    },
    onReturn(){
      this.$emit('handleReturn', {work: this.work.trim()})
    },
    openMultipleContacts(){
      const {commit} = this.$store;
      this.doClose();
      if(this.work){
        commit('set_share_work', this.work);
      }
      commit('set_switch_multiple_contacts', true);
    },
    doClear(){
      this.doClose();
      // const {commit} = this.$store;
      // commit('set_share_data', null);
      // commit('set_multiple_contacts_list', null);
      // commit('set_multiple_contacts_callback', null);
      // commit('set_share_work', '');
      // commit('set_next_switch_multiple_contacts', '');
    },
    async doSure(){
      // this.$global.preventDuplicateClicks(async ()=>{
      //   if(this.disabled_share){
      //     return;
      //   }
      //   this.disabled_share = true;
      //   if(this.work){
      //     this.$store.commit('set_share_work', this.work);
      //   }
      //   const {multiple_contacts_callback} = this.$store.state;
      //   let res;
      //   res = await multiple_contacts_callback();
      //   if(res){
      //     this.doClear();
      //   }
      //   this.disabled_share = false;
      //   return ;
      // })
      this.$emit('handleSure', this.work.trim())
     
    },
    initWork(){
      const {props_work} = this
      if((props_work??'') !== ''){
        this.work = props_work
      }
      // const {share_work} = this.$store.state;
      // if(share_work){
      //   this.work = share_work;
      // }
    },
    init(){
      // this.$store.commit('set_next_switch_multiple_contacts', 'switch_share_confirm'); // 设置多选联系人的下级弹窗
      this.initWork();
      
    },
  },
  async mounted(){
    this.init();
  }

}
</script>
<style lang="scss" scoped>
.share_confirm_page{
  @include flex;
  @include bbox;
  flex-direction: column;
  align-items: stretch;
  padding: 40px;
  padding-top: 0;
  background: #FFFFFF;
  // box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  // border-radius: 4px;
  min-width: 440px;
}
.title{
  text-align: center;
  font-size: 18px;
  color: #333;
}
.list_group{
  @include flex;
  flex-wrap: wrap;
  min-width: 360px;
  max-width: 486px;
  padding: 20px 0 10px;
  .unit{
    padding-right: 10px;
    padding-bottom: 10px;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      display: block;
    }
  }
}
.input{
  @include bbox;
  @include placeholder(#ccc);
  display: block;
  outline: none;
  line-height: 46px;
  background: #FBFBFB;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  color: #333;
  font-size: 14px;
  padding: 0 20px;
}
.btn_group{
  @include flexsb;
  padding-top: 30px;
  width: 100%;
  .button{
    @include bbox;
    cursor: pointer;
    font-size: 18px;
    line-height: 50px;
    width: 160px;
    border-radius: 4px;
  }
}
</style>