/*
 * @Author       : Hugo
 * @Date         : 2022-04-27 16:40:24
 * @LastEditTime : 2022-04-27 16:45:28
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/classes/index.js
 * @Description  : 
 * @^_^          : 
 */
import Navigation  from "./modules/Navigation"
import LargeTask from "./modules/LargeTask"
import PackageSelectTask from '@/classes/modules/PackageSelectTask'
import TeachingPackage from '@/classes/modules/TeachingPackage'
import Dialog from '@/classes/modules/Dialog'
import FileData from '@/classes/modules/FileData'

class Classes {
  static listForClasses = listForClasses
  static Navigation = Navigation
  static LargeTask = LargeTask
  static PackageSelectTask = PackageSelectTask
  static TeachingPackage = TeachingPackage
  static Dialog = Dialog
  static FileData = FileData
}




export {
  listForClasses,
  Navigation,
  LargeTask,
  PackageSelectTask,
  TeachingPackage,
  Dialog,
  FileData
}

export default Classes

function listForClasses(params){
  const {list, classes} = params || {}
  if(!list){
    return []
  }
  if(list.length === 0){
    return []
  }
  if(!classes){
    return list
  }
  const classes_list = []
  list.forEach(item=>{
    const new_classes = new classes(item)
    classes_list.push(new_classes)
  })
  return classes_list
}