/*
 * @Author       : Hugo
 * @Date         : 2021-12-23 15:56:12
 * @LastEditTime : 2022-03-07 16:07:33
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/types/index.js
 * @Description  : 
 * @^_^          : 
 */
class Types{
  /**
   * 将列表内容转换为对象
   * @param {*} params 
   * @returns 
   */
  transformObjectForList(params={}){
    const {list, obj} = params;
    // // console.log({list, obj})
    if(!list){
      return [];
    }
    if(!obj){
      return [];
    }
    if(list.length === 0){
      return [];
    }
    const obj_list = [];
    list.forEach(item=>{
      const new_obj = new obj(item);
      obj_list.push(new_obj);
    })
    return obj_list;
  }
}

const types = new Types();

export default types;