<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="80%"
      width="80%"
    >
      <div
        class="c_plugin_migrate_task_index d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose ="handleClose"
          :title="title"
          :close="true"
          background_color="#fff"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          :params="params"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonTitle from '@/components/PopUp/Common/Title'
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/PopUp/MigrateTask/SelectTask'
import { listForClasses, PTask } from '@/types'
import tools from '@/api/tools'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent
  },
  data(){
    return {
      visible: false,
      // title: '',
      // onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      params: {
        pChatDetail: undefined,
        title: '任务迁移'
      },
      taskList: []
    }
  },
  computed: {
    // title: {
    //   get() {
    //     const { params } = this
    //     if ((params ?? '') === '') {
    //       return '任务迁移'
    //     }
    //     const { title } = params
    //     return title ?? '任务迁移'
    //   },
    //   set() {
    //     // this.params.title = val
    //   }
    // }
    title () {
      const { params } = this
      if ((params ?? '') === '') {
        return '任务迁移'
      }
      const { title } = params
      return title ?? '任务迁移'
    }
  },
  watch: {
    visible: function (val) {
      // if (val) {
      //   const taskList = this.generateTaskList()
      //   this.$set(this, 'taskList', taskList)
      // }
    }
    // title: function (val) {
    //   console.log(val)
    // },
    // params: {
    //   handler: function (val) {
    //     console.log(val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    handleClose() {
      const { params } = this
      const { checkSelected } = params
      if (checkSelected) {
        this.params.checkSelected = false
        this.params.title = '任务迁移'
        return
      }
      this.visible = false
    },
    handleReturn(params) {
      const { work } = params
      const { onReturn, list } = this
      if (onReturn) {
        console.log('onReturn')
        onReturn({ list, work })
      }
      this.handleClose()
    },
    async handleSure() {
      if (this.onSure) {
        if (this.loading) {
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_migrate_task_index {
  background-color: #fff;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  overflow-y: auto;
  .main{
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
