var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register" }, [
    _c("div", { staticClass: "tab" }, [
      _vm.show
        ? _c("ul", [
            _c("li", { class: _vm.step >= 0 ? "pitchon" : "" }, [
              _c("p", [_vm._v("1")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.tabCLick(0)
                    },
                  },
                },
                [_vm._v("验证手机号码")]
              ),
            ]),
            _c("li", { class: _vm.step >= 1 ? "pitchon" : "" }, [
              _c("div", { staticClass: "placeholder" }),
              _c("p", [_vm._v("2")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.tabCLick(1)
                    },
                  },
                },
                [_vm._v("密码设置")]
              ),
            ]),
            _c("li", { class: _vm.step >= 2 ? "pitchon" : "" }, [
              _c("div", { staticClass: "placeholder" }),
              _c("p", [_vm._v("3")]),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.tabCLick(2)
                    },
                  },
                },
                [_vm._v("实名认证及用户信息")]
              ),
            ]),
          ])
        : _c("div", { staticClass: "tabagreement" }, [
            _vm._v(_vm._s(_vm.docName)),
          ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 0,
            expression: "step==0",
          },
        ],
        staticClass: "operate",
      },
      [
        _c("Telverification", {
          on: { myevent: _vm.handleEvent, user: _vm.usernameTel },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 1,
            expression: "step==1",
          },
        ],
        staticClass: "operate",
      },
      [
        _c("Setpassword", {
          on: { myevent: _vm.handleEvent, pass: _vm.passwordTel },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.step == 2,
            expression: "step==2",
          },
        ],
        staticClass: "operate",
      },
      [
        _c("Authentication", {
          on: {
            complete: _vm.complete,
            completeNew: _vm.completeNew,
            goBack: _vm.goBack,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }