<template>
  <div
    v-if="taskList"
    class="c_teaching_package_select_task_task_list bbox"
  >
    <div class="list_group d-flex flex-wrap bbox">
      <template
        v-for="(item, index) in taskList"
      >
        <thumbnail-task
          :key="index"
          :p-task="item"
          :can-select="true"
          :package-select-task="packageSelectTask"
          @handleSelected="handleSelected"
        ></thumbnail-task>
      </template>
    </div>
    <div class="more_group d-flex align-center justify-center">
      <div
        v-show="showMore && !showLoading"
        class="more cp"
        @click.stop="loadmore()"
      >
        加载更多
      </div>
      <loading-icon
        v-show="showLoading"
        size="26"
      ></loading-icon>
    </div>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/PopUp/TeachingPackage/SelectTask/ThumbnailTask'
import LoadingIcon from '@/components/basic/loadingIcon'
export default {
  name: 'TaskList',
  components: {
    LoadingIcon,
    ThumbnailTask
  },
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    showMore() {
      const { packageSelectTask } = this
      // const {
      //   origin,
      //   search,
      //   type
      // } = packageSelectTask
      // let moreFieldName = 'moreSellList'
      // if (origin === 'sell') {
      //   moreFieldName = 'moreSellList'
      //   if (search !== '') {
      //     moreFieldName = 'moreSearchSellList'
      //   }
      // }
      // if (origin === 'shopping' && type === 'task') {
      //   moreFieldName = 'moreShoppingTask'
      //   if (search !== '') {
      //     moreFieldName = 'moreSearchShoppingTask'
      //   }
      // }
      const moreFieldName = packageSelectTask.getMoreFieldName()
      return packageSelectTask[moreFieldName]
    },
    showLoading() {
      const { packageSelectTask } = this
      const { loading } = packageSelectTask
      return loading
    }
  },
  methods: {
    handleSelected(params) {
      const { pTask } = params
      const { packageSelectTask } = this
      const { selectedList } = packageSelectTask
      if (selectedList.length === 0) {
        selectedList.unshift(pTask)
        return
      }
      const index = selectedList.findIndex((unit) => {
        return unit.id === pTask.id
      })
      if (index === -1) {
        selectedList.unshift(pTask)
      } else {
        selectedList.splice(index, 1)
      }
      // console.log(packageSelectTask)
    },
    loadmore() {
      this.$emit('handleLoadmore')
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_task_list{
  width: 100%;
  padding: 20px;
  .list_group{
    width: 100%;
  }
  .more_group{
    width: 100%;
    height: 40px;
    .more{
      font-size: 14px;
      color: $main_blue;
    }
  }
}
</style>
