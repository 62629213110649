/*
 * @Author       : Hugo
 * @Date         : 2021-12-24 10:12:33
 * @LastEditTime : 2022-04-26 16:13:19
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PPackage/index.js
 * @Description  : 
 * @^_^          : 
 */

import vue from "@/main"
import global from '@/api/global'
import { PackageSelectTask } from '@/classes'
class PPackage{
  // buy = undefined
  // checkstatus         = undefined;
  // content             = undefined;
  // createTime          = undefined;
  // createusername      = undefined;
  // createuserthumbnail = undefined;
  // delflag             = undefined;
  // id                  = undefined;
  // price               = undefined;
  // read                = undefined;
  // sale                = undefined;
  // show                = undefined;
  // signUserId          = undefined;
  // title               = undefined;
  // updateTime          = undefined;
  // users               = undefined;
  // webpic              = undefined;
  // showflag            = undefined;
  // accountnum          = undefined;
  // readamount          = undefined;
  //
  // /**自定义字段 custom field */
  // cf_selected = undefined;
  constructor(props={}){
    const {
      buy,
      checkstatus,
      content,
      createTime,
      createusername,
      createuserthumbnail,
      delflag,
      id,
      price,
      read,
      sale,
      show,
      signUserId,
      title,
      updateTime,
      users,
      webpic,
      showflag,
      accountnum,
      readamount,
      packageSelectTask
    } = props
    this.buy = buy
    this.checkstatus = checkstatus
    this.content = content
    this.createTime = createTime
    this.createusername = createusername
    this.createuserthumbnail = createuserthumbnail
    this.delflag = delflag
    this.id = id
    this.price = price
    this.read = read
    this.sale = sale
    this.show = show
    this.signUserId = signUserId
    this.title = title
    this.updateTime = updateTime
    this.users = users
    this.webpic = webpic
    this.showflag = showflag
    this.accountnum = accountnum
    this.readamount = readamount
    this.packageSelectTask = new PackageSelectTask(packageSelectTask)
  }

  getData = async ()=>{
    const {id} = this;
    const res  = vue.$api.teaching_package.getData({id});
    return res;
  }

  /**
   * 获取讲师商城数据[默认第一个]
   */
  getLecturerShopData = async (params={}) => {
    const {users} = this;
    const user = users[0];
    const {userId} = user;
    const res = await vue.$global.doPost({
      url: '/projectShop/teacherDetail',
      data: {userId},
    })
    const {message, contents} = res;
    if(message === 'success'){
      // const {packagesall, packageshot, packagesnew, tasksall, taskshot, tasksnew} = contents;
      return contents;
    }

    return false;

  }

  getAuditStatus(){
    const {checkstatus} = this;
    let color = '#999';
    let audit = '';
    // 审核状态1未审核 2审核中 3审核通过 4审核不通过
    switch(checkstatus){
      case(1):
        color = '#999';
        audit = '未审核';
        break;
      case(2):
        color = '#FAAD14';
        audit = '审核中';
        break;
      case(3):
        color = '#05AD00';
        audit = '通过';
        break;
      case(4):
        color = '#D82E0D';
        audit = '未通过';
        break;
      default:
        color = '#999';
        audit = '';
        return false;
    }
    return {color, audit};
  }


  /**
   * 上架
   */
  doPutaway = async (params={}) => {
    const {cb} = params;
    // / projectShop/ upperTasks
    vue.$openConfirm({
      title: '提示',
      desc: '是否确认上架至商城？',
      sure:'是',
      cancel:'否',
      sureType: '', // 确定按钮样式 '','warn'
      onSure: async ()=>{
        const res = await vue.$global.doPost({
          url: '/projectShop/upperPackages',
          data: {packageids: this.id},
        })
        const {message} = res;
        if(message === 'success'){
          this.showflag = 1;
          vue.$notice({desc: '上架成功', type: 'success'})
          if(cb){
            cb();
          }
        }

      },
      onCancel:()=>{},
    })
  }
  /**
   * 下架
   */
  doSoldOut = async (params={}) => {
    const {cb} = params;
    vue.$openConfirm({
      title: '提示',
      desc: '是否确认从商城下架？\n教学包将不在商城展示，但不影响已购买用户',
      sure:'是',
      cancel:'否',
      sureType: '', // 确定按钮样式 '','warn'
      onSure:async ()=>{
        const res = await vue.$global.doPost({
          url: '/projectShop/downPackage',
          data: {packageid: this.id},
        })
        const {message} = res;
        if(message === 'success'){
          this.showflag = 0;
          vue.$notice({desc: '下架成功', type: 'success'})
          if(cb){
            cb();
          }
        }
      },
      onCancel:()=>{},
    })
  }

  /**
   * 选择/取消
   */
  doSelect = () => {
    this.cf_selected = true;
  }
  doDeselect = () => {
    this.cf_selected = false;
  }

  /**
   * 免费领取
   */
  getFree = async () => {
    const {price, id} = this
    if(Number(price) === 0){
      const res = await global.doPost({
        url:'/projectTeach/buy',
        data: {
          type: 2,
          price,
          id
        }
      })
      if(res.message === 'success'){
        this.buy = true
        return true
      }
    }
    return
  }
}

export default PPackage;