<!--
 * @Author       : Hugo
 * @Date         : 2020-07-13 20:50:49
 * @LastEditTime : 2020-08-27 16:54:19
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : \miaohang\src\components\basic\toast.vue
 * @^_^
--> 
<template>
  <transition name="fade">
  <div class="toast_wrapper" v-show="visible">
    <div class="toast">
      <img src="@/assets/images/common/toast_success.png" alt="" class="success icon" v-if="icon!='none'">
      <div class="title">{{title}}</div>
      <div class="desc" v-if="desc">{{desc}}</div>
    </div>
  </div>
  </transition>
 
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      title: '',
      desc: '',
      icon: '',
    }
  }
}
</script>
<style lang="scss" scoped>
.toast_wrapper{
  @include flex;
  justify-content: center;
  width:100vw;
  height:100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
  .toast{
    @include flex;
    @include bbox;
    flex-direction: column;
    // width:193px;
    padding: 40px 40px 32px;
    color: #fff;
    background-color: rgba(0,0,0,0.7);
    text-align: center;
    /* position: fixed; */
    /* top: 30%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    border-radius: 10px;
    .icon{
      width:62px;
      height: 44px;
      margin-bottom: 29px;
    }
    .title, .desc{
      text-align-last: center;
      text-align: justify;
      text-justify: inter-ideograph;
    }
    .title{
      width:100%;
      font-size:18px;
      
      font-weight:400;
      color:rgba(255,255,255,1);
    }
    .desc{
      width: 100%;
      padding-top:9px;
      font-size:16px;
      
      font-weight:400;
      color:rgba(255,255,255,1);
    }
  }
}
</style>
<style scoped>
/* vue动画过渡效果设置 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>