var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "80%", max_height: "90%" } },
    [
      _c(
        "div",
        { staticClass: "c_pop_up_task_index d-flex flex-column" },
        [
          _c("classes-task", {
            attrs: {
              data: _vm.data,
              backup: _vm.backup,
              large_task: _vm.large_task,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }