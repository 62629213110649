/*
 * @Author       : Hugo
 * @Date         : 2022-03-11 11:28:47
 * @LastEditTime : 2022-03-31 15:38:53
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/WebIM/module/wisdom.js
 * @Description  : 
 * @^_^          : 
 */
import store from '@/store'
import {PScreen, PScreenTask} from '@/types'
import vue from '@/main'
class Wisdom{
  endScreen = (ext) => {
    /**
    
      PScreen: "{\"createTime\":1647829623000,\"id\":11,\"screenName\":\"6号测试桌\",\"screenNo\":\"2022-6\",\"screenTask\":{\"createTime\":1648612394521,\"endFlag\":1,\"id\":820,\"screenId\":11,\"startTime\":1648612394521,\"taskDetailId\":101079}}"
      chatId: "132"
      chatType: "1"
      conId: "101068"
      em_apns_ext: {em_push_title: '屏幕：6号测试桌被用户Touber顶替'}
      pushType: "25"
      taskId: "13485"


     */
    const {
      taskId,
      conId,
      PScreen,
    } = ext;
    const {state} = store;
    const {task} = state;
    if(task && task.task && task.task.id == taskId){
      const {taskDeatails} = task;
      if(taskDeatails && taskDeatails.length){
        for(let item of taskDeatails){
          if(item.id == conId){
            this.changeScreen(item, JSON.parse(PScreen))
          }
          const {controlLinkList} = item;
          if(controlLinkList && controlLinkList.length){
            for( let cell of controlLinkList){
              if(cell.id == conId){
                this.changeScreen(cell, JSON.parse(PScreen))
              }
            }
          }
        }
      }
    }

  }

  changeScreen(taskDetail, screen){
    const {screenList} = taskDetail;
    // const new_list = [];
    if(screenList && screenList.length && screen){

      const index = screenList.findIndex(item=>{
        return Number(item.id) === Number(screen.id)
      })
      if(index !== -1){
        vue.$set(taskDetail.screenList, index, new PScreen(Object.assign(
          {},
          screen,
        )))
      }
      return
    }

    vue.$set(taskDetail, 'screenList', [
      ...new Set([
        ...[new PScreen(Object.assign(
          {},
          screen
        ))]
      ])
    ])
    
    // for(const unit of screenList){
    //   new_list.push(new PScreen(Object.assign(
    //     {},
    //     unit,
    //     {
    //       screenTask: {
    //         undefined,
    //       },
    //       useflag: 0,
    //     }
    //   )))
    // }
    // vue.$set(taskDetail, 'screenList', new_list)
  }
}

const wisdom = new Wisdom();

export default wisdom;