import oss from '@/api/modules/oss'
import global from '@/api/global'
import { PTaskLog } from '@/types'
import store from '@/store'
import vue from '@/main'
class Dialog {
  constructor (params) {
    const {
      files,
      chatId,
      chatType,
      uploadingFile, // 文件上传中？
    } = params ?? {}

    this.files = files
    this.chatId = chatId
    this.chatType = chatType
    this.uploadingFile = uploadingFile ?? false
  }

  autoUpload = async (params) => {
    const { handleSuccess, handleEmpty } = params
    const { files } = this
    if (!files || files.length === 0) {
      if (handleEmpty) {
        handleEmpty()
      }
      return
    }
    this.uploadingFile = true

    // 查找没有url 并且 不是失败的文件
    const unloadFile = files.find((unit) => {
      return !unit.url && !unit.failed
    })
    if ((unloadFile ?? '') !== '') {
      const res = await this.upload(unloadFile)
      if (res) {
        if (res === 'no_upload') {
          unloadFile.url = res
          unloadFile.noUpload = true
        } else {
          unloadFile.url = res
          unloadFile.success = true
          const sendRes = await this.sendFileMessage(unloadFile)
          if (sendRes.message === 'success') {
            const { contents } = sendRes
            if ((contents.PTaskLog ?? '') !== '') {
              const pTaskLog = new PTaskLog(contents.PTaskLog)
              this.pushMessageList(pTaskLog)
            }
          }
        }
      } else {
        unloadFile.failed = true
      }
      setTimeout(() => {
        this.autoUpload(params)
      }, 200)
    } else {
      this.uploadingFile = false
      if (handleSuccess) {
        handleSuccess()
      }
    }
  }

  upload = async (item) => {
    const handleProgress = (progress)=>{
      // this.$set(item, 'progress', Math.floor(progress*100));
      item.progress = Math.floor(progress * 100)
    }
    const {file} = item
    const res = await oss.uploadFile({
      file,
      handleProgress
    })
    if (res !== false) {
      return res
    }
    return res
    // const res = await this.$upload.upload
  }


  sendFileMessage = async (fileData) => {
    const { chatId, chatType } = this
    const { url, width, height } = fileData
    const data = {
      chat_id: chatId,
      chat_type: chatType,
      content: url,
      wide: width,
      high: height
    }
    const res = await global.doPost({
      url: '/projectNews/sendPicMessage',
      data,
    })
    return res
  }

  pushMessageList = (pTaskLog) => {
    const isBottom = store.state.dialogData.isBottom
    const message_list = store.state.message_list
    message_list.push(pTaskLog)
    if (isBottom) {
      vue.$nextTick(function () {
        // setTimeout(() => {
          vue.$api.messageList.scrollToLatest()
        // }, 1000)
      })
    } else {
      // store.state.dialogData.unreadNumber += 1
    }
  }

}

export default Dialog