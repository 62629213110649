<template>
  <div 
    class="components_task_sidebar_widget_container_page bbox"
  >
    <draggable
      class="control_draggable d-flex flex-wrap align-center"
      :list="list"
      :group="{name: 'widget', pull: editable ? 'clone' : false, put: false }"
      :clone="cloneWidget"
      :sort="false"
    >
      <template
        v-for="(item, index) in list"
      >
        <widget-button
          class="cp"
          :key="index"
          :name="item.name"
          :title="item.title"
          :type="item.type"
          :editable="editable"
          @click.native.stop="clickNewWidget(item)"
        ></widget-button>
      </template>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import WidgetButton from './Button.vue';
import {PTaskDetail} from '@/types';
import store from '@/store'
export default {
  components: {
    draggable,
    WidgetButton,
  },
  props:{
    large_task: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {
      list:  [
        {name:'text', type: 1, title: '文本'},
        {name: 'number', type: 2, title: '数字'},
        {name: 'money', type: 3, title: '金额'},
        {name: 'time', type: 4, title: '时间'},
        // {name: 'formula', type: 5, title: ''},
        {name: 'file', type: 6, title: '附件'},
        {name: 'hyperlink', type: 7, title: '链接'},
        // {name: 'location', type: 8, title: ''},
        {name: 'picture', type: 9, title: '图片'},
        {name: 'contact', type: 10, title: '名片'},
        {name: 'wisdom', type: 11, title: '智慧桌'},
      ],
    }
  },
  computed: {
    // task(){
    //   return this.$store.state.task;
    // },
    // taskDeatails(){
    //   const {task} = this;
    //   const {taskDeatails} = task;
    //   return taskDeatails;
    // },
    task_config(){
      return this.$store.state.task_config
    },
    // editable(){
    //   const {task_config} = this;
    //   const {editable} = task_config;
    //   return editable;
    // }
  },
  methods:{
    cloneWidget({type}){
      // // console.log(this.$store.state.task);
      // console.log('clone')
      let res = this.newWidget({type});
      res = new PTaskDetail(res);
      return res;
    },
    /**
     * 点击 新建控件
     */
    clickNewWidget({type}){
      const {large_task} = this
      const {data} = large_task
      const {taskDetails} = data
      const task_detail = this.newWidget({type});
      taskDetails.push(new PTaskDetail(task_detail));
      this.$nextTick(function(){
        this.$emit('handleAddNewWidget');
      })
    },
    /**
     * 新建控件
     */
    newWidget({type}){
      const {large_task} = this
      const {create_num} = large_task
      const widget_sort = create_num
      large_task.create_num += 1
      let task_detail = {
        type: type,
        remark : '', // 描述/标题
        content : '', // 内容/值
        controlLinkList: [],
        widget_sort, // TODO 废弃
        create_num,
        remindList: [],
      }
      if(type == 2){
        // 数值
        task_detail.unit = ''; // 单位
      }else if(type == 3){
        //金额
        task_detail.unit = '元';
      }else if(type == 4){
        task_detail.timetype = 1;
      }else if(type == 7){
        task_detail.hyperlink_list = [];
      }else if(type == 9){
        task_detail.fileList = [];
      }else if(type == 6){
        task_detail.fileList = [];
      }else if(type == 10){
        task_detail.userList = [];
      }
      return task_detail;
    },
  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_widget_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;

}
</style>