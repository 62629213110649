<!--
 * @Author       : Hugo
 * @Date         : 2022-04-13 15:35:09
 * @LastEditTime : 2022-04-14 14:58:06
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/FolderPreview/OperationBar.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_folder_preview_operation_bar bbox d-flex justify-space-between align-center un_sel"
  >
    <div class="from_group" v-text="''">来源:柳叶</div>
    <div class="operation_group d-flex align-center">
      <el-popover
        placement="bottom"
        trigger="click"
        :visible-arrow="false"
        popper-class="components_task_fun_bar_page_popover"
      >
        <div class="list un_sel">
          <div class="item bbox cp"
            @click="doShare();"
          >分享</div>
          <div class="item bbox cp"
            @click="touchCollect()"
            v-text="p_folder && p_folder.collectionflag ? '取消收藏' : '收藏'"
          >收藏</div>
        </div>
        <template slot="reference">
          <div class="menu cp"
          ></div>
        </template>
      </el-popover>
      <div class="close cp"
        @click.stop="doClose()"
      ></div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global.js'
export default {
  props: {
    p_task_log: {
      type: Object,
      default: undefined,
    },
    p_folder: {
      type: Object,
      default: undefined,
    },
  },
  methods:{
    doClose(){
      this.$emit('handleClose')
    },
    doShare(){
      this.p_folder.share()
    },
    touchCollect(){
      const {p_folder} = this
      const {collectionflag} = p_folder
      if(collectionflag){
        // 取消
      }else{
        this.$collectSelectRoute({
          onSure: async (params)=>{
            const res = await global.doPost({
              url: '/projectCustom/collectionFolder',
              data: {
                id: p_folder.id,
                fId: params.p_folder.id,
              }
            })
            if(res.message === 'success'){
              p_folder.collectionflag = 1
              this.$notice({desc:"收藏成功", type: 'success'})
              this.handleClose()
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_folder_preview_operation_bar{
  .from_group{
    font-size: 16px;
    color: #999;
  }
  .menu{
    @include background(20px, 5px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/menu.png);
  }
  .close{
    @include background(12px, 12px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/close.png);
  }
}
</style>
