<!--
 * @Author       : Hugo
 * @Date         : 2021-09-29 14:21:08
 * @LastEditTime : 2021-12-02 14:38:53
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/dialog/Sketch/Chat/Common.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="chat_common_page"
  >
    <div class="content" :class="[type]">{{msg.work}}</div>
    <div class="reply cp un_sel" :class="[type]"
      v-if="msg.logType !== 4"
      @click="$api.stopPropagation($event);replyTaskChat();"
    >回复</div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'send',
    }
  },
  methods: {
    /**
     * 回复任务聊天
     */
    replyTaskChat(){
      const {commit} = this.$store;
      commit('set_dialog_fun_status', 'text');
      commit('set_dialog_fun_chat_data', this.msg);

    },
  }
}
</script>

<style lang="scss" scoped>
.chat_common_page{
  position: relative;
  .content{
    @include bbox;
    @include transition;
    background: $main_blue;
    border-radius: 4px 4px 10px 10px;
    padding: 10px 10px 10px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    position: relative;
    z-index: 1;
    white-space: pre; 
    word-break: break-all; 
    white-space: pre-wrap; 
    word-wrap: break-word;
    &::after{
      @include background();
      content: '';
      display: block;
      width: 7px;
      height: 13px;
      position: absolute;
      top: 10px;
    }
    &.create{
      width: 350px;
    }
    &:hover{
      // text-decoration: underline;
    }
    &.receive{
      background-color: #fff;
      color: #333;
      &::after{
        left: -7px;
        background: {
          image: url(~@/assets/images/dialog/bubble_arrow_chat_receive.png);
        }
      }
    }
    &.send{
      &::after{
        right: -7px;
        background: {
          image: url(~@/assets/images/dialog/bubble_arrow_send.png);
        }
      }
    }
  }
  &:hover .reply{
    opacity: 1;
  }
  .reply{
    @include transition(0.2s);
    opacity: 0;
    position: absolute;
    top: 5px;
    font-size: 12px;
    width: 30px;
    text-align: right;
    color: $main_blue;
    &.receive{
      right: -30px;
    }
    &.send{
      display: none;
    }
  }
}
</style>
