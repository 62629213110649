<!--
 * @Author       : Hugo
 * @Date         : 2022-03-11 16:46:04
 * @LastEditTime : 2022-03-11 17:02:02
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/Task/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="80%"
    max_height="90%"
  >
    <div class="c_pop_up_task_index d-flex flex-column">
      <classes-task
        :data="data"
        :backup="backup"
        :large_task="large_task"      
      ></classes-task>
    </div>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper.vue';
import ClassesTask from '@/components/ClassesTask/index.vue';
export default {
  components: {
    CommonWrapper,
    ClassesTask,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
    large_task: {
      type: Object,
      default: undefined,
    },
  }
}
</script>

<style lang="scss" scoped>
.c_pop_up_task_index{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .main{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
}
</style>