var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_popup_record_of_communication_text_text_page bbox d-flex align-start",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "user_info flex-shrink-0 d-flex flex-column align-center mr-15",
        },
        [
          _c("img", {
            staticClass: "avatar",
            attrs: {
              src: `${_vm.$avatar_url}${_vm.item.thumbnail}@!small200`,
              alt: "",
            },
          }),
          _c("div", { staticClass: "name pt-5" }, [
            _vm._v(_vm._s(_vm.item.userName)),
          ]),
        ]
      ),
      _c("div", { staticClass: "main_group" }, [
        _c("div", { staticClass: "line_group mb-8 d-flex align-stretch" }, [
          _c(
            "div",
            {
              staticClass: "chat_content content bbox cp",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$tools.highlightKeywords(_vm.item.work, _vm.keyword)
                ),
              },
            },
            [
              _vm._v(
                "在之前已完成疫苗接种统计工作的基础上，对相关单位张贴:疫苗接种情况告知书。根据您之前上报的接种人数比例，勾选对应安全等级（如下图）。如无异议，物业将在今天17点前，前往贵司张贴告知书并拍照，同时根据要求需提供公司营业执照照片。"
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "fun_group bbox d-flex flex-column align-start justify-space-between",
            },
            [
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.getTime())),
              ]),
              _c(
                "div",
                {
                  staticClass: "check cp",
                  on: {
                    click: function ($event) {
                      return _vm.openContext()
                    },
                  },
                },
                [_vm._v("查看上下文")]
              ),
            ]
          ),
        ]),
        _vm.item.logType === 3
          ? _c("div", { staticClass: "line_group d-flex align-start" }, [
              _c(
                "div",
                {
                  staticClass: "task_name content bbox cp",
                  on: {
                    click: function ($event) {
                      return _vm.openContext()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.item.taskName))]
              ),
              _c("div", { staticClass: "fun_group" }),
            ])
          : _vm._e(),
      ]),
      _vm.switch_task_chat_context
        ? _c("task-chat-context", {
            attrs: {
              item: _vm.item,
              chat_type: Number(_vm.chat_type),
              chat_id: Number(_vm.chat_id),
              task_id: Number(_vm.item.taskId),
            },
            on: { handleClose: _vm.closeContext },
          })
        : _vm._e(),
      _vm.switch_chat_context
        ? _c("workgroup-context", {
            attrs: {
              chat_id: _vm.item.chatId,
              chat_type: _vm.item.type,
              task_id: _vm.item.taskId,
              item: _vm.item,
            },
            on: {
              handleClose: function ($event) {
                _vm.switch_chat_context = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }