import vue from '@/main';
import { registerLoading } from 'echarts';

class Notify{
  /**
   * 系统更新通知
   */
  systemUpdate(){
    const { state, commit } = vue.$store
    const { system_update_notify } = state
    if(!system_update_notify){
      vue.$notify.info({
        title: '妙行已更新',
        message: `
          <div>请保存正在编辑的内容, <span style="color:#0054A7;cursor:pointer;" onClick=" setTimeout(() => { location.reload() }, 1000);">点击这里</span> 更新</div>
        `,
        dangerouslyUseHTMLString: true,
        position: 'bottom-left',
        duration: 0,
        onClose: async ()=>{
          commit('set_system_update_notify', false);
        },
        onClick: ()=>{
          // vue.$notify.close();
          commit('set_system_update_notify', false);
          reload();
         
        }
      });
      commit('set_system_update_notify', true);
    }

  }

  offline(){
    vue.$notify.info({
      title: '环信已掉线,可能无法接收新消息通知',
      message: `
        <div>请保存正在编辑的内容, <span style="color:#0054A7;cursor:pointer;" onClick="location.reload();">点击这里</span>重新登录</div>
      `,
      dangerouslyUseHTMLString: true,
      position: 'bottom-right',
      duration: 0,
    });
  }
}
async function reload(){
  let loginUserCode = vue.$tools.getCookie('loginUserCode') || '';
  // // console.log(loginUserCode)
  const {logintype} = loginUserCode;
  // // console.log({logintype});
  if(logintype == 1){
    await refreshToken();
  }
}
async function refreshToken(){
  let password = vue.$tools.getCookie('password');
  let user = vue.$tools.getCookie('user');
  let autologin = vue.$tools.getCookie('autologin');
  let username = user.loginName;
  let data = {
    username,
    password,
    logintype: 4,
  };
  // if(autologin){
  let res = await vue.$http.request('/project/loginAuth', data);
  for(let i in res.contents){
    // vue.$cookies.set(i,res.contents[i]);
    vue.$tools.setCookie(i, res.contents[i]);
  }
  // }
}

const notify = new Notify;

export default notify;