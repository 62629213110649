/*
 * @Author       : Hugo
 * @Date         : 2022-04-11 15:11:33
 * @LastEditTime : 2022-04-12 17:06:39
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/store/stateModule/collect.js
 * @Description  : 
 * @^_^          : 
 */
const collect = {
  collect_nav: undefined,
  collect_config: undefined, // 配置
}

export default collect