var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_record_of_communication_hint_page bbox d-flex align-center justify-center",
    },
    [
      _vm.type === "default"
        ? _c("div", { staticClass: "default" }, [_vm._v("输入关键词进行搜索")])
        : _vm._e(),
      _vm.type === "none"
        ? _c(
            "div",
            { staticClass: "none_group d-flex flex-column align-center" },
            [
              _c("img", {
                staticClass: "none",
                attrs: {
                  src: require("@/assets/images/common/empty.png"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "text pt-20" }, [
                _vm._v("暂无搜索结果"),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }