<template>
  <div
    class="give_away_main bbox d-flex flex-column align-center"
    @click.stop="touchWrapper()"
  >
    <card-component
      width="422px"
      height="236px"
      class="mt-20"
      v-if="pcarduser"
      :pcard="pcarduser.card"
      :pcarduser="pcarduser"
      :show_cardno="true"
      title_size="28px"
    ></card-component>
    <div class="title_group d-flex align-center justify-space-between bbox mt-20">
      <div class="title">想对Ta说：</div>
      <div class="modify cp"
        v-show="!edit"
        @click.stop="touchEdit()"
      >修改</div>
      <div class="done btn_dark cp"
        v-show="edit"
        @click.stop="edit=false"
      >完成</div>
    </div>
    <el-input
      type="textarea"
      maxlength="80"
      :placeholder="placeholder"
      :class="{show:!edit}"
      v-model="input"
      show-word-limit
      class="give_away_main_input mt-12"
      resize="none"
      rows="4"
      :readonly="!edit"
      ref="input"
    ></el-input>
    <div
      class="time_group d-flex align-center"
    >
      <div class="symbol">
        *
      </div>
      <div class="key">
        设置好友领取期限至
      </div>
      <div
        class="time"
      >
        <div
          class="text bbox cp"
          :class="{empty: getTime === ''}"
          @click.stop="touchPicker()"
        >
          {{ getTimeFormat }}
        </div>
        <el-date-picker
          v-model="getTime"
          type="date"
          placeholder="选择日期"
          class="picker"
          ref="picker"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="hint mt-15"
      v-text="hint"
    ></div>
    <div class="give_away btn_dark"
      @click.stop="doSend()"
    >立即赠送</div>
  </div>
</template>

<script>
import global from '@/api/global'
import moment from 'moment'
import CardComponent from '@/views/PersonalCenter/GiftCard/Modules/Card.vue'
export default {
  props:{
    pcarduser: {
      type: Object,
      default: undefined,
    },
    giftCard: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    CardComponent,
  },
  data(){
    return {
      getTime: '',
      pickerOptions: {
        disabledDate: (time) => {
          return this.handleDisabledDate(time)
        }
      },
      placeholder: '哈喽！\n我正在使用妙行完成实践教学任务\n送你一张会员体验卡\n跟我一起来体验【项目思维】下的高效沟通和【边做边学】流程管理方法',
      input: '',
      hint: '礼品卡赠送说明：\n1、填写您想要对Ta说的话，点击「立即赠送」，即可看到礼品卡海报，可让好友直接扫描二维码使用礼品卡，也可选择一种赠送方式送出。\n2、好友收到礼品卡海报后，长按识别二维码即可按流程领取对应权益。\n3、礼品卡需在有效期内送出并领取，送出后注意提醒好友。\n4、每张礼品卡仅限一人领取使用，如果您同时送与多人，先领先得。\n5、礼品卡是妙行推出的具有不同权益的虚拟卡，包含的权益以卡面信息为准，好友领取并与其注册帐号绑定后即生效。',
      edit: false,
      loading: false,
    }
  },
  computed: {
    getTimeFormat() {
      const { getTime } = this
      if (getTime === '') {
        return '请选择领取期限'
      }
      return moment(getTime).format('yyyy-MM-DD')
    },
    getTimeFormatForPost() {
      const { getTime } = this
      if (getTime === '') {
        return ''
      }
      return `${moment(getTime).format('yyyy-MM-DD')} 23:59:59`
    }
  },
  methods: {
    touchWrapper() {
      // console.log({
      //   picker:this.$refs.picker,
      //   focus: this.$refs.picker.focus,
      //   blur: this.$refs.picker.blur
      // })
      // this.handlePickerBlur()
    },
    handlePickerBlur() {
      // this.
      // this.$refs.picker.$refs.input.blur()
      this.$refs.picker.blur()
    },
    handleDisabledDate(time) {
      const { pcarduser } = this
      const { termTime, channel } = pcarduser
      // return time.getTime() < Date.now() || time.getTime() > termTime
      if (channel === 0 || termTime === 0 || ((termTime ?? '') === '')) {
        return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000))
      }
      return (time.getTime() < (Date.now() - (24 * 60 * 60 * 1000)) || time.getTime() > termTime)
    },
    touchPicker() {
      // console.log(this.$refs.picker)
      this.$refs.picker.focus()
    },
    touchEdit(){
      this.edit = true
      this.$refs.input.focus()
      console.log(this.$refs.input)
    },
    async doSend(){
      if(this.loading){
        return
      }

      const {placeholder, input, pcarduser, getTimeFormatForPost, giftCard } = this
      if (getTimeFormatForPost === '') {
        this.$notice({
          desc: '请选择领取期限'
        })
        return
      }
      const {id} = pcarduser
      const data = {
        id,
        sendword: input.trim() !== '' ? input.trim() : placeholder,
        getTime: getTimeFormatForPost
      }
      this.loading = true
      const res = await global.doPost({
        url: '/card/sendCard',
        data,
      })
      if(res.message === 'success'){
        this.pcarduser.sendword = data.sendword
        const {contents} = res
        const {PCardUser} = contents
        this.pcarduser.poster = PCardUser.poster
        this.pcarduser.orcode = PCardUser.orcode
        this.pcarduser.orcodeurl = PCardUser.orcodeurl
        this.pcarduser.getTime = PCardUser.getTime
        this.$closeGiftCardGiveAway()
        if (giftCard) {
          giftCard.type = 2
        }
        this.$openGiftCardSavePoster({
          pcarduser
        })
      }
    }
  },
  mounted(){
    const { pcarduser } = this
    const { sendword, card } = pcarduser
    if(sendword){
      this.input = sendword
    }
    console.log(card)
    if (card && card.sendword) {
      this.placeholder = card.sendword
    }
  },
}
</script>

<style lang="scss" scoped>
.give_away_main{
  width: 100%;
  padding: 0 40px;
  .title_group{
    width: 100%;
    height: 26px;
    .title{
      font-size: 14px;
      color: #333;
    }
    .modify{
      color: $main_blue;
      font-size: 14px;
    }
    .done{
      border-radius: 4px;
      line-height: 26px;
      font-size: 12px;
      padding: 0 12px;
    }
  }
  .hint{
    white-space: pre-wrap;
    word-break: break-all;
    width: 100%;
    font-size: 14px;
    color: #AAAAAA;
    line-height: 26px;
  }
  .give_away{
    margin: 40px 0;
    width: 410px;
    line-height: 44px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  }
  .time_group{
    width: 100%;
    margin-top: 15px;
    .symbol{
      color: #ff3b30;
      margin-right: 3px;
    }
    .key{
      color: #333;
      font-size: 14px;
    }
    .time{
      flex: 1;
      position: relative;
      .text{
        position: relative;
        z-index: 3;
        height: 40px;
        width: 100%;
        background-color: #fff;
        line-height: 40px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
        &.empty{
          color: #aaa;
        }
      }
      .picker{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 40px;
      }
    }
  }
}
</style>

<style lang="scss">
.give_away_main{
  .give_away_main_input{
    .el-textarea__inner:focus{
      border-color: #E5E7EA!important;
    }
    .el-textarea__inner:hover{
      border-color: #E5E7EA!important;
    }
    &.show{
      .el-textarea__inner{
        @include placeholder(#333);
      }
    }
    .el-textarea__inner{
      @include placeholder(#ccc);
      border-color: #E5E7EA!important;
      font-size: 15px;
      font-family: MicrosoftYaHei,sans-serif;
      color: #555555;
      line-height: 28px;
      padding: 15px 15px 36px;
    }
    .el-input__count{
      color: #bbb;
      font-size: 12px;
    }
  }
}
</style>
