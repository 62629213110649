<template>
  <div
    class="sketch_content_wrapper"
    
  >
    <template
      v-if="status_relevance"
    >
      <sketch-title
        :msg="msg"
        :relevance="true"
        @handleAppoint="openAppointTask"
      ></sketch-title>
      <sketch-title-change
        :msg="msg"
        :relevance="true "
        @handleAppoint="openAppointTask"
      ></sketch-title-change>
      <sketch-content
        :msg="msg"
        :relevance="true"
        @handleAppoint="openAppointTask"
      ></sketch-content>
    </template>
    <template
      v-if="status_special"
    >
      <file-sketch
        v-if="msg.controlType === 6"
        :msg="msg"
      ></file-sketch>
      <hyperlink-sketch
        v-if="msg.controlType === 7"
        :msg="msg"
        @click.native="$api.stopPropagation($event)"
      >
      </hyperlink-sketch>
      <picture-sketch 
        v-if="msg.controlType === 9"
        :msg="msg"
      ></picture-sketch>
      <contact-sketch
        v-if="msg.controlType === 10"
        :msg="msg"
      ></contact-sketch>
    </template>
   
    <common-sketch
      v-if="status_common"
      :msg="msg"
      @handleAppoint="openAppointTask"
    ></common-sketch>
  </div>
</template>
<script>
import PictureSketch from './Picture.vue';
import FileSketch from './File.vue';
import HyperlinkSketch from './Hyperlink.vue';
import ContactSketch from './Contact.vue';
import CommonSketch from './Common.vue';
import SketchTitle from '@/components/dialog/Sketch/Title/Title.vue';
import SketchTitleChange from '@/components/dialog/Sketch/Title/Change.vue';
export default {
  name: 'sketch-content',
  props: {
    msg: {
      type: Object,
      default: null,
    },
    // 是否关联
    relevance: {
      type: Boolean,
      default: false,
    },

  },
  components: {
    PictureSketch,
    HyperlinkSketch,
    FileSketch,
    ContactSketch,
    CommonSketch,
    SketchTitle,
    SketchTitleChange,
  },
  computed:{
    status_relevance() {
      const { msg, relevance } = this
      const { conIdP, controlTypeP } = msg
      if (relevance) {
        // 已经是关联 则不进行下一层关联展示
        return false
      }
      if (conIdP && controlTypeP ) {
        return true
      }
      return false
    },
    status_special() {
      const { msg, status_relevance } = this;
      const { controlType,content} = msg;
      if(status_relevance){
        return false;
      }
      if (Number(controlType) === 10 && String(content) === '名片控件置空') {
        return false;
      }
      const special_arr = [6, 7, 9, 10];
      const index = special_arr.find(item=>{
        return controlType === item;
      })
      if(index) {
        return true;
      }
      
      return false;
    },
    status_common(){
      const {status_special, msg, status_relevance} = this;
      const { content } = msg;
      if(status_relevance){
        return false;
      }
      if(status_special){
        return false;
      }
      if(content){
        return true;
      }
      return false;
    },
   
  },
  methods:{
    openAppointTask(data) {
      this.$emit('handleAppoint', data);
    }
  }
}
</script>

<style lang="scss" scoped>
.sketch_content_wrapper {
  @include bbox;
  padding-left: 21px;
}
</style>
