<template>
  <div class="v_pc_gift_card_plugins_confirm_purchase_main bbox d-flex flex-column align-center">
    <card-component
      width="420px"
      height="244px"
      :show_price="false"
      :show_indate="false"
      :font_plus="2"
      :pcard="pcard"
      title_size="28px"
    ></card-component>
    <div class="number_group d-flex align-center justify-space-between">
      <div class="text">购买数量</div>
      <div class="input_group d-flex align-center">
        <div class="minus border cp"
          @click.stop="doMinus()"
        ></div>
        <input type="text" class="number border"
          maxlength="3"
          v-model="number"
        >
        <div class="plus border cp"
          @click.stop="doPlus"
        ></div>
      </div>
    </div>
    <div class="price_group d-flex align-center">
      <div class="text">总计：</div>
      <div class="price">¥ {{total_price}}</div>
    </div>
    <div class="button btn_dark"
      @click.stop="doPurchase()"
    >购买</div>
  </div>
</template>

<script>
import CardComponent from '@/views/PersonalCenter/GiftCard/Modules/Card.vue'
import global from '@/api/global'
import { POrder, PCardUser } from "@/types"
export default {
  props: {
    pcard: {
      type: Object,
      default: undefined,
    },
    gift_card: {
      type: Object,
      default: undefined,
    },

  },
  components: {
    CardComponent,
  },
  computed:{
    total_price(){
      const {number, pcard} = this
      if(!pcard){
        return ''
      }
      const {price} = pcard
      return ((number * price)/100).toFixed(2)
    }
  },
  watch: {
    //只能输入数字
    number: function(val){
      if(val){
        val = String(val)
        this.number = Number(val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.'))
      }
    },
  },
  data(){
    return {
      number: 1,
    }
  },
  methods:{
    doMinus(){
      const {number} = this
      if(number>1){
        this.number -= 1
      }
    },
    doPlus(){
      const {number} = this
      if(number<999){
        this.number += 1
      }
    },
    async doPurchase(){
      const {number:num, pcard, gift_card} = this
      const {price, id:cardId} = pcard
      const data = {
        cardId,
        payType: 1,
        price: price*num,
        num,
      }
      const res = await global.doPost({
        url: '/order/getUserBuyCardOrder',
        data,
      })
      if(res.message === 'success'){
        const {contents} = res
        const {orderTask} = contents
        const order = new POrder(orderTask)
        this.$scan_code({
          order,
          onSuccess: async () => {
            const res = await order.getGiftCard()
            if (res.message === 'success') {
              this.$openGiftCardPaymentSuccess({
                pcarduser: new PCardUser(res.contents.PCardUser),
                giftCard: gift_card
              })
              // this.$openGiftCardGiveAway({
              //   pcarduser: new PCardUser(res.contents.PCardUser)
              // })
            }
            this.$closeGiftCardConfirmPurchase()
            this.$close_scan_code()
            // this.$notice({desc: '购买成功', type: 'success'})

          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_plugins_confirm_purchase_main{
  width: 100%;
  padding: 20px 50px 40px;
  .number_group{
    width: 100%;
    font-size: 14px;
    padding-top:10px;
    color: #333;
    .input_group{
      .border{
        box-sizing: border-box;
        border: 1px solid #E5E7EA;
        background-color: #f8f8f8;
        border-radius: 4px;
        margin-left: 6px;
        height: 36px;
      }
      .minus, .plus{
        width: 40px;
        position: relative;
        user-select: none;
        &::after{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 14px;
          height: 2px;
          background-color: #999;
          z-index: 2;
        }
        &::before{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #999;
          z-index: 2;
        }
      }
      .plus{
        &::before{
          width: 2px;
          height: 14px;
        }
      }
      .number{
        width: 60px;
        text-align: center;
        outline: none;
        font-size: 16px;
        color: #333;
      }
    }
  }
  .price_group{
    padding-top: 70px;
    width: 100%;
    .text{
      font-size: 14px;
      color: #333;
      margin-right: 10px;
    }
    .price{
      font-size: 30px;
      font-weight: bold;
      color: #F3580C;
    }
  }
  .button{
    margin-top: 40px;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    line-height: 44px;
  }
}
</style>
