var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_personal_center_vip_and_consumption_vip_page bbox d-flex flex-column align-start",
    },
    [
      _c("div", { staticClass: "table_group" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(0),
          _c(
            "tbody",
            [
              [_vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4)],
              [_vm._m(5)],
              [_vm._m(6), _vm._m(7), _vm._m(8), _vm._m(9)],
              [_vm._m(10), _vm._m(11), _vm._m(12), _vm._m(13), _vm._m(14)],
              [
                _vm._m(15),
                _vm._m(16),
                _vm._m(17),
                _vm._m(18),
                _vm._m(19),
                _vm._m(20),
                _vm._m(21),
                _vm._m(22),
                _vm._m(23),
              ],
              [_vm._m(24)],
              [
                _vm._m(25),
                _vm._m(26),
                _vm._m(27),
                _vm._m(28),
                _vm._m(29),
                _vm._m(30),
                _vm._m(31),
                _vm._m(32),
                _vm._m(33),
                _vm._m(34),
                _vm._m(35),
              ],
              [_vm._m(36), _vm._m(37), _vm._m(38)],
              [
                _vm._m(39),
                _vm._m(40),
                _vm._m(41),
                _vm._m(42),
                _vm._m(43),
                _vm._m(44),
                _vm._m(45),
                _vm._m(46),
              ],
              0 ? [_vm._m(47)] : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm.expire_time && _vm.expire_group
        ? _c(
            "div",
            {
              staticClass:
                "expire_group flex-shrink-0 justify-end align-center d-flex bbox",
            },
            [
              _c("div", { staticClass: "expire" }, [
                _vm._v(_vm._s(_vm.expire_time) + "到期"),
              ]),
              _c(
                "div",
                {
                  staticClass: "renew un_sel cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.renew()
                    },
                  },
                },
                [_vm._v("续费")]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "line" }, [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("模块")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("功能")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("普通用户")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("VIP用户(付费)")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("讲师")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "4" } }, [_vm._v("登录注册")]),
      _c("td", [_vm._v("注册新用户")]),
      _c("td", [_vm._v("邀请制")]),
      _c("td", [_vm._v("邀请制")]),
      _c("td", [_vm._v("邀请制")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("编辑个人资料")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("修改密码")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("产品激活")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("th", { attrs: { scope: "row" } }, [_vm._v("搜索")]),
      _c("td", [_vm._v("搜索")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "4" } }, [_vm._v("联系人")]),
      _c("td", [_vm._v("添加联系人")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("接收联系人申请")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("备注联系人名称")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("查看申请记录")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "5" } }, [
        _vm._v("个人沟通窗口"),
      ]),
      _c("td", [_vm._v("主动创建个人沟通窗口")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("被动接受个人沟通窗口")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("拉入联系人升级为工作组")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("置顶窗口")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("从沟通列表中删除")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "9" } }, [
        _vm._v("工作组沟通窗口"),
      ]),
      _c("td", [_vm._v("创建工作组")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("被动加入工作组")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("邀请新成员")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("设置组内昵称")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("置顶窗口")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("查看公示板")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("修改工作组名称")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("从沟通列表中删除")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("查看非联系人成员个人资料")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("th", { attrs: { scope: "row" } }, [_vm._v("文字交流")]),
      _c("td", [_vm._v("文字交流")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "11" } }, [
        _vm._v("个人或工作组内任务单"),
      ]),
      _c("td", [_vm._v("创建、删除任务单")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("调用创建任务单")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("添加、删除控件")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("任务单中上传图片和附件")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("查看、接收、编辑任务单")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("分享任务单")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("查看任务单记录")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("任务单内交流讨论")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("颜色标记任务单")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("控件关联")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("隐私模式")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "3" } }, [_vm._v("教学")]),
      _c("td", [_vm._v("制作教学模板")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("销售教学模板")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("购买教学模板")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "row", rowspan: "8" } }, [_vm._v("模板管理")]),
      _c("td", [_vm._v("我创建的")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("我接收的")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("我购买的")]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("我销售的")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("创建新自定义分类")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("删除已有的自定义分类")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("在已有的自定义分类中加入任务单")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "line" }, [
      _c("td", [_vm._v("在已有的自定义分类中删除任务单")]),
      _c("td", [_c("div", { staticClass: "none" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
      _c("td", [_c("div", { staticClass: "own" })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "operation" }, [
      _c("td"),
      _c("td"),
      _c("td", [_vm._v("免费")]),
      _c("td", [
        _c("div", { staticClass: "button btn_dark" }, [
          _vm._v("成为vip用户（388元/年）"),
        ]),
      ]),
      _c("td"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }