var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c_pop_up_member_open_record_index bbox d-flex flex-column",
    },
    [
      _vm.list && _vm.list.length
        ? _c(
            "table",
            {
              staticClass: "table",
              attrs: { cellpadding: "0", cellspacing: "0" },
            },
            [
              _vm._m(0),
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("table-line", {
                    key: index,
                    attrs: { "p-vip-record": item },
                  }),
                ]
              }),
              _vm.more || _vm.loading
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "4" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "more d-flex align-center justify-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.loading,
                                  expression: "!loading",
                                },
                              ],
                              staticClass: "cp",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.loading.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("\n            加载更多\n          ")]
                          ),
                          _c("loading-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: { size: "24px" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("\n        开通方式\n      ")]),
      _c("th", [_vm._v("\n        天数\n      ")]),
      _c("th", [_vm._v("\n        VIP开通日\n      ")]),
      _c("th", [_vm._v("\n        VIP到期日\n      ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }