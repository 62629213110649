/*
 * @Author      : Hugo
 * @Date        : 2022-04-07 14: 15: 05
 * @LastEditTime : 2022-04-07 14:18:56
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PFolderTask/index.js
 * @Description : 
 * @^_^         : 
 */
class PFolderTask{
  id         = undefined
  folderId   = undefined
  taskId     = undefined
  userId     = undefined
  createTime = undefined

  constructor(params={}){
    if(params){
      const {
        id,
        folderId,
        taskId,
        userId,
        createTime,
      } = params

      this.id         = id
      this.folderId   = folderId
      this.taskId     = taskId
      this.userId     = userId
      this.createTime = createTime

    }
  }
}

export default PFolderTask