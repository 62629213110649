/*
 * @Author       : Hugo
 * @Date         : 2021-04-15 15:23:28
 * @LastEditTime : 2021-10-22 15:42:46
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/global.js
 * @Description  : 全局通用
 * @^_^          : 
 */
import vue from '@/main.js';

const post = async ({
  url,
  data,
  notice,
  openNotice = true,
  loading = false,
  loadingText= '操作中',
})=>{
  let res;
  if(loading){
    vue.$showLoading({text: loadingText})
  }
  try{
    res = await vue.$http.request(url, data);
  }catch(err){
    // console.log(err);
  }
  if(res.message != 'success'){
    if(openNotice){
      if(res.message != 'NOmodify'){
        vue.$notice({desc: res.describe || notice});
      }
    }
  }
  if(loading){
    vue.$hideLoading();
  }
  return res;
}
class Global{
   /**
   * 封装notice的post请求
   * @param {String} url 请求地址
   * @param {Object} data post请求数据
   * @param {String} notice 失败时的默认提示
   * @param {Boolean} openNotice 开启提示
   * @param {Boolean} loading 开启loading
   * @param {String} loadingText loading文本
   * @param {Boolean} preventDuplicateClicks 防止重复点击
   * @returns 
   */
  doPost = async ({
    url,
    data,
    notice,
    openNotice = true,
    loading = false,
    loadingText= '操作中',
    preventDuplicateClicks = false,
  })=>{
    const post_data = {
      url,
      data,
      notice,
      openNotice,
      loading,
      loadingText,
    }
    if(preventDuplicateClicks){
      const res =  await this.preventDuplicateClicks(async ()=>{return await post(post_data)})
      return res;
    }
    return await post(post_data);
  }
  /**
   * base64解密
   * @param {String} param base64参数
   * @returns 
   */
  decodeBase64 = (param) => {
    let atob_param;
    try{
      atob_param = vue.$tools.atob(param);
    }catch(err){
      // console.log(err);
      return false; // 解密失败
    }
    let reg_res;
    try{
      reg_res = vue.$regexp.positive_integer.test(atob_param); // 验证是否正整数
      if(reg_res){
        return atob_param;
      }
    }catch(err){
      // console.log(err);
    }
    return false; // 解密失败
  }
  /**
   * 防止重复点击
   * @param {Function} callback 
   */
  preventDuplicateClicks = async (callback) => {
    const {state, commit} = vue.$store;
    const {prevent_duplicate_clicks} = state;
    if(prevent_duplicate_clicks){
      // console.log('重复点击!');
      return;
    }
    commit('set_prevent_duplicate_clicks', true);
    let res ;
    try{
      res = await callback();
    }catch(err){
      // console.log(err);
    }
    commit('set_prevent_duplicate_clicks', false);
    return res || false;

  }
}

const global = new Global();

export default global;