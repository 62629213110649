import PreviewPictureComponent from '@/components/Plugin/PreviewPicture.vue'

const PreviewPicture = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PreviewPictureConstructor = Vue.extend(PreviewPictureComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PreviewPictureConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$previewPicture = (obj) => {
      let {url,list} = obj;
      instance.url = url;
      instance.list = list;
      instance.preview({url, list});
      // instance.visible = true;
      // duration = duration || 1500; // 默认1500关闭
      // setTimeout(()=>{
      //   instance.visible = false;
      // },duration)

    }
  }
}

export default PreviewPicture