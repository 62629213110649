var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_selected_task_list_filter_bar" },
    [
      _c("div", { staticClass: "search_group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          staticClass: "search bbox",
          attrs: { type: "text", placeholder: "搜索" },
          domProps: { value: _vm.search },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doSearch($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.search !== "",
              expression: "search !== ''",
            },
          ],
          staticClass: "clear cp",
          attrs: {
            src: require("@/assets/images/common/clear_icon.png"),
            alt: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doClearSearch()
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }