/*
 * @Author       : Hugo
 * @Date         : 2021-11-29 16:31:06
 * @LastEditTime : 2022-03-29 15:50:36
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/at/index.js
 * @Description  : 
 * @^_^          : 
 */

import vue from '@/main.js';
import {UserInfo} from '@/types';
class At{
  at = ' ';
  /**
   * 将用户名拼接上at字符
   * @param {}} name 
   * @returns {String}
   */
  atStr(name){
    return `${name}${this.at}`;
  }

  atAll(){
    return `所有人${this.at}`;
  }

  /**
   * 点击'@'
   * @param {*} params 
   * @returns 
   */
  clickAtUser(params={}){
    const {username} = params;
    let at_username = `@${username}${this.at}`
    if(username !== undefined){
      vue.$store.commit('set_dialog_click_at_name', at_username);
    }
    return at_username;
  }

  /**
   * 获取at用户列表
   * @param {*} params 
   * @returns 
   */
  getSendUsersList = async (params = {})=>{
    const at = ' ';
    const self_id = vue.$tools.getUserId();
    if(JSON.stringify(params) === '{}'){
      return false;
    }
    const {chat_id, chat_type, task_id, content} = params;
    // console.log(params);
    if(!content){
      return false;
    }
    if(chat_id === undefined){
      return false;
    }
    if(chat_type === undefined){
      return false;
    }
    const has_at = Boolean(content.indexOf('@') !== -1)
    if(!has_at){
      return false
    }
    // 判断是否@所有人
    const is_all = Boolean(content.indexOf(`@所有人${at}`) !== -1);
  

    if(!task_id){
      const dialog_users = await vue.$api.dialog.getDialogUsers({chat_id, chat_type});
      if(dialog_users){
        const {list} = dialog_users;
        let linkusers = '';
        if(list){
          if(is_all){
            list.forEach(unit=>{
              if(Number(unit.userId) !== Number(self_id)){
                linkusers += linkusers === '' ? unit.userId : `,${unit.userId}`;
              }
            })
            return linkusers;
          }
          list.forEach(unit=>{
            const user_info = new UserInfo(unit);
            const verify_str = `@${user_info.atName()}${at}`;
            if(content.indexOf(verify_str) !== -1){
              linkusers += linkusers === '' ? user_info.userId : `,${user_info.userId}`;
            }
          })
          return linkusers || false;

        }
      }
    }

    const task_users_list = await vue.$api.moduleTask.getTaskUsers({task_id});
    // console.log(task_users_list);
    if(task_users_list){
      const {list} = task_users_list;
      let linkusers = '';
      if(list){
        if(is_all){
          list.forEach(unit=>{
            if(Number(unit.userId) !== Number(self_id)){
              linkusers += linkusers === '' ? unit.userId : `,${unit.userId}`;
            }
          })
          return linkusers;
        }
        list.forEach(unit=>{
          const user_info = new UserInfo(unit);
          const verify_str = `@${user_info.atName()}${at}`;
          if(content.indexOf(verify_str) !== -1){
            linkusers += linkusers === '' ? user_info.userId : `,${user_info.userId}`;
          }
        })
        // console.log(linkusers);
        return linkusers || false;

      }
    }

    return false;
  }
  
}

const at = new At();

export default at;