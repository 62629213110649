import vue from '@/main'
import store from '@/store'
import moduleTask from '@/api/modules/task'
import tools from '@/api/tools'

class PublicNotice {
  // 公示板推送
  updatePublicNotice = (params = {}) => {
    const { ext } = params
    const { pTaskLog } = ext
    const { message_list } = store.state
    message_list.push(JSON.parse(pTaskLog))
  }

  // 公示板按钮更新
  updateNoticeBtn = (params = {}) => {
    const { ext } = params
    const { chatType, chatId } = ext
    // let route_id = vue.$route.params.id;
    const route_id = moduleTask.getRouteParamsId()
    const route_type = tools.getChatType()
    if (Number(chatType) === Number(route_type) && Number(chatId) === Number(route_id)) {
      let message_list_data = store.state.massage_list_data
      if(!message_list_data){
        message_list_data = {}
      }
      if (message_list_data.chat) {
        message_list_data.chat.readflag = 1
      }else{
        message_list_data.chat = { readflag: 1 }
      }
      store.commit('set_message_list_data', message_list_data)
    }
  }
}

const publicNotice = new PublicNotice()

export default publicNotice
