var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_main_linebar_task_tag_page bbox d-flex align-center",
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "input_group flex-shrink-0" }, [
        _c("input", {
          ref: "input",
          staticClass: "input bbox",
          attrs: { type: "text" },
        }),
        _c("div", { staticClass: "placeholder bbox" }, [_vm._v("input_tag")]),
      ]),
      _c(
        "overlay-scrollbars",
        {
          ref: "overlay",
          staticClass: "overlay_container bbox d-flex align-center",
        },
        [
          _c(
            "div",
            { staticClass: "list d-flex align-center" },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("div", { key: index, staticClass: "tag flex-shrink-0" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.tagName)),
                    ]),
                    _c("div", { staticClass: "close cp" }),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      0
        ? _c(
            "div",
            { staticClass: "tag_list_group bbox", class: { active: 1 } },
            [
              _c("div", { staticClass: "tag_list bbox" }, [
                _c("div", { staticClass: "title" }, [_vm._v("选择已有标签")]),
                _c(
                  "div",
                  {
                    staticClass: "tag_group bbox d-flex align-center flex-wrap",
                  },
                  [
                    _c("div", { staticClass: "tag cp", class: { on: 1 } }, [
                      _vm._v("val.tagName"),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "add_group cp flex-shrink-0 d-flex align-center" },
      [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "text" }, [_vm._v("添加自定义标签")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }