<template>
  <div class="template">
    <div class="tel">
      <ul>
        <li>
          <p>手 机 号 码:</p>
          <div>
            <input type="text"
              placeholder="请输入手机号码"
              v-model="userInfo.loginName"
              maxlength="11"
              name="loginPhone"
            />
            <img src="../../../assets/icon-shut.png" @click="empty" alt v-show="userInfo.loginName" />
          </div>
        </li>
        <li>
          <p>图形验证码:</p>
          <div class="input_group">
            <input type="text" placeholder="请输入图形验证码" v-model="userInfo.tuyzm" name="verifyCode"/>
            <img :src="Code" alt @click="imageCode" class="imageCode" />
          </div>
        </li>
        <li>
          <p>短信验证码:</p>
          <div class="input_group">
            <input type="text" placeholder="请输入短信验证码" v-model="smsCode" name="smscode" />
            <button class="get_verify_code" @click="clickGetCode" v-html="getCode" :class="codeshow?'noteCode':'noCode'"></button>
          </div>
        </li>
      </ul>
      <span v-html="telWarn"></span>
      <div class="btn">
        <button @click="nextstep" class="lower cp">下一步</button>
        <button @click="cancelRegister" class="cancel cp">取消注册</button>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from "axios";
export default {
  data() {
    return {
      stepreg: 1,
      Code: "",
      smsCode: "",
      telWarn: "",
      getCode: "短信验证码",
      codeshow: true,

      userInfo: {
        loginName: "",
        type: "1",
        flag: "1",
        tuyzm: ""
      },
      verifyStamp: null, // 验证码时间戳
    };
  },
  watch:{
    //只能输入数字
    // eslint-disable-next-line no-dupe-keys
    'userInfo.loginName': function(val){
      if(this.userInfo.loginName){
        this.userInfo.loginName = val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.');
      }
    },
  },
  methods: {
    cancelRegister() {
      this.$router.back();
    },
    nextstep() {
      const userReg = /^1[3456789]\d{9}$/;
      if (userReg.test(this.userInfo.loginName) && this.smsCode && this.userInfo.tuyzm) {
        let params = {
          loginName: this.userInfo.loginName,
          authCode: this.smsCode
        };
        // console.log(params);
        this.$http.getConfirmAuthCode(params).then(res => {
          // console.log(res, "验证验证码");
          if(res.message == 'success'){
            this.$emit("myevent", 1);
            this.$emit("user", this.userInfo.loginName);
            this.telWarn = "";
          }else{
            this.telWarn = res.describe;
          }
        });
      } else {
        this.telWarn = "请检查无误后再提交";
      }
    },
    empty() {
      this.userInfo.loginName = "";
    },
    async clickGetCode() {
      const userReg = /^1[3456789]\d{9}$/;
      if (userReg.test(this.userInfo.loginName)) {
        this.telWarn = "";
        if (this.codeshow && this.userInfo.tuyzm) {
          /** 校验图形验证码 */
          let verifyRes = await this.$http.request('/verify/executeVerify', {t:this.verifyStamp, tucode: this.userInfo.tuyzm});
          if(verifyRes.message != 'success'){
            // this.$toast({icon:'none',title:'提示',desc:verifyRes.describe,duration:2000});
            this.telWarn = '请输入正确的图形验证码';
            return;
          }

          /** END 校验图形验证码 */
          let num = 60,
            _this = this;
          let params = this.userInfo;
          // console.log(params, "params");
          const res = await this.$http.getSendAuthcode(params);
          // console.log(res, "res123123123");
          // if (res.status === "success") {
          //   let item = setInterval(function() {
          //     num--;
          //     _this.getCode = `已发送(${num})s`;

          //     if (num <= 0) {
          //       _this.codeshow = true;
          //       _this.getCode = "短信验证码";
          //       clearInterval(item);
          //     } else {
          //       _this.codeshow = false;
          //     }
          //     // // console.log(num);
          //   }, 1000);
          // } else if (res.status === "noexist") {
          //   this.telWarn = "用户不存在";
          // } else if (res.status === "exist") {
          //   this.telWarn = "已经注册,无法重复注册";
          // } else if (res.status === "sendFail") {
          //   this.telWarn = "发送验证码失败";
          // } else if (res.status === "loginNameError") {
          //   this.telWarn = "手机号为空";
          // }
          if(res.message == 'success'){
           let item = setInterval(function() {
            num--;
            _this.getCode = `已发送(${num})s`;

            if (num <= 0) {
              _this.codeshow = true;
              _this.getCode = "短信验证码";
              clearInterval(item);
            } else {
              _this.codeshow = false;
            }
            // // console.log(num);
          }, 1000);
          }else{
            this.telWarn = res.describe;
          }
        }else{
          this.telWarn = "请输入图形验证码";
        }
      } else {
        this.telWarn = "*请输入正确的手机号码";
      }
    },
    imageCode() {
      let verfiyStamp = String(new Date().getTime()) + Math.ceil(Math.random()*1000);
      this.verifyStamp = verfiyStamp;
      this.Code =
        `https://${this.$domain_name}/verify/execute?t=${verfiyStamp}`;
        // // console.log(verfiyStamp,'new Date().getTime()')
    }
  },
  mounted() {
    this.$store.commit('set_login_user_id', '');
    this.imageCode();
  }
};
</script>
<style lang="less" scoped>
li{
  list-style: none;
}
.template {
  width: 1200px;
  height: 550px;
  .tel {
    width: 415px;
    height: 500px;
    margin: 0 auto;
    padding-top: 50px;
    ul {
      float: left;
      display: block;
      width: 100%;
      // background: #000;
      flex-wrap: wrap;
      li {
        width: 100%;
        height: 97px;
        p {
          float: left;
          height: 97px;
          font-size: 18px;
          line-height: 97px;
          color: #0054a6;
          margin-right: 31px;
        }
        .input_group{
          position: relative;
          .imageCode, .get_verify_code{
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -20px;
          }
        }
        div {
          float: right;
          margin: 25px 0;
          width: 280px;
          height: 47px;
          border-bottom: 1px solid #d5d9db;
          input {
            float: left;
            border: 0;
            outline: none;
            // height: 17px;
            margin-top: 15px;
            font-size: 18px;
          }
          img {
            float: right;
            width: 18px;
            height: 12px;
            margin: 17px 0;
          }
          .imageCode {
            float: right;
            width: 100px;
            height: 40px;
            border-radius: 4px;
            margin: 0;
            cursor: pointer;
          }
          .noteCode {
            float: right;
            width: 100px;
            height: 40px;
            border: 2px solid #0054a6;
            border-radius: 4px;
            cursor: pointer;
          }
          .noCode {
            float: right;
            width: 100px;
            height: 40px;
            border: 2px solid #3e3e3e;
            border-radius: 4px;
            cursor: not-allowed;
          }
        }
      }
    }
    span {
      float: left;
      width: 100%;
      height: 15px;
      margin: 10px 0 43px;
      font-size: 16px;
      line-height: 15px;
      text-align: left;
      color: #d82e0d;
    }
    .btn {
      float: left;
      width: 100%;
      height: 50px;
      button:nth-of-type(1) {
        float: left;
        width: 200px;
        height: 50px;
        background: #0054a6;
        border: 0;
        border-radius: 5px;
        margin-right: 15px;
      }
    .lower{
        background: rgba(0, 84, 166, 1);
        border-radius: 4px;
        border: none;
        font-size: 18px;
        color:rgba(255, 255, 255, 1) ;
    }
    .lower:hover{
        background: #004080;
    }
      button:nth-of-type(2) {
        float: left;
        width: 200px;
        height: 50px;
        background: #e6eef6;
        border: 0;
        border-radius: 5px;
      }
    .cancel{
        font-size: 18px;
        color:rgba(0, 84, 166, 1) ;
    }
    .cancel:hover{
        background: #ccdded;
    }
    }
  }
}
</style>
