// import request from '@/api/request'
const task = {
  state: {
    info: undefined, // 当前编辑的任务信息
  },
  mutations: {
    setTaskInfo (state, data) {
      state.info = data
    },
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
  }
}

export default task
