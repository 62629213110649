var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            {
              attrs: {
                height: "auto",
                max_height: "96%",
                width: "500px",
                overflow: "auto",
                "wrapper-close": true,
              },
              on: { handleClose: _vm.handleClose },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "v_pc_gift_card_plugins_save_poster bbox d-flex flex-column",
                },
                [
                  0
                    ? _c("img", {
                        staticClass: "close_icon",
                        attrs: {
                          src: require("@/assets/images/personal_center/save_poster/close.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleClose()
                          },
                        },
                      })
                    : _vm._e(),
                  _c("plugin-component", {
                    staticClass: "plugin_component",
                    attrs: { data: _vm.data, pcarduser: _vm.pcarduser },
                    on: {
                      handleClose: _vm.handleClose,
                      handleSure: _vm.handleSure,
                      handleReturn: _vm.handleReturn,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }