import vue from '@/main';

class Input{
  /**
   * 监听@ 
   * @param {*} e 
   * @returns 
   */
  atListener(e){
    if(e){
      const {keyCode, key} = e;
      if(keyCode === 50 && key === '@'){
        return true;
      }
    }
    return false;
  }

  /**
   * 监听删除 
   * @param {*} e 
   * @returns 
   */
  deleteListener(e){
    if(e){
      const {keyCode, key} = e;
      if(keyCode === 8 && key === 'Backspace'){
        return true;
      }
    }
    return false;
  }
  /**
   * 检测中文回车
   * @param {*} e 
   * @returns 
   */
  handleKeydownChinese(e){
    let status = false;
    if(e.keyCode == 229){
      status = true;
    }
    return status;
  }

}

const input = new Input();
// const at = ' ';

// // console.log(at.charAt());
// // console.log(at.charCodeAt(0));
// // console.log(at.charCodeAt(1));
export default input;