import vue from '@/main.js';
import fun from './index';
class EditPublicNotice{
  main = async ()=>{
    const res = await fun.editTask();
    return res;
  }
}

const editPublicNotice = new EditPublicNotice();

export default editPublicNotice.main;