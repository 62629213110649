/*
 * @Author       : Hugo
 * @Date         : 2022-03-14 10:45:46
 * @LastEditTime: 2022-05-30 21:09:54
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/api/modules/ctask/modules/open.js
 * @Description  : 
 * @^_^          : 
 */
import {PTask, PTaskDetail, UserInfo, PTaskReadUser, PTag, listForClasses} from '@/types'
import global from '@/api/global'
import types from '@/utils/types'
import store from '@/store';
class Open{
  /**
   * 创建
   */
  createNewTask = () => {
    const ctask = {}
    ctask.task = new PTask()
    ctask.taskDeatails = []
    ctask.taskUsers = []
    ctask.readtaskUsers = []
  }

  /**
   * 加载数据
   * @param {*} params 
   * @returns 
   */
  loadTask = async (params={}) => {
    const {id} = params
    if(id === undefined){
      return false
    }

    const res = await global.doPost({
      url: '/projectTask/taskDetail',
      data: {
        taskId: id,
        linkflag: 1,
        seeType: 0, // 0: 正常查看, 1: 分享查看, 2: 回收站
      }
    })
    const {message, contents} = res;
    if(message === 'success'){
      const ctask      = this.classesTaskData(contents)
      const ctask_copy = this.classesTaskData(contents)
      // const {commit}   = store;
      // // 数据
      // commit('set_ctask', ctask.task);
      // commit('set_ctask_details', ctask.taskDeatails);
      // commit('set_ctask_users', ctask.taskUsers);
      // commit('set_ctask_users', ctask.readtaskUsers);
      // // 备份
      // commit('set_ctask_copy', ctask_copy.task);
      // commit('set_ctask_copy_details', ctask_copy.taskDeatails);
      // commit('set_ctask_copy_users', ctask_copy.taskUsers);
      // commit('set_ctask_copy_users', ctask_copy.readtaskUsers);

      return {
        data: {
          task:ctask.task,
          taskDetails: ctask.taskDeatails,
          taskUsers: ctask.taskUsers,
          readtaskUsers: ctask.readtaskUsers,
        },
        backup: {
          task:ctask_copy.task,
          taskDetails: ctask_copy.taskDeatails,
          taskUsers: ctask_copy.taskUsers,
          readtaskUsers: ctask_copy.readtaskUsers,
        }
      }

      // return true;
    }
    
    return false;
    


  }
  /**
   * 将任务数据转化为对象
   * @param {readtaskUsers, taskUsers, taskDeatails, task} params 
   * @returns {readtaskUsers, taskUsers, taskDeatails, task}
   */
  classesTaskData = (params) => {
    const {readtaskUsers, taskUsers, taskDeatails, task} = params;
    const ctask                                          = {};
    if(task){
      ctask.task = new PTask(task);
    }
    if(taskDeatails && taskDeatails.length){
      ctask.taskDeatails = listForClasses({
        list: taskDeatails,
        classes : PTaskDetail,
      })
    }
    if(taskUsers && taskUsers.length){
      ctask.taskUsers = listForClasses({
        list: taskUsers,
        classes : UserInfo,
      })
    }
    if(readtaskUsers && readtaskUsers.length){
      ctask.readtaskUsers = listForClasses({
        list: readtaskUsers,
        classes : PTaskReadUser,
      })
    }
    return ctask;
  }
}

const open = new Open();

export default open;