import vue from '@/main';
class PChatDetail{
  constructor(params){
    const {
      chat,
      chatId,
      chatType,
      chatUserName,
      isdisturb,
      istop,
    } = params || {};

    this.chat = chat;
    this.chatId = chatId;
    this.chatType = chatType;
    this.chatUserName = chatUserName;
    this.isdisturb = isdisturb;
    this.istop = istop;
  }


  changeDoNotDistrub = async ()=>{
    // const { chatId, chatType, isdisturb} = this;
    const res = await vue.$api.dialog.changeDoNotDistrub(this);
    if(res.message === 'success'){
      return;
    }else{
      this.isdisturb = this.isdisturb === 1 ? 0 : 1;
    }
  }
}

export default PChatDetail;