/*
 * @Author       : Hugo
 * @Date         : 2020-11-01 15:06:13
 * @LastEditTime : 2022-06-10 15:32:28
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/utils/plugin/notice.js
 * @Description  : 
 * @^_^          : 
 */
import NoticeComponent from '@/components/Plugin/Notice.vue'

const Notice = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const NoticeConstructor = Vue.extend(NoticeComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new NoticeConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    // Vue.prototype.$toast = (obj, durationDef = 1500) => {
    //   let {title,desc,duration,icon} = obj;
    //   instance.title = title;
    //   instance.desc = desc;
    //   instance.icon = icon;
    //   let timeout = duration || durationDef
    //   instance.visible = true
    //   setTimeout(() => {
    //     instance.visible = false
    //   }, timeout)
    // }
    Vue.prototype.$notice = (obj) => {
      let {desc,type, duration, mask, mobile, hide_icon} = obj;
      instance.desc = desc;
      instance.type = type;
      instance.mask = mask;
      instance.mobile = mobile;
      instance.hide_icon = hide_icon
      instance.visible = true;
      duration = duration || 1500; // 默认1500关闭
      setTimeout(()=>{
        instance.visible = false;
      },duration)

    }
  }
}

export default Notice