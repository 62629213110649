/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-03 19:44:58
 * @LastEditors  : hugo
 * @LastEditTime : 2022-07-18 13:51:35
 * @FilePath     : /miaohang/src/types/modules/PCard/index.js
 * @Description  : 
 */
class PCard{
  id         = undefined  //	int		主键
  cardname   = undefined  //  String		卡名称
  usetype    = undefined  // int		使用种类 1通用卡 2新用户
  cardpic    = undefined  //	String		卡背景图片
  createTime = undefined  //	String		创建时间
  useflag    = undefined  //	int		0未使用 1使用中 
  vipdays    = undefined  //	int		会员天数
  termdays   = undefined  //	int		有效期天数
  cardremark = undefined  //	String		卡说明
  price      = undefined  // 价格
  sendword      = undefined  // 默认寄语


  constructor(params = {}){
    const {
      id, 
      cardname, 
      usetype, 
      cardpic, 
      createTime, 
      useflag, 
      vipdays, 
      termdays, 
      cardremark, 
      price,
      sendword
    } = params ?? {}

    this.id         = id
    this.cardname   = cardname
    this.usetype    = usetype
    this.cardpic    = cardpic
    this.createTime = createTime
    this.useflag    = useflag
    this.vipdays    = vipdays
    this.termdays   = termdays
    this.cardremark = cardremark
    this.price      = price
    this.sendword      = sendword
    
  }

}

export default PCard