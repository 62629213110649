/*
 * @Author       : Hugo
 * @Date         : 2021-04-25 15:32:56
 * @LastEditTime : 2021-10-11 15:04:16
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/custom.js
 * @Description  : 自定义分类
 * @^_^          : 
 */
import vue from '@/main.js';

const CUSTOM = {
  /**
   * 获取文件夹数据
   * @param {*} params 
   * @returns 
   */
  async getFolders(params){
    // vue.$store.commit('set_custom_folders', []);
    const res = await vue.$global.doPost({
      url: '/projectCustom/queryFolder',
      data: params,
      notice: '获取文件夹数据失败',
    })
    if(res.message == 'success'){
      // let custom_folders = [];
      // if(res.contents && res.contents.pFolderList){
      //   custom_folders = res.contents.pFolderList;
      // }
      // vue.$store.commit('set_custom_folders', custom_folders);
    }
    return res;
  },
  async addToFolders(data){
    const {custom_folders} = vue.$store.state;
    const res = await vue.$global.doPost({
      url: '/projectCustom/queryFolder',
      data,
      notice: '获取文件夹数据失败',
    })
    if(res.message == 'success'){
      // let new_custom_folders = [];
      // if(res.contents && res.contents.pFolderList){
      //   new_custom_folders = custom_folders.concat(res.contents.pFolderList);
      //   vue.$store.commit('set_custom_folders', new_custom_folders);
      // }
      // vue.$store.commit('set_custom_folders', new_custom_folders);
    }
    return res;
  },
  /**
   * 获取文件夹内模板
   * @param {*} params 
   * @returns 
   */
  async getFiles(params){
    vue.$store.commit('set_custom_files', []);
    const res = await vue.$global.doPost({
      url: '/projectCustom/queryFolderTask',
      data: params,
      notice: '查询任务失败',
    })
    let custom_files = [];
    if(res.contents && res.contents.pFolderTasksList){
      custom_files = res.contents.pFolderTasksList;
    }
    // vue.$store.commit('set_custom_files', custom_files.reverse());
    vue.$store.commit('set_custom_files', custom_files);
    return res;
  },
  // 追加
  async addToFiles(params){
    const {custom_files} = vue.$store.state;
    const res = await vue.$global.doPost({
      url: '/projectCustom/queryFolderTask',
      data: params,
      notice: '查询任务失败',
    })
    if(res.contents && res.contents.pFolderTasksList){
      // let new_custom_files = custom_files.concat(res.contents.pFolderTasksList.reverse());
      let new_custom_files = custom_files.concat(res.contents.pFolderTasksList);
      vue.$store.commit('set_custom_files', new_custom_files);
    }
    return res;

  },
  /**
   * 跳转到文件夹页面
   * @param {*} folderid 
   * @returns 
   */
  routerToFolder(folderid){
    if(!folderid){
      vue.$noitce({desc: '文件夹id错误'});
      return;
    }
    folderid = vue.$tools.btoa(folderid);
    vue.$router.push({name:'fun_template_my_custom', params: {folderid}})
    return;
  },
  /**
   * 触发创建文件夹
   * @returns 
   */
  triggerCreateNewFolder(){
    vue.$openPrompt({
      title:'新建分组',
      placeholder: '输入分组名称',
      hint: '请输入分组名称',
      sure: '确定',
      cancel: '取消',
      onSure: async ()=>{
        await vue.$api.custom.createNewFolder();
      },
      onCancel: ()=>{},
    })
    return ;
  },
  /**
   * 创建新文件夹
   */
  async createNewFolder(){
    const {utils_prompt} = vue.$store.state;
    const {folderid} = vue.$route.params;
    const fId = vue.$global.decodeBase64(folderid);
    let data = {
      fId: fId || 0,
      name: utils_prompt,
    }
    const res = await vue.$global.doPost({
      url: '/projectCustom/createFolder',
      data,
      notice: '创建文件夹失败',
    })
    // 追加
    if(res.message == 'success'){
      if(res.contents && res.contents.PFolder){
        unshiftToFolders(res.contents.PFolder);
      }
    }
    return;
  },
  /**
   * 触发添加任务
   * @returns 
   */
  triggerAddTask(){
    vue.$store.commit('set_switch_select_tasks', true);
    return;
  },
  /**
   * 添加任务后更新自定义文件
   * @returns 
   */
  updateCustomFilesAfterAdd(){
    const {state, commit } = vue.$store;
    const {custom_to_add_tasks, custom_to_delete_tasks, custom_files} = state;
    if(custom_to_add_tasks && custom_to_add_tasks.length){
      custom_to_add_tasks.forEach(item=>{
        custom_files.unshift(item);
      })
    }
    if(custom_to_delete_tasks && custom_to_delete_tasks.length){
      custom_to_delete_tasks.forEach( item => {
        let index = custom_files.findIndex( unit => {
          return unit.id == item.id;
        })
        if(index != -1){
          custom_files.splice(index, 1);
        }
      })
    }
    return;
  },
  /**
   * 打开筛选选择列表
   * @param {String | Number} folderid 
   * @returns 
   */
  openFilterSelectList(item){
    // // console.log('doSelectFilter');
    const {commit} = vue.$store;
    commit('set_switch_filter_folder_select', true);
    commit('set_filter_folder_origin_folderid', item.fid);
    commit('set_filter_folder_selected_breakpoint_id', item.id)
    return;
  },
  /**
   * 获取断点数据[子元素在最外层]
   * @param {*} id 
   * @returns 
   */
  getBreakpoint(id){
    if(id == 0){
      vue.$store.commit('set_filter_folder_selected_breakpoint', null);
    }
    const {filter_folder_select_list_record} = vue.$store.state;
    let breakpoint = null;
    let next = (arr) => {
      const is_breakpoint = arr.id == id;
      if(breakpoint == null){
        breakpoint = {fid: arr.fid, id: arr.id, name: arr.name, sonfolderList: arr.sonfolderList};
      }else{
        breakpoint = {fid: arr.fid, id: arr.id, name: arr.name, sonfolderList: arr.sonfolderList, father_item: breakpoint};
      }
      // // console.log(breakpoint);
      // // console.log(')))))))')
      if(!is_breakpoint){
        next(arr.child_item);
      }
      
    }
    next(filter_folder_select_list_record);
    // // console.log(breakpoint);

    vue.$store.commit('set_filter_folder_selected_breakpoint', breakpoint);
    return;
  },
  
  /**
   * 获取上级筛选记录数据
   * @param {*} item 
   * @returns 
   */
  getFilterFolderRecordHigher(item){
    const {filter_folder_select_list_record} = vue.$store.state; 
    // console.log(filter_folder_select_list_record);
    const {id} = item;
    let higher = null;
    let next = (unit) => {
      if(unit.id != id){
        if(higher == null){
          higher = vue.$tools.deepClone(unit);
          delete higher.child_item;
        }else{
          higher = {id: unit.id, fid: unit.fid, name: unit.name, father_item: higher};
        }
        next(unit.child_item);
      }
    }
    next(filter_folder_select_list_record);
    // console.log(higher);
    let reverse = null;
    let reverseNext = (unit)=>{
      if(reverse == null){
        reverse = vue.$tools.deepClone(unit);
        delete reverse.father_item;
      }else{
        reverse = {id: unit.id, fid: unit.fid, name: unit.name, child_item: reverse};
      }
      if(unit.father_item){
        reverseNext(unit.father_item);
      }
    }
    reverseNext(higher);
    // console.log(reverse)
    return reverse;
  },
  /**
   * 清除筛选数据
   * @returns 
   */
  clearFilterSelectData(){
    const {commit } = vue.$store;
    commit('set_filter_folder_origin_folderid', 0);
    commit('set_filter_folder_selected_breakpoint_id', -1);
    commit('set_filter_folder_select_origin_list', null);
    commit('set_filter_folder_selected_breakpoint', null);
    commit('set_filter_folder_select_list_record',  null);
    return;
  },
  /**
   * 获取筛选记录 最终数据
   * @returns 
   */
  getFinalFilterRecord(){
    const {state } = vue.$store;
    const {filter_folder_select_list_record} = state;
    let next = (item)=>{
      if(item.child_item){
        return next(item.child_item);
      }else{
        return item;
      }
    }
    const final = next(filter_folder_select_list_record);
    // // console.log(final);
    return final;
  },
  /**
   * 获取移动文件/夹 id
   * @returns 
   */
  getMovingPathId(){
    const {state, commit} = vue.$store;
    const {custom_folders, custom_files} = state;
    let foldersid = '';
    let filesid = '';
    if(custom_folders && custom_folders.length){
      custom_folders.forEach((item)=>{
        if(item.multiple_folder){
          foldersid += foldersid ? `,${item.id}` : item.id
        }
      })
    }
    if(custom_files && custom_files.length){
      custom_files.forEach((item)=>{
        if(item.multiple_file){
          filesid += filesid ? `,${item.id}` : item.id
        }
      })
    }
    return {foldersid, filesid};
  },
  /**
   * 判断是否在等待移动的文件件中
   * @param {*} id 
   * @returns 
   */
  isInMovingFolder(id){
    const {switch_custom_multiple_folder} = vue.$store.state;
    // 不是选择文件夹则不判断
    if(!switch_custom_multiple_folder){
      return false;
    }
    const {foldersid} = vue.$api.custom.getMovingPathId();
    const folder_arr = foldersid.split(',');
    const index = folder_arr.findIndex(item=>{
      return item == id;
    })
    if(index == -1){
      return false;
    }
    return true;
  },
  /**
   * 清除移动路径相关设置
   * @returns 
   */
  clearMovingPath(){
    const {commit} = vue.$store;
    commit('set_switch_custom_multiple_folder', false);
    commit('set_switch_moving_path', false);
    return;
  },
  /**
   * 批量移动
   * @param {*} item 
   */
  movingByMultiple(item){
    const {select_id} = vue;
    const {id, sonfolderList} = item;
    // // console.log(vue.$store.state.custom_folders, select_id, id);
    const is_selected = (id == select_id);
    vue.select_id = item.id;
    if(sonfolderList && sonfolderList.length){
      // 有子文件夹
      vue.generateSelectData(item);
      if(is_selected){
        // 已被选中
        vue.doSure(item);
        vue.doClose();    
      }else{
        vue.getNextList(item);
      }
    }else{
      vue.next_id = item.id;
      vue.generateSelectData(item);
      vue.$set(vue, 'next_data', [])
      // 没有子文件夹
      vue.doSure(item);
      vue.doClose();   
    }
    return;
  },
  /**
   * @description: 清除多选参数
   * @param {*}
   * @return {*}
   */
  clearMultipleSelect(){
    const {commit} = vue.$store;
    commit('set_switch_custom_multiple_folder', false);
    commit('set_switch_custom_multiple_file', false);
    // commit('set_switch_moving_path', false);
    return;
  }
}

/**
 * 将文件夹追加到文件夹数据最前面
 * @param {*} params 
 * @returns 
 */
function unshiftToFolders(params){
  let {custom_folders} = vue.$store.state;
  if(params){
    custom_folders.unshift(params);
  }
  // vue.$store.commit('set_custom_folders', custom_folders);
  return;
}
export default CUSTOM;
