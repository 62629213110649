/*
 * @Author       : Hugo
 * @Date         : 2021-04-13 13:58:53
 * @LastEditTime : 2021-08-17 15:17:47
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/share.js
 * @Description  :
 * @^_^          :
 */
import vue from '@/main.js';
const SHARE = {
  /**
   * 开启分享
   * @param {*} param0 {data: 任务/教学包[数据],type: 1 任务/ 2 教学包}
   */
  doStartShare ({ data, type }) {

    let {commit } = vue.$store
    // // console.log(share_data)
    commit('set_switch_multiple_contacts', true)
    commit('set_share_data', data)
    if (type==2) {
      commit('set_multiple_contacts_callback', vue.$api.share.package)
    }
    if (type == 1) {
      commit('set_multiple_contacts_callback', vue.$api.share.task)
    }
    if (type === 5) {
      commit('set_multiple_contacts_callback', vue.$api.share.course)
    }
  },
  /**
   * 分享教学包
   * @returns Boolean
   */
  package: async () => {
    const {share_data, share_work} = vue.$store.state;
    const {id} = share_data;

    let res ;
    const {userIds, chatIds} = getShareIds();
    let data = {
      id,
      shareType:2,
      userIds,
      chatIds,
      work: share_work
    }
    try{
      res = await shareTask(data);
      if(res){
        let messageContent = `分享 ${vue.$store.state.share_data.title}`;
        updateMessageBoxAfterShare(messageContent);
        // 判断是否追加消息列表
        addToMessageListAfterShare({shareType: 2, taskId: id, work: share_work});
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return false;

  },
  /**
   * 分享任务
   * @returns Boolean
   */
  task: async ()=>{
    const { share_data, share_work } = vue.$store.state
    // console.log(share_data, vue.$store.state.task, vue.$store.state.task_original)

    const { id } = share_data
    let res
    const { userIds, chatIds } = getShareIds()
    let data = {
      id: id ?? vue.$store.state.task_original.task.id,
      shareType: 1,
      userIds,
      chatIds,
      work: share_work
    }
    try {
      res = await shareTask(data)
      if (res) {
        let messageContent = `分享 ${vue.$store.state.share_data.name}`
        updateMessageBoxAfterShare(messageContent)
        // 判断是否追加消息列表
        addToMessageListAfterShare({shareType: 1, taskId: id, work: share_work})
        return true
      }
    } catch (err) {
      // console.log(err)
    }
    return false
  },
  course: async () => {
    const { share_data, share_work } = vue.$store.state
    const { id } = share_data
    let res
    const { userIds, chatIds } = getShareIds()
    let data = {
      id: share_data.id,
      shareType: 5,
      userIds,
      chatIds,
      work: share_work
    }
    try{
      res = await shareTask(data);
      if(res){
        let messageContent = `分享 ${share_data.title}`;
        updateMessageBoxAfterShare(messageContent);
        // 判断是否追加消息列表
        addToMessageListAfterShare({shareType: 5, taskId: id, work: share_work});
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return false;
  }
}
/**
 * 分享后追加消息列表
 * @param {*} params
 * @returns
 */
function addToMessageListAfterShare (params) {
  const { share_data } = vue.$store.state
  const taskName = share_data.title || share_data.name
  const get_id = vue.$api.moduleTask.getRouteParamsId()
  const get_type = vue.$tools.getChatType()
  const {shareType, taskId, work} = params || {}
  const user = vue.$tools.getCookie('user')
  if (chatInMultipleContactsList()) {
    vue.$api.task.addToMessageList({
      chat_id: get_id,
      type: get_type,
      createTime: new Date().getTime(),
      details: [],
      fromUserId: vue.$tools.getUserId(),
      logType: 1,
      shareType,
      taskId,
      taskName,
      thumbnail: user.thumbnail,
      userName: user.userName,
      work
    });
  }

  return;
}
/**
 * 当前聊天在分享多选列表里面
 * @param {*} params
 * @returns
 */
function chatInMultipleContactsList(){
  const {state} = vue.$store;
  const {multiple_contacts_list} = state;
  const {task} = vue.$api;
  const get_id = task.getRouteParamsId();
  const get_type = vue.$tools.getChatType();
  if(multiple_contacts_list && multiple_contacts_list.length){
    const find = multiple_contacts_list.find(item => {
      return item.type == get_type && item.id == get_id;
    })
    return find;
  }
  return false;
}
/**
 * 分享后更新消息盒子
 * @returns
 */
function updateMessageBoxAfterShare (messageContent) {
  console.log(messageContent)
  const { state, commit } = vue.$store
  const { multiple_contacts_list } = state
  // console.log(multiple_contacts_list)
  multiple_contacts_list.forEach((item) => {
    console.log(item)
    vue.$api.task.messageBoxUp({
      messageContent,
      updateusername: vue.$tools.getCookie('user').userName,
      lastSendTime: new Date().getTime(),
      photo: item.thumbnail,
      chatId: item.id,
      chatType: item.type,
      name: item.name
    })
  })
}


/**
 * 获取分享的id列表
 * @returns {userIds, chatIds}
 */
function getShareIds () {
  const { multiple_contacts_list } = vue.$store.state
  let chatIds = ''
  let userIds = ''
  multiple_contacts_list.forEach((item) => {
    if(item.type == 0){
      userIds += userIds ? `,${item.id}` : item.id
    }
    if(item.type == 1){
      chatIds += chatIds ? `,${item.id}` : item.id
    }
  })
  return {userIds, chatIds}
}
/**
 * 分享教学包/任务
 * @param {*} params
 */
async function shareTask (params) {
  // const {id, shareType, userIds, chatIds, work} = params || {};
  let res
  try {
    res = await vue.$http.request('/projectTask/shareTask', params)
    if (res.message == 'success') {
      vue.$notice({desc: '分享成功', type:'success'})
      return true
    } else {
      vue.$notice({desc: res.describe})
    }
  } catch (err) {
    // console.log(err)
  }
  return false
}

export default SHARE
