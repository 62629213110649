/*
 * @Author       : Hugo
 * @Date         : 2020-07-01 11:36:30
 * @LastEditTime : 2022-02-23 10:12:26
 * @LastEditors  : Hugo
 * @Description  : im登录
 * @FilePath     : /miaohang/src/store/modules/im_login.js
 * @^_^
 */ 
import vue from '@/main.js';
import WebIM from '../../utils/WebIM/WebIM';

const im_login = {
  state:{
  },
  mutations:{

  },
  actions:{
    /**
     * 登录环信
     * @param {*} context 
     * @param {*} payload 
     */
    imLogin(context, payload) {
      // console.log('imLogin')
      // // console.log(context.rootState)
      let user = vue.$tools.getCookie('user');
      // // console.log(user, 'imuser');
      // context.commit("setUserName", payload.username);
      if(user){
        let {userId, hxPwd} = user; 
        var options = {
          apiUrl: WebIM.config.apiURL,
          user: 'userid_' + userId,
          // pwd: hxPwd + (payload ? '' : 'sss'), //测试
          pwd: hxPwd,
          appKey: WebIM.config.appkey,
          success: function (res) {
            var token = res.access_token;
            vue.$tools.setCookie('hx_token', token);
          },
          error: function(){
          }
        };
        // // console.log(WebIM.conn)
        WebIM.conn.open(options);
        // // console.log(WebIM.conn)
        // localStorage.setItem("userInfo", JSON.stringify({ userId: payload.username, password: payload.password }));
      }
    },

    reLogin(){
      const {path} = vue.$route;
      // console.log(path);
      // 登录相关页面不重新登录
      if(path.indexOf('login') !== -1){
        return;
      }
      vue.$store.dispatch('imLogout'); 
      
      // console.log('reLogin')
      let user = vue.$tools.getCookie('user');
      let {userId, hxPwd} = user;
      // WebIM.conn.reconnect();
      WebIM.generateConn();
      setTimeout(()=>{

        const options = {
          apiUrl: WebIM.config.apiURL,
          user: 'userid_' + userId,
          // accessToken: vue.$tools.getCookie('hx_token'),
          pwd: hxPwd,
          appKey: WebIM.config.appkey
        }
        WebIM.conn.open(options);
      },1000)
     
    },
    /**
     * 登出
     */
    imLogout(){
      // console.log('imLogout');
      WebIM.conn.close();
    },
    /**注册环信 */
    async imRegist({dispatch}, payload){
      let userId = vue.$tools.getUserId();
      let res = await vue.$http.request('/project/registerHX', {userId});
      if(res.message == 'success'){
        dispatch('imLogin');
        vue.$route.push({name:'fun_task_index'});
      }else{
        vue.$notice({desc:'注册环信失败,请联系管理员'});
      }
    }
  }
}



export default im_login;