/*
 * @Author       : Hugo
 * @Date         : 2020-09-01 13:05:36
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-06-08 10:17:29
 * @Description  : 
 * @FilePath     : /miaohang/src/utils/loading.js
 */
import LoadingComponent from '@/components/basic/Loading.vue'

const Loading = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const LoadConstructor = Vue.extend(LoadingComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new LoadConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    /**打开loading */
    Vue.prototype.$showLoading = (obj) => {
      if(obj){

        let {text, background} = obj;
        if(text){
          instance.text = text || '加载中';
        }
        if(background){
          instance.background = background || '#0054A7';
        }
      }
      instance.visible = true;
    }
    Vue.prototype.$hideLoading = () =>{
      instance.visible = false;
      setTimeout(()=>{
        instance.text = '加载中';
      },500)
    }
  }
}

export default Loading;