import vue from '@/main.js'

class TaskList {
  getList = async (params) => {
    const {chat_id, chat_type, is_all, keyword, start, tag_id} = params || {};
    let data = {
      chatId: chat_id,
      chatType: chat_type,
      isAll: is_all || 0, //是否全部0否 1是
      keyword: keyword || '',
      start: start || 0,
      tagId: tag_id || '',
    }
    console.log(data)
    const res = await vue.$global.doPost({
      url: '/projectTask/mytaskList',
      data,
    })
    return res
  }
}

const taskList = new TaskList();

export default taskList;