var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list && _vm.list.length
    ? _c(
        "div",
        {
          staticClass:
            "components_task_sidebar_page bbox d-flex flex-column flex-shrink-0",
          class: { close: !_vm.switch_sidebar },
        },
        [
          _c("div", { staticClass: "open_group" }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.switch_sidebar,
                  expression: "!switch_sidebar",
                },
              ],
              staticClass: "open button",
              on: {
                click: function ($event) {
                  return _vm.changeSwitchSidebar()
                },
              },
            }),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switch_sidebar,
                  expression: "switch_sidebar",
                },
              ],
              staticClass: "close button",
              on: {
                click: function ($event) {
                  return _vm.changeSwitchSidebar()
                },
              },
            }),
          ]),
          _c("fun", {
            staticClass: "fun_component border-bottom",
            attrs: { switch_sidebar: _vm.switch_sidebar, list: _vm.list },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.switch_sidebar,
                  expression: "switch_sidebar",
                },
              ],
              staticClass: "pannel_group",
            },
            [
              _c("widget-container", {
                attrs: {
                  data: _vm.data,
                  backup: _vm.backup,
                  large_task: _vm.large_task,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }