<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      max_height="96%"
      width="500px"
      overflow="auto"
      :wrapper-close="true"
      @handleClose="handleClose"
    >
      <div
        class="v_pc_gift_card_plugins_save_poster bbox d-flex flex-column"
      >
        <img
          v-if="0"
          src="~@/assets/images/personal_center/save_poster/close.png"
          alt=""
          class="close_icon"
          @click.stop="handleClose()"
        >
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          :data="data"
          :pcarduser="pcarduser"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from './Main.vue'
export default {
  components: {
    CommonWrapper,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      title: '',
      onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      data: undefined,
      backup: undefined,
      pcarduser: undefined,
    }
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){

      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_plugins_save_poster{
  background-color: #E5EDF6;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  // overflow-y: auto;
  padding: 20px;
  position: relative;
  height: 100%;
  max-height: 100%;
  .close_icon{
    position: absolute;
    //top:-17px;
    //right: -17px;
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
  }
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .title_common{
    width: 100%;
    height: 50px;
    background: #fff;
    position: relative;
    .title_group{
      position: relative;
      background-color: transparent;
      width: 100%;
      padding: 0 20px;
      text-align: center;
      .title{
        width: 100%;
        position: relative;
      }

    }
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
