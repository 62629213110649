var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_teaching_package_selected_task_list_index bbox d-flex flex-column",
    },
    [
      _c("div", { staticClass: "main_group bbox" }, [
        _c(
          "div",
          { staticClass: "sticky_group bbox" },
          [
            _c("filter-bar", {
              staticClass: "mb-10",
              attrs: { "package-select-task": _vm.packageSelectTask },
              on: { handleSearch: _vm.handleSearch },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("task-list", {
              attrs: {
                "package-select-task": _vm.packageSelectTask,
                "task-list": _vm.taskList,
                search: _vm.pageData.search,
              },
            }),
          ],
          1
        ),
      ]),
      _c("operation-bar", {
        staticClass: "flex-shrink-0",
        attrs: {
          "task-list": _vm.taskList,
          "package-select-task": _vm.packageSelectTask,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }