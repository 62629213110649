<template>
    <div>
        <div class="logo">
            <img src=" ../../assets/logow.png" alt="">
        </div>
        <!-- {{info}} -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    props:['info']
}
</script>