const dialog = {
  // 会话页聊天框光标索引
  dialog_chat_cursor_index: null,
  // 点击@用户名
  dialog_click_at_name: null,
  // 回话信息
  dialogData: {
    unreadNumber: 0, // 未读信息数量
    isBottom: true, // 是否在页面底部
  }
}

export default dialog;