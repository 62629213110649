<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      width="auto"
      max_width="1200px"
    >
      <div class="c_plugin_address_book_prompt d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose="handleClose"
          :title="title"
          :close="true"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          :task="params.pTask"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
          ref="plugin"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import CommonTitle from '@/components/PopUp/Common/Title'
import PluginComponent from '@/components/PopUp/Tag/StatusTag/index'
import global from '@/api/global'
import tools from '@/api/tools'
import store from '@/store'
import vue from '@/main'
export default {
  components: {
    CommonWrapper,
    PluginComponent,
    CommonTitle
  },
  data(){
    return {
      visible: false,
      params: undefined,
      // title: '',
      // onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
    }
  },
  computed: {
    title() {
      const { params } = this
      const { title } = params
      return title ?? ''
    },
    onSure() {
      const { params } = this
      const { onSure } = params
      return onSure
    }

  },
  watch: {
    visible: function(val) {
      if (val) {
        // 备份
        this.params.pTaskBackup = tools.deepClone(this.params.pTask)
        this.$nextTick(function() {
          if (this.$refs.plugin) {
            this.$refs.plugin.init()
          }
          console.log(this.$refs, this.$refs.plugin.init)
        })
      }
    }
  },
  mounted(){
    // console.log(this)
    // this.$refs.plugin.init()
  },
  methods: {

    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){
      console.log(this)
      const { params } = this
      const { pTask, pTaskBackup } = params
      const { levelStatus, processStatus, id } =  pTask
      if(this.loading){
        return
      }
      const data = {
        taskId: id
      }
      console.log(levelStatus, pTaskBackup.levelStatus,processStatus,  pTaskBackup.processStatus)
      if (String(levelStatus) !== String(pTaskBackup.levelStatus)) {
        data.levelStatus = levelStatus
      }
      if (String(processStatus) !== String(pTaskBackup.processStatus)) {
        data.processStatus = processStatus
      }
      // if ((levelStatusModified ?? '') !== '') {
      //   data.levelStatus = levelStatusModified
      // }
      // if ((processStatusModified ?? '') !== '') {
      //   data.processStatus = processStatusModified
      // }
      this.loading = true
      const res = await global.doPost({
        url: '/projectTask/setStatusTag',
        data
      })
      this.loading = false
      const { message } = res
      if (message === 'success') {
        this.$notice({
          desc: '设置成功',
          type: 'success'
        })
        // 如果是当前聊天页面 则追加taskLog
        const { contents } = res
        if (contents && contents.taskLog) {
          const { message_list } = store.state
          if (message_list && message_list.length) {
            message_list.push(contents.taskLog)
            const isBottom = store.state.dialogData.isBottom
            if (isBottom) {
              // 滚动到底部
              this.$nextTick(function(){
                store.commit('set_task_trigger_scroll_to_bottom', true);
              })
            } else {
              // 新消息提示
              // store.state.dialogData.unreadNumber += 1
            }
          }
        }
        // if ((levelStatusModified ?? '') !== '') {
        //   pTask.levelStatus = levelStatusModified
        // }
        // if ((processStatusModified ?? '') !== '') {
        //   pTask.processStatus = processStatusModified
        // }
        // pTask.levelStatusModified = undefined
        // pTask.levelStatusModified = undefined
      }
      if(this.onSure){

        // await this.onSure()

      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_address_book_prompt{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
    width: 400px;
    padding: 0 30px;
    box-sizing: border-box;
  }
}
</style>
