import CopyWidget from './modules/copyWidget'
class AppData {
  constructor () {
    this.time = Date.now()
    this.copyWidget = new CopyWidget()
  }
}

const appData = new AppData()

export default appData
