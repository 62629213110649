<!--
 * @Author       : Hugo
 * @Date         : 2021-12-17 11:49:11
 * @LastEditTime : 2022-02-21 11:39:16
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/SelectWidgetContact.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_popup_select_widget_contact_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="wrapper bbox d-flex flex-column">
        <div class="title_group">
          <div class="title">{{title}}</div>
          <img src="@/assets/images/common/close.png" alt="" class="close cp" @click.stop="doClose">
        </div>
        <div class="search_group">
          <div class="search_box">
            <img src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp">
            <el-input
              v-model="input"
              placeholder="搜索"
              class="search"
              @keydown.enter.native="doSearch($event)"
            ></el-input>
            <img src="@/assets/images/common/clear_icon.png" alt="" class="clear_icon cp" @click="input = '';">
          </div>
        </div>
        <div class="container_group">
          <!-- 待选 -->
          <div class="select_group un_sel">
            <template
              v-if="!switch_search"
            >
                
              <div
                v-if="chatUserList!==null"
                class="top_title"
              >
                组内联系人
              </div>
              <template v-for="(item, index) in chatUserList" >
                <div class="unit padding"  :key="`chat_user_list${index}`"
                  v-show="!item.hide"
                >
                  <div
                    class="create_dialog_select_unit cp"
                    :class="{'on': item.selected}"
                    @click="selectItem({userid:item.userId, selected:item.selected})"
                  >
                    <div class="name_group">
                      <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                      <div class="name" v-html="item.friendRemark || item.userName ">{{item.userName}}</div>
                    </div>
                    <div class="select_box">
                      <div class="select cp" ></div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="top_title" v-if="chatUserList!==null">全部联系人</div>
              <template v-for="(item, index) in friendList" >
                <div class="unit padding"  :key="`friend_list${index}`"
                  v-show="!item.hide"
                >
                  <div class="create_dialog_select_unit cp" :class="{'on': item.selected}" @click="selectItem({userid:item.friendUserId, selected:item.selected})">
                    <div class="name_group">
                      <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                      <div class="name" v-html="item.friendRemark || item.userName ">{{item.userName}}</div>
                    </div>
                    <div class="select_box">
                      <div class="select cp" ></div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template
              v-if="switch_search"
            >
              <template v-for="(item, index) in search_list" >
                <div class="unit padding"  :key="`search_list_${index}`"
                  v-show="!item.hide"
                >
                  <div
                    class="create_dialog_select_unit cp"
                    :class="{'on': item.selected}"
                    @click="selectItem({userid:item.friendUserId || item.userId, selected:item.selected})"
                  >
                    <div class="name_group">
                      <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                      <div class="name" v-html="searchHtml(item)"></div>
                    </div>
                    <div class="select_box">
                      <div class="select cp" ></div>
                    </div>
                  </div>
                </div>
              </template>
            </template>

          </div>
          <!-- 已选 -->
          <div class="selected_group un_sel" >
            <template v-for="(item, index) in selected_list">
              <div
                class="unit padding"  :key="index"
              >
                <div class="create_dialog_selected_unit">
                  <div class="name_group">
                    <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
                    <div class="name" v-html="item.friendRemark || item.userName ">{{item.userName}}</div>
                  </div>
                  <div class="select_box" >
                    <div class="select cp" @click="removeUser(item.userId)"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="fun_group">
          <button class="btn btn_dark sure" @click="doSure()">确定</button>
          <button class="btn btn_light cancel" @click="doClose()">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    title: {
      type: String,
      default: '选择联系人'
    },
    /**
     * 选择列表 
     * 已选择 selected
     * 可删除 deletable
     * 隐藏 hide 
     */
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    search: {
      type: Boolean,
      default: true,
    },
    select_all: {
      type: Boolean,
      default: false,
    },
    chat_user_list: {
      type: Array,
      default: null,
    },
    friend_list: {
      type: Array,
      default: null,
    },
    user_list: {
      type: Array,
      default: function(){
        return [];
      }
    }
  },
  watch:{
    input:function(val){
      if(val.trim() == ''){
        this.clearSearch();
      }
    },
  },
  data(){
    return{
      index: 1,
      input: '',
      chatUserList: null,
      friendList: null,
      selected_list: null,
      switch_search: false,
      search_list: [],
      keyword: '',
    }
  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    this.$emit('setZIndex', zindex);  // 用于解决选择联系人弹层被遮盖

    const {chat_user_list, friend_list, user_list} = this;
    this.$set(this, 'chatUserList', chat_user_list);
    this.$set(this, 'friendList', friend_list);
    this.$set(this, 'selected_list', this.$tools.deepClone(user_list)||[]);
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    doSure(){
      this.$emit('handleSure', this.selected_list)
      this.doClose()
    },
    selectUser(userid){
      // console.log(userid)
      // this.$emit('handleSelect', userid);
      const {chatUserList, friendList} = this;
      let data = null;
      if(chatUserList != null){
        const aim = chatUserList.find(unit=>{
          return unit.userId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', true);
          data = aim;
        }
      }
      if(friendList != null){
        const aim = friendList.find(unit=>{
          return unit.friendUserId == userid;
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', true);
          if(data === null){
            data = {
              friendRemark: aim.friendRemark,
              thumbnail: aim.thumbnail,
              userId: aim.friendUserId,
              userName: aim.userName,
            }
          }
        }
      }

      if(data !== null){
        this.selected_list.push(data);
      }
    },
    removeUser (userid) {
      const { chatUserList, friendList, selected_list } = this
      if(chatUserList != null){
        const aim = chatUserList.find((unit) => {
          return Number(unit.userId) === Number(userid)
        })
        if(aim !== undefined){
          this.$set(aim, 'selected', false);
        }
      }
      if (friendList != null) {
        const aim = friendList.find((unit) => {
          return Number(unit.friendUserId) === Number(userid)
        })
        if (aim !== undefined) {
          this.$set(aim, 'selected', false)
        }
      }
      if (selected_list !== null) {
        const index = selected_list.findIndex((unit) => {
          return Number(unit.userId) === Number(userid)
        })
        if (index !== -1) {
          this.selected_list.splice(index, 1)
        }
      }
    },
    selectItem (params) {
      console.log(this.search_list)
      const {selected, userid}= params;
      console.log(params)
      if(selected){
        this.removeUser(userid);
      }else{
        this.selectUser(userid);
      }
    },
    clearSearch () {
      this.keyword = '';
      this.switch_search = false;
    },
    chineseHandler (e) {
      let status = false
      if (e.keyCode === 229) {
        status = true
      }
      return status
    },
    /**
     * @description: 搜索[父组件需要写]
     * @param {*}
     * @return {*}
     */
    doSearch (e) {
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e)
      if (is_chinese) {
        return false
      }
      if (this.input.trim() !== '') {
        this.filterContact(this.input.trim())
        this.keyword = this.input.trim()
      }
    },
    /**
     * @description: 筛选联系人
     * @param {String} val 搜索字段 
     * @return {Object} 选择列表 
     */
    filterContact (keyword) {
      this.switch_search = true
      const { friendList, chatUserList } = this
      let list = []
        if (chatUserList && chatUserList.length) {
          chatUserList.forEach((item) => {
            if (item.chatUserName && item.chatUserName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
              list.push(item);
            } else if (item.friendRemark && item.friendRemark.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
              list.push(item);
            }else if(item.userName && item.userName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
              list.push(item);
            }
          })
        }
        friendList.forEach(item=>{
          // 判断是否已在list中
          const is_in = list.find(unit=>{
            return Number(unit.userId) === Number(item.friendUserId)
          })
          if (is_in === undefined) {
            if (item.friendRemark && item.friendRemark.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
              list.push(item)
            } else if (item.chatUserName && item.chatUserName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
              list.push(item)
            } else if (item.userName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1){
              list.push(item)
            }
          }
        })
      this.$set(this, 'search_list', list);
    },
    searchHtml (item) {
      const { keyword } = this
      const { chatUserName,friendRemark,userName } = item
      if (chatUserName && chatUserName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
        return this.$tools.highlightKeywords(chatUserName, keyword)
      }
      if (friendRemark && friendRemark.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
        return this.$tools.highlightKeywords(friendRemark, keyword)
      }
      if (userName && userName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
        return this.$tools.highlightKeywords(userName, keyword)
      }
      return chatUserName || friendRemark || userName
    }
  }
}
</script>
<style lang="scss" scoped>
.components_popup_select_widget_contact_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .wrapper{
    width:60%;
    height:768px;
    max-width: 1200px;
    max-height: 80%;
    background-color: #fff;
    box-shadow:0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    border-radius:4px;
    overflow: hidden;
  }
  .title_group{
    @include bbox;
    flex-shrink: 0;
    text-align: center;
    width:100%;
    position: relative;
    z-index: 2;
    padding-top:36px;
    .title{
      font-size:18px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
      position: relative;
      z-index: 2;
    }
    .close{
      position: absolute;
      z-index: 3;
      top: 42px;
      right: 30px;
      width: 11px;
      height: 11px;
    }
  }
  .search_group{
    flex-shrink: 0;
    padding: {
      top:39px;
      bottom: 30px;
    }
    .search_box{
      @include flexsb;
      @include bbox;
      padding: {
        left: 30px;
        right: 20px;
      }
      margin:0 auto;
      width:400px;
      height:40px;
      background:rgba(243,243,243,1);
      border-radius:20px;
      .search_icon{}
      .search{
        border:none;
        background-color: transparent;
        .el-input__inner{
          border:none;
          background-color: transparent;
        }
      }
      .clear_icon{}
    }
  }
   .container_group{
    @include bbox;
    @include flex;
    align-items: stretch;
    height:100%;
    max-height: 100%;
    flex:1;
    width:100%;
    overflow: hidden;
    border: {
      top: 2px solid #f0f0f0;
      bottom: 2px solid #f0f0f0;
    }
    .padding{
      padding: {
        top: 10px;
        bottom: 10px;
        left:37px;
        right:46px;
      }
    }
    .select_group{
      @include bbox;
      @include hidescrollbar;
      padding: 20px 0;
      overflow-y: auto;
      width:50%;
      border-right: 1px solid #f0f0f0;
      .top_title{
        color: #666;
        font-size: 14px;
        padding: {
          left:37px;
          right: 46px;
          top: 10px;
          bottom: 10px;
        }
      }
      .add_new_group{
        @include flex;
        padding-top: 10px;
        .img{
          width: 44px;
          height: 44px;
          border-radius: 4px;
          display: block;
        }
        .text{
          padding-left: 16px;
          font-size:14px;
          
          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
      .firstname_group{
        .title{
          @include bbox;
          font-size:14px;
          
          font-weight:400;
          color:rgba(153,153,153,1);
          text-transform: uppercase;
        }
        .unit{
          @include bbox;
          @include transition;
          background-color:#fff;
          &:hover{
            background-color: $background_hover_color;
          }
        }
      }
      .select_all_group{
        @include flexsb;
        @include bbox;
        padding: {
          left: 37px;
          right: 46px;
          top: 6px;
          bottom: 10px;
        }
        .text{
          font-size: 14px;
        }
        &.on{
          .select_box{
            .select{
              border:none;
              background-image: url(~@/assets/images/create_dialog/selected.png);
              background-size: 100%;
            }
          }
        }
        .select_box{
          width:18px;
          height:18px;
          .select{
            @include bbox;
            // @include transition;
            width: 100%;
            height: 100%;
            border:2px solid rgba(153,153,153,1);
            border-radius:50%;
          }
        }

      }
    }
    .selected_group{
      @include bbox;
      @include hidescrollbar;
      flex-shrink: 0;
      padding: {
        top: 20px;
        bottom: 20px;
      }
      overflow-y: auto;
      width:50%;
      border-left: 1px solid #f0f0f0;
      .unit{
        @include bbox;
        @include transition;
        background-color:#fff;
        &:hover{
          background-color: $background_hover_color;
        }
      }
    }
  }
  .fun_group{
    @include flex;
    padding:20px 0;
    flex-shrink: 0;
    .btn{
      @include transition;
      width:160px;
      line-height:50px;
      background:rgba(204,204,204,1);
      border-radius:4px;
      margin: 0 20px;
      font-size:18px;
      outline: none;
      border: none;
      font-weight:400;
      text-align: center;
      color:#fff;
      cursor: pointer;
    }
    .sure{
      background-color: $main_blue;
      &:hover{
        background-color: #004080;
      }
    }
    .cancel{
      background-color:rgba(0,84,166,0.1);
      color: $main_blue;
      &:hover{
        background-color: rgba(0,84,166,0.2);
      }
    }
  }
}
@media screen and (max-height: 800px){
  .select_task_container{
    .title_group{
      padding-top:16px;
      .title{
        font-size: 14px;
      }
      .close{
        top:22px;
      }
    }
    .search_group{
      padding: {
        top: 10px;
        bottom: 10px;
      }
      .search_box{
        padding: {
          left: 20px;
          right: 10px;
        }
        width: 300px;
        height:30px;
        border-radius:15px;
      }
    }
    .container_group{
      .padding{
        padding: {
          top: 6px;
          bottom: 6px;
          left:37px;
          right:46px;
        }
      }
      .select_group{
        padding: 10px 0;
      }
      .selected_group{
        padding: 10px 0;
      }
    }
    .fun_group{
      padding:10px 0;
      .btn{
        width:120px;
        line-height:30px;
        margin: 0 10px;
        font-size:14px;
      }
      .sure{
        background-color: $main_blue;
        &:hover{
          background-color: #004080;
        }
      }
      .cancel{
        background-color:rgba(0,84,166,0.1);
        color: $main_blue;
        &:hover{
          background-color: rgba(0,84,166,0.2);
        }
      }
    }
  }
}
.create_dialog_select_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  padding: {
    // left: 37px;
    // right: 46px;
    // top: 11px;
    // bottom: 11px;
  }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  &.on{
    .select_box{
      .select{
        border:none;
        background-image: url(~@/assets/images/create_dialog/selected.png);
        background-size: 100%;
      }
    }
  }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    width:18px;
    height:18px;
    .select{
      @include bbox;
      // @include transition;
      width: 100%;
      height: 100%;
      border:2px solid rgba(153,153,153,1);
      border-radius:50%;
    }
  }
}
@media screen and (max-height: 800px){
  .create_dialog_select_unit{
    .name_group{
      .avatar{
        width: 30px;
        height: 30px;
      }
    }
  }
}
.create_dialog_selected_unit{
  @include bbox;
  @include flexsb;
  @include transition;
  // background-color: #fff;
  width:100%;
  // padding: {
  //   left: 37px;
  //   right: 46px;
  //   top: 11px;
  //   bottom: 11px;
  // }
  // &:hover{
  //   background-color:#f4f4f4;
  // }
  .name_group{
    @include flex;
    @include bbox;
    width:100%;
    flex: 1;
    padding-right: 15px;
    overflow: hidden;
    .avatar{
      width: 44px;
      height: 44px;
      border-radius: 4px;
      flex-shrink: 0;
    }
    .name{
      @include bbox;
      padding-left: 16px;
      width:100%;
      flex:1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size:14px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
  }
  .select_box{
    width:18px;
    height:18px;
    .select{
      @include bbox;
      @include transition;
      width: 100%;
      height: 100%;
      border-radius:50%;
      border:none;
      background-image: url(~@/assets/images/create_dialog/delete.png);
      background-size: 100%;
    }
  }
}
@media screen and (max-height: 800px){
  .create_dialog_selected_unit{
    .name_group{
      .avatar{
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>

<style lang="css">
  .select_task_container .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;
    
    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>