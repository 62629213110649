var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "share_confirm_page" }, [
    _c(
      "div",
      { staticClass: "list_group" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "unit cp",
            on: {
              click: function ($event) {
                return _vm.onReturn()
              },
            },
          },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: item.name,
                  placement: "bottom",
                  "open-delay": 500,
                },
              },
              [
                _c("img", {
                  staticClass: "avatar",
                  attrs: {
                    src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                    alt: "",
                  },
                }),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.work,
          expression: "work",
        },
      ],
      staticClass: "input",
      attrs: { type: "text", placeholder: "简短描述" },
      domProps: { value: _vm.work },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.work = $event.target.value
        },
      },
    }),
    _c("div", { staticClass: "btn_group" }, [
      _c(
        "button",
        {
          staticClass: "button btn_dark",
          attrs: { disabled: _vm.disabled_share },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSure()
            },
          },
        },
        [_vm._v("确定 (" + _vm._s(_vm.list.length) + ")")]
      ),
      _c(
        "button",
        {
          staticClass: "button btn_light",
          on: {
            click: function ($event) {
              return _vm.doClear()
            },
          },
        },
        [_vm._v("取消")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }