<template>

<div class="plication">
    <div class="plication-left">
      <h1 class="logo">
        <img src="../../assets/logo.png" alt />
      </h1>
      <div
        class="tupian"
        @click="book(1)"
        :style="disabled?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-message.png" alt class="book" v-if="disabled" />
        <img src="../../assets/book.png" alt class="book" v-else />
      </div>
      <div class="bok"></div>
      <div
        class="pple"
        @click="book(2)"
        :style="people?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-people-sel.png" alt class="people" v-if="people" />
        <img src="../../assets/icon-people-nor.png" alt class="people" v-else />
      </div>
      <div
        class="mpa"
        @click="book(3)"
        :style="mpashow?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/modulex.png" alt class="mplae" v-if="mpashow" />
        <img src="../../assets/icon-Template-nor.png" alt class="mplae" v-else />
      </div>
      <img src alt class="phical" />
    </div>
    <div class="plication-con">
         <div class="bar">
        <div class="barinput">
          <input type="text" v-model="searchString" placeholder="搜索" class="hder" />
          <img src="../../assets/icon-search.png" alt class="search" />
        </div>
        <div class="imgbox">
          <img src="../../assets/icon-add.png" alt class="jia" />
        </div>
      </div>
      <div class="applyc">
      <div class="apply">
          <img src="../../assets/icon-apply.png" alt="" class="imgrec">
          <p class="record">申请记录</p>
      </div>
      <div class="xian1"></div>
      <div class="work">
        <div class="w" @click="gongzuozuClick">
          <img v-show="!gongzuozu" src="../../assets/icon-shouqi-nor.png" alt="" class="triangle">
          <img v-show="gongzuozu" src="../../assets/icon-zhankaix.png" alt="" class="triangle">
          <p class="zu">工作组</p>
        </div>
        <div class="wor" v-show="gongzuozu">
        <div class="img">
          <div class="img1">
            <div class="wrp">
            <div class="imgx"></div>
            <div class="imgl"></div>
            </div>
            <div class="xia">
            <div class="imgs"></div>
            <div class="imgr"></div>
            </div>
          </div>
          <p class="pro">1391项目组</p>
        </div>
        <div class="img">
          <div class="img1">
            <div class="wrp">
            <div class="imgx"></div>
            <div class="imgl"></div>
            </div>
            <div class="xia">
            <div class="imgs"></div>
            <div class="imgr"></div>
            </div>
          </div>
          <p class="prol">杨勇、瓜瓜、王明、李...</p>
        </div>
          <div class="img">
          <div class="img1">
            <div class="wrp">
            <div class="imgx"></div>
            <div class="imgl"></div>
            </div>
            <div class="xia">
            <div class="imgs"></div>
            <div class="imgr"></div>
            </div>
          </div>
          <p class="pror">中国进口商品展览会</p>
        </div>
        </div>
      </div>
      <div class="work1"></div>
      <div class="lian">
        <div class="liana" @click="lianaClick">
           <img v-show="liana" src="../../assets/icon-zhankaix.png" alt="" class="triangle">
           <img v-show="!liana" src="../../assets/icon-shouqi-nor.png" alt="" class="triangle">
           <p class="ted">联系人</p>
        </div>
        <div class="lianxipeple" v-show="liana">
        <div class="c">
          <p class="b">A</p>
        </div>
        <div class="xi">
          <div class="pe">
            <img src="" alt="" class="tp">
            <p class="am">AM</p>
          </div>
          <div class="pe">
            <img src="" alt="" class="tp">
            <p class="ak">奥卡</p>
          </div>
          <div class="pe">
            <img src="" alt="" class="tp">
            <p class="aks">艾科斯</p>
          </div>
        </div>
        <div class="b">
          <div class="bc">
            <p class="bi">B</p>
          </div>
          <div class="bxi">
            <img src="" alt="" class="tp">
            <p class="am">办文博</p>
          </div>
          <div class="pe">
            <img src="" alt="" class="tp">
            <p class="ak">本开</p>
          </div>
          <div class="fbs">
            <img src="" alt="" class="tp">
            <p class="fps">博平生</p>
          </div>
        </div>
        </div>
      </div>
      <div class="line2"></div>
      <div class="bably">
        <div class="prob" @click="probClick">
          <img v-show="prob" src="../../assets/icon-zhankaix.png" alt="" class="inve">
          <img v-show="!prob" src="../../assets/icon-shouqi-nor.png" alt="" class="inve">
          <p class="proba">可能认识的人</p>
        </div>
        <div class="keneng" v-show="prob">
        <div class="ak">
          <img src="" alt="" class="aks">
          <p class="ams">AM</p>
        </div>
        <div class="ask">
          <img src="" alt="" class="asks">
          <p class="aks">奥卡 </p>
        </div>
      </div>
      </div>
    </div>
    </div>
    <div class="line1"></div>
    <div class="plication-right">
        <div class="jilu">
            <p class="sjilu">申请记录</p>
        </div>
        <div class="jilist">
            <dt class="flower">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="flowers">
            </dt>
            <dd class="nam">
                <p class="si">石慧君</p>
                 <p class="phon">13503589561</p>
                  <p class="gongsi">我是上游公司的石慧君</p>
            </dd>
            <div class="time">
                <span class="shijian">20/02/16 15:38</span>
                  <div class="btn">
                    <el-button class="accept">接受</el-button>
                    <el-button class="refuse" >拒绝</el-button>
                </div>
            </div>
        </div>
          <div class="jilist">
            <dt class="flower">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="flowers">
            </dt>
            <dd class="nam">
                <p class="si">石慧君</p>
                 <p class="phon">13503589561</p>
                  <p class="gongsi">我是上游公司的石慧君</p>
            </dd>
            <div class="time">
                <span class="shijian">20/02/16 15:38</span>
                  <div class="btn">
                    <el-button class="accept">接受</el-button>
                    <el-button class="refuse" >拒绝</el-button>
                </div>
            </div>
        </div>
          <div class="added">
            <dt class="img">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="imge">
            </dt>
            <dd class="name">
                <p class="stone">石慧君</p>
                 <p class="phone">13503589561</p>
                  <p class="me">展览会会议上我们商谈过关于代理事宜，刚才和您通过电话，商谈具体创意落地事宜，
                      刚才和您通过电话，商谈具体创意落地事宜</p>
            </dd>
            <div class="timeto">
                <span class="tim">20/02/16 15:38</span>
                  <div class="btn">
                    <el-button class="addto">已添加</el-button>
                </div>
            </div>
        </div>
          <div class="added">
            <dt class="img">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="imge">
            </dt>
            <dd class="name">
                <p class="stone">石慧君</p>
                 <p class="phone">13503589561</p>
                  <p class="me">展览会会议上我们商谈过关于代理事宜，刚才和您通过电话，商谈具体创意落地事宜，
                      刚才和您通过电话，商谈具体创意落地事宜</p>
            </dd>
            <div class="timeto">
                <span class="tim">20/02/16 15:38</span>
                  <div class="btn">
                    <!-- <el-button class="addto">已添加</el-button> -->
                    <p class="yi">已申请</p>
                    <p class="play">请等待对方反馈</p>
                </div>
            </div>
        </div>
          <div class="added">
            <dt class="img">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="imge">
            </dt>
            <dd class="name">
                <p class="stone">石慧君</p>
                 <p class="phone">13503589561</p>
                  <p class="me">展览会会议上我们商谈过关于代理事宜，刚才和您通过电话，商谈具体创意落地事宜，
                      刚才和您通过电话，商谈具体创意落地事宜</p>
            </dd>
            <div class="timeto">
                <span class="tim">20/02/16 15:38</span>
                  <div class="btn">
                      <p class="jujue">对方已拒绝</p>
                    <el-button class="shen">重新申请</el-button>
                </div>
            </div>
        </div>
          <div class="added">
            <dt class="img">
            <img src="https://ss0.bdstatic.com/94oJfD_bAAcT8t7mm9GUKT-xh_/timg?image&quality=100&size=b4000_4000&sec=1587973694&di=c5bd75fcacf19a4c0126b86394cb3db9&src=https://pic3.16pic.com/00/01/11/16pic_111395_b.jpg" alt="" class="imge">
            </dt>
            <dd class="name">
                <p class="stone">石慧君</p>
                 <p class="phone">13503589561</p>
                  <p class="me">展览会会议上我们商谈过关于代理事宜，刚才和您通过电话，商谈具体创意落地事宜，
                      刚才和您通过电话，商谈具体创意落地事宜</p>
            </dd>
            <div class="timeto">
                <span class="tim">20/02/16 15:38</span>
                  <div class="btn">
                    <el-button class="addto">已拒绝</el-button>
                </div>
            </div>
        </div>
    </div>
    </div>

</template>

<script>
export default {
data() {
    return {
      searchString: "",
      disabled: false,
      people: false,
      mpashow: false,
      item: "",
      gongzuozu:false,
      liana:false,
      prob:false,
    };
},
 methods: {
    book(index) {
      if (index == 1) {
        this.mpashow = false;
        this.people = false;
        this.disabled = true;
      }
      if (index == 2) {
        this.mpashow = false;
        this.disabled = false;
        this.people = true;
      }
      if (index == 3) {
        this.people = false;
        this.disabled = false;
        this.mpashow = true;
      }
    },
    pple() {
      this.people = !this.people;
    },
    mpaclick() {
      this.mpashow = !this.mpashow;
    },
    gongzuozuClick(){
      this.gongzuozu = !this.gongzuozu
    },
    lianaClick(){
      this.liana = !this.liana
    },
     probClick(){
      this.prob = !this.prob
    }
  },
}

</script>

<style  lang='less' scoped>
.plication{
    width: 100%;
    height: 100%;
    display: flex;

    .plication-left {
        width: 60px;
    height: 100%;
    background: #0054a6;
     .logo {
      display: block;
      width: 48px;
      height: 48px;
      margin-top: 6px;
      margin-left: 6px;
    }
    .tupian {
      width: 62px;
      height: 50px;
      margin-top: 30px;
      .book {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 12px;
        z-index: 11;
      }
    }
        .pple {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .people {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
        .mpa {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .mplae {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
        .phical {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background: orange;
      margin-top: 381px;
      margin-left: 8px;
    }
    }
    .plication-con{
      width: 250px;
      // height: 100%;
      overflow:hidden;
     .bar {
      display: flex;
      width: 250px;
      height: 66px;
      border-bottom: 2px solid #f4f4f4;
      overflow: hidden;
      .barinput {
        margin-top: 16px;
        margin-left: 16px;
        .hder {
          width: 138px;
          height: 30px;
          background: rgba(243, 243, 243, 1);
          border-radius: 15px;
          border: none;
          outline: none;
          position: relative;
          padding-left: 40px;
        }
        .search {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 24px;
          left: 86px;
        }
      }
      .imgbox {
        width: 30px;
        height: 30px;
        background: rgba(243, 243, 243, 1);
        border-radius: 50%;
        margin-left: 14px;
        margin-top: 16px;
        .jia {
          display: block;
          width: 10px;
          height: 11px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
    .applyc{
      width: 250px;
      height: 100%;
      overflow-x: scroll;

      .apply{
        width: 250px;
        height:84px;
        display: flex;
        .imgrec{
          display: block;
          width: 44px;
          height: 44px;
          margin-top: 20px;
          margin-left: 18px;
        }
        .record{
          width:56px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
          margin-top: 35px;
          margin-left: 16px;
        }

    }

    .xian1{
      width:232px;
      height:2px;
      background:rgba(240,240,240,1);
      margin-left: 19px;
    }
    .work{
      width: 250px;
      .w{
        width: 250px;
        height: 52px;
        display: flex;
         .triangle{
        display: block;
        width: 10px;
        height: 8px;
        margin-left:16px;
        margin-top: 22px;
      }
      .zu{
        width:42px;
        height:14px;
        font-size:14px;
        font-weight:400;
        color:rgba(102,102,102,1);
        margin-top: 15px;
        margin-left: 6px;
      }
      }
      .wor{

     .img{
        width: 250px;
        height: 66px;
       display: flex;
       .img1{
         width: 44px;
         height: 44px;
         background: pink;
        //  margin-top:22px;
        //  margin-left: 17px;
         margin: 11px 0 0 17px;
         .wrp{
           display: flex;
            .imgx{
           width: 22px;
           height: 22px;
           background: orange;
         }
         .imgl{
           width: 22px;
           height: 22px;
           background: blue;
         }
         }
        .xia{
          display: flex;
          .imgs{
           width: 22px;
           height: 22px;
           background: purple;
         }
         .imgr{
           width: 22px;
           height: 22px;
           background: red;
         }
        }

       }
       .pro{
         width:75px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
          margin-top: 25px;
          margin-left: 7px;
       }
       .prol{
         width:151px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
           margin-top: 25px;
          margin-left: 7px;
       }
       .pror{
         width:126px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
           margin-top:25px;
          margin-left: 7px;
       }
     }
    }
    }
    .work1{
      width:232px;
      height:2px;
      background:rgba(240,240,240,1);
      margin-left: 17px;
    }
    .lian{
      .liana{
        display: flex;
        .triangle{
          display: block;
        width: 10px;
        height: 8px;
        margin-left:16px;
        margin-top: 22px;
        }
        .ted{
          width:42px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(102,102,102,1);
          margin-top: 15px;
          margin-left: 6px;
        }
      }
      .lianxipeple{


      .c{
        .b{
          width:10px;
          height:11px;
          font-size:14px;
          font-weight:400;
          color:rgba(170,170,170,1);
          margin-left: 16px;
          margin-top:24px;
        }
      }
      .xi{
        .pe{
          display: flex;
          .tp{
            display: block;
            width: 44px;
            height: 44px;
            background: red;
            margin-top:20px;
            margin-left: 16px;
          }
          .am{
            width:23px;
            height:11px;
            font-size:14px;
            font-weight:400;
            color:rgba(51,51,51,1);
            margin-left: 15px;
            margin-top:37px;
          }
        }
        .ak{
          width:28px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
          margin-top:32px;
            margin-left:15px;
        }
        .aks{
          width:42px;
          height:14px;
          font-size:14px;
          font-weight:400;
          color:rgba(51,51,51,1);
          margin-top:31px;
          margin-left:16px;
        }
      }
      .b{
        .bc{
          .bi{
          width:10px;
          height:11px;
          font-size:14px;
          font-weight:400;
          color:rgba(170,170,170,1);
          margin-left: 16px;
          margin-top:24px;
          }
        }
        .bxi{
          display: flex;
          .tp{
            width: 44px;
            height: 44px;
            background: red;
            display: block;
            margin-top:20px;
            margin-left:16px;
          }
          .am{
            width:42px;
            height:14px;
            font-size:14px;
            font-weight:400;
            color:rgba(51,51,51,1);
            margin-top:36px;
            margin-left:15px;
          }

        }
        .pe{
          display: flex;
          .tp{
             width: 44px;
            height: 44px;
            background: red;
            display: block;
             margin-top:20px;
            margin-left:16px;
          }
          .ak{
            width:28px;
            height:14px;
            font-size:14px;
            font-weight:400;
            color:rgba(51,51,51,1);
            margin-top:36px;
            margin-left:15px;
          }
        }
        .fbs{
          display: flex;
          .tp{
             width: 44px;
            height: 44px;
            background: red;
            display: block;
            margin-top:20px;
            margin-left:16px;
          }
          .fps{
            width:42px;
            height:14px;
            font-size:14px;
            font-weight:400;
            color:rgba(51,51,51,1);
            margin-top:36px;
            margin-left:15px;
          }
        }

      }
      }
    }
      .line2{
          width:232px;
          height:2px;
          background:rgba(240,240,240,1);
          margin-top:20px;
          margin-left: 19px ;
        }
        .bably{
          .prob{
            display: flex;
            .inve{
              display:block;
              width: 10px;
              height: 8px;
              margin-left:16px;
              margin-top:22px;
            }
            .proba{
              width:84px;
              height:14px;
              font-size:14px;
              font-weight:400;
              color:rgba(102,102,102,1);
              margin-left: 6px;
              margin-top:15px;
            }
          }
          .keneng{


          .ak{
            display: flex;
            .aks{
              display: block;
            width:44px;
            height:44px;
            border-radius:4px;
            background: purple;
            margin-left: 16px;
            margin-top:20px;
            }
            .ams{
              width:23px;
              height:11px;
              font-size:14px;
              font-weight:400;
              color:rgba(51,51,51,1);
              margin-left: 15px;
              margin-top:30px;
            }
          }
          .ask{
            display: flex;
            .asks{
              width: 44px;
              height: 44px;
              background: plum;
              margin-top: 20px;
              margin-left: 16px;
            }
            .aks{
              width:28px;
              height:14px;
              font-size:14px;
              font-weight:400;
              color:rgba(51,51,51,1);
              margin-top:36px;
              margin-left: 15px;
            }
          }
        }
         }
    }

    }
    .line1{
        width:2px;
        height:100%;
        background:rgba(244,244,244,1);
    }
    .plication-right{
        width: 100%;
        height: 100%;
        background: #F5F8FA;
        overflow: auto;
        .jilu{
            width: 100%;
            height: 44px;
            border-bottom: 2px solid #F4F4F4;
            .sjilu{
                width:72px;
                height:19px;
                font-size:18px;
                font-weight:400;
                color:rgba(51,51,51,1);
                margin-top:22px;
                margin-left: 21px;
            }
        }
        .jilist{
            width:100%;
            height:130px;
            background:rgba(255,255,255,1);
            border-radius:10px;
            display: flex;
            margin-top:20px;
            .flower{
               .flowers{
            display: block;
            width:90px;
            height:90px;
            border-radius:4px;
            margin-top:20px;
            margin-left: 20px;
            }
            }


            .nam{
                .si{
                    width:48px;
                    height:16px;
                    font-size:16px;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    margin-top:20px;
                    margin-left: 20px;
                }
                .phon{
                    width:87px;
                    height:12px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    margin-top:14px;
                    margin-left: 20px;
                }
                .gongsi{
                    width:140px;
                    height:14px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    margin-top:34px;
                    margin-left: 20px;
                }
            }
            .time{
                // display: flex;
                // margin-left: 53px;
                .shijian{
                    display: block;
                    width:102px;
                    height:13px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    margin-top: 16px;
                    margin-left: 838px;
                }
                .btn{
                .accept{
                        display: block;
                    width:100px;
                    height:34px;
                    background:rgba(0,84,166,1);
                    border-radius:4px;
                    border: none;
                    color: #ffffff;
                    margin-top: 10px;
                    margin-left: 838px;
                    // flex-direction: column;
                }
                .refuse{
                    display: block;
                    width:100px;
                    height:34px;
                    background:#e5eef6;
                    border-radius:4px;
                    border: none;
                    color: #0054A6;
                    margin-top:8px;
                    margin-left: 838px;
                    // margin-right:41px ;
                    // flex-direction: column;
                }
                }

            }
        }
        .added{
              width:100%;
            height:130px;
            background:rgba(255,255,255,1);
            border-radius:10px;
            display: flex;
            // margin-top:20px;
            margin-top:20px;
            .img{
              .imge{
                  display: block;
                  width: 90px;
                  height: 90px;
                  margin-top:20px;
                  margin-left:20px;
              }
            }
            .name{
            .stone{
                width:48px;
                height:17px;
                font-size:16px;
                font-weight:400;
                color:rgba(51,51,51,1);
                margin-top:20px;
                margin-left: 20px;
                }
                .phone{
                    width:89px;
                    height:12px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    margin-top:14px;
                    margin-left: 20px;
                }
                .me{
                    width:100%;
                    height:36px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    line-height:22px;
                     margin-top:34px;
                    margin-left: 20px;
                }
            }
            .timeto{
                .tim{
                    display: block;
                    width:102px;
                    height:13px;
                    font-size:14px;
                    font-weight:400;
                    color:rgba(102,102,102,1);
                    margin-top:20px;
                    margin-left: 179px;
                }
                .btn{
                    .addto{
                        width:100px;
                        height:34px;
                        background:rgba(204,204,204,1);
                        border-radius:4px;
                        color: #ffffff;
                        border: none;
                        margin-left:179px;
                        margin-top:23px;
                    }
                    .yi{
                        width:51px;
                        height:17px;
                        font-size:16px;
                        font-weight:400;
                        color:rgba(0,84,166,1);
                        margin-left:204px;
                        margin-top:23px;
                    }
                    .play{
                        width:107px;
                        height:14px;
                        font-size:14px;
                        font-weight:400;
                        color:rgba(0,84,166,1);
                        margin-left:179px;
                        margin-top:7px;
                    }
                    .jujue{
                        width:87px;
                        height:17px;
                        font-size:16px;
                        font-weight:400;
                        color:rgba(180,17,0,1);
                    }
                    .shen{
                        width:100px;
                        height:34px;
                        background:rgba(0,84,166,1);
                        opacity:0.94;
                        border-radius:4px;
                        color: #FFFFFF;
                    }
                    .jujue{
                        width:87px;
                        height:17px;
                        font-size:16px;
                        font-weight:400;
                        color:rgba(180,17,0,1);
                        margin-left:179px;
                        margin-top: 27px;
                    }
                    .shen{
                        margin-left: 179px;
                        margin-top:15px;
                    }
                }
            }
        }
    }
}
.applyc::-webkit-scrollbar {
  width:10px;
    }
    .applyc::-webkit-scrollbar-thumb {
  width:10px;
  height: 10px;
  border-radius: 5px;
  background: #ddd;
        }
</style>
