var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            { attrs: { height: "80%", width: "600px", max_width: "60%" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "c_plugin_migrate_task_select_target_group d-flex flex-column",
                },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0",
                    attrs: {
                      title: _vm.title,
                      close: true,
                      background_color: "#fff",
                    },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _c("plugin-component", {
                    staticClass: "plugin_component",
                    attrs: { params: _vm.params },
                    on: {
                      handleClose: _vm.handleClose,
                      handleSure: _vm.handleSure,
                      handleReturn: _vm.handleReturn,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }