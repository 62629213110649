<template>
  <div
    class="components_popup_record_of_communication_picture_picture_page bbox"
  >
    <div class="image_group">
      <!-- <div class="image"
        :style="{'background-image': `url(${$img_url}${item.url})`}"
      > -->
      <div class="image">
        <el-image 
          class="img"
          fit="cover"
          :src="`${$img_url}${item.url}@!small200`" 
          :preview-src-list="[`${$img_url}${item.url}`]"
          lazy
        ></el-image>
      </div>
      <div class="mask_group bbox p-10 d-flex flex-column justify-space-between align-start"
        @click.stop="item.previewFile({type: 1})"
      >
        <div class="task_name cp"
          v-text="`任务: ${item.taskname}`"
          @click.stop="item.openTask({config: {edit: true}});"
        >任务：Apple公司年会公司年会 ></div>
        <div class="fun_group d-flex align-end justify-space-between">
          <div class="size">{{item.formatFilesize()}}</div>
          <div class="download cp"
            @click.stop="item.download({type: 1})"
          ></div>
        </div>
      </div>
    </div>
    <div class="picture_name bbox px-5 cp"
      v-text="item.filename"
      @click.stop="item.previewFile({type: 1})"
    >北京北北京奥林北京北北京奥林奥北京北北京奥林奥.jpg</div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: null,
    }
  }
}
</script>

<style lang="scss" scoped>
.components_popup_record_of_communication_picture_picture_page{ 
  .image_group{
    @include transition;
    position: relative;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    .image{
      position: relative;
      z-index: 2;
      width: 140px;
      height: 140px;
      // background: {
      //   size: cover;
      //   position: center;
      // }
      .img{
        width: 140px;
        height: 140px;
      }
    }
    &:hover{
      .mask_group{
        opacity: 1;
      }
    }
    .mask_group{
      @include transition;
      opacity: 0;
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: {
        color: rgba(0,0,0,0.7);
      }
      .task_name{
        @include transition;
        color: #fff;
        font-size: 12px;
        &:hover{
          color: #A2D1FF;
        }
      }
      .fun_group{
        width: 100%;
        .size{
          color: #fff;
          font-size: 12px;
        }
        .download{
          width: 24px;
          height: 22px;
          background-image: url(~@/assets/images/record_of_communication/picture/download.png);
        }
      }
    }
  }
  .picture_name{
    @include single_line_intercept;
    width: 140px;
    font-size: 12px;
    background-color: #e7e7e7;
    color: #333;
    line-height: 30px;
    border-radius: 0 0 4px 4px;
  }
}
</style>