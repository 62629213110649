/*
 * @Author       : Hugo
 * @Date         : 2021-04-26 10:40:38
 * @LastEditTime : 2021-08-10 17:02:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/utils/common.js
 * @Description  : 针对utils
 * @^_^          : 
 */
import vue from '@/main.js';

const COMMON = {
  /**
   * 获取全局浮层zindex得的值并加1 返回加1的值 拿到可以直接使用
   * @returns {String|Number}
   */
  getZIndex(){
    const {default_floor_z_index} = vue.$store.state;
    vue.$store.commit('set_default_floor_z_index', default_floor_z_index+1);
    return default_floor_z_index+1;
  },
  /**
   * 设置zindex
   * @param {*} className 
   */
  setPopoverZIndex(className){
    vue.$nextTick(function(){
      setTimeout(()=>{
        const zindex = vue.$utils.getZIndex();
        // // console.log(zindex);
        // // console.log(document.querySelectorAll(`${className}`))
        document.querySelectorAll(`${className}`).forEach(item=>{
          // // console.log(item.style);
          item.style.zIndex = zindex;
        })
      },200)
      
    })
  },
  /**
   * 设置element图片zindex
   */
  setElementImageZIndex(){
    const zIndex = vue.$utils.getZIndex();
    document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
      item.style.zIndex = zIndex;
    })
  }
}

export default COMMON;
