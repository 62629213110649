/*
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-12 14:34:34
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-25 11:20:21
 * @FilePath: /miaohang/src/api/modules/task_fun/editReminder.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import vue from '@/main';
class EditReminder{
  main = async (data)=> {
    // console.log(data);
    const res = await vue.$global.doPost({
      url: '/remind/remindEdit',
      data,
      loading: true,
      loadingText: '提交中',
    })
    return res;
  }
}

const editReminder = new EditReminder();

export default editReminder.main;