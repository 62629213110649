import vue from '@/main';
class UserDetail{
  /**
   * 发起对话
   */
  initiateDialogue(data){
    vue.$api.common_goToSingleDialog(data);
    vue.$api.moduleTask.closeTask();
  } 
  /**
   * 申请好友
   * @param {*} data 
   */
  applyFriend(data){
    vue.$store.commit('to_add_friend', data);
  }
}

const userDetail = new UserDetail();

export default userDetail;