var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_collect_navigation_index bbox d-flex flex-wrap align-center",
    },
    [
      _vm.collect_nav && _vm.collect_nav.length && !_vm.preview
        ? _c(
            "div",
            {
              staticClass: "all unit cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.goToTop()
                },
              },
            },
            [_vm._v("全部分组")]
          )
        : _vm._e(),
      _vm.collect_nav && _vm.collect_nav.length
        ? [
            _vm._l(_vm.collect_nav, function (item, index) {
              return [
                item && item.name
                  ? [
                      (_vm.preview && index !== 0) || !_vm.preview
                        ? _c(
                            "div",
                            { key: `${index}_symbol`, staticClass: "symbol" },
                            [_vm._v(" > ")]
                          )
                        : _vm._e(),
                      _c("unit-component", {
                        key: index,
                        staticClass: "unit cp",
                        class: { active: index === _vm.collect_nav.length - 1 },
                        attrs: { p_folder: item },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.goToFolder(item, index)
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }