/*
 * @Author       : Hugo
 * @Date         : 2021-12-23 15:44:00
 * @LastEditTime : 2021-12-23 15:47:30
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/pRotation/index.js
 * @Description  : 
 * @^_^          : 
 */
class PRotation{
  content = undefined;
  createTime = undefined;
  endTime = undefined;
  id = undefined;
  isuse = undefined;
  pic = undefined;
  position = undefined;
  sort = undefined;
  startTime = undefined;
  title = undefined;
  type = undefined;

  constructor(props={}){
    const {
      content,
      createTime,
      endTime,
      id,
      isuse,
      pic,
      position,
      sort,
      startTime,
      title,
      type,
    } = props;
    this.content = content;
    this.createTime = createTime;
    this.endTime = endTime;
    this.id = id;
    this.isuse = isuse;
    this.pic = pic;
    this.position = position;
    this.sort = sort;
    this.startTime = startTime;
    this.title = title;
    this.type = type;
  }
}

export default PRotation;