<!--
 * @Author       : Hugo
 * @Date         : 2022-02-22 09:49:10
 * @LastEditTime : 2022-02-22 11:57:06
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/ShoppingMail/AddToCustomList/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="600px"
    max_height="460px"
  >
    <div
      class="com_shopping_mail_add_to_custom_list_index pb-20 d-flex flex-column"
    >
      <common-title
        class="flex-shrink-0"
        :title="title"
        close
      >
      </common-title>
      <div class="main bbox d-flex flex-column align-start">
        <div
          class="search_group d-flex px-16 flex-shrink-0"
        >
          <img
            src="~@/assets/images/common/search_icon.png"
            alt=""
            class="icon mr-4"
          >
          <input type="text" class="search input"
            placeholder="搜索"
          >
        </div>
        <div class="create_group d-flex mt-15 px-16 bbox un_sel cp flex-shrink-0">
          <img
            src="~@/assets/images/shopping_mail/custom_list/create.png"
            alt=""
            class="icon mr-4">
          <div class="text">创建清单</div>
        </div>
        <div class="list_group mt-10">
          <template
            v-for="(item, index) in 20"
          >
              
            <div class="list d-flex justify-space-between bbox px-16 mb-10"
              :key="index"
            >
              <div class="name_group d-flex">
                <div class="name mr-10">未命名</div>
                <img
                  src="~@/assets/images/shopping_mail/custom_list/edit.png"
                  alt=""
                  class="edit cp un_sel"
                >
              </div>
              <div class="operation_group un_sel">
                <div class="btn button disabled">添加</div>
              </div>
            </div>
          </template>

        </div>
      </div>
    </div>
  </common-wrapper>    
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
.com_shopping_mail_add_to_custom_list_index{
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  background-color: #fff;
  overflow        : auto;
  .main{
    flex    : 1;
    width   : 100%;
    padding : 20px;
    overflow: auto;
  }
  .search_group{
    background-color: #f4f4f4;
    border-radius   : 4px;
    max-width       : 240px;
    width           : 100%;
    .icon{}
    .search{
      @include placeholder(#999);
      font-size       : 14px;
      color           : #333;
      line-height     : 30px;
      height          : 30px;
      border          : none;
      background-color: transparent;
      outline         : none;
    }
  }
  .create_group{
    width        : 100%;
    font-size    : 14px;
    color        : #999;
    border-radius: 4px;
    line-height  : 38px;
    border       : 1px dashed #ccc;
    // background-color: #f4f4f4;
  }
  .list_group{
    @include scrollbar;
    overflow-y : auto;
    font-size  : 14px;
    color      : #333;
    line-height: 38px;
    width      : 100%;
    .list{
      // background-color: #f4f4f4;
      border       : 1px solid #ccc;
      border-radius: 4px;
      .name_group{
        &:hover{
          .edit{
            opacity: 1;
          }
        }
        .edit{
          @include transition;
          opacity: 0;
        }
      }
      .operation_group{
        .btn{
          font-size       : 12px;
          line-height     : 28px;
          background-color: #4D88C1;
          border-radius   : 4px;
          width           : 60px;
          text-align      : center;
          color           : #fff;
          &.disabled{
            background-color: #ccc;
            cursor          : default;
          }
        }
      }
    }
  }
}
</style>
