<template>
  <transition name="fade">
    <div class="reminder_notice_plugin"
      v-if="visible"
    >
      <div class="list_group">
        <template
          v-for="(data,index) in list"
        >
          <div class="main bbox d-flex flex-column justify-space-between align-start"
            :key="index"
            :style="{
              'z-index': data.zindex,
              top: `${30+index*5}px`,
              right: `${30-index*5}px`,
            }"
            v-if="index< max"
          >
            <div class="info_group d-flex flex-column">
              <div class="user_group d-flex align-center">
                <img 
                  :src="`${$avatar_url}${data.userPhoto}@!small200`" alt="" class="avatar">
                <div class="from">来自 {{data.userName}} 的提醒:</div>
              </div>
              <div class="content">{{data.content}}</div>
              <div class="task_group">任务：{{data.taskName}}</div>
              <div class="widget_group">控件：{{data.conName || '未命名控件'}}</div>
            </div>
            <div class="operating_group d-flex align-center justify-end un_sel">
              <div class="button ignore"
                @click="readed({data});"
              >我知道了</div>
              <div class="button leave_for"
                @click="check(data)"
              >前往查看</div>
            </div>
          </div>
        </template>
      </div>
     
    </div>
  </transition>
</template>
<script>
/**
 * 提醒通知  
 * 
 * 
 */
export default {
  data () {
    return {
      // visible: false,
      data: null,
      index: 0,
      max: 3, // 最大储存消息数
      list: [],
    }
  },
  computed:{
    visible(){
      const {list} = this;
      if(list && list.length){
        return true;
      }
      return false;
    }
  },
  watch:{
    // visible: function(val){
    //   if(val){
    //     this.zindex = this.$utils.getZIndex();
    //   }
    // },
  },
  methods: {
    pushNotice(params){
      const {max} = this;
      const {data} = params;
      this.$set(data, 'zindex', this.$utils.getZIndex());
      this.list.unshift(data);
      // 最多保留max个数据
      // if(this.list.length > max){
      //   this.list.splice(max-1, this.list.length - max)
      // }

      // this.zindex = this.$utils.getZIndex();
    },
    readed(params){
      const {data} = params;
      const index = this.list.findIndex(item=>{
        return item.id == data.id
      })
      if(index != -1){
        this.list.splice(index, 1);
      }
      this.$api.reminder.readed(data);
    },
    check(data){
      const isOpenTask = this.$api.reminder.isOpenTask();
      if(isOpenTask){
        this.$openConfirm({
          title:'是否关闭当前任务?',
          desc:'该操作无法撤销',
          sure:'',
          cancel:'',
          onSure:()=>{

            this.$api.moduleTask.closeTask();
            this.$api.moduleTask.openTask({
              taskId: data.taskId,
              config: {
                edit: true,
                appoint_widget: {
                  widget_id: data.conId,
                }
              }
            });
            // console.log({
            //   taskId: data.taskId,
            //   config: {
            //     edit: true,
            //     appoint_widget: {
            //       widget_id: data.conId,
            //     }
            //   }
            // })
            const {ptype, endTime} = data;
            if(ptype == 1 || (endTime && Date.now() > Number(endTime)) ){
              this.$api.moduleTask.common.updateReminderConcrened({
                taskId: data.taskId,
                type: 'decrease',
              })
            }
            
            this.readed({data});

          },
          onCancel:()=>{},
        })
      }else{
      // this.$api.moduleTask.closeTask();
        this.$api.moduleTask.openTask({
          taskId: data.taskId,
          config: {
            edit: true,
            appoint_widget: {
              widget_id: data.conId,
            }
          }
        });
        this.readed({data});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.reminder_notice_plugin{
  .main{
    @include transition;
    width: 440px;
    height: 248px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 20px;
    z-index: 999999999;
    position: fixed;
    top: 30px;
    right: 30px;

  }
  .user_group{
    width: 100%;
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-right: 12px;
    }
    .from{
      font-size: 20px;
      font-weight: bold;
      color: #EE6800;
    }
  }
  .content{
    width: 100%;
    font-size: 15px;
    color: #333333;
    line-height: 22px;
    padding-top: 16px;
    word-break:break-all;
  }
  .task_group{
    width: 100%;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    padding-top: 10px;
  }
  .widget_group{
    width: 100%;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    padding-top: 10px;
  }
  .operating_group{
    width: 100%;
    .button{
      font-size: 14px;
      color: #EE6D00;
      line-height: 28px;
      border: 1px solid #EE6D00;
      border-radius: 4px;
      margin-left: 10px;
      padding: 0 20px;
      &.leave_for{
        background-color: #EE6D00;
        color: #fff;
      }
    }
  }
}
</style>