<template>
  <div
    class="components_popup_record_of_communication_task_task_page bbox cp"
    v-if="item"
    @click="item.open({edit: true})"
  >
    <div class="color_group"
      v-if="item.colour"
    >
        <template
          v-for="(item,index) in getColorList(item.colour)"
        >
          <div
            class="color"
            :key="index"
            :style="{'border-left-color': item}"
          ></div>
        </template>
        
      </div>
    <div class="content_group">
      <div class="title_group d-flex align-center justify-space-between">
        <div class="title"
          v-html="$tools.highlightKeywords(item.name, keyword)"
        ></div>
        <div class="time">{{$moment(item.updateTime).format('YYYY年MM月DD日 HH:mm')}}</div>
      </div>
      <div class="widget_group"
        v-if="widget"
      >
        <div class="widget"
          v-if="keyword !== ''"
          v-html="`包含控件: ${$tools.highlightKeywords(widget.remark, keyword)}`"
        >包含控件: 内容排期及其他事项讨论</div>
      </div>
    </div>
    <div class="info_group d-flex align-center justify-space-between">
      <div class="user_info">
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.createusername"
          placement="bottom"
        >
          <img :src="`${$avatar_url}${item.createuserthumbnail}@!small200`" alt="" class="avatar">
        </el-tooltip>
      </div>
      <div class="tag_info">
        <div
          v-if="0"
          class="tag finance"
        >
          财
        </div>
        <div
          v-if="item.draft"
          class="tag draft"
        >草</div>
        <div
          v-if="item.taskType === 2 || item.taskType === 3"
          class="tag teach"
        >教</div>
        <div 
          v-if="item.hide === 1"
          class="tag privacy"
        >私</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    keyword: {
      type: String,
      default: '',
    }
  },
  data(){
    return {
      widget: null,
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    /**
     * @description: 获取颜色列表
     * @param {*} colour
     * @return {*}
     */
    getColorList(colour){
      if(!colour){
        return '';
      }
      const color_list = colour.split(',');
      return color_list;

    },
    init(){
      this.getWidgetInfo();
    },
    // 获取包含控件数据
    getWidgetInfo(){
      const {keyword} = this;
      const controls = JSON.parse(this.item.controls);
      if(controls && controls.length){
        // 目前没有查询关联控件
        const widget = controls.find(item=>{
          return item.remark.includes(keyword);
        })
        if(widget){
          this.$set(this, 'widget', Object.assign({},widget));
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.components_popup_record_of_communication_task_task_page{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e8eeff;
  background-color: #fff;
  padding: 0 16px;
  line-height: 20px;
  font-size: 15px;
  color: #333;
  position: relative;
  z-index: 2;
  .color_group{
    @include flex;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 0;
    top: 0;
    left: 1px;
    z-index: 3;
    .color{
      flex:1;
      width:5px;
      border: none;
      border-left: 4px solid #FFB038;
      &:first-child{
        border-top-left-radius: 20px;
      }
      &:last-child{
        border-bottom-left-radius: 20px;
      }
      &.placeholder{
        border-left-color: transparent;
      }
    }
  }
  .content_group{
    border-bottom: 4px solid #f8f8f8;
    .title_group{
      padding: 10px 0;
      .time{
        font-size: 12px;
        color: #999;
      }
    }
    .widget_group{
      padding-bottom: 10px;
      font-size: 14px;
      color: #666;
    }
  }
  .info_group{
    padding: 10px 0;
    .avatar{
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 4px;
    }
    .tag_info{
      @include flex;
      .tag{
        @include bbox;
        padding: 0 7px;
        background: rgba(255, 114, 0, 0.08);
        border-radius: 4px;
        font-size: 12px;
        color: #FF9D4D;
        line-height: 24px;
        margin-right: 10px;
        &.finance {
          color: #D32F2F;
          background-color: #FFEBEE;
        }
        &.privacy{
          color: #8C73F1;
          background-color: rgba(83, 47, 230, 0.08);
        }
        &.draft{
          color: #5EC799;
          background-color: rgba(31, 175, 112, 0.08);
        }
        &.reminder{
          width: 20px;
          height: 20px;
          padding: 0;
          background-image: url(~@/assets/images/task/task_thumbnail_reminder.png);
        }
      }
    }
  }
}
</style>
