var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select_area_page", on: { click: _vm.pageHandler } },
    [
      _c("div", { staticClass: "title_group" }, [
        _c(
          "div",
          {
            staticClass: "select province on cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.step = 1
                _vm.$store.commit("set_city", null)
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.province
                  ? _vm.province.province
                    ? _vm.province.province
                    : "省/直辖市"
                  : "省/直辖市"
              )
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "select city cp",
            class: {
              on: _vm.city != null && _vm.city.city != null,
              selected: _vm.city != null && _vm.city.city != null,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.step = 2
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm.city
                  ? _vm.city.city
                    ? _vm.city.city
                    : "请选择市"
                  : "请选择市"
              )
            ),
          ]
        ),
        _c("div", { staticClass: "select county cp" }, [
          _vm._v(_vm._s(_vm.county)),
        ]),
        _c("div", {
          staticClass: "close cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("closeSelectArea", true)
            },
          },
        }),
      ]),
      _c(
        "div",
        { staticClass: "content_group" },
        [
          _vm.step == 1 && _vm.province_list
            ? _vm._l(_vm.province_list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item cp",
                    class: { select: item.select },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectProvince(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.province))]
                )
              })
            : _vm._e(),
          _vm.step == 2 && _vm.city_list
            ? _vm._l(_vm.city_list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item cp",
                    class: { select: item.select },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.selectCity(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.city))]
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }