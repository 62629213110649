<!--
 * @Author       : Hugo
 * @Date         : 2022-04-22 11:00:25
 * @LastEditTime: 2022-05-16 20:41:21
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Recommend/Modules/AddressBookPrompt/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_recommend_modules_address_book_prompt bbox un_sel"
  >
    <div class="title">通过手机通讯录添加更多妙行联系人</div>
    <div class="subtitle">通过妙行APP，您可以查看在您手机通讯录中已经注册妙行的联系人，并向他们申请好友请求。（此功能需要通过妙行获取通讯录权限）</div>
    <div class="progress_group d-flex align-stretch">
      <template
        v-for="(item,index) in progress_list"
      >
        <div
          :key="index"
          class="progress d-flex flex-column align-center flex-shrink-0 bbox"
        >
          <div class="num d-flex justify-center align-center flex-shrink-0">{{item.progress}}</div>
          <div class="content_group d-flex flex-column align-center bbox">
            <div class="desc flex-shrink-0 d-flex align-center">{{item.descript}}</div>
            <div class="img_group d-flex align-center justify-center">
              <img 
                :src="require(`@/assets/images/recommend/address_book_prompt/progress${item.progress}.png`)"
                alt=""
                class="img"
              >
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="operation_group bbox d-flex justify-space-between align-center">
      <div class="nomore_prompt d-flex align-center cp"
        @click.stop="selected = !selected"
      >
        <div class="select_box"
          :class="{active: selected}"
        ></div>
        <div class="text">不再提醒</div>
      </div>
      <div class="button btn_light"
        @click.stop="doClose"
      >关闭</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      progress_list: [
        {progress: 1, descript: '扫码下载妙行APP'},
        {progress: 2, descript: '允许妙行访问您的通讯录权限'},
        {progress: 3, descript: '根据您的通讯录名单，系统自动将您认识的妙行用户推送给您。'},
        {progress: 4, descript: '选择您希望添加的联系人，发起联系人申请并等待对方通过。'},
      ],
      selected: false,
    }
  },
  methods:{
    doClose(){
      if(this.selected){
        this.$emit('handleSure')
        return
      }
      this.$emit('handleClose')
    }
  }
}
</script>


<style lang="scss" scoped>
.c_recommend_modules_address_book_prompt{
  width: 100%;
  padding: 40px 20px;
  .title{
    font-size: 30px;
    font-weight: bold;
    color: #001224;
    padding-bottom: 10px;
  }
  .subtitle{
    font-size: 16px;
    color: #445566;
    line-height: 28px;
    padding-bottom: 20px;
  }
  .progress{
    flex: 1;
    flex-shrink: 0;
    .num{
      background-image: url(~@/assets/images/recommend/address_book_prompt/num.png);
      width: 34px;
      height: 34px;
      line-height: 34px;
      font-size: 16px;
      color: $main_blue;
      margin-bottom: 20px;
    }
    &:last-child{
      .content_group{
        border-right: none;
      }
    }
    .content_group{
      width: 100%;
      flex: 1;
      border-right: 2px solid #f0f0f0;
      padding: 0 10px;
      .desc{
        height: 57px;
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        margin-bottom: 10px;
        padding: 0 30px;
      }
      .img_group{
        flex: 1;
        max-width: 100%;
        .img{
          max-width: 100%;
        }
      }
    }
  }
  .operation_group{
    width: 100%;
    padding: 30px 20px 0;
    font-size: 14px;
    color: #333;
    .nomore_prompt{
      .select_box{
        @include transition;
        width: 16px;
        height: 16px;
        background-image: url(~@/assets/images/recommend/address_book_prompt/unselected.png);
        &.active{
          background-image: url(~@/assets/images/recommend/address_book_prompt/selected.png);
        }
      }
      .text{
        padding-left: 8px;
      }
    }
    .button{
      width: 108px;
      border-radius: 4px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
