var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_navigation_unit bbox d-flex align-center" },
    [_c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.p_folder.name))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }