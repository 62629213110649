var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_invivation_plugins_poster bbox" }, [
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _c("div", { staticClass: "hyperlink bbox" }, [
      _vm._v(
        "\n    妙行用户 XXX 邀请您注册妙行，请访问 http://www.yourutec.com/xxxxxsdfsfsdfxxx 进入妙行进行注册（有效期至：2022-09-29 14:34），链接72小时内有效，请及时注册。\n  "
      ),
    ]),
    _c("div", { staticClass: "hyperlink_hint bbox" }, [
      _vm._v("*请注意，此访问链接72小时内有效，72小时后点击邀请按钮会重新生成"),
    ]),
    _vm._m(3),
    _c(
      "div",
      { staticClass: "poster_group d-flex align-stretch un_sel" },
      [_c("poster-component", { staticClass: "poster" }), _vm._m(4)],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rebuild_group" }, [
      _c(
        "div",
        { staticClass: "button d-flex align-center rebuild justify-center" },
        [
          _c("img", {
            staticClass: "send_email mr-6",
            attrs: {
              src: require("@/assets/images/invivation_poster/send_email.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "text" }, [_vm._v("生成新链接/海报")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type_group d-flex align-center" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/invivation_poster/key.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "type_title web" }, [
        _vm._v("邀请朋友通过网页注册"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "web_copy_group bbox d-flex justify-space-between align-center",
      },
      [
        _c("div", { staticClass: "text" }, [
          _vm._v("点击【复制内容】，将内容发送给好友"),
        ]),
        _c("div", { staticClass: "button" }, [_vm._v("复制内容")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type_group d-flex align-center" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/invivation_poster/scan.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "type_title img" }, [
        _vm._v("邀请朋友通过手机扫码注册"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "operation_group d-flex flex-column justify-end align-start bbox",
      },
      [
        _c(
          "div",
          { staticClass: "button btn_dark d-flex align-center justify-center" },
          [
            _c("img", {
              staticClass: "icon mr-6",
              attrs: {
                src: require("@/assets/images/invivation_poster/save.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("保存海报")]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "button btn_light d-flex align-center justify-center",
          },
          [
            _c("img", {
              staticClass: "icon mr-6",
              attrs: {
                src: require("@/assets/images/invivation_poster/copy.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "text" }, [_vm._v("复制海报")]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }