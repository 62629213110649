/*
 * @Author       : Hugo
 * @Date         : 2022-04-13 12:09:38
 * @LastEditTime : 2022-04-13 14:25:32
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/share_confirm.js
 * @Description  : 
 * @^_^          : 
 */
import PluginComponent from '@/components/Plugin/Modules/ShareConfirm/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$shareConfirm = (params={}) => {
      // // console.log(obj)
      const {title,list, onSure, onReturn, work} = params
      instance.visible = true
      instance.list = list
      instance.onSure = onSure
      instance.onReturn = onReturn
      instance.work = work ?? ''
      instance.title = title ?? '分享至'
    }
    Vue.prototype.$closeShareConfirm = () => {
      instance.visible = false
    }
  }
}

export default PluginMember