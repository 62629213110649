var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_classes_task_modules_fun_bar_index d-flex justify-space-between bbox align-center",
    },
    [
      _c("div", { staticClass: "origin_group" }, [
        _vm._v(_vm._s(_vm.getSource())),
      ]),
      _c(
        "div",
        { staticClass: "fun_group d-flex align-center" },
        [
          _c("color", {
            staticClass: "h-24 px-10 border-left",
            attrs: { task: _vm.task },
          }),
          _c("privacy", {
            staticClass: "h-24 px-10 border-left border-right",
            attrs: { task: _vm.task },
          }),
          _c("button-group", {
            on: {
              handleCall: _vm.callTask,
              handleUse: _vm.useTask,
              handleSend: _vm.handleSend,
            },
          }),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "click",
                "popper-class": "c_classes_task_modules_fun_bar_index_popover",
              },
            },
            [
              _c("div", { staticClass: "list un_sel" }, [
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    on: {
                      click: function ($event) {
                        return _vm.doShare()
                      },
                    },
                  },
                  [_vm._v("分享")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    on: {
                      click: function ($event) {
                        return _vm.doDelete()
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    on: {
                      click: function ($event) {
                        return _vm.doRecover()
                      },
                    },
                  },
                  [_vm._v("恢复")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item bbox cp",
                    on: {
                      click: function ($event) {
                        return _vm.completeDelete()
                      },
                    },
                  },
                  [_vm._v("彻底删除")]
                ),
              ]),
              _c("template", { slot: "reference" }, [
                _c("div", { staticClass: "menu cp" }),
              ]),
            ],
            2
          ),
          _c("div", {
            staticClass: "close cp",
            on: {
              click: function ($event) {
                return _vm.closeTask()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }