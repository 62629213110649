/*
 * @Author       : Hugo
 * @Date         : 2020-09-10 14:44:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-07 11:13:09
 * @Description  : 
 * @FilePath     : /miaohang/src/utils/plugin/video.js
 */
import VideoComponent from '@/components/Plugin/Video.vue'

const Video = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const VideoConstructor = Vue.extend(VideoComponent);

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new VideoConstructor();

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$openVideo = (obj) => {
      if(obj){
        let {src} = obj;
        instance.src = src;
      }
      // if(obj){
      //   let {title, desc, sure, cancel, onSure, onCancel} = obj
      //   instance.title = title || '提示'
      //   instance.desc = desc || '确定要这么做?'
      //   instance.sure = sure || '确定'
      //   instance.cancel = cancel || '取消'
      //   instance.onSure = onSure
      //   instance.onCancel = onCancel
      // }else{
      //   instance.title = '提示'
      //   instance.desc = '确定要这么做?'
      //   instance.sure = '确定'
      //   instance.cancel = '取消'
      // }
      instance.visible = true
    }
    Vue.prototype.$closeVideo = () => {
      instance.visible = false
    }
  }
}

export default Video