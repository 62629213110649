<template>
   <div
    class="components_popup_record_of_communication_index_page bbox"
  >
    <!-- <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    > -->
      <div class="container bbox d-flex flex-column">
        <div class="header_group  flex-shrink-0">
          <div class="title_group d-flex align-center un_sel justify-center">
            <div class="title">搜索沟通记录</div>
            <div class="close cp"
              @click="doClose();"
            ></div>
          </div>
          <div class="search_group bbox"

          >
            <div class="icon"
              @click="$refs.search.focus();"
            ></div>
            <input type="text"
              class="search bbox"
              :class="{disabled: tab_type === 'picture'}"
              placeholder="搜索..."
              ref="search"
              v-model="search"
              @keydown.enter="handleSearchKeydown($event)"
            >
          </div>
          <div class="tab_group bbox">
            <div class="tab_bar d-flex align-center">
              <div class="tab"
                @click="changeTab('task')"
                :class="{active: tab_type === 'task'}"
               
              >任务模板</div>
              <div class="tab"
                @click="changeTab('text')"
                :class="{active: tab_type === 'text'}"
              >文本消息</div>
              <div class="tab"
                @click="changeTab('file')"
                :class="{active: tab_type === 'file'}"
              >附件</div>
              <div class="tab"
                @click="changeTab('picture')"
                :class="{active: tab_type === 'picture'}"
              >图片</div>
            </div>
          </div>

        </div>
        <div class="main d-flex flex-column">
          <div class="wrapper"
            ref="wrapper"
          >
            <hint
              v-if="!do_search"
            ></hint>
            <template
              v-else
            >
              <task
                ref="task"
                class="bbox section"
                v-if="tab_type === 'task'"
                :keyword="keyword"
                :chat_type="Number(chat_type)"
                :chat_id="Number(chat_id)"
              ></task>
              <text-group
                ref="text"
                class="bbox section"
                v-if="tab_type === 'text'"
                :keyword="keyword"
                :chat_type="Number(chat_type)"
                :chat_id="Number(chat_id)"
              ></text-group>
              <file
                ref="file"
                class="bbox section"
                v-if="tab_type === 'file'"
                :keyword="keyword"
                :chat_type="Number(chat_type)"
                :chat_id="Number(chat_id)"
              ></file>
              <picture-group
                ref="picture"
                class="bbox section"
                v-if="tab_type === 'picture'"
                :keyword="keyword"
                :chat_type="Number(chat_type)"
                :chat_id="Number(chat_id)"
              ></picture-group>
            </template>
          </div>
        </div>

      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Task from './Task/index.vue';
import TextGroup from './Text/index.vue';
import File from './File/index.vue';
import PictureGroup from './Picture/index.vue';
import Hint from './Hint.vue';
export default {
  components: {
    Task,
    TextGroup,
    File,
    PictureGroup,
    Hint,
  },
  data(){
    return {
      index: 1,
      tab_type: 'task',
      search: '',
      do_search: false,
      keyword: '', // 用于传递给子组件
      chat_type: false,
      chat_id: false,
      trigger_loadmore: false,
    }
  },
  watch: {
    search: function(val){
      if(val === ''){
        const {tab_type} = this;
        if(tab_type === 'picture'){
          return;
        }
        this.do_search = false;
        // 任务在清空后搜索全部
        if(tab_type === 'task'){
          this.$nextTick(function(){
            this.doSearch();
          })
        }


      }
    },
    trigger_loadmore: function(val){
      if(val){
        const {tab_type} = this;
        const ref = this.$refs[tab_type];
        ref.handleLoadMore();
      }
    }
  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    const chat_type = this.$tools.getChatType();
    const chat_id = this.$api.moduleTask.getRouteParamsId();
    this.chat_type = chat_type;
    this.chat_id = chat_id;
    this.$refs.wrapper.addEventListener(
      'scroll',
      this.handleScroll,
      false,
    )
    this.doSearch();
  },
  methods: {
    doClose(){
      this.$emit('handleClose');
    },
    changeTab(type){
      this.tab_type = type;
      const {search} = this;
      // 图片直接打开
      if(type === 'picture' || type === 'task'){
        this.doSearch();
      }else{
        // 不是图片 并且没有搜素内容 则提示搜索
        if(search === ''){
          this.do_search = false;
        }else{
          this.doSearch();
        }
      }
    },
    handleSearchKeydown(e){
      const is_chinese = this.$api.handleKeydownChinese(e);
      if(is_chinese){
        return;
      }
      this.doSearch();
    },
    doSearch(){
      const {search} = this;
      // if(search !== ''){
        this.keyword = search;
      // }
      this.do_search = true;
    },
    handleScroll(){
      const {scrollTop, clientHeight, scrollHeight} = this.$refs.wrapper;
      let trigger_loadmore = false;
      if((scrollHeight - scrollTop -clientHeight) < 100){
        trigger_loadmore = true;
      }
      // // console.log(scrollHeight - scrollTop - clientHeight);
      // this.wrapper_top = this.$refs.wrapper.scrollTop;
      this.trigger_loadmore = trigger_loadmore;
    }
    
  }
}
</script>

<style lang="scss" scoped>
.components_popup_record_of_communication_index_page{
  // .mask{
  //   position: fixed;
  //   width: 100%;
  //   height: 100%;
  //   top:0;
  //   left: 0;
  //   background-color: rgba(0,0,0,0.3);
  // }
  width: 100%;
  height: 100%;
  .container{
    width: 100%;
    height: 100%;
    // max-width: 800px;
    // max-height: 800px;
    // border-radius: 4px;
    // box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    // background-color:#fff;
    overflow: hidden;
  }
  .header_group{
    width: 100%;
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    background-color: #f8f8f8;
    width: 100%;
    // height: 100%;
    overflow: hidden;
    border-radius: 0 0 4px 4px;
    padding: 40px 0;
    flex: 1;
    .wrapper{
      @include scrollbar;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .section{
      padding: 0 40px;
    }
  }
  .search_group{
    position: relative;
    width: 100%;
    padding: 0 40px;
    .search{
      @include transition;
      width: 100%;
      outline: none;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      line-height: 30px;
      padding: 0 36px;
      font-size: 14px;
      color: #333;
      @include placeholder(#bbb);
      &:focus{
        border: 1px solid $main_blue;
      }
      &:hover{
        border: 1px solid $main_blue;
      }
      &.disabled{
        border: 1px solid #f0f0f0!important;
      }
    }
    .icon{
      @include background;
      position: absolute;
      top: 50%;
      left: 56px;
      width: 12px;
      height: 12px;
      background-image: url(~@/assets/images/common/search_icon.png);
      transform: translateY(-50%);
    }
  }
  .tab_group{
    padding: 0 40px;
  }
  .tab_bar{
    .tab{
      @include transition;
      cursor: pointer;
      line-height: 50px;
      border-bottom: 3px solid transparent;
      font-size: 14px;
      color: #333;
      margin-right: 80px;
      &:last-child{
        margin-right: 0;
      }
      &.active{
        color: $main_blue;
        border-bottom-color: $main_blue;
      }
    }
  }
  
}
</style>