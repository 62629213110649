import axios from "axios";
import config from "./config/config"; // application/x-www-form-urlencoded
import collocate from "./config/collocate";

const login = {
  // 发送登录短信验证码
  sendLoginPhone(params) {
    // return axios.get('/api/auth/login/v1/sms/send/phone/' + params + "?sign=" + md5(params), config)
  },

  //  用户密码登录验证
  loginForm(params) {
    return axios.post("/project/loginAuth.htm", params, config);
  },
  updatePassword(params) {
    return axios.post("/project/updatePassword.htm", params, config);
  }
};

export default login;
