var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chat_task_title d-flex align-center" },
    [
      _c("title-status-tag", {
        staticClass: "mr-5",
        attrs: {
          size: "16px",
          "process-status": String(_vm.msg.processStatus),
          "level-status": _vm.msg.levelStatus,
        },
      }),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.msg.taskName))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }