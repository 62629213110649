import Qs from "qs";
// import { BASE_URL } from "./baseurl";
export default {
  baseURL: process.env.VUE_APP_BASEURL,
  headers: { "Content-Type": "application/json;charset=UTF-8" },
  params: {},
  paramsSerializer: function(params) {
    return Qs.stringify(params);
  },
  data: {
    // EID:"8484662",
    // Method:"kis.APP003678.ueanalysis.UEStockAnalysis.",
    // NetID:"8484662159954",
    // params: {}
  },
  timeout: 10000,
  withCredentials: false, // default不携带cookie信息
  responseType: "json", // default

  // onUploadProgress: function (progressEvent) {
  //     // Do whatever you want with the native progress event
  // },
  onDownloadProgress: function() {
    // Do whatever you want with the native progress event
  },
  maxContentLength: 20000,
  validateStatus: function(status) {
    return status >= 200 && status < 300; // default
  },
  maxRedirects: 5000 // default
};
