<!--
 * @Author       : Hugo
 * @Date         : 2021-11-29 12:02:24
 * @LastEditTime : 2021-12-01 16:43:16
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/At/All.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_at_all_index un_sel bbox cp"
    @click.stop="clickAll();"
  >
    <div class="page_group d-flex align-center bbox">
      <div class="icon"></div>
      <div class="name pl-16">所有{{task_id ? '参与人' : '组员'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task_id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    clickAll(){
      this.$emit('handleAll');
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_at_all_index{
  width: 100%;
  .page_group{
    width: 100%;
  }
  .icon{
    @include background(41px, 41px);
    background-image: url(~@/assets/images/pop_up/at/all.png);
    width: 41px;
    height: 41px;
  }
  .name{
    font-size: 14px;
    color: #333;
  }
}
</style>
