<!--
 * @Author       : Hugo
 * @Date         : 2022-04-07 16:00:35
 * @LastEditTime : 2022-04-11 16:02:10
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Navigation/Unit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_navigation_unit bbox d-flex align-center"
  >
    <div class="name">{{p_folder.name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    p_folder: {
      type: Object,
      default: undefined,
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_navigation_unit{
  
}
</style>
