import global from '@/api/global'
class PackageSelectTask{
  shoppingTask = undefined
  constructor (params = {}) {
    const {
      type,
      origin,
      search,
      // 三种默认状态
      sellList,
      shoppingPackage,
      shoppingTask,
      // 教学包任务列表
      packageTaskList,
      // 搜索列表
      searchSellList,
      searchPackageList,
      searchShoppingTask,
      searchPackageTaskList,
      // 更多
      moreSellList,
      moreShoppingPackage,
      moreShoppingTask,
      morePackageTaskList,
      moreSearchSellList,
      moreSearchShoppingPackage,
      moreSearchShoppingTask,
      moreSearchPackageTaskList,
      // 已选择教学包
      pPackage,
      // 加载状态
      loading,
      // 任务列表
      originTaskList,
      taskList,
      selectedList
    } = params
    this.type = type ?? 'package'
    this.origin = origin ?? 'sell'
    this.search = search ?? ''
    this.sellList = sellList ?? []
    this.shoppingPackage = shoppingPackage ?? []
    this.shoppingTask = shoppingTask ?? []
    this.packageTaskList = packageTaskList ?? []
    this.searchSellList = searchSellList ?? []
    this.searchPackageList = searchPackageList ?? []
    this.searchShoppingTask = searchShoppingTask ?? []
    this.searchPackageTaskList = searchPackageTaskList ?? []
    this.moreSellList = moreSellList
    this.moreSearchSellList = moreSearchSellList
    this.moreShoppingPackage = moreShoppingPackage
    this.morePackageTaskList = morePackageTaskList
    this.moreSearchShoppingPackage = moreSearchShoppingPackage
    this.moreShoppingTask = moreShoppingTask
    this.moreSearchShoppingTask = moreSearchShoppingTask
    this.moreSearchPackageTaskList = moreSearchPackageTaskList
    this.pPackage = pPackage ?? undefined
    this.loading = loading ?? false
    this.taskList = taskList ?? []
    this.selectedList = selectedList ?? []
    this.originTaskList = originTaskList ?? []
  }

  initList = async () => {
    // console.log(this, this.sellList)
    const { origin, type, sellList } = this
    if (origin === 'sell') {
      // 获取我销售的
      if (!sellList || sellList.length === 0) {
        const res = await this.getSellList()
        return res
      }
      return
    }
    if (origin === 'shopping') {
      if (type === 'package') {
        // 获取商城教学包列表
      }
      if (type === 'task') {
        // 获取商城模板
      }
    }
  }


  getSellList = async () => {
    if (this.loading) {
      return
    }
    const { search, sellList, searchSellList } = this
    const data = {
      keyword: search,
      searchtype: 3,
    }
    if (search === '') {
      if (sellList && sellList.length) {
        data.startId = sellList[sellList.length - 1].id
      }
    }
    if (search !== '') {
      if (searchSellList && searchSellList.length) {
        data.startId = searchSellList[searchSellList.length - 1].id
      }
    }
    this.loading = true
    const res = await global.doPost({
      url: '/projectTask/taskByparamList',
      data
    })
    this.loading = false
    return res
  }

  getShoppingPackage = async () => {
    // console.log('getShoppingPackage')
    if (this.loading) {
      return
    }
    const { search } = this
    const listFieldName = this.getListFieldName()
    const data = {
      keyword: search,
      start: this[listFieldName].length
    }
    this.loading = true
    const res = await global.doPost({
      url: '/projectShop/shopPackages',
      data
    })
    this.loading = false
    return res
  }
  getShoppingTask = async () => {
    if (this.loading) {
      return
    }
    const { search } = this
    const listFieldName = this.getListFieldName()
    const data = {
      keyword: search,
      start: this[listFieldName].length
    }
    this.loading = true
    const res = await global.doPost({
      url: '/projectShop/shopTasks',
      data
    })
    this.loading = false
    return res
  }
  getPackageTaskList = async () => {
    if (this.loading) {
      return
    }
    const { search, pPackage } = this
    const listFieldName = this.getListFieldName()
    const data = {
      packageId: pPackage.id,
      keyword: search
    }
    if (this[listFieldName] && this[listFieldName].length) {
      data.startId = this[listFieldName][this[listFieldName].length - 1].id
    }
    // if (search === '') {
    //   if (packageTaskList && packageTaskList.length) {
    //     data.startId = packageTaskList[packageTaskList.length - 1].id
    //   }
    // }
    // if (search !== '') {
    //   if (packageTaskList && packageTaskList.length) {
    //     data.startId = packageTaskList[packageTaskList.length - 1].id
    //   }
    // }
    this.loading = true
    const res = await global.doPost({
      url: '/projectTeach/queryPackageTaskList',
      data
    })
    this.loading = false
    return res
  }

  getListFieldName = () => {
    const {
      search,
      origin,
      type,
      pPackage
    } = this
    let listFieldName = ''
    if (origin === 'sell') {
      listFieldName = 'sellList'
      if (search !== '') {
        listFieldName = 'searchSellList'
      }
    }
    if (origin === 'shopping') {
      if (type === 'package') {
        listFieldName = 'shoppingPackage'
        if (search !== '') {
          listFieldName = 'searchPackageList'
        }
        if (pPackage) {
          listFieldName = 'packageTaskList'
          if (search !== '') {
            listFieldName = 'searchPackageTaskList'
          }
        }
      }
      if (type === 'task') {
        listFieldName = 'shoppingTask'
        if (search !== '') {
          listFieldName = 'searchShoppingTask'
        }
      }
    }
    return listFieldName
  }
  getMoreFieldName = () => {
    const {
      search,
      origin,
      type,
      pPackage
    } = this
    let moreFieldName = ''
    if (origin === 'sell') {
      moreFieldName = 'moreSellList'
      if (search !== '') {
        moreFieldName = 'moreSearchSellList'
      }
    }
    if (origin === 'shopping') {
      if (type === 'package') {
        moreFieldName = 'moreShoppingPackage'
        if (search !== '') {
          moreFieldName = 'moreSearchShoppingPackage'
        }
        if (pPackage) {
          moreFieldName = 'morePackageTaskList'
          if (search !== '') {
            moreFieldName = 'moreSearchPackageTaskList'
          }
        }
      }
      if (type === 'task') {
        moreFieldName = 'moreShoppingTask'
        if (search !== '') {
          moreFieldName = 'moreSearchShoppingTask'
        }
      }
    }
    return moreFieldName
  }

  getAddTaskId = () => {
    const { originTaskList, taskList } = this
    let addId = ''
    // 查找不在【原始列表】中的【确认数据】
    taskList.forEach((unit) => {
      const inOrigin = originTaskList.find((item) => {
        return item.id === unit.id
      })
      if (!inOrigin) {
        addId += `${addId ? ',' : ''}${unit.id}`
      }
    })
    return addId
  }

  getDeleteTaskId = () => {
    const { originTaskList, taskList } = this
    // console.log({ originTaskList, taskList })
    let deleteId = ''
    // 查找不在【确认列表】中的【原始数据】
    originTaskList.forEach((unit) => {
      const inOrigin = taskList.find((item) => {
        // console.log(item.id, unit.id)
        return item.id === unit.id
      })
      if (!inOrigin) {
        deleteId += `${deleteId ? ',' : ''}${unit.id}`
      }
    })
    return deleteId
  }
}

export default PackageSelectTask
