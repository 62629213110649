<!--
 * @Author       : Hugo
 * @Date         : 2022-04-20 13:36:48
 * @LastEditTime : 2022-04-21 13:52:30
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Consulting/Modules/Common/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_consulting_modules_common bbox"
  >
    <div class="hint">您好，请确认并填写以下信息，方便工作人员与您及时联系。</div>
    <div class="line_group bbox d-flex align-start"
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">真实姓名</div>
      <input type="text" class="input"
        v-model="name"
        placeholder="请输入真实姓名"
        :disabled="readonly"
      >
    </div>
    <div class="line_group bbox d-flex align-start "
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">联系方式</div>
      <input type="text" class="input"
        v-model="phone"
        maxlength="11"
        placeholder="请输入联系方式"
        :disabled="readonly"
      >
    </div>
    <div class="line_group bbox d-flex align-start "
      v-show="need_code"
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">短信验证码</div>
      <input type="text" class="input"
        v-model="code"
        placeholder="请输入短信验证码"
      >
      <div class="button verify flex-shrink-0 un_sel"
        :class="{active: countdown!==0}"
        @click.stop="getCode()"
      >{{countdown !== 0 ? `已发送 ${countdown}s`:'发送验证码'}}</div>
    </div>
    <div class="line_group bbox d-flex align-start "
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">您的职位</div>
      <el-select
        v-model="identities"
        clearable
        :disabled="readonly"
        placeholder="请选择您的职位"
        class="select_group"
      >
        <el-option
          v-for="item in identities_list"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="line_group bbox d-flex align-start "
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">机构名称</div>
      <input type="text" class="input"
        v-model="school"
        :disabled="readonly"
        placeholder="请输入机构名称"
      >
    </div>
    <div class="line_group bbox d-flex align-start "
      :class="{readonly}"
    >
      <div class="key flex-shrink-0">{{content_key}}</div>
      <el-input
        type="textarea"
        :disabled="readonly"
        placeholder="请输入内容"
        v-model="content"
        maxlength="80"
        show-word-limit
        resize="none"
        class="textarea"
      >
      </el-input>
    </div>
    <div class="opertaion_group">
      <div class="button btn_dark"
        @click.stop="touchSubmit()"
      >{{submit_again?'再次提交': submit_text}}</div>
    </div>
  </div>
</template>

<script>
import regexp from '@/utils/regexp'
import global from '@/api/global.js'
import tools from '@/api/tools.js'
import {PEnquire} from '@/types'
export default {
  data(){
    return{
      identities: undefined,
      identities_list: [
        {value: 1, label: '教师'},
        {value: 2, label: '学生'},
        {value: 3, label: '职工'},
        {value: 4, label: '其他'},
      ],
      content: '',
      p_enquire: undefined,
      name: '',
      phone: '',
      school: '',
      code: '',
      submit_again: false,
      countdown: 0,
    }
  },
  computed: {
    show_code(){
      return true
    },
    content_key(){
      const {enquireType} = this
      if(enquireType === 2){
        return '申请描述'
      }
      if(enquireType === 3){
        return '需求描述'
      }
      if(enquireType === 4){
        return '想法描述'
      }
      return '简短描述'
    },
    submit_text(){
      const {enquireType} = this
      if(enquireType === 2){
        return '提交申请'
      }
      if(enquireType === 3){
        return '提交需求'
      }
      if(enquireType === 4){
        return '提交想法'
      }
      return '提交'
    },
    need_code(){
      const {p_enquire, phone} = this
      const user = tools.getCookie('user')
      if(!this.testPhone()){
        return false
      }
      return Boolean(Number(phone) !== Number(user?.phone) && Number(phone) !== Number(p_enquire?.phone))
    },
    readonly(){
      const {submit_again} = this
      return submit_again
    }
  },
  props: {
    subtitle: {
      type: String,
      default: undefined,
    },
    enquireType: {
      type: Number,
      default: undefined,
    },
    paramId: {
      type: Number,
      default: undefined,
    },

  },
  mounted(){
    this.init()
  },
  methods: {
    async init(){
      this.getConsulting()
    },
    async getConsulting(){
      const {paramId, enquireType} = this
      const res = await global.doPost({
        url: '/projectTeach/queryEnquire',
        data: {
          paramId, enquireType
        }
      })
      if(res.message === 'success'){
        if(res.contents && res.contents.PEnquire){
          this.$set(this, 'p_enquire', new PEnquire(res.contents.PEnquire))
          this.submit_again = true;
        }
      }
      this.initData()

      // global.doPost({
      //   url: '/projectTeach/addEnquire',
      //   data: {
      //     name: 'test',
      //     phone: '17711111111',
      //     identities: 3,
      //     school: 'school',
      //     content: 'content',
      //     paramId: undefined,
      //     enquireType: 2,
      //     code: undefined
      //   }
      // })
    },
    initData(){
      const {p_enquire} = this
      const {content,
      identities,
      name,
      phone,
      school,} = p_enquire || {}
      const user = tools.getCookie('user')
    
      this.content    = content ?? ''
      this.identities = identities
      this.name       = name ?? ''
      this.phone      = phone ?? user.phone
      this.school     = school ?? ''
    },
    async touchSubmit(){
      const {submit_again, p_enquire, content, identities, name, phone, school, code, need_code, enquireType, paramId} = this
      if(submit_again){
        this.submit_again = false
        return
      }
      if(!this.testPhone()){
        this.$notice({desc:'请输入正确的手机号'})
        return
      }
      if(name.trim() === ''){
        this.$notice({desc:'请输入真实姓名'})
        return
      }
      if(!identities){
        this.$notice({desc:'请选择职位'})
        return
      }
      if(school.trim() === ''){
        this.$notice({desc:'请输入机构名称'})
        return
      }
      if(content.trim() === ''){
        this.$notice({desc:'请输入描述'})
        return
      }
      if(need_code && code.trim() === ''){
        this.$notice({desc:'请输入验证码'})
        return
      }
      let url = '/projectTeach/addEnquire'
      const data = {
        name,
        phone,
        identities,
        school,
        content,
        enquireType,
        paramId,
      }
      if(need_code){
        data.code = code
      }
      if(p_enquire){
        url = '/projectTeach/editEnquire'
        data.id = p_enquire.id
        delete data.enquireType
        delete data.paramId
      }
      const res = await global.doPost({
        url,
        data,
      })
      if(res.message === 'success'){
        this.$notice({desc: '提交成功', type: 'success'})
        this.handleClose()
      }else{
        this.$noitce({desc: res.describe})
      }
      
    },
    async getCode(){
      const {phone} = this
      if(this.countdown !== 0){
        this.$notice({desc:'已发送,请稍候再试'})
        return
      }
      if(!this.testPhone()){
        this.$noitce({desc: '请输入正确的手机号'})
        return
      }
      this.countdown = 60
      const timer = setInterval(()=>{
        this.countdown -=1
        if(this.countdown === 0){
          clearInterval(timer)
        }
      }, 1000)
      await global.doPost({
        url: '/project/sendAuthcode',
        data: {
          loginName: phone,
          type: 3,
          flag: 1,
        }
      })
    },
    testPhone(){
      const {phone} = this
      if(regexp.phone.test(phone)){
        return true
      }
      return false
    },
    handleClose(){
      this.$emit('handleClose')
    },
    

  },
  
}
</script>

<style lang="scss" scoped>
.c_consulting_modules_common{
  width: 100%;
  padding: 16px 40px 0;
  .hint{
    font-size: 14px;
    padding-bottom: 16px;
  }
  .line_group{
    padding-bottom: 20px;
    .key{
      width: 80px;
      font-size: 14px;
      color: #333;
      line-height: 36px;
    }
    &.readonly{
      .input{
        border-color: transparent;
        background-color: transparent;
      }
    }
    .input{
      @include placeholder(#bbb);
      font-size: #333;
      font-size: 14px;
      line-height: 34px;
      flex: 1;
      box-sizing: border-box;
      border: 1px solid #E5E7EA;
      padding: 0 10px;
      outline: none;
      background-color: #FBFBFB;
    }
    .verify{
      @include transition;
      width: 100px;
      text-align: center;
      font-size: 14px;
      color: $main_blue;
      border: 1px solid $main_blue;
      line-height: 34px;
      border-radius: 4px;
      margin-left: 10px;
      &.active{
        color: #bbb;
        border-color: #E5E7EA;
      }
    }
  }
  .opertaion_group{
    padding-bottom: 30px;
    padding-top: 20px;
    .button{
      margin:0 auto;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      width: 240px;
      line-height: 40px;
    }
  }
  
  
}
</style>
<style lang="scss">
.c_consulting_modules_common{
  .readonly{
    .select_group{
      .el-input{
        &.is-focus{
          border-color: transparent!important;
        }
      }
      .el-input__inner{
        background-color: transparent!important;
        border-color: transparent!important;
      }
    }
    .textarea{
      .el-textarea__inner{
        border-color: transparent!important;
        background-color: transparent!important;
      }
    }
  }
  .select_group{
    width: 100%;
    .el-input{
      &.is-focus{
        border-color: #E5E7EA!important;
      }
    }
    .el-input__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      color: #333!important;
      background-color: #FBFBFB!important;
    }
    .el-input.is-disabled .el-input__inner{
      cursor: inherit!important;
    }
  }
  .textarea{
    .el-textarea__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      color: #333!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      font-family: 'MicroSoft YaHei'!important;
      line-height: 20px!important;
      font-size: 14px!important;
      padding-top: 8px!important;
      padding-bottom: 8px!important;
      background-color: #FBFBFB!important;
    }
  }
}
</style>
