/*
 * @Author       : Hugo
 * @Date         : 2022-01-06 15:14:45
 * @LastEditTime : 2022-06-08 10:04:36
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/scan_code.js
 * @Description  : 
 * @^_^          : 
 */
import ScanCodeComponent from '@/components/Plugin/Modules/ScanCode/index.vue';

const ScanCode = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ScanCodeConstructor = Vue.extend(ScanCodeComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ScanCodeConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$scan_code = (params={}) => {
      const {title, order, onSuccess, onFail, onCancel, onCancelApply, onClose} = params;
      if(title !== undefined){
        instance.title   = title;
      }
      instance.order = order;
      instance.onSuccess = onSuccess;
      instance.onFail = onFail;
      instance.onCancel = onCancel;
      instance.onCancelApply = onCancelApply;
      instance.onClose = onClose;
      instance.visible = true;
      // duration = duration || 1500; // 默认1500关闭
      // setTimeout(()=>{
      //   instance.visible = false;
      // },duration)
    }
    Vue.prototype.$close_scan_code = () => {
      instance.visible = false;
    }
  }
}

export default ScanCode