<!--
 * @Author       : Hugo
 * @Date         : 2020-09-10 14:19:39
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-04-26 14:12:57
 * @Description  : 确认
 * @FilePath     : /miaohang/src/components/Plugin/MessageBox.vue
-->
<template>
  <transition name="fade">
    <div class="plugin_confirm" v-if="visible" @click="doSure"
      :style="{'z-index': zindex}"
    >
      <div class="confirm_group" @click.stop="stopPropagation();">
        <div class="title">{{title}}</div>
        <div class="desc">{{desc}}</div>
        <div class="fun_group">
          <div class="btn sure" @click.stop="doSure">{{sure}}</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      title: 'Title',
      desc: 'A description',
      sure: '确定',
      cancel: '取消',
      onSure: null,
      zindex: 2000,
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.zindex = this.$utils.getZIndex();
      }
    },
  },
  methods:{
    doSure(){
      if(this.onSure){
        this.onSure();
      }
      this.visible = false;
    },
    stopPropagation(){
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
.plugin_confirm{
  @include flex;
  justify-content: center;
  position: fixed;
  top:0 ;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 999999999;
  .confirm_group{
    padding: 30px 40px;
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    min-width: 350px;
    .title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      padding-bottom: 28px;
    }
    .desc{
      margin: 0 auto;
      // @include text_justify;
      text-align: center;
      max-width: 350px;
      font-size: 16px;
      color: #333333;
      line-height: 24px;
      padding-bottom: 30px;
      // text-align-last: left;
      font-weight: normal;
    }
    .fun_group{
      @include flex;
      @include bbox;
      justify-content: center;
      .btn{
        min-width: 160px;
        line-height: 50px;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        &.sure{
          background: $main_blue;
          color: #fff;
          margin-right: 10px;
        }
        &.cancel{
          background-color: rgba(0, 84, 167, 0.1);
          color: $main_blue;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>

