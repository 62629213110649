<template>
  <div
    class="components_task_main_linebar_task_title_page bbox d-flex align-center"
    v-if="task"
  >
    <div class="icon flex-shrink-0"></div>
    <input
      type="text"
      class="title"
      placeholder="输入任务标题"
      v-model="task.name"
    >
  </div>
</template>
<script>
import store from '@/store'
export default {
  props:{
    data: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    task(){
      const {data} = this
      const {task} = data ?? {}
      return task
    },
    // task_config(){
    //   return this.$store.state.task_config;
    // },
    // title_status(){
    //   const {editable} = this;
    //   const {call} = this.task_config;
    //   if(call){
    //     return true;
    //   }
    //   if(editable){
    //     return true;
    //   }
    //   return false;
    // }
  },
  mounted(){
    // // console.log(this.task);
    // const {task} = this;
    // const {name} = task.task;
    // // // console.log(name);
    // if(name){
    //   this.$store.commit('set_task_config', Object.assign(
    //     {},
    //     this.$store.state.task_config,
    //     {task_name:name}
    //   ))
    // }

  }
}
</script>
<style lang="scss" scoped>
.components_task_main_linebar_task_title_page{
  width: 100%;
  background-color: #fff;
  .icon{
    @include background;
    width:24px;
    height: 24px;
    background-image: url(~@/assets/images/task/task_title_icon.png);
    margin-right: 16px;
  }
  .title{
    @include placeholder(#acacac, bold);
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
}
</style>