<template>
  <div
    class="components_pop_up_common_wrapper"
  >
    <div
      class="mask d-flex align-center justify-center"
      @click.stop="handleClickWrapper"
      :style="{zIndex: index}"
    >
      <div
        class="container bbox d-flex flex-column"
        @click.stop="stopPropagation($event)"
        :style="{
          height,
          width,
          'max-height'      : max_height,
          'max-width'       : max_width,
          'background-color': transparent ? 'transparent': '#fff',
          'box-shadow'      : transparent ? 'none'       : box_shadow,
          'border-radius'   : `${border_radius}`,
          overflow
        }"  
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    max_width: {
      type: String,
      default: '80%',
    },
    max_height: {
      type: String,
      default: '90%',
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    border_radius: {
      type: String,
      default: '4px',
    },
    overflow: {
      type: String,
      default: 'unset'
    },
    wrapperClose: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      index: 2000,
      box_shadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.1)'
    }
  },
  mounted() {
    this.$nextTick(function(){
      this.index = this.$utils.getZIndex()
    })
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation()
    },
    handleClickWrapper() {
      const { wrapperClose } = this
      if (wrapperClose) {
        this.$emit('handleClose')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_common_wrapper{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    @include hidescrollbar;
    box-shadow:0 0 30px 0 rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
}
</style>