var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.handleResize,
          expression: "handleResize",
        },
      ],
      ref: "page",
      staticClass:
        "components_task_main_linebar_task_user_page bbox d-flex align-center flex-wrap align-content-center",
      attrs: { id: "components_task_main_linebar_task_user_page" },
    },
    [
      _c("div", { staticClass: "icon flex-shrink-0" }),
      _c(
        "div",
        { staticClass: "list flex-shrink-0 d-flex align-center" },
        [
          _vm._l(_vm.list, function (item, index) {
            return [
              index < _vm.max_avatar_number
                ? _c("div", { key: index, staticClass: "avatar_group" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                        alt: "",
                      },
                    }),
                    0 && item.readed
                      ? _c("div", { staticClass: "readed" }, [_vm._v("已读")])
                      : _vm._e(),
                    _c("div", { staticClass: "hint" }, [
                      _vm._v(_vm._s(_vm.priorityName(item))),
                    ]),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm.is_more
        ? _c("div", {
            staticClass: "more flex-shrink-0 cp",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.openAllTaskUsers.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "add flex-shrink-0",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchAddUser.apply(null, arguments)
            },
          },
        },
        [_c("div", { staticClass: "hint" }, [_vm._v("点击添加参与人")])]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }