var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_popup_record_of_communication_picture_picture_page bbox",
    },
    [
      _c("div", { staticClass: "image_group" }, [
        _c(
          "div",
          { staticClass: "image" },
          [
            _c("el-image", {
              staticClass: "img",
              attrs: {
                fit: "cover",
                src: `${_vm.$img_url}${_vm.item.url}@!small200`,
                "preview-src-list": [`${_vm.$img_url}${_vm.item.url}`],
                lazy: "",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "mask_group bbox p-10 d-flex flex-column justify-space-between align-start",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.item.previewFile({ type: 1 })
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "task_name cp",
                domProps: { textContent: _vm._s(`任务: ${_vm.item.taskname}`) },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.item.openTask({ config: { edit: true } })
                  },
                },
              },
              [_vm._v("任务：Apple公司年会公司年会 >")]
            ),
            _c(
              "div",
              {
                staticClass: "fun_group d-flex align-end justify-space-between",
              },
              [
                _c("div", { staticClass: "size" }, [
                  _vm._v(_vm._s(_vm.item.formatFilesize())),
                ]),
                _c("div", {
                  staticClass: "download cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.item.download({ type: 1 })
                    },
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "picture_name bbox px-5 cp",
          domProps: { textContent: _vm._s(_vm.item.filename) },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.item.previewFile({ type: 1 })
            },
          },
        },
        [_vm._v("北京北北京奥林北京北北京奥林奥北京北北京奥林奥.jpg")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }