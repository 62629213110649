var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_sketch_page" }, [
    _vm.list && _vm.list.length
      ? _c(
          "div",
          { staticClass: "list d-flex align-center flex-wrap" },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: item.name,
                          placement: "bottom",
                        },
                      },
                      [
                        _c("el-image", {
                          staticClass: "avatar",
                          attrs: {
                            src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                            fit: "cover",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    (!_vm.list || !_vm.list.length) && _vm.msg.content
      ? _c(
          "div",
          {
            staticClass: "empry",
            domProps: { textContent: _vm._s(_vm.msg.content) },
          },
          [_vm._v("\n    " + _vm._s(_vm.msg.content) + "\n  ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }