/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 14:21:19
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-06-15 10:33:26
 * @Description  : 公共MUTATION
 * @FilePath     : /miaohang/src/store/mutations.js
 */ 
import State from '@/store/state';
let mutationObj = {};
for(let i in State){
  mutationObj[`set_${i}`] = (state, payload) => {
    state[i] = payload;
  }
}
// 自定义
let customMutations = {
  /**
   * 关闭所有弹出框
   * @param {*} state 
   * @param {*} payload 
   */
  close_all_popover(state, payload){
    state.close_all_popover = payload;
  },
  /**
   * 登录初始化数据
   * 登录后调用
   */
  loginInit(state, payload){
    // console.log(state);
    // console.log('登录初始化');
    // 需要初始化的字段
    let initObj = [
      'message_list_data',
      'contact_data',
      'workteam_data',
      'message_box_list',
      'message_box_unit',
      'friends_list',
      'friends_list_group',
      'workteam_list',
      'edit_task_id',
      'edit_task_data',
      'edit_task_data_list',
      'edit_task_users',
      'task_log',
      'my_task_list',
      'message_list_data',
      'dialog_user_list',
      'message_list',
      'chat_id',
      'switch_task_box',
    ]
    for(let i in initObj){
      state[initObj[i]] = null;
    }
    // 需要初始化的子模块变量[false]
    const modulesObjToFalse = [
      {
        modules: 'common',
        fields: [
          'search_switch',
        ]
      }
    ]
    for(let i in modulesObjToFalse){
      for(let k in modulesObjToFalse[i].fields){
        state[modulesObjToFalse[i].modules][modulesObjToFalse[i].fields[k]] = false;
      }
    }
   
  },
}
const mutations = Object.assign(mutationObj, customMutations);
// const mutations= {
//   /**
//    * 设置添加好友弹窗
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_add_contact_to_dialog_box(state, payload){
//     state.add_contact_to_dialog_box = payload;
//   },
//   set_add_contact_dialog_box_type(state, payload){
//     state.add_contact_dialog_box_type = payload;
//   },
//   /**
//    * 打开/关闭沟通记录
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_communication_record_box(state, payload){
//     state.communication_record_box = payload;
//   },
//   /**
//    * 设置联系人信息
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_contact_data(state, payload){
//     state.contact_data = payload;
//   },
//   /**
//    * 设置工作组信息
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_workteam_data(state, payload){
//     state.workteam_data = payload;
//   },
//  
//   /************************ START 公共数据 ***********************/
  
//   /**
//    * 设置消息盒子列表
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_message_box_list(state, payload){
//     state.message_box_list = payload;
//   },
//   /**
//    * 设置消息盒子(单项)
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_message_box_unit(state, payload){
//     state.message_box_unit = payload;
//   },
//   /**
//    * 设置好友列表
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_friends_list(state, payload){
//     state.friends_list = payload;
//   },
//   /**
//    * 设置好友列表(按首字母分组)
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_friends_list_group(state, payload){
//     state.friends_list_group = payload;
//   },
//   /**
//    * 设置工作组
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_workteam_list(state, payload){
//     state.workteam_list = payload;
//   },
//   /**
//    * 设置打开关闭编辑模板
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_edit_task_box(state, payload){
//     state.edit_task_box = payload;
//   },
//   /**
//    * 设置任务id
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_edit_task_id(state, payload){
//     state.edit_task_id = payload;
//   },
//   /**
//    * 设置任务数据
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_edit_task_data(state, payload){
//     state.edit_task_data = payload;
//   },
//   /**
//    * 设置任务数据(列表)
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_edit_task_data_list(state, payload){
//     state.edit_task_data_list = payload;
//   },
//   /**
//    * 任务记录
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_task_log(state, payload){
//     state.task_log = payload;
//   },
//   /**
//    * 最近模板列表
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_my_task_list(state, payload){
//     state.my_task_list = payload;
//   },
//   /**
//    * 当前任务用户
//    */
//   set_edit_task_users(state, payload){
//     state.edit_task_users = payload;
//   },
//   /**
//    * 消息列表
//    * 包含dialog_user_list和message_list
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_message_list_data(state, payload){
//     state.message_list_data = payload;
//   },
//   /**
//    * 会话用户列表
//    */
//   set_dialog_user_list(state, payload){
//     state.dialog_user_list = payload;
//   },

//   /**
//    * 消息列表
//    */
//   set_message_list(state, payload){
//     state.message_list = payload;
//   },

//   /**
//    * 新的申请
//    * @param {*} state 
//    * @param {*} payload 
//    */
//   set_new_apply(state, payload){
//     state.new_apply = payload;
//   },

//   // 当前会话id
//   set_chat_id(state, payload){
//     state.chat_id = payload;
//   }


//   /************************ END 公共数据 ***********************/
// }

export default mutations;