/*
 * @Author       : Hugo
 * @Date         : 2022-03-11 16:51:53
 * @LastEditTime: 2022-05-19 15:30:24
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/utils/plugin/modules/task.js
 * @Description  : 
 * @^_^          : 
 */
import PluginComponent from '@/components/Plugin/Modules/NotMember/index.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor();
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openNotMember = (params={}) => {
      // // console.log(obj)
      const {title, hint} = params ?? {}
      instance.title = title ?? '此功能为VIP专享权益'
      instance.hint = hint ?? ''
      instance.visible = true;
    }
    Vue.prototype.$closeNotMember = () => {
      instance.visible = false;
    }
  }
}

export default PluginMember;