import vue from '@/main';

class DeleteReminder{
  main = async (params) => {
    const {id} = params || {};
    const res = await vue.$global.doPost({
      url: '/remind/deleteRemind',
      data: {remindId: id},
    })
    return res;

  }
}

const deleteReminder = new DeleteReminder();

export default deleteReminder.main;