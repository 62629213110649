import vue from '@/main';
import {PChatDetail, PMessageBox} from '@/types';
class Dialog{
  /**
   * 获取聊天用户列表
   * @param {Object} params 
   * @returns 
   */
  getDialogUsers = async (params={}) => {
    const {chat_id, chat_type, loading} = params;
    const {dialog_users} = vue.$store.state;
    if(chat_id === dialog_users.chat_id && chat_type === dialog_users.chat_type && dialog_users.list && dialog_users.list.length){
      return dialog_users;
    }else{
      console.log({chat_id, chat_type})
      if(chat_type == 1){
        let res = await vue.$global.doPost({
          url: 'projectFriends/chatUserList',
          data: {chatId: chat_id},
          openNotice: false,
          loading,
        })
        if(res.message === 'success'){
          if(res.contents && res.contents.list){
            let new_dialog_users = {
              chat_id,
              chat_type,
              list: res.contents.list
            }
            vue.$store.commit('set_dialog_users', new_dialog_users);
            return new_dialog_users;
          }
        }
      }
      if(chat_type == 0){
        let res = await vue.$global.doPost({
          url: 'project/getUserById',
          data: {userId: chat_id},
          openNotice: false,
        })
        if(res.message === 'success'){
          if(res.contents && res.contents.user){
            let new_dialog_users = {
              chat_id,
              chat_type,
              list: [
                vue.$tools.getCookie('user'),
                res.contents.user,
              ]
            }
            vue.$store.commit('set_dialog_users', new_dialog_users);
            return new_dialog_users;
          }
        }
      }
    }
    return false;
  }

  changeDoNotDistrub = async (params) => {
    const {chatId, chatType, isdisturb: type} = params || {};
    if(!chatId){
      return false;
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/setIsdisturb',
      data: {
        chatId,
        chatType,
        type
      },
      openNotice: false,
    })
    if(res.message === 'success'){
      // 联动
      // Store
      //  chat_detail
      //  message_box_list
      const {state, commit} = vue.$store;
      const {chat_detail, message_box_list} = state;
      commit('set_chat_detail', new PChatDetail(Object.assign(
        {},
        chat_detail,
        {isdisturb: type},
      )))
      const message_box_index = message_box_list.findIndex(item=> {
        return item.chatId === chatId && item.chatType === chatType;
      })
      // console.log(message_box_index);
      if(message_box_index !== -1) {
        // message_box_list[message_box_index] = new PMessageBox(Object.assign(
        //   {},
        //   message_box_list[message_box_index],
        //   {isdisturb: type},
        // ))
        vue.$set(message_box_list[message_box_index], 'isdisturb', type);
      }
      
    }
    return res;
  }

  getChatDetail = async (params) => {
    const {chatId, chatType} = params || {};
    let data = {
      chatType,
      chatId,
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/chatDetail',
      data,
      openNotice: false,
    })
    if(res.message === 'success'){
      if(res.contents.PChatDetail){
        return new PChatDetail(res.contents.PChatDetail);
      }
    }
    return false;
  }
}

const dialog = new Dialog();
export default dialog;
