class FileData {
  constructor (params) {
    const {
      file,
      progress,
      previewUrl,
      uploading,
      url,
      failed,
      success,
      noUpload,
      width,
      height,
    } = params
    this.file = file
    this.progress = progress ?? 0
    this.previewUrl = previewUrl
    this.uploading = uploading ?? false
    this.url = url ?? ''
    this.failed = failed ?? false
    this.success = success ?? false
    this.noUpload = noUpload ?? false
    this.width = width
    this.height = height

  }
}

export default FileData