<template>
  <div
    class="list_group d-flex"
  >
    <div
      class="icon flex-shrink-0 pic"
      @click.stop="doPreview"
    ></div>
    <div
      class="info_group d-flex flex-column justify-space-between align-start"
      @click.stop="doPreview"
    >
      <div class="name">
        {{ fileData.file.name}}
      </div>
      <div class="size">
        {{ fileSize }}
      </div>
    </div>
    <div class="delete cp"
      @click.stop="$emit('handleDelete')"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'File',
  props: {
    fileData: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    fileSize() {
      const { fileData } = this
      if ((fileData ?? '') === '') {
        return ''
      }
      const { file } = fileData
      const { size } = file
      /*
      1K = 1024
      1M = 1024 * 1024
      1G = 1024 * 1024 * 1024
       */
      if (size < 1024) {
        return `${size}B`
      } else if (size < 1024 * 1024) {
        const kb = (size / 1024).toFixed(2)
        return `${kb}KB`
      } else if (size < 1024 * 1024 * 500) {
        const mb = (size / 1024 / 1024).toFixed(2)
        return `${mb}MB`
      }
      return ''
    }
  },
  methods: {
    doPreview() {
      console.log('do', this.fileData)
      this.$emit('handlePreview')
    }
  }
}
</script>

<style scoped lang="scss">
// pic doc pdf mp4 ppt unknown zip xls
@mixin fileIcon($name){
  &.#{$name}{
    background-image: url('~@/assets/images/task/#{$name}.png');
  }
}
.list_group{
  width: 100%;
  padding: 5px 0;
  position: relative;
  &:hover{
    .delete{
      opacity: 1;
    }
  }
  .icon{
    @include fileIcon(pic);
    @include fileIcon(doc);
    @include fileIcon(pdf);
    @include fileIcon(mp4);
    @include fileIcon(ppt);
    @include fileIcon(unknown);
    @include fileIcon(zip);
    @include fileIcon(xls);
    @include background(32px, 32px);
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }
  .info_group{
    .name{
      font-size: 14px;
      color: #333;
      height: 20px;
      width: 100%;
    }
    .size{
      font-size: 12px;
      color: #999;
      line-height: 16px;
    }
  }
  .delete{
    @include transition;
    @include background(14px, 14px);
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/common/delete.png');
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}
</style>