/*
 * @Author       : Hugo
 * @Date         : 2020-09-10 14:44:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-09-27 11:58:57
 * @Description  : 
 * @FilePath     : \miaohang\src\utils\messageBox.js
 */
import MessageBoxComponent from '@/components/Plugin/MessageBox.vue'

const MessageBox = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const MessageBoxConstructor = Vue.extend(MessageBoxComponent);

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new MessageBoxConstructor();

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$openMessageBox = (obj) => {
      if(obj){
        let {title, desc, sure, onSure, } = obj;
        instance.title = title || '提示';
        instance.desc = desc || '温馨提示';
        instance.sure = sure || '确定';
        instance.onSure = onSure;
      }else{
        instance.title = '提示';
        instance.desc = '温馨提示';
        instance.sure = '确定';
      }
      instance.visible = true
     
    }
  }
}

export default MessageBox