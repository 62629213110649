var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "scrollBox",
      staticClass: "components_pop_up_workgroup_context_main_page",
    },
    [
      _c(
        "div",
        { ref: "wrapper", staticClass: "wrapper" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isGettingMsg && !_vm.nomore,
                  expression: "isGettingMsg && !nomore",
                },
              ],
              staticClass: "loading_group",
            },
            [
              _c("div", { staticClass: "icon" }),
              _c("div", { staticClass: "text" }, [_vm._v("加载中")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading_prev,
                  expression: "loading_prev",
                },
              ],
              staticClass: "loading_group",
            },
            [
              _c("div", { staticClass: "icon" }),
              _c("div", { staticClass: "text" }, [_vm._v("加载中")]),
            ]
          ),
          _vm.nomore_prev
            ? _c("div", { staticClass: "nomore_group" }, [
                _c("div", { staticClass: "nomore" }, [
                  _vm._v("没有新的消息了"),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.list, function (item, index) {
            return [
              item.logType != 1 && !_vm.is_system && !item.pseudo_delete
                ? _c(
                    "div",
                    {
                      key: item.id,
                      ref: "dialog_unit",
                      refInFor: true,
                      staticClass: "dialog_unit",
                    },
                    [
                      _c("msg-unit", {
                        attrs: {
                          type:
                            item.fromUserId == _vm.userId ? "send" : "receive",
                          msg_data: item,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              item.logType == 1
                ? _c(
                    "div",
                    { key: index, ref: "dialog_unit", refInFor: true },
                    [_c("welcome-unit", { attrs: { item: item } })],
                    1
                  )
                : _vm._e(),
            ]
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading_next,
                  expression: "loading_next",
                },
              ],
              staticClass: "loading_group",
            },
            [
              _c("div", { staticClass: "icon" }),
              _c("div", { staticClass: "text" }, [_vm._v("加载中")]),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }