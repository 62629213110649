/*
 * @Author       : Hugo
 * @Date         : 2021-04-29 10:08:06
 * @LastEditTime : 2021-04-29 10:37:02
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/router/beforeEach.js
 * @Description  : 
 * @^_^          : 
 */
// router.beforeEach((to, from, next) => {
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })
import store from 'vuex';
import router from '.';
const beforeEach = (to, from, next)=> {
  next();
  /**禁止浏览器返回 */
  let allowBack = true // 给个默认值true
  if (to.meta.allowBack !== undefined) {
    allowBack = to.meta.allowBack
  }
  if (!allowBack) {
    history.pushState(null, null, location.href)
  }
  if(router.app.$store && router.app.$store.commit){
    router.app.$store.commit('set_allow_back', allowBack);
  }
  /**禁止浏览器返回 */
}

export default beforeEach;