var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_classes_task_modules_plugins_internal_contact_main d-flex flex-column align-center",
    },
    [
      _c(
        "div",
        { staticClass: "search_group flex-shrink-0 d-flex align-center mb-20" },
        [
          _c("img", {
            staticClass: "search_icon cp",
            attrs: {
              src: require("@/assets/images/common/search_icon.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$refs.search.focus()
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword",
              },
            ],
            ref: "search",
            staticClass: "search bbox",
            attrs: { type: "text", placeholder: "搜索" },
            domProps: { value: _vm.keyword },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.keyword = $event.target.value
              },
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.keyword.trim() !== "",
                expression: "keyword.trim() !== ''",
              },
            ],
            staticClass: "clear_icon cp",
            attrs: {
              src: require("@/assets/images/common/clear_icon.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                _vm.keyword = ""
              },
            },
          }),
        ]
      ),
      _c("div", { staticClass: "main_group bbox un_sel d-flex" }, [
        _c(
          "div",
          { staticClass: "list_group select_group bbox" },
          [
            _c(
              "div",
              {
                staticClass:
                  "all_group cp d-flex justify-space-between align-center",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchAll()
                  },
                },
              },
              [
                _c("div", { staticClass: "text" }, [_vm._v("全选")]),
                _c("div", {
                  staticClass: "select",
                  class: { active: _vm.all },
                }),
              ]
            ),
            _vm._l(_vm.list, function (item, index) {
              return [
                _c("list-unit", {
                  key: index,
                  attrs: { userinfo: item, keyword: _vm.keyword },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.touchListUnit(item)
                    },
                  },
                }),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "list_group selected_group bbox" },
          [
            _vm._l(_vm.selected_list, function (item, index) {
              return [
                _c("selected-unit", {
                  key: index,
                  attrs: { userinfo: item },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.removeUnit(item)
                    },
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "fun_group bbox d-flex align-center justify-center" },
        [
          _c(
            "div",
            {
              staticClass: "button btn_dark",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleSure.apply(null, arguments)
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "div",
            {
              staticClass: "button btn_light",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleCancel.apply(null, arguments)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }