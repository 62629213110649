/*
 * @Author       : Hugo
 * @Date         : 2020-07-20 08:59:38
 * @LastEditTime : 2022-01-05 11:31:43
 * @LastEditors  : Hugo
 * @Description  : 过滤器
 * @FilePath     : /miaohang/src/utils/filter.js
 * @^_^
 */
import moment from 'moment'; // 处理时间戳
let filter = {
  // 通用日期格式 
  universalDate,
};
function sameElseCallback({value, noTime}){
  const aim_year = new Date(value).getFullYear();
  const year = new Date().getFullYear();
  if(aim_year === year){
    return `MM/DD${noTime?'':' HH:mm'}`;
  }
  return `YYYY/MM/DD`;
} 
/**
 * 通用日期格式 今天, 昨天, 周几, 具体
 * @param {Number | String} value 时间戳
 * @param {Object} config {formatStr: 时间格式化字符串, noTime: 具体日期不显示时间, }
 */
function universalDate(value, config){
  // 设置星期
  moment.updateLocale('zh-cn', {
    weekdays : [
      '星期日','星期一','星期二','星期三','星期四','星期五','星期六',
    ]
  });
  let {formatStr, noTime} = config || {};
  moment.locale('zh-cn');
  let todayZeroTime = new Date(new Date().setHours(0, 0, 0, 0) + + 24 * 60 * 60 * 1000 - 1).getTime(); //获取当天零点的时间
  // 自定义格式
  // 以当天零点开始计算 以当前计算则传null
  // let outStr = moment(value).calendar(null, {
  let outStr = moment(value).calendar(todayZeroTime, {
    sameDay: 'HH:mm',
    nextDay: '[明天]',
    nextWeek: 'dddd',
    lastDay: `[昨天]${noTime?'':' HH:mm'}`,
    lastWeek: 'dddd' +`${noTime?'':' HH:mm'}`,
    // lastWeek: `MM月DD日${noTime?'':' HH:mm'}`,
    // sameElse: `MM月DD日${noTime?'':' HH:mm'}`,
    sameElse: ()=>{return sameElseCallback({value, noTime})}
  });
  return outStr;


  let showStr = ''; // 需要展示的时间字符串
  let showStamp = Number(value);
  // let def = new Date(showStamp).getTimezoneOffset(); //返回协调世界时（UTC）相对于当前时区的时间差值，单位为分
  // showStamp -= def*60*1000;
  /**
   * 获取当前日期
   */
  let now = new Date();
  let nowstamp = now.getTime(); //当前时间戳
  
  // 年 月 日 周
  let year = now.getFullYear();
  let month = now.getMonth(); // 0 ~ 11
  let date = now.getDate(); // 1 ~ 31
  let day = now.getDay(); // 0 ~ 6
  //  当前时间距离当日零点 时间戳
  /**
   * 当日零时时间戳
   */
  let nowDay = new Date(year, month, date, 0, 0, 0, 0);
  let nowDayStamp = nowDay.getTime();
  /**
   * 24小时时间戳
   */
  let oneDayStamp = 24 * 60 * 60 * 1000;
  if (showStamp >= nowDayStamp) {
    // 当日时间
    showStr = moment(showStamp).format('HH:mm');
  } else if (showStamp >= (nowDayStamp - oneDayStamp) && showStamp < nowDayStamp ) {
    // 昨天
    showStr = '昨天 ';
    if(!noTime){
      showStr += moment(showStamp).format('HH:mm');
    }
  } else if ( showStamp >= (nowDayStamp - oneDayStamp*2) && day > 1){
    // 本周 至少是周三
    let dayMap = {
      0: '周一',
      1: '周二',
      2: '周三',
      3: '周四',
      4: '周五',
      5: '周六',
      6: '周日',
    }
    showStr = dayMap[day];
    if(!noTime){
      showStr += ' ' + moment(showStamp).format('HH:mm');
    }
  } else {
    if(!formatStr){
      formatStr = 'YYYY/MM/DD HH:mm';
    }
    // 具体日期
    showStr = moment(showStamp).format(formatStr);
  }
  return showStr;
}

export default filter;