var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_plugins_confirm_purchase_main bbox d-flex flex-column align-center",
    },
    [
      _c("card-component", {
        attrs: {
          width: "420px",
          height: "244px",
          show_price: false,
          show_indate: false,
          font_plus: 2,
          pcard: _vm.pcard,
          title_size: "28px",
        },
      }),
      _c(
        "div",
        {
          staticClass: "number_group d-flex align-center justify-space-between",
        },
        [
          _c("div", { staticClass: "text" }, [_vm._v("购买数量")]),
          _c("div", { staticClass: "input_group d-flex align-center" }, [
            _c("div", {
              staticClass: "minus border cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doMinus()
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number,
                  expression: "number",
                },
              ],
              staticClass: "number border",
              attrs: { type: "text", maxlength: "3" },
              domProps: { value: _vm.number },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.number = $event.target.value
                },
              },
            }),
            _c("div", {
              staticClass: "plus border cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doPlus.apply(null, arguments)
                },
              },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "price_group d-flex align-center" }, [
        _c("div", { staticClass: "text" }, [_vm._v("总计：")]),
        _c("div", { staticClass: "price" }, [
          _vm._v("¥ " + _vm._s(_vm.total_price)),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "button btn_dark",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doPurchase()
            },
          },
        },
        [_vm._v("购买")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }