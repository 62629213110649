/*
 * @Author       : Hugo
 * @Date         : 2022-01-10 16:18:01
 * @LastEditTime : 2022-01-10 16:39:12
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/download/index.js
 * @Description  : 
 * @^_^          : 
 */
class Download{
  /**
   * 通过url下载
   * @param {*} url 
   */
  byUrl(params={}){
    const {url} = params;
    // console.log(url);
    // return;
    // iframe下载
    const iframe = document.createElement("iframe");
    iframe.style.display = "none"; // 防止影响页面
    iframe.style.height = 0; // 防止影响页面
    iframe.src = url; 

    document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
    // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
    setTimeout(()=>{
      iframe.remove();
    }, 5 * 60 * 1000);
  }
}

const download = new Download();
export default download;