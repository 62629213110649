<template>
  <div class="c_teaching_package_select_task_router_bar d-flex">
    <div
      class="router cp"
      @click.stop="goToAllPackage"
    >
      全部课程
    </div>
    <div class="symbol">
      &gt;
    </div>
    <div class="router active">
      {{ packageName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RouterBar',
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    packageName() {
      const { packageSelectTask } = this
      const { pPackage } = packageSelectTask
      if (!pPackage) {
        return ''
      }
      return pPackage.title
    }
  },
  methods: {
    goToAllPackage() {
      const { packageSelectTask } = this
      packageSelectTask.pPackage = undefined
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_router_bar{
  font-size: 14px;
  line-height: 22px;
  color: #666;
  .active{
    color: $main_blue;
  }
  .symbol{
    line-height: 22px;
    margin: 0 5px;
  }
}
</style>
