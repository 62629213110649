<template>
  <div
    class="c_teaching_package_select_task_filter_bar bbox d-flex"
  >
    <el-popover
      v-model="switchOrigin"
      placement="bottom-start"
      trigger="manual"
      :visible-arrow="false"
      popper-class="c_teaching_package_select_task_filter_bar_type_select"
    >
      <div class="origin_select_list_group">
        <div
          class="item"
          :class="{ active: packageSelectTask.origin === 'sell' }"
          @click.stop="changeOrigin('sell')"
        >
          我销售的
        </div>
        <div
          class="item"
          :class="{ active: packageSelectTask.origin === 'shopping' }"
          @click.stop="changeOrigin('shopping')"
        >
          模板商城
        </div>
      </div>
      <template
        slot="reference"
      >
        <div
          class="origin_button bbox d-flex cp"
          @click.stop="switchOrigin = true"
        >
          <div class="text">
            {{ origin }}
          </div>
          <img
            src="~@/assets/images/teaching_package/select_task/type_select_icon.png"
            alt=""
            class="icon"
          >
        </div>
      </template>
    </el-popover>
    <div class="search_group">
      <input
        type="text"
        class="search bbox"
        placeholder="搜索"
        v-model="search"
        @keydown.enter="handleKeydownEnter($event)"
      >
      <img
        v-show="search !== ''"
        src="~@/assets/images/common/clear_icon.png"
        alt=""
        class="clear cp"
        @click.stop="doClearSearch()"
      >
    </div>
    <div
      v-show="showType"
      class="type_list d-flex bbox"
    >
      <div
        class="type"
        :class="{ active: type === 'package' }"
        @click.stop="changeType('package')"
      >
        课程
      </div>
      <div
        class="type"
        :class="{ active: type === 'task' }"
        @click.stop="changeType('task')"
      >
        教学模板
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'FilterBar',
  props: {
    packageSelectTask: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      switchOrigin: false,
      search: ''
    }
  },
  computed: {
    origin() {
      const { packageSelectTask } = this
      if (!packageSelectTask) {
        return '我销售的'
      }
      const { origin } = packageSelectTask
      switch (origin) {
        case 'sell':
          return '我销售的'
        case 'shopping':
          return '模板商城'
        default:
          return '我销售的'
      }
    },
    type() {
      const { packageSelectTask } = this
      const { type } = packageSelectTask
      return type
    },
    showType() {
      const { packageSelectTask } = this
      const { origin } = packageSelectTask
      if (origin === 'sell') {
        return false
      }
      return true
    }
  },
  watch: {
    origin: function() {
      this.doClearSearch()
    },
    type: function() {
      this.doClearSearch()
    }
  },
  methods: {
    doSearch() {
      const { packageSelectTask, search } = this
      packageSelectTask.search = search.trim()
      this.$emit('handleDoSearch')
    },
    handleKeydownEnter(e) {
      console.log(e)
      if (e.keyCode === 229) {
        return
      }
      this.doSearch()
    },
    changeType(type) {
      this.doClearSearch()
      this.packageSelectTask.type = type
      // this.packageSelectTask.initList()
    },
    doClearSearch() {
      this.search = ''
      this.packageSelectTask.search = ''
    },
    changeOrigin(origin) {
      this.doClearSearch()
      this.packageSelectTask.origin = origin
      this.switchOrigin = false
      // this.packageSelectTask.initList()
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_filter_bar{
  .origin_button{
    line-height: 30px;
    background: #D4D4D4;
    border-radius: 4px 0 0 4px;
    padding: 0 10px;
    .text{
      margin-right: 5px;
      font-size: 14px;
      font-family: MicrosoftYaHei, "PingFang SC", serif;
      color: #333333;
    }
  }
  .search_group{
    width: 200px;
    position: relative;
    background: #E9E9E9;
    border-radius: 0 4px 4px 0;
    .search{
      @include placeholder(#999);
      position: relative;
      z-index: 2;
      width: 100%;
      line-height: 30px;
      height: 30px;
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 14px;
      color: #333;
      padding: {
        left: 10px;
        right: 30px;
      }
    }
    .clear{
      position: absolute;
      z-index: 3;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
  .type_list{
    .type{
      cursor: pointer;
      padding: 0 17px;
      font-size: 14px;
      font-family: MicrosoftYaHei, "PingFang SC", serif;
      color: #696969;
      line-height: 22px;
      &:first-child{
        border-right: 2px solid #eee;
      }
      &.active{
        color: $main_blue;
      }
    }
  }
}
</style>
<style lang="scss">
.c_teaching_package_select_task_filter_bar_type_select{
  margin-top: 2px!important;
  padding:0;
  min-width: 0;
  .origin_select_list_group{
    padding: 5px 0;
    .item{
      @include transition;
      cursor: pointer;
      font-size: 14px;
      font-family: MicrosoftYaHei, "PingFang SC", serif;
      line-height: 30px;
      padding: {
        left: 16px;
        right: 68px;
      }
      background-color: #fff;
      &:hover, &.active{
        background-color: #ddd;
      }
      &.active{
        font-weight: bold;
      }
    }
  }
}
</style>
