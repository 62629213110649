var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hyperlink_sketch_list" }, [
    _c(
      "div",
      { staticClass: "list" },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            index < 5
              ? _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    class: { value: item.value },
                    on: {
                      click: function ($event) {
                        return _vm.openHyperlink(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name || item.value))]
                )
              : _vm._e(),
          ]
        }),
        _vm.is_more && !_vm.switch_more
          ? _c(
              "div",
              {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    _vm.switch_more = true
                  },
                },
              },
              [_vm._v("更多")]
            )
          : _vm._e(),
        _vm.is_more && _vm.switch_more
          ? [
              _vm._l(_vm.list, function (item, index) {
                return [
                  index >= 5
                    ? _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item",
                            class: { value: item.value },
                            on: {
                              click: function ($event) {
                                return _vm.openHyperlink(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name || item.value))]
                        ),
                      ])
                    : _vm._e(),
                ]
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }