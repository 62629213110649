var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sketch_common_page",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.openAppoint()
        },
      },
    },
    [_vm._v(_vm._s(_vm.msg.content))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }