var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_folder_preview d-flex flex-column" },
    [
      _c("operation-bar", {
        staticClass: "flex-shrink-0 operation_group",
        attrs: { p_task_log: _vm.p_task_log, p_folder: _vm.p_folder },
        on: { handleClose: _vm.handleClose },
      }),
      _c("main-component", {
        ref: "main",
        staticClass: "main_group",
        attrs: { preview: true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }