var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pop_up_dilaog_send_files_index d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "container bbox" },
        [_c("files-list", { attrs: { dialog: _vm.dialog } })],
        1
      ),
      _vm.dialog.uploadingFile
        ? _c("progress-component", { attrs: { dialog: _vm.dialog } })
        : _vm._e(),
      !_vm.dialog.uploadingFile
        ? _c(
            "div",
            {
              staticClass:
                "operation_bar d-flex align-center justify-end flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  staticClass: "button btn_light",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("handleClose")
                    },
                  },
                },
                [_vm._v("\n      取消\n    ")]
              ),
              _c(
                "div",
                {
                  staticClass: "button btn_dark",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.doSend.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      发送\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }