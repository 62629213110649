/*
 * @Author       : Hugo
 * @Date         : 2022-04-22 11:22:41
 * @LastEditTime : 2022-04-22 11:24:11
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/address_book_prompt.js
 * @Description  : 
 * @^_^          : 
 */

import PluginModule from '@/components/Plugin/Modules/AddressBookPrompt/index.vue';

const PluginConfig = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginModuleConstructor = Vue.extend(PluginModule);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginModuleConstructor();

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$openAddressBookPrompt = (params={}) => {
      // console.log(params)
      const {
        onSure
      } = params;

      instance.onSure  = onSure;
      instance.visible = true;
    }
    Vue.prototype.$closeAddressBookPrompt = () => {
      instance.visible = false;
    }
    
  }
}

export default PluginConfig;