<template>
  <div class="c_pop_up_batch_add_contacts_to_tasks_select_task_index bbox d-flex flex-column justify-space-between">
    <div class="filter_group bbox">
      <div class="search_group">
        <input
          type="text"
          class="search"
          placeholder="搜索"
          v-model="search"
          @keydown.enter="doSearch"
        >
        <img
          v-show="search !== ''"
          src="~@/assets/images/common/clear_icon.png"
          alt=""
          class="clear cp"
          @click.stop="doClearSearch()"
        >
      </div>
    </div>
    <task-list
      ref="taskList"
      class="task_list"
      :user-info-simple-pmcs="userInfoSimplePmcs"
      :show-loading="loading"
      @handleLoadmore="handleLoadmore"
    ></task-list>
    <operation-bar
      :user-info-simple-pmcs="userInfoSimplePmcs"
    ></operation-bar>
  </div>
</template>

<script>
import global from '@/api/global'
import tools from '@/api/tools'
import router from '@/router'
import OperationBar from '@/components/PopUp/BatchAddContactsToTasks/SelectTask/OperationBar'
import TaskList from '@/components/PopUp/BatchAddContactsToTasks/SelectTask/TaskList'
import { listForClasses, PTask } from '@/types'
export default {
  name: 'SelectTask',
  components: {
    TaskList,
    OperationBar
  },
  props: {
    userInfoSimplePmcs: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      search: ''
    }
  },
  computed: {
    selectList () {
      const { userInfoSimplePmcs } = this
      const { selectList } = userInfoSimplePmcs
      return selectList
    }
  },
  watch: {
    search: function(val) {
      if (val === '') {
        this.doSearch()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async handleLoadmore() {
      await this.getTaskList()
    },
    doClearSearch() {
      this.search = ''
      // this.doSearch()
    },
    async doSearch() {
      this.userInfoSimplePmcs.keyword = this.search
      if (this.loading) {
        return
      }
      const chatType = tools.getChatType()
      const { currentRoute } = router
      console.log(currentRoute)
      const chatId = tools.atob(currentRoute.params.id)
      if (!chatId) {
        return
      }
      this.$refs.taskList.$el.scrollTop = 0
      this.loading = true
      await this.userInfoSimplePmcs.doSearch({
        chatType,
        chatId
      })
      this.loading = false
    },
    async init () {
      this.getTaskList()
    },
    async getTaskList () {
      console.log(this.userInfoSimplePmcs)
      if (this.loading) {
        return
      }
      const chatType = tools.getChatType()
      const { currentRoute } = router
      console.log(currentRoute)
      const chatId = tools.atob(currentRoute.params.id)
      if (!chatId) {
        return
      }
      this.loading = true
      await this.userInfoSimplePmcs.getChatTaskList({
        chatType,
        chatId
      })
      this.loading = false
      return
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_batch_add_contacts_to_tasks_select_task_index{
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  overflow: auto;
  .filter_group{
    width: 100%;
    padding: 16px 20px;
    .search_group{
      width: 200px;
      position: relative;
      background: #E9E9E9;
      border-radius: 0 4px 4px 0;
      .search{
        @include placeholder(#999);
        position: relative;
        z-index: 2;
        width: 100%;
        line-height: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
        outline: none;
        font-size: 14px;
        color: #333;
        padding: {
          left: 10px;
          right: 30px;
        }
      }
      .clear{
        position: absolute;
        z-index: 3;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
      }
    }
  }
  .task_list{
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>