var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("common-wrapper", { attrs: { height: "80%", width: "80%" } }, [
            _c(
              "div",
              {
                staticClass:
                  "c_plugin_teaching_package_selected_task_list d-flex flex-column",
              },
              [
                _c("common-title", {
                  staticClass: "flex-shrink-0",
                  attrs: {
                    title: `查看已选（${_vm.number}）`,
                    close: true,
                    background_color: "#fff",
                  },
                  on: { handleClose: _vm.handleClose },
                }),
                _c("plugin-component", {
                  staticClass: "plugin_component",
                  attrs: {
                    "p-package": _vm.pPackage,
                    "task-list": _vm.taskList,
                  },
                  on: {
                    handleClose: _vm.handleClose,
                    handleSure: _vm.handleSure,
                    handleReturn: _vm.handleReturn,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }