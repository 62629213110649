import createTask from './createTask';
import editTask from './editTask';
import sendWidget from './sendWidget';
import callTask from './callTask';
import createTeach from './createTeach';
import editTeach from './editTeach';
import createDraft from './createDraft';
import editDraft from './editDraft';
import sendDraft from './sendDraft';
import createInstance from './createInstance';
import editInstance from './editInstance';
import deleteTask from './deleteTask';
import editPublicNotice from './editPublicNotice';
import createReminder from './createReminder';
import editReminder from './editReminder';
import deleteReminder from './deleteReminder';

class TaskFun {
  createTask = createTask;
  editTask = editTask;
  callTask = callTask;
  sendWidget = sendWidget;
  createTeach = createTeach;
  editTeach = editTeach;
  createDraft = createDraft;
  editDraft = editDraft;
  sendDraft = sendDraft;
  createInstance = createInstance;
  editInstance = editInstance;
  deleteTask = deleteTask;
  editPublicNotice = editPublicNotice;
  createReminder = createReminder;
  editReminder = editReminder;
  deleteReminder = deleteReminder;
}

const taskFun = new TaskFun();

export default taskFun;