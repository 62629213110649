/*
 * @Author       : Hugo
 * @Date         : 2021-11-11 16:29:25
 * @LastEditTime : 2022-07-20 11:48:41
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/store/stateModule/app.js
 * @Description  : 
 * @^_^          : 
 */
const app = {
  // 标签页隐藏/激活
  label_page_hide_time_stamp: '',
  label_page_activation_time_stamp: '',
  // 密码
  password: null,

  // 上一级导航
  prev_route: undefined,

  // project/start 接口信息
  start: undefined,

  // 
  toResourceRoute: undefined, // 需要返回上一级的路由信息

  taskChannelRouter: undefined, // 任务频道路由

  copyWidgetList: [], // 复制的控件



}

export default app;