import WebIM from '@/utils/WebIM/WebIM'
import tools from '@/api/tools'
import global from '@/api/global'
import router from '@/router'
class Im {

  sendMySelf = () => {
    const userId = tools.getUserId()
    const chatType = tools.getChatType()
    const { currentRoute } = router
    const id = currentRoute.params.id
    const chatId = global.decodeBase64(id)
    if (!chatId) {
      return
    }
    const { conn } = WebIM
    let option = {
      // 设置消息类型。
      type: 'cmd',
      // 设置会话类型。
      chatType: 'singleChat',
      // 设置消息接收方的用户 ID。
      to: `userid_${userId}`,
      // 设置自定义动作。对于透传消息，该字段必填。
      action : 'action',
      // 设置消息扩展信息。
      ext: {
        chatId: Number(chatId),
        chatType,
        pushType: 1
      }
    }
    // console.log(WebIM)
    // 创建一条透传消息。
    let msg = WebIM.message.create(option)
    // 调用 `send` 方法发送该透传消息。
    conn.send(msg).then((res) => {
      // 消息成功发送回调。
      console.log("Success", res)
    }).catch((e) => {
      // 消息发送失败回调。
      console.log("Fail", e)
    })
  }
}

const im = new Im()

export default im
