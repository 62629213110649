<!--
 * @Author       : Hugo
 * @Date         : 2021-10-27 15:45:35
 * @LastEditTime : 2022-04-11 10:18:09
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/WorkgroupContext/Main.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div class="components_pop_up_workgroup_context_main_page" ref="scrollBox">
    <div class="wrapper" ref=wrapper>
      <div class="loading_group" v-show="isGettingMsg && !nomore">
        <div class="icon"></div>
        <div class="text">加载中</div>
      </div>
      <div class="loading_group" v-show="loading_prev">
        <div class="icon"></div>
        <div class="text">加载中</div>
      </div>
      <div class="nomore_group" v-if="nomore_prev">
        <div class="nomore">没有新的消息了</div>
      </div>
      <template v-for="(item,index) in list" >
        <div
          class="dialog_unit"
          ref="dialog_unit"
          :key="item.id"
          v-if="item.logType!=1 && !is_system && !item.pseudo_delete"
        >
          <msg-unit :type="item.fromUserId == userId ? 'send' : 'receive'" :msg_data="item" ></msg-unit>
        </div>
        <div
          v-if="item.logType == 1"
          :key="index" 
          ref="dialog_unit"
        >
          <welcome-unit
            
            :item="item"
            
          ></welcome-unit>
        </div>
      </template>
      <div class="loading_group" v-show="loading_next">
        <div class="icon"></div>
        <div class="text">加载中</div>
      </div>
    </div>
  </div>
</template>
<script>
import MsgUnit from '@/components/dialog/msgUnit.vue';
import WelcomeUnit from '@/components/dialog/share/unit.vue';
export default {
  components:{
    MsgUnit,
    WelcomeUnit,
  },
  props: {
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    start_data: {
      type: Object,
      default:  null,
    }
  },
  data(){
    return {
      msgList: null,
      userId: this.$tools.getUserId(),
      // scrollto 配置
      options: {
        container: '#fun_task_dialog', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },
      scrollTop: 1000, 
      isGettingMsg: false,
      nomore: false,
      // new
      list: [],
      nomore_next: false,
      loading_next: false,
      nomore_prev: false,
      loading_prev: false,
    }
  },
  watch:{
    '$route.params.id': async function(newVal){
      if(newVal){
        // 关闭编辑任务浮层
        this.$store.commit('set_edit_task_box', false);

        // this.chatId =  this.$api.moduleTask.getRouteParamsId();
        await this.init();
        // this.scrollToBottom();
        this.$nextTick(function(){
          this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
          this.scrollToBottom();
          //  200ms后开启监听
          setTimeout(() => {
            this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false);
          }, 200);
          
        })
      }
    },
    message_list:{
      handler: function(val){
        // // console.log(val);
        if(!val || val.length == 0 || val.length < 20){
          this.nomore = true;
        }
        // this.scrollToBottom();
      },
      deep: true,
    },
    /**
     * 加载更多
     */
    scrollTop: async function(val){
      if(val < 200){
        if(!this.isGettingMsg){
          // // console.log('获取新消息')
          this.isGettingMsg = true;
          await this.unshiftMessageList();
        }
      }
      let {scrollHeight, clientHeight} = this.$refs.scrollBox;
      // // console.log(this.$store.state.message_box_unit);
      let message_box_unit = this.$store.state.message_box_unit;
      let message_box_list = this.$tools.deepClone(this.$store.state.message_box_list);
      if((scrollHeight - (val + clientHeight)) < 20 && message_box_unit && message_box_unit.unreadMessageNum){
        await this.$http.request('/projectTask/unreadTozero',{chat_id: message_box_unit.chatId, chat_type: message_box_unit.chatType});
        // 置空未读数
        let index = message_box_list.findIndex( item => {
          return item.chatId == message_box_unit.chatId && item.chatType == message_box_unit.chatType;
        })
        if(index != -1){
          message_box_list[index].unreadMessageNum = 0;
        }
        this.$store.commit('set_message_box_list', message_box_list);
      }
    },
    // 触发滚动到底部
    task_trigger_scroll_to_bottom: function(val){
      // console.log('task_trigger_scroll_to_bottom',val);
      if(val){
        this.$nextTick(function(){
          this.scrollToBottom();
          this.$store.commit('set_task_trigger_scroll_to_bottom', false);
        })
      }
    },
  },
  
  computed:{
    chatId(){
      const id = this.$route.params.id;
      if(id){
        return this.$global.decodeBase64(id);
      }
      return '';
    },
    message_list(){
      return this.$store.state.message_list;
    },
    task_trigger_scroll_to_bottom(){
      return this.$store.state.task_module.task_trigger_scroll_to_bottom;
    },
    /**
     * 是否是系统推送[小妙]
     */
    is_system(){
      const {chatId} = this;
      if(chatId == this.$cm_id){
        return true;
      }
      return false;
    }
  },

  methods:{
    /**
     * 获取消息列表projectTask/messageList
     */
    async getMsgList(){
      this.$store.commit('set_message_list', []);
      await this.$store.dispatch('get_message_list');
      this.scrollToBottom();
      this.$store.commit('set_task_trigger_scroll_to_bottom', false);

    },
    /**
     * 最近模板列表 
     */
    async getMyTaskList(){
      this.$store.commit('set_my_task_list', []);
      this.$store.dispatch('get_my_task_list');
    },

    async init(){
      const {start_data} = this;
      // console.log(start_data);
      if(start_data){
        this.$set(this, 'list', [start_data]);
      }
      this.$nextTick(function(){
        this.getNextList();
      })
      this.$nextTick(function(){
        // console.log(this.$refs.scrollBox)
        this.$refs.wrapper.addEventListener('wheel', this.scrollEventListenerHandler, false);
      })
      
      return;

      this.getMsgList();
      // this.getMyTaskList();
      this.scrollTop = this.$refs.scrollBox.scrollHeight;
      this.isGettingMsg = false;
      this.nomore = false;
      
      this.$nextTick(function(){
        this.scrollToBottom();
        this.$store.commit('set_task_trigger_scroll_to_bottom', false);
        //  200ms后开启监听
        setTimeout(() => {
          this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false);
        }, 200);
      })
    },
    /**
     * @description: 滚动到底部
     * @return: 
     */
    scrollToBottom(){
      // 获取最后一个元素
      if(this.$refs.dialog_unit && this.$refs.dialog_unit.length != 0){
        let element = this.$refs.dialog_unit[this.$refs.dialog_unit.length - 1];
        this.$scrollTo(element,1, this.options);
      }
    },
    /**
     * 滚动监听函数
     */
    scrollEventListenerHandler(e){
      // // console.log(e);
      const { wheelDelta } = e;
      const {scrollTop, scrollHeight, clientHeight} = this.$refs.wrapper;
      // this.scrollTop = this.$refs.scrollBox.scrollTop;
      const top = scrollTop ;
      const bottom = scrollHeight - scrollTop - clientHeight;
      // 向上滚动 距离顶部少于50;
      if(wheelDelta > 0 && top < 50){
        this.getPrevList({bottom});
      }
      // 乡下滚动 距离底部小于50;
      if(wheelDelta < 0 && bottom < 50){
        this.getNextList();
      }
    },
    /**追加消息列表 */
    async unshiftMessageList(){
      let message_list = this.$tools.deepClone(this.message_list);
      let chatType = 1;
      if(this.$route.name == 'fun_task_dialog_single'){
        chatType = 0;
      }
      if(message_list){
        let res = await this.$http.request('/projectTask/messageList', {chatType,chatId: this.chatId,startId: message_list ? message_list[0].id : ''});
        let tasklogList = [];
        if(res.contents && res.contents.tasklogList){
          tasklogList = res.contents.tasklogList.reverse();
        }
        if(tasklogList && tasklogList.length){
          let new_message_list = [...tasklogList, ...message_list];
          this.$store.commit('set_message_list', new_message_list);
        }
        if(tasklogList.length == 0){
          this.nomore = true;
          return ;
        }
        //跳转到追加前的位置 (-1 向上移动一位)
        this.$nextTick(function(){
          let element = this.$refs.dialog_unit[tasklogList.length - 2];
          this.$scrollTo(element,1, this.options);
        })
      }
      setTimeout(() => {
        this.isGettingMsg = false;
      }, 400);
    },
    async getNextList(){
      const {nomore_next, loading_next, chat_id: chatId, chat_type: chatType, list} = this;
      if(nomore_next || loading_next){
        return;
      }
      this.loading_next = true;
      const data = {
        chatId,
        chatType,
        type: 2,
        startId: list[list.length - 1].id,
      }
      const res = await this.$global.doPost({
        url: '/projectTaskLogSearch/searchTaskLogTextPage',
        data,
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {tasklogList} = contents;
        if(tasklogList.length < 20){
          this.nomore_next = true;
        }
        this.$set(this, 'list', [
          ...new Set([
            ...list,
            ...tasklogList,
          ])
        ])
      }

      this.loading_next = false;
    },
    async getPrevList(params){
   
      const {bottom} = params || {};
      const {nomore_prev, loading_prev, chat_id: chatId, chat_type: chatType, list} = this;
      if(nomore_prev || loading_prev){
        return;
      }
      this.loading_prev = true;
      const data = {
        chatId,
        chatType,
        type: 1,
        startId: list[0].id,
      }
      const res = await this.$global.doPost({
        url: '/projectTaskLogSearch/searchTaskLogTextPage',
        data,
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {tasklogList} = contents;
        if(tasklogList.length < 20){
          this.nomore_prev = true;
        }
        // console.log(tasklogList)
        // console.log(list)
        // console.log([
        //   ...new Set([
        //     ...tasklogList,
        //     ...list,
        //   ])
        // ])
        this.$set(this, 'list', [
          ...new Set([
            ...tasklogList.reverse(),
            ...list,
          ])
        ])
      }

      // 获取之前的bottom 
      this.$nextTick(function(){
        if((bottom??'') !== ''){
          const {scrollTop, scrollHeight, clientHeight} = this.$refs.wrapper;
          // this.scrollTop = this.$refs.scrollBox.scrollTop;
          const after_bottom = scrollHeight - scrollTop - clientHeight;
          // console.log({bottom,after_bottom})
          this.$refs.wrapper.scrollTop = after_bottom - bottom - 70; // 70为滚动后额外增加的一段距离 替代加载中的样式
        }
      })
      // console.log(this.list.length, this.list);

      this.loading_prev = false;
    }
  },
  async mounted() {
    // this.getNextList();


    // // console.log(this.task_trigger_scroll_to_bottom, 'this.task_trigger_scroll_to_bottom')
    this.init();
    // if(this.message_list && this.message_list.length && this.message_list< 20){
    //   this.nomore = true;
    // }else{
    //   this.nomore = false;
    // }
    // this.$nextTick(function(){
    //   this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
    //   this.scrollToBottom();
    //   this.$store.commit('set_task_trigger_scroll_to_bottom', false);
    //   //  200ms后开启监听
    //   setTimeout(() => {
    //     this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false);
    //   }, 200);
    // })
  },
}
</script>

<style lang="scss" scoped>
.components_pop_up_workgroup_context_main_page{
  
  width: 100%;
  height: 100%;
  overflow: hidden;
  .wrapper{
    @include hidescrollbar;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .dialog_unit{
    width:100%;
    padding-bottom: 22px;
    &:last-child{
      padding-bottom: 0;
    }
  }
  .loading_group{
    @include flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
    .icon{
      animation: myfirst 1s linear infinite;
      -webkit-animation: myfirst 1s linear infinite;
      transform-origin: center;
      transform: rotate(0deg);
      width: 16px;
      height: 16px;
      background: {
        size: 100%;
        image: url(~@/assets/images/common/loadmore.png);
      }
    }
    .text{
      padding-left: 10px;
      color: #999;
      font-size: 14px;
    }
  }
  .nomore{
    @include flex;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    color: #999;
    padding: 20px 0 40px;
  }
}
@keyframes myfirst
{
    0%   {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}
/* Safari 与 Chrome */
@-webkit-keyframes myfirst 
{
    0%   {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}
</style>