var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c(
            "common-wrapper",
            {
              attrs: {
                height: "60%",
                width: "70%",
                max_width: "400px",
                max_height: "408px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "c_plugin_share_route d-flex flex-column" },
                [
                  _c("common-title", {
                    staticClass: "flex-shrink-0",
                    attrs: {
                      title: _vm.title,
                      close: true,
                      background_color: "#fff",
                    },
                    on: { handleClose: _vm.handleClose },
                  }),
                  _vm.visible
                    ? _c("plugin-component", {
                        staticClass: "plugin_component",
                        attrs: { dialog: _vm.dialog },
                        on: {
                          handleClose: _vm.handleClose,
                          handleSure: _vm.handleSure,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }