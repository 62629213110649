class Debounce{
  // main = (fn, wait, immediate=false) => {
  //   // console.log(fn, wait)
  //   let timer, startTimeStamp=0;
  //   let context, args;
   
  //   let run = (timerInterval)=>{
  //     timer= setTimeout(()=>{
  //       let now = (new Date()).getTime();
  //       let interval=now-startTimeStamp
  //       if(interval<timerInterval){ // the timer start time has been reset，so the interval is less than timerInterval
  //         // console.log('debounce reset',timerInterval-interval);
  //         startTimeStamp=now;
  //         run(wait-interval);  // reset timer for left time 
  //       }else{
  //         if(!immediate){
  //           fn.apply(context,args);
  //         }
  //         clearTimeout(timer);
  //         timer=null;
  //       }
        
  //     },timerInterval);
  //   }
   
  //   return function(){
  //     context=this;
  //     args=arguments;
  //     let now = (new Date()).getTime();
  //     startTimeStamp=now; // set timer start time
   
  //     if(!timer){
  //       // console.log('debounce set',wait);
  //       if(immediate) {
  //         fn.apply(context,args);
  //       }
  //       run(wait);    // last timer alreay executed, set a new timer
  //     }
  //   }
  // }
  main = (fn,timerDelay,immediate) => {
    let timer;
    return function () {
        let that = this;
        let args = arguments;
        if (timer) clearTimeout(timer);
        if (immediate) {
            var callNow = !timer;
            timer = setTimeout(() => {
              timer = null;
            }, timerDelay)
            if (callNow) fn.apply(that, args)
        }
        else {
          timer = setTimeout(function(){
            fn();
            // fn.apply(that, args)
            }, timerDelay);
        }
    }
  }
}

const debounce = new Debounce();

export default debounce.main;


