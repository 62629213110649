var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("plugin-module", {
            attrs: {
              chat_user_list: _vm.chat_user_list,
              friend_list: _vm.friend_list,
              user_list: _vm.user_list,
            },
            on: {
              handleClose: _vm.handleClose,
              handleSure: _vm.handleSure,
              handleSelect: _vm.handleSelect,
              handleRemove: _vm.handleRemove,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }