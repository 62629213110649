<template>
  <div class="dialog_file_message_picture_message bbox">
    <el-image
      :src="`${$file_url}${msgData.work}`"
      :style="{
        width: `${clacSize.width}px`,
        height: `${clacSize.height}px`
      }"
      :lazy="true"
      :preview-src-list="[`${$file_url}${msgData.work}`]"
      fit="cover"
      class="picture_message"
    >
      <div
        slot="placeholder"
        class="img_placeholder d-flex justify-center align-center"
      >
        加载中<span class="dot">...</span>
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  name: 'PictureMessage',
  props: {
    msgData: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      maxWidth: 310,
      maxHeight: 360
    }
  },
  computed: {
    dataWidth() {
      const { msgData } = this
      if (msgData === undefined) {
        return this.maxWidth
      }
      const { wide } = msgData
      return wide
    },
    dataHeight() {
      const { msgData } = this
      if (msgData === undefined) {
        return this.maxHeight
      }
      const { high } = msgData
      return high
    },
    clacSize() {
      const { dataWidth, dataHeight, maxWidth, maxHeight } = this
      let width = dataWidth
      let height = dataHeight
      if (dataWidth >= maxWidth) {
        width = maxWidth
        height = dataHeight * width / dataWidth
      }
      if (dataWidth <= maxWidth && dataHeight >= maxHeight) {
        height = dataHeight * width / dataWidth
      }
      return { width, height }
    }
  }
}
</script>

<style scoped lang="scss">
.dialog_file_message_picture_message{
  max-width: 350px;
  max-height: 400px;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  .picture_message{
    width: 100%;
    height: 100%;
    max-height: 360px;
  }
  .img_placeholder{
    user-select: none;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #ccc;
  }
}
</style>