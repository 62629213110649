/*
 * @Author       : Hugo
 * @Date         : 2020-05-25 10:06:13
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-04-29 10:26:34
 * @Description  : 
 * @FilePath     : /miaohang/src/router/fun_task.js
 */ 
const fun_task = [
  {
    path: "/fun_task",
    name: "fun_task",
    component: () => import('@/views/layout/default.vue'),
    meta: {allowBack: false},
    // redirect: 'fun_task_index',
    children: [
      {
        path: "index",
        name: "fun_task_index",
        components: {
          default: ()=> import('@/views/fun_task/index.vue'),
          sidebar: () => import('@/components/Group/Aside/Task.vue'),
        },
        meta: {allowBack: false},
      },
      // 会话
      {
        path: 'dialog',
        name: 'fun_task_dialog',
        components: {
          default: ()=> import('@/views/layout/dialog.vue'),
          sidebar: () => import('@/components/Group/Aside/Task.vue'),
        },
        meta: {allowBack: false},
        children: [
          {
            path: 'single/:id',
            name: 'fun_task_dialog_single',
            component: ()=> import('@/views/fun_task/dialog.vue'),
            // beforeEnter: (to, from, next) => {
            //   // console.log(to, from);
            //   // next({...to})
            //   // next({ ...to, replace: true })
            // },
            meta: {allowBack: false},
          },
          {
            path: 'group/:id',
            name: 'fun_task_dialog_group',
            component: ()=> import('@/views/fun_task/dialog.vue'),
            meta: {allowBack: false},
          }

        ]
      }
      
    ]
  }
]

export default fun_task;