<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-30 23:46:40
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-31 23:40:53
 * @FilePath: /miaohang/src/components/ClassesTask/modules/Plugins/internalContact/Main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="c_classes_task_modules_plugins_internal_contact_main d-flex flex-column align-center">
    <div class="search_group flex-shrink-0 d-flex align-center mb-20">
      <img
        src="@/assets/images/common/search_icon.png" alt="" class="search_icon cp"
        @click.stop="$refs.search.focus()"
      >
      <input type="text" class="search bbox"
        v-model="keyword"
        placeholder="搜索"
        ref="search"
      >
      <img
        src="@/assets/images/common/clear_icon.png"
        alt="" class="clear_icon cp" @click="keyword = ''"
        v-show="keyword.trim() !== ''"
      >
    </div>
    <div class="main_group bbox un_sel d-flex">
      <div class="list_group select_group bbox">
        <div
          class="all_group cp d-flex justify-space-between align-center"
          @click.stop="touchAll()"
        >
          <div class="text">全选</div>
          <div class="select"
            :class="{active: all}"
          ></div>
        </div>
        <template v-for="(item, index) in list" >
          <list-unit
            :key="index"
            :userinfo="item"
            :keyword="keyword"
            @click.stop.native="touchListUnit(item)"
          ></list-unit>
        </template>
      </div>
      <div class="list_group selected_group bbox">
        <template v-for="(item, index) in selected_list" >
          <selected-unit
            :key="index"
            :userinfo="item"
            @click.stop.native="removeUnit(item)"
          ></selected-unit>
        </template>
      </div>
    </div>
    <div class="fun_group bbox d-flex align-center justify-center">
      <div class="button btn_dark"
        @click.stop="handleSure"
      >确定</div>
      <div class="button btn_light"
        @click.stop="handleCancel"
      >取消</div>
    </div>
  </div>
</template>

<script>
import SelectedUnit from './SelectedUnit.vue'
import ListUnit from './ListUnit.vue'
import tools from '@/api/tools'
import {listForClasses, UserInfo} from '@/types'
export default {
  components: {
    SelectedUnit,
    ListUnit,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    data: {
      type: Object,
      default: undefined,
    },
    chat_user_list: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    all(){
      const {list, keyword, data} = this
      const {task} = data
      const {userId} = task
      const keyword_trim = keyword.trim()
      let all = true
      if(list){

        list.forEach(item => {
          if(!item.selected && item.userId != userId ){
            const searchName = item.search({keyword: keyword_trim})
            // console.log(searchName, (!keyword_trim))
            // console.log(!(keyword_trim && !searchName) , !keyword_trim)
            if(!(keyword_trim && !searchName) || !keyword_trim){ //  搜索时  || 未搜索时
              all = false
            }
          }
        })
      }else{
        all = false
      }
      return all
    },
  },
  data(){
    return {
      keyword: '',
      list: [],
      selected_list: [],
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.generateList()
    },
    generateList(){
      const {data, chat_user_list} = this
      const {task, taskUsers} = data
      const {tousers, add_users, delete_users, userId} = task
      let list = tools.deepClone(chat_user_list)
      let selected_list = []
      // if(tousers){
      //   const tousers_arr = tousers.split(',')
      //   tousers_arr.forEach(item => {
      if(taskUsers && taskUsers.length){
        taskUsers.forEach(item=>{
          const aim = list.find(unit=>{
            return Number(unit.userId) === Number(item.userId)
          })
          if(aim){
            aim.selected = true
            
            // 移除创建者
            if(Number(aim.userId) !== Number(userId)){
              selected_list.push(aim)
            }
          }
        })
      }
      const create_index = list.findIndex(item=>{
        return Number(item.userId) === Number(userId)
      })
      if(create_index !== -1){
        list.splice(create_index, 1)
      }

      list = listForClasses({
        list,
        classes: UserInfo,
      })
      selected_list = listForClasses({
        list:selected_list ,
        classes: UserInfo,
      })
      // console.log(list)
      this.$set(this, 'list', list)
      this.$set(this, 'selected_list', selected_list)
    },
    touchListUnit(item){
      if(item.selected){
        const can_delete = this.canDelete(item)
        if(can_delete === false){
          this.$notice({desc: '没有操作权限！'})
          return
        }
      }
      item.selected = !item.selected
      // 增加
      if(item.selected){
        this.selected_list.push(item)
      }
      // 减少
      if(!item.selected){
        const minus_index = this.selected_list.findIndex(unit=>{
          return Number(unit.userId) === Number(item.userId)
        })
        if(minus_index !== -1){
          this.selected_list.splice(minus_index, 1)
        }
      }
    },
    removeUnit(item){
      const can_delete = this.canDelete(item)
      if(can_delete === false){
        this.$notice({desc: '没有操作权限！'})
        return
      }
      const {list, selected_list} = this
      const list_aim = list.find(unit=>{
        return Number(unit.userId) === Number(item.userId)
      })
      if(list_aim){
        list_aim.selected = false
      }
      const selected_index = selected_list.findIndex(unit=>{
        return Number(unit.userId) === Number(item.userId)
      })
      if(selected_index !== -1){
        selected_list.splice(selected_index, 1)
      }
      // const select_index = this
    },
    touchAll(){
      const {list, all, keyword} = this
      // 取消全选
      if(all){
        list.forEach(item=>{
          if(item.selected){
            if(keyword.trim() !== ''){
              if(item.search({keyword: keyword.trim()})){
                this.touchListUnit(item)
              }
            }else{
              this.touchListUnit(item)
            }
          }
        })
      }

      // 全选
      if(!all){
        list.forEach(item=> {
          if(!item.selected){
            if(keyword.trim() !== ''){
              if(item.search({keyword: keyword.trim()})){
                this.touchListUnit(item)
              }
            }else{
              this.touchListUnit(item)
            }
          }
        })
      }
    },
    handleCancel(){
      this.$emit('handleClose')
    },
    handleSure(){
      const {data, list, selected_list} = this
      const {task} = data
      const {tousers, id, userId} = task
      let add_users = ''
      let delete_users = ''

     
      if(tousers){
        const tousers_arr = tousers.split(',')
        // 如果selected_list中的不在touser中  则添加到add_users中
        selected_list.forEach(item=>{
          const is_in_tousers = tousers_arr.find(unit=>{
            return Number(unit) === Number(item.userId)
          })
          if(!is_in_tousers){
            add_users += `${add_users ? ',' : ''}${item.userId}`
          }
        })
        // 如果touser中的数据不存在于selected_list 中 并且不是创建者 则添加到delete_users

        tousers_arr.forEach(item=> {
          if(Number(item) !== Number(userId)){ // 不是创建者
            const is_in_select = selected_list.find(unit=>{
              return Number(item) === Number(unit.userId)
            })
            if(!is_in_select){
              delete_users += `${delete_users ? ',' : ''}${item}`
            }

          }
        })

      }

      console.log(add_users, delete_users)

      this.data.task.add_users = add_users
      this.data.delete_users = delete_users

      // 修改taskUsers
      let taskUsers = []
      const create_user = data.taskUsers.find(item=>{
        return Number(item.userId) === Number(userId)
      })
      if(create_user){
        taskUsers.push(create_user)
      }
      selected_list.forEach(item=> {
        const in_task_user = data.taskUsers.find(unit=>{
          return Number(unit.userId) === Number(item.userId)
        })
        if(in_task_user){
          taskUsers.push(in_task_user)
        }else{
          const in_list = list.find(unit=>{
            return Number(unit.userId) === Number(item.userId)
          })
          if(in_list){
            taskUsers.push(in_list)
          }
        }
      })

      data.taskUsers = taskUsers
      


      this.$emit('handleSure')
    },
    canDelete(item){
      const {data} = this
      const {task} = data
      const {userId, tousers} = task
      const login_user_id = tools.getUserId()
      // 创建者可以删除所有
      if(Number(login_user_id) === Number(userId)){
        return true
      }
      if(tousers){
        const tousers_arr = tousers.split(',')
        // 不是创建者 不能删除tousers中的用户
        if(tousers_arr.includes(String(item.userId))){
          return false
        }
      }
      return true
    }

  }
}
</script>

<style lang="scss" scoped>
.c_classes_task_modules_plugins_internal_contact_main{
  width: 100%;
  height: 100%;
  overflow: auto;
  .search_group{
    padding: {
      left: 30px;
      right: 20px;
    }
    width:400px;
    height:40px;
    background:rgba(243,243,243,1);
    border-radius:4px;
    position: relative;
    .search_icon{
      
    }
    .search{
      @include placeholder(#999);
      position: relative;
      color: #666;
      z-index: 2;
      flex: 1;
      padding: 0 10px;
      line-height: 40px;
      border: none;
      outline: none;
      background-color: transparent;
    }
    .clear_icon{
      position: absolute;
      z-index: 3;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .main_group{
    width: 100%;
    flex: 1;
    overflow: auto;
    border-top: 2px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
    .list_group{
      flex: 1;
      height: 100%;
      overflow: auto;
      position: relative;
      &.select_group{
        border-right: 1px solid #f0f0f0;
      }
      &.selected_group{
        border-left: 1px solid #f0f0f0;
      }
      .all_group{
        position: sticky;
        top: 0;
        left: 0;
        padding: {
          left: 20px;
          right: 20px;
        }
        background-color: #fff;
        height: 60px;
        .text{
          font-size: 14px;
        }
        .select{
          @include bbox;
          &.active{
            border:none;
            background-image: url(~@/assets/images/create_dialog/selected.png);
            background-size: 100%;
          }
          // @include transition;
          width:18px;
          height:18px;
          border:2px solid rgba(153,153,153,1);
          border-radius:50%;
        }
      }
    }
  }
  .fun_group{
    padding: 15px 0;
    .button{
      font-size: 16px;
      padding: 0 40px;
      border-radius: 4px;
      line-height: 40px;
      margin: 0 10px;
    }
  }
}
</style>