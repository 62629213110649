<!--
 * @Author       : Hugo
 * @Date         : 2022-04-13 15:09:34
 * @LastEditTime : 2022-04-15 10:40:22
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Plugin/FolderPreview.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="90%"
      max_width="80%"
      max_height="90%"
    >
      <div class="c_collect_plugin_select_route d-flex flex-column"
      >
        <!-- <div class="main d-flex align-start justify-space-between bbox"> -->
        <plugin-component
          class="plugin_component"
          :p_task_log="p_task_log"
          :p_folder="p_folder"
          :preview="true"
          @handleClose = "handleClose"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import {PFolder} from '@/types'
import tools from '@/api/tools.js'
import store from '@/store'
import global from '@/api/global.js'
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/Collect/FolderPreview/index.vue'
export default {
  components: {
    CommonWrapper,
    PluginComponent,
  },
  data(){
    return {
      visible: false,
      p_task_log: undefined,
      id: undefined,
      p_folder: undefined,
    }
  },
  mounted(){
    // // console.log(this.task, )
    // console.log('111')
    // this.init()
  },
  watch:{
    visible: function(val){
      if(val){
        this.init()
      }
    }
  },
  methods: {
    async init(){
      const {id} = this
      console.log(id)
      if(id){
        const data = {
          fId: id,
          sort: 0,
        }
        const res = await global.doPost({
          url: '/projectCustom/queryFolder',
          data,
        })
        if(res.message === 'success'){
          if(res.contents && res.contents.pFolder){
            const p_folder = new PFolder(res.contents.pFolder)
            this.$set(this, 'p_folder', p_folder)
            store.commit('set_collect_nav',[
              ...new Set([
                ...[p_folder]
              ])
            ])
            console.log(store.state.collect_nav)
          }
          
        }
      }
    },
    handleClose(){
      tools.removeCookie('collect_nav')
      store.commit('set_collect_nav', undefined)
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_plugin_select_route{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    border-top: 1px solid #f4f4f4;
  }
}
</style>
