<template>
  <div class="c_pop_up_batch_add_contacts_to_tasks_select_task_thumbnail_task ml-5 mr-10 mb-10">
    <thumbnail-task
      :p-task="pTask"
      :can-select="canSelect"
      :nonselectable="nonselectable"
      :selected="selected"
      @handleSelected="handleSelected"
    ></thumbnail-task>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/Thumbnail/Task'
export default {
  components: {
    ThumbnailTask
  },
  props: {
    pTask: {
      type: Object,
      default: undefined
    },
    userInfoSimplePmcs: {
      type: Object,
      default: undefined
    },
  },
  computed: {
    selected() {
      const { userInfoSimplePmcs, pTask } = this
      if (!userInfoSimplePmcs || !pTask) {
        return false
      }
      const { selectedList } = userInfoSimplePmcs
      if (selectedList.length === 0) {
        return false
      }
      const aim = selectedList.find((unit) => {
        return Number(unit.id) === Number(pTask.id)
      })
      if (aim) {
        return true
      }
      return false
    },
    canSelect() {
      const { nonselectable } = this
      return !nonselectable
    },
    nonselectable() {
      const { userInfoSimplePmcs, pTask } = this
      if (!userInfoSimplePmcs || !pTask) {
        return false
      }
      const { tousers } = pTask
      const { userId } = userInfoSimplePmcs
      if (!tousers) {
        return false
      }
      const arr = tousers.split(',')
      const aim = arr.find((item) => {
        return Number(item) === Number(userId)
      })
      if (aim) {
        return true // 已在任务中 不能被选择
      }
      return false
    }
  },
  methods: {
    handleDelete(params){
      this.$emit('handleDelete', params)
    },
    handleSelected(params) {
      const { userInfoSimplePmcs, pTask } = this
      const { selectedList } = userInfoSimplePmcs
      const index = selectedList.findIndex((item) => {
        return Number(item.id) === Number(pTask.id)
      })
      if (index === -1) {
        selectedList.push(pTask)
      } else {
        selectedList.splice(index, 1)
      }
      // this.$emit('handleSelected', params)
    },
    /**
     * @description: 获取颜色列表
     * @param {*} item
     * @return {*}
     */
    getColorList(item) {
      const { colour } = item
      if (!colour) {
        return ''
      }
      const color_list = colour.split(',')
      return color_list
    }
  }
}
</script>

<style scoped lang="scss">
</style>