var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template" }, [
    _c("div", { staticClass: "tel" }, [
      _c("ul", [
        _c("li", [
          _c("p", [_vm._v("手 机 号 码:")]),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userInfo.loginName,
                  expression: "userInfo.loginName",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请输入手机号码",
                maxlength: "11",
                name: "loginPhone",
              },
              domProps: { value: _vm.userInfo.loginName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userInfo, "loginName", $event.target.value)
                },
              },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.userInfo.loginName,
                  expression: "userInfo.loginName",
                },
              ],
              attrs: { src: require("../../../assets/icon-shut.png"), alt: "" },
              on: { click: _vm.empty },
            }),
          ]),
        ]),
        _c("li", [
          _c("p", [_vm._v("图形验证码:")]),
          _c("div", { staticClass: "input_group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userInfo.tuyzm,
                  expression: "userInfo.tuyzm",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请输入图形验证码",
                name: "verifyCode",
              },
              domProps: { value: _vm.userInfo.tuyzm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userInfo, "tuyzm", $event.target.value)
                },
              },
            }),
            _c("img", {
              staticClass: "imageCode",
              attrs: { src: _vm.Code, alt: "" },
              on: { click: _vm.imageCode },
            }),
          ]),
        ]),
        _c("li", [
          _c("p", [_vm._v("短信验证码:")]),
          _c("div", { staticClass: "input_group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smsCode,
                  expression: "smsCode",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请输入短信验证码",
                name: "smscode",
              },
              domProps: { value: _vm.smsCode },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.smsCode = $event.target.value
                },
              },
            }),
            _c("button", {
              staticClass: "get_verify_code",
              class: _vm.codeshow ? "noteCode" : "noCode",
              domProps: { innerHTML: _vm._s(_vm.getCode) },
              on: { click: _vm.clickGetCode },
            }),
          ]),
        ]),
      ]),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.telWarn) } }),
      _c("div", { staticClass: "btn" }, [
        _c("button", { staticClass: "lower cp", on: { click: _vm.nextstep } }, [
          _vm._v("下一步"),
        ]),
        _c(
          "button",
          { staticClass: "cancel cp", on: { click: _vm.cancelRegister } },
          [_vm._v("取消注册")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }