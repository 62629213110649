<!--
 * @Author       : Hugo
 * @Date         : 2020-06-15 08:41:09
 * @LastEditTime : 2021-10-11 12:16:39
 * @LastEditors  : Hugo
 * @Description  : 编辑控件页面显示头像 (可查看详情)
 * @FilePath     : /miaohang/src/components/basic/avatarDetail.vue
 * @^_^
--> 
<!-- 头像 可查看详情 -->
<template>
  <div class="basic_avatar_detail">
    <!-- 更新用户是自己的时候不展示详细信息 -->
    <!-- <el-popover
      placement="right-start"
      trigger="click"
      visible-arrow="false"
      v-model="visible"
      :disabled="$tools.getUserId() == msg_data.updateUserId"
      popper-class="basic_avatar_detail_popover"
    > 
      <div class="workteam_detail_unit_show_group">
        <div class="show_box">
          <div class="basic_group">
            <ContactDetailBasic :userData="userData"></ContactDetailBasic>
          </div>
          <div class="btn_group" v-if="dialog_name != 'fun_task_dialog_single' && !isSystemUser()">
            <div
              class="btn cp dialog"
              v-if="userData && userData.isfriend"
              @click="goToDialog();visible=false;"
            >发起对话</div>
            <div
              class="btn cp apply"
              v-if="userData && !userData.isfriend && userData.userId != $tools.getUserId()"
              @click="doApply();visible=false;"
            >申请联系人</div>
          </div>
        </div>
      </div> -->
      <div
        class="content cp"
        slot="reference"
        @click="getUserInfo"
        :style="{width: width}"
      >
        <img
          :style="{width: width, height: height}"
          :src="`${$avatar_url}${msg_data.thumbnail}@!small200`"
          alt=""
          class="avatar"
        >
      </div>
      
    <!-- </el-popover>     -->
  </div>
</template>
<script>
// import ContactDetailBasic from '@/components/Contact/ContactDetailBasic.vue';
export default {
  components: {
    // ContactDetailBasic,
  },
  data(){
    return{
      dialog_name: this.$route.name, //会话名
      userData: null,
      visible: false,
    }
  },
  props: {
    msg_data: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '44px',
    },
    height: {
      type: String,
      default: '44px',
    }
  },
  computed: {
    
  },
  watch:{
    msg_data: {
      handler: function(){},
      deep: true,
    },
    type: function(){},
    '$route.name': function(newval){
      this.dialog_name = newval;
    },
  },
  methods: {
    /**获取用户信息 */
    async getUserInfo(){
      
      let chatType = this.$tools.getChatType();

      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();

      // let {thumbnail, userName} = this.msg_data;
      // if(thumbnail && userName){
      //   this.$set(this, 'userData', this.msg_data);
      //   // console.log('his, userData', this.msg_data)
      //   return;
      // }
      // // console.log(this.msg_data)
      let loginUserId = this.$tools.getUserId();
      let userId = this.msg_data.fromUserId || this.msg_data.userId || this.msg_data.byUserId;
      this.$api.user.getInfo({userId});
      return;
      let data = {
        userId,
      }
      
      if(chatType == 1 && chatId){
        data.chatId = chatId;
      }
      // 不是当前登录用户
      let res = await this.$http.request('/project/getUserById',data);
      
      let userinfo = res.contents.user;
      this.$set(this, 'userData', userinfo);

      this.$nextTick(function(){
        this.$utils.setPopoverZIndex('.basic_avatar_detail_popover');
        const popover = document.querySelectorAll('.basic_avatar_detail_popover');
        if(popover && popover.length){
          let z = this.$utils.getZIndex();
          popover.forEach(item=>{
            item.style.zIndex = z;
          })
        }
      })
    },
    /**跳转到会话 */
    goToDialog(){
      this.$api.common_goToSingleDialog(this.userData);
    },
    /**
     * 申请联系人
     */
    doApply(){
      // this.$api.common_open_add_friend_box();
      this.$store.commit('to_add_friend', this.userData);
    },
    // 是否系统用户
    isSystemUser(){
      let status = false;
      
      const {msg_data} = this;
      if(msg_data){
        let userId = msg_data.fromUserId || msg_data.userId || msg_data.byUserId;
        status = this.$api.isSystemUser(userId);
      }
      return status;
    }
  },


}
</script>
<style lang="scss" scoped>
.basic_avatar_detail{
  // width: 44px;
  // padding-bottom: 33px;
  .show_group{
    @include bbox;
    width: 340px;
    padding: 20px;
    .show_box{
      width: 100%;
      min-height: 500px;
    }
  }
  .content{
    // width:44px;
    .avatar{
      // width: 44px;
      // height: 44px;
      border-radius: 4px;
      display: block;
      background-color: $img_color;
    }
  }
}
</style>
<style lang="scss">
  .workteam_detail_unit_show_group{
    @include bbox;
    width: 340px;
    padding: 20px;
    .show_box{
      width: 100%;
      min-height: 500px;
      .btn_group{
        @include flex;
        justify-content: center;
        width:100%;
        padding-top:12px;
        .btn{
          @include transition;
          padding:0 30px;
          line-height:40px;
          border:2px solid rgba(0,84,166,1);
          border-radius:4px;
          font-size:16px;
          
          font-weight:400;
          color:rgba(0,84,166,1);
          background-color: transparent;
          &:hover{
            background-color: #DCE7F1;
          }
        }
      }
    }
  }
</style>