var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "plugin_confirm",
            style: { "z-index": _vm.zindex },
            on: { click: _vm.doSure },
          },
          [
            _c(
              "div",
              {
                staticClass: "confirm_group",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.stopPropagation()
                  },
                },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("div", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))]),
                _c("div", { staticClass: "fun_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn sure",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.doSure.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.sure))]
                  ),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }