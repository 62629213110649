var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_migrate_task_select_task d-flex flex-column justify-space-between",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.params.checkSelected,
              expression: "!params.checkSelected",
            },
          ],
          staticClass: "search_wrapper d-flex align-center bbox flex-shrink-0",
        },
        [
          _c(
            "div",
            {
              staticClass: "all_group d-flex align-center cp mr-20",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleALl.apply(null, arguments)
                },
              },
            },
            [
              _c("div", {
                staticClass: "all_icon mr-5",
                class: { active: _vm.isAll },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n        当前全部任务\n      "),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "search_group d-flex align-center bbox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "icon mr-4" }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.search,
                    expression: "search",
                  },
                ],
                ref: "search",
                staticClass: "search bbox",
                attrs: { type: "text", placeholder: "搜索..." },
                domProps: { value: _vm.search },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleSearchKeydown.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.search = $event.target.value
                  },
                },
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.search.trim() !== "",
                    expression: "search.trim() !== ''",
                  },
                ],
                staticClass: "clear",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearSearch.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { ref: "listWrapper", staticClass: "list_wrapper bbox" },
        [
          _c("task-list", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.list && _vm.list.length,
                expression: "list && list.length",
              },
            ],
            staticClass: "list_group bbox",
            attrs: {
              list: _vm.list,
              "page-data": _vm.pageData,
              params: _vm.params,
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.loadingTask && (!_vm.list || _vm.list.length === 0),
                  expression: "!loadingTask && (!list || list.length===0)",
                },
              ],
              staticClass: "no_task_group d-flex align-center justify-center",
            },
            [
              _c("div", { staticClass: "no_task" }, [
                _vm._v("\n        无相关任务\n      "),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.noMore,
                  expression: "!noMore",
                },
              ],
              staticClass:
                "loadmore_group d-flex align-center justify-center bbox flex-shrink-0",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loadingTask,
                      expression: "!loadingTask",
                    },
                  ],
                  staticClass: "load_more cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.loadingMoreTask.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        加载更多\n      ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loadingTask,
                      expression: "loadingTask",
                    },
                  ],
                  staticClass: "loading d-flex align-center justify-center",
                },
                [_c("loading-icon", { attrs: { size: "20px" } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("operation-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.params.checkSelected,
            expression: "!params.checkSelected",
          },
        ],
        staticClass: "operation_bar bbox",
        attrs: { "page-data": _vm.pageData },
        on: {
          handleCheckSelectedList: _vm.handleCheckSelectedList,
          handleClose: _vm.handleClose,
          handleSure: _vm.handleSure,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }