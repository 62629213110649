<template>
  <div class="pages">
    <div class="pages-left">
      <h1 class="logo">
        <img src="../../assets/logo.png" alt />
      </h1>
      <div
        class="tupian"
        @click="book(1)"
        :style="disabled?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-message.png" alt class="book" v-if="disabled" />
        <img src="../../assets/book.png" alt class="book" v-else />
      </div>
      <div class="bok"></div>
      <div
        class="pple"
        @click="book(2)"
        :style="people?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/icon-people-sel.png" alt class="people" v-if="people" />
        <img src="../../assets/icon-people-nor.png" alt class="people" v-else />
      </div>
      <div
        class="mpa"
        @click="book(3)"
        :style="mpashow?'backgroundColor:#FFFFFF;':'backgroundColor:#0054A6;'"
      >
        <img src="../../assets/modulex.png" alt class="mplae" v-if="mpashow" />
        <img src="../../assets/icon-Template-nor.png" alt class="mplae" v-else />
      </div>
      <img src alt class="phical" />
    </div>

    <div class="pages-con">
      <div class="bar">
        <div class="barinput">
          <input type="text" v-model="searchString" placeholder="搜索" class="hder" />
          <img src="../../assets/icon-search.png" alt class="search" />
        </div>
        <div class="imgbox">
          <img src="../../assets/icon-add.png" alt class="jia" />
        </div>
      </div>
      <div class="listbox">
        <ul class="list">
          <li class="lbo" v-for="(item, index) in zhidingArr" :key="index">
            <!-- 置顶 -->
            <div class="lbo1">
              <img src alt class="img1" />
              <div class="lboleft">
                <div class="zhao">
                  <p class="ptop">{{item.name}}</p>
                  <p class="tim">11:45</p>
                  <img src="../../assets/label-Sticky.png" alt class="sticy" />
                </div>
                <div class="pbottom">
                  <p class="vloe">{{item.explain}}</p>
                  <!-- <img src="../../assets/icon-More.png" alt class="more" @click="diandian(index)"/> -->
                  <img src="../../assets/icon-More.png" alt class="more" @click="diandian(item)"/>
                </div>
                <p class="qu" v-if="item.flag">取消置顶</p>
              </div>
            </div>
            <div class="linex"></div>
          </li>
        </ul>
        <ul class="list">
          
          <li class="lbo" v-for="(item,index) in 50" :key="index" @click="lbo(index)">
            <!-- 111 -->
            <div class="lbo1">
              <img src alt class="img1" />
              <div class="lboleft">
                <div class="zhao">
                  <p class="ptop">赵丽颖</p>
                  <p class="tim">11:45</p>
                  <img src="../../assets/label-Sticky.png" alt class="sticy" />
                </div>
                <div class="pbottom">
                  <p class="vloe">按照VIVO的KV，开始...</p>
                  <img src="../../assets/icon-More.png" alt class="more" />
                </div>
                <!-- <p class="qu">置顶</p> -->
                <!-- <p class="qu">置顶</p> -->
              </div>
            </div>
            <div class="linex"></div>
          </li>
        </ul>
      </div>
    </div>
    <div class="dtleft">
      <DetailInfo :info="detailsInfo"></DetailInfo>
      <!-- <div class="leftc" ></div>
      <div class="leftc1"></div>-->
    </div>
  </div>
</template>

<script>
import DetailInfo from "@/components/DetailInfo";
export default {
  components: { DetailInfo },
  data() {
    return {
      searchString: "",
      disabled: false,
      people: false,
      mpashow: false,
      item: "",

      zhidingArr:[
        {
          img:'ad',
          name:'赵丽颖',
          explain:'按照valu的对照',
          flag:false,
        },
        {
          img:'ad',
          name:'周深',
          explain:'准备竞标方案，先制作...',
          flag:false,

        },
        {
          img:'ad',
          name:'米希亚',
          explain:'按照VIVO的KV，开始...',
          flag:false,

        },
         {
          img:'ad',
          name:'赵老师',
          explain:'按照VIVO的KV，开始...',
          flag:false,

        },
         {
          img:'ad',
          name:'李现',
          explain:'按照VIVO的KV，开始...',
          flag:false,

        },
       
        
       
      ],
      detailsInfo:'',
    };
  },
  methods: {
    book(index) {
      if (index == 1) {
        this.mpashow = false;
        this.people = false;
        this.disabled = true;
        
      }
      if (index == 2) {
        this.mpashow = false;
        this.disabled = false;
        this.people = true;
        this.$router.push("/Contacts/contacts") 
      }
      if (index == 3) {
        this.people = false;
        this.disabled = false;
        this.mpashow = true;
         this.$router.push("/Detail/Deta")
      }
    },
    pple() {
      this.people = !this.people;
    },
    mpaclick() {
      this.mpashow = !this.mpashow;
    },
    lbo(index) {
      // console.log(11111, index);
      //  this.lbo=item
      this.detailsInfo=index;
    },
   
    // diandian(index){
    //   // console.log('diandiand')
    //   this.zhidingArr.forEach(v => {
    //     // console.log(v)
    //     v.flag=false;
    //   });
    //   this.zhidingArr[index].flag=true;
    // }
     diandian(e){
      // console.log('diandiand',e)
      this.zhidingArr.forEach(v => {
        // console.log(v)
        v.flag=false;
      });
      // this.zhidingArr[index].flag=true;
      item.flag=true;
    },
    //  book(){
    //     // @click.book(2) = !click.book(2),
    //   // this.$router.push("/Contacts/contacts")
    // }
  }
};
</script>

<style lang='less' scoped>
.pages {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  display: flex;

  .pages-left {
    width: 60px;
    height: 100%;
    background: #0054a6;
    .logo {
      display: block;
      width: 48px;
      height: 48px;
      margin-top: 6px;
      margin-left: 6px;
    }
    .tupian {
      widows: 62px;
      height: 50px;
      margin-top: 30px;
      .book {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 12px;
        z-index: 11;
      }
    }
    .pple {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .people {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }
    .mpa {
      width: 60px;
      height: 50px;
      margin-top: 10px;
      .mplae {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 auto;
        padding: 13px;
        z-index: 11;
      }
    }

    .phical {
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background: orange;
      margin-top: 381px;
      margin-left: 8px;
    }
  }
  .pages-con {
    width: 250px;
    height: 100%;
      //  overflow:hidden;
    .bar {
      display: flex;
      width: 250px;
      height: 66px;
      border-bottom: 2px solid #f4f4f4;
      overflow: hidden;
      .barinput {
        margin-top: 16px;
        margin-left: 16px;
        .hder {
          width: 138px;
          height: 30px;
          background: rgba(243, 243, 243, 1);
          border-radius: 15px;
          border: none;
          outline: none;
          position: relative;
          padding-left: 40px;
        }
        .search {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 24px;
          left: 86px;
        }
      }
      .imgbox {
        width: 30px;
        height: 30px;
        background: rgba(243, 243, 243, 1);
        border-radius: 50%;
        margin-left: 14px;
        margin-top: 16px;
        .jia {
          display: block;
          width: 10px;
          height: 11px;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }
    // .line1{
    //     width:250px;
    //     height:2px;
    //     background:rgba(244,244,244,1);
    //     margin-top:16px;
    // }

    .listbox {
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
      .list {
        .lbo {
          width: 250px;
          height: 66px;
          display: flex;
          .lbo1 {
            display: flex;

            position: relative;
            .img1 {
              display: block;
              width: 44px;
              height: 44px;
              background: pink;
              margin-top: 11px;
              margin-left: 17px;
              
            }
            .lboleft {
              // position: relative;

              .zhao {
                display: flex;
                .ptop {
                  display: block;
                  width: 42px;
                  height: 14px;
                  font-size: 14px;
                  font-weight: 400;
                  color: rgba(51, 51, 51, 1);
                  margin-left: 16px;
                  margin-top: 14px;
                }
                .tim {
                  width: 30px;
                  height: 9px;
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(153, 153, 153, 1);
                  margin-left: 81px;
                  margin-top: 6px;
                }
                .sticy {
                  display: block;
                  width: 14px;
                  height: 14px;
                  margin-left: 6px;
                }
              }
              .pbottom {
                display: flex;
                .vloe {
                  width: 126px;
                  height: 13px;
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(153, 153, 153, 1);
                  margin-top: 9px;
                  margin-left: 16px;
                }
                .more {
                  display: block;
                  width: 2px;
                  height: 10px;
                  margin-left: 25px;
                  margin-top: 12px;
                  // position: relative;
                }
              }
              .qu {
                width: 120px;
                height: 50px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
                opacity: 0.9;
                border-radius: 4px;
                font-size: 14px;
                line-height: 50px;
                text-align: center;
                margin-left: 68px;
                margin-top: 36px;
                position: absolute;
                z-index: 99;
                top:68px;
                left: 100px;
              }
            }
          }
          .linex {
            width: 174px;
            height: 1px;
            background: rgba(244, 244, 244, 1);
            margin-left: 76px;
          }
        }
      }
      .lbo:hover {
        width: 250px;
        height: 66px;
        background: rgba(244, 244, 244, 1);
      }
    }

    .listbox::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  .linebox {
    width: 2px;
    height: 100%;
    background: rgba(244, 244, 244, 1);
    .lb {
      width: 250px;
      height: 66px;
    }
  }
  .dtleft {
    width: 100%;
    height: 100%;
    // background: pink;

    .leftc {
      width: 100%;
      height: 100%;
      background: orange;
    }
    .leftc1 {
      width: 100%;
      height: 100%;
      background: pink;
    }
  }
}
</style>