<template>
  <div class="c_pop_up_batch_add_contacts_to_tasks_selected_task_list_index bbox d-flex flex-column">
    <div class="main_group bbox">
      <div class="sticky_group bbox">
        <div class="filter_group bbox">
          <div class="search_group">
            <input
              type="text"
              class="search"
              placeholder="搜索"
              v-model="pageData.search"
            >
            <img
              v-show="pageData.search !== ''"
              src="~@/assets/images/common/clear_icon.png"
              alt=""
              class="clear cp"
              @click.stop="pageData.search = ''"
            >
          </div>
        </div>
      </div>
      <div class="container">
        <task-list
          :task-list="taskList"
          :search="pageData.search"
        ></task-list>
      </div>
    </div>
    <operation-bar
      class="flex-shrink-0"
      :task-list="taskList"
      :user-info-simple-pmcs="userInfoSimplePmcs"
    ></operation-bar>
  </div>
</template>

<script>
import tools from '@/api/tools'
import TaskList from '@/components/PopUp/BatchAddContactsToTasks/SelectedTaskList/TaskList'
import OperationBar from '@/components/PopUp/BatchAddContactsToTasks/SelectedTaskList/OperationBar'
import { listForClasses, PTask } from '@/types'
export default {
  name: 'index',
  components: {
    OperationBar,
    TaskList
  },
  props: {
    userInfoSimplePmcs: {
      type: Object,
      default: undefined
    },
    taskList: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      // taskList: [],
      pageData: {
        search: ''
      }
    }
  },
  methods: {
    handleSearch(params) {
      const { search } = params
      this.pageData.search = search
      console.log(this.pageData)
    },
    init() {
      this.generateTaskList()
    },
    generateTaskList() {
      const { packageSelectTask } = this
      const { selectedList } = packageSelectTask
      // return tools.deepClone(selectedList)
      const taskList = listForClasses({
        list: tools.deepClone(selectedList),
        classes: PTask,
        defaultParams: { selected: true } // 默认选中
      })
      this.$set(this, 'taskList', taskList)
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_batch_add_contacts_to_tasks_selected_task_list_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  .main_group{
    @include scrollbar;
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .sticky_group{
      position: sticky;
      width: 100%;
      background-color: #f8f8f8;
      top: 0;
      left: 0;
      z-index: 3;
      padding: 20px 20px 0;
    }
    .filter_group{
      width: 100%;
      //padding: 16px 20px;
      .search_group{
        width: 200px;
        position: relative;
        background: #E9E9E9;
        border-radius: 0 4px 4px 0;
        .search{
          @include placeholder(#999);
          position: relative;
          z-index: 2;
          width: 100%;
          line-height: 30px;
          height: 30px;
          border: none;
          background-color: transparent;
          outline: none;
          font-size: 14px;
          color: #333;
          padding: {
            left: 10px;
            right: 30px;
          }
        }
        .clear{
          position: absolute;
          z-index: 3;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>