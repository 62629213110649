<!--
 * @Author       : Hugo
 * @Date         : 2021-11-15 11:32:37
 * @LastEditTime : 2022-04-20 14:35:43
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/Common/Title.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_pop_up_common_title d-flex align-center justify-center"
  > 
    <template
      v-if="$slots.default"
    >
      <div class="slot_basic"
        :style="{
          'border-radius': `${border_radius} ${border_radius} 0 0`,
          'background-color': background_color,
        }"
      >
        <slot></slot>
      </div>
    </template>
    <div class="wrapper_group d-flex align-center justify-center"
      :style="{
        'border-radius': `${border_radius} ${border_radius} 0 0`,
        'background-color': background_color
      }"
      v-else
    >
      <div class="title">{{title}}</div>
      <div class="close cp"
        @click.stop="$emit('handleClose')"
        v-if="close"
      ></div>
    </div>
  </div> 
    
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Default Title',
    },
    border_radius: {
      type: String,
      default: '4px',
    },
    close: {
      type: Boolean,
      default: false,
    },
    background_color: {
      type: String,
      default: '#F8F8F8',
    }
  },
  mounted(){
    // console.log(this.close);
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_common_title{
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  border-radius: 4px 4px 0 0;
  position: relative;
  z-index: 2;
  .slot_basic{
    width: 100%;
    overflow: hidden;
  }
  .wrapper_group{
    width: 100%;
    height: 46px;
    // background: #F8F8F8;
  }
  .close{
    @include background(12px, 12px);
    position: absolute;
    width: 20px;
    height: 20px;
    background: {
      image: url(~@/assets/images/pop_up/close.png);
    }
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
