<!--
 * @Author       : Hugo
 * @Date         : 2022-01-06 14:59:03
 * @LastEditTime : 2022-01-17 17:05:10
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Payment/BuyNow/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_payment_buy_now_index bbox d-flex flex-column justify-space-between"
  >
    <div class="info_group">
      <div class="task_name_group d-flex align-center">
        <div class="name mr-20">任务名称</div>
        <div class="task_name">{{title}}</div>
      </div>
      <div class="unit_price_group d-flex align-center mt-20">
        <div class="name mr-20">任务单价</div>
        <div class="price">¥ {{unit_price}}</div>
      </div>
      <div class="line mt-20"></div>
      <div
        class="d-flex align-center mt-20"
        :class="{gray: binding === undefined}"
      >
        <div class="name mr-6">是否绑定在此账号？</div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="right"
          popper-class=""
        >
          <div slot="content">如绑定在此账号，购买后即可自动绑定在该账户<br/>如绑定在其他账号，购买后需在我的订单中查看激活码手动激活。</div>
          <img src="~@/assets/images/common/question_mark.png" alt="" class="question_mark">
        </el-tooltip>
      </div>
      <div class="binding_group d-flex align-center mt-20"
        :class="{gray: binding === undefined}"
      >
        <div
          class="button_group mr-10"
          :class="{
            active: binding === 0,
            disabled: task.buy
          }"
        >
          <div
            class="button"
            @click.stop="handleBinding(0)"
          >
            是
          </div>
          <div class="placeholder mb-5">&nbsp;</div>
          <div class="hint">*购买后将自动绑定至此账户中</div>
        </div>
        <div
          class="button_group"
          :class="{active: binding === 1}"
        >
          <div class="button"
            @click.stop="binding = 1;"
          >否</div>
          <div class="placeholder mb-5">&nbsp;</div>
          <div class="hint">*购买后需在我的订单中查看激活码手动激活</div>
        </div>
      </div>
      <div class="binding_number_group  d-flex align-center mt-20"
        v-show="binding === 1"
      >
        <div class="name mr-20">选择绑定账户数</div>
        <number-group
          :number="binding_number"
          @handleChange="bindingNumberChange"
          @handleMinus="bindingNumberMinus"
          @handlePlus="bindingNumberPlus"
        ></number-group>
      </div>
      <div class="line my-10"></div>
      <div
        class="d-flex align-center mt-10"
        :class="{gray: binding === undefined}"
      >
        <div class="name mr-6">是否需要同时购买VIP</div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="vip_hint"
          placement="right"
          popper-class=""
        >
          <img src="~@/assets/images/common/question_mark.png" alt="" class="question_mark">
        </el-tooltip>
      </div>
      <div 
        class="binding_group d-flex align-center mt-20"
        :class="{gray: binding === undefined}"
      >
        <div class="button_group mr-10"
          :class="{active: buy_vip === true}"
        >
          <div class="button"
            @click.stop="choiceBuyVip(true);"
          >是</div>
          <div class="placeholder mb-5">&nbsp;</div>
          <!-- <div class="hint">*购买后将自动绑定至此账户中</div> -->
        </div>
        <div class="button_group"
          :class="{active: buy_vip === false}"
        >
          <div class="button"
            @click.stop="choiceBuyVip(false);"
          >否</div>
          <div class="placeholder mb-5">&nbsp;</div>
          <div class="hint"
            v-if="vip_hint_status"
          >*非VIP账户使用时部分功能可能会受限</div>
        </div>
      </div>
    
      <div class="binding_number_group  d-flex align-center mt-10" 
        v-show="buy_vip === true"
      >
        <div class="name mr-20">选择VIP年限</div>
        <number-group
          :number="vip_number"
          @handleChange="vipNumberChange"
          @handleMinus="vipNumberMinus"
          @handlePlus="vipNumberPlus"
        ></number-group>
      </div>
    </div>  
      <!-- :class="{gray: disable_submit}" -->
    <div class="submit_group un_sel mt-20 flex-shrink-0"
      :class="{gray: buy_vip === undefined}"
    >
      <div class="line"></div>
      <div class="total_price_group d-flex align-center  mt-20">
        <div class="name">总计：</div>
        <div class="price mx-10">¥ {{total_price}}</div>
        <el-tooltip
          v-show="show_total_hint"
          class="item"
          effect="dark"
          placement="right"
          popper-class=""
        >
          <div slot="content">计算过程：<br/>{{total_price_hint}}</div>
          <img src="~@/assets/images/common/question_mark.png" alt="" class="question_mark">
        </el-tooltip>
      </div>
      <div class="button submit btn_dark mt-20"
        @click.stop="doSubmit();"
      >确认</div>
    </div>
  </div>
</template>

<script>
import NumberGroup from './Number.vue';
import {POrder} from '@/types';
import store from '@/store';
import global from '@/api/global';
export default {
  components: {
    NumberGroup,
  },
  props: {
    task: {
      type: Object,
      default: undefined,
    },
  },
  computed:{
    title(){
      const {task} = this;
      if(task === undefined){
        return '';
      }
      return task.name;
    },
    unit_price(){
      const {task} = this;
      if(task === undefined){
        return '';
      }
      if(task.price == null){
        return '';
      }

      return Number(Number(task.price/100).toFixed(2));
    },
    disable_submit(){ // 禁止提交
      const {binding, buy_vip} = this;
      if(binding === undefined || buy_vip === undefined){
        return true;
      }
      return false;
    },
    total_price(){
      const {binding, buy_vip, unit_price, binding_number, vip_number, member_price} = this;
      let total_price = unit_price;
      if(binding === 1){
        total_price *=binding_number;
      }
      if(buy_vip){
        total_price += (member_price??0)/100 * binding_number * vip_number;
      }
      return Number(total_price).toFixed(2);
    },
    show_total_hint(){
      if(this.total_price){
        return true;
      }
      return false;
    },
    total_price_hint(){
      const {binding, buy_vip, unit_price, binding_number, vip_number, total_price, member_price} = this;
      let hint = `模板单价 ${unit_price} `;
      if(binding === 1){
        hint += `× 账户数 ${binding_number} `
      }
      if(buy_vip){
        hint += `+ 会员单价 ${(member_price??0)/100} × 账户数 ${binding_number} × 年限 ${vip_number} `
      }
      hint += `=总价 ${total_price}`
      return hint;
    },
    vip_hint(){
      const {binding,} = this;
      const user = this.$tools.getCookie('user');
      const {vipEndDate, userVip} = user;
      let hint = '如被绑定账户非vip账户，使用时部分功能可能会受限。';
      if(userVip === 1 && binding === 0){
        // 为自己购买
        hint = `您的VIP截止日期：${this.$moment(vipEndDate).format('YYYY年MM月DD日')}；VIP到期后，使用时部分功能可能会受限。`
      }
      return hint;
    },
    vip_hint_status(){
      const {binding,} = this;
      const user = this.$tools.getCookie('user');
      const {userVip} = user;
      if(binding === 0 && userVip === 1){
        return false;
      }
      return true;
    }
  },
  data(){
    return {
      binding: undefined, // 是否绑定 0自己 1代买
      binding_number: 1, // 绑定数量
      buy_vip: undefined, // 绑定vip 
      vip_number: 1, // vip年限
      member_price: undefined, // 会员价格
    }
  },
  mounted() {
    this.init()
    console.log(this.task)
  },
  methods: {
    handleBinding(binding) {
      const { task } = this
      const { buy } = task
      // 已经购买无法再点击给自己购买
      if (buy && binding === 0) {
        this.$notice({
          desc: '该账号已购买当前任务，无法重复购买！'
        })
        return
      }
      this.binding = binding
    },
    bindingNumberChange(number){
      this.binding_number = number;
    },
    bindingNumberMinus(){
      this.binding_number -= 1;
    },
    bindingNumberPlus(){
      this.binding_number += 1;
    },
    vipNumberChange(number){
      this.vip_number = number;
    },
    vipNumberMinus(){
      this.vip_number -= 1;
    },
    vipNumberPlus(){
      this.vip_number += 1;
    },
    async doSubmit(){
      const {binding, buy_vip, task, binding_number, vip_number, total_price} = this;
      if(binding === undefined){
        this.$notice({desc: '请选择是否绑定在此账号'});
        return; 
      }
      if(buy_vip === undefined){
        this.$notice({desc: '请选择是否需要同时购买VIP'});
        return; 
      }
      const {id:taskId, price} = task;
      const data = {
        taskId,
        payType: 1, //    支付类型(1微信支付,2支付宝支付,3苹果支付)
        price: total_price*100, // 支付价格
        selfOrOther: binding, //0自己 1代买
        vipyears: buy_vip ? vip_number : 0, //vip年限  一年为1
        num: binding === 1 ? binding_number : 1,  //绑定账户数 自己购买时为1
      }
      this.$showLoading({text:'正在生成订单'});
      const res = await this.$global.doPost({
        url: '/order/getUserBuyTaskOrder',
        data,
      })
      const {message, contents} = res;
      if(message === 'success'){
        const {orderTask} = contents;
        const order = new POrder(orderTask);
        this.$scan_code({
          title: '购买支付',
          order,
        })
      }
      this.$hideLoading();
    },
    async init(){
      this.getMemberPrice();
    },
    /**
     * 获取会员费
     */
    async getMemberPrice(){
      const {state, commit} = store;
      const {start} = state;
      if((start??'') === '' || (start.priceYear??'' === '')){
        const res = await global.doPost({
          url: '/project/start', 
          data: {
            type: 4,
            version: '2.0.1'
          }
        })
        if(res.message === 'success'){
          const {appdownDto} = res.contents;
          this.member_price = appdownDto.priceYear;
          commit('set_start', appdownDto);
        }
        return;
      }

      this.member_price = start.priceYear;
    },
    choiceBuyVip(val){
      const {binding} = this;
      if(binding === undefined){
        this.$notice({desc: '请选择是否绑定在此账号'});
        return;
      }
      this.buy_vip = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.components_payment_buy_now_index{
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #333;
  .question_mark{
    width: 16px;
    height: 16px;
    display: block;
  }
  // .gray{
  //   @include transition;
  //   opacity: 0.8;
  //   filter: grayscale(90%);
  //   // color: #ccc!important;
  // }
  .line{
    width: 100%;
    height: 2px;
    background-color: #f4f4f4;
  }
  .info_group,
  .submit_group{
    width: 100%;
    .total_price_group{
      .price{
        font-size: 30px;
        color: #F3580C;
        font-weight: bold;
      }
    }
    .submit{
      font-size: 16px;
      line-height: 44px;
      width: 410px;
      border-radius: 4px;
      text-align: center;
    }
  }
  .info_group{
    flex: 1;
    overflow-y: auto;
  }
  .task_name_group{
    .task_name{
      font-weight: bold;
      font-size: 20px;
    }
  }
  .unit_price_group{
    .price{
      font-weight: bold;
      font-size: 20px;
    }
  }
  .button_group{
    width:200px;
    position: relative;
    z-index: 2;
    &.disabled{
      &.active{
        .button{
          border-color: #E5E7EA;
          background-color: #F0F5FA;
        }
        .hint{
          opacity: 1;
        }
      }
    }
    &:hover{
      .button{
        border-color: #0054A7;
      }
      .hint{
        opacity: 1;
      }
    }
    &.active{
      .button{
        border-color: #0054A7;
        background-color: #F0F5FA;
      }
      .hint{
        opacity: 1;
      }
    }
    .button{
      @include transition;
      width: 200px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #E5E7EA;
    }
    .placeholder{
      line-height: 20px;
      font-size: 12px;
      height: 20px;
    }
    .hint{
      @include transition;
      opacity: 0;
      position: absolute;
      z-index: 3;
      bottom: 5px;
      left: 0;
      word-break: keep-all;
      line-height: 20px;
      font-size: 12px;
      color: #617B9D;
      white-space: nowrap;
    }
    
  }
  .gray{
    color: #ccc;
    border-color: #E5E7EA;
    div{
      color: #ccc;
      border-color: #E5E7EA;
    }
    // .button{
    //   &:hover{
    //     border-color: #cacccf!important;
    //     background-color: #FBFBFB;
    //   }
    // }
    .price{
      color: #ccc!important;
    }
    .submit{
      color: #fff!important;
      background-color: #ddd!important;
    }
    &:hover{
      color: #ccc;
      border-color: #E5E7EA;
      div, .button{
        color: #ccc;
        border-color: #E5E7EA;
      }
    }
  }
}
</style>
