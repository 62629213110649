var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_select_task_thumbnail_package" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.pPackage.title) + "\n  "),
      ]),
      _c("img", {
        staticClass: "picture",
        attrs: { src: `${_vm.$img_url}${_vm.pPackage.webpic}`, alt: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }