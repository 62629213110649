<template>
  <div
    class="brief_title_group"
    :class="[`brief_type_${briefType(relevance? msg.controlType : (msg.controlTypeP || msg.controlType) )}`]"
    @click.stop="openAppoint();"
  >
    <div class="icon"></div>
    <div class="update_title">{{relevance? '关联: ': ''}}{{content}}</div>
    <!-- <div class="update_title">{{content}}</div> -->
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    },
    relevance: {
      type: Boolean,
      default: false,
    },
    log: {
      type: Object,
      default: null,
    }
  },
  computed: {
    content(){
      const {msg, relevance, log} = this;
      const {conTitleP, conTitle, conIdP, controlType, type} = msg;
      if(controlType === 101){
        if(type == 2){
          return '任务标题修改';
        }
        return log.taskName;
      }
      if(conIdP && !relevance){
        return conTitleP || '未命名标题';
      }
      return conTitle || '未命名标题';
    },
  },
  methods: {
    /**
     * @description: 简述类型 [模板, 文本, 数字.... ]
     * @param {*} controlType
     * @return {*}
     */
    briefType(controlType){
      let type = '';
      switch (controlType) {
        case 101: type = 'template';
        break;
        case 102: type = 'template';
        break;
        case 103: type = 'template';
        break;
        case 104: type = 'relevancy';
        break;
        case 1: type = 'text';
        break;
        case 2: type = 'number';
        break;
        case 3: type = 'money';
        break;
        case 4: type = 'time';
        break;
        case 5: type = 'formula';
        break;
        case 6: type = 'attachment';
        break;
        case 7: type = 'hyperlink';
        break;
        case 8: type = 'location';
        break;
        case 9: type = 'picture';
        break;
        case 10: type = 'contact';
        break;
        case 11: type = 'wisdom';
        break;
        default: type = 'template';
        break;
      }
      return type;
    },
    openAppoint(){
      // console.log('openAppoint')
      const {msg, relevance} = this;
      const {conId, conIdP} = msg;
      if(!relevance && conIdP){
        this.$emit('handleAppoint', {parent_id: null, widget_id: conIdP});
        return;
      }
      this.$emit('handleAppoint', {parent_id: conIdP, widget_id: conId});

    }
  }
}
</script>
<style lang="scss" scoped>
@mixin brief_action($action){
  @if $action == create{
    color: #00651C;
  } @else if $action == update{
    color: #DD8300;
  } @else if $action == delete{
    color: #C85454;
  }
}
@mixin brief_type($type){
  &.brief_type_#{$type} .icon{
    @include background;
    background-image: url(~@/assets/images/dialog/brief_#{$type}.png);
  }
}
.brief_title_group{
  @include flex;
  @include brief_type(template);
  @include brief_type(relevancy);
  @include brief_type(text);
  @include brief_type(number);
  @include brief_type(money);
  @include brief_type(time);
  @include brief_type(formula);
  @include brief_type(attachment);
  @include brief_type(hyperlink);
  @include brief_type(location);
  @include brief_type(picture);
  @include brief_type(contact);
  @include brief_type(wisdom);
  font-size: 14px;
  align-items: flex-start;
  padding: {
    bottom: 5px;
  }
  &.brief_action_create{
    @include brief_action(create);
  }
  &.brief_action_update{
    @include brief_action(update);
  }
  &.brief_action_delete{
    @include brief_action(delete);
  }
  &:hover{
    text-decoration: underline;
  }
  .icon{
    width: 17px;
    height: 17px;
    margin-right: 4px;
    flex-shrink: 0;
    margin-top:2px;
  }
  .update_action{
    padding-right: 4px;
    flex-shrink: 0;
  }
  .update_type{
    padding-right: 4px;
    flex-shrink: 0;
  }
  .update_title{
    padding-right: 4px;
    color: #333;
    font-weight: bold;
    max-width: 650px;
    line-height: 20px;
  }
}
</style>