var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_popup_record_of_communication_file_index_page bbox",
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c("unit", {
            key: index,
            staticClass: "mb-20",
            attrs: { item: _vm.PFile(item), keyword: _vm.keyword },
          }),
        ]
      }),
      _vm.nomore
        ? _c("div", { staticClass: "nomore py-10" }, [_vm._v("没有更多了")])
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading d-flex justify-center" },
            [_c("loading", { attrs: { size: "20px" } })],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }