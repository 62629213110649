var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "90%", max_height: "90%" } },
    [
      _c(
        "div",
        {
          staticClass:
            "components_pop_up_member_rights_index d-flex flex-column",
        },
        [
          _c("common-title", {
            staticClass: "flex-shrink-0",
            attrs: { title: "会员权益", close: "" },
            on: {
              handleClose: function ($event) {
                return _vm.handleClose()
              },
            },
          }),
          _c(
            "div",
            { staticClass: "main" },
            [_c("vip", { attrs: { expire_group: false } })],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }