/*
 * @Author       : Hugo
 * @Date         : 2022-02-21 11:41:04
 * @LastEditTime : 2022-02-21 12:14:47
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/select_widget_contact.js
 * @Description  : 
 * @^_^          : 
 */
import PluginModule from '@/components/Plugin/Modules/SelectWidgetContace/index.vue';

const PluginConfig = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginModuleConstructor = Vue.extend(PluginModule);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginModuleConstructor();

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$select_widget_contact = (params={}) => {
      // console.log(params)
      const {
        chat_user_list,
        friend_list,
        user_list,
        item,
        cb,
      } = params;

      instance.chat_user_list = chat_user_list;
      instance.friend_list    = friend_list;
      instance.user_list      = user_list;
      instance.item           = item;
      instance.cb             = cb;
      instance.visible        = true;
      // duration = duration || 1500; // 默认1500关闭
      // setTimeout(()=>{
      //   instance.visible = false;
      // },duration)
    }
    
  }
}

export default PluginConfig;