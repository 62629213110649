<!--
 * @Author       : Hugo
 * @Date         : 2022-04-08 16:59:36
 * @LastEditTime: 2022-05-17 11:29:27
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Collect/File/FileUnit.vue
 * @Description  :
 * @^_^          :
-->
<template>
  <task
    class="mb-15"
    :p_task="p_task"
    :menu="!preview && show_menu"
    :current_folder="current_folder"
    :switch_select="switch_select"
    :show_from="false"
    :disable_select="disable_select"
    :can-set-status="canSetStatus"
    @handleClick="handleClick"
    @handleCallTask="handleCallTask"
    @handleShareTask="handleShareTask"
    @handleRemoveCollect="handleRemoveCollect"
    @handleTouchCollect="handleTouchCollect"
    @handleStartSelect="handleStartSelect"
    @handleSure="handleSure"
    @handleRemove="handleRemove"
    :select-list="selectList"
  ></task>
</template>

<script>
import global from '@/api/global.js'
import tools from '@/api/tools.js'
import Task from '@/components/Common/Modules/Thumb/Task.vue'
export default {
  components: {
    Task
  },
  props: {
    p_task: {
      type: Object,
      default: undefined,
    },
    switch_select: {
      type: Boolean,
      default: false,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
    call: { // 是否调用
      type: Boolean,
      default: false,
    },
    show_menu: {
      type: Boolean,
      default: true,
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  computed: {
    canSetStatus() {
      const { p_task } = this
      const { tousers } = p_task
      const userId = tools.getUserId()
      const userArr = String(tousers).split(',')
      const aim = userArr.find((item) => {
        return Number(item) === Number(userId)
      })
      if (aim !== undefined) {
        return true
      }
      return false
    }
  },
  methods: {
    handleClick(){
      const {call} = this
      console.log(call)
      // return
      if(call){
        // this.$api.moduleTask.callTask({taskId:this.p_task.id,
        //   config: {
        //     open_by_call: true,
        //     // view: true, //我要使用
        //   }
        // })
        this.$api.moduleTask.callTask({
          taskId:this.p_task.id,
          config: {
            select_target: false,
            // jump: true,
            call: true,
            use: true,
            open_by_call: true, // 通过调用打开, 如果关闭时modified为false 则返回新建页
            open_with_call_page: false
          }
        })
        return
      }
      const user_id = tools.getUserId()
      const {tousers} = this.p_task
      const config = {edit:true}
      if(`${tousers},`.indexOf(`${user_id},`)===-1){
        config.view = true
      }
      this.p_task.open(config)
    },
    handleTouchCollect(){
      const {p_task} = this
      const {collectionflag} = p_task
      if(collectionflag){
        this.handleRemoveCollect()
        return
      }
      this.handleCollect()
    },
    handleCollect(){
      this.p_task.collect()
    },
    async handleRemoveCollect(){
      this.$emit('handleRemoveCollect', this.p_task)
    },
    handleShareTask(){
      this.p_task.share()
    },
    handleCallTask(){
      // this.$api.task.callTask({taskId: this.p_task.id});
      const { call } = this
      if (call) {
        this.$api.moduleTask.callTask({
          taskId:this.p_task.id,
          config: {
            select_target: true,
            jump: true,
            use: true,
            open_by_call: true, // 通过调用打开, 如果关闭时modified为false 则返回新建页
            open_with_call_page: false
          }
        })
        return
      }

      this.$api.moduleTask.callTask({
        taskId:this.p_task.id,
        config: {
          select_target: true,
          jump: true,
          use: true,
          open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false,
        }
      })
    },
    handleStartSelect(){
      this.$emit('handleStartSelect')
    },
    handleSure(aim){
      console.log({aim})
      this.$openConfirm({
        title:`是否移动到 '${aim.name}' ?`,
        desc:'',
        sure:'确定',
        cancel:'取消',
        onSure:async ()=>{
          let data = {
            ids: this.p_task.id,
            type: 1,
            toid: aim.id
          }
          let res = await global.doPost({
            url: '/projectCustom/moveFolderOrTask',
            data,
            notice: '移动失败',
          })
          if(res.message=='success'){
            this.handleRemove()
            // 重新加载文件和文件夹

          }
        },
        onCancel:()=>{},
      })
    },
     /** 移除当前文件夹 */
    handleRemove(){
      console.log('folderUnit')
      this.$emit('handleRemove', this.p_task)
    },
  }
}
</script>
