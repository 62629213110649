<!--
 * @Author       : Hugo
 * @Date         : 2022-01-07 12:13:00
 * @LastEditTime : 2022-06-08 10:07:32
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/Payment/ScanCode/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<script src="../../../types/modules/POrder/index.js"></script>
<template>
  <div class="components_payment_scan_code_index d-flex flex-column align-center">
    <div class="img_wrapper d-flex align-center justify-center">
      <img :src="`${domain}/code/twoCode?width=150&amp;text=${codeUrl}`" alt="" class="img">
    </div>
    <div class="info_group pt-10 d-flex align-center">
      <div class="icon wechat mr-10"></div>
      <div class="text">扫码支付{{price}}元</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: undefined,
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    onFail: {
      type: Function,
      default: undefined,
    },
    onCancel: {
      type: Function,
      default: undefined,
    },
    onCancelApply: {
      type: Function,
      default: undefined,
    },
    onClose: {
      type: Function,
      default: undefined,
    },
  },
  data(){
    return {
      domain: process.env.VUE_APP_BASEURL,
    }
  },
  computed: {
    codeUrl(){
      const {order} = this;
      if(order === undefined){
        return '';
      }
      return order.codeUrl;
    },
    price(){
      const {order} = this;
      if(order === undefined){
        return '';
      }
      return order.convertToYuan();
    },
   
  },
  mounted(){
    this.init();
  },
  methods: {
    async init(){
      const {
        order,
        onSuccess,
        onFail,
        onCancel,
        onCancelApply,
        onClose,
      } = this;
      order.pollingStatus({
        success_cb: ()=>{
          if(onSuccess){
            onSuccess()
            return
          }
          this.$close_buy_now();
          this.$close_scan_code();
          this.$api.payment.scanCodeStatus({
            status: 'success',
            title: '支付成功',
            jump: true,
            jump_name: '我的订单',
            jump_router_name: 'shopping_mail_order_list',
          })
        },
        fail_cb: ()=>{
          if(onFail){
            onFail()
            return
          }
          this.failedCallFunction({title: '支付失败'})
        },
        cancel_cb: ()=>{
          if(onCancel){
            onCancel()
            return
          }
          this.failedCallFunction({title: '取消订单'})
        },
        cancel_apply_cb: ()=>{
          if(onCancelApply){
            onCancelApply()
            return
          }
          this.failedCallFunction({title: '取消订单申请中'})
        },
        close_cb: ()=>{
          if(onClose){
            onClose()
            return
          }
          this.failedCallFunction({title: '订单关闭'})
        },
      })
    },
    failedCallFunction(params={}){
      this.$close_scan_code();
      const {title} = params;
      this.$api.payment.scanCodeStatus({
        status: 'failed',
        title,
        jump: false,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.components_payment_scan_code_index{
  .img_wrapper{
    @include background(162px, 162px);
    width           : 162px;
    height          : 162px;
    background-image: url(~@/assets/images/common/qrcode_wrapper.png);
    .img{
      display: block;
    }
  }
  .info_group{
    font-size: 14px;
    color    : #333;
    .icon{
      width: 20px;
      height: 20px;
      &.wechat{
        background-image: url(~@/assets/images/common/wechat20.png);
      }
    }
  }
}
</style>