<!--
 * @Author       : Hugo
 * @Date         : 2022-03-14 13:40:43
 * @LastEditTime : 2022-04-06 11:42:38
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/ClassesTask/modules/Main/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_classes_task_modules_main_index bbox d-flex flex-column"
  >
    <task-user
      class="flex-shrink-0 task_user_component px-20 border-bottom"
      :large_task="large_task"
      :data="data"
      :backup="backup"
    ></task-user>
    <task-title
      class="flex-shrink-0 task_title_component px-20 border-bottom"
      :large_task="large_task"
      :data="data"
      :backup="backup"
    ></task-title>
    <task-tag
      class="flex-shrink-0 task_tag_component px-20 border-bottom"
      :large_task="large_task"
      :task="task"
      v-if="0"
    ></task-tag>
    <widget-container
      class="widge_container_component"
      ref="widget_container"
      :large_task="large_task"
      :data="data"
      :backup="backup"
      :list="taskDetails"
    >
      <template v-slot>
        <div
          class="call_task_entrance d-flex flex-column align-center justify-center cp bbox"
        >
          <img src="~@/assets/images/task/call_task_entrance.png" alt="" class="un_sel">
          <div class="text un_sel">调用已有模板 创建全新任务</div>
        </div>
      </template>
    </widget-container>

  </div>
</template>
<script>
import TaskUser from './LineBar/TaskUser.vue';
import TaskTitle from './LineBar/TaskTitle.vue';
import TaskTag from './LineBar/TaskTag.vue';
import WidgetContainer from './WidgetContainer.vue';
export default {
  components: {
    TaskUser,
    TaskTitle,
    TaskTag,
    WidgetContainer,
  },
  props:{
    large_task: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
    large_task: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return{
      switch_call_task: false,
    }
  },
  computed: {
    data(){
      const {large_task} = this
      const {data } = large_task
      return data
    },
    readtaskUsers(){
      const {data} = this
      const {readtaskUsers} = data ?? {}
      return readtaskUsers
    },
    task(){
      const {data} = this
      const {task} = data ?? {}
      return task
    },
    taskDetails(){
      const {data} = this
      const {taskDetails} = data ?? {}
      return taskDetails
    },
    taskUsers(){
      const {data} = this
      const {taskUsers} = data ?? {}
      return taskUsers
    },
  },
  methods: {
  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.c_classes_task_modules_main_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  .px-20{
    padding-left: 20px;
    padding-right: 20px;
  }
  .border-bottom{
    border-bottom: 1px solid #f4f4f4;
  }
  .task_user_component{
    height: 50px;
  }
  .task_title_component{
    height: 70px;
  }
  .task_tag_component{
    height: 50px;
  }
  .widge_container_component{
    flex: 1;
    padding: 20px 20px 30px;
    position: relative;
  }
  .call_task_entrance{
    width: 100%;
    padding: 30px 0;
    border: 1px dashed #C6D0D6;
    border-radius: 4px;
    .text{
      font-size: 22px;
      font-weight: 400;
      color: #88A0B5;
      padding-top: 20px;
    }
  }
}
</style>
