<template>
  <div class="c_teaching_package_select_task_thumbnail_task ml-5 mr-10 mb-10">
    <thumbnail-task
      :p-task="pTask"
      :can-select="true"
      :selected="selected"
      @handleSelected="handleSelected"
    ></thumbnail-task>
  </div>
</template>

<script>
import ThumbnailTask from '@/components/Thumbnail/Task'
export default {
  components: {
    ThumbnailTask
  },
  props: {
    pTask: {
      type: Object,
      default: undefined
    },
    packageSelectTask: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    selected() {
      const { packageSelectTask, pTask } = this
      if (!packageSelectTask || !pTask) {
        return false
      }
      const { selectedList } = packageSelectTask
      if (selectedList.length === 0) {
        return false
      }
      const aim = selectedList.find((unit) => {
        return unit.id === pTask.id
      })
      if (aim) {
        return true
      }
      return false
    }
  },
  methods: {
    handleDelete(params){
      this.$emit('handleDelete', params)
    },
    handleSelected(params) {
      this.$emit('handleSelected', params)
    },
    /**
     * @description: 获取颜色列表
     * @param {*} item
     * @return {*}
     */
    getColorList(item) {
      const { colour } = item
      if (!colour) {
        return ''
      }
      const color_list = colour.split(',')
      return color_list
    }
  },
  mounted () {
    // console.log(this.packageSelectTask, this.pTask)
    // const { packageSelectTask, pTask } = this
    // const { selectedList } = packageSelectTask
    // if (selectedList && selectedList.length) {
    //   const aim = selectedList.find((unit) => {
    //     return unit.id === pTask.id
    //   })
    //   if (aim) {
    //     pTask.cf_selected = true
    //   }
    // }
    // console.log(this.pTask)
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_thumbnail_task{
  border-radius:10px;
  width:160px;
  // overflow: hidden;
  .new_content_group{
    @include bbox;
    width: 160px;
    height: 140px;
    background: rgba(244, 244, 244, 1);
    border: 1px solid#E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    // margin-left: 10px;
    // margin-bottom: 20px;
    position: relative;
    z-index: 2;
    .select_group{
      @include transition;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/task_thumb/unselected.png);
      &.active{
        background-image: url(~@/assets/images/task_thumb/selected.png);
      }
    }
    .delete{
      @include transition;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/delete/delete20.png);
    }
    .color_group{
      @include flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      left: 0px;
      .color{
        flex:1;
        width:5px;
        border: none;
        border-left: 4px solid #FFB038;
        &:first-child{
          border-top-left-radius: 20px;
        }
        &:last-child{
          border-bottom-left-radius: 20px;
        }
        &.placeholder{
          border-left-color: transparent;
        }
      }
    }
    .dot {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      background: rgba(255, 59, 48, 1);
      border-radius: 50%;
    }
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .unit_fun_btn{
      @include background(3px 15px);
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 9;
      background-image: url(~@/assets/images/control/fun_more.png);
      top:10px;
      right:2px;
    }
    .info_group {
      @include flexsb;
      @include bbox;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 96px;
      padding: 19px 16px;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      .info_box{
        @include flexsb;
        @include bbox;
        align-items: flex-start;
        width: 100%;
        flex:1;
        height: 100%;
        padding-right: 13px;
        position: relative;
        .info{
          @include multi_line_intercept(2);
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
          text-align: justify;
          text-justify: inter-ideograph;
          text-align-last: left;
          word-break: break-all;
          .status_tag_group{
            margin-bottom: 2px;
            .tag{
              margin-bottom: 2px;
            }
          }
        }
        .more_btn{
          @include background;
          position: absolute;
          width: 16px;
          height: 16px;
          background: {
            image: url(~@/assets/images/control/fun_more.png);
            size: contain;
          }
          right: -8px;
          top: 0;
          z-index: 3;
        }
      }
      .time_box{
        @include bbox;
        padding-left: 7px;
        width: 100%;
        font-size: 12px;
        color:#666;
        border-left: 4px solid #e5e5e5;
        height: 12px;
        line-height: 12px;
      }

    }
    .template_info_group{
      @include flexsb;
      @include bbox;
      height: 40px;
      padding: 0 16px;
      border-radius: 0 0 10px 10px;
      background-color:#fff;
      &.separate{
        border-top: 2px solid #f8f8f8;
      }
      .avatar_group{
        @include flex;
        .avatar{
          display: block;
          width:26px;
          height: 26px;
          border-radius: 4px;
          overflow: hidden;
        }
        .new_chat_icon{
          margin-left: 8px;
          height: 19px;
          width: 20px;
        }
      }
      .tag_box{
        @include flex;
        justify-content: flex-end;
        .tag{
          font-size: 12px;
          line-height: 19px;
          padding: 0 7px;
          margin-left:4px;
          border-radius: 2px;
          &.privacy{
            color: #8C73F1;
            background-color: rgba(83, 47, 230, 0.08);
          }
          &.teach{
            color: #FF7200;
            background-color: rgba(255, 114, 0, 0.08);
          }
          &.draft{
            color: #5EC799;
            background-color: rgba(31, 175, 112, 0.08);
          }
          &.reminder{
            width: 20px;
            height: 20px;
            padding: 0;
            background-image: url(~@/assets/images/task/task_thumbnail_reminder.png);
          }
        }
      }
      .check_status{
        font-size:14px;
        color:#05AD00;
      }
      .teaching_group{
        flex: 1;
        .putaway{
          width: 54px;
          height: 22px;
          display: block;
        }
        .audit{
          font-size: 14px;
        }
      }
    }

    &.loadmore{
      @include flex;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      border:none;
    }
  }
  .from_group{
    @include flex;
    @include bbox;
    padding-left: 6px;
    width: 100%;
    font-size: 12px;
    padding-top: 10px;
    margin-bottom: 10px;
    .tag{
      flex-shrink: 0;
    }
    .from{
      @include single_line_intercept;
      @include bbox;
      padding-left: 5px;
      width: 100%;
      flex:1;
      color:#1B6FC2;
    }
  }
  .content_group{
    @include bbox;
    padding: 19px 16px;
    background-color: #fff;
    height: 105px;
    position: relative;
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .content{
      @include multi_line_intercept(4);
      font-size:12px;

      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:18px;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      word-break: break-all;
    }
  }
  .info_group{
    @include flexsb;
    @include bbox;
    padding: 0 16px;
    width:100%;
    height: 35px;
    .time{
      font-size:12px;

      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .tag{
      width: 14px;
      height: 18px;
      background: {
        image: url(~@/assets/images/fun_template/tag.png);
      }
    }
    .check_status{
      font-size:14px;
      color:#05AD00;
    }
  }
}
</style>