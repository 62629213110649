<!--
 * @Author       : Hugo
 * @Date         : 2021-04-26 10:34:11
 * @LastEditTime : 2022-04-12 15:26:25
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Prompt.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <transition name="fade">
    <div class="plugin_prompt" v-if="visible" @click="doClose"
      :style="{'z-index': zindex}"
    >
      <div class="confirm_group" @click.stop="stopPropagation();">
        <div class="close cp" @click.stop="doClose"></div>
        <div class="title">{{title}}</div>
        <div class="desc" v-show="desc && 0">{{desc}}</div>
        <div class="main px-20">
          <input type="text" class="input"
            :placeholder="placeholder"
            v-model="input"
            ref="input"
            @input="changeInput()"
            @keydown.enter="doSure();"
          >
          <div class="fun_group">
            <div class="btn sure btn_dark" @click.stop="doSure">{{sure}}</div>
            <div class="btn cancel btn_light" @click.stop="doCancel">{{cancel}}</div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      title: 'Title',
      desc: 'A description',
      placeholder: 'placeholder',
      sure: '确定',
      cancel: '取消',
      onSure: null,
      onCancel: null,
      input: '',
      hint: '未输入内容',
      zindex: 2000,
      loading: false,
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.$nextTick(function(){
          this.$refs.input.focus();
        })
        this.zindex = this.$utils.getZIndex();
        // console.log(this.zindex);
      }
    },
  },
  methods:{
    changeInput(){
      
      const {input} = this;
      this.$utils.prompt.changeInput(input);
    },
    doClear(){
      this.input = '';
      this.$utils.prompt.doClear();
    },
    async doSure(){
      if(this.input == ''){
        this.$notice({desc: this.hint});
        return;
      }
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure();
        this.loading = false
      }
      this.visible = false;
      this.doClear();
    },
    async doCancel(){
      if(this.onCancel){
        await this.onCancel();
      }else{
        await this.doClose();
      }
      this.visible = false;
      this.doClear();
    },
    doClose(){
      this.visible = false;
      return;
    },
    stopPropagation(){
      return false;
    }
  }
}
</script>
<style lang="scss" scoped>
.plugin_prompt{
  @include flex;
  justify-content : center;
  position        : fixed;
  top             : 0 ;
  left            : 0;
  width           : 100%;
  height          : 100%;
  background-color: rgba(0,0,0,0.3);
  z-index         : 999999999;
  .confirm_group{
    padding         : 0px 0px 30px;
    background-color: #fff;
    box-shadow      : 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
    border-radius   : 4px;
    position        : relative;
    .close{
      @include background(12px, 12px);
      position        : absolute;
      top             : 14px;
      right           : 10px;
      height          : 20px;
      width           : 20px;
      background-image: url(~@/assets/images/common/close.png);

    }
    .title{
      font-size  : 16px;
      font-weight: bold;
      color      : #333333;
      text-align : center;
      // padding-bottom: 28px;
      line-height     : 46px;
      margin-bottom   : 20px;
      background-color: #f8f8f8;
      border-radius   : 4px 4px 0 0;
    }
    .desc{
      // @include text_justify;
      text-align    : center;
      max-width     : 350px;
      font-size     : 16px;
      color         : #333333;
      line-height   : 24px;
      padding-bottom: 30px;
      // text-align-last: left;
      font-weight: bold;
    }
    .input{
      @include bbox;
      @include placeholder(#999);
      display      : block;
      max-width    : 350px;
      width        : 100%;
      line-height  : 34px;
      outline      : none;
      background   : #FBFBFB;
      border       : 1px solid #F0F0F0;
      border-radius: 4px;
      font-size    : 14px;
      color        : #333333;
      padding      : 0 20px;
      margin-bottom: 20px;
    }
    .fun_group{
      @include flexsb;
      @include bbox;
      .btn{
        min-width    : 160px;
        line-height  : 40px;
        border-radius: 4px;
        text-align   : center;
        font-size    : 14px;
        cursor       : pointer;
        &.sure{
          margin-right: 10px;
        }
        &.cancel{
          margin-left: 10px;
        }
      }
    }
  }
}
</style>

