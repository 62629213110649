/*
 * @Author      : Hugo
 * @Date        : 2022-04-20 14: 38: 30
 * @LastEditTime : 2022-04-20 15:47:46
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/types/modules/PEnquire/index.js
 * @Description : 
 * @^_^         : 
 */
class PEnquire{
  content     = undefined  //: "content"
  createTime  = undefined  //: 1650439380000
  enquireType = undefined  //: 2
  id          = undefined  //: 14
  identities  = undefined  //: 3
  name        = undefined  //: "test"
  paramId     = undefined  //: null
  phone       = undefined  //: "17711111111"
  school      = undefined  //: "school"
  updateTime  = undefined  //: 1650439380000
  userId      = undefined  //: 4360

  constructor(params={}){
    const {
      content,
      identities,
      name,
      phone,
      school,
      createTime,
      enquireType,
      id,
      paramId,
      updateTime,
      userId,
    } = params || {}

    this.content     = content
    this.createTime  = createTime
    this.enquireType = enquireType
    this.id          = id
    this.identities  = identities
    this.name        = name
    this.paramId     = paramId
    this.phone       = phone
    this.school      = school
    this.updateTime  = updateTime
    this.userId      = userId
  }
}

export default PEnquire