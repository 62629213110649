var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "weaken_content_page d-flex justify-center align-center" },
    [
      _vm.type === "work"
        ? _c("work-weaken", { attrs: { msg: _vm.msg } })
        : _c("common-weaken", { attrs: { msg: _vm.msg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }