/*
 * @Author       : Hugo
 * @Date         : 2022-01-06 15:14:45
 * @LastEditTime : 2022-01-07 14:44:18
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/buy_now.js
 * @Description  : 
 * @^_^          : 
 */
import BuyNowComponent from '@/components/Plugin/Modules/BuyNow/index.vue';

const BuyNow = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const BuyNowConstructor = Vue.extend(BuyNowComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new BuyNowConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$buy_now = (params={}) => {
      const {title, task, vip} = params;
      instance.title   = title;
      instance.task   = task;
      instance.visible = true;
      // duration = duration || 1500; // 默认1500关闭
      // setTimeout(()=>{
      //   instance.visible = false;
      // },duration)
    }
    Vue.prototype.$close_buy_now = () => {
      instance.visible = false;
    }
  }
}

export default BuyNow