<template>
  <div
    class="components_popup_record_of_communication_picture_index_page bbox"
  >
    <template
      v-for="(item, index) in picture_grouping"
    >
      <div
        :key="index"
      >
        <div class="type_title mb-15">{{item.type === 'week' ? '本周' : `${item.year}年${item.month}月`}}</div>
        <div class="picture_group d-flex align-center flex-wrap mb-10">
          <template
            v-for='(child, child_index) in item.list'
          >
            <unit
              class="mb-5 mr-5 flex-shrink-0"
              :key="child_index"
              :item="child"
            ></unit>
          </template>
        </div>
      </div>
    </template>
    <!-- <template
      v-for="(item, index) in 20"
    >
      <unit
        class="mb-20"
        :key="index"
      ></unit>
    </template>
     -->
    <div class="nomore py-10"
      v-if="nomore"
    >没有更多了</div>
    <div class="loading d-flex justify-center"
      v-if="loading"
    >
      <loading
        size="20px"
      ></loading>
    </div>

  </div>
</template>

<script>

/**
 * 
接收数据


  {
    diff_type : 'week',
    diff_title: '本周',
    diff_data : [

    ]

  }
]
 * 
 */
import { PFile } from '@/types';
import Loading from '@/components/basic/loadingIcon.vue'
import Unit from './Picture.vue';
export default {
  components: {
    Unit,
    Loading,
  },
  data(){
    return {
      // 模拟数据
      simulate_data: [
        { "date": "Sat Nov 14 2020 05:33:50 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Wed Dec 16 2020 03:55:47 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Sun Jan 10 2021 17:32:41 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Sep 23 2021 22:10:03 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Feb 04 2021 12:13:16 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Wed May 26 2021 10:56:53 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Tue Jul 06 2021 00:34:24 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Tue Aug 24 2021 02:50:32 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Fri Sep 24 2021 02:45:50 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Wed Jan 20 2021 13:27:21 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Sat May 22 2021 12:03:03 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Sun Mar 28 2021 11:56:38 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Sun Apr 11 2021 05:47:32 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Sat Sep 18 2021 07:10:11 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Jun 10 2021 21:27:24 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Mon May 24 2021 07:23:06 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Mar 04 2021 14:52:58 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Wed Dec 16 2020 15:38:17 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Jul 22 2021 15:56:01 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" },
        { "date": "Thu Oct 21 2021 03:54:27 GMT+0800 (China Standard Time)", "image": "http://lorempixel.com/640/480" }
      ],
      picture_grouping: [],
      list: [],
      loading: false,
      nomore: false,
    }
  },
  props:{
    chat_id: {
      type: Number,
      default: null,
    },
    chat_type: {
      type: Number,
      default: null,
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  mounted(){
    // const {simulate_data: res} = this;
    // res.forEach(item=>{
    //   // console.log(this.$moment(item.date).format('YYYY-MM-DD'));
    // })
    this.init();
  },
  methods: {
    groupPictures(params){
      const {createTime:date} = params;
      const now = this.$moment(Date.now());
      const aim = this.$moment(date);
      const weekdiff = now.diff(aim, 'weeks');
      if(weekdiff == 0 ){
        this.generateGroupData({
          type: 'week',
          data: params,
        })
      }
      if(weekdiff > 1){
        this.generateGroupData({
          type: 'month',
          year: this.$moment(date).format('YYYY'),
          month: this.$moment(date).format('MM'), 
          data: params,
        })
      }

    },
    generateGroupData(params){
      const {picture_grouping} = this;
      const {type, year, month, data} = params;
      // // console.log({type, year, month})
      // 本周数据
      if(type === 'week'){
        const aim = picture_grouping.find(item=>{
          return item.type === 'week';
        })
        if(aim === undefined){
          picture_grouping.unshift({
            type,
            list: [data],
          })
        }else{
          this.$set(aim, 'list', [...new Set([
            ...aim.list, 
            ...[data]
          ])] );
        }
      }
      // 月份数据
      if(type === 'month'){
        // 查找是否有当前年月
        const aim = picture_grouping.find(item=> {
          return item.year === year && item.month === month;
        })
        // 有 直接添加
        if(aim !== undefined){
          this.$set(aim, 'list', [...new Set([...aim.list, ...[data]])] );
        }
        // 没有
        if(aim === undefined){
          // 查询是否包含相同年
          const has_same_year = picture_grouping.find( unit=> {
            return unit.year === year;
          })
          let index = -1;
          // 有相同年 => 插入到比自己小的月份之前
          if(has_same_year){
            index = picture_grouping.findIndex( item => {
              return item.type === 'month' && item.year === year && Number(item.month) < Number(month);
            })
          }
          // 没有相同年 => 插入到比自己小的年份之前
          if(!has_same_year){
            index = picture_grouping.findIndex( item => {
              return item.type === 'month' && Number(item.year) < Number(year);
            })
          }

          // 有比当前年月大的年月数据
          if(index !== -1){
            // 增加
            picture_grouping.splice(index, 0, {
              type: 'month',
              year,
              month,
              list: [data]
            })
          }else{
            // 没有比当前年月大的数据
            // 是否有本周数据
            // const has_week = picture_grouping.find(item=>{
            //   return item.type === 'week';
            // })
            // if(has_week){
            //   // 加到第二个
            //   picture_grouping.splice(1, 0, {
            //     type: 'month',
            //     year,
            //     month,
            //     list: [data]
            //   })
            // }else{
              // 没有 加到最前面
              picture_grouping.push( {
                type: 'month',
                year,
                month,
                list: [data]
              })
          //   }
          }
        }

      }
    },
    async init(){
      await this.getList();
      // const {simulate_data: res} = this;
      // // console.log(res);
      // res.forEach(item=>{
      //   this.groupPictures(item);
      // })
      // // console.log(this.picture_grouping);
    },
    async getList(){
      this.loading = true;
      const {chat_id, chat_type, keyword, list} = this;
      if(isNaN(chat_id)){
        return;
      }
      if(isNaN(chat_type)){
        return;
      }
      if(chat_type == -1){
        return;
      }
      const res = await this.$global.doPost({
        url: '/projectTaskLogSearch/searchTaskLogPic',
        data: {
          chatType: chat_type,
          chatId: chat_id,
          start: list.length,
        }
      });
      this.loading = false;
      const {contents, message} = res;
      if(message === 'success'){
        const {fileList} = contents;
        if(fileList.length != 50){
          this.nomore = true;
        }
        if(fileList){
          this.$set(this, 'list', [...new Set([...list, ...fileList])]);
          if(fileList.length){
            fileList.forEach(unit=>{
              this.groupPictures( new PFile(unit));
            })
          }
          return fileList;
        }

      }
      return false;
    },
    async handleLoadMore(){
      const {nomore, loading} = this;
      // console.log({nomore, loading})
      if(nomore || loading){
        return;
      }
      // console.log('getList')
      await this.getList();
    },

  }
}
</script>

<style lang="scss" scoped>
.components_popup_record_of_communication_picture_index_page{
  width : 100%;
  height: 100%;
  .type_title{
    font-size: 12px;
    color: #333;

  }
  .loading{
    height: 40px;
  }
  .nomore{
    font-size: 14px;
    text-align: center;
    color: #999;
  }
}
</style>