var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_seletct_route bbox d-flex flex-column" },
    [
      _c(
        "overlay-scrollbars",
        { ref: "overlay", staticClass: "overlay bbox" },
        [
          _c(
            "div",
            { staticClass: "main_group d-flex align-center flex-shrink-0" },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c("list-component", {
                    key: index,
                    ref: `list${index}`,
                    refInFor: true,
                    staticClass: "list_group flex-shrink-0",
                    attrs: { list: item },
                    on: { handleNext: _vm.handleNext },
                  }),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "operation_group d-flex justify-space-between flex-shrink-0 bbox align-center",
        },
        [
          _c("div", { staticClass: "create_group" }, [
            _c(
              "div",
              {
                staticClass: "create un_sel d-flex align-center cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchCreate()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img mr-8",
                  attrs: {
                    src: require("@/assets/images/custom/create.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [_vm._v("创建分组")]),
              ]
            ),
          ]),
          _c("div", { staticClass: "btn_group d-flex" }, [
            _c(
              "div",
              {
                staticClass: "button btn_light",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleClose()
                  },
                },
              },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              {
                staticClass: "button btn_dark",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doSure()
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }