/*
 * @Author       : Hugo
 * @Date         : 2021-12-31 11:25:22
 * @LastEditTime : 2021-12-31 11:47:27
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/consulting_form.js
 * @Description  : 咨询表单
 * @^_^          : 
 */


import ConsultingFormComponent from '@/components/Plugin/Modules/ConsultingForm/index.vue';

const ConsultingForm = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ConsultingFormConstructor = Vue.extend(ConsultingFormComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ConsultingFormConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$consulting_form = (params) => {
      // // console.log(obj)
      const {
        title,
        user,
        loginUserCode
      } = params;
      instance.title         = title;
      instance.user          = user;
      instance.loginUserCode = loginUserCode;
      instance.visible       = true;
    }
    // Vue.prototype.$close_become_member = () => {
    //   instance.visible = false;
    // }
  }
}

export default ConsultingForm