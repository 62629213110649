/*
 * @Author       : Hugo
 * @Date         : 2022-02-22 09:55:07
 * @LastEditTime : 2022-02-22 11:00:51
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/add_to_custom_list.js
 * @Description  : 
 * @^_^          : [插件模板]
 */
import PluginComponent from '@/components/Plugin/Modules/AddToCustomList/index.vue';

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor();
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$add_to_custom_list = (params={}) => {
      // // console.log(obj)
      const {title} = params;
      instance.title   = title ?? '添加至清单';
      instance.visible = true;
    }
    Vue.prototype.$close_add_to_custom_list = () => {
      instance.visible = false;
    }
  }
}

export default PluginMember;