/*
 * @Author       : Hugo
 * @Date         : 2020-09-10 14:44:58
 * @LastEditors  : Hugo
 * @LastEditTime : 2022-04-22 10:43:42
 * @Description  : 
 * @FilePath     : /miaohang/src/utils/confirm.js
 */
import ConfirmComponent from '@/components/Plugin/Confirm.vue'

const Confirm = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ConfirmConstructor = Vue.extend(ConfirmComponent);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ConfirmConstructor();
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    // 在Vue的原型链上注册方法，控制组件
    /**
     * 打开确认框
     * @param {Object} obj
     * @param {String} obj.title - 标题
     * @param {String} obj.desc - 描述
     * @param {String} obj.sure - 确认文本
     * @param {String} obj.cancel - 取消文本
     * @param {Function} obj.onSure - 确认函数
     * @param {Function} obj.onCancel - 取消函数
     * @param {String} obj.sureType - 确认类型 default btn_dark
     * @param {String} obj.cancelType - 取消类型 default btn_light
     */
    Vue.prototype.$openConfirm = (obj) => {
      if (obj) {
        let { title, desc, sure, cancel, onSure, onCancel, sureType, cancelable, cancelType } = obj
        instance.title = title
        instance.desc = desc
        instance.sure = sure || '确定'
        instance.cancel = cancel || '取消'
        instance.onSure = onSure
        instance.onCancel = onCancel
        instance.sureType = sureType || ''
        instance.cancelType = cancelType || ''
        if (cancelable != null && cancelable != undefined) {
          instance.cancelable = obj?.cancelable
        }
      } else {
        instance.title = '提示'
        instance.desc = '温馨提示'
        instance.sure = '确定'
        instance.cancel = '取消'
      }
      
      instance.visible = true
    }
    Vue.prototype.$closeConfirm = () => {
      instance.visible = false
    }
  }
}

export default Confirm