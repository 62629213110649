import { PMessageBox, PTaskLog } from '@/types'
import router from '@/router'
import global from '@/api/global'
import apiChat from '@/api/chat'
import store from '@/store'
import utilUsers from '@/api/utils/users'
import tools from '@/api/tools'
import moduleTask from '@/api/modules/task'
import vue from '@/main'
import pushTask from './pushTask'

class Chat {
  receive = (params) => {
    if (!params) {
      return
    }
    const { ext } = params ?? {}
    if (!ext) {
      return
    }
    if (!ext.pTaskLog) {
      return
    }
    // console.log(ext.pTaskLog,(JSON.parse(ext.pTaskLog)))
    const pTaskLog = new PTaskLog(JSON.parse(ext.pTaskLog))
    this.pushChatAtMessageList({ ext, pTaskLog })
    // 更新消息盒子
    this.pushMessageBoxList({ ext, pTaskLog })
    this.updateMyTaskListOfChat({ ext, pTaskLog })
  }

  pushChatAtMessageList = (params = {}) => {
    const {
      ext,
      pTaskLog
    } = params ?? {}
    const { currentRoute } = router
    const { name:currentRouteName } = currentRoute
    if (currentRouteName.indexOf('fun_task_dialog') === -1) {
      return
    }
    const { chatId, chatType } = ext
    const isSingle = currentRouteName === 'fun_task_dialog_single' && Number(chatType) === 0
    const isGroup = currentRouteName === 'fun_task_dialog_group' && Number(chatType) === 1
    const { id } = currentRoute.params
    const currentId = global.decodeBase64(id)
    const isCurrentChat = currentId && (Number(currentId) === Number(chatId))
    const isCurrent = (isSingle || isGroup) && isCurrentChat
    // 当前聊天页面
    if (isCurrent) {
      const isBottom = store.state.dialogData.isBottom
      // apiChat.pushChatAtMessageList(pTaskLog)
      const { message_list } = store.state
      message_list.push(pTaskLog)
      if (isBottom) {
        vue.$nextTick(function () {
          vue.$api.messageList.scrollToLatest()
        })
      } else {
        store.state.dialogData.unreadNumber += 1
      }
    }
  }

  pushMessageBoxList = async (params = {}) => {
    // console.log(params)
    const { ext, pTaskLog } = params ?? {}
    const { currentRoute } = router
    const { name:currentRouteName } = currentRoute
    if (!ext) { return }
    if (!pTaskLog) { return }
    const { chatId, chatType } = ext
    const {
      // tousers,
      linkusers, // @的人
      chatName,
      lastSendTime,
      messageContent,
      updateUserId,
      updateusername,
      updateuserthumbnail,
      photo,
      userName
    } = ext
    let message_box_list = store.state.message_box_list
    if (!message_box_list || !message_box_list.length) {
      const res = await global.doPost({
        url: '/projectTask/messageBoxList',
        openNotice: false
      })
      if (res.message === 'success') {
        if(res.contents && res.contents.messageBox){
          message_box_list = res.contents.messageBox || []
        }else{
          message_box_list = []
        }
      }else{
        message_box_list = []
      }
    }
    // console.log({ message_box_list })
    const index = message_box_list.findIndex((item) => {
      return (Number(item.chatId) === Number(chatId)) && (Number(item.chatType) === Number(chatType))
    })
    const loginUserId = tools.getUserId()
    // 有人@我
    const isLinkMe = Boolean(linkusers && `,${linkusers},`.indexOf(`,${loginUserId},`) !== -1)
    // console.log({ isLinkMe })
    if (index !== -1) {
      const { chatId, type:chatType } = pTaskLog
      const isSingle = currentRouteName === 'fun_task_dialog_single' && Number(chatType) === 0
      const isGroup = currentRouteName === 'fun_task_dialog_group' && Number(chatType) === 1
      const { id } = currentRoute.params
      const currentId = global.decodeBase64(id)
      const isCurrentChat = currentId && (Number(currentId) === Number(chatId))
      const isCurrent = (isSingle || isGroup) && isCurrentChat
      if (!isCurrent) {
        message_box_list[index].unreadMessageNum = message_box_list[index].unreadMessageNum ? message_box_list[index].unreadMessageNum + 1 : 1
        message_box_list[index].islinkme = isLinkMe
      }
      message_box_list[index].name = chatName ?? message_box_list[index].name
      message_box_list[index].lastSendTime = Number(lastSendTime)
      message_box_list[index].messageContent = messageContent
      message_box_list[index].updateusername = utilUsers.getPriorityName({
        userId: updateUserId,
        name: updateusername
      })
      message_box_list[index].photo = Number(chatType) === 1 ? photo : updateuserthumbnail
      const currentChatType = tools.getChatType()
      const currentChatId = global.decodeBase64(id)
      if ((Number(currentChatType) === Number(chatType)) && currentChatId && (Number(currentChatId) === Number(chatId)) ) {
        message_box_list[index].unreadMessageNum = message_box_list[index].unreadMessageNum ? message_box_list[index].unreadMessageNum + 1 : 1
      }
      // 移到顶部
      const pMessageBox = message_box_list[index]
      message_box_list.splice(index, 1)
      // 置顶消息处理
      if (pMessageBox.istop) {
        message_box_list.unshift(pMessageBox)
      } else {
        // 非置顶消息
        const notTopIndex = message_box_list.findIndex((item) => {
          return !item.istop
        })
        if(notTopIndex !== -1){
          message_box_list.splice(
            notTopIndex,
            0,
            pMessageBox
          )
        }else{
          message_box_list.unshift(pMessageBox)
        }
      }
    } else {
      const pMessageBox = new PMessageBox({
        chatId,
        chatType,
        createTime: Number(lastSendTime),
        messageContent,
        photo: Number(chatType) === 1 ? photo : updateuserthumbnail,
        unreadMessageNum: 1,
        updateusername: updateusername || userName,
        name: chatName,
        islinkme: isLinkMe
      })
      // 非置顶消息
      let notTopIndex = message_box_list.findIndex(item => {
        return !item.istop;
      })
      if(notTopIndex !== -1){
        message_box_list.splice(
          notTopIndex,
          0,
          pMessageBox
        )
      }else{
        message_box_list.unshift(pMessageBox)
      }
    }
    store.commit(
      'set_message_box_list',
      message_box_list
    )
  }

  updateMyTaskListOfChat = (params={}) => {
    const { ext, pTaskLog } = params ?? {}
    const { chatId, chatType } = ext
    const { taskId } = pTaskLog
    const currentChatType = tools.getChatType()
    const currentChatId = moduleTask.getRouteParamsId()
    const {
      my_task_list,
      switch_task_box,
      task_pannel,
      task_config
    } = store.state
    const { task_id } = task_config
    const isOpenChat = Boolean(((task_id) && taskId == task_id  )&&(chatId == currentChatId && currentChatId != undefined) && (chatType == currentChatType && currentChatType != undefined) && switch_task_box)
    if (!isOpenChat) {
      if (my_task_list && my_task_list.length) {
        const aim = my_task_list.find((item) => {
          return item.id == taskId
        })
        if (aim) {
          if (aim.taskchatnum) {
            aim.taskchatnum += 1
          } else {
            aim.taskchatnum = 1
          }
        }
      }
    } else {
      // 如果不在聊天界面 添加未读红点
      if(task_pannel != 'chat'){
        store.commit('set_task_unreader_chat', true)
      }
      this.pushChatList({ pTaskLog })
    }
  }

  pushChatList= (params = {}) => {
    const { pTaskLog } = params ?? {}
    const { task_config } = store.state
    const { taskId } = pTaskLog
    const { chat_message_id } = task_config
    // console.log('任务聊天 [追加聊天内容]');
    // const { details, taskId } = ext || {};
    // const parse_details = JSON.parse(details);
    if(chat_message_id){
      vue.$store.commit('set_task_config', Object.assign(
        {},
        task_config,
        {chat_message_id: null},
      ))
      apiChat.getTaskChatList({taskId})
    }else{
      let { task_chat_message_list } = store.state
      if (!task_chat_message_list || task_chat_message_list.length === 0) {
        task_chat_message_list = [pTaskLog]
      }else{
        task_chat_message_list.push(pTaskLog)
      }
      setTimeout(()=>{
        store.commit('set_task_chat_trigger_scroll_to_bottom', true);
      },200)
    }
  }

  /**
   * 更新工作组名称
   * @param params
   */
  updateGroupChatName = (params) => {
    if (!params) {
      return
    }
    const { ext } = params ?? {}

    if (!ext) {
      return
    }
    if (!ext.pTaskLog) {
      return
    }
    // console.log(ext.pTaskLog,(JSON.parse(ext.pTaskLog)))
    const pTaskLog = new PTaskLog(JSON.parse(ext.pTaskLog))
    const {
      chatId,
      chatName,
      // lastSendTime
    } = ext
    pushTask.pushMessageBoxList({ ext, pTaskLog })
    const { dialog_data, chat_detail, lastSendTime } = store.state
    if (dialog_data && Number(dialog_data.chatId) === Number(chatId) && router.currentRoute.name === 'fun_task_dialog_group') {
      const newDialogData = {
        chatId,
        chatName,
        createTime: Number(lastSendTime),
        createUserId: dialog_data.createUserId,
        jurisdiction: dialog_data.jurisdiction,
        photo: dialog_data.photo,
        users: dialog_data.users // 用户数量
      }
      // console.log(newDialogData)
      // 更新名称
      chat_detail.chat.chatName = chatName
      // 此处为修改名称位置的数据
      store.commit('set_dialog_data', newDialogData)
    }
  }

  /**
   * 更新侧边栏数量
   * @param params
   * @param params.ext ext数据
   */
  updateSidebarNumber = (params) => {
    const { ext } = params
    const { chatType, chatId } = ext
    const routeId = moduleTask.getRouteParamsId()
    const routeType = tools.getChatType();
    const { chat_detail } = store.state
    if (Number(chatType) === Number(routeType) && Number(chatId) == Number(routeId)) {
      if (chat_detail.chat && chat_detail.chat.sidebarflag) {
        chat_detail.chat.sidebarflag += 1
      } else {
        chat_detail.chat.sidebarflag = 1
      }
      // vue.$store.commit('set_chat_detail', chat_detail);
    }
  }
}

const chat = new Chat()

export default chat
