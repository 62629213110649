<template>
  <div class="v_ap_gift_receiving_card_plugins_receiving_main bbox"
    :class="{mobile}"
  >
    <div class="input_group bbox d-flex align-center justify-space-between">
      <input type="text" class="input bbox"
        placeholder="请输入手机号码"
        v-model="userinfo.phone"
        maxlength="11"
      >
      <img src="~@/assets/images/personal_center/gift_card/receiving/clear.png" alt="" class="clear cp"
        v-show="String(userinfo.phone).trim()!== ''"
        @click.stop="userinfo.phone=''"
      >
    </div>
    <div class="input_group bbox d-flex align-center justify-space-between">
      <input type="text" class="input bbox"
        placeholder="请输入短信验证码"
        v-model="userinfo.phonecode"
        maxlength="6"
      >
      <div class="get_verification_code cp"
        :class="{active: userinfo.countdown === 0}"
        @click.stop="getVerify()"
      >{{verify_text}}</div>
    </div>
    <div class="input_group bbox d-flex align-center justify-space-between"
      v-if="userinfo.newuser"
    >
      <input
        :type="password_type"
        class="input bbox"
        v-model="userinfo.userPassword"
        placeholder="请输入密码"
        maxlength=""
      >
      <div class="see cp"
        :class="{hide: show_password===false}"
        @click.stop="show_password=!show_password"
      ></div>
    </div>
    <div class="input_group bbox d-flex align-center justify-space-between"
      v-if="userinfo.newuser"
    >
      <input type="text" class="input bbox"
        placeholder="请输入妙行昵称"
        v-model="userinfo.userName"
      >
      <img src="~@/assets/images/personal_center/gift_card/receiving/clear.png" alt="" class="clear cp"
        v-show="userinfo.userName.trim() !== ''"
        @click.stop="userinfo.userName = ''"
      >
    </div>
    <div class="read_group d-flex aling-center flex-wrap mt-20"
      v-if="userinfo.newuser"
    >
      <div class="select cp"
        :class="{active: userinfo.selecte_aggrement}"
        @click.stop="userinfo.selecte_aggrement=!userinfo.selecte_aggrement"
      ></div>
      <div class="text">已阅读并同意</div>
      <div class="check"
        @click.stop="checkService()"
      >《服务协议》</div>
      <div class="check"
        @click.stop="checkPrivacy()"
      >《隐私政策》</div>
    </div>
    <div class="receive btn_dark mt-20 cp"
      @click.stop="doReceive()"
    >{{userinfo.newuser?'注册':'领取'}}</div>

  </div>
</template>

<script>
import global from '@/api/global'
import regexp from '@/utils/regexp'
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    userinfo: {
      type: Object,
      default: undefined,
    },
    pcarduser: {
      type: Object,
      default: undefined,
    },
    page: {
      type: Object,
      default: undefined,
    },
    routeGetTime: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      show_password: false,
      loading_verify: false,
      loading_receive: false,
    }
  },
  computed:{
    password_type(){
      const {show_password} = this
      if(show_password){
        return 'text'
      }
      return 'password'
    },
    verify_text(){
      const {userinfo} = this
      if(!userinfo){
        return ''
      }
      const {countdown} = userinfo
      if(countdown === 0){
        return '获取验证码'
      }
      return `已发送(${countdown}s)`
    }
  },
  watch: {
    //只能输入数字
    'userinfo.phone': function(val){
      if(val){
        val = String(val)
        this.userinfo.phone = Number(val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.'))
      }
    },
  },
  methods:{
    checkPrivacy(){
      this.$openAggrementPrivacy()
    },
    checkService(){
      this.$openAggrementService()
    },
    async getVerify(){
      if(this.loading_verify){
        return
      }
      const {userinfo, mobile} = this
      const {phone,countdown} = userinfo
      if(countdown !== 0){
        return
      }
      if(!regexp.phone.test(phone)){
        this.$notice({desc: '请输入正确的手机号！', mobile, hide_icon: true})
        return
      }
      this.loading_verify = true
      const res = await global.doPost({
        url: '/project/sendAuthcode',
        data: {
          loginName: phone,
          type: 4,
          flag: 1,
        }
      })
      userinfo.countdown = 60
      this.loading_verify = false
      if(res.message === 'success'){
        const {contents} = res
        const {registerflag} = contents
        if(Number(registerflag) === 0){
          userinfo.newuser = true
        }else{
          userinfo.newuser = false
        }
        userinfo.timer = setInterval(()=>{
          userinfo.countdown -= 1
          if(userinfo.countdown === 0){
            clearInterval(userinfo.timer)
          }
        },1000)
      }else{
        userinfo.countdown = 0
        this.$notice({
          desc: res.describe,
        })
      }
    },
    async doReceive(){
      if(this.loading_receive){
        return
      }
      const { userinfo,pcarduser, mobile, routeGetTime } = this
      const {orcode, card} = pcarduser
      const {usetype} = card
      const {userName, userPassword, phone, phonecode, newuser, selecte_aggrement} = userinfo

      if(!regexp.phone.test(phone)){
        this.$notice({desc: '请输入正确的手机号！', mobile, hide_icon: true})
        return
      }
      if(phonecode.trim() === ''){
        this.$notice({desc: '请输入验证码！', mobile, hide_icon: true})
        return
      }
      if(usetype === 2 && !newuser){
        this.$notice({desc: '仅新用户可以领取！', mobile, hide_icon: true})
        return
      }
      if(newuser){
        if(userPassword.trim() === ''){
          this.$notice({desc: '请输入密码！', mobile, hide_icon: true})
          return
        }
        if(userPassword.trim().length < 6){
          this.$notice({desc: '密码长度不能小于6位', mobile, hide_icon: true})
          return
        }
        if(userName.trim() === ''){
          this.$notice({desc: '请输入昵称！', mobile, hide_icon: true})
          return
        }
        if(!selecte_aggrement){
          this.$notice({desc: '请先阅读并同意服务协议！', mobile, hide_icon: true})
          return
        }
      }
      const data = {
        phone,
        phonecode,
        orcode,
        getTime: routeGetTime
      }
      if(newuser){
        data.userPassword = userPassword
        data.userName = userName
      }


      this.loading_receive = true
      const res = await global.doPost({
        url: '/card/useCard',
        data,
        openNotice: false,
      })
      this.loading_receive = false
      if(res.message === 'success'){
        const {contents} = res
        const {PCardUser} = contents
        this.pcarduser.vipEndDate = PCardUser.vipEndDate
        this.$emit('handleClose')
        this.page.type = 2
      }else{
        this.$notice({desc: res.describe, mobile, hide_icon: true})
        return
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v_ap_gift_receiving_card_plugins_receiving_main{
  width: 430px;
  padding: 0 50px 50px;
  .input_group{
    width: 100%;
    margin-top: 20px;
    // height: 50px;
    border-bottom: 1px solid #D5D9DB;
  }
  .input{
    @include placeholder(#82929C);
    font-size: 16px;
    line-height: 50px;
    padding: 0 10px;
    flex: 1;
    border: none;
    outline: none;
  }
  .receive{
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
  }
  .check{
    color: $main_blue;
    cursor: pointer;
  }
  .see{
    @include background(18px, 14px);
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/images/personal_center/gift_card/receiving/see.png');
    &.hide{
      background-image: url('~@/assets/images/personal_center/gift_card/receiving/hide.png');
    }

  }
  .get_verification_code{
    color: #82929C;
    font-size: 16px;
    &.active{
      cursor: pointer;
      color: $main_blue;
    }
  }
  .select{
    @include background();
    width: 16px;
    height: 16px;
    background-image: url('~@/assets/images/personal_center/gift_card/receiving/unselected.png');
    margin-right: 10px;
    &.active{
      background-image: url('~@/assets/images/personal_center/gift_card/receiving/selected.png');
    }
  }
  &.mobile{
    width: 6.5rem;
    padding: 0 0.5rem 0.5rem;
    .input{
      font-size: 14px;
    }
    .get_verification_code{
      font-size: 14px;
    }
    .read_group{
      font-size: 12px;
    }
    .select{
      margin-right: 4px;
      width: 14px;
      height: 14px;
    }
    .receive{
      font-size: 14px;
      line-height: 40px;
    }
  }
}
</style>
