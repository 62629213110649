/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-08 11:55:42
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-17 14:58:03
 * @FilePath     : /miaohang/src/types/modules/PCardUser/index.js
 * @Description  : 
 */
import PCard from "../PCard"
class PCardUser{
  cardId      = undefined  //: 1 
  cardno      = undefined  //: "LPK202206081116008889" 卡编号 
  channel     = undefined  //: 0 获取渠道0购买 1官方赠送
  createTime  = undefined  //: 1654658160000 创建时间
  id          = undefined  //: 6
  orcode      = undefined  //: null 二维码code字符串 
  orcodeurl   = undefined  //: null 二维码code地址
  poster      = undefined  //: 0 是否生成过海报0否1是
  sendword    = undefined  //: null 寄语
  termTime    = undefined  //: 1655522160000 有效期
  usePhone    = undefined  //: null 使用者手机号
  useTime     = undefined  //: null 使用时间
  useUserId   = undefined  //: null 使用用户id
  useUserName = undefined  //: null 使用者昵称
  userId      = undefined  //: 4360
  card        = undefined  //卡信息
  vipEndDate  = undefined
  getTime = undefined // 领取日期

  constructor(params={}){
    const {
      cardId,
      cardno,
      channel,
      createTime,
      id,
      orcode,
      orcodeurl,
      poster,
      sendword,
      termTime,
      usePhone,
      useTime,
      useUserId,
      useUserName,
      userId,
      card,
      vipEndDate,
      getTime
    } = params ?? {}

    this.cardId = cardId
    this.cardno = cardno
    this.channel = channel
    this.createTime = createTime
    this.id = id
    this.orcode = orcode
    this.orcodeurl = orcodeurl
    this.poster = poster
    this.sendword = sendword
    this.termTime = termTime
    this.usePhone = usePhone
    this.useTime = useTime
    this.useUserId = useUserId
    this.useUserName = useUserName
    this.userId = userId
    this.vipEndDate = vipEndDate
    this.getTime = getTime
    if(card){
      this.card = new PCard(card)
    }
  }
}

export default PCardUser