<template>
  <div
    v-if="visible"
  >
    <plugin-component
      :title="title"
      @handleClose="doClose"
      :chat_type="chat_type"
      :chat_id="chat_id"
      :task_id="task_id"
      @handleSure="handleSure"
      @handleAll="handleAll"
    ></plugin-component>
  </div>
</template>

<script>
import PluginComponent from '@/components/PopUp/At/index';
export default {
  components: {
    PluginComponent,
  },
  data(){
    return{
      visible: false,
      title: '',
      chat_type: '',
      chat_id: '',
      task_id: '',
      handleSure: undefined,
      handleAll: undefined
    }
  },
  mounted(){

  },
  methods: {
    doClose(){
      console.log('close')
      this.visible = false
    }
  }
}
</script>
