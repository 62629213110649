var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "c_pop_up_batch_add_contacts_to_tasks_select_task_thumbnail_task ml-5 mr-10 mb-10",
    },
    [
      _c("thumbnail-task", {
        attrs: {
          "p-task": _vm.pTask,
          "can-select": _vm.canSelect,
          nonselectable: _vm.nonselectable,
          selected: _vm.selected,
        },
        on: { handleSelected: _vm.handleSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }