<template>
  <div
    class="c_pop_up_migrate_task_select_task_operation_bar d-flex align-center justify-space-between"
  >
    <div
      class="number_group d-flex align-center cp"
      @click.stop="handleCheckSelectedList"
    >
      <div
        class="text"
      >
        已选：
      </div>
      <div
        class="number"
      >
        {{ number }}
      </div>
      <div
        class="icon"
      ></div>
      <img
        src="~@/assets/images/teaching_package/select_task/select_number.png"
        alt=""
        class="icon ml-10"
      >
    </div>
    <div
      class="btn_group d-flex align-center"
    >
      <div
        class="btn btn_light cp"
        @click.stop="doClose"
      >
        取消
      </div>
      <div
        class="btn btn_dark cp"
        @click.stop="doSure"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageData: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    number() {
      try {
        const { pageData } = this
        const { selectedList } = pageData
        return selectedList.length
      } catch (e) {
        return ''
      }
    }
  },
  methods: {
    handleCheckSelectedList () {
      this.$emit('handleCheckSelectedList')
    },
    doClose () {
      this.$emit('handleClose')
    },
    doSure () {
      this.$emit('handleSure')
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_migrate_task_select_task_operation_bar {
  .btn_group {
    .btn {
      font-size: 14px;
      line-height: 40px;
      border-radius: 4px;
      padding: 0 26px;
      margin-left: 12px;
    }
  }
}
</style>