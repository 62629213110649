/*
 * @Author       : Hugo
 * @Date         : 2022-04-11 11:21:04
 * @LastEditTime : 2022-04-14 14:48:43
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/utils/plugin/modules/collect_select_route.js
 * @Description  : 
 * @^_^          : 
 */
import PluginComponent from '@/components/Collect/Plugin/CollectSelectRoute.vue'

const PluginMember = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PluginMemberConstructor = Vue.extend(PluginComponent)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PluginMemberConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$collectSelectRoute = (params={}) => {
      // // console.log(obj)
      const {title, onSure,} = params
      instance.visible = true
      instance.onSure = onSure
      instance.title = title ?? '选择分组'
    }
    Vue.prototype.$closeCollectSelectRoute = () => {
      instance.visible = false
    }
  }
}

export default PluginMember