var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_personal_center_plugins_not_member_index bbox" },
    [
      _c("div", { staticClass: "main bbox" }, [
        _vm.hint
          ? _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))])
          : _vm._e(),
        _c("div", { staticClass: "list_group bbox" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("成为VIP用户享受以下功能："),
          ]),
          _c(
            "div",
            {
              staticClass:
                "entitlemen_table d-flex align-center flex-wrap un_sel",
            },
            [
              _vm._l(_vm.entitlemen_table, function (item, index) {
                return [
                  _c(
                    "div",
                    { key: index, staticClass: "item d-flex align-center" },
                    [
                      _c("img", {
                        staticClass: "img mr-10",
                        attrs: {
                          src: require(`@/assets/images/personal_center/vip/vip${item.pic}.png`),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "btn_group bbox d-flex align-center" }, [
          _c(
            "div",
            {
              staticClass: "button member mr-10",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.becomeMember()
                },
              },
            },
            [_vm._v("立即开通VIP会员")]
          ),
          _c(
            "div",
            {
              staticClass: "button close cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("handleClose")
                },
              },
            },
            [_vm._v("我再想想")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }