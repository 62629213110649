<template>
  <div
    class="c_pop_up_migrate_task_select_task d-flex flex-column justify-space-between"
  >
    <div
      v-show="!params.checkSelected"
      class="search_wrapper d-flex align-center bbox flex-shrink-0"
    >
      <div
        class="all_group d-flex align-center cp mr-20"
        @click.stop="handleALl"
      >
        <div
          class="all_icon mr-5"
          :class="{ active: isAll }"
        ></div>
        <div
          class="text"
        >
          当前全部任务
        </div>
      </div>
      <div
        class="search_group d-flex align-center bbox"
        @click.stop="touchSearch"
      >
        <div
          class="icon mr-4"
        ></div>
        <input
          v-model="search"
          type="text"
          class="search bbox"
          ref="search"
          placeholder="搜索..."
          @keydown.enter="handleSearchKeydown"
        >
        <div
          v-show="search.trim() !== ''"
          class="clear"
          @click.stop="clearSearch"
        ></div>
      </div>
    </div>
    <div
      class="list_wrapper bbox"
      ref="listWrapper"
    >
      <task-list
        v-show="list && list.length"
        class="list_group bbox"
        :list="list"
        :page-data="pageData"
        :params="params"
      ></task-list>
      <div
        v-show="!loadingTask && (!list || list.length===0)"
        class="no_task_group d-flex align-center justify-center"
      >
        <div
          class="no_task"
        >
          无相关任务
        </div>
      </div>
      <div
        v-show="!noMore"
        class="loadmore_group d-flex align-center justify-center bbox flex-shrink-0"
      >
        <div
          v-show="!loadingTask"
          class="load_more cp"
          @click.stop="loadingMoreTask"
        >
          加载更多
        </div>
        <div
          v-show="loadingTask"
          class="loading d-flex align-center justify-center"
        >
          <loading-icon
            size="20px"
          ></loading-icon>
        </div>
      </div>
    </div>
    <operation-bar
      v-show="!params.checkSelected"
      class="operation_bar bbox"
      :page-data="pageData"
      @handleCheckSelectedList="handleCheckSelectedList"
      @handleClose="handleClose"
      @handleSure="handleSure"
    ></operation-bar>
  </div>
</template>

<script>
import LoadingIcon from '@/components/basic/loadingIcon'
import global from '@/api/global'
import tools from '@/api/tools'
import TaskList from './SelectTask/TaskList'
import OperationBar from './SelectTask/OperationBar'
export default {
  components: {
    OperationBar,
    LoadingIcon,
    TaskList
  },
  props: {
    params: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      loadingTask: false,
      noMoreTask: false,
      noMoreSearch: false,
      search: '',
      oldSearch: '',
      taskList: [],
      searchList: [],
      selectedList: [],
      switchSearch: false,
      checkList: [],
      pageData: {
        selectedList: []
      }
    }
  },
  computed: {
    pChatDetail () {
      const { params } = this
      if (params) {
        const { pChatDetail } = params
        return pChatDetail
      }
      return undefined
    },
    list () {
      const { searchList, taskList, switchSearch, checkList, params } = this
      const { checkSelected } = params
      if (checkSelected) {
        console.log(checkList)
        return checkList
      }
      if (switchSearch) {
        return searchList
      }
      return taskList
    },
    isAll () {
      try {
        const { pageData, list } = this
        const { selectedList } = pageData
        // list成员都在selectedList中 则为true
        if (selectedList.length === 0) {
          return false
        }
        if (!list || list.length === 0) {
          return false
        }
        for (let i of list) {
          const aim = selectedList.find((unit) => {
            return unit.id === i.id
          })
          if (!aim) {
            return false
          }
        }
        return true
      } catch (e) {
        return false
      }
    },
    noMore () {
      try {
        const { noMoreTask, noMoreSearch, switchSearch, params } = this
        const { checkSelected } = params
        if (checkSelected) {
          return true
        }
        if (switchSearch) {
          return noMoreSearch
        }
        return noMoreTask
      } catch (e) {
        return true
      }
    }
  },
  watch: {
    search: function (val) {
      if (val.trim() === '') {
        this.clearSearch()
      }
    }
  },
  methods: {
    handleSure () {
      const { pageData } = this
      const { selectedList } = pageData
      if (!selectedList || selectedList.length === 0) {
        this.$notice({
          desc: '未选择任务'
        })
        return
      }
      this.$openMigrateTaskSelectTargetGroup({
        taskList: selectedList
      })
    },
    handleClose () {
      this.$emit('handleClose')
    },
    /**
     * 查看已选任务
     */
    handleCheckSelectedList () {
      this.params.title = '查看已选'
      this.params.checkSelected = true
      console.log(this.selectedList)
      this.$set(this, 'checkList', tools.deepClone(this.pageData.selectedList))
      console.log(this.checkList)
    },
    handleALl () {
      try {
        const { pageData, list, isAll } = this
        const { selectedList } = pageData
        if (list && list.length) {
          if (isAll) {
            // 删除
            for (let i of list) {
              const index = selectedList.findIndex((unit) => {
                return unit.id === i.id
              })
              if (index !== -1) {
                selectedList.splice(index, 1)
              }
            }
          } else {
            // 增加
            // 查找是否有大于100人的任务
            const aLotOfUsersTask = list.find((unit) => {
              if (unit.tousers) {
                const tousersArr = unit.tousers.split(',')
                return tousersArr.length > 100
              }
            })
            if (aLotOfUsersTask) {
              this.$openConfirm({
                title: '是否将大于100个参与人的任务全部选中？',
                onSure: () => {
                  for (let i of list) {
                    const aim = selectedList.find((unit) => {
                      return unit.id === i.id
                    })
                    if (!aim) {
                      selectedList.push(i)
                    }
                  }
                },
                onCancel: () => {
                  for (let i of list) {
                    const aim = selectedList.find((unit) => {
                      return unit.id === i.id
                    })
                    const tousersArr = i.tousers.split(',')
                    if (!aim && tousersArr.length <= 100) {
                      selectedList.push(i)
                    }
                  }
                }
              })
              return
            }
            for (let i of list) {
              const aim = selectedList.find((unit) => {
                return unit.id === i.id
              })
              if (!aim) {
                selectedList.push(i)
              }
            }
          }
        }
      } catch (e) {
        return
      }
    },
    clearSearch () {
      this.search = ''
      this.oldSearch = ''
      this.switchSearch = false
      this.noMoreSearch = false
    },
    loadingMoreTask() {
      // 先跳转到底部
      try {
        const { $refs } = this
        const { listWrapper } = $refs
        listWrapper.scrollTop = listWrapper.scrollHeight
      } catch (e) {
        console.log(e)
      }
      this.getList()
    },
    touchSearch() {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },
    init() {
      this.getList()
    },
    handleSearchKeydown (e) {
      const { keyCode } = e
      if (keyCode === 229) {
        return
      }
      this.doSearch()
    },
    doSearch () {
      const { search } = this
      const keyword = search.trim()
      if (keyword === '') {
        this.clearSearch()
        return
      }
      // 如果跟上次搜索的内容不一样 则不变化
      if (this.oldSearch !== keyword) {
        this.oldSearch = keyword
        this.$set(this, 'searchList', [])
        this.switchSearch = true
        this.getList()
      }
    },
    async getList() {
      const { pChatDetail, search } = this
      if ((pChatDetail ?? '') === '') {
        return
      }
      if (this.loadingTask) {
        return
      }
      this.loadingTask = true
      const { chatType, chatId } = pChatDetail
      const keyword = search.trim()
      let start = 0
      if (keyword === '') {
        start = this.taskList.length
      } else {
        start = this.searchList.length
      }
      const data = {
        keyword,
        chatType: Number(chatType),
        chatId: Number(chatId),
        start
      }
      const res = await global.doPost({
        url: '/projectTaskLogSearch/searchTaskLog',
        data
      })
      this.loadingTask = false
      const { message, contents } = res
      if (message === 'success') {
        if ((contents ?? '') === '') {
          return
        }
        const { taskList } = contents
        if ((taskList ?? '') === '' ) {
          return
        }
        // 没有更多了
        if (taskList.length < 50) {
          if (keyword !== '') {
            this.noMoreSearch = true
          } else {
            this.noMoreTask = true
          }
        }
        if (keyword !== '') {
          this.searchList = this.searchList.concat(taskList)
        } else {
          this.taskList = this.taskList.concat(taskList)
        }
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_migrate_task_select_task {
  width: 100%;
  color: #333;
  position: relative;
  flex: 1;
  overflow: auto;
  .search_wrapper {
    width: 100%;
    position: sticky;
    z-index: 3;
    background-color: #fff;
    padding: 10px 20px;
    .all_group {
      .all_icon {
        @include background;
        width: 15px;
        height: 15px;
        background-image: url(~@/assets/images/common/square_select.png);
        &.active {
          background-image: url(~@/assets/images/common/square_selected.png);
        }
      }
      .text {
        font-size: 13px;
        color: #666;
      }
    }
    .search_group {
      padding: 0 10px;
      border-radius: 4px;
      background-color: #f3f3f3;
      position: relative;
      .icon {
        @include background;
        background-image: url(~@/assets/images/common/search_icon.png);
        width: 16px;
        height: 16px;
        cursor: text;
      }
      .search {
        @include placeholder(#ccc);
        background-color: transparent;
        font-size: 14px;
        color: #333;
        line-height: 28px;
        height: 28px;
        outline: none;
        border: none;
        width: 160px;
        padding-right: 20px;
      }
      .clear {
        @include background(14px, 14px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/images/common/clear_icon.png);
      }
    }
  }
  .list_wrapper {
    @include scrollbar;
    width: 100%;
    padding: 10px 20px;
    overflow: auto;
    flex: 1;
    .list_group {
      width: 100%;
      padding-top: 20px;
    }
    .no_task_group {
      width: 100%;
      height: 100%;
      color: #999;
      font-size: 15px;
    }
  }
  .loadmore_group {
    //position: absolute;
    //height: 100%;
    min-height: 40px;
    width: 100%;
    //bottom: 0;
    //left: 0;
    background-color: #fff;
    .load_more {
      font-size: 14px;
      color: $main_blue;
    }
  }
  .operation_bar {
    width: 100%;
    padding: 10px 20px;
  }
}
</style>