<template>
  <div
    class="weaken_content_page d-flex justify-center align-center"
  >
    <work-weaken :msg="msg"
      v-if="type==='work'"
    ></work-weaken>
    <common-weaken
      v-else
      :msg="msg"></common-weaken>
  </div>
</template>
<script>
import WorkWeaken from '@/components/dialog/Weaken/Work.vue';
import CommonWeaken from '@/components/dialog/Weaken/Common.vue';
export default {
  props:{
    msg: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'default',
    }
  },
  components: {
    CommonWeaken,
    WorkWeaken,
  }
}
</script>
<style lang="scss" scoped>
.weaken_content_page{
  width: 100%;

}
</style>