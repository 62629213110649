var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        [
          _c("common-wrapper", { attrs: { height: "auto", width: "600px" } }, [
            _c(
              "div",
              { staticClass: "c_plugin_invivation_poster d-flex flex-column" },
              [
                _c(
                  "common-title",
                  {
                    staticClass: "flex-shrink-0 title_common",
                    attrs: {
                      title: _vm.title,
                      close: true,
                      background_color: "transparent",
                    },
                    on: { handleClose: _vm.handleClose },
                  },
                  [
                    _c("template", { slot: "default" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title_group bbox d-flex justify-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "title" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.title) +
                                  "\n              "
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "c_plugin_invivation_poster_tooltip_content",
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  1、每个邀请链接/海报72小时内有效；"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  2、此页面展示最近一次生成且未失效的链接/海报，更多海报请在“邀请记录”中查看；"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  3、若想生成新时效的链接/海报，请点击按钮“生成新链接/海报”\n                "
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "question" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass: "close cp",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleClose()
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
                _c("plugin-component", {
                  staticClass: "plugin_component",
                  on: {
                    handleClose: _vm.handleClose,
                    handleSure: _vm.handleSure,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }