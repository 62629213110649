var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "give_away_main bbox d-flex flex-column align-center",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.touchWrapper()
        },
      },
    },
    [
      _vm.pcarduser
        ? _c("card-component", {
            staticClass: "mt-20",
            attrs: {
              width: "422px",
              height: "236px",
              pcard: _vm.pcarduser.card,
              pcarduser: _vm.pcarduser,
              show_cardno: true,
              title_size: "28px",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "title_group d-flex align-center justify-space-between bbox mt-20",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("想对Ta说：")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.edit,
                  expression: "!edit",
                },
              ],
              staticClass: "modify cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchEdit()
                },
              },
            },
            [_vm._v("修改")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.edit,
                  expression: "edit",
                },
              ],
              staticClass: "done btn_dark cp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.edit = false
                },
              },
            },
            [_vm._v("完成")]
          ),
        ]
      ),
      _c("el-input", {
        ref: "input",
        staticClass: "give_away_main_input mt-12",
        class: { show: !_vm.edit },
        attrs: {
          type: "textarea",
          maxlength: "80",
          placeholder: _vm.placeholder,
          "show-word-limit": "",
          resize: "none",
          rows: "4",
          readonly: !_vm.edit,
        },
        model: {
          value: _vm.input,
          callback: function ($$v) {
            _vm.input = $$v
          },
          expression: "input",
        },
      }),
      _c("div", { staticClass: "time_group d-flex align-center" }, [
        _c("div", { staticClass: "symbol" }, [_vm._v("\n      *\n    ")]),
        _c("div", { staticClass: "key" }, [
          _vm._v("\n      设置好友领取期限至\n    "),
        ]),
        _c(
          "div",
          { staticClass: "time" },
          [
            _c(
              "div",
              {
                staticClass: "text bbox cp",
                class: { empty: _vm.getTime === "" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchPicker()
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.getTimeFormat) + "\n      ")]
            ),
            _c("el-date-picker", {
              ref: "picker",
              staticClass: "picker",
              attrs: {
                type: "date",
                placeholder: "选择日期",
                "picker-options": _vm.pickerOptions,
              },
              model: {
                value: _vm.getTime,
                callback: function ($$v) {
                  _vm.getTime = $$v
                },
                expression: "getTime",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", {
        staticClass: "hint mt-15",
        domProps: { textContent: _vm._s(_vm.hint) },
      }),
      _c(
        "div",
        {
          staticClass: "give_away btn_dark",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSend()
            },
          },
        },
        [_vm._v("立即赠送")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }