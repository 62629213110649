/*
 * @Author       : Hugo
 * @Date         : 2020-08-19 10:00:28
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-06-08 10:15:26
 * @Description  : 
 * @FilePath     : /miaohang/src/store/modules/common.js
 */

import Vue from '@/main.js'

// 任务
const common = {
  state: {
    search_chat_groups: null, //搜索聊天记录
    search_friends: null, //搜索好友
    search_switch: false, //搜索开关
    search_key: '', //搜索关键词
  },
  mutations: {
    common_set_search_chat_groups(state, payload){
      state.search_chat_groups = payload;
    },
    common_set_search_friends(state, payload){
      state.search_friends = payload;
    },
    common_set_search_switch( state, payload) {
      state.search_switch = payload;
    },
    common_set_search_key( state, payload){
      state.search_key = payload;
    },
  },
  actions: {
    /**
     * 搜索框
     * @param {*} param0 
     * @param {*} params 
     */
    async common_get_search_data({ state, commit }, params) {
      let key = params.key
      // if (String(key) === String(state.search_key)) {
      //   return
      // }
      Vue.$showLoading({ text:'搜索中' })
      if (key !== '') {
        let res = await Vue.$http.request('/project/searchWeb', { param: key })
        let { chatGoups, friends, tasks } = res.contents
        commit('common_set_search_chat_groups', chatGoups)
        commit('common_set_search_friends', friends)
        commit('common_set_search_switch', true)
        commit('common_set_search_key', key)
        commit('set_search_task', tasks)
      }
      Vue.$hideLoading()
    },
    /**清除搜索内容 */
    async common_clear_search({commit}){
      commit('common_set_search_chat_groups', null);
      commit('common_set_search_friends', null);
      commit('common_set_search_switch', false);
      commit('common_set_search_key', '');
    }
  },
};

export default common;
