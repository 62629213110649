var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-wrapper",
    { attrs: { max_width: "400px", max_height: "180px" } },
    [
      _c(
        "div",
        {
          staticClass:
            "components_pop_up_payment_status_index d-flex align-center justify-center",
        },
        [
          _c("div", {
            staticClass: "status_icon",
            class: { failed: _vm.status === "failed" },
          }),
          _vm.close
            ? _c("div", {
                staticClass: "close cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doClose()
                  },
                },
              })
            : _vm._e(),
          _c("div", [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm.jump
              ? _c("div", { staticClass: "jump_group pt-20" }, [
                  _c("span", { staticClass: "seconds blue" }, [
                    _vm._v(_vm._s(_vm.count_down)),
                  ]),
                  _c("span", { staticClass: "hint" }, [
                    _vm._v(
                      " 秒后将自动跳转至" + _vm._s(_vm.jump_name) + " ｜ "
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "immediately_jump blue cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.immediatelyJump()
                        },
                      },
                    },
                    [_vm._v("立即跳转")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }