var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("div", { staticClass: "components_plugin_user_detail_page bbox" }, [
        _c(
          "div",
          {
            staticClass: "mask d-flex align-center justify-center",
            style: { zIndex: _vm.index },
            on: {
              click: function ($event) {
                return _vm.doClose()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "container bbox d-flex flex-column",
                on: {
                  click: function ($event) {
                    return _vm.$api.stopPropagation($event)
                  },
                },
              },
              [
                0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "title_group d-flex align-center un_sel justify-center flex-shrink-0",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                        _c("div", {
                          staticClass: "close cp",
                          on: {
                            click: function ($event) {
                              return _vm.doClose()
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "main" },
                  [
                    _c("contact-detail-basic", {
                      attrs: { userData: _vm.data },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn_group d-flex align-center justify-center",
                      },
                      [
                        _vm.data && _vm.data.isfriend
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn_dark cp dialog",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.initiateDialogue()
                                  },
                                },
                              },
                              [_vm._v("\n            发起对话\n          ")]
                            )
                          : _vm._e(),
                        _vm.data &&
                        !_vm.data.isfriend &&
                        _vm.data.userId != _vm.$tools.getUserId()
                          ? _c(
                              "div",
                              {
                                staticClass: "btn cp btn_dark apply",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.applyFriend(_vm.data)
                                  },
                                },
                              },
                              [_vm._v("\n            申请联系人\n          ")]
                            )
                          : _vm._e(),
                        _vm.canBatch
                          ? _c(
                              "div",
                              {
                                staticClass: "btn cp btn_light",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.touchAddToTasks.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            批量添加至任务\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }