import { render, staticRenderFns } from "./audit_course_package.vue?vue&type=template&id=47224432&scoped=true"
import script from "./audit_course_package.vue?vue&type=script&lang=js"
export * from "./audit_course_package.vue?vue&type=script&lang=js"
import style0 from "./audit_course_package.vue?vue&type=style&index=0&id=47224432&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47224432",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47224432')) {
      api.createRecord('47224432', component.options)
    } else {
      api.reload('47224432', component.options)
    }
    module.hot.accept("./audit_course_package.vue?vue&type=template&id=47224432&scoped=true", function () {
      api.rerender('47224432', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialog/Sketch/audit_course_package.vue"
export default component.exports