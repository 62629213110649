var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "privacy_policy_page" }, [
      _c("div", [_vm._v("导言")]),
      _c("div", { staticClass: "indent" }, [
        _vm._v(
          "欢迎使用由北京优联信驰信息科技有限公司（以下简称“优联科技”或“UTEC”或“我们”）提供服务或运营控制的包含妙行、会E人等系统、产品和服务。(以下简称“我们的产品”)用户的隐私安全对于我们十分重要，我们非常愿意告知您我们如何收集和使用您的相关信息。"
        ),
      ]),
      _c("h4", [_vm._v("一、隐私协议的覆盖范围")]),
      _c("div", [_c("div", [_vm._v("本协议旨在帮助您了解如下内容：")])]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们收集哪些信息；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们将如何使用收集到的信息；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们如何共享收集到的信息；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们如何存储收集到的信息并如何保障其安全性；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t有害信息的过滤和删除；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t您的权利；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们收集到的信息是如何跨境传输的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t业务转让；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t其他重要的隐私策略；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t我们对待儿童的隐私政策；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t联系我们；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t隐私政策的变更")]),
      ]),
      _c("div", [
        _vm._v(
          "本隐私协议在您使用我们的产品时全程有效（包含但不限于妙行、会E人等）。此协议还阐述了您有权决定是否允许我们收集您的行为数据。如果您不同意此协议，请勿访问或使用我们的产品或者与我们业务的任何其他方面进行互动。我们的产品在为您提供服务时收集的信息由优联科技进行控制。我们的产品以包括网站、桌面产品、移动产品在内的形式为您提供了协作服务，帮助您进行团队合作和与人沟通。本协议中的“服务”指的是上述所有产品及服务。"
        ),
      ]),
      _c("h4", [_vm._v("二、我们收集哪些信息")]),
      _c("div", [
        _vm._v(
          "我们将收集您提供给我们的信息，包括您在使用我们的产品或服务时产生的信息以及其他信息来源向我们提供的信息，具体类别如下所述："
        ),
      ]),
      _c("h4", [_vm._v("您提供给我们的信息")]),
      _c("div", [
        _vm._v("我们将收集您在我们的产品或服务中直接输入或提交的数据。"),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "账户信息和个人资料信息：我们将在您注册账户、创建或修改个人资料、设置、登入、通过我们的服务进行购买或为购买而进行登记时收集您的有关信息，例如：您在注册服务时提供的联系信息以及某些情况下的帐单信息将会被我们收集。您还可以选择将个人照片及其他详细信息添加到您的个人资料中，以显示于我们的服务系统之上。在您选择或修改个人偏好时，我们也将持续获取和追踪您的偏好信息。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "您通过我们的产品所提供的内容：我们将通过您使用的我们的产品的网页、桌面产品和移动产品来收集和存储您发布、发送、接收和分享的内容，此内容包括您的任何信息或者您选择包含的信息，还包括您上传到服务系统中的文件和链接。我们收集和存储的内容包括：您附加于任何模版、表单上的文件、项目、列表或企业的名称或者对工作内容的描述，您在模版、内容、分享、文件中的评论，您上传的图片、表情以及文件等。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "您通过我们的产品所提供的信息：我们也通过所拥有、运营的产品和社交媒体来收集您所提交的信息。例如，当您提供反馈或参与任何互动功能调查、促销活动、抽奖活动或其他活动时，您向我们提供内容都会被我们收集。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("4.")]),
        _c("div", [
          _vm._v(
            "您通过我们的支持渠道所提供的信息：我们还将收集您通过我们的客户支持服务或者其他支持服务所提交的问题与信息。当您打开我们的客户支持聊天窗口或是邮件时，系统会要求您提供联系信息、您所遇到的问题或截图以及任何其他有助于解决问题的文档、截屏等信息。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("5.")]),
        _c("div", [
          _vm._v(
            "支付信息：当您注册某些付费服务时，我们会收集某些付款和账单信息。例如：在您为我们的产品付费时，我们要求您提交的姓名和联系信息等；您也可能会提供付款账号信息，如银行卡卡号等。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("您使用服务时自动收集的信息")]),
      _c("div", [
        _vm._v(
          "当您浏览和使用我们的服务时（如浏览网站或者在网页中进行相应的操作），我们将自动收集您的一些相关信息。"
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "您在我们的产品中的使用行为：当您访问我们的任何服务并与之互动时，我们会跟踪您的某些信息。这些信息包括您正在使用的功能，如：点击模版、选择联系人或者点击其他链接；同时包括了您上传到服务中的附件及其类型、大小和文件名称；也包括您所经常使用的搜索词以及您在产品中是如何与他人互动的等等。我们还收集了您合作的团队和人员的信息以及您与他们的互动方式，例如与您最频繁协作和沟通的人员。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "设备与连接信息：在您使用我们的服务时，我们收集了有关您的计算机、手机、平板电脑或您用于访问服务的其他设备的信息。此设备信息包括您在安装、访问、更新或使用我们的服务时的连接类型和设置。我们还通过您的设备收集关于您的操作系统、浏览器类型、IP地址、分享/退出页面的URL、设备标识符以及应用于软件崩溃相关数据的信息。我们也将使用您的IP地址、国家或地区偏好来估算您的位置，以提供更好的服务体验。我们所收集的信息数量取决于您用于访问服务的设备类型和设置。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "Cookies和其他跟踪技术：我们及我们的第三方合作伙伴（如我们的分析合作伙伴）可能会使用Cookie和其他跟踪技术（如网页打点等）来识别您的身份。Cookie是仅限文本的信息字符串，网站会将这些字符串传输到计算机硬盘的浏览器 Cookie 文件中，以便网站能够记住您的身份信息以及其他详细信息。Cookie可能由网站设置或来源自第三方，如广告商。Cookie本身不用于或意图用于从用户计算机读取任何信息（Cookie本身的内容除外）。Cookie只是网站所使用的最初将其放置在您硬盘上的标识符。同一台服务器可以检索到 Cookie 信息的实际内容，从而标识计算机，并进而根据主服务器上存储的信息来自定义、跟踪或控制站点的使用情况。我们会通过这些追踪手段来帮助您更好地使用我们的产品，也帮助我们更好地改善对应功能。您可以通过修改浏览器设置来选择接受或者拒绝Cookie。但是如果您禁用了Cookie，您将不能使用我们的产品的各项交互功能。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("我们从其他来源渠道收集到的信息")]),
      _c("div", [
        _vm._v(
          "我们会从服务中的其他用户、其他第三方服务和相关公司以及我们的业务和渠道合作伙伴那里收集到有关您的信息"
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "服务中的其他用户：我们服务的其他用户可能会在服务提交内容时提供有关您的信息。例如：在模版备注或信息内容中写入您的名字，或者在聊天中提及您的信息。我们也会收集到来自其他服务用户的您的电子邮箱地址、手机号、微信等，以便邀请您加入服务。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "您关联到账户的其他服务：当您启用微信等第三方应用或将第三方服务与我们的服务关联时，我们会收集到有关您的信息。例如：如果您使用微信凭据创建账户或登录服务，我们会根据您的微信个人资料设置的许可收到您的姓名等信息，以便对您进行身份验证。您或您的管理员可能还会将我们的服务与您使用的其他服务相联合，如允许您通过我们的服务访问、存储、共享和编辑来自第三方的某些内容。例如：您可以授权我们的服务在服务界面中访问和显示来自第三方文档共享服务的文件（如 Office365等）。又或者，您可以授权我们的服务同步联系人列表或地址簿，以便您可以轻松地与服务中的联系人进行联系，或者邀请他们与我们在服务上协作。在将我们的服务与第三方服务链接或联合时，我们收集到的信息取决于该第三方服务所控制的设置、权限和隐私政策。因此您应该始终关注并了解这些第三方服务中的隐私设置和通知，以了解可能向我们透露或与我们的服务共享的数据。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "我们的合作伙伴：我们与各大合作伙伴开展合作，将会围绕我们的产品与合作伙伴的产品进行单点登录、数据打通、培训和开展其他服务。其中一些合作伙伴还帮助我们推广产品，为我们带来销售线索并转售我们的产品。我们会收到来自这些合作伙伴的信息，如账单信息、技术联系信息、公司名称、您所感兴趣的产品等。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("三、我们将如何使用收集到的信息")]),
      _c("div", [
        _vm._v(
          "我们如何使用收集到的信息部分取决于您所使用的服务、如何使用它们以及您所设置的偏好。以下是我们使用所收集的、关于您的信息的具体目的："
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "为您提供更好的服务与个性化体验：我们使用关于您的信息向您提供服务，包括识别您在什么地点登陆、应该提供什么样的用户支持以及如何运营和维护整体服务。例如：我们使用您在账户中提供的名称和图片（如头像）来向其他服务用户标识您。我们的服务还包括个性化您的体验与定制功能，提高您使用 我们的产品的效率，并通过自动化的分析与计算，提高他人与您沟通协作的能力与效率，更好地帮助您和您的团队构建稳固与准确的关联关系。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "用于研究与开发迭代：我们一直在寻找方法使我们的服务以更智能、更快速、更安全、更综合、更有效的方式来帮助您获得更好的使用体验。我们通过集体学习，了解人们如何使用我们的服务，并不断收集用户直接向我们提供的反馈，以帮助我们排查问题并确定产品趋势、用法、活动模式以及服务整合和改进的方式。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "用于改善与用户的沟通交流：我们将使用您的联系信息，通过电子邮件和服务内容发送交易邮件，包括回应您的意见问题与需求、提供客户支持以及向您发送更新、通知、警告等。根据您的设置，当您或其他人在服务中进行互动时，我们会向您发送电子邮件通知，例如：当您在模版中被人提及或将您添加到模版时。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("4.")]),
        _c("div", [
          _vm._v(
            "用于技术服务：我们使用您的信息来解决您遇到的技术问题、响应您的协助请求、分析崩溃信息并进行修复和改进服务。如果您允许我们这样做，我们会与我们的产品的技术专家分享您的信息，以回应与支持相关请求。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("5.")]),
        _c("div", [
          _vm._v(
            "用于加强安全保护与鉴权：我们使用有关您和您的服务使用情况信息来验证账户和活动、监控可疑或欺诈活动并确定上述行为是否违反服务政策。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("6.")]),
        _c("div", [
          _vm._v(
            "用于保护我们合法的商业利益和其他合法权益：在法律要求或我们认为有必要保护我们的合法权利、利益和他人利益的情况下，我们会使用有关您的信息来处理法律诉讼、合规、监管和审计相关事务，以及与收购、合并或出售业务相关的信息披露。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("7.")]),
        _c("div", [
          _vm._v(
            "数据处理的合法性：合法性取决于您使用的服务以及您如何使用这些服务。这意味着我们仅在以下情形收集和使用您的信息：我们需要处理数据以为您提供服务，包括运营服务、提供客户支持、个性化功能以及保护服务的安全性；处理数据是为实现合法利益所需（您所享有的数据保护利益优先于该种利益的情况除外），如用于研究和开发、营销和推广服务以及保护我们的合法权益；您同意为特定目的而处理数据；我们需要处理您的数据以履行法律义务。"
          ),
        ]),
      ]),
      _c("div", [
        _vm._v(
          "如果您已经同意我们出于特定目的使用您的信息，您也拥有随时改变主意的权利，但这不会影响任何已经发生了的处理。当我们或第三方（如您的雇主）合法使用您的信息时，您有权反对。但是在某些情况下，这可能意味着您不再使用服务。"
        ),
      ]),
      _c("h4", [_vm._v("四、我们如何共享收集到的信息")]),
      _c("div", [
        _vm._v(
          "我们开发和提供沟通协作工具，并希望它们能更好地帮助您的工作和生活。这也意味着我们将通过服务或者第三方的形式共享收集到的信息。我们将以下述方式分享我们收集的有关您的信息，包括与可能的业务转移相关的信息，但我们承诺绝不向广告客户或其他第三方出售您的信息。"
        ),
      ]),
      _c("h4", [_vm._v("与服务中的其他用户共享")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "用于沟通、协作、合作：您可以创建包含您的信息的模版、工作组或其他内容，并添加授予其他人的访问权限或者授予他人共享、编辑、复制和下载的权限。当您共享或与特定内容进行互动时，服务的某些协作功能会向其他服务用户显示部分或全部的个人资料信息。例如：当您在发布模版时，我们会在您发布的内容旁边显示您的个人头像和名称，以便其他有权访问此模版、工作组、对话窗口的用户了解是谁发表了内容；同样，当您加入对话或工作组时，您的姓名、个人资料图片和联系信息将显示在其他成员的列表中，以便他们可以查找到您并与您互动。请注意，有些模版、工作组或者项目可以设置为公开，这意味着发布在该载体上的任何内容（包括关于您的信息）都可以被公开查看。您可以随时查看您的项目设置，以确认某个特定的项目是否是公开的。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "信息发布、经验社区：我们的产品提供可公开访问的经验频道、评论区等。您在这些版块上提供的任何信息，包括用于发布信息的账号相关个人信息资料，可能被任何访问我们的产品的公众所阅读、收集和使用。即使您终止了您的账户，您的经验文章、评论和某些配置文件信息也可能会被保留。我们建议您在输入这些信息时考虑其安全性与隐私性。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("与第三方合作伙伴共享")]),
      _c("div", [
        _vm._v(
          "我们与第三方分享信息，以帮助我们运营、提供、改进、整合、定制、支持和销售我们的服务。"
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "指向第三方网站的链接：服务可能包含指向您的其他网站或服务的链接，这些网站或服务的隐私惯例可能与我们的不同。您需要注意的是，您使用以及您提交给任何第三方网站的信息均受其隐私政策的约束，而不是受本服务隐私政策的约束。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "我们有权选择是否将以弹窗的方式单独告知接收个人信息的第三方身份或类型、向第三方分享数据的目的及数据范围。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("与关联公司分享")]),
      _c("div", [
        _vm._v(
          "在某些情况下，我们会与关联公司分享我们收集的信息。关联公司是我们拥有或经营的公司。我们与关联公司分享您的信息，以运营和改善产品和服务并提供其他相关服务给您。本隐私政策适用于我们在这些情况下分享的信息。"
        ),
      ]),
      _c("h4", [_vm._v("五、我们如何存储收集到的信息并如何保障其安全性")]),
      _c("div", [
        _vm._v(
          "信息的存储和安全：我们使用阿里云提供的云服务托管我们收集的信息，并使用技术措施来保护您的数据。尽管我们极尽可能地保护您的信息安全，但由于互联网的固有特性，没有任何系统是绝对安全的，我们无法保证数据在通过互联网传输过程中和存储在我们系统时的绝对安全。我们会尽一切可能防止安全入侵事件的发生。但如果发生该类事件，我们将在合理期限内第一时间告知您。"
        ),
      ]),
      _c("h4", [_vm._v("信息将被存储多久")]),
      _c("div", [
        _vm._v(
          "信息将被存储多久是由该信息的种类所决定的，我们在下文中将详细描述。当信息达到一定存储期限后，我们会删除您的信息或做匿名处理。但当删除操作无法进行的时候（如信息已存储在备份存档中），我们将安全地存储您的信息，并将其隔离，直至删除成为可能。"
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "账号信息：我们将保留您的账户信息，直到您删除您的账户。我们还将保留一些必要的信息，以履行法律义务、解决争议、履行协议、支持业务运营并继续开发和改进我们的服务。如果我们将要保留这些信息以用于服务改进和产品迭代，我们会将数据脱敏，并且我们只会利用这些信息进行集体性分析而不会针对您的个人数据做专门分析。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "您在我们服务中所分享的信息：如果您的账户已停用或被停用，您的部分信息和您提供的内容将被保留，以便您的团队成员或其他用户充分使用服务。例如：我们会继续显示您提供给项目、网站或模版等中的评论和内容。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "市场偏好信息：如果您选择接收我们的营销电子邮件，我们会保留有关您的营销偏好信息，除非您明确要求我们删除此类信息。我们将保留自创建日期起的合理时间段内从 Cookie 和其他跟踪技术中所获得的信息。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("六、有害信息的过滤和删除")]),
      _c("div", [
        _vm._v(
          "根据相关法律的规定，我们的产品和服务禁止用户创建和储存一切有害信息，包括："
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t违反中国宪法确定的基本原则的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t有损国家声誉和利益，危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一的；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t宣扬恐怖主义、极端主义、民族仇恨、民族歧视的；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t破坏国家、地区间友好关系的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t破坏国家宗教政策，宣扬邪教和封建迷信的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t散布谣言或不实消息，扰乱社会秩序，破坏社会稳定的；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t散布色情、淫秽、暴力、赌博、凶杀、恐怖内容或者教唆犯罪的；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t侵犯他人名誉、隐私、著作权、信息网络传播权等合法权益的；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t含有法律、行政法规禁止的其他内容的。")]),
      ]),
      _c("div", [
        _vm._v(
          "我们将针对以上信息制定过滤和屏蔽机制。如用户创建项目或储存文件时不能履行和遵守本政策中的规定，我们有权对违反本政策的用户做出关闭账户的处理，同时保留依法追究当事人法律责任的权利。此外，我们在采取移除等相应措施后，不为此向原发布人承担违约责任或其他法律责任。"
        ),
      ]),
      _c("h4", [_vm._v("七、您的权利")]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("1.")]),
        _c("div", [
          _vm._v(
            "您可以在使用我们的产品和服务的过程中，访问、修改、撤回和删除您提供的注册信息和其他个人信息，也可以与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("2.")]),
        _c("div", [
          _vm._v(
            "每个业务功能可能需要某些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或撤回您的授权同意。当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("3.")]),
        _c("div", [
          _vm._v(
            "在某些业务功能中，我们可能仅依据信息系统、算法等非人工自动决策机制对您的个人信息做出处理。如果这些决定将显著影响您的合法权益，您有权要求我们做出解释，我们也将对此提供适当的救济方式。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("4.")]),
        _c("div", [
          _vm._v(
            "您有权要求限制对您个人数据的处理。在下列情形中，您有权限制我们处理数据：当您对个人数据的准确性有争议，并允许我们在一定的期限内核实其准确性时；当数据处理是非法的，且您反对删除个人数据而是要求限制使用该个人数据时；当我们不再需要个人数据以实现处理目的，但您为了提起、行使或抗辩法律诉求而需要该个人数据时；当您基于反对权需要核实我们的法律依据是否优先于您的法律依据时。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("5.")]),
        _c("div", [
          _vm._v(
            "您有权选择退出促销订阅。您可以选择不接收我们的订阅促销信息，为此目的，您可以直接联系我们从促销邮件列表或注册数据库中中删除您的联系信息。"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }, [_vm._v("6.")]),
        _c("div", [
          _vm._v(
            "为保障安全，您可能需要以提供书面材料或其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如：需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如：涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。"
          ),
        ]),
      ]),
      _c("div", [
        _vm._v("在以下情形中，按照法律法规要求，我们将无法响应您的请求："),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t与国家安全、国防安全直接相关的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t与公共安全、公共卫生、重大公共利益直接相关的；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v("•\t与犯罪侦查、起诉、审判和判决执行等直接相关的；"),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t有充分证据表明您存在主观恶意或滥用权利的；")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "•\t响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；"
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("•\t涉及商业秘密的。")]),
      ]),
      _c("h4", [_vm._v("八、业务转让")]),
      _c("div", [
        _vm._v(
          "我们收集的信息将被视作一项资产，如果存在下列情况之一，此类信息可能成为被转让的资产之一：优联科技、任何关联公司或部门与其他企业或部门进行合并、被收购，或者进行破产、解散、重组，或者出售部分或全部优联科技的资产或股票，或者进行融资、公开发行证券，或者被收购全部或部分业务，或者进入其他类似交易或程序，或者为此类活动进行的其他程序和步骤（例如尽职调查）。"
        ),
      ]),
      _c("div", [
        _vm._v(
          "当上述交易完成后，若存在转让优联科技收集的信息的情况，我们会以邮件和/或其他方式通知您。收购方或者其他与收购方具有类似地位的权利义务承继者，只能按照此隐私策略（或者向您收集信息时任何有效的后续策略）来使用您的信息。请注意，转让后提交或收集的信息可能根据收购方的要求在法律允许的范围内进行修改，隐私政策也将据此进行更新。"
        ),
      ]),
      _c("h4", [_vm._v("九、我们对待儿童的隐私政策")]),
      _c("div", [
        _vm._v(
          "我们的服务不针对16岁以下的个人，我们也不会故意收集16岁以下儿童的个人信息。如果发现16岁以下的儿童向我们提供了个人信息，我们将采取措施删除此类信息。如果您发现存在上述儿童向我们提供个人信息的情况，请您通过我们的客户支持服务联系我们。"
        ),
      ]),
      _c("h4", [_vm._v("十、联系我们")]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "如果您对本隐私政策有任何疑问、意见或建议，请通过以下方式与我们联系："
          ),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("邮箱：Contact@ourutec.com")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("电话：010 - 8057 0224")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [_vm._v("一般情况下，我们将在三十天内回复。")]),
      ]),
      _c("div", [
        _c("div", { staticClass: "num" }),
        _c("div", [
          _vm._v(
            "本隐私政策的订立、履行和解释均适用中国法律。如果您对我们的回复不满意，特别是当我们的个人信息处理行为损害了您的合法权益，您可以至北京，向优联科技注册地所在的人民法院申请仲裁的方式寻求解决方案。当您使用我们的产品和服务，即意味着您已经同意本隐私政策所示之法律管辖及争议解决方式的有关约定。"
          ),
        ]),
      ]),
      _c("h4", [_vm._v("十二、隐私政策的变更")]),
      _c("div", [
        _vm._v(
          "我们可能会不定期更改此隐私政策。隐私政策的任何更改都会在此页面上发布。我们鼓励您在使用服务时了解我们的隐私政策，随时了解我们的信息能够帮助您更好地保护隐私。如果您不同意本隐私政策的任何更改，您需要停止使用服务并停用您的账户。"
        ),
      ]),
      _c("div", { staticClass: "comname" }, [
        _vm._v("北京优联信驰信息科技有限公司"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }