<!--
 * @Author       : Hugo
 * @Date         : 2021-04-01 10:29:38
 * @LastEditTime : 2022-07-18 14:44:25
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/dialog/share/unit.vue
 * @Description  :
 * @^_^          :
-->
<template>
  <div class="welcome_unit_page">
    <div class="time_group">
      {{ item.createTime | universalDate }}
    </div>
    <div class="unit_content_group cp"
      :class="[unit_type]"
    >
      <!-- <img src="~@/assets/logo.png" alt="" class="avatar"> -->
      <div class="avatar cp"
        v-show="unit_type==='left'"
      >
        <AvatarDetail :msg_data="item"></AvatarDetail>
      </div>
      <div class="container_wrapper">
        <div class="user_name"
          v-if="unit_type==='left'"
        >{{item.userName}}</div>
        <div
          v-if="is_welcome && 0"
          class="container un_sel"
        >
          <div class="info_group">
            <div class="title">{{item.taskName}}</div>
            <div class="description" v-text="item.work ? item.work : '感谢您使用妙行系统！希望妙行系统可以帮助您把实践任务教学和管理功能合并，以沟通的方式实现用户之间的协同交互。'"></div>
            <button class="btn button cp" @click="doOpen();">查看详情</button>
          </div>
          <img
            src="~@/assets/images/dialog/welcome_placeholder_img.png"
            alt=""
            class="img"
            v-if="!item.shareUrl"
          >
          <img
            :src="`${$img_url}${item.shareUrl}`"
            alt=""
            class="img"
            v-if="item.shareUrl"
          >
        </div>
        <div
          v-if="is_welcome"
          class="container un_sel"
        >
          <div class="info_group">
            <div class="title">{{item.taskName}}</div>
            <div class="description" v-text="item.work ? item.work : '感谢您使用妙行系统！希望妙行系统可以帮助您把实践任务教学和管理功能合并，以沟通的方式实现用户之间的协同交互。'"></div>
            <button
              class="btn button cp"
              @click="handleCheck()"
            >
              {{ checkButtonText }}
            </button>
          </div>
          <img
            src="~@/assets/images/dialog/welcome_placeholder_img.png"
            alt=""
            class="img"
            v-if="!item.shareUrl"
          >
          <img
            :src="`${$img_url}${item.shareUrl}`"
            alt=""
            class="img"
            v-if="item.shareUrl"
          >
        </div>
        <div class="container share un_sel"
          v-else
          :class="{right : unit_type === 'right'}"
          @click="doOpen();"
        >
          <div class="descript_group justify-space-between">
            <div class="content_group">
              <div class="name" v-if="item.taskName">{{item.taskName}}</div>
              <div class="work">{{item.work}}</div>
            </div>
            <img
              v-if="is_share_package || shareCourse"
              src="~@/assets/images/package/share_icon.png" alt="" class="icon">
            <img
              v-if="is_share_task"
              src="~@/assets/images/task/share_icon.png" alt="" class="icon">
            <img
              v-if="is_share_folder"
              src="~@/assets/images/collect/share_icon.png" alt="" class="icon">
          </div>
          <div class="hint"
            v-if="is_share_package"
          >课程分享</div>
          <div class="hint"
            v-if="is_share_task"
          >任务分享</div>
          <div class="hint"
            v-if="is_share_folder"
          >收藏包分享</div>
          <div
            v-if="shareCourse"
            class="hint"
          >课程分享</div>

        </div>
      </div>
      <div class="avatar cp"
        v-show="unit_type === 'right'"
      >
        <AvatarDetail :msg_data="item"></AvatarDetail>
      </div>
    </div>

  </div>
</template>
<script>
import AvatarDetail from '@/components/basic/avatarDetail.vue';
import CoursePackage from '@/models/cource_package'
import store from '@/store'
export default {
  components:{
    AvatarDetail,
  },
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  computed: {
    // 点击按钮文本
    checkButtonText() {
      const { item } = this
      const { logType } = item
      if (logType === 1) {
        return '查看详情'
      }
      if (logType === 5) {
        return '前往查看'
      }
      return '查看详情'
    },
    // 左右
    unit_type(){
      let type = 'left';
      const { item } = this
      const userid = this.$tools.getUserId()
      const { fromUserId } = item
      if (Number(fromUserId) === Number(userid)) {
      // if(fromUserId === userid){
        type = 'right'
      }
      return type
    },
    // 欢迎消息
    is_welcome(){
      const {item} = this;
      const {fromUserId} = item;
      return fromUserId === this.$cm_id;

    },
    is_share_task(){
      const {is_welcome, item} = this
      const {shareType} = item
      if(is_welcome){
        return false
      }
      return shareType === 1;

    },
    is_share_package(){
      const {is_welcome, item} = this
      const {shareType} = item
      if(is_welcome){
        return false
      }
      return shareType === 2

    },
    is_share_folder(){
      const {is_welcome, item} = this
      const {shareType} = item
      if(is_welcome){
        return false
      }
      return shareType === 4

    },
    shareCourse () {
      const { item, is_welcome } = this
      if(is_welcome){
        return false
      }
      const {shareType} = item
      return shareType === 5

    }
  },
  methods: {
    handleCheck() {
      const { item } = this
      const { logType } = item
      if (logType === 1) {
        this.doOpen()
      }
      if (logType === 5) {
        this.$router.push({ name: 'personal_center_gift_card', query: { type: 2} })
      }
    },
    /**
     * @description: 点击打开
     * @return {*}
     */
    async doOpen(){
      const { item } = this
      const { shareType, taskId } = item
      /**
       *  logType: 0模板 1分享(这里的logType都为1)
       *  shareType: 分享类型 1模板 2教学包 3h5
       */
      if (shareType === 1) {
        // this.openTaskBox();
        this.$api.moduleTask.openTask({
          taskId: this.item.taskId,
          config: {
            view: true,
            system: this.is_welcome,
            share: true,
          }
        })
      }
      if (shareType === 2) {
        this.openTeachingPackage(taskId);
      }
      if (shareType === 4) {
        console.log(this.item)
        this.item.folderPreview()
      }
      if (shareType === 5) {
        const coursePackage = new CoursePackage({
          id: item.taskId
        })
        store.commit(
          'setCoursePackageData',
          coursePackage
        )
        this.$modal.show('globalViewCrousePackage')
      }
    },
    /**
     * @description: 打开消息盒子
     * @return {*}
     */
    async openTaskBox(){
      if(this.$api.isDeleteTask(this.item)){
        return false;
      }
      // console.log(params);
      // this.$api.task.openShareTask({taskId: this.item.taskId});
      this.$api.moduleTask.openTask({taskId: this.item.taskId, config: {
        // view: true,
        // teach: true,
      }})
    },
    /**
     * @description: 打开分享教学包
     * @param {*} id
     * @return {*}
     */
    openTeachingPackage(id){
      const {commit } = this.$store;
      commit('set_switch_teaching_package', true);
      commit('set_teaching_package_id', id);
    },

  }
}
</script>

<style lang="scss" scoped>
.welcome_unit_page{
  // @include flex;
  // align-items: flex-start;
  padding-bottom: 22px;
  width: 100%;

  .time_group{
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #999;
    padding-bottom: 22px;
  }
  .unit_content_group{
    @include flex;
    align-items: flex-start;
    &.left{
      justify-content: flex-start;
    }
    &.right{
      justify-content: flex-end;
    }
  }
  .container_wrapper{
    @include flex;
    flex-direction: column;
    align-items: flex-start;
    .user_name{
      padding-left: 15px;
      font-size: 12px;
      color: #999;
      padding-bottom: 7px;
    }
  }
  .container{
    @include flex;
    align-items: stretch;
    background-color: #fff;
    // padding: 30px;
    padding: 20px;
    // border-top-right-radius: 4px;
    border-radius: 4px;
    max-width: 700px;
    margin: 0 15px;
    width: 350px;
    .img{
      flex-shrink: 0;
      margin-left: 10px;
      max-width: 250px;
      align-self: center;
    }
    &.right{
      // background-color: #DCE6F7;
      background-color: $main_blue;
      .name{
        color: #fff!important;
      }
      .hint, .work{
        color: #ddd!important;
      }
    }
  }
  .info_group{
    @include flexsb;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    // flex: 1;
    .title{
      // font-size: 22px;
      font-size: 16px;
      color: #002141;
      // padding-bottom: 20px;
      padding-bottom: 10px;
    }
    .description{
      // font-size: 18px;
      font-size: 14px;
      // line-height: 26px;
      line-height: 20px;
      color: #82929C;
      // padding-bottom: 25px;
      padding-bottom: 15px;
      height: 100%;
      flex: 1;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .btn{
      @include bbox;
      display: block;
      border: 1px solid #0054A7;
      border-radius: 4px;
      line-height: 40px;
      font-size: 16px;
      width: 100%;
      letter-spacing: 2px;
      text-align: center;
      padding-left: 2px;
      color: $main_blue;
      min-width: 200px;
    }
  }
  .container.share{
    flex-direction: column;
    .hint{
      border-top: 1px solid #D5D9DB;
      font-size: 12px;
      color: #82929C;
      padding-top: 12px;
    }
  }
  .descript_group{
    @include flex;
    align-items: flex-start;
    .content_group{
      padding-right: 26px;
      .name{
        @include textjustify;
        font-size: 14px;
        color:#333;
        padding-bottom: 12px;
      }
      .work{
        font-size: 12px;
        color: #666;
        line-height: 20px;
      }
    }
    .icon{
      margin-bottom: 12px;
    }

  }
  .avatar{
    width: 44px;
    height: 44px;
    display: block;
    border-radius: 4px;
    background-color: $img_placeholder_color;
    flex-shrink: 0;
  }
}
</style>
