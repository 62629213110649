import vue from '@/main.js';
class Tag{
  async getList(){
    const res = await vue.$global.doPost({
      url: 'projectTask/myTags',
      data: {keyword: ''}
    })
    return res;
  }
}

const tag = new Tag();

export default tag;