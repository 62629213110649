var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_collect_select_route_list bbox" }, [
    _c(
      "div",
      { ref: "list", staticClass: "list_group" },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            _c(
              "div",
              {
                key: index,
                staticClass: "item d-flex align-start bbox cp",
                class: { active: item.cf && item.cf.selected },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchFolder(item)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img flex-shrink-0",
                  attrs: {
                    src: require(`@/assets/images/custom/folder.png`),
                    alt: "",
                  },
                }),
                _c("div", {
                  staticClass: "text",
                  domProps: { textContent: _vm._s(item.name) },
                }),
                _c("div", {
                  staticClass: "next flex-shrink-0",
                  class: {
                    has_child: item.sonfolderList && item.sonfolderList.length,
                  },
                }),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }