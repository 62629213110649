var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_recommend_modules_task_gift_bag bbox" }, [
    _c("div", { staticClass: "title" }, [_vm._v("新用户模板礼包")]),
    _c("div", { staticClass: "subtitle" }, [
      _vm._v(
        "为了让您快速适应妙行的使用，我们为您准备了一份模板礼包，使用礼包中的模板，可以让您更方便、快捷的进行专业的工作沟通，获取后，您可以在“模板频道-我收藏的”中查看并使用。"
      ),
    ]),
    _c(
      "div",
      { staticClass: "list_group d-flex align-center flex-wrap" },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            item.gifttype === 1
              ? _c("thumb-task", {
                  key: index,
                  staticClass: "thumb_task",
                  attrs: {
                    p_task: item.task,
                    show_from: false,
                    show_time: false,
                    show_mini_tag: false,
                    type: "shopping-mail",
                  },
                  on: { handleClick: _vm.handleClick },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "operation_group d-flex justify-end" }, [
      _c(
        "div",
        {
          staticClass: "button btn_dark",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doGet()
            },
          },
        },
        [_vm._v("好的")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }