<!--
 * @Author       : Hugo
 * @Date         : 2022-04-20 10:41:05
 * @LastEditTime : 2022-04-22 14:30:35
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Recommend/Modules/TaskGiftBag/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_recommend_modules_task_gift_bag bbox"
  >
    <div class="title">新用户模板礼包</div>
    <div class="subtitle">为了让您快速适应妙行的使用，我们为您准备了一份模板礼包，使用礼包中的模板，可以让您更方便、快捷的进行专业的工作沟通，获取后，您可以在“模板频道-我收藏的”中查看并使用。</div>

    <div class="list_group d-flex align-center flex-wrap">
      <template
        v-for="(item, index) in list"
      >
        <thumb-task
          v-if="item.gifttype === 1"
          :key="index"
          :p_task="item.task"
          :show_from="false"
          :show_time="false"
          :show_mini_tag="false"
          type="shopping-mail"
          class="thumb_task"
          @handleClick="handleClick"
        ></thumb-task>
      </template>
    </div>
    <div class="operation_group d-flex justify-end">
      <div class="button btn_dark"
        @click.stop="doGet()"
      >好的</div>
    </div>
  </div>
</template>

<script>
import {PTask, listForClasses, PGift} from '@/types'
import ThumbTask from '@/components/Common/Modules/Thumb/Task.vue'
import global from '@/api/global'
import tools from '@/api/tools'

export default {
  components: {
    ThumbTask
  },
  data(){
    return{
      list: [],
      loading: false,
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    async init(){
      this.getList()
    },
    async getList(){
      const res = await global.doPost({
        url: '/projectCustom/queryGift',
      })
      if(res.message === 'success'){
        this.$set(this, 'list', Object.assign(
          {},
          this.list,
          listForClasses({
            list: res.contents.list,
            classes: PGift
          })
        ))
      }
    },
    async doGet(){
      // if(this.loading){
      //   return
      // }
      // this.loading = true
      this.$emit('handleSure')
     
    },
    handleClick(item){
      this.$api.moduleTask.openTask({taskId: item.id, config: {
        view: true,
        share: true,
        hide_fun: true,
        hide_tag: true,
      }});
    }
  }
}
</script>

<style lang="scss" scoped>
.c_recommend_modules_task_gift_bag{
  padding: 40px 50px;
  .title{
    color: #001224;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 16px;
  }
  .subtitle{
    font-size: 16px;
    color: #445566;
    line-height: 28px;
    padding-bottom: 10px;
  }
  .list_group{
    .thumb_task{
      margin: {
        top: 20px;
        right: 20px;
        bottom: 20px;
      }
    }
  }
  .button{
    line-height: 40px;
    padding: 0 40px;
    margin-top: 20px;
    border-radius: 4px;
    font-size: 14px;
  }
}
</style>
