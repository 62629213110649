var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectList
    ? _c(
        "div",
        {
          staticClass:
            "c_pop_up_batch_add_contacts_to_tasks_select_task_task_list bbox",
        },
        [
          _c(
            "div",
            { staticClass: "list_group d-flex flex-wrap bbox" },
            [
              _vm._l(_vm.selectList, function (item, index) {
                return [
                  _c("thumbnail-task", {
                    key: index,
                    attrs: {
                      "p-task": item,
                      "user-info-simple-pmcs": _vm.userInfoSimplePmcs,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "more_group d-flex align-center justify-center" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMore && !_vm.showLoading,
                      expression: "showMore && !showLoading",
                    },
                  ],
                  staticClass: "more cp",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.loadmore()
                    },
                  },
                },
                [_vm._v("\n      加载更多\n    ")]
              ),
              _c("loading-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showLoading,
                    expression: "showLoading",
                  },
                ],
                attrs: { size: "26" },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }