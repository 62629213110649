/*
 * @Author       : Hugo
 * @Date         : 2021-03-24 15:09:00
 * @LastEditTime : 2021-08-17 15:17:53
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/task_api.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main.js';
import tools from '@/api/tools';
const task_api = {
  /**
     * @description: 关闭时移除心跳key
     * @param {*}
     * @return {*}
     */
  removeHeartbeat: async (id) => {
    let res;
    try{
      res = await vue.$http.request('/projectNews/removetaskMessageheartbeat', {taskId: id});
    }catch(err){
      // console.log(err);
    }
  },
  /**
   * 获取路由id
   */
  getRouteParamsId(){
    const {params} = vue.$route;
    const {id} = params;
    return vue.$global.decodeBase64(id);
    let atob_id;
    try{
      atob_id = tools.atob(id);
    }catch(err){
      // console.log(err);
      // vue.$router.push({path: '/'});
      return;
    }
    
    let reg_res;
    try{
      reg_res = vue.$regexp.positive_integer.test(atob_id); // 验证是否正整数
      if(reg_res){
        return atob_id;
      }
    }catch(err){
      // console.log(err);
    }

    // 跳转到首页
    // vue.$router.push({path: '/'});

    
    return false;
  },
  /**
   * 打开分享任务单
   * @param {*} {taskId: 任务id}
   */
  openShareTask({taskId}){
    vue.$store.dispatch('openTaskBox', {taskId, seeType: 1});
  },
  /**
   * 预览任务
   * @param {*} {taskId: 任务ID} 
   */
  previewTask({taskId}){
    vue.$store.dispatch('openTaskBox', {taskId, preview:true});
  },
  /**
   * 打开任务
   * @param {*} {taskId: 任务ID} 
   */
  openTask({taskId}){
    vue.$store.dispatch('openTaskBox', {taskId});
  },
  /**
   * 调用任务
   * @param {*} {taskId: 任务id, reelect: 是否选择发送对象} 
   */
  callTask({taskId, reelect}){
    const data = {taskId, status: 'clear', reelect }
    vue.$store.commit('set_task_need_relevancy', true);
    vue.$store.dispatch('openTaskBox', data); 
  },

  /**
   * @description: 消息盒子提升(滚动效果需要task.vue添加)
   * @param {*} params 
   */
  messageBoxUp (params) {
    let { task_data } = vue.$store.state
    // let {istop} = message_box_unit || {};
    let {
      chatId,
      chatType,
      messageContent,
      updateusername,
      photo,
      name
    } = params
    // // console.log(messageContent)
    let message_box_list = vue.$tools.deepClone(vue.$store.state.message_box_list)
    if (!message_box_list) {
      return
    }
    // 根据传入的chatID和chatType来获取index
    let index = message_box_list.findIndex((item) => {
      return item.chatId == chatId && item.chatType == chatType
    })
    // console.log(index)
    let unit = {
      messageContent,
      updateusername,
      lastSendTime: new Date().getTime(),
      photo,
      chatId,
      chatType
    }
    let istop
    if (index != -1) {
      unit = message_box_list[index]
      istop = unit.istop
      unit.messageContent = messageContent
      unit.updateusername = updateusername
      unit.lastSendTime = new Date().getTime()
    } else {
      // 新建
      unit.name = name
      istop = 0
    }
    if (istop == 1) {
      // 置顶
      message_box_list.splice(index, 1)
      message_box_list.unshift(unit)
    } else {
      // 取消置顶
      message_box_list.splice(index, 1) // 移除
      // 要移动到的目标索引
      let aim_index = message_box_list.findIndex((item) => {
        return item.lastSendTime <= unit.lastSendTime && item.istop == 0;
      })
      // if(aim_index != -1){
      message_box_list.splice(aim_index || 0, 0, unit)
      // }
    }
    vue.$store.commit('set_message_box_list', message_box_list)
  },
  /**
   * 是否当前聊天
   * @param {*} params 
   */
  isCurrentChat(params){
    const {chat_id, chat_type} = params;
    let get_id = vue.$api.moduleTask.getRouteParamsId();
    let get_type = vue.$tools.getChatType();
    if(chat_id == get_id && chat_type == get_type){
      return true;
    }
    return false;
  },
  /**
   * @description: 追加消息列表
   * @param {Object} taskLog
   */
  addToMessageList(taskLog){
    const {chatId,createTime,deleteFlag,details,fromUserId,id,linkme,logType,shareType,shareUrl,taskId,taskName,taskType,thumbnail,toUserId,type,userName,work} = taskLog || {};

    /**
     * chatId: 132, createTime: 1618972090000, deleteFlag: 0, details: [], fromUserId: 4360, id: 23326, linkme: true, logType: 1, shareType: 1, shareUrl: "pmcs/2021/03/30/1617073535568361700.png", taskId: 4234, taskName: "测试新建", taskType: 1, thumbnail: "2020/11/10/1604977511102349951.jpg", toUserId: null, type: 1, userName: "Touber", work: "6erf",
     */
    const {state, commit } = vue.$store;
    // let {task_data} = state;
    // let route = vue.$route;
    // let getRouteParamsId = vue.$api.moduleTask.getRouteParamsId();
    // // 不是当前聊天不更新
    // if(getRouteParamsId != task_data.chatId || vue.$tools.getChatType() != task_data.chatType){
    //   return;
    // }

    let message_list_unit = taskLog;
    let message_list = vue.$tools.deepClone(state.message_list);
    message_list_unit.createTime = Number(message_list_unit.createTime);
    if(message_list){
      message_list.push(message_list_unit);
      commit('set_message_list', message_list);
      vue.$api.task.updateMessageListOnPush(message_list_unit);
      vue.$nextTick(function(){
        // 触发滚动到顶部
        commit('set_task_trigger_scroll_to_bottom', true);
        // // console.log('chufa')
      })
    }
  },

  /**
   * 发送消息后更新消息列表[更新taskType 和 linkme ...]
   * @params {Object} msg 推送来的消息
   */
  async updateMessageListOnPush(ext){
    let {taskId, taskType, linkme} = ext;
    let {message_list} = vue.$store.state;
    if(message_list && message_list.length){
      message_list.forEach( (item, index) => {
        if(item.taskId == taskId){
          item.taskType = taskType;
          item.linkme = linkme
        }
      })
      vue.$store.commit('set_message_list', message_list)
    }
    // unreadToZero(ext); //未读消息置空
  },
  /**
   * 关闭任务盒子
   * @returns 
   */
  closeTaskBox(){
    return;
  },
  /**
   * 任务是否被修改
   * @returns 
   */
  taskBeModified(){
    return;
  },
  /**
   * 更新隐私模式数据
   * @param {Object} params {task_id: id, privacy: hide}
   * @returns 
   */
  updatePrivacyData(params){
    const {task_id, privacy} = params;
    const {message_list, my_task_list} = vue.$store.state;
    if(message_list && message_list.length){
      message_list.forEach(item=>{
        if(item.taskId == task_id){
          vue.$set(item, 'hide', privacy);
        }
      })
    }
    if(my_task_list && my_task_list.length){
      my_task_list.forEach(item=> {
        if(item.id == task_id){
          vue.$set(item, 'hide', privacy);
        }
      })
    }
    return;
  }

}

export default task_api;