/*
 * @Author       : Hugo
 * @Date         : 2021-04-21 16:56:50
 * @LastEditTime : 2021-04-25 09:55:22
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/package.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main.js';

const PACKAGE = {
  /**
   * 关闭教学包编辑界面
   * @returns 
   */
  closeEditBox(){
    // 判断教学包编辑界面是否打开
    if(vue.$api.package.beModified()){
      //被编辑过
      vue.$api.package.closeConfirm();
      return;
    }
    vue.$api.package.doClose();
    // 关闭
    return;
  },
  doClose(){
    vue.$store.commit('set_' +
      'switch_package_box', false)
  },
  /**
   * 关闭教学包确认框[被编辑过]
   */
  closeConfirm(){
    vue.$openConfirm({
      title: '是否关闭?',
      desc: '关闭不会保存已编辑过的内容',
      sure:'确定',
      cancel:'取消',
      onSure:()=>{
        vue.$api.package.doClose();
      },
      onCancel:()=>{},
    })
  },
  /**
   * 教学包是否被修改过
   * @returns 
   */
  beModified(){
    // 判断是否打开了教学包编辑
    const {commit, state} = vue.$store;
    const {switch_package_box, package_modified} = state;
    if(!switch_package_box){
      return false;
    }
    
    
    return package_modified;
  },
  /**
   * 编辑教学包标记
   */
  doModified(){
    const {commit} = vue.$store;
    // console.log('mod')
    commit('set_package_modified', true);
  }
}

export default PACKAGE;