var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_p_send_out_details_main bbox d-flex flex-column align-stretch justify-start",
    },
    [
      _c(
        "div",
        { staticClass: "time_group bbox d-flex justify-space-between" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v("卡号：" + _vm._s(_vm.pcarduser.cardno)),
          ]),
          _c("div", { staticClass: "channel", class: [_vm.channel.type] }, [
            _vm._v(_vm._s(_vm.channel.name)),
          ]),
        ]
      ),
      _c("card-component", {
        staticClass: "card_component cp mb-10",
        attrs: {
          pcard: _vm.pcarduser.card,
          pcarduser: _vm.pcarduser,
          width: "420px",
          height: "244px",
          title_size: "28px",
        },
      }),
      _c("div", { staticClass: "detail_group d-flex align-center" }, [
        _c("div", { staticClass: "name" }, [_vm._v("获取日期")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(
            _vm._s(
              _vm
                .$moment(_vm.pcarduser.createTime)
                .format("YYYY-MM-DD hh:mm:ss")
            )
          ),
        ]),
      ]),
      _c("div", { staticClass: "detail_group d-flex align-center" }, [
        _c("div", { staticClass: "name" }, [_vm._v("领取姓名")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm.pcarduser.useUserName)),
        ]),
      ]),
      _c("div", { staticClass: "detail_group d-flex align-center" }, [
        _c("div", { staticClass: "name" }, [_vm._v("领取号码")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm.pcarduser.usePhone)),
        ]),
      ]),
      _c("div", { staticClass: "detail_group d-flex align-center" }, [
        _c("div", { staticClass: "name" }, [_vm._v("领取时间")]),
        _c("div", { staticClass: "value" }, [
          _vm._v(
            _vm._s(
              _vm.$moment(_vm.pcarduser.useTime).format("YYYY-MM-DD HH:mm:ss")
            )
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }