<template>
  <div
    class="components_task_main_widget_container_page bbox d-flex flex-column bbox"
    v-if="1"
  >
    <template
      v-if="!list || (list && list.length == 0) "
    >
      <slot></slot>
    </template>
    <!-- un_drag不可拖拽 不可拖拽导致无法点击获取焦点 -->
    <!-- :group="{name:`deep_level`, pull: 是否可以拖出,}" -->
    <draggable
      v-if="list"
      class="draggable_wrapper"
      :class="{position:1}"
      :list="list"
      :group="{name:`widget`, pull: false}"
      handle=".components_task_main_widget_title_page .icon"
      animation="300"
      @change="changeLog"
      :style="{minHeight: `${minHeight}px`}"
    >
      <template
        v-for="(item) in list"
      >
        <widget-wrapper
          :key="item.id ? `id_${item.id}` : `create_num_${item.create_num}`"
          class="widget_wrapper"
          ref="widget"
          :large_task="large_task"
          :data="data"
          :backup="backup"
          :p_task_detail="item"
          :parent="parent"
        ></widget-wrapper>
      </template>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
let num = 0;
// import WidgetWrapper from './Widget/Wrapper.vue';
export default {
  name: 'WidgetContainer',
  components:{
    draggable,
    // WidgetWrapper,
    WidgetWrapper: () => import('./Widget/Wrapper.vue')
  },
  data(){
    return{
      // scrollto 配置
      options: {
        container: '#control_section', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true
      },
      init_status: false, // init函数是否正式执行
      scrollToWidget_status: false,
      timeout: null,
    }
  },
  props: {
    large_task: {
      type: Object,
      default: undefined,
    },
    // data: {
    //   type: Object,
    //   default: undefined,
    // },
    // backup: {
    //   type: Object,
    //   default: undefined,
    // },
    parent: {
      type: Object,
      default: undefined,
    },
    list: {
      type: Array,
      default: undefined,
    },
    minHeight: {
      type: Number,
      default: 20
    },
  },
  computed: {
    data(){
      const {large_task} = this
      const {data} = large_task
      return data
    },
    backup(){
      const {large_task} = this
      const {backup} = large_task
      return backup
    },
  },
  methods: {
    /**
     * @description: 控件列表数据变化钩子
     * @param {*} evt
     * @return {*}
     */
    changeLog: function(evt) {
      console.log(evt)
      // if(evt.moved){
      //   evt.moved.element.moved = true; // 标记有移动的控件
      // }
      // if(evt.removed){
      //   evt.removed.element.moved = true; // 标记有移动的控件
      // }

      // this.$redrawVueMasonry("masonry")
      const {parent} = this;
      // if(parent){
      //   this.$set(parent, 'moved', true);
      //   this.$set(parent, 'modified', true);
      // }else{
      //   this.$store.commit('set_task_config', Object.assign(
      //     {},
      //     this.$store.state.task_config,
      //     {moved: true}
      //   ))
      // }

    },
    addNewWidget(){
      this.scrollToBottom();
    },
     /**
     * @description: 滚动到底部
     */
    scrollToBottom(){
      // 获取最后一个元素
      if(this.$refs.widget && this.$refs.widget.length != 0){
        let element = this.$refs.widget[this.$refs.widget.length - 1];
        if(element){
          this.$scrollTo(element.$el,200, this.options);
        }
      }
    },
    async repealWidget(data){
      const {item, parent} = data;
      const {task_original} = this.$store.state;
      const {taskDeatails} = task_original;
      const {list} = this;
      if(parent == null){
        const obj = taskDeatails.find(unit=>{
          return unit.id == item.id && unit.id;
        })
        const index = list.findIndex(unit=>{
          return unit.id === obj.id;
        })
        this.$set(list, index, Object.assign(
          {},
          this.$tools.deepClone(obj),
          {repeal_title: true, repeal_content: true, islock: 0, userlock: '', }
        ));
      }else{
        const parent_obj = taskDeatails.find(unit=>{
          return unit.id == parent.id && unit.id;
        })
        const obj = parent_obj.controlLinkList.find(unit=>{
          return unit.id == item.id && unit.id;
        })
        const index = parent.controlLinkList.findIndex(unit=>{
          return unit.id == obj.id;
        })
        this.$set(parent.controlLinkList, index, Object.assign(
          {},
          this.$tools.deepClone(obj),
          {repeal_title: true, repeal_content: true, islock: 0, userlock: '', }
        ))
      }
      await this.$api.moduleTask.removeLock(item);
    },
    deleteWidget(data){
      this.$emit('handleDeleteWidget', data);
    },
    deleteRelevancy(data){
      this.$emit('handleDeleteRelevancy', data);
    },
    switchSidebar(){
      this.$refs.wrapper.switchSidebar();
    },
    modifiedSign(params){
      this.$emit('handleMofifiedSign', params);
    },
    init(){
      // // console.log(num++)

      this.$showLoading({text:'加载中'});
      const {list, relevancy, init_status} = this;
      if(init_status){
        this.$hideLoading();
        return;
      }
      if(list && list.length && !this.$refs.widget){
        // // console.log('this.$refs.widget 不存在 则200ms检测执行一次')
        // this.$refs.widget 不存在 则200ms检测执行一次
        setTimeout(()=>{
          this.init();
        },50);
        return;
      }
      // // console.log(relevancy, 'revelancy')
      if(relevancy){
        this.init_status = true;
        this.$hideLoading();
        return;
      }
      const {task_config} = this.$store.state;
      const {appoint_widget} = task_config;
      // // console.log(appoint_widget)
      if(appoint_widget){
        const {parent_id, widget_id} = appoint_widget;
        const index = list.findIndex(item=>{
          if(parent_id){
            return item.id == parent_id;
          }
          return item.id == widget_id;
        })
        // console.log(index, parent_id);
        if(index == -1){
          // 控件已删除 直接打开模板 不再滚动
          this.init_status = true;
          this.$hideLoading();
          return;
        }
        if(parent_id){
          this.$set(list[index], 'switch_relevancy', true); //将关联打开
          this.$nextTick(function(){
            this.$refs.widget[index].scrollToWidget(widget_id);
          })
        }else{
          // 跳转到一级控件
          if(this.$refs.widget && this.$refs.widget[index] && this.$refs.widget[index].$el){

            this.$scrollTo( this.$refs.widget[index].$el,20, this.options);
            clearTimeout(this.timeout);
            this.timeout =  setTimeout(()=>{
              const offsetParent = this.$refs.widget[index].$el.offsetParent;
              const parent_scrollTop = offsetParent.scrollTop; // 距离顶部高度
              const parent_scrollHeight = offsetParent.scrollHeight; // 滚动总高度
              const parent_offsetHeight = offsetParent.offsetHeight; // 自身高度
              const offsetTop = this.$refs.widget[index].$el.offsetTop;
              // // console.log(offsetParent);
              // // console.log(parent_scrollTop - offsetTop, "parent_scrollTop - offsetTop")
              // // console.log(parent_offsetHeight + parent_scrollTop, 'parent_offsetHeight + parent_scrollTop');
              // // console.log(parent_scrollHeight, 'parent_scrollHeight');

              // 目标控件offsetTop和父控件scrollTop 相差小于50 || 已经滚动到底部(距离底部100)
              if((Math.abs(parent_scrollTop - offsetTop) < 50) || (Math.abs(parent_scrollHeight - (parent_offsetHeight + parent_scrollTop)) < 100)){
                this.init_status = true; // 执行完毕标记
                this.$hideLoading();
              }else{
                this.init();
              }
            }, 50)
          }else{

            this.init();
          }
        }
        return;
      }
      this.$hideLoading();

    },
    scrollToWidget(widget_id){
      // console.log(widget_id)
      this.$showLoading({text:'加载中'});
      const {list, scrollToWidget_status} = this;
      if(scrollToWidget_status){
        this.$hideLoading();
        return;
      }
      const index = list.findIndex(item=>{
        return item.id == widget_id;
      })

      if(index != -1){
        if(this.$refs.widget[index] && this.$refs.widget[index].$el){

          this.$scrollTo( this.$refs.widget[index].$el,20, this.options);
          clearTimeout(this.timeout);
          this.timeout =  setTimeout(()=>{
            const offsetParent = this.$refs.widget[index].$el.offsetParent;
            const parent_scrollTop = offsetParent.scrollTop; // 距离顶部高度
            const parent_scrollHeight = offsetParent.scrollHeight; // 滚动总高度
            const parent_offsetHeight = offsetParent.offsetHeight; // 自身高度
            const offsetTop = this.$refs.widget[index].$el.offsetTop;
            // 目标控件offsetTop和父控件scrollTop 相差小于50 || 已经滚动到底部
            if((Math.abs(parent_scrollTop - offsetTop) < 100) || (Math.abs(parent_scrollHeight - (parent_offsetHeight + parent_scrollTop))< 50)){
              this.scrollToWidget_status = true; // 执行完毕标记
              this.$hideLoading();
            }else{
              this.scrollToWidget(widget_id);
            }
          }, 50)
        }else{
          this.scrollToWidget(widget_id);
        }
      }
    },
    setZIndex(zindex){
      this.$emit('setZIndex', zindex);
    },
    handleSortFile(params){
      this.$emit('handleSortFile', params);
    },
  },
  mounted(){
    console.log(this.list)
    this.$nextTick(function(){
      // this.init();
    })
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_widget_container_page{
  // background-color:red;
  width: 100%;
  height: 100%;
  // padding: 10px 20px;
  // padding: 10px 0;
  overflow-y: auto;
  .draggable_wrapper{
    @include scrollbar;
    overflow-y: auto;
    width: 100%;
    max-height: 100%;
    flex: 1;
    &.position{
      position: relative;
    }
  }
  .widget_wrapper{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }

}
</style>
