var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c("div", { staticClass: "reminder_notice_plugin" }, [
          _c(
            "div",
            { staticClass: "list_group" },
            [
              _vm._l(_vm.list, function (data, index) {
                return [
                  index < _vm.max
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "main bbox d-flex flex-column justify-space-between align-start",
                          style: {
                            "z-index": data.zindex,
                            top: `${30 + index * 5}px`,
                            right: `${30 - index * 5}px`,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "info_group d-flex flex-column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "user_group d-flex align-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: `${_vm.$avatar_url}${data.userPhoto}@!small200`,
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "from" }, [
                                    _vm._v(
                                      "来自 " +
                                        _vm._s(data.userName) +
                                        " 的提醒:"
                                    ),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "content" }, [
                                _vm._v(_vm._s(data.content)),
                              ]),
                              _c("div", { staticClass: "task_group" }, [
                                _vm._v("任务：" + _vm._s(data.taskName)),
                              ]),
                              _c("div", { staticClass: "widget_group" }, [
                                _vm._v(
                                  "控件：" +
                                    _vm._s(data.conName || "未命名控件")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "operating_group d-flex align-center justify-end un_sel",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "button ignore",
                                  on: {
                                    click: function ($event) {
                                      return _vm.readed({ data })
                                    },
                                  },
                                },
                                [_vm._v("我知道了")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "button leave_for",
                                  on: {
                                    click: function ($event) {
                                      return _vm.check(data)
                                    },
                                  },
                                },
                                [_vm._v("前往查看")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }