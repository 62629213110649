var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_file_index bbox d-flex flex-column" },
    [
      _c("div", { staticClass: "title_group flex-shrink-0 bbox" }, [
        _vm._v("任务"),
      ]),
      _c("div", { staticClass: "list_wrapper bbox" }, [
        _c(
          "div",
          {
            staticClass:
              "list_group bbox d-flex flex-wrap align-start justify-start",
          },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                !_vm.onlyTeach || (_vm.onlyTeach && item.task.taskType === 2)
                  ? _c("file-unit", {
                      key: index,
                      staticClass: "mr-10 mb-5",
                      attrs: {
                        p_task: item.task,
                        switch_select: _vm.switch_select,
                        preview: _vm.preview,
                        show_menu: _vm.show_menu,
                        call: _vm.call,
                        current_folder: _vm.current_folder,
                        disable_select: _vm.disable_select,
                        "select-list": _vm.selectList,
                      },
                      on: {
                        handleRemoveCollect: _vm.handleRemoveCollect,
                        handleStartSelect: _vm.handleStartSelect,
                        handleRemove: _vm.handleRemove,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectTask(item.task)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "loading_group un_sel d-flex justify-center" },
          [
            !_vm.nomore
              ? _c(
                  "div",
                  {
                    staticClass: "loading cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getMore()
                      },
                    },
                  },
                  [_vm._v("加载更多")]
                )
              : _c("div", { staticClass: "nomore" }, [_vm._v("没有更多了")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }