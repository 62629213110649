<template>
  <div
    class="components_plugin_user_detail_page bbox"
    v-if="visible"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
      @click="doClose();"
    >
      <div class="container bbox d-flex flex-column"
        @click="$api.stopPropagation($event)"
      >
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0"
          v-if="0"
        >
          <div class="title">{{title}}</div>
          <div class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main">
          <contact-detail-basic
            :userData="data"
          ></contact-detail-basic>
          <div class="btn_group d-flex align-center justify-center">
            <div
              class="btn btn_dark cp dialog"
              v-if="data && data.isfriend"
              @click.stop="initiateDialogue();"
            >
              发起对话
            </div>
            <div
              class="btn cp btn_dark apply"
              v-if="data && !data.isfriend && data.userId != $tools.getUserId()"
              @click.stop="applyFriend(data);"
            >
              申请联系人
            </div>
            <div
              v-if="canBatch"
              class="btn cp btn_light"
              @click.stop="touchAddToTasks"
            >
              批量添加至任务
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </div>
</template>

<script>
import ContactDetailBasic from '@/components/Contact/ContactDetailBasic.vue';
import { UserInfoSimplePmcs } from '@/types'
import tools from '@/api/tools'
export default {
  components: {
    ContactDetailBasic,
  },
  data(){
    return {
      index: 1,
      title: '',
      visible: false,
      userId: '',
      data: null,
      canBatch: false
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.index = this.$utils.getZIndex()
        this.getCanBatch()
      }
    },
  },
  methods: {
    getCanBatch() {
      const chatType = tools.getChatType()
      console.log(chatType)
      if (Number(chatType) === 1) {
        this.canBatch = true
        return
      }
      this.canBatch = false
    },
    touchAddToTasks() {
      this.doClose()
      this.$openBatchAddContactsToTasksSelectTask({
        userInfoSimplePmcs: new UserInfoSimplePmcs(this.data)
      })
    },
    doClose(){
      this.visible = false;
    },
    initiateDialogue(){
      this.doClose();
      this.$plugin.userDetail.initiateDialogue(this.data);
    },
    applyFriend(){
      this.doClose();
      this.$plugin.userDetail.applyFriend(this.data);
    }

  },
  mounted(){
  },
}
</script>
<style lang="scss" scoped>
.components_plugin_user_detail_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 99999;
  }
  .container{
    // width: 100%;
    // height: 100%;
    max-width: 80%;
    max-height: 90%;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
    padding-bottom: 20px;
    position: relative;
    z-index: 2;
  }
  .button{
    @include background;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    &.previous{
      left: -100px;
      background-image: url(~@/assets/images/reminder/previous.png);
    }
    &.next{
      right: -100px;
      background-image: url(~@/assets/images/reminder/next.png);
    }
  }
  .title_group{
    height: 30px;
    position: relative;
    width: 100%;
    // background-color: #f8f8f8;
    border-radius: 4px 4px 0 0;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    width: 600px;
    font-size: 14px;
    color: #333333;
    padding: 20px;
  }
  .mb-24{
    margin-bottom: 24px;
  }
  .btn_group{
    padding-top: 30px;
    .btn {
      cursor: pointer;
      line-height: 40px;
      border-radius: 4px;
      margin-right: 20px;
      text-align: center;
      width: 200px;
    }
  }
}
</style>