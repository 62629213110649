<template>
  <div class="v_pc_gift_card_p_send_out_details_main bbox d-flex flex-column align-stretch justify-start">
    <div class="time_group bbox d-flex justify-space-between">
      <div class="time">卡号：{{pcarduser.cardno}}</div>
      <div class="channel"
        :class="[channel.type]"
      >{{channel.name}}</div>
    </div>
    <card-component
      class="card_component cp mb-10"
      :pcard="pcarduser.card"
      :pcarduser="pcarduser"
      width="420px"
      height="244px"
      title_size="28px"
    ></card-component>
    <div class="detail_group d-flex align-center">
      <div class="name">获取日期</div>
      <div class="value">{{$moment(pcarduser.createTime).format('YYYY-MM-DD hh:mm:ss')}}</div>
    </div>
    <div class="detail_group d-flex align-center">
      <div class="name">领取姓名</div>
      <div class="value">{{pcarduser.useUserName}}</div>
    </div>
    <div class="detail_group d-flex align-center">
      <div class="name">领取号码</div>
      <div class="value">{{pcarduser.usePhone}}</div>
    </div>
    <div class="detail_group d-flex align-center">
      <div class="name">领取时间</div>
      <div class="value">{{$moment(pcarduser.useTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
    </div>


  </div>
</template>

<script>
import CardComponent from "@/views/PersonalCenter/GiftCard/Modules/Card.vue"
export default {
  props: {
    pcarduser: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    CardComponent,
  },
  computed: {
    channel(){
      const {pcarduser} = this
      if(!pcarduser){
        return {name: '', type: ''}
      }
      const {channel} = pcarduser
      if(channel === 0){
        return {name: '付费购买', type: ''}
      }
      if(channel === 1){
        return {name: '官方赠送', type: 'office'}
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_p_send_out_details_main{
  padding: 40px 20px;
  .time_group{
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    .channel{
      color: #EB5800;
      &.office{
        color: #884006;
      }
    }
  }
  .detail_group{
    color: #333;
    font-size: 14px;
    padding-top: 20px;
    .name{
      padding-right: 20px;
      color: #666;
    }
  }
}
</style>
