var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_pop_up_at_all_index un_sel bbox cp",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.clickAll()
        },
      },
    },
    [
      _c("div", { staticClass: "page_group d-flex align-center bbox" }, [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "name pl-16" }, [
          _vm._v("所有" + _vm._s(_vm.task_id ? "参与人" : "组员")),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }