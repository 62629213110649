<!--
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-06-08 20:41:28
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-08 20:55:09
 * @FilePath     : /miaohang/src/components/Aggrement/Plugins/Privacy/Main.vue
 * @Description  : 
-->
<template>
  <privacy-policy></privacy-policy>
</template>


<script>
import PrivacyPolicy from '@/components/Clause/PrivacyPolicy.vue'
export default {
  components: {
    PrivacyPolicy,
  }
}
</script>
``