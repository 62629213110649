<!--
 * @Author       : Hugo
 * @Date         : 2022-02-21 11:38:37
 * @LastEditTime : 2022-02-21 12:11:46
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/SelectWidgetContace/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <plugin-module
      :chat_user_list="chat_user_list"
      :friend_list="friend_list"
      :user_list="user_list"
      @handleClose="handleClose"
      @handleSure="handleSure"
      @handleSelect="handleSelect"
      @handleRemove="handleRemove"
    ></plugin-module>
  </div>
</template>

<script>
import PluginModule from '@/components/PopUp/SelectWidgetContact.vue';
export default {
  components: {
    PluginModule,
  },
  data(){
    return{
      visible       : false,
      chat_user_list: undefined,
      friend_list   : undefined,
      user_list     : undefined,
      item          : undefined,
      cb            : undefined,
    }
  },
  mounted(){

  },
  methods: {
    doClose(){
      this.visible = false;
    },
    handleClose(){
      this.cb({method_name: 'handleClose', data: undefined});
      this.doClose();
    },
    handleSure(selected_list){
      this.cb({method_name: 'handleSure', data: selected_list});
    },
    handleSelect(userid){
      this.cb({method_name: 'handleSelect', data: userid});
    },
    handleRemove(userid){
      this.cb({method_name: 'handleRemove', data: userid});
    }


   
    

  }
}
</script>
