<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="auto"
      width="auto"
      max_width="840px"
    >
      <div class="c_plugin_gift_card_payment_success d-flex flex-column"
      >
        <plugin-component
          class="plugin_component"
          :pcarduser="pcarduser"
          :gift-card="giftCard"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
        ></plugin-component>
        <!-- </div> -->
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/PopUp/GiftCardPaymentSuccess/index.vue'
export default {
  components: {
    CommonWrapper,
    PluginComponent,
  },
  data(){
    return {
      giftCard: undefined,
      visible: false,
      title: '',
      onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      pcarduser: undefined
    }
  },
  mounted(){
    // // console.log(this.task, )
  },
  methods: {

    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){

      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_gift_card_payment_success{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius   : 4px;
  overflow-y: auto;
  .main{
    flex   : 1;
    width  : 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
