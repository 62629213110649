<!--
 * @Author       : Hugo
 * @Date         : 2022-03-14 13:26:44
 * @LastEditTime : 2022-04-01 11:41:20
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/ClassesTask/modules/FunBar/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_classes_task_modules_fun_bar_index d-flex justify-space-between bbox align-center"
  >
    <div class="origin_group"
    >{{getSource()}}</div>
    <div class="fun_group d-flex align-center">
      <color
        :task="task"
        class="h-24 px-10 border-left"
      ></color>
      <privacy
        :task="task"
        class="h-24 px-10 border-left border-right"
      ></privacy>
      <button-group
        @handleCall="callTask"
        @handleUse="useTask"
        @handleSend="handleSend"
      ></button-group>
      <el-popover
        placement="bottom-end"
        trigger="click"
        popper-class="c_classes_task_modules_fun_bar_index_popover"
      >
        <div class="list un_sel">
          <div class="item bbox cp"
            @click="doShare();"
          >分享</div>
          <div class="item bbox cp"
            @click="doDelete()"
          >删除</div>
          <div class="item bbox cp"
            @click="doRecover()"
          >恢复</div>
          <div class="item bbox cp"
            @click="completeDelete();"
          >彻底删除</div>
        </div>
        <template slot="reference">
          <div class="menu cp"
          ></div>
        </template>
      </el-popover>
      
      <div class="close cp"
        @click="closeTask();"
      ></div>
    </div>
  </div>
</template>
<script>
import ButtonGroup from './ButtonGroup.vue'
import Privacy from './Privacy.vue'
import Color from './Color.vue'
export default {
  components:{
    ButtonGroup,
    Privacy,
    Color,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return{
      switch_popover: false,
    }
  },
  computed: {
    task(){
      const {data} = this
      const {task} = data ?? {}
      return task
    }
  },
  methods: {
    getSource(){
      const {task} = this;
      if(!task){
        return '';
      }
      return task.getSource();
    },
    closeTask(){
      // this.$api.moduleTask.closeTask();
      this.$closeTask();
    },
    closePoppver(){
      this.switch_popover = false;
    },
    doDelete(){
      const {task_config} = this.$store.state;
      const {draft, chat_id, chat_type, task_id, task_name} = task_config;
      this.closePoppver();
      this.$openConfirm({
        title:`是否删除${draft===1?'草稿':'任务'}?`,
        // desc:'该操作无法撤销',
        sure:'是',
        cancel:'否',
        onSure:async ()=>{

          if(draft){
            // 删除草稿
            const res = await this.$api.editTask.deleteDraft({id: task_id});
            if(res){
              this.$api.editTask.deleteDraftList({taskId: task_id});
            }
          }else{
            // 删除任务
            let res = await this.$api.deleteTask({
              taskId: task_id, 
              chatId:chat_id, 
              chatType: chat_type,
            });
            if(res){
              // 追加删除简述
              let task_log_unit = {
                chatId: chat_id,
                deleteFlag: 1,
                details: [
                  {
                    content: '',
                    controlType: 101,
                    createTime: new Date().getTime(),
                    title: task_name,
                    type: 3,
                  }
                ],
                createTime: new Date().getTime(),
                fromUserId: this.$tools.getUserId(),
                linkme: true,
                taskName: null,
                taskType: null,
                thumbnail: this.$tools.getCookie('user').thumbnail,
                type: 1,
                userName: this.$tools.getCookie('user').userName,
              }
              // 删除之前的任务简述(前端伪删除)
              const {message_list} = this.$store.state;
              message_list.forEach(item=>{
                if(item.taskId == task_id){
                  item.pseudo_delete = true;
                }
              })
              this.$api.moduleTask.common.pushMessageList(task_log_unit);
            }
          }
          this.$store.commit('set_switch_task_box', false);  //关闭
          // this.switch_fun_menu = false;
        },
        onCancel: ()=>{
          // this.switch_fun_menu = false;
         
        }
      })
     
    },
     /**
     * @description: 分享任务单 
     * @param {*}
     * @return {*}
     */    
    doShare(){
      const {commit, state} = this.$store;
      const {task} = state;
      commit('set_switch_multiple_contacts', true);
      commit('set_share_data', task.task);
      commit('set_multiple_contacts_callback', this.$api.share.task);
      this.closePoppver();
    },
    callTask(taskId){
      this.$emit('handleCall', taskId);
    },
    useTask(taskId){
      this.$emit('handleUse', taskId);
    },
    handleSend(){
      this.$emit('handleSend');
    },
    doRecover(){
      this.closePoppver();
      this.$emit('handleRecover');
    },
    completeDelete(){
      this.closePoppver();
      this.$emit('handleCompleteDelete');
    }
  }
}
</script>
<style lang="scss" scoped>
.c_classes_task_modules_fun_bar_index{
  border-bottom: 1px solid #F4F4F4;
  height: 50px;
  padding: 0 20px;
  padding-right: 10px;
  width: 100%;
  .origin_group{
    font-size: 16px;
    color: #999999;
  }
  .menu{
    @include background(20px, 5px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/menu.png);
  }
  .close{
    @include background(12px, 12px);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-image: url(~@/assets/images/task/close.png);
  }
  .px-10{
    padding-left: 10px;
    padding-right: 10px;
  }
  .h-24{
    height: 24px;
  }
  .border-left{
    border-left: 1px solid #f4f4f4;
  }
  .border-right{
    border-right: 1px solid #f4f4f4;
  }
}
</style>
<style lang="scss">
.c_classes_task_modules_fun_bar_index_popover{
  padding: 5px 0;
  margin-top: 0px!important;
  width: 100px;
  min-width: 100px;
  .list{
    .item{
      @include transition;
      font-size: 14px;
      color: #333333;
      line-height: 34px;
      padding: 0 10px;
      background-color: #fff;
      &.active{
        font-weight: bold;
      }
      &:hover{
        background-color: #ddd;
      }
    }
  }
}
</style>