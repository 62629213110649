var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c_collect_folder_unit bbox" }, [
    _c(
      "div",
      {
        staticClass: "unit_wrapper cp",
        on: {
          dblclick: function ($event) {
            return _vm.goToFolder()
          },
        },
      },
      [
        _c("img", {
          staticClass: "image un_sel",
          attrs: {
            src: require(`@/assets/images/custom/folder${
              _vm.readonly ? "_readonly" : ""
            }.png`),
            alt: "",
          },
        }),
        !_vm.preview && _vm.show_menu
          ? _c(
              "el-popover",
              {
                attrs: {
                  "visible-arrow": false,
                  offset: -5,
                  placement: "right-start",
                  width: "400",
                  trigger: "click",
                  "popper-class": "c_collect_folder_unit_popover",
                },
                model: {
                  value: _vm.switch_menu,
                  callback: function ($$v) {
                    _vm.switch_menu = $$v
                  },
                  expression: "switch_menu",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.start_moving,
                        expression: "!start_moving",
                      },
                    ],
                    ref: "menu_group",
                    staticClass: "menu_group",
                  },
                  [
                    0
                      ? _c("div", { staticClass: "menu_list_group" }, [
                          !_vm.readonly
                            ? _c(
                                "div",
                                {
                                  staticClass: "item un_sel cp",
                                  on: {
                                    click: function ($event) {
                                      return _vm.doRename()
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: require("@/assets/images/custom/menu/rename.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("重新命名"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "menu_list_group" }, [
                      _vm.renameable
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.doRename()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/rename.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("重新命名"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "item un_sel cp",
                          on: {
                            click: function ($event) {
                              return _vm.doShare()
                            },
                          },
                        },
                        [_c("div", { staticClass: "text" }, [_vm._v("分享")])]
                      ),
                      (_vm.readonly && _vm.p_folder.shareid) || !_vm.readonly
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.startMove()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/move.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("移动至"),
                              ]),
                              _c("img", {
                                staticClass: "arrow",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/arrow.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      (_vm.readonly && _vm.p_folder.shareid) || !_vm.readonly
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.removeCollect()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/delete.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("删除分组"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.disable_select &&
                      ((_vm.readonly && _vm.p_folder.shareid) || !_vm.readonly)
                        ? _c(
                            "div",
                            {
                              staticClass: "item un_sel cp",
                              on: {
                                click: function ($event) {
                                  return _vm.startSelect()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "icon",
                                attrs: {
                                  src: require("@/assets/images/custom/menu/multiple.png"),
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("多选分组"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      0
                        ? _c("div", { staticClass: "item un_sel" }, [
                            _c("div", { staticClass: "from" }, [
                              _vm._v("来源：C1220302｜妙行营销功能研发1.0"),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm.start_moving
                  ? _c(
                      "div",
                      {
                        staticClass: "moving_path_wrapper",
                        style: {
                          width: `${_vm.moving_path_width}px`,
                          "z-index": _vm.moving_path_z_index,
                        },
                      },
                      [
                        _vm.start_moving
                          ? _c("moving-path", {
                              attrs: {
                                mask: false,
                                multiple: false,
                                type: "folder",
                                id: String(_vm.p_folder.id),
                                current_folder: _vm.current_folder,
                                max_height: String(_vm.moving_max_height),
                                filter_list: [_vm.p_folder],
                              },
                              on: {
                                handleClose: _vm.doCloseMenu,
                                handleRemove: _vm.handleRemove,
                                handleSure: _vm.handleSure,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", {
                  staticClass: "menu_btn cp",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.switch_select,
                expression: "switch_select",
              },
            ],
            staticClass: "select_group cp",
          },
          [
            _c("div", {
              staticClass: "select",
              class: { on: _vm.p_folder.cf && _vm.p_folder.cf.selected },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.doSelect()
                },
              },
            }),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "name_group bbox" }, [
      _vm._v(_vm._s(_vm.folder_name)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }