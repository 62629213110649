var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [_vm._v("\n    " + _vm._s(_vm.type) + "\n  ")]),
    _c("td", [_vm._v("\n    " + _vm._s(_vm.pVipRecord.days) + "\n  ")]),
    _c("td", [_vm._v("\n    " + _vm._s(_vm.createTime) + "\n  ")]),
    _c("td", [_vm._v("\n    " + _vm._s(_vm.endTime) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }