<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-30 21:42:44
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-30 22:10:37
 * @FilePath: /miaohang/src/components/ClassesTask/modules/Plugins/AllTaskUsers/Main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div    
    class="c_classes_task_modules_plugins_all_task_users_main bbox d-flex align-center flex-wrap"
  >
    <template
      v-if="taskUsers"
    >
      <template
        v-for="(item, index) in taskUsers"
      >
        <div
          :key="index"
          class="user d-flex align-center flex-column flex-shrink-0"
          @click="openUserDetail(item);"
        >
          <div class="avatar_group">
            <img 
              :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
            <div class="readed_group d-flex align-center justify-center"
              v-if="item.isread"
            >
              <div class="text">已读</div>
            </div>
          </div>
          <div class="name">{{priorityName(item)}}</div>
        </div>
      </template>
      <div class="add_group cp un_sel"
        v-if="data.task.editable"
      >
        <div class="text bbox">添加</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: undefined
    },
    backup: {
      type: Object,
      default: undefined
    },
  },
  computed: {
    taskUsers(){
      const {data} = this
      if(!data){
        return undefined
      }
      const {taskUsers} = data
      return taskUsers
    }
  },
  methods: {
    priorityName(item){
      const {updateremark, friendRemark, userName, chatUserName} = item;
      if(updateremark === 1){
        return friendRemark || chatUserName || userName;
      }
      return chatUserName || userName;

    },
  }
  
}
</script>

<style lang="scss" scoped>
.c_classes_task_modules_plugins_all_task_users_main{
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  .user{
    width: 62px;
    margin: 5px 5px 10px;
  }
  .avatar_group{
    width: 52px;
    height: 52px;
    position: relative;
  }
  .avatar{
    width: 52px;
    height: 52px;
    // background-color: rgba(255,0,255, 0.6);
    border-radius: 4px;
    z-index: 2;
  }
  .readed_group{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    font-size: 12px;
    color: #fff;
    line-height: 14px;
    text-align: center;
    .text{
      transform: scale(0.6);
    }
  }
  .name{
    @include single_line_intercept;
    font-size: 12px;
    padding-top: 5px;
    color: #333;
    width:100%;
    text-align: center;
  }
  .add_group{
    margin: 5px 5px 30px;
    width: 62px;
    .text{
      width: 52px;
      line-height: 50px;
      height:52px;
      border-radius: 4px;
      border: 1px solid #aaa;
      text-align: center;
      font-size: 12px;
      letter-spacing: 2px;
      padding-left: 2px;
      color: #999;
    }
  }
}
</style>