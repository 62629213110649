<!--
 * @Author       : Hugo
 * @Date         : 2021-11-15 12:15:39
 * @LastEditTime : 2022-06-06 17:17:13
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/PopUp/identificationCode/Free.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="600px"
    :transparent="true"
    class="justify-center"
  >
    <div class="components_pop_up_identification_code_free_wrapper d-flex align-center justify-center">
      <div class="components_pop_up_identification_code_free">
        <img src="~@/assets/logo.png" alt="" class="logo"
        >
        <div class="wrapper">
          <div class="close cp"
            @click="doClose();"
          ></div>
          <template
            v-if="page_process === 'init'"
          >
            <div class="title_group d-flex align-center justify-center">
              <div class="title">{{title}}</div>
            </div>
            <div class="main d-flex flex-column align-start bbox">
              <!--<div class="item_group d-flex align-center">-->
              <!--  <div class="name">真实姓名</div>-->
              <!--  <div class="value_group">-->
              <!--    <input type="text" class="value bbox"-->
              <!--      placeholder="输入真实姓名"-->
              <!--      v-model="user_real_name"-->
              <!--      @keydown="hint=''"-->
              <!--    >-->
              <!--  </div>-->
              <!--</div>-->
              <!--<div class="item_group d-flex align-center">-->
              <!--  <div class="name">联系方式</div>-->
              <!--  <div class="value_group">-->
              <!--    <input type="text" class="value bbox"-->
              <!--      placeholder="输入联系方式"-->
              <!--      maxlength="11"-->
              <!--      v-model="phone"-->
              <!--      @keydown="hint=''"-->
              <!--    >-->
              <!--  </div>-->
              <!--</div>-->
              <!--<div class="item_group d-flex align-center">-->
              <!--  <div class="name">您的职位</div>-->
              <!--  <div class="value_group">-->
              <!--    <input type="text" class="value bbox"-->
              <!--      placeholder="输入您的职位"-->
              <!--      v-model="job"-->
              <!--      @keydown="hint=''"-->
              <!--    >-->
              <!--  </div>-->
              <!--</div>-->
              <!--<div class="item_group d-flex align-center">-->
              <!--  <div class="name">学校名称</div>-->
              <!--  <div class="value_group">-->
              <!--    <input type="text" class="value bbox"-->
              <!--      placeholder="输入学校名称"-->
              <!--      v-model="school"-->
              <!--      @keydown="hint=''"-->
              <!--    >-->
              <!--  </div>-->
              <!--</div>-->
              <div
                class="line_group bbox d-flex align-start"
              >
                <div class="key flex-shrink-0">
                  真实姓名
                </div>
                <input
                  v-model="name"
                  type="text"
                  class="input"
                  placeholder="请输入真实姓名"
                >
              </div>
              <div
                class="line_group bbox d-flex align-start "
              >
                <div class="key flex-shrink-0">
                  联系方式
                </div>
                <input
                  v-model="phone"
                  type="text"
                  class="input"
                  maxlength="11"
                  placeholder="请输入联系方式"
                >
              </div>
              <div
                class="line_group bbox d-flex align-start "
              >
                <div class="key flex-shrink-0">
                  您的职位
                </div>
                <el-select
                  v-model="identities"
                  clearable
                  placeholder="请选择您的职位"
                  class="select_group"
                  value="identities"
                >
                  <el-option
                    v-for="item in identities_list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                class="line_group bbox d-flex align-start "
              >
                <div class="key flex-shrink-0">
                  机构名称
                </div>
                <input
                  v-model="school"
                  type="text"
                  class="input"
                  placeholder="请输入机构名称"
                >
              </div>
              <div
                class="line_group bbox d-flex align-start "
              >
                <div class="key flex-shrink-0">
                  简短描述
                </div>
                <el-input
                  v-model="content"
                  type="textarea"
                  placeholder="请输入内容"
                  maxlength="80"
                  show-word-limit
                  resize="none"
                  class="textarea"
                >
                </el-input>
              </div>
              <div class="hint bbox">{{hint}}</div>
              <div class="button_group d-flex justify-center">
                <div class="btn btn_dark"
                  @click="touchSubmit();"
                >点击咨询</div>
              </div>
            </div>
          </template>
          <template
            v-if="page_process === 'success'"
          >
            <div class="success_group d-flex flex-column justify-center align-center">
              <img src="~@/assets/images/identification_code/success.png" alt="" class="success_icon">
              <div class="text">您已成功提交，请等待反馈</div>
              <div class="btn btn_dark cp bbox"
                @click="applyMore();"
              >继续添加多个申请</div>
            </div>

          </template>

        </div>
      </div>
    </div>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper.vue';
import global from '@/api/global'
export default {
  props: {
    title: {
      type: String,
      default: '妙行链动计划',
    },
    user: {
      type: Object,
      default: null,
    },
    loginUserCode: {
      type: String,
      default: '',
    }
  },
  components: {
    // Table
    CommonWrapper,
  },
  data() {
    return {
      loadingPost: false,
      identities: 1,
      identities_list: [
        { value: 1, label: '教师' },
        { value: 2, label: '学生' },
        { value: 3, label: '职工' },
        { value: 4, label: '其他' }
      ],
      content: '',
      identify_hint: '',
      user_real_name: '',
      phone: '',
      school: '',
      job: '',
      hint: '',
      page_process: 'init',
    }
  },
  watch: {
    //只能输入数字
    phone: function (val) {
      if(val){
        this.phone = String(val)
          .replace(/[^\d]+/g, "")
      }
    },
  },
  mounted(){
    const user = this.user;
    if(user){
      const {userRealName, phone} = user;
      this.user_real_name = userRealName;
      this.phone = phone;
    }
    
  },
  methods: {
    async touchSubmit(){
      const { content, identities, name, phone, school, loadingPost } = this
      const phoneRegExp = /^1[3456789]\d{9}$/;
      // const {user_real_name: name, phone, school, job} = this;
      // const data = {
      //   name: name.trim(),
      //   phone: String(phone).trim(),
      //   school: school.trim(),
      //   job: job.trim(),
      // }
      // if(data.name === ''){
      //   this.hint = '*请您填写全部信息';
      //   return;
      // }
      // if(data.phone === '' || !phoneRegExp.test(phone)){
      //   this.hint = '*请输入正确的联系方式';
      //   return;
      // }
      // if(data.school === ''){
      //   this.hint = '*请您填写全部信息';
      //   return;
      // }
      // if(data.job === ''){
      //   this.hint = '*请您填写全部信息';
      //   return;
      // }
      //
      // const res = await this.doSubmit(data);
      // if(res.message === 'success'){
      //   this.page_process = 'success';
      // }else{
      //   this.hint = `*${res.describe}`
      // }

      if (name.trim() === '') {
        this.$notice({ desc: '请输入真实姓名'})
        return
      }
      if (phone === '' || !phoneRegExp.test(phone)) {
        this.$notice({ desc: '请输入正确的手机号'});
        return
      }
      // if (!identities) {
      //   this.$notice({desc: '请选择职位' })
      //   return
      // }
      if (school.trim() === '') {
        this.$notice({ desc: '请输入机构名称' })
        return
      }
      if (loadingPost) {
        return
      }
      const data = {
        name,
        phone,
        identities,
        school,
        content,
        enquireType: 1,
        title: `智慧桌技术支持-${name}`
      }
      this.loadingPost = true
      const res = await global.doPost({
        url: '/projectH5/link',
        data
      })
      this.loadingPost = false
      // const res = await this.doSubmit();
      if(res.message === 'success'){
        // this.$router.push({name: 'active_page_plan_success', params: {add_more_router_name: 'active_page_laboratory'}});
        this.page_process = 'success';
      }

    },
    async doSubmit(params){
      const {name, phone, job, school} = params;
      const  data = {
        // name: '链动信息采集',
        name: `采集-${name}-销售`,
        task_type: 1,
        chat_type: 1,
        // chat_id: 197,
        // to_users: '3,193,380,3762',
        chat_id: 912,
        // to_users: '5494,193,2863,380,3762',
        to_users: '5494,193,2863,380,3762,2863,4385,4383,4382,2424,3116,4386,5555,5902,4435',
        // controls: JSON.stringify([
        //   {"type":1,"remark":"姓名","content":name,"remindList":[],"sort":1,"fileJson":[],"sortJson":[]},
        //   {"type":1,"remark":"学校","content":school,"remindList":[],"sort":2,"fileJson":[],"sortJson":[]},
        //   {"type":1,"remark":"职务","content":job,"remindList":[],"sort":3,"fileJson":[],"sortJson":[]},
        //   {"type":1,"remark":"电话","content":phone,"remindList":[],"sort":4,"fileJson":[],"sortJson":[]}
        // ])
        controls: JSON.stringify([
          {"type":1,"remark":"姓名","content":name,"remindList":[],"sort":1,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"学校","content":school,"remindList":[],"sort":2,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"职务","content":job,"remindList":[],"sort":3,"fileJson":[],"sortJson":[]},
          {"type":1,"remark":"电话","content":phone,"remindList":[],"sort":4,"fileJson":[],"sortJson":[]}
        ])
      }
      this.$showLoading({title: '提交中'});
      // const res = await this.$global.doPost({
      //   url: '/projectTask/createTask',
      //   data,
      // })
      const res = await this.$http.basic_request(
        '/projectTask/createTask',
        data,
        {
          headers: {
            loginUserCode: JSON.stringify({"code":"1634626594791100000","userId":"5494","logintype":"1"}),
          }
        }
      )
      // const res = {message: 'success'};
      this.$hideLoading();
      return res;

    },
    applyMore(){
      this.user_real_name = '';
      this.phone = '';
      this.school = '';
      this.job = '';
      this.page_process = 'init';
    },
    doClose(){
      this.$emit('handleClose');
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_identification_code_free_wrapper{
  width: 100%;
  height: 100%;
}
.components_pop_up_identification_code_free{
  width: 100%;
  .logo{
    margin: 0 auto;
    width: 80px;
    height: 80px;
    z-index: 3;
    display: block;
    position: relative;
    margin-bottom: -30px;
  }
  .wrapper{
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    //height: 402px;
    position: relative;
    z-index: 2;
    padding-top: 30px;
  }
  .title_group{
    margin: 0 auto;
    font-size: 20px;
    color: $main_blue;
    padding-top: 12px;
  }
  .close{
    @include background(12px, 12px);
    background: {
      image: url(~@/assets/images/common/close.png);
    }
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 3;
    right: 12px;
    top: 12px;
  }
  .main{
    width: 100%;
    padding: 40px;
  }
  //.item_group{
  //  width: 100%;
  //  margin-bottom: 20px;
  //  &:nth-of-type(4){
  //    margin-bottom: 0;
  //  }
  //  .name{
  //    font-size: 14px;
  //    color: #333333;
  //    width: 80px;
  //  }
  //  .value_group{
  //    flex: 1;
  //  }
  //  .value{
  //    @include placeholder(#bbb);
  //    width: 100%;
  //    height: 36px;
  //    background: #FBFBFB;
  //    border-radius: 4px;
  //    border: 1px solid #E5E7EA;
  //    font-size: 14px;
  //    color: #333;
  //    outline: none;
  //    padding: 0 10px;
  //  }
  //}
  .line_group{
    padding-bottom: 20px;
    width: 100%;
    .key{
      width: 80px;
      font-size: 14px;
      color: #333;
      line-height: 36px;
    }
    .select_group{
      width: 100%;
    }
    &.readonly{
      .input{
        border-color: transparent;
        background-color: transparent;
      }
    }
    .input{
      @include placeholder(#bbb);
      font-size: 14px;
      line-height: 34px;
      flex: 1;
      box-sizing: border-box;
      border: 1px solid #E5E7EA;
      padding: 0 10px;
      outline: none;
      background-color: #FBFBFB;
    }
    .verify{
      @include transition;
      width: 100px;
      text-align: center;
      font-size: 14px;
      color: $main_blue;
      border: 1px solid $main_blue;
      line-height: 34px;
      border-radius: 4px;
      margin-left: 10px;
      &.active{
        color: #bbb;
        border-color: #E5E7EA;
      }
    }
  }
  .hint{
    width: 100%;
    font-size: 14px;
    color: #D82E0D;
    padding-left: 80px;
    margin-top: 10px;
    height: 20px;
  }
  .button_group{
    width: 100%;
    padding-top: 10px;
    .btn{
      font-size: 14px;
      line-height: 40px;
      border-radius: 4px;
      width: 240px;
      text-align: center;

    }
  }
  .success_group{
    width: 100%;
    height: 100%;
    .text{
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    .btn{
      line-height: 40px;
      font-size: 14px;
      width: 240px;
      border-radius: 4px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
</style>
<style lang="scss">
.components_pop_up_identification_code_free{
  .readonly{
    .select_group{
      .el-input{
        &.is-focus{
          border-color: transparent!important;
        }
      }
      .el-input__inner{
        background-color: transparent!important;
        border-color: transparent!important;
      }
    }
    .textarea{
      .el-textarea__inner{
        border-color: transparent!important;
        background-color: transparent!important;
      }
    }
  }
  .select_group{
    width: 100%;
    .el-input{
      &.is-focus{
        border-color: #E5E7EA!important;
      }
    }
    .el-input__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      color: #333!important;
      background-color: #FBFBFB!important;
    }
    .el-input.is-disabled .el-input__inner{
      cursor: inherit!important;
    }
  }
  .textarea{
    .el-textarea__inner{
      @include placeholder(#bbb);
      border-color: #E5E7EA!important;
      color: #333!important;
      padding-left: 10px!important;
      padding-right: 10px!important;
      font-family: 'MicroSoft YaHei',sans-serif!important;
      line-height: 20px!important;
      font-size: 14px!important;
      padding-top: 8px!important;
      padding-bottom: 8px!important;
      background-color: #FBFBFB!important;
    }
  }
}
</style>

