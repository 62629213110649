var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template" }, [
    _vm.show
      ? _c("div", { staticClass: "tel" }, [
          _c("ul", [
            _c("li", [
              _c("p", [_vm._v("密        码:")]),
              _c("div", { staticClass: "hint_group" }, [
                _vm.input_type === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: "请输入密码",
                        name: "new_pwd",
                        maxlength: "8",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.password)
                          ? _vm._i(_vm.password, null) > -1
                          : _vm.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.password = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.password = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.password = $$c
                          }
                        },
                      },
                    })
                  : _vm.input_type === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: "请输入密码",
                        name: "new_pwd",
                        maxlength: "8",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.password, null) },
                      on: {
                        change: function ($event) {
                          _vm.password = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      attrs: {
                        placeholder: "请输入密码",
                        name: "new_pwd",
                        maxlength: "8",
                        type: _vm.input_type,
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: (_vm.password || _vm.repetition) && !_vm.show_pwd,
                      expression: "(password || repetition) && !show_pwd",
                    },
                  ],
                  staticClass: "cp",
                  attrs: {
                    src: require("../../../assets/icon-eye.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.show_pwd = true
                    },
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: (_vm.password || _vm.repetition) && _vm.show_pwd,
                      expression: "(password || repetition) && show_pwd",
                    },
                  ],
                  staticClass: "cp",
                  attrs: {
                    src: require("../../../assets/icon-sel.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.show_pwd = false
                    },
                  },
                }),
                _c("div", { staticClass: "hint" }, [
                  _vm._v("请输入6-8位的数字或字母"),
                ]),
              ]),
            ]),
            _c("li", [
              _c("p", [_vm._v("重复密码:")]),
              _c("div", [
                _vm.input_type === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.repetition,
                          expression: "repetition",
                        },
                      ],
                      attrs: {
                        placeholder: "请重复输入密码",
                        name: "repetition",
                        maxlength: "8",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.repetition)
                          ? _vm._i(_vm.repetition, null) > -1
                          : _vm.repetition,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.repetition,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.repetition = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.repetition = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.repetition = $$c
                          }
                        },
                      },
                    })
                  : _vm.input_type === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.repetition,
                          expression: "repetition",
                        },
                      ],
                      attrs: {
                        placeholder: "请重复输入密码",
                        name: "repetition",
                        maxlength: "8",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.repetition, null) },
                      on: {
                        change: function ($event) {
                          _vm.repetition = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.repetition,
                          expression: "repetition",
                        },
                      ],
                      attrs: {
                        placeholder: "请重复输入密码",
                        name: "repetition",
                        maxlength: "8",
                        type: _vm.input_type,
                      },
                      domProps: { value: _vm.repetition },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.repetition = $event.target.value
                        },
                      },
                    }),
              ]),
            ]),
            _c("li", { staticClass: "agreement" }, [
              _c("h1", { on: { click: _vm.selsctShow } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selsct,
                      expression: "selsct",
                    },
                  ],
                  attrs: { src: require("../../../assets/icon-nor.png") },
                }),
              ]),
              _c("h1", { on: { click: _vm.selsctShow } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.selsct,
                      expression: "!selsct",
                    },
                  ],
                  attrs: { src: require("../../../assets/icon-dui.png") },
                }),
              ]),
              _c("p", [
                _vm._v("我已阅读并同意\n                    "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.agreementShow("service_agreement")
                      },
                    },
                  },
                  [_vm._v("用户服务协议")]
                ),
                _vm._v("\n                     \n                    "),
                _c(
                  "a",
                  {
                    attrs: { href: "" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.agreementShow("privacy_policy")
                      },
                    },
                  },
                  [_vm._v("用户隐私政策")]
                ),
              ]),
            ]),
          ]),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.Warn) } }),
          _c("div", { staticClass: "btn" }, [
            _c("button", { staticClass: "low", on: { click: _vm.nextstep } }, [
              _vm._v("下一步"),
            ]),
            _c(
              "button",
              { staticClass: "cancel", on: { click: _vm.cancelRegister } },
              [_vm._v("取消注册")]
            ),
          ]),
        ])
      : _c("div", { staticClass: "protocol" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "contentArea" },
              [
                _vm.agreement_type == "service_agreement"
                  ? _c("service-agreement")
                  : _vm._e(),
                _vm.agreement_type == "privacy_policy"
                  ? _c("privacy-policy")
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "continue" }, [
            _c("button", { on: { click: _vm.continueShow } }, [
              _vm._v("继 续"),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }