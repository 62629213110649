<!--
 * @Author       : Hugo
 * @Date         : 2022-04-13 15:09:50
 * @LastEditTime : 2022-04-25 15:32:35
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/FolderPreview/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->

<template>
  <div
    class="c_collect_folder_preview d-flex flex-column"
  > 
    <operation-bar
      class="flex-shrink-0 operation_group"
      :p_task_log="p_task_log"
      :p_folder="p_folder"
      @handleClose="handleClose"
    ></operation-bar>
    <main-component
      class="main_group"
      ref="main"
      :preview="true"
    ></main-component>
  </div>
</template>

<script>
import store from '@/store'
import OperationBar from './OperationBar.vue'
import MainComponent from '@/components/Collect/Main.vue'
export default {
  components: {
    OperationBar,
    MainComponent,
  },
  props:{
    p_task_log: {
      type: Object,
      default: undefined
    },
    p_folder: {
      type: Object,
      default: undefined
    },
  },
  watch:{
    // '$route': function(val){
    //   console.log(val)

    // }
  },
  mounted(){
  
    // setTimeout(()=>{
    //   console.log(1)
    //   this.$router.push({
    //     name: 'fun_template_my_custom',
    //     params: {
    //       type: 'custom',
    //       folderid: Math.floor(Math.random()*100)
    //     }

    //   })
    // }, 10000)
  },
  methods: {
    handleClose(){
      this.$emit('handleClose')
    },
    handleCreateFolder(p_folder){
      if(p_folder){
        this.$refs.main.handleCreateFolder(p_folder)
      }
    },
    handleChangeSearch(search){
      this.$refs.main.changeSearch(search)
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_folder_preview{
  width: 100%;
  height: 100%;
  background-color: #fff;
  .operation_group{
    width: 100%;
    height: 50px;
    padding: 0 20px;
  }
  .main_group{
    flex: 1;
    width: 100%;
    overflow: auto;
    background-color: #f8f8f8;
  }
  .main_wrapper{
    width: 100%;
  }
}
</style>
