<!--
 * @Author       : Hugo
 * @Date         : 2022-01-06 16:56:14
 * @LastEditTime : 2022-01-07 11:11:58
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Payment/BuyNow/Number.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_payment_buy_now_number bbox d-flex align-center"
  >
    <div class="button minus"
      @click.stop="doMinus();"
    ></div>
    <input type="text" class="number mx-6"
      v-model="input_number"
      @change="doChange();"
    >
    <div class="button plus"
      @click.stop="doPlus();"
    ></div>

  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 1,
    }
  },
  data(){
    return {
      input_number: 1,
    }
  },
  watch:{
    number: function(val){
      this.input_number = val;
    },
    //只能输入数字
    input_number: function(val){
      if(val){
        val = String(val);
        this.input_number = Number(val.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.'));
      }
    }
  },
  mounted(){
    this.input_number = this.number;
  },
  methods: {
    doMinus(){
      if(this.number == 1){
        return false;
      }
      this.$emit('handleMinus');
    },
    doPlus(){
      this.$emit('handlePlus');
    },
    doChange(){
      if(this.number === this.input_number){
        return false;
      }
      // console.log(this.input_number);
      this.$emit('handleChange', Number(this.input_number));
    }
  }
}
</script>

<style lang="scss" scoped>
.components_payment_buy_now_number{
  .button,
  .number{
    box-sizing: border-box;
    border: 1px solid #E5E7EA;
    border-radius: 4px;
    height: 36px;
    line-height: 34px;
  }
  .button{
    @include background(16px,16px);
    width: 40px;
    background-image: url(~@/assets/images/payment/minus.png);
    &.plus{
      background-image: url(~@/assets/images/payment/plus.png);
    }
  }
  .number{
    width: 80px;
    outline: none;
    text-align: center;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
  }
}
</style>
