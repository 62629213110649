<template>
  <div
    class="weaken_common_page d-flex justify-center align-center"
    v-if="action"
  >

    <div class="username"
      v-if="action.userName"
    >"{{action.userName}}"</div>
    <div
      v-if="action.task_action"
    >&nbsp;{{action.task_action}}</div>
    <div
      class="action">&nbsp;"<span class="name" :class="{cp: action.type !== 'delete_task'}" @click="handlerClick(action)">{{action.taskName}}</span>"
    </div>
    <div
      v-if="action.task_hint"
    >&nbsp;{{action.task_hint}}</div>
    <div
      v-if="action.control_hint"
    >&nbsp;{{action.control_hint}}</div>
    <div
      v-if="action.user_action"
    >&nbsp;{{action.user_action}}</div>
    <div
      v-if="action.conTitle"
    >&nbsp;"<span class="name">{{action.conTitle}}</span>"</div>
    <div 
      v-if="action.control_action"
    >&nbsp;{{action.control_action}}</div>
    <div
      v-if="action.relevanceTitle"
    >&nbsp;"<span class="name">{{action.relevanceTitle}}</span>"</div>
  </div>
</template>
<script>
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  computed:{
    detail(){
      const {msg} = this;
      if(!msg){
        return null;
      }
      const {details} = msg;
      if(!details){
        return null;
      }
      return details[0];
    },
    action(){
      /**
       * delete_task
       * delete_control
       * delete_relevance
       * change_order
       * add_partner
       * clear_control
       * clear_relevance
       */
      /**
       * 公共参数
      {
        taskId: 'id',
        taskName: '',

      }
       */
      /* 
        删除任务 '任务名'
        {
          deleteFlag: 1,
          taskName: '任务名',
          details: [
            {
              controlType: 101,
              type: 3,
              title: '',
            }
          ]
        }
        删除任务 '任务名' [中的 '控件名' 控件]
        {
          taskName: '任务名',
          details: [
            {
              type:3,
              conTitle: '控件名'
            }
          ]
        }
        删除任务 '任务名' [中的 '控件名' 的关联控件 '关联控件名']
        {
          taskName: '任务名',
          details: [
            {
              type:3,
              conTitle: '控件名'
              conTitleP: '控件名'
              conId: '',
              conIdP: '',
            }
          ]
        }

        更改任务 '任务名' 中的控件顺序
        {
          taskName: '任务名',
          details: [
            {
              controlType: 103,
            }
          ]
        }
        将任务 中添加 参与人
        {
          taskName: '任务名',
          details: [
            {
              title: '任务参与人',
              content: '',
            }
          ]
        }
        将 '参与人' 从共工作组中移除
        { 暂无 }
        将 '参与人' 添加至工作组
        { 暂无 }
        更新了公示板
        { 暂无 }
        置空任务 '任务名' [中的 '控件名' 控件] 
        暂不提出
        {
          taskName: '任务名',
          details: [
            {
              conTitle: '',
            }
          ]
        }
        置空任务 '任务名' [中的 '控件名' 的关联控件 '关联控件名']  
        暂不提出
        {
          taskName: '任务名',
          details: [
            {
              conId: 44904
              conIdP: 44863
              conTitle: ""
              conTitleP: "图片测试"
              content: "将控件名称（1）置空 "
              controlType: 104
              controlTypeP: 9
              taskId: 5623
              title: "'1' 修改为 ''"
              type: 2
            }
          ]
        }
      */

      const {msg, detail} = this;
      const {details, deleteFlag, taskName, taskId, userName} = msg;
      if(!detail){
        return
      }
      const {conId, conIdP, conTitle, conTitleP, content, controlType, controlTypeP, type, title} = detail;
      let result = {
        taskName,
        taskId,
        userName,
      }
      // // console.log(deleteFlag ,type === 3 , controlType === 101)
      if(deleteFlag || type === 3 && controlType === 101){
        result.type = 'delete_task'
        result.task_action = '删除任务'
        result.taskName = title
        return result
      }
      if(type === 3 && !deleteFlag){
        result.control_hint = '中的'
        result.control_action = '控件'
        result.conTitle = conTitle || '未命名标题'
        result.conId = conId
        if(conIdP){
          result.type = 'delete_relevance'
          result.conTitle = conTitleP || '未命名标题'
          result.conId = conIdP
          result.control_hint = '中'
          result.relevanceTitle = conTitle || '未命名标题'
          result.control_action = '的关联控件'
        }
        result.type = 'delete_control'
    
        
        result.task_action = '删除任务'
      }
      if(type === 4 || type === 5){
        if(type === 4){
          result.task_action = '开始'
        }
        if(type === 5){
          result.task_action = '结束'
        }
        result.task_action += '投屏'
        result.control_hint = '中的'
        result.conTitle = conTitle || '未命名标题'
        result.conId = conId
        result.control_action = '控件'
      }
      if(type === 6){
        delete result.userName
        result.task_action = '任务'
        result.control_hint = '中的'
        result.conTitle = conTitle || '未命名标题'
        result.conId = conId
        result.control_action = '投屏结束'

      }
      if(controlType === 103){
        result.type = 'change_order'
        result.task_action = '更改任务'
        result.task_hint = '控件顺序'
      }
      if(controlType === 102){
        result.task_action = '将任务'
        result.user_action = content
      }
      if (controlType === 105) {
        result.task_action = '将任务'
        result.user_action = content
      }
      return result
    },
  },
  methods: {
    handlerClick(action){
      const { type, taskId, conId, conIdP} = action;
      if(type === 'delete_task'){
        return;
      }
      if(taskId){
        this.openTask({taskId});
      }
    },
    openTask({taskId}){
      this.$api.moduleTask.openTask({taskId, config: {
        edit: true,
      }})
      return;
    },
  },

}
</script>
<style lang="scss" scoped>
.weaken_common_page{
  font-size: 12px;
  color: #aaa;
  .name{
    color: #617B9D;
  }
}
</style>