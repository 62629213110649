<template>
  <div class="information" @click="pageHandler">
    <div class="information_mask"></div>
    <div class="information-con">
      <div class="intion">
        <p class="xxi">完善个人信息</p>
      </div>
      <div class="gray"></div>
      <div class="input_wrapper">
        <div class="gender line_group ">
          <p class="key">性别:</p>
          <div class="val no_border">
            <p class="redu cp" @click="selectSex('女')">
              <img src="../../assets/icon-dui.png" v-if="user.userSex &&user.userSex == '女'" alt />
              <img src="../../assets/icon-nor.png" v-else alt />
              <!-- <img src="../../assets/icon-nor.png" v-if="nor1" alt /> -->
            </p>
            <p class="nv">女士</p>
            <img src="../../assets/icon-girl.png" alt class="girl" />
            <p class="redu1 cp" @click="selectSex('男')">
              <!-- <img src="../../assets/icon-nor.png" v-if="nor2" alt /> -->
              <img src="../../assets/icon-dui.png" v-if="user.userSex &&user.userSex == '男'" alt />
              <img src="../../assets/icon-nor.png" v-else alt />
            </p>
            <p class="man">男士</p>
            <img src="../../assets/icon-boy.png" alt class="boy" />
          </div>
        </div>
        <div class="region line_group">
          <p class="key">地区:</p>
          <!-- <div class="sfen"> -->
          <div class="val select_area">
            <div
              class="info cp"
              :class="{un_input: !area_selected}"
              @click.stop="switch_select_area=true;"
            >{{area}}</div>
            <div class="select_icon cp" @click.stop="switch_select_area=true;"></div>
            <div class="select_area_model" v-if="switch_select_area">
              <select-area @closeSelectArea="switch_select_area=false;"></select-area>
            </div>
          </div>
        </div>
        <div class="line_group">
          <div class="key">机构:</div>
          <div class="val">
            <!-- 个人用户填写的所属企业名称或学校名称 ownedEnterprises -->
            <input type="text" class=" input" v-model="user.ownedEnterprises">
            <div class="clear" v-show="user.ownedEnterprises" @click.stop="user.ownedEnterprises=''"></div>
          </div>
        </div>
        <div class="line_group">
          <div class="key">职务:</div>
          <div class="val">
            <!-- userPosition -->
            <input type="text" class=" input" v-model="user.userPosition">
            <div class="clear" v-show="user.userPosition" @click.stop="user.userPosition=''"></div>
          </div>
        </div>
        <div class="line_group">
          <div class="key">身份:</div>
          <div class="val select_identity">
            <div
              class="info cp"
              :class="{un_input: !user.identity}"
              @click.stop="switch_select_identity=true;"
            >{{identity_text}}</div>
            <div class="select_icon cp" @click.stop="switch_select_identity=true;"></div>
            <div class="select_identity_group" v-if="switch_select_identity">
              <div
                class="title"
                :class="{un_input: !user.identity}"
              >{{identity_text}}
                <div class="select_icon"></div>
              </div>
              <div class="item" @click="selectIdentity(1)">教师</div>
              <div class="item" @click="selectIdentity(2)">学生</div>
              <div class="item" @click="selectIdentity(3)">从业者</div>
            </div>
          </div>
        </div>
        <div class="line_group">
          <div class="key">邮箱:</div>
          <div class="val">
            <!-- userPosition -->
            <input type="text" class=" input" v-model="user.email">
            <div class="clear" v-show="user.email" @click.stop="user.email=''"></div>
          </div>
        </div>
        <div class="line_group ">
          <div class="key" style="align-self: flex-start;">个性签名:</div>
          <div class="val no_border" style="align-self: flex-start;height: auto;">
            <!-- userPosition -->
            <textarea class="sign" v-model="user.personalDescribe"></textarea>
          </div>
        </div>
      </div>
      <div class="vation">
        <button class="reser cp" @click="saveFile">保存</button>
        <button class="too cp" @click="tiaoguo">跳过</button>
      </div>
    </div>
    <identification-code
      v-if="switch_identification_code"
      :loginUserCode="loginUserCode"
      :user="user"
      @handleClose="closeIdentificationCode();"
      @handleSuccess="identifySuccess()"
    ></identification-code>
  </div>
</template>

<script>
import IdentificationCode from '@/components/PopUp/identificationCode/index.vue';
import SelectArea from '@/components/Module/SelectArea.vue';
export default {
  components:{
    SelectArea,
    IdentificationCode,
  },
  data() {
    return {
      nor1: true,
      nor2: true,
      shengSpan: "请选择省",
      shiSpan: "请选择市",
      params: {
        phone: "", //手机号
        sex: "", //性别男 女
        provinceCoder: "", //省份编码
        coder: "", //城市编码
        address: "111", //地址
        ownedEnterprises: "", //所属公司
        userPosition: "", //职位
        email: "", // 邮箱
        personalDescribe: "" //描述
      },
      user: this.$tools.getCookie('user') || {},
      switch_select_area: false,
      switch_select_identity: false,

      switch_identification_code: false,
      // user: null,
      loginUserCode: '',
    };
  },
  computed:{
    province(){
      return this.$store.state.province;
    },
    city(){
      return this.$store.state.city;
    },
    /**地区是否已选择 */
    area_selected(){
      let {province} = this;
      if(province && province.province && province.provinceCoder){
        return true;
      }else{
        return false;
      }
    },
    area(){
      let area = '请选择省/市';
      let {province, city} = this;
      if(province && province.province && province.provinceCoder){
        area = province.province;
      }
      if(city && city.city && city.coder){
        if(area != '请选择省/市'){
          area += ' ' + city.city;
        }
      }
      return area;
    },
    identity_text(){
      let identity_text = '请选择身份';
      let {identity} = this.user;
      if(identity){
        switch(identity){
          case 1:
            identity_text = '教师';
            break;
          case 2:
            identity_text = '学生';
            break;
          case 3:
            identity_text = '从业者';
            break;
          default:
            identity_text = '其他';
            break;
        }
      }
      return identity_text;
    }
  },
  watch: {
    province: {
      handler: function(){},
      deep: true,
    },
    city: {
      handler: function(){},
      deep: true,
    },
    area: {
      handler: function(){},
      deep: true,
    },
    user: {
      handler: function(){},
      deep: true,
    },
    identity_text: function(){},
  },
  mounted() {
    this.init();
  },
  methods: {
    /**选择性别 */
    selectSex(val){
      this.$set(this.user, 'userSex', val);
    },
    /**点击页面的行为 */
    pageHandler(){
      this.switch_select_area = false;
      this.switch_select_identity = false;
    },
    /**选择身份 */
    selectIdentity(identity){
      this.$set(this.user, 'identity', identity);
      this.switch_select_identity = false;
    },
    init(){
      let {user} = this;
      // 设置省市信息
      let {provinceCoder, coder, province, city} = user;
      this.$store.commit('set_province', { province, provinceCoder });
      this.$store.commit('set_city', {coder, city});
    },
    async saveFile() {
      let data = {};
      let {ownedEnterprises,userPosition,identity,email, personalDescribe,userSex} = this.user;
      let emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if(email && !emailReg.test(email)){
        this.$toast({icon:'none',title:'提示',desc:'邮箱格式错误,请修改后提交',duration:1000});
        return;
      }
      data.provinceCoder = this.province ? this.province.provinceCoder : '';
      data.coder = this.city ? this.city.coder : '';
      data.ownedEnterprises = ownedEnterprises || '';
      data.userPosition = userPosition || '';
      data.identity = identity || '';
      data.email = email || '';
      data.personalDescribe = personalDescribe || '';
      data.sex = userSex || '';
      let res = await this.$http.request('/project/finishInfo',data);
      if(res.message == 'success'){
        this.$cookies.set('user',this.user);// 设置cookie
        this.$router.push('/');
      }
    },
    tiaoguo() {
      this.$router.push("/");
    },
    closeIdentificationCode(){
      this.switch_identification_code = false;
    },
    async identifySuccess(){
      this.closeIdentificationCode();
      this.$showLoading({title: '加载中'});
      // await this.login();
      await this.$api.user.refreshUserData();
      this.$hideLoading();
      this.$router.push('/')
    }
  }
};
</script>
<style lang="scss" scoped>
.information{
  .information-con{
    .input_wrapper{
      @include flex;
      flex-direction: column;
      justify-content: center;
      width: 400px;
      margin: 0 auto;
    }
    .line_group{
      @include flex;
      @include bbox;

      width: 100%;
      margin-top: 15px;
      .key{
        @include text_justify;
        flex-shrink: 0;
        width: 90px!important;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 84, 166, 1);
        margin-right: 20px;
      }
      .val{
        @include flex;
        @include bbox;
        width: 100%;
        flex: 1;
        border-bottom: 1px solid #d5d9db;
        height: 45px;
        position: relative;
        &.no_border{
          border-bottom: 1px solid transparent;
        }
        .info{
          @include bbox;
          padding-right: 20px;
          font-size: 18px;
          color: #343434;
          width: 100%;
          // 未输入
          &.un_input{
            color: #D6D9DC;
          }
        }
        &.select_area{
          position: relative;
          .select_icon{
            @include background(12px, 8px);
            width:20px;
            height: 20px;
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: -10px;
            background-image: url(~@/assets/images/common/load_more.png);
          }
          .select_area_model{
            position: absolute;
            z-index: 99;
            top:0;
            left: 0;
          }
        }
        .input{
          @include bbox;
          width: 100%;
          border: none;
          line-height: 44px;
          outline: none;
          color: #343434;
          padding-right: 20px;
          position: relative;
          z-index: 2;
          font-size: 18px;
        }
        .clear{
          @include background(18px 12px);
          cursor: pointer;
          position: absolute;
          width: 20px;
          height: 20px;
          background-image: url(~@/assets/icon-shut.png);
          top:50%;
          margin-top: -10px;
          right: 0;
          z-index: 3;
        }
        &.select_identity{
          .info{
            @include bbox;
            width: 100%;
            padding-right: 20px;
          }
          .select_icon{
            @include background(12px, 8px);
            width:20px;
            height: 20px;
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: -10px;
            background-image: url(~@/assets/images/common/load_more.png);
          }
          .select_identity_group{
            position: absolute;

            top: 0;
            left: -10px;
            right: 0;
            background: #FFFFFF;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            padding-bottom: 18px;
            z-index: 4;
            font-size: 18px;
            .title{
              // @include bbox;
              padding-left: 10px;
              width: 100%;
              position: relative;
              color: #343434;
              line-height: 45px;
              border-bottom: 1px solid #d5d9db;
              // 未输入
              &.un_input{
                color: #D6D9DC;
              }
              .select_icon{
                @include background(12px 8px);
                width: 20px;
                height:20px;
                background-image: url(~@/assets/images/common/load_more.png);
                top:50%;
                margin-top: -10px;
                right: 10px;
                position: absolute;
              }
            }
            .item{
              @include bbox;
              cursor: pointer;
              width: 100%;
              padding-left:10px;
              line-height: 50px;
              &:hover{
                background-color: #f8f8f8;
              }
            }
          }
        }
        .sign{
          @include scrollbar;
          @include bbox;
          resize: none;
          width: 100%;
          height: 80px;
          line-height: 20px;
          font-size: 18px;
          outline: none;
          padding: 10px 16px;
          color: #343434;
          border-radius: 4px;
          border: 1px solid #D6DADC;
          font-family: 'MicroSoft YaHei';
        }
      }
    }
  }
}
</style>

<style lang='less' scoped>
.information {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/tp.jpg");
  background-position: center;
  background-size: cover;
  // opacity: 0.8;
  display: flex;
  align-items: center;
  position: relative;
  .information_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 2;
  }

  .information-con {
    width: 1200px;
    height: 680px;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;
    position: relative;
    z-index: 3;
    .xxi {
      width: 108px;
      height: 17px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      margin-left: 546px;
      margin-top: 41px;
    }

    .gray {
      width: 1200px;
      height: 2px;
      background: rgba(240, 240, 240, 1);
      margin-top: 32px;
    }

    .gender {
      display: flex;
      .redu {
        width: 16px;
        height: 16px;
        // border: 2px solid rgba(130, 146, 156, 1);
        // border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .nv {
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        padding-left: 10px;
      }
      .girl {
        display: block;
        width: 20px;
        height: 20px;
        padding-left: 3px;
      }
      .redu1 {
        width: 16px;
        height: 16px;
        margin-left: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .man {
        font-size: 18px;
        color: rgba(51, 51, 51, 1);
        padding-left: 10px;
      }
      .boy {
        display: block;
        width: 20px;
        height: 20px;
        padding-left: 3px;
      }
    }


    .vation {
      display: flex;

      .reser {
        width: 190px;
        height: 50px;
        background: rgba(0, 84, 166, 1);
        border-radius: 4px;
        font-size: 18px;
        color: #ffffff;
        border: none;
        margin-left: 402px;
        margin-top: 30px;
      }
      .reser:hover {
        background: #004080;
      }
      .too {
        width: 190px;
        height: 50px;
        background: #e4eef6;
        border-radius: 4px;
        color: #ffffff;
        border: none;
        font-size: 18px;
        margin-left: 16px;
        margin-top: 30px;
        color: #0054a6;
      }
      .too:hover {
        background: #ccdded;
      }
    }
  }
}
</style>
