/*
 * @Author       : Hugo
 * @Date         : 2020-08-19 14:29:01
 * @LastEditors: hugo
 * @LastEditTime: 2022-06-01 09:37:51
 * @Description  : 
 * @FilePath: /miaohang/src/router/personal_center.js
 */
const personal_center = [
  {
    path: "/personal_center",
    name: "personal_center",
    component: () => import('@/views/layout/default.vue'),
    // redirect: 'fun_task_index',
    children: [
      {
        path: "index",
        name: "personal_center_index",
        redirect: 'data',
        components: {
          default: ()=> import('@/views/PersonalCenter/index.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      },
      {
        path: "data",
        name: "personal_center_data",
        components: {
          default: ()=> import('@/views/PersonalCenter/Data.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      },
      {
        path: "password",
        name: "personal_center_password",
        components: {
          default: ()=> import('@/views/PersonalCenter/Password.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      },
      {
        path: 'activation_code',
        name: 'personal_center_activation_code',
        components: {
          default: ()=> import('@/views/PersonalCenter/ActivationCode.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      },
      {
        path: 'vip_and_consumption',
        name: 'personal_center_vip_and_consumption',
        components: {
          default: ()=> import('@/views/PersonalCenter/VipAndConsumption.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      },
      // 礼品卡
      {
        path: 'gift_card',
        name: 'personal_center_gift_card',
        components: {
          default: ()=> import('@/views/PersonalCenter/GiftCard/index.vue'),
          sidebar: () => import('@/components/Group/Aside/PersonalCenter.vue'),
        },
      }

    ]
  }
]

export default personal_center;