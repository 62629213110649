var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "brief_title_group",
      class: [
        `brief_type_${_vm.briefType(
          _vm.relevance
            ? _vm.msg.controlType
            : _vm.msg.controlTypeP || _vm.msg.controlType
        )}`,
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.openAppoint()
        },
      },
    },
    [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "update_title" }, [
        _vm._v(_vm._s(_vm.relevance ? "关联: " : "") + _vm._s(_vm.content)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }