import vue from '@/main.js'
import sendTaskFun from './sendTaskFun'

class CallTask{
  main = async () => {
    const { task, task_config } = vue.$store.state
    const { create_teach, price, create_draft, select_target, select_target_chat_id, select_target_chat_type, create_instance } = task_config
    const { levelStatus, processStatus } = task.task
    if(task_config.task_name === ''){
      vue.$notice({desc: '请输入任务标题'})
      return false
    }
    let data = {
      name: task_config.task_name, 
      task_type: task_config.task_type,
      hide: task_config.privacy ? 1 : 0,
      to_users: sendTaskFun.generateToUsers(),
      controls: sendTaskFun.generateControls(),
      chat_type: task_config.chat_type,
      chat_id: task_config.chat_id,
      addtags: task_config.addtags,
    }
    if (levelStatus) {
      data.levelStatus = levelStatus
    }
    if (processStatus) {
      data.processStatus = processStatus
    }
    console.log(task_config)
    if (select_target && !create_teach && !create_draft) {
      if ((select_target_chat_id === null || select_target_chat_type === null)) {
        vue.$notice({ desc: '请选择发送对象' })
        return false
      }
      data.chat_id = select_target_chat_id
      data.chat_type = select_target_chat_type
    }
    const res = await vue.$global.doPost({
      url: '/projectTask/createTask',
      data,
      notice: '发布失败',
      preventDuplicateClicks: true,
    })
    return res
  }
}

const callTask = new CallTask()

export default callTask.main
