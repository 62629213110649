/*
 * @Author       : Hugo
 * @Date         : 2021-12-24 14:45:00
 * @LastEditTime : 2021-12-24 15:03:59
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/modules/teaching_package/index.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main';
class TeachingPackage{
  getData = async (params)=>{
    const {id} = params;
    const res = vue.$global.doPost({
      url: '/projectTeach/queryPackageDetail',
      data: {
        packageId: id,
      }
    })
    return res;
  }

  getTaskList = async (params)=> {
    const {id, startId} = params;
    const data = {
      packageId: id,
    };
    if(startId){
      data.startId = startId;
    }
    const res = vue.$global.doPost({
      url: '/projectTeach/queryPackageTaskList',
      data,
    })
    return res;
  }
}

const teaching_package = new TeachingPackage();

export default teaching_package;