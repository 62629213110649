import vue from '@/main.js';
import {UserInfo} from '@/types';
import store from '@/store'
class Chat{
  /**
   * 消息列表追加
   * @param {*} param0 
   * @returns 
   */
  pushChatAtMessageList = (pTaskLog = {}) => {
    const {
      chatId,
      type,
      thumbnail,
      work,
      userName,
      fromUserId,
      createTime,
      taskId,
      taskName,
      logType,
      toUserId,
      hide
    } = pTaskLog
    const isBottom = store.state.dialogData.isBottom
    const {params} = vue.$route;
    if(!params){
      return;
    }
    const {id} = params;
    if(!id){
      return ;
    }
    const current_chat_id = vue.$global.decodeBase64(id);
    const current_chat_type = vue.$tools.getChatType();
    const params_chat_id = type ? chatId : toUserId;
    // // console.log({chatId, type, thumbnail, work, userName, fromUserId, createTime, taskId, taskName, logType},chatId,current_chat_id, type, current_chat_type);
    // 单聊发送聊天 返回的chatId为null
    console.log({
      params_chat_id,
      current_chat_id,
      type,
      current_chat_type,
      chatId,
      toUserId,
    })
    if(params_chat_id != current_chat_id || type != current_chat_type){
      return;
    }
  
    const message_list = store.state.message_list
    // message_list.push({
    //   chatId,
    //   type,
    //   thumbnail,
    //   work,
    //   userName,
    //   fromUserId,
    //   createTime,
    //   taskId,
    //   taskName,
    //   logType,
    //   toUserId,
    //   hide
    // })
    message_list.push(pTaskLog)
    if (isBottom) {
      vue.$nextTick(function () {
        vue.$api.messageList.scrollToLatest()
      })
    } else {
      // store.state.dialogData.unreadNumber += 1
    }
  }
  /**
   * 发送聊天消息
   * @param {object} param0 
   * @returns 
   */
  sendChat = async ({taskId,content,chat_type,chat_id}) => {

    const data = {
      taskId,content,chat_type,chat_id,
    }
    const linkusers = await this.atUserList({task_id:taskId,content,chat_type,chat_id});
    if(linkusers){
      data.linkusers = linkusers;
    }
    try{
      const res = await vue.$global.doPost({
        url: '/projectNews/sendTaskChat',
        data: data,
        notice: '发送失败',
        preventDuplicateClicks: true,
      })
      return res;
    }catch(err){
      return err;
    }
  }
  pushChatAtMessageBoxList(params){
    const { chatId, chatType, lastSendTime,messageContent,name, updateUserId,updateusername } = params || {}
    let message_box_list = vue.$store.state.message_box_list
    if (!message_box_list || !message_box_list.length) {
      return
    }
    let index = message_box_list.findIndex( (item) => {
      return (item.chatId == chatId) && ( item.chatType == chatType)
    })
    // 消息盒子列表
    if (index != -1) {
      message_box_list[index].lastSendTime = Number(lastSendTime);
      // message_box_list[index].createTime = Number(lastSendTime);
      message_box_list[index].messageContent = messageContent;
      message_box_list[index].updateusername  = vue.$utils.users.getPriorityName({userId:updateUserId, name: updateusername});
      // message_box_list[index].photo  = chatType === "1" ? photo : updateuserthumbnail;
      // 当前聊天窗口不更新盒子
      // if(route.params.id != chatId){
      if (vue.$api.moduleTask.getRouteParamsId() != chatId) {
        message_box_list[index].unreadMessageNum = message_box_list[index].unreadMessageNum?message_box_list[index].unreadMessageNum+1 : 1
      }
      // 移到顶部
      let unit = message_box_list[index]
      message_box_list.splice(index, 1)
      if (unit.istop) {
        // 置顶消息处理
        message_box_list.unshift(unit)
      } else {
        // 非置顶消息
        let not_top_index = message_box_list.findIndex((item) => {
          return !item.istop
        })
        if (not_top_index != -1) {
          message_box_list.splice(not_top_index, 0 ,unit)
        } else {
          message_box_list.unshift(unit)
        }
      }
    } else {
      //新建消息盒子
      let message_box_unit = {
        chatId,
        chatType: chatType,
        createTime: Number(lastSendTime),
        messageContent: messageContent,
        name: name,
        // photo: chatType === "1" ? photo : updateuserthumbnail,
        unreadMessageNum: 1,
        updateusername: updateusername
      }
      // 非置顶消息
      let not_top_index = message_box_list.findIndex(item => {
        return !item.istop
      })
      if (not_top_index != -1) {
        message_box_list.splice(not_top_index, 0 ,message_box_unit)
      } else {
        message_box_list.unshift(message_box_unit)
      }
    }
  }
  /**
   * 获取任务聊天信息
   * @param {*} params 
   * @returns 
   */
  async getTaskChatList (params) {
    const {taskId} = params || {}
    if (!taskId) {
      return
    }
    let data = {
      taskId,
      startId: ''
    }
    const res = await vue.$global.doPost({
      url: '/projectNews/taskMessageList',
      data
    })
    if (res.message == 'success') {
      const {taskMessageList} = res.contents
      // this.$set(this, 'message_list', taskMessageList);
      if (taskMessageList && taskMessageList.length) {
        vue.$store.commit('set_task_chat_message_list', taskMessageList.reverse())
      }
    }
    vue.$nextTick(function () {
      // console.log('滚动到底部');
      // 滚动到底部
      setTimeout(() => {
        vue.$store.commit('set_task_chat_trigger_scroll_to_bottom', true)
      },200)
    })
  }
  /**
   * 获取 @用户 列表
   * @param {*} params 
   * @returns 
   */
  atUserList = async (params = {}) => {
    return vue.$api.at.getSendUsersList(params);
    const at = ' ';
    const self_id = vue.$tools.getUserId();
    if(JSON.stringify(params) === '{}'){
      return false;
    }
    const {chat_id, chat_type, task_id, content} = params;
    if(!content){
      return false;
    }
    if(chat_id === undefined){
      return false;
    }
    if(chat_type === undefined){
      return false;
    }
    // 判断是否@所有人
    const is_all = Boolean(content.indexOf(`@所有人${at}`) !== -1);
  

    if(!task_id){
      const dialog_users = await vue.$api.dialog.getDialogUsers({chat_id, chat_type});
      if(dialog_users){
        const {list} = dialog_users;
        let linkusers = '';
        if(list){
          if(is_all){
            list.forEach(unit=>{
              if(Number(unit.userId) !== Number(self_id)){
                linkusers += linkusers === '' ? unit.userId : `,${unit.userId}`;
              }
            })
            return linkusers;
          }
          list.forEach(unit=>{
            const user_info = new UserInfo(unit);
            const verify_str = `@${user_info.priorableName()}${at}`;
            if(content.indexOf(verify_str) !== -1){
              linkusers += linkusers === '' ? user_info.userId : `,${user_info.userId}`;
            }
          })
          return linkusers || false;

        }
      }
    }

    return false;
  }
}

const chat = new Chat();

export default chat;