<template>
  <div
    class="sketch_chat_content_page d-flex align-stretch flex-column"
    :class="[type]"
  >
    <chat-common
      class="cp"
      :msg="msg"
      :type="type"
      @click.native="openTask"
    ></chat-common>
    <chat-task-title
      class="cp"
      v-if="is_task"
      :msg="msg"
      @click.native="openTask"
    ></chat-task-title>
  </div>
</template>
<script>
import tools from '@/api/tools'
import store from '@/store'
import ChatCommon from './Common.vue';
import ChatTaskTitle from './TaskTitle.vue';
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'send',
    }
  },
  computed: {
    is_task(){
      const { msg } = this
      const { logType } = msg
      if (logType === 3) {
        return true
      }
      return false
    }
  },
  components: {
    ChatCommon,
    ChatTaskTitle,
  },
  methods: {
    openTask(){
      const { $route } = this
      const { params } = $route
      const { id } = params
      const currentChatId = tools.atob(id)
      const { is_task } = this
      if (is_task) {
          const config = {}
          config.edit = true
          config.chat = true
          this.$api.moduleTask.openTask({
            taskId: this.msg.taskId,
            config,
            currentChatId,
            callback: this.handlerMigrate
          })
      }
    },
    /**
     * 任务被迁移的处理
     */
    handlerMigrate (params) {
      const { msg } = this
      const { taskId } = msg
      const { message_list, my_task_list } = store.state
      // 提示：已迁移
      this.$openConfirm({
        title: '该任务被迁移至其他工作组，已自动清除',
        desc: '是否跳转至该工作组查看？',
        onSure: () => {
          try {
            const { task } = params.contents
            const { chatId } = task
            this.$router.push({
              name: 'fun_task_dialog_group',
              params: {
                id: tools.btoa(chatId)
              }
            })

          } catch (e) {
            console.log(e)
          }
        }
      })
      // 清除当前message_list的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const taskLogIndex = message_list.findIndex((unit) => {
            return Number(unit.taskId) === Number(taskId)
          })
          if (taskLogIndex !== -1) {
            message_list.splice(taskLogIndex, 1)
          } else {
            break
          }
        }

      } catch (e) {
        console.log(e)
      }
      // 清除任务区的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const myTaskListIndex = my_task_list.findIndex((unit) => {
            return Number(unit.id) === Number(taskId)
          })
          if (myTaskListIndex !== -1) {
            my_task_list.splice(myTaskListIndex, 1)
          } else {
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

}
</script>
<style lang="scss" scoped>
.sketch_chat_content_page{
  max-width: 680px;
  padding-right: 30px;
  &.send{
    padding-right: 0;
  }
}
</style>