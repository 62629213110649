<!--
 * @Author       : Hugo
 * @Date         : 2021-05-07 01:23:14
 * @LastEditTime: 2022-05-20 00:17:08
 * @LastEditors: hugo 1634765756@qq.com
 * @Description  : 
 * @FilePath     : /miaohang/src/components/Common/MovingPath/Unit.vue
 * ^_^
-->
<template>
  <div
    class="unit_page"
    @click="$api.stopPropagation($event)"
  >
    <div class="list flex"
      :style="{'max-height': `${max_height}px`}"
    >
      <div class="group new"
        v-if="createable"
      >
        <div class="item un_sel cp"
          @click="createNewFolder();"
        >
          <div class="text">新建分组</div>
        </div>
      </div>
      <div class="group folder"
        ref="list"
        :style="{'max-height': `${max_height-51}px`}"
      >
        <template
          v-for="(item, index) in origin"
        >
          <div class="item un_sel cp"
            :key="index"
            @click="doSelect(item)"
            v-if="canShowFolder(item)"
          >
            <div class="text">{{item.name}}</div>
            <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow"
              v-if="item.sonfolderList && item.sonfolderList.length"
            >
          </div>
        
        </template>
        <div class="loading"
          v-show="loading && !no_more"
        >
          <loading-icon
            size="12px"
          ></loading-icon>
        </div>
       
      </div>
    </div>
    <template
      v-if="next_data && next_data.length"
    >
      <unit
        :data="next_data"
        :multiple="multiple"
        :type="type"
        :id="id"
        :max_height="max_height"
        :filter_list="filter_list"
        @handleRemove="handleRemove"
        @handleSure="handleSure"
      ></unit>
    </template>
  </div>
</template>
<script>
import LoadingIcon from '@/components/basic/loadingIcon.vue';
export default {
  name: 'unit',
  components:{
    LoadingIcon
  },
  props: {
    filter_list: { //不显示需要排除的文件夹 
      type: Array,
      default: undefined,
    },
    current_folder: { // 当前文件夹
      type: Object,
      default: undefined,
    },
    folderid: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'default'
    },
    id: {
      type: String,
      default: '0',
    },
    max_height: {
      type: String,
      default: '451',
    },
    createable: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: function(){
        return [
          {id: 34, userId: 4360, name: "1992", createTime: 1619419418000, updateTime: 1619419418000},
          {id: 34, userId: 4360, name: "1992", createTime: 1619419418000, updateTime: 1619419418000},
          {id: 33, userId: 4360, name: "1993", createTime: 1619419389000, updateTime: 1619419389000},
          {id: 32, userId: 4360, name: "1994", createTime: 1619419378000, updateTime: 1619419378000},
          {id: 31, userId: 4360, name: "1995", createTime: 1619419334000, updateTime: 1619419334000},
          {id: 30, userId: 4360, name: "1996", createTime: 1619419300000, updateTime: 1619419300000},
          {id: 29, userId: 4360, name: "1997", createTime: 1619419273000, updateTime: 1619419273000},
          {id: 28, userId: 4360, name: "1998", createTime: 1619419267000, updateTime: 1619419267000},
          {id: 27, userId: 4360, name: "1998", createTime: 1619419045000, updateTime: 1619419045000},
          {id: 26, userId: 4360, name: "1999", createTime: 1619418593000, updateTime: 1619418593000},
          {id: 25, userId: 4360, name: "2000", createTime: 1619418583000, updateTime: 1619418583000},
          {id: 24, userId: 4360, name: "2002", createTime: 1619418570000, updateTime: 1619418570000},
          {id: 23, userId: 4360, name: "2001", createTime: 1619418561000, updateTime: 1619418561000},
          {id: 22, userId: 4360, name: "2003", createTime: 1619418557000, updateTime: 1619418557000},
          {id: 21, userId: 4360, name: "2004", createTime: 1619418554000, updateTime: 1619418554000},
          {id: 20, userId: 4360, name: "2005", createTime: 1619418550000, updateTime: 1619418550000},
          {id: 19, userId: 4360, name: "2006", createTime: 1619418547000, updateTime: 1619418547000},
          {id: 18, userId: 4360, name: "2007", createTime: 1619418543000, updateTime: 1619418543000},
          {id: 17, userId: 4360, name: "2008", createTime: 1619418540000, updateTime: 1619418540000},
          {id: 16, userId: 4360, name: "2009", createTime: 1619418536000, updateTime: 1619418536000},
          {id: 15, userId: 4360, name: "2010", createTime: 1619418523000, updateTime: 1619418523000},
          {id: 14, userId: 4360, name: "2011", createTime: 1619418520000, updateTime: 1619418520000},
          {id: 13, userId: 4360, name: "2012", createTime: 1619418516000, updateTime: 1619418516000},
          {id: 12, userId: 4360, name: "2013", createTime: 1619418507000, updateTime: 1619418507000},
          {id: 11, userId: 4360, name: "2014", createTime: 1619418503000, updateTime: 1619418503000},
          {id: 10, userId: 4360, name: "2015", createTime: 1619418499000, updateTime: 1619418499000},
          {id: 9, userId: 4360, name: "2016", createTime: 1619418495000, updateTime: 1619418495000},
          {id: 8, userId: 4360, name: "2017", createTime: 1619418489000, updateTime: 1619418489000},
          {id: 7, userId: 4360, name: "2018年", createTime: 1619418485000, updateTime: 1619418485000},
          {id: 6, userId: 4360, name: "2019年", createTime: 1619418480000, updateTime: 1619418480000},
          {id: 5, userId: 4360, name: "2020年", createTime: 1619417980000, updateTime: 1619417980000},
          {id: 4, userId: 4360, name: "2021年", createTime: 1619417961000, updateTime: 1619417961000},
        ]
      }
    }
  },
  data(){
    return {
      origin: this.data,
      loading: false,
      no_more: false,
      next_data: [],
      next_id: -1,
      loadmore_list_critical_value: false,
      select_id: -1,
      select_data: null,
    }
  },
  watch: {
    data: {
      handler:function(val){
        if(val){
          // this.$set(this, 'origin', val)
          this.init();
        }
      },
      deep: true,
    },
    loadmore_list_critical_value: function(val){
      if(val){
        const {no_more, loading} = this;
        if(no_more || loading){
          return;
        }
        // console.log('loadmore')
        // 加载更多
        this.loadMoreData();
      }
    }
  },
  methods: {
    /**
     * 文件夹是否可以显示
     */
    canShowFolder(item){
      const {filter_list, current_folder} = this
      if((!filter_list || filter_list.length === 0) && current_folder === undefined){
        return true
      }
      // 选中的列表不显示
      if(filter_list && filter_list.length === 0){
        const has = filter_list.find(unit=>{
          return unit.id === item.id
        })
        if(has !== undefined){
          return false
        }
      }
      // 当前文件夹不显示
      if(current_folder){
        if(item.id === current_folder.id){
          return false
        }
      }
      
      return true
    },

    handleRemove(){
      console.log('unit_handle_remove')
      this.$emit('handleRemove')
    },
    /**
     * @description: 判断是否显示待移动的文件夹
     * @param {*} item
     * @return {*}
     */
    isInMovingFolder(item){
      return this.$api.custom.isInMovingFolder(item.id);
    },
    /**
     * @description: 创建新分组
     * @param {*}
     * @return {*}
     */
    async createNewFolder(){
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }
      this.$openPrompt({
        title:'新建分组',
        placeholder: '输入分组名称',
        hint: '请输入分组名称',
        sure: '确认',
        cancel: '取消',
        onSure: async ()=>{
          const {utils_prompt} = this.$store.state;
          const fId = this.data[0].fid;
          let data = {
            fId: fId || 0,
            name: utils_prompt,
          }
          const res = await this.$global.doPost({
            url: '/projectCustom/createFolder',
            data,
            notice: '创建文件夹失败',
          })
          // 追加
          if(res.message == 'success'){
            if(res.contents && res.contents.PFolder){
              this.$notice({desc: '创建成功', type: 'success'});
              this.data.unshift(res.contents.PFolder);
            }
          }

        },
        onCancel: ()=>{},
      })
    },
    /**
     * @description: 关闭
     * @param {*}
     * @return {*}
     */
    doClose(){
      const {multiple, type, id} = this;
      // const {commit} = this.$store;
      // commit('set_switch_filter_folder_select', false);
      // commit('set_filter_folder_selected_breakpoint_id', -1);
      this.$api.custom.clearMovingPath();
      if(type == 'file'){
        this.$store.commit('set_close_task_menu_of_moving', true);
      }
    },
    /**
     * @description: 加载更多
     * @param {*}
     * @return {*}
     */
    async loadMoreData(){
      this.loading = true;
      const {origin} = this;
      const data = {
        sort: 1,
        fId: origin[origin.length-1].fid,
        startId: origin[origin.length-1].id,
      }
      let res = await this.$api.custom.getFolders(data);
      if(res.contents && res.contents.pFolderList){
        let new_origin = origin.concat(res.contents.pFolderList);
        if(res.contents.pFolderList.length < 50){
          this.no_more = true;
        }
        this.$set(this, 'origin', new_origin);
        // this.$set(this, 'next_data', res.contents.pFolderList)
      }
      this.loading = false;
    },
    async init(){
      const {data} = this;
      this.$set(this, 'origin', data);
      this.loading = false;
      if(data.length < 50){
        this.no_more = true;
      }else{
        this.no_more = false;
        this.addEventListener('list');
      }
      this.$set(this, 'next_data', []);
    },
    async doSelect(item){
      
        // 多选移动
        const {select_id} = this;
        const {id, sonfolderList} = item;
        // // console.log(this.$store.state.custom_folders, select_id, id);
        const is_selected = (id == select_id);
        this.select_id = item.id;
        if(sonfolderList && sonfolderList.length){
          // 有子文件夹
          this.generateSelectData(item);
          if(is_selected){
            // 已被选中
            this.doSure(item);
            this.doClose();    
          }else{
            this.getNextList(item);
          }
        }else{
          this.next_id = item.id;
          this.generateSelectData(item);
          this.$set(this, 'next_data', [])
          // 没有子文件夹
          this.doSure(item);
          this.doClose();   
        }
     
      
    },
    /**
     * @description: 确定选中
     * @param {*} item
     * @return {*}
     */
    doSure(item){
      console.log(this.$emit)
      const that = this
      this.$emit('handleClose')
      this.$emit('handleSure', item)
      return
      const {multiple, type, id} = this;
      if(multiple){
        const {foldersid, filesid} = this.$api.custom.getMovingPathId();
        if(foldersid == '' && filesid == ''){
          return;
        }
        this.$openConfirm({
          title:`是否移动到 '${item.name}' ?`,
          desc:'',
          sure:'确定',
          cancel:'取消',
          onSure:async ()=>{
            let data = {
              ids: foldersid || filesid,
              type: foldersid != '' ? 0 : 1,
              toid: item.id
            }
            let res = await this.$global.doPost({
              url: '/projectCustom/moveFolderOrTask',
              data,
              notice: '移动失败',
            })
            if(res.message=='success'){
              console.log(res)
              this.$emit('handleRemove')
              // 重新加载文件和文件夹
              if(foldersid != ''){
                this.$store.commit('set_switch_reload_custom_folders', true);
              }
              if(filesid != ''){
                this.$store.commit('set_switch_reload_custom_files', true);
              }
              this.doClose();
              
            }
          },
          onCancel:()=>{},
        })

      }


      // 单个移动
      if(!multiple){
        let data_type = '';
        if(type == 'folder'){
          data_type = 0;
        }
        if(type == 'file'){
          data_type = 1;
        }
        if(type == 'file'){
          this.$store.commit('set_close_task_menu_of_moving', true);
        }
        this.$openConfirm({
          title:`是否移动到 '${item.name}' ?`,
          desc:'',
          sure:'确定',
          cancel:'取消',
          onSure:async ()=>{
            let data = {
              ids: id,
              type: data_type,
              toid: item.id
            }
            let res = await this.$global.doPost({
              url: '/projectCustom/moveFolderOrTask',
              data,
              notice: '移动失败',
            })
            if(res.message=='success'){
              console.log(res)
              console.log(this.$emit)
              that.$emit('handleRemove')
              // 重新加载文件和文件夹
              if(data_type == 0){
                this.$store.commit('set_switch_reload_custom_folders', true);
              }
              if(data_type == 1){
                this.$store.commit('set_switch_reload_custom_files', true);
              }

              this.doClose();
              
            }
          },
          onCancel:()=>{},
        })
      }

      // console.log(item);
      
      // this.transferSelectData();
    },
    handleSure(aim){
      this.$emit('handleSure', aim)
    },
    /**
     * @description: 生成被选择的数据
     * @param {*} item
     * @return {*}
     */
    generateSelectData(item){
      const origin = this.$tools.deepClone(this.origin);
      let find = origin.find(unit=>{
        return item.id == unit.id;
      })
      // // console.log(find)
      if(find){
        this.$set(this, 'select_data', find);
      }
      
    },
    /**
     * @description: 向上传递数据
     * @param {*} item
     * @return {*}
     */
    transferSelectData(item){
      // const {select_data} = this;
      const select_data = this.$tools.deepClone(this.select_data);
      // // console.log('--------')
      // if(item){
      //   // console.log(item.name);
      // }
      // // console.log(select_data, item)
      if(item != undefined){
        // item.father_item = select_data;
        select_data.child_item = item;
      
        // if(this.$parent.transferSelectData){
        //   this.$parent.transferSelectData(item);
        // }else{
        //   this.$parent.$parent.transferSelectData(item);
        // }
        // return;
      }
      if(this.$parent.transferSelectData){
        this.$parent.transferSelectData(select_data);
      }else{
        this.$parent.$parent.transferSelectData(select_data);
      }

    },
    /**
     * @description: 获取下一级列表
     * @param {*} item
     * @return {*}
     */
    async getNextList(item){
      if(item.sonfolderList==null){
        return;
      }
      const {id} = item;
      if(this.next_id == id){
        return ;
      }
      this.next_id = id;
      let data = {
        sort: 1,
        fId: id,
      }
      let res = await this.$api.custom.getFolders(data);
      if(res.contents && res.contents.pFolderList){
        // this.$store.commit('set_filter_folder_select_origin_list', res.contents.pFolderList);
        this.$set(this, 'next_data', res.contents.pFolderList);
        this.$nextTick(function(){
          this.$store.commit('set_moving_path_scroll_to_right', true);
        })
      }
    },

    /**
    * 滚动监听函数
    */
    scrollHandler(refname){
      // // console.log('scrollEventListenerHandler');
      this.scrollTop = this.$refs[refname].scrollTop;
      let status = false;
      if(this.$refs[refname]){
        const {clientHeight, scrollHeight} = this.$refs[refname];
        const {scrollTop} = this;
        // // console.log(scrollHeight - scrollTop - clientHeight)
        if((scrollHeight - scrollTop - clientHeight) < 200){
          status = true;
        }
      }
      // this.loadmore_files_critical_value = status;
      this[`loadmore_${refname}_critical_value`] = status;
    },
    scrollListHandler(){
      this.scrollHandler('list');
    },

    addEventListener(refname){
      
      // // console.log('addEventListener', this.$refs[refname]);
      if(this.$refs[refname]){

        this.$nextTick(function(){
          this.$refs[refname].scrollTop = 0;
          this.$refs[refname].removeEventListener('scroll', this.scrollListHandler)
          //  200ms后开启监听
          setTimeout(() => {
            if(this.$refs[refname]){
              this.$refs[refname].addEventListener('scroll', this.scrollListHandler, false);
            }
          }, 200);
          
        })
      }
    },
  },
  mounted(){
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.unit_page{
  @include flex;
  align-items: flex-start;
  .list{
    @include scrollbar;
    @include flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    // @include hidescrollbar;
    max-height: 40*11+11px;
    // overflow-y: auto;
    overflow: hidden;
    align-items: stretch;
    flex-direction: column;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right:1px;
  }
  .group{
    @include flex;
    @include bbox;
    flex-direction: column;
    align-items: stretch;
    padding: 5px 0;
    background-color:#fff;
    overflow-y: auto;
    flex-shrink: 0;
    &.new{
      border-bottom: 1px solid #eee;
    }
    &.folder{
      @include scrollbar;
      max-height: 10*40px;
      .item{
        height: 40px;
      }
    }
    .item{
      @include transition;
      @include flexsb;
      flex-shrink: 0;
      height: 40px;
      padding: 0 10px;
      &:hover{
        background-color: #eee;
      }
      .text{
        font-size: 14px;
        color:#333;
        padding-right: 10px;
      }
      .arrow{
        width: 8px;
        height: 12px;
      }
    }
    .loading{
      height:40px;
      background-color: #333;
    }
  }
}
</style>