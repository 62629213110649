<!--
 * @Author       : Hugo
 * @Date         : 2021-04-30 19:53:15
 * @LastEditTime : 2021-05-04 00:25:46
 * @LastEditors  : Hugo
 * @Description  : 
 * @FilePath     : \miaohang\src\components\basic\loadingIcon.vue
 * ^_^
-->
<template>
  <div
    class="loading flex"
  >
    <svg
      class="spinner"
      :style="{
        width: size,
        height: size
      }"
      :width="size"
      :height="size"
      viewBox="0 0 66 66"
      xmlns="https://www.w3.org/2000/svg"
    >
      <circle
        class="path"
        fill="none"
        stroke-width="6"
        stroke-linecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
    <svg
      v-if="0"
      version="1.1"
      id="loader-1"
      xmlns="https://www.w3.org/2000/svg"
      xmlns:xlink="https://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="size"
      :height="size"
      viewBox="0 0 50 50"
      style="enable-background:new 0 0 50 50;"
      xml:space="preserve"
    >
      <path
        :fill="background"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: '40px'
    }
  }
}
</script>
<style lang="scss" scoped>

$offset: 187;
$duration: 1.4s;
.loading{
  width: 100%;
  height: 100%;
  justify-content: center;
}
.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite, 
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
	0% { stroke: #4285F4; }
	25% { stroke: #DE3E35; }
	50% { stroke: #F7C223; }
	75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}
.page_loading {
  @include flex;
  justify-content: center;
  width:100vw;
  height:100vh;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
  background-color: rgba(255,255,255, 0.7);
 .loading_group{
    .load_text{
      padding-top: 10px;
      font-size: 14px;
      color:#555;
    }
 }
}
</style>