<template>
  <div
    class="sketch_file_page"
    
  > 
    <template
      v-if="file_list && file_list.length && msg.files != '' && msg.filesNum != 0"
    >
      <!--预览图片 -->
      <el-image
        ref="image"
        v-show="0"
        :src="preview_img_src"
        :preview-src-list="img_list"
        @click.native="setImgZindex();$api.stopPropagation($event)"
      ></el-image>
      <div class="list"
        @click="$api.stopPropagation($event)"
      >
        <template
          v-for="(item, index) in file_list"
        >
          <div
            :key="index"
            class="file d-flex align-start"
            v-if="index < 5"
            
          > 
            <div class="info_group d-flex align-start"
              @click="handlerFilename(item);"
            >
              <div
                class="icon flex-shrink-0"
                :class="item.class_name"  
              ></div>
              <div class="name">{{item.filename}}</div>
            </div>
            <div class="download_icon flex-shrink-0" @click="downloadFile(item)"></div>
          
          </div>
        </template>
        <div class="more cp un_sel"
          @click="getMore();"
          v-if="is_more && !switch_more"
        >更多...</div>
        <template
          v-if="switch_more"
        >
          <template
            v-for="(item, index) in file_list"
          >
            <div
              :key="index"
              class="file d-flex align-start"
              v-if="index >= 5"
            > 
              <div class="info_group d-flex align-start"
                @click="handlerFilename(item);"
              >
                <div
                  class="icon flex-shrink-0"
                  :class="item.class_name"  
                ></div>
                <div class="name">{{item.filename}}</div>
              </div>
              <div
                class="download_icon flex-shrink-0"
                @click="downloadFile(item)"
              ></div>
            
            </div>
          </template>
        </template>
      </div>
      <div
        class="pack_up"
        v-if="is_more && switch_more"
        @click="switch_more=false;$api.stopPropagation($event)"
      >收起</div>
    </template>
 
    <common-sketch
      v-else
      :msg="msg"
    ></common-sketch>
  </div>
</template>
<script>
import CommonSketch from './Common.vue';
import oss from '@/api/modules/oss'
import global from '@/api/global'
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  components: {
    CommonSketch,
  },
  data(){
    return {
      file_list: [],
      img_list: [],
      switch_more: false,
      preview_img_src: '',
      loadingCheckFile: false
    }
  },
  computed:{
    is_more(){
      const {file_list} = this;
      if(file_list && file_list.length > 5){
        return true ;
      }
      return false;
    }
  },
  methods: {
    getMore(){
      this.switch_more = true;
    },
    generateFileList () {
      const { msg } = this
      const {files} = msg
      let file_list = []
      let img_list = []
      // const critical = this.$moment('2021-09-08 10:56').format('x'); // 临界时间 // 以发布日期为准
      // const critical = this.$critical;
      // const picture_critical = this.$picture_critical;
      if(!files || files === '' ){
        return
      }
      const files_split = files.split('|');
      files_split.forEach(item=> {
        const obj = item.split('>');
        file_list.push({
          url: `${obj[0]}`,
          filename: obj[1],
          type: obj[2],
          id: obj[3],
          class_name: this.$tools.getExtName(obj[1]),
        })


        if(this.$tools.getExtName(obj[1]) === 'pic'){
          // if(createTime > critical && createTime < picture_critical){
          //   img_list.push(`${this.$img_url}${obj[0]}`)
          // }else{
            img_list.push(`${this.$file_url}${obj[0]}`);
          // }
          // if(item.createTime < Number(critical)){
            // img_list.push(`${this.$img_url}${obj[0]}`);
          // }else{

          //   img_list.push(`${this.$file_url}${obj[0]}`);
          // }
        }
      })
      this.$set(this, 'file_list', file_list);
      this.$set(this, 'img_list', img_list);
    },
    setImgZindex(){
      const zIndex = this.$utils.getZIndex();
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex;
      })
    },
    /**
     * 检查文件
     * @param {Object} item
     * @param {Number} item.id - 文件id
     * @returns {Promise<void>}
     */
    async checkFile (item) {
      if (this.loadingCheckFile) {
        return false
      }
      const { id } = item
      this.loadingCheckFile = true
      const res = await global.doPost({
        url: '/projectTask/checkFile',
        data: {
          fileId: id
        }
      })
      this.loadingCheckFile = false
      if (res.message !== 'success') {
        return false
      }
      return true
    },
    /**
     * @description: 
     * @param {*} item
     * @return {*}
     */    
    async handlerFilename (item) {
      // 'doc','xls', 'ppt','mp4', 'pic', 'zip', 'pdf', 'unknown'
      const { url, type } = item
      // const res = await this.checkFile(item)
      // if (!res) {
      //   return
      // }

      if (type === 'mp4') {
        this.previewVideo(item)
      }
      if (type === 'pic' || type === 'jpg' || type === 'jpeg' || type === 'png') {
        this.previewPicture(item)
      }
      if (type === 'doc' || type === 'ppt' || type === 'xls' || type === 'docx' || type === 'pptx' || type === 'xlsx') {
        this.previewOffice(item)
      }
      if (type === 'pdf') {
        this.previewPdf(item)
      }
    },
    previewVideo(item){
      let protocol = location.protocol;
      let src = this.$file_url;
      // 根据当前环境切换http/https源
      if(protocol == 'http:'){
        src = this.$file_url_http;
      }
      let {url} = item;
      src += url;
      // console.log(src);
      this.$openVideo({src})
    },
    previewPicture(item){
      // const {createTime} = item;
      // const critical = this.$moment('2021-09-08 10:56').format('x'); // 临界时间 // 以发布日期为准
      // const critical = this.$critical;
      // const picture_critical = this.$picture_critical;

      // if(createTime > critical && createTime < picture_critical){
      //   this.preview_img_src = `${this.$img_url}${item.url}`;
      // }else{
        this.preview_img_src = `${this.$file_url}${item.url}`;
      // }
      this.$nextTick(function(){
        this.$refs.image.clickHandler();
        this.setImgZindex();
      })
      // window.open(`${this.$file_url}${item.url}`, '_blank');
    },
    previewOffice(item){
      const basic_url = 'https://view.officeapps.live.com/op/view.aspx?src=';
      // console.log(`${basic_url}${this.$file_url}${item.url}`)
      window.open(`${basic_url}${this.$file_url}${item.url}`, '_blank')
    },
    previewPdf(item){
      window.open(`${this.$file_url}${item.url}`)
    },
    async downloadFile(item) {
      // const res = await this.checkFile(item)
      // if (!res) {
      //   return
      // }
      oss.downloadFile({ file:item })
      return
      // oss.downloadFile({ file:item })
      // return
      // const {  msg } = this;
      // const {createTime} = msg;
      let { id } = item;
      // const filetype = item.type;
      // const critical = this.$moment('2021-09-08 10:56').format('x'); // 临界时间 // 以发布日期为准
      // let type = filetype == 'pic' ? 1 : 2;
      let type = 2;
      // if(createTime < Number(critical)){
        // type = 2;
      // }
      let exportURL = `https://${this.$domain_name}/project/download?fileid=${id}&type=${type}`;
      // console.log(exportURL);
      // location.href
      // // window.open(exportURL, "_blank"); // 下载打开新窗口
      // window.location = exportURL; 
      // return;
      
      // iframe下载
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = exportURL; 

      // // console.log(createTime,critical ,exportURL,item)
      // return;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(()=>{
        iframe.remove();
      }, 5 * 60 * 1000);
    }
  },
  mounted() {
    this.generateFileList();
  },
}
</script>
<style lang="scss" scoped>
@mixin icon_type($type){
  &.#{$type}{
    background-image: url(~@/assets/images/dialog/sketch/#{$type}.png);
  }
}
.sketch_file_page{
  .list{
    max-width: 650px;
  }
  .pack_up{
    font-size: 12px;
    color:$main_blue;
    line-height: 20px;
    padding-top: 10px;
  }
  .icon{
    @include background(16px, 16px);
    margin-right: 6px;
    width: 16px;
    height: 20px;
    // 'doc','xls', 'ppt','mp4', 'pic', 'zip', 'pdf', 'unknown'
    @include icon_type(doc);
    @include icon_type(xls);
    @include icon_type(ppt);
    @include icon_type(mp4);
    @include icon_type(pic);
    @include icon_type(zip);
    @include icon_type(pdf);
    @include icon_type(unknown);
  }
  .name{
    color: #555;
    font-size: 14px;
    line-height: 20px;
  }
  .download_icon{
    @include transition;
    @include background(14px, 14px);
    background-image: url(~@/assets/images/dialog/sketch/download.png);
    width: 20px;
    height: 20px;
    opacity: 0;
  }
  .info_group{
    @include bbox;
    padding-right: 10px;
  }
  .more{
    line-height: 20px;
    padding-top: 10px;
    font-size: 12px;
    color: $main_blue;
  }
  .file{
    margin-top: 10px;
    &:last-child{
      // margin-top: 0;
    }
    &:hover{
      .download_icon{
        opacity: 1;
      }
    }
  }
}
</style>