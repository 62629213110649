import { PTaskLog } from '@/types'
import pushTask from './pushTask'

class Share {
  receive = (params) => {
    console.log(params)
    if (!params) {
      return
    }
    const { ext } = params ?? {}
    if (!ext) {
      return
    }
    if (!ext.pTaskLog) {
      return
    }
    console.log(ext.pTaskLog,(JSON.parse(ext.pTaskLog)))
    const pTaskLog = new PTaskLog(JSON.parse(ext.pTaskLog))
    pushTask.pushTaskLog({ ext, pTaskLog })
    pushTask.pushMessageBoxList({ ext, pTaskLog })
  }
}

const share = new Share()

export default share
