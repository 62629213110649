var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sign" }, [
    _c("div", { staticClass: "mask" }),
    _c(
      "div",
      { staticClass: "sign-con" },
      [_c("router-view", { staticClass: "router_view" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }