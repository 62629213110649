<template>
  <div class="v_pc_gift_card_plugins_save_poster_main bbox">
    <div class="poster_group bbox d-flex flex-column align-center" id="poster">
      <div class="card_group bbox">
        <div class="text_group">
          <template
            v-for="(item, index) in sendword"
          >
            <div class="text"
              :key="index"
            >{{item}}</div>
          </template>
        </div>
        <card-component
          width="420px"
          height="244px"
          class="mt-10"
          v-if="pcarduser"
          :pcard="pcarduser.card"
          :pcarduser="pcarduser"
          :show_indate="false"
          :term-of-validity="String(pcarduser.getTime)"
          title_size="28px"
        ></card-component>
        <div class="qrcode_group d-flex align-stretch bbox mt-20 align-self-start">
          <img
            :src="img_url"
            alt=""
            class="qrcode mr-16"
          >
          <div class="info_group d-flex flex-column justify-space-between align-start">
            <div class="title_group">
              <div class="title">长按或扫描二维码</div>
              <div class="title">领取权益</div>
            </div>
            <!--<div class="sub">请在有效期内领取</div>-->
            <div class="sub">
              请在 {{$moment(pcarduser.getTime).format('YYYY-MM-DD')}} 之前领取
            </div>
          </div>
        </div>
      </div>
      <div class="hint_group bbox">
        <div class="hint">温馨提示：</div>
        <div class="hint">1、每张礼品卡仅限一人领取使用。</div>
        <div class="hint">2、礼品卡是妙行推出的具有不同权益的虚拟卡，包含的权益以卡面信息为准。</div>
      </div>

    </div>
    <div class="button_group bbox">
      <div class="button btn_dark"
        @click.stop="doSave()"
      >保存海报</div>
    </div>
  </div>
</template>

<script>
import files from '@/utils/files'
import CardComponent from '@/views/PersonalCenter/GiftCard/Modules/Card.vue'
import qrcode from 'qrcode'
export default {
  components: {
    CardComponent
  },
  props: {
    pcarduser: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return {
      img_url: '',
    }
  },
  computed: {
    sendword(){
      const {pcarduser} = this
      if(!pcarduser){
        return ''
      }
      const {sendword} = pcarduser
      return sendword.split('\n')
    },
  },
  methods: {
    init(){
      this.generateImgUrl()
    },
    generateImgUrl(){
      const {pcarduser} = this
      const {orcodeurl} = pcarduser
      qrcode.toDataURL(orcodeurl, {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        margin: 2,
      })
      .then(url => {
        // console.log(url)
        this.img_url = url
      })
      .catch( (err) => {
        console.error(err)
      })
    },
    doSave(){
      files.downloadElement({
        querySelector:'#poster',
        name: '妙行用户权益礼品卡'
      })
    },
    copyCanvas(canvas){
      // console.log(canvas)
      if(canvas){
        const src = canvas.toDataURL()
        this.copyImage(src)
      }
    },
    copyImage(url){
      const image = new Image()
      // 执行跨域请求获取图片
      // image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        // 使用canvas 将图片转换成为image/png的blob数据
        // console.log(canvas)
        canvas.toBlob(function (data) {
          // clipboard.write 仅支持image/png的blob数据
          navigator.clipboard.write([
            new window.ClipboardItem({
              [data.type]: data,
            }),
          ])
        }, 'image/png')
      }
      image.src = url
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_plugins_save_poster_main{
  // height: 100%;
  overflow: auto;
  .poster_group{
    width: 100%;
    padding: 20px;
    background-color: #E5EDF6;
  }
  .card_group{
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
  }
  .text_group{
    .text{
      line-height: 36px;
      font-size: 18px;
      color: #333;
      // text-decoration: underline;
      // text-underline-offset: 10px;
      // text-decoration-color: #868686;
      // text-decoration-style: dashed;
    }
  }
  .qrcode_group{
    margin-left: 20px;
    .qrcode{
      background-color: #ccc;
      width: 90px;
      height: 90px;
    }
    .info_group{
      .title_group{
        font-size: 18px;
        color: #002141;
        line-height: 24px;
      }
      .sub{
        font-size: 14px;
        color: #82929C;
        line-height: 19px;
      }
    }
  }
  .hint_group{
    width: 100%;
    padding: 16px 25px 0;
    font-size: 14px;
    color: #82929C;
    line-height: 24px;
  }
  .button_group{
    padding: 0 25px;
    .button{
      width: 100%;
      text-align: center;
      border-radius: 4px;
      font-size: 16px;
      line-height: 44px;
    }
  }
}
</style>

