/*
 * @Author       : Hugo
 * @Date         : 2021-11-19 10:49:05
 * @LastEditTime : 2021-11-19 10:50:25
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/store/stateModule/user.js
 * @Description  : 
 * @^_^          : 
 */
const user = {
  // 用户数据更新
  user_data_update: false, 
}

export default user;