/*
 * @Author       : Hugo
 * @Date         : 2021-04-09 15:19:48
 * @LastEditTime : 2022-07-01 00:46:23
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/utils/regexp.js
 * @Description  : 正则
 * @^_^          : 
 */
const REGEXP = {
  // 正整数
  positive_integer: /^[0-9]*[1-9][0-9]*$/,
  // 邮箱
  email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  // 手机号
  phone: /^1[3456789]\d{9}$/,


}

export default REGEXP;
