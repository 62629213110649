<template>
  <div
    class="c_pop_up_migrate_task_select_task_task_list d-flex align-center flex-wrap"
  >
    <template
      v-for="(item, index) in list"
    >
      <thumbnail-task
        :key="index"
        :p-task="item"
        :page-data="pageData"
      ></thumbnail-task>
    </template>
  </div>
</template>

<script>
import ThumbnailTask from './ThumbnailTask'
export default {
  components: {
    ThumbnailTask
  },
  props: {
    params: {
      type: Object,
      default: undefined
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    pageData: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">

</style>