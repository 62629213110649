<!--
 * @Author       : Hugo
 * @Date         : 2021-12-15 12:19:23
 * @LastEditTime : 2021-12-15 14:49:53
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/PaymentStatus/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <payment-status
      :title            = "title"
      :close            = "close"
      :jump             = "jump"
      :jump_second      = "jump_second"
      :jump_name        = "jump_name"
      :jump_router_name = "jump_router_name"
      :type             = "type"
      :status           = "status"
      @handleClose      = "handleClose"
    ></payment-status>
  </div>
</template>

<script>
import PaymentStatus from '@/components/PopUp/PaymentStatus/index.vue';
export default {
  components: {
    PaymentStatus,
  },
  data(){
    return{
      visible         : false,
      title           : undefined,
      close           : false,
      jump            : false,
      jump_second     : 5,
      jump_name       : undefined,
      jump_router_name: undefined,
      type            : 'member',
      status          : 'success',
    }
  },
  methods: {
    handleClose(){
      this.visible = false;
    }
  }
}
</script>