/*
 * @Author      : Hugo
 * @Date        : 2022-03-04 13: 45: 21
 * @LastEditTime: 2022-05-16 11:24:20
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/types/modules/PTaskDetail/index.js
 * @Description : 
 * @^_^         : 
 */
import common from '@/api/modules/task_fun/common'
import tools from '@/api/tools'
import global from '@/api/global'
import vue from '@/main'
import {PScreen, listForClasses, PFile} from '@/types'
import types from '@/utils/types'
import store from '@/store'
class PTaskDetail{
  address             = undefined
  coder               = undefined
  content             = undefined
  controlLinkJson     = undefined
  controlLinkList     = undefined
  countdown           = undefined
  detailsLevel        = undefined
  fileJson            = undefined
  fileList            = undefined
  id                  = undefined
  islink              = undefined
  islock              = undefined
  linkId              = undefined
  provinceCoder       = undefined
  remark              = undefined
  remindList          = undefined
  screens             = undefined
  screenList          = undefined
  screenuserid        = undefined
  screenstarttime     = undefined
  screenstatus        = undefined
  screenusername      = undefined
  screenuserthumbnail = undefined
  sort                = undefined
  sortJson            = undefined
  taskId              = undefined
  timetype            = undefined
  type                = undefined
  unit                = undefined
  updatenum           = undefined
  userList            = undefined
  userlock            = undefined
  userId              = undefined
 





  /**自定义字段 custom field */
  cf_edit_data     = undefined  // 编辑字段
  modified         = undefined
  repeal_content   = undefined
  widget_sort      = undefined  // 排序 [即将废弃]
  switch_relevancy = undefined
  create_num       = undefined  // 创建的索引 无id时使用


  constructor(params={}){
    const {
      address,
      coder,
      content,
      controlLinkJson,
      controlLinkList, // TODO types.transformObjectForList 转换
      countdown,
      detailsLevel,
      fileJson,
      fileList,
      id,
      islink,
      islock,
      linkId,
      provinceCoder,
      remark,
      remindList,
      screens,
      sort,
      sortJson,
      taskId,
      timetype,
      type,
      unit,
      updatenum,
      userList,
      userlock,
      screenList,
      screenuserid,
      screenstarttime,
      screenstatus, // 0未投屏 1投屏中
      screenusername,
      screenuserthumbnail,
      userId,

      cf_edit_data,
      modified,
      repeal_content,
      widget_sort,
      create_num,

      
    } = params
    // // console.log(params)
    this.address             = address
    this.coder               = coder
    this.content             = content
    this.controlLinkJson     = controlLinkJson
    this.countdown           = countdown
    this.detailsLevel        = detailsLevel
    this.fileJson            = fileJson
    // this.fileList            = fileList
    this.id                  = id
    this.islink              = islink
    this.islock              = islock
    this.linkId              = linkId
    this.provinceCoder       = provinceCoder
    this.remark              = remark
    this.remindList          = remindList
    this.screens             = screens
    this.sort                = sort
    this.sortJson            = sortJson
    this.taskId              = taskId
    this.timetype            = timetype
    this.type                = type
    this.unit                = unit
    this.updatenum           = updatenum
    this.userList            = userList
    this.userlock            = userlock
    this.userId              = userId
    this.cf_edit_data        = cf_edit_data
    this.modified            = modified
    this.repeal_content      = repeal_content
    this.widget_sort         = widget_sort
    this.screenuserid        = screenuserid
    this.screenstarttime     = screenstarttime
    this.screenstatus        = screenstatus
    this.screenusername      = screenusername
    this.screenuserthumbnail = screenuserthumbnail
    this.create_num          = create_num
    // this.controlLinkList     = controlLinkList
    if(controlLinkList && controlLinkList.length){
      this.controlLinkList = types.transformObjectForList({
        list: controlLinkList,
        obj : PTaskDetail
      })
    }else{
      this.controlLinkList = []
    }


    if(screenList && screenList.length){
      this.screenList = types.transformObjectForList({
        list: screenList,
        obj : PScreen
      })
    }else{
      this.screenList = []
    }

    if(fileList && fileList.length){
      this.fileList = listForClasses({
        list: fileList,
        classes: PFile,
      })
    }else{
      this.fileList = []
    }

  }

  getLock = async () => {
    const userid = tools.getUserId()
    const { id, userlock, updatenum } = this
    if ( id && (Number(userlock) !== Number(userid))) {
      const res = await global.doPost({
        url: '/projectTask/getlock',
        data: {
          id,
          updatenum
        }
      })
      if(res.message === 'success'){
        this.userlock = userid
        this.islock = 1
        return true 
      }else if(res.message === 'updated'){
        // 更新控件内容
        await this.updateControlData(res);
        return false
      }else{
        this.islock = 1
        vue.$notice({
          desc: res.describe
        })
        return false
      }
    } else {
      return true
    }
  }

  /**
   * @description: 更新控件数据
   * @param {*}
   */
  updateControlData = async (res) => {
    vue.$openConfirm({
      title:'',
      desc:'控件内容已被 更新',
      sure:'确定',
      cancel:'我再看看',
      onSure:()=>{
        let data = res.contents.PTaskDetail;
        // console.log(data);
        for(let i in data){
          // vue.$set(item, i, data[i]);
          this[i] = data[i]
        }
        // vue.$set(item, 'repeal_content', true);//重绘
        if(data.type == 9){
          vue.$nextTick(function(){
            vue.$redrawVueMasonry(); // 重绘
          })
        }
        // 更新超链接
        // if(data.type == 7){
        //   this.$refs.control_hyperlink.updateContent(data.content);
        // }
      },
      // onCancel:function(){},
    })
  }

  /**
   * 编辑字段
   * @param {*} params 
   */
  edit = (params={})=>{
    const {key, value}   = params
    const {cf_edit_data} = this
    if(!cf_edit_data){
      this.cf_edit_data = {}
    }
    this.cf_edit_data[key] = value
    // // console.log(this)
  }
  /**
   * 修改内容 => 完成
   */
  modify = ()=>{
    const {cf_edit_data} = this
    if(cf_edit_data){
      for(const [key, value] of  Object.entries(cf_edit_data)){
        // // console.log(key, value)
        this[key] = value
      }
    }
    this.modified     = false
    this.cf_edit_data = null
  }
  /**
   * 撤销
   */ 
  cancel = () => {
    this.modified     = false
    this.cf_edit_data = null
  }


  generateRequestData = () => {
    const data           = tools.deepClone(this)
    const {cf_edit_data} = data
    if(cf_edit_data){
      for(const [key, value] of Object.entries(cf_edit_data)){
        data[key] = value
      }
    }
    return data
  }
  /**
   * 是否可以投屏
   * @returns 
   */
  canProjectionScreen = () => {
    const {cf_edit_data, screens, countdown, content, id} = this
    // console.log(this)
    if((cf_edit_data?.screens ?? screens ?? '') === ''){
      return false
    }
    if((cf_edit_data?.countdown ?? countdown ?? '') === '' || (cf_edit_data?.countdown ?? countdown ?? '') == 0){
      return false
    }
    if((cf_edit_data?.content ?? content ?? '') === ''){
      return false
    }
    return true
  }
  /**
   * 投屏
   */
  projectionScreen = async () => {
    const {cf_edit_data, screens, countdown, content, id} = this
    // console.log(this, cf_edit_data?.countdown ?? countdown)
    if((cf_edit_data?.screens ?? screens ?? '') === ''){
      vue.$notice({desc: '请选择屏幕'})
      return
    }
    if((cf_edit_data?.countdown ?? countdown ?? '') === '' || (cf_edit_data?.countdown ?? countdown ?? '') == 0){
      vue.$notice({desc: '请选择时间'})
      return
    }
    if((cf_edit_data?.content ?? content ?? '') === ''){
      vue.$notice({desc: '请输入任务描述'})
      return
    }
    if(id){
      const res = await global.doPost({
        url : '/screen/screenShow',
        data: {
          conIds: id,
        },
      })
      if(res.message === 'success'){
        vue.$notice({
          desc: '正在发送投屏请求',
          type: 'success',
        })
        this.projectionScreenStart()
        if(res?.contents?.taskLogs && res?.contents?.taskLogs?.length ){
          for(let i in res.contents.taskLogs){
             // 消息列表追加
            common.pushMessageList(res.contents.taskLogs[i])
            // 消息盒子追加
            common.pushMessageBox(Object.assign(
              {},
              res.contents.taskLogs[i],
            ))
          }
         
        }
      }
    }
  }
  /**
   * 投屏开始 screenList增加当前控件信息
   */
  projectionScreenStart = async () => {
    const {screenList, id:taskDetailId} = this
    const {userId, thumbnail, userName,}           = tools.getCookie('user')
    const new_list                      = []
    for(const unit of screenList){
      new_list.push(new PScreen(Object.assign(
        {},
        unit,
        {
          screenTask: {
            taskDetailId,
            userId,
            thumbnail,
            endFlag: 1,   // 开始投屏
          },
          useflag     : 1,
        }
      )))
    }
    this.screenstatus = 1;
    this.screenuserid = userId;
    this.screenusername = userName;
    this.screenuserthumbnail = thumbnail;
    vue.$set(this, 'screenList', new_list)
    console.log(this)

  }

  /**
   * 结束投屏
   */
  endForScreen = async () => {
    const {id} = this
    const res  = await global.doPost({
      url : '/screen/endShowTaskByUserCon',
      data: {
        id,
      }
    })
    if(res.message === 'success'){
      this.projectionScreenEnd()
      if(res?.contents?.taskLog){
        // 消息列表追加
        common.pushMessageList(res.contents.taskLog)
        // 消息盒子追加
        common.pushMessageBox(Object.assign(
          {},
          res.contents.taskLog,
        ))
      }
      
    }
    return res
  }

  projectionScreenEnd = async ()=> {
    const {screenList} = this
    const new_list     = []
    for(const unit of screenList){
      new_list.push(new PScreen(Object.assign(
        {},
        unit,
        {
          screenTask: {
            undefined,
          },
          useflag: 0,
        }
      )))
    }
    this.screenstatus = 0;
    // this.screenuserid = userId;
    // this.screenusername = userName;
    // this.screenuserthumbnail = thumbnail;
    vue.$set(this, 'screenList', new_list)
  }

  /**
   * 获取投屏用户数据
   * @returns 
   */
  getScreenUserData = () => {
    const {screenuserid:userId,screenuserthumbnail:thumbnail,screenusername:userName, screenList, id} = this
    return {
      userId,
      thumbnail,
      userName
    }
    // if(!screenList || screenList.length === 0){
    //   return false
    // }
    // const aim = screenList.find(item=>{
    //   const {screenTask} = item

    //   return screenTask.taskDetailId === id

    // })
    // if(aim !== undefined){
    //   // console.log(aim)
    //   return aim.screenTask
    // }
    // return false
  }
  


}

export default PTaskDetail