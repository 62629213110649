var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search_box" },
    [
      _c("img", {
        staticClass: "search_icon cp",
        attrs: {
          src: require("@/assets/images/common/search_icon.png"),
          alt: "",
        },
      }),
      _c("el-input", {
        staticClass: "search",
        attrs: { placeholder: "搜索" },
        nativeOn: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.doSearch($event)
          },
        },
        model: {
          value: _vm.input,
          callback: function ($$v) {
            _vm.input = $$v
          },
          expression: "input",
        },
      }),
      _c("img", {
        staticClass: "clear_icon cp",
        attrs: {
          src: require("@/assets/images/common/clear_icon.png"),
          alt: "",
        },
        on: {
          click: function ($event) {
            return _vm.doClear()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }