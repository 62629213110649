/*
 * @Author       : Hugo
 * @Date         : 2022-04-25 14:11:45
 * @LastEditTime : 2022-06-17 12:40:05
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/utils/files.js
 * @Description  : 
 * @^_^          : 
 */
import html2canvas from 'html2canvas'
class Files{
  /**
   * 下载元素
   */
  downloadElement(params){
    const {querySelector, name} = params
    const ele = document.querySelector(querySelector)
    html2canvas(
      ele,
      {
        useCORS: true,
        allowTaint: false,
        proxy: 'https://img-content.ourutec.com/'
      }
    ).then((canvas) => {
      console.log(canvas)
      // document.body.appendChild(canvas);
      this.downloadCanvas({canvas, name: name ?? '海报'})

    })
  }

  /**
   * 拷贝元素
   */
  copyElement(querySelector){
    const ele = document.querySelector(querySelector)
    html2canvas(
      ele,
      {
        // useCORS: true,
        allowTaint:true,
      }

    ).then((canvas) => {
      // console.log(canvas)
      this.copyCanvas(canvas)

    })
  }

  /**
   * 获取图片的 base64 编码
   * @param image 图像对象
   * @return {string} 返回已编码的 base64数据
   */
  getImageBase64 = (image) => {
    // console.log(image)
    image.setAttribute('crossOrigin', 'Anonymous');
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, image.width, image.height)
    // 获取图片后缀名
    const extension = image.src
      .substring(image.src.lastIndexOf('.') + 1)
      .toLowerCase()
    // 某些图片 url 可能没有后缀名，默认是 png
    return canvas.toDataURL('image/' + extension, 1)
  }

  /**
   * 单张图片下载
   * @param url 图像链接
   * @param name 图片名称
   */
  downloadImage = (params) => {
    console.log(params)
    const {url, name} = params
    const link = document.createElement('a')
    link.setAttribute('download', name)
    const image = new Image()
    // 添加时间戳，防止浏览器缓存图片
    // image.src = url
    // 设置 crossOrigin 属性，解决图片跨域报错
    // image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
      console.log('onload')
      link.href = this.getImageBase64(image)
      link.click()
      image.remove()
    }
  }

  downloadCanvas = (params) => {
    const {canvas, name} = params
    const url = canvas.toDataURL()
    var MIME_TYPE = "image/png"
    var dlLink = document.createElement('a')
    dlLink.download = name
    dlLink.href = url
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':')
    document.body.appendChild(dlLink)
    dlLink.click()
    document.body.removeChild(dlLink)
    // this.downloadImage({url, name})
  }

  urlToBase64 = (url, cb) => {
    // console.log(url)
    const image = new Image()
    // 执行跨域请求获取图片
    image.setAttribute('crossOrigin', 'anonymous')
    image.crossOrigin = 'Anonymous'
    // image.src = url + '?timestamp=' + Date.now()
    image.src = url
    image.onload = function () {
      console.log('urlToBase64')
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      // 使用canvas 将图片转换成为image/png的blob数据
      // console.log(canvas)
      // console.log(canvas.toDataURL())
      if(cb){
        cb(canvas.toDataURL())
      }
      // canvas.toBlob(function (data) {
      //   // console.log(data)
      //   // clipboard.write 仅支持image/png的blob数据
      //   // navigator.clipboard.write([
      //   //   new window.ClipboardItem({
      //   //     [data.type]: data,
      //   //   }),
      //   // ])
      // }, 'image/png')
      setTimeout(()=>{
        image.remove()
      },1000)
    }
  }

  copyImage = (url) => {
    // console.log(url)
    const image = new Image()
    // 执行跨域请求获取图片
    // image.setAttribute('crossOrigin', 'anonymous')
    // image.crossOrigin = 'Anonymous'
    // image.src = url + '?timestamp=' + Date.now()
    image.src = url
    image.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      // 使用canvas 将图片转换成为image/png的blob数据
      // console.log(canvas)
      canvas.toBlob(function (data) {
        // clipboard.write 仅支持image/png的blob数据
        navigator.clipboard.write([
          new window.ClipboardItem({
            [data.type]: data,
          }),
        ])
      }, 'image/png')
    }
    
  }



  copyCanvas(canvas){
    // console.log(canvas)
    const url = canvas.toDataURL()
    this.copyImage(url)
  }

}

const files = new Files()

export default files