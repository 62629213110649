<template>
  <div
    class="c_pop_up_member_open_record_index bbox d-flex flex-column"
  >
    <table
      v-if="list && list.length"
      class="table"
      cellpadding="0"
      cellspacing="0"
    >
      <tr>
        <th>
          开通方式
        </th>
        <th>
          天数
        </th>
        <th>
          VIP开通日
        </th>
        <th>
          VIP到期日
        </th>
      </tr>
      <template
        v-for="(item, index) in list"
      >
        <table-line
          :key="index"
          :p-vip-record="item"
        ></table-line>
      </template>
      <tr
        v-if="more || loading"
      >
        <td
          colspan="4"
        >
          <div
            class="more d-flex align-center justify-center"
          >
            <div
              v-show="!loading"
              class="cp"
              @click.stop="loading"
            >
              加载更多
            </div>
            <loading-icon
              v-show="loading"
              :size="'24px'"
            ></loading-icon>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import global from '@/api/global'
import { listForClasses, PVipRecord } from '@/types'
import TableLine from './Line'
import LoadingIcon from '@/components/basic/loadingIcon'
export default {
  name: 'index',
  components: {
    LoadingIcon,
    TableLine
  },
  props: {
  },
  data() {
    return {
      // taskList: [],
      more: false,
      list: [],
      loading: false,
    }
  },
  computed: {
    // taskList() {
    //   const { packageSelectTask } = this
    //   const { selectedList } = packageSelectTask
    //   return tools.deepClone(selectedList)
    // }
  },
  mounted () {
    // console.log(this.taskList)
    this.init()
  },
  methods: {
    async getMore() {
      await this.getList()
    },
    init() {
      this.getList()
    },
    async getList() {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await global.doPost({
        url: '/project/vipRecordList',
        start: this.list.length
      })
      this.loading = false
      const { message, contents } = res
      if (message === 'success') {
        const { list } = contents
        console.log(list)
        console.log(listForClasses({
          list,
          classes: PVipRecord
        }))
        if (list) {
          this.list = this.list.concat(listForClasses({
            list,
            classes: PVipRecord
          }))
        }
        if (list && list.length === 20) {
          this.more  = true
        } else{
          this.more = false
        }
      }
      console.log(res)
      console.log(this.list)
    }
  }
}
</script>

<style scoped lang="scss">
.c_pop_up_member_open_record_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  .table{
    width: 100%;
    tr:nth-of-type(even){
      background-color: rgba(229, 238, 246, 0.5);
    }
    th{
      padding: 0;
      line-height: 50px;
      border-right: 1px solid #E7EAED;
      border-bottom: 1px solid #E7EAED;
      &:last-child{
        border-right: none;
      }
    }
    .more{
      width: 100%;
      height: 40px;
      line-height: 30px;
      color: $main_blue;
      text-align: center;
    }
  }
}
</style>