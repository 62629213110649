export {
  listConvertToModel,
}

/**
 * 将列表转化为Class
 * @param params
 * @param params.list 列表
 * @param params.module Class
 *
 * @returns {*[]|*}
 */
function listConvertToModel (params) {
  if (!params) {
    return []
  }
  const { list, model } = params ?? {}
  if (!list) {
    return []
  }
  if (list.length === 0) {
    return []
  }
  if (!model) {
    return list
  }
  const modelList = []
  list.forEach((item) => {
    const new_classes = new model(item)
    modelList.push(new_classes)
  })
  return modelList
}
