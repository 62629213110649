<template>
    <!-- 中心版块 -->
    <div class="sign-con">
        <!-- 中心左边版块 -->
        <div class="sign-conl"></div>
        <!-- 中心左边版块logo部分 -->
        <div class="sign-conc">
            <div class="sign-contop">
                <img style="top:1px" src="../../assets/logo.png" alt class="logo" />
                <h2>妙行 | 让沟通更专业</h2>
            </div>
            <h3>WELCOME</h3>
            <p class="wb">Project Management Communication System</p>
        </div>
        <!-- 左边版块 -->
        <div class="sign-conr">
            <!-- 用户信息 -->
            <div class="right">
                <!-- <p class="text">请输入手机号码</p> -->
                <div class="i-box">
                    <img src="../../assets/icon-mobile.png" alt class="img2" />
                    <input class="phone" type="text" v-model="userName"
                      placeholder="请输入手机号码"
                      maxlength="11"
                    />
                    <img src="../../assets/icon-shut.png" alt class="shut cp" @click="shut" v-show="userName" />
                    <!-- 输入密码 -->
                </div>
                <div class="line"></div>
                <!-- <p class="text">请输入密码</p> -->
                <div class="i-box" style="margin-top:56px;">
                    <img src="../../assets/icon-password.png" class="img2" alt />

                    <div v-if="sel">
                        <input class="password" :type="types" v-model="password" @keyup.enter="login"
                          placeholder="请输入密码"
                        />

                        <img src="../../assets/icon-sel.png" alt @click="showps" class="sel cp" v-show="password" />
                    </div>
                    <div v-else>
                        <input class="password" :type="types" v-model="password" />
                        <img src="../../assets/icon-eye.png" alt @click="showps" class="sel cp" v-show="password" />
                    </div>
                </div>
                <div class="line1"></div>
                <p v-html="userNameWarn" class="cuowu"></p>
                <div class="box">
                    <!-- <button class="login">登录</button> -->
                    <el-button class="login_btn" @click.native="login" type="primary" round :loading="isBtnLoading">登录</el-button>
                    <!-- <button class="forgetps">忘记密码</button> -->
                    <el-button class="forgetps_btn" @click.native="forgetPassword" type="primary" round :loading="isBtnLoading">忘记密码</el-button>
                </div>
                <div class="rudeu">
                    <!-- <input type="radio" name="sex" value="n" class="sex" /> -->
                    <!-- <img src="../assets/icon-nor.png" alt="" @click="nor" class="nor"> -->
                    <p class="automatic">30天内自动登录</p>
                    <p class="letgo">
                        <!-- 没有账号去 -->
                        <a @click="zhuce" class="cp">立即注册</a>
                        <!-- <span @click="zhuce">注册</span> -->
                    </p>
                    <img v-if="nor" @click="norc" src="../../assets/icon-nor.png" alt class="nor" />
                    <img v-else style="left:-311px" @click="norc" src="../../assets/icon-dui.png" alt class="nor" />
                </div>
            </div>
        </div>
        <identification-code
          v-if="switch_identification_code"
          :loginUserCode="JSON.parse(loginUserCode)"
          :user="user"
          @handleClose="closeIdentificationCode();"
          @handleSuccess="identifySuccess()"
        ></identification-code>
    </div>
</template>
<script>
import IdentificationCode from '@/components/PopUp/identificationCode/index.vue';
export default {
  components: {
    IdentificationCode,
  },
  data() {
      return {
          userName: "",
          password: "",
          types: "password",
          check: false,
          nor: true,
          isBtnLoading: false,
          sel: true,
          userNameWarn: "",

          switch_identification_code: false,
          user: null,
          loginUserCode: '',
      };
  },
  watch:{
    //只能输入数字
    userName: function (val) {
      if(val){
        this.userName = val
          .replace(/[^\d]+/g, "")
          // .replace(".", "$#$")
          // .replace(/\./g, "")
          // .replace("$#$", ".");
      }
    },
  },
  mounted(){
    this.$store.commit("set_login_user_id", '') // 溢出登录用户标记
    //  是否已勾选30天自动登录
    let automatic = this.$tools.getCookie('automatic');
    if(automatic){
      this.nor = false;
    }
  },

  computed: {
      btnText() {
          if (this.isBtnLoading) return "登录中...";
          return "登录";
      }
  },
  methods: {
      async login() {
          const userReg = /^1[3456789]\d{9}$/;
          if(!this.nor){
            this.$cookies.set('automatic', true, '30d');
          }else{
            this.$cookies.remove('automatic'); //del 
          }
          if (userReg.test(this.userName)) {
            this.userNameWarn = "";
            let data = {
              username: this.userName,
              password: this.password,
              logintype: 4,
            }
            // let res = await this.$http.request('/project/loginAuth', data);
            // this.$store.commit('loginInit');
            let loginRes;
            if (this.nor === false) {
              // 自动登录
              loginRes = await this.$api.doLogin(data.username, this.$md5(data.password), true);

            
              
            } else if (this.nor === true) { 
              // // console.log('清除cookie')
              loginRes = await this.$api.doLogin(data.username, this.$md5(data.password), false);
              // console.log(loginRes);
              // if(loginRes == true){
              //   this.$router.push({name:'fun_task_index'});
              // }else{
              //   this.userNameWarn = loginRes.describe;
              // }
            } 
            if (loginRes == true) {
              const { $store } = this
              const { common, state } = $store
              const { toResourceRoute } = state
              if (toResourceRoute) {
                this.$router.push(toResourceRoute)
                common('set_toResourceRoute', undefined)
              }else{
                this.$router.push({name:'fun_task_index'});
              }
            }
            if(loginRes.identification_code) {
              this.switch_identification_code = true;
              const {contents} = loginRes;
              const {loginUserCode, user} = contents;
              this.$set(this, 'loginUserCode', loginUserCode);
              this.$set(this, 'user', user);
            }else{
              this.userNameWarn = loginRes.describe;
            }
          } else {
              this.userNameWarn = "请输入正确的手机号码和密码";
          }
      },
      closeIdentificationCode(){
        this.switch_identification_code = false;
      },
      /**
       * 识别成功
       */
      async identifySuccess () {
        this.closeIdentificationCode()
        this.$showLoading({title: '加载中'})
        await this.login()
        this.$hideLoading()
      },
      forgetPassword() {
          this.$router.push("/login/forget")
      },

      shut() {
          this.$data.userName = "";
      },
      showps() {
          this.$data.types = this.$data.types === "password" ? "text" : "password";
          this.$data.sel = !this.$data.sel;
      },
      norc() {
          this.$data.nor = !this.$data.nor;
      },
      zhuce() {
          this.$router.push("/login/register")
      }
  }
};
</script>

<style lang='less' scoped>
.sign-con {
    width: 1200px;
    height: 650px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    position: relative;

    .sign-conc {
        // display: flex;
        position: absolute;
        // top: 1px;
        width: 476px;
        box-sizing: border-box;
        padding-right: 50px;
        .sign-contop {
            display: flex;
            align-items: center;
            margin-top: 60px;
            .logo {
                display: block;
                width: 50px;
                height: 50px;
                
                margin-left: 60px;
            }

            h2 {
                width: 220px;
                // height: 26px;
                color: #ffffff;
                margin-left: 16px;
                // margin-top: 72px;
            }
        }
       
        h3 {
            // width: 168px;
            // height: 23px;
            font-size: 32px;
            font-family: Arial;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            line-height: 38px;
            opacity: 0.8;
            margin-top: 400px;
            // margin-left: 258px;
            width: 100%;
            text-align-last: right;
        }
        .wb {
            // width: 274px;
            // height: 11px;
            font-size: 12px;
            font-family: Arial;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            line-height: 27px;
            opacity: 0.6;
            // margin-left: 166px;
            // margin-top: 21px;
            width: 100%;
            text-align-last: right;
            padding-bottom: 40px;
        }
        // 资源库样式
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    .sign-conl {
        width: 476px;
        height: 650px;
        background: rgba(0, 0, 0, 1);
        opacity: 0.3;
    }

    .sign-conr {
        width: 724px;
        height: 650px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        .right {
            position: relative;
            .text {
                margin-top: 50px;
                width: 126px;
                height: 17px;
                font-size: 18px;
                
                font-weight: 400;
                color: rgba(130, 146, 156, 1);
                margin-bottom: 10px;
            }
            .i-box {
                display: flex;
                position: relative;
                align-items: center;
                padding: 17px 0;
                input {
                    outline: none;
                    display: block;
                    width: 330px;
                    line-height: 30px;
                    font-size: 18px;
                    
                    font-weight: 400;
                    border: none;
                    color: rgba(130, 146, 156, 1);
                    margin-left: 10px;
                }
                .phone{
                  color:#0054A8;
                }

                .img2 {
                    display: flex;
                    width: 18px;
                    height: 22px;
                    margin-top: 10px;
                    margin-bottom: 5px;
                }
                .shut {
                    width: 18px;
                    height: 12px;
                    position: absolute;
                    bottom: 28px;
                    left: 308px;
                }
                .sel {
                    width: 18px;
                    height: 12px;
                    position: absolute;
                    left: 308px;
                    bottom: 28px;
                }
                .password {
                  color:#0054A8;
                    // margin-top: 5px;
                }
            }
            .line {
                width: 330px;
                height: 1px;
                background: rgba(213, 217, 219, 1);
            }

            .line1 {
                width: 330px;
                height: 1px;
                background: rgba(213, 217, 219, 1);
            }
            .cuowu {
                display: block;
                width: 220px;
                height: 15px;
                font-size: 14px;
                color: #d82e0d;
                margin-top: 15px;
            }

            .box {
                margin-top: 50px;

                .login_btn {
                    width: 160px;
                    height: 50px;
                    background: rgba(0, 84, 166, 1);
                    border-radius: 4px;
                    border: none;
                    font-size: 18px;
                    margin-right: 10px;
                    color: rgba(255, 255, 255, 1);
                }
                .login_btn:hover {
                    background: #004080;
                }
                .forgetps_btn {
                     width: 160px;
                    height: 50px;
                    background: #e5eef6;
                    border-radius: 4px;
                    color: rgba(0, 84, 166, 1);
                    font-size: 18px;
                    border: none;
                }
                .forgetps_btn:hover {
                    background: #ccdded;
                }
            }

            .rudeu {
                width: 556px;
                display: flex;
                margin-top: 10px;
                // position: relative;

                .nor {
                    position: relative;
                    left: -311px;
                    margin-top: 2px;
                }

                .sex {
                    width: 16px;
                    height: 16px;
                    margin-top: 2px;
                    // border-radius:50%  ;
                }

                .automatic {
                    margin-left: 25px;
                    width: 114px;
                    height: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(130, 146, 156, 1);
                }

                .letgo {
                    margin-left: 56px;
                    width: 117px;
                    height: 17px;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: underline;
                    color: rgba(130, 146, 156, 1);
                    // text-align: center;

                    a {
                        color: #0054a6;
                    }
                }
            }
        }
    }
}
</style>