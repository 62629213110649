var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.task
    ? _c(
        "div",
        {
          staticClass:
            "components_task_main_linebar_task_title_page bbox d-flex align-center",
        },
        [
          _c("div", { staticClass: "icon flex-shrink-0" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.task.name,
                expression: "task.name",
              },
            ],
            staticClass: "title",
            attrs: { type: "text", placeholder: "输入任务标题" },
            domProps: { value: _vm.task.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.task, "name", $event.target.value)
              },
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }