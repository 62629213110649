const funArgumentedReality = [
  {
    path: "/funArgumentedReality",
    name: "fun_argumented_reality",
    component: () => import('@/views/layout/default.vue'),
    meta: {allowBack: false},
    // redirect: 'fun_argumented_reality_index',
    children: [
      {
        path: "index",
        name: "fun_argumented_reality_index",
        components: {
          default: ()=> import('@/views/ArgumentedReality/index.vue'),
        },
        meta: {
          allowBack: false,
          hideSidebar: true
        },
        children: [
          {
            path: 'edit/:id',
            name: 'AREdit',
            components: {
              default: () => import('@/views/ArgumentedReality/Edit/index.vue')
            },
            meta: {
              allowBack: false,
              hideSidebar: true
            },
          },
          {
            path: 'scheme',
            name: 'ARScheme',
            components: {
              default: () => import('@/views/ArgumentedReality/Scheme/index.vue')
            },
            meta: {
              allowBack: false,
              hideSidebar: true
            },
          },
          {
            path: 'schemelist/:id',
            name: 'ARSchemeList',
            components: {
              default: () => import('@/views/ArgumentedReality/Scheme/index.vue')
            },
            meta: {
              allowBack: false,
              hideSidebar: true
            },
          }
        ],
      },


    ]
  }
]

export default funArgumentedReality