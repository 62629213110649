import axios from "axios";
import config from "./config/config"; // application/x-www-form-urlencoded
import collocate from "./config/collocate";

const forget = {
  /*********************************************** GET ******************************************************/

  // 发送登录短信验证码
  sendForgetPhone(params) {
    // return axios.get('/api/auth/login/v1/sms/send/phone/' + params, config)
  },

  /*********************************************** POST ******************************************************/

  //  获取图形验证码
  forgetForm(params) {
    return axios.post("/verify/execute.htm", params, config);
  },

  //§3.1.2 获取手机验证码
  getSendAuthcode(params) {
    return axios.post(`/project/sendAuthcode`, params, config);
  },

  // §3.1.3 验证验证码
  getConfirmAuthCode(params) {
    return axios.post(`/project/confirmAuthCode`, params, config);
  },

  sendAuthcode(params) {
    return axios.post("/project/sendAuthcode.htm", params, config);
  },

  //注册
  mobileRegisterGeren(params) {
    // console.log('params', params)
    return axios.post("/project/mobileRegisterGeren", params, config);
  },

  //修改密码
  updatePassword(params) {
    return axios.post("/project/updatePassword.htm", params, config);
  },

  //获取省份
  getProvinceList() {
    return axios.post("/project/getProvinceList.htm", config);
  },
  //完善个人信息
  finishInfo(params) {
    return axios.post("/project/finishInfo.htm", params, config);
  },
  //§3.1.13 联系人列表
  loginUserCode(params) {
    return axios.post("/projectFriends/friendsList",params,config);
  },
    //§3.1.14联系人申请列表
    friendsApplyList(params){
      return axios.post("/projectFriends/ friendsApplyList",params,config)
    },
    //§3.1.15添加好友
    friendsApply(params){
      return axios.post("/projectFriends/ friendsApply",params,config)
    },
    //§3.1.16处理好友申请
    friendsApplyDo(params){
      return axios.post("/projectFriends/ friendsApplyDo",params,config)
    },
    //§3.1.17修改联系人备注
    updateFriend(params){
      return axios.post("/projectFriends/updateFriend",params,config)
    },
    //§3.1.18删除联系人
    deleteFriend(params){
      return axios.post("/projectFriends/deleteFriend",params,config)
    }
};

export default forget;
