import PaymentStatusComponent from '@/components/Plugin/Modules/PaymentStatus/index.vue';

const PaymentStatus = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const PaymentStatusConstructor = Vue.extend(PaymentStatusComponent)

    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new PaymentStatusConstructor()

    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    
    Vue.prototype.$payment_status = (params) => {
      // // console.log(obj)
      const {
        title,
        close,
        jump,
        jump_second,
        jump_name,
        jump_router_name,
        type,
        status,
      } = params;
      instance.title            = title;
      instance.close            = close;
      instance.jump             = jump;
      instance.jump_second      = jump_second;
      instance.jump_name        = jump_name;
      instance.jump_router_name = jump_router_name;
      instance.type             = type;
      instance.status           = status;
      instance.visible          = true;
    }
    Vue.prototype.$close_become_member = () => {
      instance.visible = false;
    }
  }
}

export default PaymentStatus