<!--
 * @Author       : Hugo
 * @Date         : 2021-12-31 11:26:27
 * @LastEditTime : 2022-02-23 10:10:34
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/ConsultingForm/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <consulting-form-page
      
      :title="title"
      :loginUserCode="loginUserCode"
      :user="user"
      @handleClose="handleClose();"
    >
      
    </consulting-form-page>
  </div>
</template>

<script>
import ConsultingFormPage from '@/components/PopUp/identificationCode/Free.vue';
export default {
  components: {
    ConsultingFormPage,
  },
  data(){
    return{
      visible      : false,
      title        : undefined,
      user         : undefined,
      loginUserCode: undefined,
    }
  },
  mounted(){
    // // console.log(this.user);
  },
  methods: {
    handleClose(){
      this.visible = false;
    }
  }
}
</script>