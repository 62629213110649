<!--
 * @Author       : Hugo
 * @Date         : 2021-12-15 10:45:11
 * @LastEditTime : 2021-12-16 10:21:47
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Plugin/Modules/BecomeMember/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    v-if="visible"
  >
    <become-member
      :title="title"
      :renew="renew"
      @handleClose="doClose"
    ></become-member>
  </div>
</template>

<script>
import BecomeMember from '@/components/PopUp/BecomeMember/index.vue';
export default {
  components: {
    BecomeMember,
  },
  data(){
    return{
      visible: false,
      title  : undefined,
      renew  : false,
    }
  },
  mounted(){

  },
  methods: {
    doClose(){
      this.visible = false;
    }
  }
}
</script>
