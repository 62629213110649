var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "forget-con" }, [
    _c("div", { staticClass: "forget-conl" }),
    _vm._m(0),
    _c("div", { staticClass: "forget-conr" }, [
      _vm.show
        ? _c(
            "div",
            [_c("Forget", { on: { findPassword: _vm.findPassword } })],
            1
          )
        : _vm._e(),
      !_vm.show
        ? _c("div", [_c("New", { on: { logIn: _vm.logIn } })], 1)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "forget-conll" }, [
      _c("div", { staticClass: "forget-contop" }, [
        _c("img", {
          staticClass: "logo",
          staticStyle: { top: "1px" },
          attrs: { src: require("../../assets/logo.png"), alt: "" },
        }),
        _c("h2", [_vm._v("妙行 | 让沟通更专业")]),
      ]),
      _c("h3", [_vm._v("WELCOME")]),
      _c("p", { staticClass: "wb" }, [
        _vm._v("Project Management Communication System"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }