import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=3905ffab&scoped=true"
import script from "./Main.vue?vue&type=script&lang=js"
export * from "./Main.vue?vue&type=script&lang=js"
import style0 from "./Main.vue?vue&type=style&index=0&id=3905ffab&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3905ffab",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3905ffab')) {
      api.createRecord('3905ffab', component.options)
    } else {
      api.reload('3905ffab', component.options)
    }
    module.hot.accept("./Main.vue?vue&type=template&id=3905ffab&scoped=true", function () {
      api.rerender('3905ffab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ClassesTask/modules/Plugins/AllTaskUsers/Main.vue"
export default component.exports