import store from '@/store'
class MessageBox {
  clearUnreader = (params = {}) => {
    const { ext } = params
    if (!ext) {
      return
    }
    const {
      chatId,
      chatType
    } = ext
    const { message_box_list } = store.state
    if (!message_box_list || message_box_list.length === 0) {
      return
    }
    const aim = message_box_list.find((item) => {
      return Number(item.chatId) === Number(chatId) && Number(item.chatType) === Number(chatType)
    })
    if (aim) {
      aim.unreadMessageNum = 0
    }
  }
}

const messageBox = new MessageBox()

export default messageBox
