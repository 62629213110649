<!--
 * @Author       : Hugo
 * @Date         : 2021-12-13 15:24:18
 * @LastEditTime: 2022-05-23 16:43:04
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/PopUp/BecomeMember/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="750px"
    height="auto"
    border_radius  = "10px"
  >
    <div class="components_pop_up_become_member_index pb-20 d-flex flex-column"
      v-if="pOrder"
    >
      
      <common-title
        class="flex-shrink-0"
        border_radius  = "10px"
      >
        <slot>
          <div class="custom_title_group d-flex align-center justify-space-between bbox">
            <div class="title_group d-flex align-center">
              <div class="title">{{title ? title : `亲爱的${user.userName}，您正在${renew?'续费':'申请成为'}VIP用户`}}</div>
              <img src="~@/assets/images/common/vip.png" alt="" class="vip">
            </div>
            <div class="close cp" 
              @click.stop="$emit('handleClose')"
            ></div>
          </div>
        </slot>
      </common-title>
      <div class="main bbox">
        <div class="list_group bbox">
          <div class="title">成为VIP用户享受以下功能：</div>
          <div class="entitlemen_table d-flex align-center flex-wrap un_sel">
            <template
              v-for="(item,index) in entitlemen_table"
            >
              <div class="item d-flex align-center"
                :key="index"
              >
                <img 
                  :src="require(`@/assets/images/personal_center/vip/vip${item.pic}.png`)" alt="" class="img mr-10"
                >
                <div class="text">{{item.text}}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="pay_group bbox d-flex mt-10">
          <div class="img_wrapper d-flex align-center justify-center">
            <img :src="`${domain}/code/twoCode?width=120&amp;text=${pOrder.codeUrl}`" alt="" class="img">
          </div>
          <div class="info_group">
            <div class="price_group">
              <span class="text">价格：</span>
              <span class="price">{{pOrder.convertToYuan()}}</span>
              <span class="symbol">元/年</span>
            </div>
            <div class="hint_group d-flex align-center">
              <div class="icon wechat mr-10"></div>
              <div class="text">扫码支付{{pOrder.convertToYuan()}}元</div>
            </div>
          </div>
        </div>
        

      </div>
      <div class="main d-flex align-start justify-space-between bbox"
        v-if="0"
      >
        <div class="hint_group">
          <div class="title pb-20">成为VIP用户后，您将享受以下功能：</div>
          <div class="item">主动创建工作组</div>
          <div class="item">邀请新成员加入工作组</div>
          <div class="item">查看工作组中非联系人成员的个人资料</div>
          <div class="item">任务单的颜色标记功能</div>
          <div class="item">隐私模式发送任务单</div>
          <div class="item">自定义分类管理任务单</div>
          <div class="more"
            @click.stop="switch_member_rights = true;"
          >更多权益…</div>
        </div>
        <div class="payment_group d-flex flex-column align-center bbox">
          <div class="number_group">
            <div class="box">

              <span class="symbol">¥</span>
              <span class="price">{{pOrder.convertToYuan()}}</span>
              <span class="unit">/年</span>
            </div>
          </div>
          <div class="img_group d-flex flex-column align-center">
            <div class="img_wrapper d-flex align-center justify-center">
              <img :src="`${domain}/code/twoCode?width=150&amp;text=${pOrder.codeUrl}`" alt="" class="img">
            </div>
            <div class="info_group pt-10 d-flex align-center">
              <div class="icon wechat mr-10"></div>
              <div class="text">扫码支付{{pOrder.convertToYuan()}}元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <member-rights
      v-if="switch_member_rights"
      @handleClose="closeMemberRights();"
    ></member-rights>
  </common-wrapper>
</template>

<script>
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
import {POrder} from '@/types';
import MemberRights from '@/components/PopUp/MemberRights/index.vue';
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    MemberRights,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    renew: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      user: this.$tools.getCookie('user'),
      pOrder: undefined,
      domain: process.env.VUE_APP_BASEURL,
      switch_member_rights: false,

      entitlemen_table: [
        {text: '查看联系人能力画像的具体数值', pic: 1},
        {text: '创建任务自定义标签', pic: 4},
        {text: '收藏“模板商城”中的任务', pic: 2},
        {text: '在“我收藏的”中创建文件夹', pic: 5},
        {text: '使用“智慧桌”控件', pic: 3},
      ]
    }
  },
  mounted(){
    this.$nextTick(function(){
      this.init();
    })
  },
  methods: {
    async init(){
      await this.getUserBuyVipOrder();
      this.pOrder.pollingMemberStatus(); // 轮询会员订单
    },
    async getUserBuyVipOrder(){
      const res = await this.$global.doPost({
        url: '/order/getUserBuyVipOrder',
        data: {
          timeType: 2,
        }
      })
      const {message, contents} = res;
      if(message === 'success'){
        if(contents && contents.orderYear){
          this.$set(this, 'pOrder', new POrder(contents.orderYear));
        }
      }
      return res;
    },
    closeMemberRights(){
      this.switch_member_rights = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_become_member_index{
  background-color: #fff;
  width           : 100%;
  flex            : 1;
  border-radius: 10px;
  .custom_title_group{
    padding         : 0 40px;
    min-height      : 80px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    .close{
      right: 40px;
    }
  }
  .main{
    flex   : 1;
    width  : 100%;
    padding: 0 20px;
    .list_group{
      width: 660px;
      padding: 20px;
      background: #F8F8F8;
      border-radius: 4px;
      .title{
        font-size: 16px;
        color: #333333;
        line-height: 21px;
        padding-bottom: 20px;
      }
      .entitlemen_table{
        .item{
          width: 49%;
          margin-right: 1%;
          margin-bottom: 20px;
        }
        .img{
          width: 40px;
          height: 40px;
        }
        .text{
          font-size: 14px;
          color: #555555;
          line-height: 19px;
        }
      }
    }
    .pay_group{
      width: 100%;
      padding: 0 10px;
      .img_wrapper{
        // @include background(112px, 112px);
        // width           : 112px;
        // height          : 112px;
        // background-image: url(~@/assets/images/common/qrcode_wrapper.png);
        .img{
          display: block;
          width: 120px;
          height: 120px;
        }
      }
      .info_group{
        .price_group{
          span{
            vertical-align: middle;
            font-size: 16px;
            color: #333;
          }
          .price{
            font-size: 28px;
            font-weight: bold;
            color: #F3580C;
            line-height: 37px;
          }
          .symbol{
            vertical-align: text-top;
            color: #F3580C;
            font-size: 14px;
            line-height: 30px;
          }
        }
        .hint_group{
          font-size: 14px;
          color    : #333;
          .icon{
            width: 20px;
            height: 20px;
            &.wechat{
              background-image: url(~@/assets/images/common/wechat20.png);
            }
          }
        }

      }
    }
  }
  .hint_group{
    color    : #333;
    font-size: 16px;
    .title{

    }
    .item{
      position     : relative;
      color        : #666;
      margin-bottom: 16px;
      padding-left : 16px;
      &::before{
        content         : '';
        display         : block;
        position        : absolute;
        top             : 50%;
        left            : 0px;
        border-radius   : 50%;
        width           : 8px;
        height          : 8px;
        background-color: #999;
        transform       : translateY(-50%);

      }
    }
    .more{
      color: $main_blue;
    }
  }
  .payment_group{
    .number_group{
      color        : #F3580C;
      font-size    : 16px;
      position     : relative;
      margin-bottom: 30px;
      &::before{
        content         : '';
        display         : block;
        position        : absolute;
        top             : -6px;
        bottom          : -6px;
        left            : -9px;
        right           : -9px;
        background-color: #FBEDE7;
        border-radius   : 10px;
        z-index         : 1;
      }
     
      .box{
        position        : relative;
        z-index         : 2;
        height          : 48px;
        padding         : 0 20px;
        background-color: #fff;
        border-radius   : 10px;
        position        : relative;
        z-index         : 2;
       
      }
      span{
        vertical-align: baseline;
      }
      .price{
        font-size: 32px;
        font-weight: bold;
      }
    }
    .img_group{
      .img_wrapper{
        @include background(162px, 162px);
        width           : 162px;
        height          : 162px;
        background-image: url(~@/assets/images/common/qrcode_wrapper.png);
        .img{
          display: block;
        }
      }
      .info_group{
        font-size: 14px;
        color    : #333;
        .icon{
          width: 20px;
          height: 20px;
          &.wechat{
            background-image: url(~@/assets/images/common/wechat20.png);
          }
        }
      }
    }
  }
}
</style>