/*
 * @Author       : Hugo
 * @Date         : 2021-12-23 11:09:38
 * @LastEditTime : 2022-07-20 15:02:57
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/api/modules/shopping_mail/index.js
 * @Description  : 
 * @^_^          : 
 */
import vue from '@/main';
class ShoppongMail{
  getTask = async (params)=>{
    const { taskId, url } = params;
    const res = await vue.$api.moduleTask.getTask({taskId, url, config: {view:true, relevancy: true}});
    return res;
  }

  // 改变一级
  setTopNav = (params={}) => {
    const {type,page_type} = params;
    const {$router, $store} = vue;
    const {state, commit} = $store;
    if(type === 'page'){

      vue.$store.commit('set_spm_nav', [
        ... new Set([
          {type: 'page', page_type}
        ])
      ])
      vue.$router.push({name: `shopping_mail_${page_type}`})
    }
    if(type === 'search'){
      const search_data = {
        name: '搜索',
        only: false,
        type: 'teaching_package',
        open_search: true,
        init_all: true,
        price_type: undefined,
        loading_init: true,
      }
      commit('set_spm_nav', [
        ... new Set([
          {type: 'search', search_data}
        ])
      ])
      $router.push({
        name: 'shopping_mail_search', 
        // params:{
        //   search_data: Object.assign(
        //     {},
        //     search_data,
        //   )}
      })
      // commit('set_spm_search_filter_data', Object.assign(
      //   {},
      //   search_data,
      // ))
      this.saveSearchFilterData(search_data);
    }
  }

  /**追加 */
  pushNav = (params={})=>{
    // console.log(params);
   
    const {type, page_type, search_data, payment, package_data, task_data, lecturer_data} = params;
    const {state, commit } = vue.$store;
    const {spm_nav} = state;
    if(spm_nav!==undefined){

      commit('set_spm_nav', [
        ... new Set([
          ...[],
          ...spm_nav,
          {type, page_type, payment, package_data, task_data, lecturer_data, search_data},
        ])
      ])
    }
    // console.log([
    //   ... new Set([
    //     ...[],
    //     ...spm_nav,
    //     {type, page_type, payment, package_data, task_data, lecturer_data, search_data},
    //   ])
    // ])
   
    // // console.log(task_data)
    // // console.log(task_data?.task?.id)
    // // console.log(task_data?.task?.id ?? task_data.id)
    // 跳转
    if(type === 'task'){
      vue.$router.push({name: 'shopping_mail_task_detail', params: {
        // task_id: task_data.task?.id ?? task_data.id,
        task_id: task_data.caseId ?? task_data.task?.id ?? task_data.id,
        payment: payment ? Date.now() : 0,
      }})
    }
    if(type === 'teaching_package'){
      vue.$router.push({name: 'shopping_mail_teaching_package_detail', params: {
        package_id: package_data.ppackage?.id ?? package_data.id,
      }})
    }
    if(type === 'search'){
      vue.$router.push({
        name: 'shopping_mail_search',
        // params: {search_data},
      })
      commit('set_spm_search_filter_data', Object.assign(
        {},
        search_data,
      ))
      // // console.log(search_data);
      // // console.log(vue.$store.state.spm_search_filter_data)
    }
  }


  changeNav = (params={}) => {
    const {new_nav} = params;
    // console.log(new_nav)
    const last = new_nav[new_nav.length - 1];
    const {type, page_type, payment, package_data, task_data, lecturer_data, search_data, route} = last;
    const {$router, $store} = vue;
    const {state, commit} = $store;
    // const {spm_nav} = state;
    if(type === 'page'){
      this.setTopNav({type,page_type});
    }
    if(type === 'task'){
      vue.$router.push({name: 'shopping_mail_task_detail', params: {
        task_id: task_data.caseId ?? task_data.task?.id ?? task_data.id,
        payment: payment ? Date.now() : 0,
      }})
    }
    if(type === 'teaching_package'){
      // // console.log(package_data?.ppackage?.id);
      // // console.log(package_data?.ppackage?.id ?? package_data.id);
      vue.$router.push({
        name: 'shopping_mail_teaching_package_detail',
        params: {
          package_id: package_data.ppackage?.id ?? package_data.id,
        }
      })
    }
    if(type === 'search'){
      const {spm_search_filter_data} = state;
      $router.push({
        name: 'shopping_mail_search',
        // params: {search_data:Object.assign(
        //   {},
        //   search_data,
        //   spm_search_filter_data, // 使用历史数据
        // )},
        
      })
      // commit('set_spm_search_filter_data', Object.assign(
      //   {},
      //   search_data,
      //   spm_search_filter_data,
      // ))
    }
    if(type === 'route'){
      const {name, params} = route;
      $router.push({name, params});
    }
    last.loading_init = true;
    vue.$store.commit('set_spm_nav', [
      ...new Set([
        ...new_nav,
      ])
    ]);

  }


  saveSearchFilterData(params){
    // console.log(params)
    vue.$store.commit(
      'set_spm_search_filter_data',
      Object.assign(
        {},
        params,
      )
    )
  }

  changeSearchFilterData(params){
    vue.$store.commit(
      'set_spm_search_filter_data',
      Object.assign(
        {},
        vue.$store.state.spm_search_filter_data,
        params,
      )
    )
  }

  saveSearchFilterBackupData(params){
    vue.$store.commit(
      'set_spm_search_filter_backup_data',
      Object.assign(
        {},
        params,
      )
    )
  }

  /**
   * 咨询购买
   * @param {*} params 
   */
  doConsulting(params={}){
    const {title} = params;
    const user = vue.$tools.getCookie('user') ?? undefined;
    vue.$consulting_form({
      title: title ?? '咨询购买',
      user,
      
    });
  }

  /**
   * 备份面包屑导航
   */
  backupNavigation(params={}){
    // 备份导航
    vue.$store.commit('set_spm_prev_nav', [
      ...new Set([
        ...vue.$store.state.spm_nav,
      ])
    ])
  }

  /**
   * 恢复面包屑导航
   * @param {*} params 
   */
  recoverNavigation(params={}){
    if(vue.$store.state.spm_prev_nav!==undefined){
      // 备份导航
      vue.$store.commit('set_spm_nav', [
        ...new Set([
          ...vue.$store.state.spm_prev_nav,
        ])
      ])
    }
  }

  


}

const shopping_mail = new ShoppongMail();

export default shopping_mail;