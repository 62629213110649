<!--
 * @Author       : Hugo
 * @Date         : 2021-12-15 16:48:15
 * @LastEditTime : 2021-12-16 10:06:25
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/PopUp/MemberRights/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <common-wrapper
    max_width="90%"
    max_height="90%"
  >
    <div class="components_pop_up_member_rights_index d-flex flex-column">
      <common-title
        class="flex-shrink-0"
        title="会员权益"
        close
        @handleClose="handleClose();"
      >
      </common-title>
      <div class="main">
        <vip
          :expire_group="false"
        ></vip>
      </div>
    </div>
  </common-wrapper>
</template>

<script>
import Vip from '@/components/PersonalCenter/VipAndConsumption/Vip/index.vue';
import CommonWrapper from '@/components/PopUp/Common/Wrapper';
import CommonTitle from '@/components/PopUp/Common/Title';
export default {
  components: {
    Vip,
    CommonWrapper,
    CommonTitle,
  },
  methods: {
    handleClose(){
      this.$emit('handleClose');
    },
  }
  
}
</script>

<style lang="scss" scoped>
.components_pop_up_member_rights_index{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .main{
    flex: 1;
    width: 100%;
    overflow: auto;
  }
}
</style>