<!--
 * @Author       : Hugo
 * @Date         : 2022-03-14 13:54:47
 * @LastEditTime : 2022-04-02 10:29:14
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/ClassesTask/modules/SideBar/Wrapper.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="components_task_sidebar_page bbox d-flex flex-column flex-shrink-0"
    :class="{close: !switch_sidebar}"
    v-if="list && list.length"
  >
    <div class="open_group"
    >
      <div class="open button"
        v-show="!switch_sidebar"
        @click="changeSwitchSidebar()"
      ></div>
      <div class="close button"
        v-show="switch_sidebar"
        @click="changeSwitchSidebar()"
      ></div>
    </div>
    <fun
      class="fun_component border-bottom"
      :switch_sidebar="switch_sidebar"
      :list="list"
    ></fun>
    <div class="pannel_group"
      v-show="switch_sidebar"
    >
      <widget-container
        :data="data"
        :backup="backup"
        :large_task="large_task"
      ></widget-container>
      <!-- <template
      >
        <chat-container
        ></chat-container>
        <record-container
        ></record-container>
        <teach-container
        ></teach-container>
      </template> -->
    </div>
  </div>
</template>
<script>
import Fun from './Fun.vue';
import WidgetContainer from './Widget/Container.vue';
// import ChatContainer from './Chat/Container.vue';
// import RecordContainer from './Record/Container.vue';
// import TeachContainer from './Teach/Container.vue';
export default {
  components: {
    Fun,
    WidgetContainer,
    // ChatContainer,
    // RecordContainer,
    // TeachContainer,
  },
  props: {
    data: {
      type: Object,
      default: undefined,
    },
    backup: {
      type: Object,
      default: undefined,
    },
    large_task: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return {
      switch_sidebar: true,
      origin_list: [
        {
          type: 'widget',
          name: '控件区',
        },
        {
          type: 'chat',
          name: '讨论区',
        },
        {
          type: 'record',
          name: '历史区',
        },
        {
          type: 'teach',
          name: '教学区',
        },
      ]
    }
  },
  
  computed:{
    list(){
      return [
        {
          type: 'widget',
          name: '控件区',
          active: true,
        },
        {
          type: 'chat',
          name: '讨论区',
        },
        {
          type: 'record',
          name: '历史区',
        },
        {
          type: 'teach',
          name: '教学区',
        },
      ];
     
    },
    // switch_sidebar(){
    //   // const {task_config} = this.$store.state;
    //   // const {switch_sidebar} = task_config;
    //   // return switch_sidebar;
    //   return '';
    // },
    active_list(){
      const list = this.$tools.deepClone(this.list);
      const {type} = this;
      list.map(unit=>{
        if(unit.type == type){
          unit.active = true;
        }
        return unit;
      })
      return list;
    },
    can_teach(){
      const user = this.$tools.getCookie('user');
      const {sign} = user;
      if(sign === 1){
        return true;
      }
      return false;
    },
    
  },
  watch:{
  },
  methods:{
    changeSwitchSidebar(){
      this.switch_sidebar = !this.switch_sidebar
    },
    changePannel(type){
      if(!this.switch_sidebar){
        this.changeSwitchSidebar();
      }
      this.$emit('handleChangePannel', type);
    },
    addNewWidget(){
      this.$emit('handleAddNewWidget');
    },
    handleSavaTask(){
      this.$emit('handleSavaTask');
    }
  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_page{
  // @include transition;
  position: relative;
  z-index: 3;
  // height: 100%;
  width: 400px;
  background-color:#fff;
  &.close{
    width: 54px;
  }
  .open_group{
    position: absolute;
    top:50%;
    left: -19px;
    transform: translateY(-50%);
    .button{
      @include background;
      width: 19px;
      height: 48px;
      &.open{
        background-image: url(~@/assets/images/task/sidebar_open.png);
      }
      &.close{
        background-image: url(~@/assets/images/task/sidebar_close.png);
      }
    }
  }
  .fun_component{
    height: 50px;
  }
  .border-bottom{
    border-bottom: 1px solid #f4f4f4;
  }
  .pannel_group{
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>