/*
 * @Author       : Hugo
 * @Date         : 2022-03-14 11:36:34
 * @LastEditTime : 2022-04-02 10:41:27
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/store/stateModule/ctask.js
 * @Description  : 
 * @^_^          : 
 */
const ctask = {
  // 任务数据
  ctask                     : undefined, 
  ctask_details             : undefined,
  ctask_users               : undefined,
  ctask_read_task_users     : undefined,
  // 备份
  ctask_copy                : undefined,   
  ctask_copy_details        : undefined,
  ctask_copy_users          : undefined,
  ctask_copy_read_task_users: undefined,

  //
  ctask_list: [], //  {data:'数据', backup: '备份'}

}
export default ctask;