import vue from '@/main';
class User{
  /**
   * 查看用户信息 
   * @param {*} params 
   * @returns 
   */
  getInfo = async (params)=> {
    const {userId} = params || {};
    if(userId){
      vue.$showLoading({text: '加载中'});
      let res = await vue.$http.request('/project/getUserById',{userId});
      vue.$hideLoading();
      let userinfo = res.contents.user;
      const {isfriend} = userinfo;

      const already_vip = this.alreadyVip();
      const already_lecturer = this.alreadyLecturer();
      // if(already_vip === false && !already_lecturer && !isfriend && userinfo.userId !== Number(vue.$tools.getUserId())){
      //   // vue.$notice({desc: '您不是会员, 无法查看非联系人成员资料'});
      //   vue.$become_member({
      //     title: '成为VIP用户，解锁更多功能'
      //   })
      //   return;
      // }
      vue.$user_detail({data: userinfo});
    }
  }
  
  /**
   * 会员权限
   * 讲师拥有全部权限
   * @param {*} params 
   */
  membershipPrivileges(params){
    // console.log(params)
    const {hint, notice} = params || {};
    // console.log(notice)
    // notice 是否自动弹窗 默认弹窗
    
    const already_vip = this.alreadyVip();
    // if(!already_vip && hint){
    //   vue.$become_member({
    //     title: '成为VIP用户，解锁更多功能'
    //   })
    // }
    if(!already_vip && (notice === undefined || notice)){
      vue.$openNotMember({hint})
    }
    return already_vip;
  }

  /**
    copy

    const already = this.$api.user.membershipPrivileges({hint: '您不是会员,无法创建工作组'});
    if(already === false){
      return;
    }

   */
  /**
   * 是否是会员
   * @param {*} params 
   * @returns 
   */
   alreadyVip = (params) => {
    // const {hint} = params || {};
    const user = vue.$tools.getCookie('user');
    if(user){
      const {userVip} = user;
      if(userVip === 1){
        return true;
      }
    }
    // if(hint){
    //   vue.$notice({desc: hint||'您不是会员,无法操作'});
    // }
    return false;
  }

  /**
   * 是否讲师
   * @param {*} params 
   * @returns 
   */
  alreadyLecturer = (params) => {
    const user = vue.$tools.getCookie('user');
    if(user){
      const {sign} = user;
      if(sign === 1){
        return true;
      }
    }
    return false;
  }

  /**
   * 刷新用户信息[请求操作]
   */
  refreshUserData = async () => {
    let password = vue.$tools.getCookie('password');
    let user = vue.$tools.getCookie('user');
    let username = user.loginName;
    let data = {
      username,
      password,
      logintype: 4,
    };
    let res = await vue.$http.request('/project/loginAuth', data)
    for(let i in res.contents){
      // vue.$cookies.set(i,res.contents[i]);
      vue.$tools.setCookie(i, res.contents[i])
    }
  }
  /**
   * 更新用户信息
   */
  updateUserData = async () => {
    vue.$store.commit('set_user_data_update', false);
    await this.refreshUserData();
    vue.$store.commit('set_user_data_update', true);
  }

  /**
   * 优先显示名
   * @param {*} params 
   * @returns 
   */
  priorableName = (params={}) => {
    const {userId, chatUserName, friendRemark, userName, updateremark} = params;
    if(!userId){
      return false;
    }
    if(friendRemark !== null && friendRemark !== '' && Number(updateremark) === 1){
      return friendRemark;
    }
    if(chatUserName !== null && chatUserName !== ''){
      return chatUserName;
    }
    if(userName !== null){
      return userName;
    }
    return false;
  }


  
}

const user = new User();

export default user;