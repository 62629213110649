<!--
 * @Author       : Hugo
 * @Date         : 2020-05-27 10:03:11
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-09-10 16:15:33
 * @Description  : 联系人详情
 * @FilePath     : /miaohang/src/components/Contact/ContactDetailBasic.vue
--> 
<template>
  <div class="contact_detail" v-if="userData">
    <div class="name_group">
      <el-image 
        style="width: 90px; height: 90px"
        class="avatar"
        :src="userData.thumbnail ? `${$avatar_url}${userData.thumbnail}@!small200` :  `${$avatar_url}${userData.updateuserthumbnail}@!small200`" 
        :preview-src-list="[userData.thumbnail ? `${$avatar_url}${userData.thumbnail}` :  `${$avatar_url}${userData.updateuserthumbnail}`]"
        :z-index="Number(z_index)"
        @click.native="setImageZIndex()"
      >
      </el-image>
      <img :src="userData.thumbnail ? `${$avatar_url}${userData.thumbnail}` :  `${$avatar_url}${userData.updateuserthumbnail}`" alt="" class="avatar" v-if="0">
      <div class="name_box">
        <div class="name_wrapper">
          <div class="input_name_group">
            <template v-if="!userData.isfriend || is_self">
              <div
                class="name"
              >{{userData.userName}}</div>
              <div
                v-show="!edit_name &&(userData.friendRemark !=''&&userData.friendRemark != null)"
                class="sex"
                :class="{male: userData.userSex=='男', female: userData.userSex=='女'}"
              ></div>
            </template>
            <template v-if="!edit_name && userData.isfriend && !is_self">
              <div
                class="name cp empty"
                @click.stop="triggerInputNameFocus"
                v-show="!edit_name && !userData.updateremark"
              >点击输入备注名</div>
              <div
                class="name cp"
                :class="{empty:(userData.friendRemark ==''||userData.friendRemark == null) && !userData.updateremark}"
                @click.stop="triggerInputNameFocus"
                v-show="!edit_name && userData.updateremark"
              >{{userData.friendRemark || userData.userName}}</div>
              <div
                v-show="!edit_name &&(userData.friendRemark !=''&&userData.friendRemark != null) || userData.updateremark"
                class="sex cp"
                @click.stop="changeUserFriendRemark"
                :class="{male: userData.userSex=='男', female: userData.userSex=='女'}"
              ></div>
            </template>
           
            <!-- 自己或不是好友的 disabled = true -->
            <input
              v-show="edit_name"
              type="text"
              ref="inputname"
              class="input_name"
              v-model="inputname"
              :disabled="userData.userId== $tools.getUserId() || !userData.isfriend"
              :class="{not_friend: !userData.isfriend}"
              @blur="changeUserFriendRemark"
              @keyup.enter="$refs.inputname.blur();"
              maxlength="16"
            >
          </div>
          <div class="gender" v-show="0"></div>
        </div>
        <div class="chat_username"
          v-if="userData.chatUserName"
        >工作组昵称:{{userData.chatUserName}}</div>
        <div class="nickname" v-if="!is_self">昵称: {{userData.userName}}</div>
      </div>
    </div>
    <div class="sign_group" >
      <div class="title">个性签名:</div>
      <div class="sign" v-if="userData.personalDescribe">{{userData.personalDescribe}}</div>
    </div>
    <div class="info_group" v-if="userData.isfriend || is_self">
      <div class="key">机 构:</div>
      <div class="val" v-if="userData.ownedEnterprises">{{userData.ownedEnterprises}}</div>
    </div>
    <div class="info_group" v-if="userData.isfriend || is_self">
      <div class="key">职 务:</div>
      <div class="val" v-if="userData.userPosition">{{userData.userPosition}}</div>
    </div>
    <div class="line" v-show="userData.personalDescribe">
    </div>
    <div class="info_group" v-if="userData.isfriend || is_self">
      <div class="key">妙行号:</div>
      <div class="val">{{userData.phone}}</div>
    </div>
    <div class="info_group" v-show="(userData.isfriend || is_self)">
      <div class="key">姓  名:</div>
      <div class="val">{{userData.userRealName}}</div>
    </div>
    <div class="info_group">
      <div class="key">地 区:</div>
      <div class="val">{{`${userData.province ? userData.province : ''} ${userData.city ? userData.city : ''}`}}</div>
    </div>
    <div class="info_group" v-if="userData.isfriend || is_self">
      <div class="key">邮 箱:</div>
      <div class="val">{{userData.email}}</div>
    </div>
    <div class="line" v-show="0">
    </div>
  </div>
</template>
<script>
export default {
  props:{
    userData:{
      type: Object,
      default: null,
    }
  },
  computed:{
    /**好友列表 */
    friends_list(){
      if(this.$store){
        return this.$store.state.friends_list;
      }
      return [];
    },
    // 是否是自己
    is_self(){
      let userid = this.$tools.getUserId();
      if(userid == this.userData.userId){
        return true;
      }
      return false;
    }
  },
  watch:{
    userData: {
      handler: function(val){
        if(val){
          this.setFriendRemark();
          // if(val && !val.updateremark){
          //   this.inputname = '';
          // }else{
          //   this.inputname = this.friendRemark || (val && (val.friendRemark ?val.friendRemark : val.userName));
          // }
        }
      },
      deep: true,
    },
    friends_list: {
      handler: function(val){
        if(val){
          this.setFriendRemark();
        }
      },
      deep: true,
    }
  },
  data(){
    return{
      inputname: '',
      friendRemark: '',
      edit_name: false,
      z_index: 2000,
    }
  },
  methods:{
    /**
     * @description: 触发输入昵称
     */
    triggerInputNameFocus(){
      let {userData} = this;
      if(this.userData.isfriend){
        this.edit_name = true;
        this.$nextTick(function(){
          if(userData && !userData.updateremark){
            this.inputname = '';
          }else{
            this.inputname = this.friendRemark || (userData && (userData.friendRemark ?userData.friendRemark : userData.userName));
          }
          this.$refs.inputname.focus();
        })
      }
    },
    /**
     * 更改用户昵称
     */
    async changeUserFriendRemark(){
      let data = {
        friendUserId: this.userData.userId,
        friendRemark: this.inputname,
      }
      this.edit_name = false
      if (this.inputname === this.userData.friendRemark) {
        return
      }
      // if(this.inputname === ''){
      //   if(!this.userData.updateremark){
      //     return ;
      //   }
      // }
      // if(this.inputname != this.userData.friendRemark){
        this.$set(this.userData, 'friendRemark', this.inputname)
        this.$set(this.userData, 'updateremark', this.inputname === '' ? 0 : 1)
        await this.$http.request('/projectFriends/updateFriend', data)
        this.$toast({icon:'success',title:'',desc:'修改成功',duration:600})
        this.$api.changeUserFriendRemark({
          friendRemark:this.inputname,
          userId:this.userData.userId
        })
        this.$store.dispatch('get_friends_list'); //刷新用户列表
      // }
      
    },
    /**
     * 初始化时加载用户昵称
     */
    setFriendRemark(){
      let friends_list = this.friends_list;
      if(!this.userData){
        return;
      }
      if(friends_list){
        let friendData = friends_list.find((item)=>{
          return item.friendUserId == this.userData.userId;
        })
        if(friendData){
          this.friendRemark = friendData.friendRemark;
        }
      }
    },
    setImageZIndex(){
      this.$utils.setElementImageZIndex();
    },
  },
  mounted(){
    this.z_index = this.$utils.getZIndex();
    this.setFriendRemark();
    if(this.userData && !this.userData.updateremark){
      this.inputname = '';
    }else{
      this.inputname = this.friendRemark || (this.userData && (this.userData.friendRemark ?this.userData.friendRemark : this.userData.userName));
    }

    // if(this.userData.updateremark || this.userData.friendRemark){
    //   // this.edit_name = true;
    // }
  }
}
</script>

<style lang="scss" scoped>
.contact_detail{
  width:100%;
  height:100%;
  flex:1;
  .name_group{
    @include flex; 
    width:100%;
    align-items: stretch;
    overflow:hidden;
    .avatar{
      flex-shrink: 0;
      display: block;
      width:90px;
      height:90px;
      border-radius:4px;
      background-color: $img_color;
    }
    .name_box{
      @include flexsb;
      @include bbox;
      width:100%;
      flex: 1;
      padding-left: 10px;
      flex-direction: column;
      align-items: flex-start;
      overflow:hidden;
      .name_wrapper{
        @include flex;
        width: 100%;
        .input_name_group{
          @include flex;
          position: relative;
          
          .name{
            font-size:18px;
            font-weight:400;
            color:rgba(51,51,51,1);
            padding:0 10px;
            line-height: 36px;
            // opacity: 0;
            &.empty{
              color: #ccc;
            }
          }
          .sex{
            @include background;
            width: 15px;
            height: 15px;
          
            // margin-left: 5px;
            &.male{
              background-image: url(~@/assets/images/common/male.png);
            }
            &.female{
              background-image: url(~@/assets/images/common/female.png);
            }
          }
          .input_name{
            
            @include transition;
            @include bbox;
            padding: 0 10px;
            background-color: transparent;
            border:none;
            outline: none;
            // position: absolute;
            // top:0;
            // left:0;
            z-index: 2;
            width: 100%;
            font-size:18px;
            height: 36px;
            font-weight:400;
            color:rgba(51,51,51,1);
            border: 1px solid transparent;
            border-radius: 4px;
            border: 1px solid #ccc;
            &.not_friend:hover{
              border: 1px solid transparent;
            }
            &:hover{
              border: 1px solid #ccc;
            }
          }
        }
        
        .gender{
          width: 16px;
          height: 16px;
          background: {
            image: url(~@/assets/images/common/female.png);
            size: 100%;
          }
          &.male{
            background-image: url(~@/assets/images/common/male.png);
          }
        }
      }
      .chat_username{
        @include bbox;
        @include single_line_intercept;
        font-size: 16px;
        color: rgba(102,102,102,1);
        padding:0 10px;
        width: 100%;
      }
      .nickname{
        @include bbox;
        font-size:16px;
        padding: 0 10px;
        font-weight:400;
        color:rgba(102,102,102,1);
      }
    }
  }
  .sign_group{
    @include textjustify;
    padding: 29px 0;
    font-size:14px;
    
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:22px;
    .title{}
    .sign{}
  }
  .info_group{
    @include flex;
    padding-bottom: 17px;
    align-items: flex-start;
    font-size:15px;
    
    font-weight:400;
    color:rgba(102,102,102,1);
    .key{
      flex-shrink: 0;
      text-align: justify;
      text-align-last: justify;
      text-justify: inter-ideograph;
      width:60px;
      
    }
    .val{
      @include bbox;
      padding-left: 20px;
      width:100%;
      flex: 1;
    }
  }
  .line{
    width:100%;
    height: 2px;
    background-color: $border_color;
    margin-bottom: 29px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
</style>