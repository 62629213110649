<template>
<div class="c_teaching_package_select_task_package_list bbox">
  <div class="list_group d-flex flex-wrap bbox">
    <template
      v-for="(item, index) in packageList"
    >
      <thumbnail-package
        v-if="pPackage.id !== item.id"
        :key="index"
        class="package_group cp"
        :p-package="item"
        @click.stop.native="checkPackage(item)"
      ></thumbnail-package>
    </template>
  </div>
  <div class="more_group d-flex align-center justify-center">
    <div
      v-show="showMore && !showLoading"
      class="more cp"
      @click.stop="loadmore()"
    >
      加载更多
    </div>
    <loading-icon
      v-show="showLoading"
      size="26"
    ></loading-icon>
  </div>
</div>
</template>

<script>
import LoadingIcon from '@/components/basic/loadingIcon'
import ThumbnailPackage from '@/components/PopUp/TeachingPackage/SelectTask/ThumbnailPackage'
export default {
  name: 'PackageList',
  components: {
    ThumbnailPackage,
    LoadingIcon
  },
  props: {
    pPackage: {
      type: Object,
      default: undefined
    },
    packageSelectTask: {
      type: Object,
      default: undefined
    },
    packageList: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    showMore() {
      const { packageSelectTask } = this
      const moreFieldName = packageSelectTask.getMoreFieldName()
      return packageSelectTask[moreFieldName]
    },
    showLoading() {
      const { packageSelectTask } = this
      const { loading } = packageSelectTask
      return loading
    }
  },
  methods: {
    loadmore() {
      this.$emit('handleLoadmore')
    },
    checkPackage(item) {
      const { packageSelectTask } = this
      packageSelectTask.search = ''
      this.$emit('handleClearFilterBarSearch')
      packageSelectTask.pPackage = item
      // this.$set(packageSelectTask, 'pPackage', item)
      // this.$set(packageSelectTask, 'search', '')
      // console.log(packageSelectTask)
      // packageSelectTask.getPackageTaskList()
      this.$emit('handleGetPackageTaskList')
      // console.log(packageSelectTask)
    }
  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_package_list{
  width: 100%;
  padding: 20px;
  .list_group{
    width: 100%;
    .package_group{
      margin: {
        right: 20px;
        bottom: 20px;
      }
    }
  }
  .more_group{
    width: 100%;
    height: 40px;
    .more{
      color: $main_blue;
      font-size: 14px;
    }
  }
}
</style>