/*
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-25 13:26:19
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-13 12:16:06
 * @FilePath     : /miaohang/src/classes/modules/LargeTask/index.js
 * @Description  : 
 */
let id = 1
class LargeTask{
  data = undefined
  backup = undefined
  teachData = undefined
  id = 1          // 自加字段 用于判断删除
  otask = undefined
  otask_config = undefined
  otask_origin = undefined

  constructor(params = {}){
    const {
      data,
      backup,
      teachData,
      // create_num,
      otask,
      otask_config,
      otask_origin,
    } = params ?? {}
    
    this.data = data
    this.backup = backup
    this.teachData = teachData
    this.id = id++
    this.otask = otask
    this.otask_config = otask_config
    this.otask_origin = otask_origin
  }

}

export default LargeTask